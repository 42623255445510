<ion-header #header>
  <ion-toolbar #toolbar>
    <ion-buttons slot="primary">
      <ion-button appClick (clicked)="onClose()" #menuButton id="mainmenu-menu-button">OK</ion-button>
    </ion-buttons>
    <ion-title><span id="page-title-span">{{ 'Seriennummern' | translate }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="app-content">
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button appClick (clicked)="onBarcodeScan()" color="dark">
      <ion-icon name="barcode"></ion-icon>
    </ion-fab-button>
    <ion-fab-button appClick (clicked)="onManuelleEingabe()" color="primary">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div class="section">
    <h1>{{ position.Bezeichnung }}</h1>
    <p>
      Bitte Seriennummern scannen
    </p>
  </div>

  <ion-list>
    <ion-item *ngFor="let p of position.Elemente">
      <ion-label class="pos-nr">{{ p.Pos }}. </ion-label>
      <ion-label *ngIf="p.Nummer">{{ p.Nummer }}</ion-label>
      <ion-label *ngIf="!p.Nummer" class="nummer-fehlt">Bitte scannen</ion-label>

      <ion-button slot="end" fill="clear" class="delete-item-button" appClick (clicked)="onEntfernen(p)" color="danger" *ngIf="p.Nummer">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
</ion-content>