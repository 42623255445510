import { Component, OnInit, Input, SimpleChanges, OnChanges, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { Utils } from 'src/app/api/helper/utils';
import { ArtikelListItem } from 'src/app/api/model/model';
import { Artikel, Kontakt } from 'src/app/api/model/swagger-model';
import { SystemService } from 'src/app/api/system.service';

const log = new Logger("ArtikelSuchenComponent");

@Component({
    selector: 'app-artikel-suchen',
    templateUrl: './artikel-suchen.component.html',
    styleUrls: ['./artikel-suchen.component.scss'],
})
export class ArtikelSuchenComponent implements OnInit, OnChanges, AfterViewInit {
    static instance: ArtikelSuchenComponent;

    @ViewChild('searchbar', { static: false }) searchbar: IonSearchbar;

    @Input() items: ArtikelListItem[];

    sichtbareItems: ArtikelListItem[];

    isVisible = false;
    suchtext = '';

    constructor(
        private cdr: ChangeDetectorRef,
        private systemService: SystemService,
        private modalController: ModalController,) {

        ArtikelSuchenComponent.instance = this;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.auftrag) {
            this.refresh();
        }
    }

    ngOnInit() {
        this.isVisible = true;

        for (const item of this.items) {
            if (item.artikel) {
                item.text = item.artikel.artikelKey + ': ' + item.artikel.bezeichnung;
                item.subtext = '';

                if (item.artikelgruppe) {
                    item.subtext = item.artikelgruppe.Name;
                }

                item.suchtext = item.artikel.bezeichnung
                    + ';' + item.artikel.Bezeichnung2
                    + ';' + item.artikel.artikelGruppe
                    + ';' + item.artikel.artikelKey
                    + ';' + item.artikel.BhAktion;

            } else if (item.vertrag) {
                item.text = item.vertragsposition.PosNr + ': ' + item.vertragsposition.Bezeichnung;
                item.subtext = '';

                item.suchtext = item.vertrag.Bezeichnung
                    + ';' + item.vertrag.Vertragsnummer
                    + ';' + item.vertragsposition.Bezeichnung
                    + ';' + item.vertragsposition.ArtikelKey
                    + ';' + item.vertragsposition.BhAktion;
            } else {
                item.text = '???';
                item.subtext = '';
                item.suchtext = '';
            }

            item.suchtext = item.suchtext.toLowerCase();
        }

        this.refresh();
    }

    async ngAfterViewInit() {
        log.info('ngAfterViewInit');

        setTimeout(() => {
            if (this.searchbar) {
                try {
                    this.searchbar.setFocus();
                } catch (err) {
                    log.error('setTimeout onSuchen: ' + Utils.getErrorMessage(err), err);
                }
            }
        }, 500);
    }

    refresh() {
        log.debug('refresh');
        this.filterSichtbareItems();
    }

    onClose() {
        log.debug('onClose');
        this.modalController.dismiss(null);
    }

    onSearchChanged(e: Event) {
        this.filterSichtbareItems();

        this.cdr.detectChanges();
    }

    private filterSichtbareItems() {
        log.debug('filterSichtbareItems');

        let sichtbareItems: ArtikelListItem[];

        if (this.suchtext && this.suchtext.trim()) {
            sichtbareItems = [];

            const parts: string[] = this.suchtext.toLowerCase().trim().split(' ');

            for (const item of this.items) {
                let isMatch = true;

                for (const str of parts) {
                    if (!item.suchtext.includes(str)) {
                        isMatch = false;
                        break;
                    }
                }

                if (isMatch) {
                    sichtbareItems.push(item);
                }
            }
        } else {
            sichtbareItems = this.items;
        }

        this.sichtbareItems = sichtbareItems;
    }

    onArtikelSelected(item: ArtikelListItem) {
        this.modalController.dismiss(item);
    }

    myHeaderFn(auftrag: Artikel, recordIndex, records: Artikel[]) {
        const that = ArtikelSuchenComponent.instance;

        // let headerGeandert = false;

        // if (recordIndex <= 0) {
        //     headerGeandert = true;
        // } else {
        //     const vorherigerAuftrag = records[recordIndex - 1];

        //     if (that.auftragFahrzeugFilterAktiv) {
        //         if (vorherigerAuftrag.FahrzeugKennung != auftrag.FahrzeugKennung || vorherigerAuftrag.AAbholdatum != auftrag.AAbholdatum) {
        //             headerGeandert = true;
        //         }
        //     } else {
        //         if (vorherigerAuftrag.AAbholdatum != auftrag.AAbholdatum) {
        //             headerGeandert = true;
        //         }
        //     }
        // }

        // if (headerGeandert) {
        //     let datum = auftrag.AAbholdatum;

        //     if (datum === datumGestern) {
        //         datum = 'Gestern';
        //     } else if (datum === datumHeute) {
        //         datum = 'Heute';
        //     } else if (datum === datumMorgen) {
        //         datum = 'Morgen';
        //     } else if (datum === datumUebermorgen) {
        //         datum = 'Übermorgen';
        //     }

        //     if (that.auftragFahrzeugFilterAktiv) {
        //         return {
        //             title: auftrag.FahrzeugKennung,
        //             subtitle: datum
        //         };
        //     } else {
        //         return {
        //             title: datum
        //         };
        //     }
        // }

        return null;
    }
}
