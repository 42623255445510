export enum Auftragsstatus {
    Unbekannt = 0,
    Neu = 10,
    Angenommen = 20,
    Zugewiesen = 35,
    Weiterleiten = 40,
    InBearbeitung = 50,
    Unterbrochen = 70,
    FreigabeAnfordern = 71,
    Gedruckt = 80,
    FertigKommissioniert = 81,
    Bezahlt = 85,
    Abgeschlossen = 90,
    Abgelehnt = 91,
    Geloescht = 95
}
