import { MobileWaage } from './waage';
import { Logger } from '../helper/app-error-logger';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { App } from '../helper/app';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';

import * as moment from 'moment';
import { GeraetStatusTyp, WaageProtokoll, WaageProtokollEintrag } from '../model/model';
import { Utils } from '../helper/utils';
import { WaageKonfiguration } from '../helper/app.config';
import { AppStorage } from '../helper/app-storage';
import { Wiegedaten } from '../model/swagger-model';

const BOARDCOMPUTER = 5;
const WAAGE = 1;

export class WelvaartsWaage implements MobileWaage {
    private log = new Logger('WelvaartsWaage');

    konfiguration: WaageKonfiguration;
    adresse: string;
    status = new BehaviorSubject<GeraetStatusTyp>(GeraetStatusTyp.NichtVerbunden);
    sub: Subscription;
    aktiv: boolean;

    dagtumLetzteUhrSynchronisierung = 0;

    error = new Subject<string>();
    connected = new Subject<void>();
    disconnected = new Subject<void>();
    datenEmpfangen = new Subject<void>();
    wiegedatenEmpfangen = new Subject<Wiegedaten>();

    updateIntervalId: any = null;

    protokoll: WaageProtokoll;

    constructor(private bluetoothSerial: BluetoothSerial) {
        this.log.info('WELVAARTS Waage');

        this.updateIntervalId = setInterval(() => {
            if (this.aktiv) {
                this.pruefeVerbindung();
            }
        }, 30000);
    }

    async getProtokoll(): Promise<WaageProtokoll> {
        if (!this.protokoll) {
            this.protokoll = await AppStorage.current.get('waage-protokoll-' + this.adresse);

            if (!this.protokoll) {
                this.protokoll = {
                    waageAdresse: this.adresse,
                    eintraege: []
                }
            }

            if (!this.protokoll.waageAdresse) {
                this.protokoll.waageAdresse = this.adresse;
            }

            if (!this.protokoll.eintraege) {
                this.protokoll.eintraege = [];
            }
        }

        return this.protokoll;
    }

    getTyp() {
        return 'welvaarts';
    }

    setActive(active: boolean) {
        if (this.aktiv === active) {
            // Keine Änderung
            return;
        }

        this.aktiv = active;

        if (active) {
            this.connect();
        } else {
            this.close()
        }
    }

    async connect(): Promise<any> {
        this.log.info('Connect');

        if (!App.isCordovaAvailable()) {
            this.disconnected.next();
            return;
        }

        await App.ready();

        try {
            this.log.debug('Aktiviere Bluetooth...');
            const enableResult = await this.bluetoothSerial.enable();

            this.log.debug('enableResult: ' + enableResult, enableResult);

            this.log.debug('Subscribe auf Data...');
            this.sub = this.bluetoothSerial.subscribe('}').subscribe(p => this.onDataReceived(p));

            const connectResult = await this.bluetoothSerial.connect(this.adresse).toPromise();

            this.log.info('Verbindung zur Waage', connectResult);
            this.status.next(GeraetStatusTyp.Verbunden);
            this.connected.next();
        } catch (err) {
            this.log.warn(`Connect Waage (${this.adresse}): ` + Utils.getErrorMessage(err), err);
            this.status.next(GeraetStatusTyp.NichtVerbunden);

            let errMessage = Utils.getErrorMessage(err);

            if (errMessage.indexOf('User did not enable Bluetooth') >= 0) {
                errMessage = 'Bluetooth nicht aktiv';
            }

            this.disconnected.next();
            this.error.next(errMessage);
        }
    }

    async close(): Promise<any> {
        this.log.debug('Close');

        this.status.next(GeraetStatusTyp.NichtVerbunden);

        if (!App.isCordovaAvailable()) {
            this.disconnected.next();
            return;
        }

        try {
            if (this.sub) {
                this.sub.unsubscribe();
                this.sub = null;
            }

            this.log.debug('Disconnecting bluetooth...');
            await this.bluetoothSerial.disconnect();
            this.log.debug('Disconnected');
        } catch (err) {
            this.error.next(Utils.getErrorMessage(err));
            this.log.warn(`Close Waage: ${err.message}`);
        }

        this.disconnected.next();
    }

    async dispose(): Promise<void> {
        this.log.debug('dispose');

        this.aktiv = false;

        if (this.updateIntervalId) {
            clearInterval(this.updateIntervalId);
            this.updateIntervalId = null;
        }

        await this.close();

        // https://stackoverflow.com/questions/40452979/does-subject-complete-unsubscribe-all-listeners
        this.wiegedatenEmpfangen.complete();
        this.wiegedatenEmpfangen.observers.length = 0;
    }

    async pruefeVerbindung(): Promise<void> {
        this.log.debug('pruefeVerbindung');

        if (!this.aktiv) {
            this.status.next(GeraetStatusTyp.NichtVerbunden);
            return;
        }

        if (!App.isCordovaAvailable()) {
            return;
        }

        try {
            const connected = await this.bluetoothSerial.isConnected();

            this.log.debug('isConnected', connected);

            if (connected) {
                this.status.next(GeraetStatusTyp.Verbunden);
                const maxAlter = 12 * 60 * 60 * 1000;

                this.connected.next();

                if (this.dagtumLetzteUhrSynchronisierung < Date.now() - maxAlter) {
                    this.dagtumLetzteUhrSynchronisierung = Date.now();

                    this.syncUhr(false);
                }
            } else if (!connected) {
                this.log.debug('Waage nicht verbunden. Versuche reconnect...');

                await this.close();
                await this.connect();
            }
        } catch (err) {
            this.log.info('pruefeVerbindung', err);
            this.error.next(Utils.getErrorMessage(err));
            this.status.next(GeraetStatusTyp.Fehler);
        }
    }

    async wiegen(nr: number) {
        this.log.debug('wiegen: ' + nr);

        try {
            await this.pruefeVerbindung();

            if (nr == 1) {
                // Erste Wiegung
                this.senden('1#51#5#');
            } else if (nr == 2) {
                // Zweite Wiegung
                this.senden('1#53#5#');
            }

        } catch (err) {
            this.log.error('wiegen: ' + Utils.getErrorMessage(err), err);
        }
    }

    async tarieren() {
        this.log.debug('tarieren');

        try {
            await this.pruefeVerbindung();
            this.senden('1#101#5#1#');
        } catch (err) {
            this.log.error('tarieren: ' + Utils.getErrorMessage(err), err);
        }
    }

    speichereProtokoll() {
        // Zu alte Einträge löschen
        const maxEintraege = 100;
        const protokoll = this.protokoll;

        if (protokoll.eintraege.length > maxEintraege) {
            protokoll.eintraege.splice(0, protokoll.eintraege.length - maxEintraege);
        }

        AppStorage.current.set('waage-protokoll-' + this.adresse, protokoll);
    }

    async onDataReceived(message: string) {
        try {
            this.log.debug('Waage onDataReceived', message);

            this.status.next(GeraetStatusTyp.Verbunden);
            this.datenEmpfangen.next();

            message = message.trim();

            if (!message.startsWith('{')) {
                // Verwerfe Nachricht, da nicht vollständig
                return;
            }

            const protokoll = await this.getProtokoll();

            const protokollEintrag: WaageProtokollEintrag = {
                timestamp: Date.now(),
                rohdaten: message,
                typ: 'unbekannt',
                daten: ''
            };

            protokoll.eintraege.push(protokollEintrag);

            this.speichereProtokoll();

            // Wiededaten Beispiel:
            // {5#30#1#1915486214#08#02#20#12#57#50##VIE-EN xxx#####110##110##0#######@99}

            // Entferne die geschweiften Klammern
            const daten = message.substr(1, message.length - 2);

            this.log.debug('Daten: ' + daten);

            const posAt = daten.indexOf('@');

            if (posAt <= 0) {
                // Keien Checksumme gefunden. Ignorieren
                return;
            }

            const nutzdaten = daten.substr(0, posAt);
            const checksumme = parseInt(daten.substr(posAt + 1), 10);
            const berechneteChecksum = this.berechneChecksumme(nutzdaten);

            this.log.debug('Daten', {
                nutzdaten,
                checksumme,
                berechneteChecksum
            });

            if (checksumme != berechneteChecksum) {
                this.log.warn('Checksumme ungültig. Egal.');
                return;
            }

            const parts = daten.split('#');

            const empfaenger = parseInt(parts[0], 10);
            const command = parseInt(parts[1], 10);
            const absender = parseInt(parts[2], 10);

            if (empfaenger != BOARDCOMPUTER) {
                // 5 ist der Boardcomputer, also wir.
                // Alles für andere Empfänger ignorieren
                this.log.warn('Ungültiger Empfänger: ' + empfaenger);
                return;
            }

            if (absender != WAAGE) {
                this.log.warn('Ungültiger Absender: ' + absender);
                return;
            }

            switch (command) {
                case 1:
                    protokollEintrag.daten = 'NACK_CHECKSUM';
                    protokollEintrag.typ = 'NACK';
                    break;

                case 3:
                    protokollEintrag.daten = 'NACK_VARIABLE_COUNT: ' + parts[3] + ' ' + parts[4];
                    protokollEintrag.typ = 'NACK';
                    break;

                case 5:
                    protokollEintrag.daten = 'NACK_UNKNOWN_COMMAND';
                    protokollEintrag.typ = 'NACK';
                    break;

                case 9:
                    protokollEintrag.daten = 'NACK_GENERAL: ' + parts[3];
                    protokollEintrag.typ = 'NACK';
                    break;

                case 30:
                    this.verarbeiteWiegedaten(parts.slice(3), protokollEintrag);
                    break;

                case 14:
                    this.log.info('Acknowledge synchronise clock empfangen');
                    protokollEintrag.daten = 'ACK CLOCK SYNC';
                    protokollEintrag.typ = 'ACK';
                    break;

                case 52:
                    protokollEintrag.daten = 'ACK WEIGHING 1 REQ: ' + parts[3];
                    protokollEintrag.typ = 'ACK';
                    break;

                case 54:
                    protokollEintrag.daten = 'ACK WEIGHING 2 REQ: ' + parts[3];
                    protokollEintrag.typ = 'ACK';
                    break;

                case 102:
                    protokollEintrag.daten = 'ACK RESET TARE REQ: ' + parts[3];
                    protokollEintrag.typ = 'ACK';
                    break;

                default:
                    protokollEintrag.daten = "Unbekanntes Command: " + command + '. Rohdaten: ' + protokollEintrag.rohdaten;
                    protokollEintrag.typ = 'unbekannt';
            }

        } catch (err) {
            this.log.error('onDataReceived', err);
            this.error.next(Utils.getErrorMessage(err));
        }
    }

    verarbeiteWiegedaten(data: string[], protokollEintrag: WaageProtokollEintrag) {
        const serialNumber = parseInt(data[0], 10);
        const day = parseInt(data[1], 10);
        const month = parseInt(data[2], 10);
        const year = parseInt(data[3], 10);
        const hour = parseInt(data[4], 10);
        const minute = parseInt(data[5], 10);
        const second = parseInt(data[6], 10);
        const customerId = data[7];
        const licensePlateTruck = data[8];
        const gps = data[9];
        const rfid = data[10];
        const statusCode = parseInt(data[11], 10);
        const wasteCode = parseInt(data[12], 10);
        const weight1e = parseFloat(data[13]);
        const status1e = data[14];
        const weight2e = parseFloat(data[15]);
        const status2e = data[16];
        const weightContents = parseFloat(data[17]);

        const datum = moment()
            .year(2000 + year)
            .month(month - 1) // Januar = 0
            .day(day)
            .hour(hour)
            .minute(minute)
            .second(second);

        const wiegedaten: Wiegedaten = {
            Wiegenummer: serialNumber,
            Datum: datum.toISOString(),
            Wiegung1: weight1e,
            Wiegung2: weight2e,
            Netto: weightContents
        };

        this.log.info('Wiegedaten gelesen', wiegedaten);

        // Wiegung speichern
        // TODO
        this.wiegedatenEmpfangen.next(wiegedaten);

        // Antworten
        this.senden(`1#31#5#${serialNumber}#`);

        protokollEintrag.daten = `Nr. ${serialNumber}, Datum: ${datum.format('DD.MM.YYYY HH:mm:ss')}, W1: ${weight1e}, W2: ${weight2e}, NET: ${wiegedaten.Netto}`;
        protokollEintrag.typ = 'wiegung';

        this.speichereProtokoll();
    }

    syncUhr(pruefeVerbindung = true) {
        this.log.debug('syncUhr');

        try {
            if (pruefeVerbindung) {
                this.pruefeVerbindung();
            }

            const now = moment();

            const daten = '1#13#5'
                + '#' + now.date()
                + '#' + (now.month() + 1)
                + '#' + (now.year() - 2000)
                + '#' + now.hour()
                + '#' + now.minute()
                + '#' + now.second()
                + '#';

            this.senden(daten);
        } catch (err) {
            this.log.error('syncUhr: ' + Utils.getErrorMessage(err), err);
        }
    }

    senden(nutzdaten: string) {
        const checksumme = this.berechneChecksumme(nutzdaten);
        const message = "{" + nutzdaten + "@" + checksumme.toString() + '}';

        this.log.debug('senden: ' + message);

        this.protokoll.eintraege.push({
            daten: 'SEND: ' + message,
            typ: 'senden',
            timestamp: Date.now(),
            rohdaten: message
        });

        this.bluetoothSerial.write(message);
    }

    berechneChecksumme(nutzdaten: string): number {
        let checksum = 0;

        for (const c of nutzdaten) {
            checksum += c.charCodeAt(0);
        }

        return checksum % 256;
    }
}
