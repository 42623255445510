<ion-header #header>
  <ion-toolbar #toolbar>
    <ion-buttons slot="primary">
      <ion-button appClick (clicked)="onClose()" #menuButton id="mainmenu-menu-button">Abbrechen</ion-button>
    </ion-buttons>
    <ion-title><span id="page-title-span">{{ 'Anrufen' | translate }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="app-content">
  <ion-list>
    <ng-container *ngFor="let g of gruppen">
      <ion-item-divider *ngIf="g.titel">
        <ion-label>{{ g.titel }}</ion-label>
      </ion-item-divider>

      <ion-item *ngFor="let p of g.kontakte" appClick (clicked)="onAnrufen(p)" button="true">
        <ion-label>
          <h2>{{ p.Name }}</h2>
          <p>{{ p.Telefon }}</p>
        </ion-label>
        <ion-icon name="call" slot="end" color="primary"></ion-icon>
      </ion-item>
    </ng-container>
  </ion-list>

</ion-content>