

import { Injectable, Optional } from '@angular/core';
import { Storage } from '@ionic/storage';
import { RemoteService } from './remote.service';

import { Subject, BehaviorSubject } from 'rxjs';
import { Logger, LogLevel } from './helper/app-error-logger';
import { AppConfig } from './helper/app.config';
import { SystemService } from './system.service';
import { StammdatenService } from './stammdaten.service';

import * as moment from 'moment';
import * as pako from 'pako';

import { AlertController, ModalController, Platform, NavController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AppStorage } from './helper/app-storage';
import { UvvPruefGegenstand, UvvKatalog, UvvPruefung, Textdatei, TextdateiTyp } from './model/swagger-model';
import { SyncResult } from './model/sync-result';
import { UvvHelper } from './helper/uvv-helper';
import { App } from './helper/app';
import { Utils } from './helper/utils';
import { UvvPruefGegenstandEx } from './model/model';
import { UiHelper } from './helper/ui-helper';

@Injectable({
    providedIn: 'root'
})
export class UvvService {
    private log = new Logger('UvvService');

    anzahlUvv = new BehaviorSubject<number>(0);
    uvvGegenstaende = new BehaviorSubject<UvvPruefGegenstandEx[]>([]);
    uvvKataloge = new BehaviorSubject<UvvKatalog[]>([]);
    uvvPruefungen = new BehaviorSubject<UvvPruefung[]>([]);

    datenLadenPromise: Promise<any>;

    private isReady = new BehaviorSubject<boolean>(false);

    constructor(
        private storage: Storage,
        private remoteService: RemoteService,
        private stammdatenService: StammdatenService,
        private alertCtrl: AlertController,
        private modalController: ModalController,
        private router: Router,
        private nav: NavController,
        private toastController: ToastController,
        private systemService: SystemService) {
    }

    async init() {
        // WebSocket-Event
        this.systemService.uvvAktualisieren.subscribe(() => this.syncMitServer('uvvAktualisieren'));

        App.current.uvvVerfuegbar.subscribe(() => this.updateUvvVerfuegbarUndAktiv());
        App.current.fahrer.subscribe(() => this.updateUvvVerfuegbarUndAktiv());

        await this.ladeDatenVonStorage();

        this.updateUvvVerfuegbarUndAktiv();

        setTimeout(() => {
            try {
                if (App.current.uvvVerfuegbar.getValue()) {
                    if (this.uvvGegenstaende.getValue().length == 0) {
                        // UVV ist aktiv aber es sind keine Daten vorhanden. Sync erzwingen
                        this.syncMitServer("UVV verfuegbar aber kein Daten vorhanden", true);
                    }
                }
            } catch (err) {
                this.log.error('setTimeout init: ' + Utils.getErrorMessage(err), err);
            }
        }, 15000);

        this.isReady.next(true);
    }

    private updateUvvVerfuegbarUndAktiv() {
        let aktiv = false;

        if (App.current.uvvVerfuegbar.getValue()) {
            if (AppConfig.current.einstellungen.AnmeldungErforderlich === 'ja') {
                const fahrer = App.current.fahrer.getValue();

                if (fahrer && fahrer.uvvBerechtigt) {
                    aktiv = true;
                }
            } else {
                aktiv = true;
            }
        }

        if (aktiv != App.current.uvvBerechtigt.getValue()) {
            this.log.debug('UVV verfügbar und aktiv (changed): ' + aktiv);
            App.current.uvvBerechtigt.next(aktiv);
        }
    }

    async loescheDaten() {
        this.uvvPruefungen.next([]);
        this.uvvGegenstaende.next([]);
        this.uvvKataloge.next([]);

        await AppStorage.current.set('uvv-gegenstaende', this.uvvGegenstaende.getValue());
        await AppStorage.current.set('uvv-kataloge', this.uvvKataloge.getValue());
        await AppStorage.current.set('uvv-pruefungen-keys', []);
    }

    async bereinigeDaten() {
    }

    async ladeDatenVonStorage(): Promise<void> {
        if (!this.datenLadenPromise) {
            this.log.info("Lade UVV-Daten vom Storage...");

            this.datenLadenPromise = new Promise(async resolve => {
                let gegenstaende = await AppStorage.current.get('uvv-gegenstaende');
                let kataloge = await AppStorage.current.get('uvv-kataloge');
                let pruefungenKeys = await AppStorage.current.get('uvv-pruefungen-keys');

                if (!gegenstaende) {
                    gegenstaende = [];
                }

                if (!kataloge) {
                    kataloge = [];
                }

                if (!pruefungenKeys) {
                    pruefungenKeys = [];
                }

                for (const g of gegenstaende) {
                    this.fixGegenstand(g);
                }

                this.uvvKataloge.next(kataloge);
                this.uvvGegenstaende.next(gegenstaende);

                const pruefungen = [];

                for (const key of pruefungenKeys) {
                    const pruefung = await AppStorage.current.get('uvv-pruefung-' + key);

                    if (pruefung) {
                        pruefungen.push(pruefung);
                    }
                }

                this.uvvPruefungen.next(pruefungen);

                resolve(null);
            });
        }

        await this.datenLadenPromise;

        this.log.debug("Lade UVV-Daten vom Storage fertig", {
            gegenstaende: this.uvvGegenstaende.getValue(),
            kataloge: this.uvvKataloge.getValue(),
            pruefungen: this.uvvPruefungen.getValue()
        });
    }

    async syncMitServer(info: string, fullUpdate = false): Promise<SyncResult> {
        try {
            this.log.debug('syncMitServer: ' + info + ', fullUpdate=' + fullUpdate);

            let gegenstaende = this.uvvGegenstaende.getValue();
            let kataloge = this.uvvKataloge.getValue();

            let uvvTimestamp = await AppStorage.current.get('uvv-timestamp');

            if (fullUpdate || !uvvTimestamp || !gegenstaende.length || !kataloge.length) {
                uvvTimestamp = 0;
            }

            // Lade UVV-Daten vom Server die nach dem UVV-Timestamp geändert wurden
            const syncResult = await this.remoteService.syncUvv(uvvTimestamp);

            if (!syncResult) {
                this.log.warn('Sync UVV: keine Antwort');
                return;
            }

            let aktualisierungVorhanden = false;

            if (syncResult.istVollstaendig) {
                // Server hat mit vollständigem UVV-Datensatz geantwortet. Es müssen alle lokal vorhandenen Daten gelöscht werden
                const gegenstaendeEx = syncResult.gegenstaende as UvvPruefGegenstandEx[];

                for (const gegenstandEx of gegenstaendeEx) {
                    this.fixGegenstand(gegenstandEx);
                }

                gegenstaende = gegenstaendeEx;
                kataloge = syncResult.kataloge;
                aktualisierungVorhanden = true;

            } else {
                // Übernehme nur die aktualisierten Daten
                for (const g of syncResult.gegenstaende) {
                    const index = gegenstaende.findIndex(p => p.key === g.key);

                    const gegenstandEx = g as UvvPruefGegenstandEx;

                    this.fixGegenstand(gegenstandEx);

                    if (index >= 0) {
                        const oldObj = gegenstaende[index];
                        const newObj = g;

                        if (UvvHelper.areEqual(oldObj, newObj)) {
                            this.log.debug('gegenstand NICHT geändert', {
                                old: gegenstaende[index],
                                new: g
                            });

                        } else {
                            this.log.debug('gegenstand geändert', {
                                old: gegenstaende[index],
                                new: g
                            });

                            aktualisierungVorhanden = true;
                        }

                        gegenstaende[index] = gegenstandEx;
                    } else {
                        gegenstaende.push(gegenstandEx);
                        aktualisierungVorhanden = true;
                    }
                }

                for (const katalog of syncResult.kataloge) {
                    const index = kataloge.findIndex(p => p.name === katalog.name);

                    if (index >= 0) {
                        kataloge[index] = katalog;
                        aktualisierungVorhanden = true;
                    } else {
                        kataloge.push(katalog);
                        aktualisierungVorhanden = true;
                    }
                }
            }

            // Merke den Timestamp
            await AppStorage.current.set('uvv-timestamp', syncResult.timestamp);

            this.log.debug('aktualisierungVorhanden', aktualisierungVorhanden);

            if (aktualisierungVorhanden) {
                this.uvvGegenstaende.next(gegenstaende);
                this.uvvKataloge.next(kataloge);

                await AppStorage.current.set('uvv-gegenstaende', gegenstaende);
                await AppStorage.current.set('uvv-kataloge', kataloge);
            }

            return {
                success: true
            };
        } catch (err) {
            this.log.error('syncMitServer', err);

            return {
                errorMessage: err,
                success: false
            };
        }
    }

    async getUvvGegenstand(key: string): Promise<UvvPruefGegenstandEx> {
        if (!this.uvvGegenstaende.getValue().length) {
            await this.datenLadenPromise;
        }

        const item = this.uvvGegenstaende.getValue().find(p => p.key == key);

        return this.fixGegenstand(item);
    }

    fixGegenstand(item: UvvPruefGegenstandEx) {
        if (!item) {
            return item;
        }

        if (item.naechstePruefung || !item.naechstePruefungTimestamp) {
            try {
                item.naechstePruefungTimestamp = +moment(item.naechstePruefung).valueOf();
            } catch (err) {
                this.log.warn(`Fehler beim Parsen von UVV-Gegenstand ${item.bezeichnung}: ${item.naechstePruefung}: ${Utils.getErrorMessage(err)}`);
            }
        }

        return item;
    }

    async getAktiveUvvPruefung(gegenstand: UvvPruefGegenstand): Promise<UvvPruefung> {
        await this.datenLadenPromise;

        const pruefungen = this.uvvPruefungen.getValue().filter(p => p.GegenstandKey == gegenstand.key && p.Status < 90);

        if (!pruefungen.length) {
            return null;
        }

        return pruefungen[0];
    }

    public async ready() {
        if (this.isReady.getValue()) {
            return;
        }

        return new Promise(resolve => {
            const subscription = this.isReady.subscribe((value) => {
                if (value) {
                    subscription.unsubscribe();
                    resolve(null);
                }
            });
        });
    }

    async abschliessenPruefung(uvvPruefung: UvvPruefung): Promise<void> {
        const gegenstand = await this.getUvvGegenstand(uvvPruefung.GegenstandKey);

        if (!gegenstand) {
            throw new Error('UVV-Gegenstand ' + uvvPruefung.GegenstandKey + ' nicht gefunden');
        }

        const katalog = this.uvvKataloge.getValue().find(p => p.name == gegenstand.katalogName);

        if (!katalog) {
            throw new Error('UVV-Katalog ' + gegenstand.katalogName + ' nicht gefunden');
        }

        if (!uvvPruefung.Eintraege) {
            uvvPruefung.Eintraege = [];
        }

        uvvPruefung.Datum = moment().toISOString();
        uvvPruefung.Status = 90;

        this.log.debug('abschliessenPruefung', uvvPruefung);

        await this.speicherePruefung(uvvPruefung);

        // Copy
        const sendUvvPruefung: UvvPruefung = JSON.parse(JSON.stringify(uvvPruefung));

        for (const a of sendUvvPruefung.Eintraege) {
            if (a.Bilder) {
                for (const bild of a.Bilder) {
                    if (bild.BildGuid) {
                        bild.Bild = await AppStorage.current.getBildBase64Data(bild.BildGuid);
                    }
                }
            }
        }

        const textdatei: Textdatei = {
            typ: TextdateiTyp.UvvPruefung,
            key: uvvPruefung.GegenstandKey,
            uvvPruefung: sendUvvPruefung,
            datum: moment().toISOString(),
            geraeteNummer: AppConfig.current.geraeteNummer
        };

        const mitGpsPosition = AppConfig.current.einstellungen.GpsPositionBeiUvv === 'ja';

        // Kein await hier aus Performance-Gründen
        this.systemService.sendeTextdatei(textdatei, mitGpsPosition);

        // Nächstes Prüfdatum berechnen
        gegenstand.letztePruefung = moment().startOf('day').toISOString();
        gegenstand.naechstePruefung = UvvHelper.berechneNaechstePruefung(katalog, gegenstand);
        gegenstand.naechstePruefungTimestamp = +moment(gegenstand.naechstePruefung).valueOf();

        await this.speichereGegenstand(gegenstand);

        const toast = await this.toastController.create({
            message: 'UVV-Prüfung wurde abgeschlossen',
            duration: 1500
        });

        toast.present();
    }

    async speichereGegenstand(uvvGegenstand: UvvPruefGegenstandEx): Promise<void> {
        await this.datenLadenPromise;

        let list = this.uvvGegenstaende.getValue();

        if (!list) {
            list = [];
        }

        uvvGegenstand.naechstePruefungTimestamp = +moment(uvvGegenstand.naechstePruefung).valueOf();

        const index = list.findIndex(p => p.key == uvvGegenstand.key);

        if (index >= 0) {
            list[index] = uvvGegenstand;
        } else {
            list.push(uvvGegenstand);
        }

        this.uvvGegenstaende.next(list);

        await AppStorage.current.set('uvv-gegenstaende', this.uvvGegenstaende.getValue());
    }

    async speicherePruefung(uvvPruefung: UvvPruefung): Promise<void> {
        await this.datenLadenPromise;

        let list = this.uvvPruefungen.getValue();

        if (!list) {
            list = [];
        }

        const index = list.findIndex(p => p.GegenstandKey == uvvPruefung.GegenstandKey);

        let speichereKeys = false;

        if (index >= 0) {
            list[index] = uvvPruefung;
        } else {
            list.push(uvvPruefung);
            speichereKeys = true;
        }

        this.uvvPruefungen.next(list);

        const key = 'uvv-pruefung-' + uvvPruefung.GegenstandKey;

        await AppStorage.current.set(key, uvvPruefung);

        if (speichereKeys) {
            await this.speicherePruefungKeys();
        }

        if (uvvPruefung.Pruefergebnis == 'OK') {
            const gegenstand = await this.getUvvGegenstand(uvvPruefung.GegenstandKey);

            if (gegenstand) {
                if (gegenstand.zustand === 'beschaedigt') {
                    gegenstand.zustand = null;

                    this.speichereGegenstand(gegenstand);
                }
            }
        }
    }

    async speicherePruefungKeys(): Promise<void> {
        const keys = this.uvvPruefungen.getValue().map(p => p.GegenstandKey);

        await AppStorage.current.set('uvv-pruefungen-keys', keys);
    }

    getBehaelterByBehaelterNr(behaelterNr: string, isScan: boolean): UvvPruefGegenstand {
        if (!behaelterNr) {
            return;
        }

        behaelterNr = behaelterNr.trim().toUpperCase();

        const gegenstaende = this.uvvGegenstaende.getValue();
        let behaelter: UvvPruefGegenstand = null;

        if (isScan) {
            // Behaelter-Nummer wurde gescannt. Zuerst nach einer passenden IdentNr suchen
            behaelter = gegenstaende.find(p => p.identNummer && p.identNummer.toUpperCase() == behaelterNr);

            if (!behaelter) {
                behaelter = gegenstaende.find(p => p.manuelleNummer && p.manuelleNummer.toUpperCase() == behaelterNr);
            }
        } else {
            // Nummer wurde eingegeben. Zuerst die "manuelle Nummer" prüfen. Das ist normalerweise eine Nummer die auf dem Container steht.
            behaelter = gegenstaende.find(p => p.manuelleNummer && p.manuelleNummer.toUpperCase() == behaelterNr);

            if (!behaelter) {
                behaelter = gegenstaende.find(p => p.identNummer && p.identNummer.toUpperCase() == behaelterNr);
            }
        }

        return behaelter;
    }

    async registriereNeuenBehaelterRemote(uvvPruefGegenstand: UvvPruefGegenstand): Promise<UvvPruefGegenstand> {
        this.log.info('registriereNeuenBehaelterRemote', uvvPruefGegenstand);

        try {
            const textdatei: Textdatei = {
                typ: TextdateiTyp.NeuerBehaelter,
                datum: moment().toISOString(),
                geraeteNummer: AppConfig.current.geraeteNummer,
                Behaelter: uvvPruefGegenstand
            };

            const result = await this.remoteService.liveTextdatei(textdatei);

            if (result) {
                if (result.Fehlermeldung) {
                    UiHelper.showErrorLight(result.Fehlermeldung);
                    return null;
                }

                const behaelter = result.Behaelter;

                if (behaelter) {
                    // Übernehme den Behälter in die Stammdaten
                    this.fixGegenstand(behaelter);

                    const list = this.uvvGegenstaende.getValue();
                    list.push(behaelter);
                    this.uvvGegenstaende.next(list);

                    await AppStorage.current.set('uvv-gegenstaende', list);
                }

                return behaelter;
            } else {
                UiHelper.showFehlerKommunikation();
            }
        } catch (err) {
            UiHelper.showError(err);
        }

        return null;
    }
}
