import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

export class I18N {
    static translateService: TranslateService;

    public static instant(key: string): string {
        if (!key) {
            return '';
        }

        return I18N.translateService.instant(key);
    }

    public static get(key: string): Promise<string> {
        return I18N.translateService.get(key).toPromise();
    }

    public static getObservable(key: string): Observable<string> {
        return I18N.translateService.get(key);
    }
}

export function i18n(key: string): string {
    return I18N.instant(key);
}