import { TranslateLoader } from "@ngx-translate/core";
import { Observable, of } from "rxjs";

import { translations } from 'src/app/shared/translations';
import { Logger } from "./app-error-logger";

const log = new Logger('AppTranslationLoader');

export class AppTranslationLoader implements TranslateLoader {

    getTranslation(lang: string): Observable<any> {
        log.debug('getTranslation: ' + lang);

        lang = lang.toUpperCase();
        return of(translations[lang]);
    }
}
