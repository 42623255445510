import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AuftragService } from 'src/app/api/auftrag.service';
import { AuftragEx, UnterschriftEx } from 'src/app/api/model/model';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { UnterschriftComponent } from 'src/app/shared/components/unterschrift/unterschrift.component';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { Subscription } from 'rxjs';
import { ImageHelper } from 'src/app/api/helper/image-helper';
import { App } from 'src/app/api/helper/app';
import { ClickHelper } from 'src/app/api/helper/click-helper';
import { SystemService } from 'src/app/api/system.service';
import { AppConfig } from 'src/app/api/helper/app.config';

import * as moment from 'moment';
import { Utils } from 'src/app/api/helper/utils';

@Component({
    templateUrl: './unterschrift-modal.page.html',
    styleUrls: ['./unterschrift-modal.page.scss'],
})
export class UnterschriftModalPage implements OnInit, AfterViewInit, OnDestroy {
    log = new Logger("UnterschriftModalPage");

    @ViewChild('unterschriftComponent', { static: false }) unterschriftComponent: UnterschriftComponent;
    @ViewChild('hiddenCanvans', { static: false }) hiddenCanvans: any;

    @Input() unterschrift: UnterschriftEx;
    @Input() titel: string;

    hiddenCanvasElement: HTMLCanvasElement;
    datumHeute = '';
    speichernButtonColor = 'light';
    speichernDisabled = true;
    logo: string;

    private subscriptions: Subscription[] = [];

    constructor(
        private auftragService: AuftragService,
        private systemService: SystemService,
        private modalController: ModalController) { }

    async ngOnInit() {
        if (AppConfig.current.einstellungen.LogoAufUnterschritseiteAnzeigen == 'ja') {
            this.logo = AppConfig.current.einstellungen.Logo;
        }
    }

    ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    ngAfterViewInit() {
        this.hiddenCanvasElement = this.hiddenCanvans.nativeElement;
    }

    ionViewWillEnter() {
        this.datumHeute = moment().format('DD.MM.YYYY');
    }

    ionViewDidEnter() {
        App.current.backAction = () => this.onAbbrechen(null);
    }

    ionViewWillLeave() {
        App.current.backAction = null;
        App.loading(false);
    }

    onAbbrechen(e: Event) {
        this.modalController.dismiss(false);
    }

    onSignaturVorhanden(component: UnterschriftComponent) {
        this.unterschriftComponent = component;

        if (this.unterschriftComponent.istSignaturVorhanden()) {
            this.speichernDisabled = false;
            this.speichernButtonColor = 'success';
        } else {
            this.speichernDisabled = true;
            this.speichernButtonColor = 'light';
        }
    }

    async onSpeichern(e: Event) {
        ClickHelper.click(e, () => this.doSpeichern());
    }

    async doSpeichern() {
        App.loading(true);

        try {
            if (this.unterschriftComponent.istSignaturVorhanden()) {
                const imageDataUrl = this.unterschriftComponent.getImageDataUrl();
                const imageData = await ImageHelper.dataUrlToImageData(imageDataUrl);
                const croppedCanvas = ImageHelper.cropImage(imageData);

                this.unterschrift.Bild = croppedCanvas.toDataURL();
                this.unterschrift.Data = this.unterschriftComponent.getSignaturData();
                this.unterschrift.Guid = Utils.uuid();
                this.unterschrift.Datum = moment().toISOString();
                this.unterschrift.Position = await this.systemService.getAktuelleGpsPosition('unterschrift', 60);
            } else {
                this.unterschrift.Bild = null;
                this.unterschrift.Data = null;
                this.unterschrift.Guid = null;
                this.unterschrift.Datum = null;
                this.unterschrift.Position = null;
            }
        } catch (err) {
            this.log.error('doSpeichern', err);
        }

        App.loading(false);

        this.modalController.dismiss(true);
    }

    onReset(e: Event) {
        ClickHelper.click(e, () => {
            this.unterschriftComponent.reset();
        });
    }
}
