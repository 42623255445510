<!-- <ion-content #content>

</ion-content> -->

<ion-card>
    <ion-card-header>
      <ion-card-title>UVV-Prüfung</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <!-- <div class="icon-outer">
        <img src="/assets/icon/task.png" class="task-icon" />
      </div> -->
      
      <div class="section">
        <p class="text">
          Für {{ bezeichnung }} ist eine UVV-Prüfung  innerhalb der nächsten <b [class.red]="restTage < 0" [class.orange]="restTage < 30 && restTage >= 0">{{ restTage }} Tage</b> notwendig.
        </p>
      </div>

      <div>
        <ion-button expand="full" color="primary" appClick (clicked)="onAnzeigen($event)">Jetzt prüfen</ion-button>
        <ion-button expand="full" color="dark" fill="clear" appClick (clicked)="onAblehnen()">Prüfung ablehnen</ion-button>
      </div>
    </ion-card-content>
  </ion-card>

<!-- 
isVisible = false;
bezeichnung = '';
datumNaechstePruefung = '';
restTage = 0;
abgelehnt = true; -->