import { Druckformular, Auftrag, Fahrzeug, Personal, ReportElement, ReportElementType, TextAlignType, Auftragsposition, BarcodeType, FontStyleType } from 'src/app/api/model/swagger-model';

import * as moment from 'moment';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { Utils } from 'src/app/api/helper/utils';
import { DruckerHelper } from './drucker-helper';
import * as JsBarcode from 'jsbarcode';
import { AuftragEx } from 'src/app/api/model/model';

const log = new Logger('HtmlDrucker');

function ErsetzePlatzhalter(str: string, platzhalter: string, wert: any): string {
    return Ersetze(str, '{' + platzhalter + '}', wert);
}

function Ersetze(str: string, suchwert: string, wert: any): string {
    let result = str;
    let ttl = 10;

    do {
        if (wert === null || typeof (wert) === 'undefined') {
            wert = '';
        }

        result = str.replace(suchwert, wert);

        if (result === str) {
            // Nichts mehr ersetzt
            break;
        }
    } while (ttl-- > 0);

    return result;
}

function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export class HtmlDrucker {
    defaultFontSize = 20;

    constructor(
        private druckformular: Druckformular,
        private auftrag: AuftragEx,
        private fahrzeug: Fahrzeug,
        private fahrer: Personal) {

        if (!druckformular) {
            throw new Error('Parameter druckformular darf nicht null sein')
        }
    }

    async erstelleHtml(): Promise<any> {
        if (!this.auftrag) {
            throw new Error('Parameter auftrag darf nicht null sein')
        }

        let deltaY = 0;

        const breite = this.druckformular.LabelWidth;
        const hoehe = 29.7;

        const style = `<style>
            body {
                width: ${breite}mm;
                height: ${hoehe}mm;
                margin: 5mm 5mm 5mm 5mm;
            }

            html { width: 100% }
            .element { position: fixed; }
            .text { position: fixed; overflow: visible; white-space: nowrap; }
            .textblock { position: fixed; overflow: visible; white-space: pre-wrap; }
            .image { object-fit: contain; }
            </style>`;

        let html = '';

        const druckformular = this.druckformular;
        const auftrag = this.auftrag;
        const now = moment();

        if (!auftrag.Abholadresse) {
            auftrag.Abholadresse = {};
        }

        if (!auftrag.Erzeugeradresse) {
            auftrag.Erzeugeradresse = {};
        }

        if (!auftrag.Verwerteradresse) {
            auftrag.Verwerteradresse = {};
        }

        if (!this.fahrzeug) {
            this.fahrzeug = {};
        }

        if (!this.fahrer) {
            this.fahrer = {};
        }

        const platzhalter = DruckerHelper.erstellePlatzhalter(auftrag, this.fahrer, this.fahrzeug, null);

        if (druckformular.Header && druckformular.Header.Model && druckformular.Header.Model.Elements) {
            for (const reportElement of druckformular.Header.Model.Elements) {
                html += this.loadElement(reportElement, deltaY, platzhalter);
            }

            deltaY += druckformular.Header.Height;
        }


        if (auftrag.Positionen && druckformular.Details && druckformular.Details.Model) {
            for (const position of auftrag.Positionen) {
                if (position.Menge) {
                    const posPlatzhalter = DruckerHelper.erstellePlatzhalter(auftrag, this.fahrer, this.fahrzeug, position);

                    for (const reportElement of druckformular.Details.Model.Elements) {
                        html += this.loadElement(reportElement, deltaY, posPlatzhalter, position);
                    }

                    deltaY += druckformular.Details.Height;
                }
            }
        }

        if (druckformular.Footer && druckformular.Footer.Model && druckformular.Footer.Model.Elements) {
            for (const reportElement of druckformular.Footer.Model.Elements) {
                html += this.loadElement(reportElement, deltaY, platzhalter);
            }

            deltaY += druckformular.Footer.Height;
        }

        html = `<html><head>${style}</head><body>${html}</body></html>`;

        return html;
    }

    loadElement(e: ReportElement, deltaY: number, platzhalter, position: Auftragsposition = null): string {
        log.debug('loadElement', e);

        if (!DruckerHelper.isDruckbedingungOk(e, this.auftrag, this.fahrer, this.fahrzeug, position)) {
            return '';
        }

        const y = (e.Y + deltaY) / this.druckformular.Dpmm;
        const x = e.X / this.druckformular.Dpmm;
        const width = e.Width / this.druckformular.Dpmm;
        const height = e.Height / this.druckformular.Dpmm;

        let html = '';

        let fontStyle = '';
        let textDecoration = '';
        let fontWeight = '';

        switch (e.FontStyle) {
            case FontStyleType.Fett:
                fontWeight = 'font-weight: bold;';
                break;
            case FontStyleType.Kursiv:
                fontStyle = 'font-style: italic;';
                break;
            case FontStyleType.Unterstrichen:
                textDecoration = 'text-decoration: underline;';
                break;
            case FontStyleType.FettUnterstrichen:
                fontWeight = 'font-weight: bold;';
                textDecoration = 'text-decoration: underline;';
                break;
            case FontStyleType.KursivUnterstrichen:
                fontStyle = 'font-style: italic;';
                textDecoration = 'text-decoration: underline;';
                break;
            case FontStyleType.FettKursiv:
                fontWeight = 'font-weight: bold;';
                fontStyle = 'font-style: italic;';
                break;
        }

        let style = `left: ${x}mm; top: ${y}mm; width: ${width}mm; height: ${height}mm; ${fontStyle} ${fontWeight} ${textDecoration} text-align: ${this.getTextAlign(e.TextAlign)}; text-align: ${this.getTextAlign(e.TextAlign)}; font-family: '${this.getTextAlign(e.TextAlign)}'; `;

        if (e.FontSize) {
            style += ` font-size: ${e.FontSize}; `;
        }

        const text = this.formatTextbockText(e.Content, platzhalter);

        switch (e.Typ) {
            case ReportElementType.Textblock:
                html = `<div class="element textblock" style="${style}">${escapeHtml(text)}</div>`
                break;

            case ReportElementType.Text:
                html = `<div class="element text" style="${style}">${escapeHtml(text)}</div>`
                break;

            case ReportElementType.Barcode:
                html = this.loadBarcodeElement(e, text, style);
                break;

            case ReportElementType.Rect:
                html = `<div class="element rect" style="${style}; background-color: #000"></div>`
                break;

            case ReportElementType.Image:
                let imageDataUrl;

                switch (e.Content) {
                    case 'unterschrift-erz':
                        imageDataUrl = this.getUnterschriftBild('ERZ');
                        break;
                    case 'unterschrift-bef':
                        imageDataUrl = this.getUnterschriftBild('BEF');
                        break;
                    case 'unterschrift-ent':
                        imageDataUrl = this.getUnterschriftBild('ENT');
                        break;
                    case 'custom':
                        imageDataUrl = e.ImageData;
                        break;
                };

                if (imageDataUrl) {
                    html = `<img class="element image" style="${style}" src="${imageDataUrl}" />`
                }
                break;

            // default:
            //     object = null;
            //     log.error('ReportElementType nicht implementiert: ' + e.Typ);
        }

        return html;
    }

    private loadBarcodeElement(e: ReportElement, text: string, style: string): string {
        let format: string;

        switch (e.BarcodeType) {
            case BarcodeType.Ean8:
                format = "EAN-8";
                break;
            case BarcodeType.UPC:
                format = "UPC";
                break;
            case BarcodeType.Code128:
                format = "CODE128";
                break;
            default:
                format = "CODE39";
                break;
        }

        let width = e.ModuleWidth;

        if (!width) {
            width = 2;
        }

        const displayValue = e.PrintInterpolationLine === 'Y';
        let textPosition = 'bottom';

        if (e.PrintInterpolationLineAboveCode == 'Y') {
            textPosition = 'top';
        }

        const canvas = document.createElement('canvas');

        JsBarcode(canvas, text, {
            format,
            height: e.Height,
            width,
            displayValue,
            textPosition
        });

        const imageDataUrl = canvas.toDataURL();

        return `<img class="element image" style="${style}" src="${imageDataUrl}" />`
    }

    private getUnterschriftBild(rolle: string) {
        if (!this.auftrag.Unterschriften) {
            return null;
        }

        const unterschrift = this.auftrag.Unterschriften.find(p => p.Typ === rolle);

        if (unterschrift) {
            const bild = unterschrift.Bild;

            if (bild) {
                if (bild.startsWith('data:')) {
                    return bild;
                }
            } else if (unterschrift.Guid) {
                // TODO
            }
        }

        return null;
    }

    private formatTextbockText(text: string, platzhalter: any): string {
        if (!text) {
            text = '';
        }

        if (platzhalter && text && text.indexOf('{') >= 0) {
            for (const key in platzhalter) {
                if (platzhalter.hasOwnProperty(key)) {
                    let value = platzhalter[key];

                    if (typeof (value) === 'undefined' || value == null || value === 'undefined') {
                        value = '';
                    }

                    text = ErsetzePlatzhalter(text, key, value)
                }
            }
        }

        return text;
    }

    getTextAlign(textAlign: TextAlignType): string {
        switch (textAlign) {
            case TextAlignType.Left:
                return 'left';
            case TextAlignType.Center:
                return 'center';
            case TextAlignType.Right:
                return 'right';
            case TextAlignType.Justified:
                return 'justified';
            default:
                return 'left';
        }
    }

    getFontFamily(font: string) {
        switch (font) {
            case '0':
                return 'Arial';

            case 'A':
                return 'Times New Roman';

            default:
                return 'Arial';
        }
    }
}