

export class Constants {
    static ANGEMELDETES_FAHRZEUG = "angemeldetes-fahrzeug";
    static ANGEMELDETES_FAHRZEUG_DATE = "angemeldetes-fahrzeug-date";
    static ANGEMELDETER_ANHAENGER = "angemeldeter-anhaenger";
    static ANGEMELDETER_ANHAENGER_DATE = "angemeldeter-anhaenger-date";
    static LETZTES_ANGEMELDETES_FAHRZEUG = "letztes-angemeldetes-fahrzeug";
    static LETZTER_ANGEMELDETER_ANHAENGER = "letzter-angemeldeter-anhaenger";

    static AUFTRAEGE = 'auftraege';

    static dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
}
