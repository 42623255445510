import { Logger } from './app-error-logger';
import { AuftragsFormular, ReCoMobilBild } from '../model/swagger-model';

import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { v4 as uuidv4 } from 'uuid';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Barcode } from '../model/model';
import { IonInput } from '@ionic/angular';

const log = new Logger("Utils");

export class Utils {
    static wildcardRegexMap = new Map<string, RegExp>();

    static isAdHocTourKey(tourKey: string) {
        // 3b97502c-c260-426e-9a15-100308236f2a
        if (tourKey && tourKey.length >= 30) {
            return true;
        }

        return false;
    }

    static formatDatum(str: string) {
        if (!str) {
            return '';
        }

        if (str.length <= 10) {
            return str;
        }

        try {
            const m = moment(str);
            return m.format('DD.MM.YYYY');
        } catch (err) {
            log.warn(Utils.getErrorMessage(err), err);
            return str;
        }
    }

    static getAktuellesDatum() {
        return moment().format('DD.MM.YYYY');
    }

    static getAktuelleUhrzeit() {
        return moment().format('HH:mm');
    }

    static formatUhrzeit(str: string) {
        if (!str) {
            return '';
        }

        if (str.length <= 10) {
            return str;
        }

        try {
            const m = moment(str);
            return m.format('HH:mm');
        } catch (err) {
            log.warn(Utils.getErrorMessage(err), err);
            return str;
        }
    }

    static focusNextInputElement() {
        const nextEl = Utils.findNextTabStop(document.activeElement);

        if (nextEl) {
            nextEl.focus();
        }
    }

    static findNextTabStop(el) {
        try {
            var universe = document.querySelectorAll(
                "input, button, select, textarea, a[href]"
            );

            var list = Array.prototype.filter.call(universe, function (item) {
                return item.tabIndex >= "0";
            });

            var index = list.indexOf(el);
            return list[index + 1] || list[0];
        } catch (err) {
            return null;
        }
    }

    static setFocus(input: IonInput) {
        if (input) {
            input.setFocus();
        } else {
            log.warn('setFocus nicht möglich');
        }
    }

    static formatFloat(value: number, decimais: number): string {
        if (typeof value === 'number') {
            return (Math.round(value * 100) / 100).toFixed(2);;
        }

        return value as any;
    }

    static clone<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

    static isTrue(obj: any) {
        if (typeof obj === 'string') {
            obj = obj.toLowerCase();
        }

        return obj === true || obj === 'ja' || obj === 'true' || obj === 'x' || obj === 'y' || obj === 'j';
    }

    static isFalse(obj: any) {
        if (typeof obj === 'string') {
            obj = obj.toLowerCase();
        }

        return obj === false || obj === 'nein' || obj === 'false' || obj === 'n';
    }

    static isGS1Barcode(barcode: Barcode): boolean {
        let isGS1 = false;

        if (barcode.format && barcode.format.startsWith('GS1')) {
            isGS1 = true;
        }

        if (barcode.source === 'ZEBRA' || barcode.source === 'SIMULATION') {
            if (barcode.format.includes('EAN128') || barcode.format.includes('LABEL-TYPE-DATAMATRIX')) {
                isGS1 = true;
            }
        }

        return isGS1;
    }

    static webView: WebView;

    static replaceAll(str: string, search: string, replacement: string) {
        return str.split(search).join(replacement);
    }

    static uuid() {
        return uuidv4();
    }

    static getImgSrc(base64: string): string {
        if (base64 && !base64.startsWith('data:')) {
            return "data:image/png;base64," + base64;
        } else {
            return base64;
        }
    }

    static entferneHtmlTags(text: string): string {
        if (!text) {
            return '';
        }

        text = text
            .replace(/<p>/g, "")
            .replace(/<\/p>/g, "\n")
            .replace(/<br \/>/g, "\n");

        return text;

        // let tmp = document.createElement("DIV");
        // tmp.innerHTML = text;
        // return tmp.textContent || tmp.innerText || "";

        // text = text
        //     .replace(/<p>/g, "")
        //     .replace(/<\/p>/g, "\n")
        //     .replace(/<br \/>/g, "\n");

        // return text;
    }

    static isNumeric(str: string) {
        if (typeof (str) === 'number') {
            return true;
        }

        if (typeof (str) === 'string') {
            return /^\d+$/.test(str);;
        }

        return false;
    }

    static parseInt(obj: any): number {
        if (typeof (obj) === 'number') {
            return obj;
        }

        if (typeof (obj) === 'string') {
            const str = obj.trim().replace(',', '.');

            if (str.length === 0) {
                return null;
            }

            const value = parseInt(str, 10);

            if (isNaN(value)) {
                return obj as any;
            }

            return value;
        }

        return null;
    }

    static parseIntMitDefault(obj: any, defaultValue: number): number {
        if (typeof (obj) === 'number') {
            return obj;
        }

        if (typeof (obj) === 'string') {
            const str = obj.trim().replace(',', '.');

            if (str.length === 0) {
                return defaultValue;
            }

            const value = parseInt(str, 10);

            if (isNaN(value)) {
                return defaultValue;
            }

            return value;
        }

        return defaultValue;
    }

    static parseFloat(obj: any): number {
        if (typeof (obj) === 'number') {
            return obj;
        }

        if (typeof (obj) === 'string') {
            const str = obj.trim().replace(',', '.');

            if (str.length === 0) {
                return null;
            }

            const value = parseFloat(str);

            if (isNaN(value)) {
                return obj as any;
            }

            return value;
        }

        return null;
    }

    static escapeHtml(unsafe: string) {
        if (!unsafe) {
            return '';
        }

        if (typeof (unsafe) !== 'string') {
            return unsafe;
        }

        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    static nowIsoDateString(): string {
        return moment().toISOString();
    }

    static nowGermanDateString(): string {
        return moment().format('DD.MM.YYYY');
    }

    static nowGermanDateTimeString(): string {
        return moment().format('DD.MM.YYYY HH:mm');
    }

    static nowIsoDateOnlyString(): string {
        return moment().format('YYYY-MM-DD');
    }

    static toIsoDateString(str: string): string {
        if (str) {
            if (str.match(/\d\d\.\d\d\.\d\d\d\d/g)) {
                const isoStr = moment(str, 'DD.MM.YYYY').toISOString();

                log.debug(`Datum konvertiert von '${str}' nach '${isoStr}'`);

                return isoStr;
            }
        }

        return str;
    }

    static isGueltigesDatum(str: string): boolean {
        if (!str) {
            return null;
        }

        if (typeof (str) === 'string') {
            if (str.match(/^\d\d\.\d\d\.\d\d\d\d/g)) {
                return true;
            }

            if (str.match(/^\d\d\d\d\-\d\d\-\d\d.*/g)) {
                return true;
            }
        }

        return false;
    }

    static parseDatum(str: string): moment.Moment {
        if (!str) {
            return null;
        }

        if (str.match(/\d\d\.\d\d\.\d\d\d\d/g)) {
            return moment(str, 'DD.MM.YYYY');
        }

        return moment(str);
    }

    static parseUhrzeit(str: string): moment.Moment {
        if (!str) {
            return null;
        }

        if (str.match(/\d\d\:\d\d\:\d\d/g)) {
            return moment(str, 'HH:mm:ss');
        }

        if (str.match(/\d\d\:\d\d/g)) {
            return moment(str, 'HH.mm');
        }

        return moment(str);
    }

    static distinct<T>(arr: Array<T>): Array<T> {
        return arr.filter((value, index, self) => {
            return self.indexOf(value) == index;
        });
    }

    static stripDotted(str: string, maxLength: number): string {
        if (!str) {
            return '';
        }

        if (maxLength < 3) {
            return '...';
        }

        if (str.length < maxLength) {
            return str;
        }

        return str.substr(0, maxLength - 3) + '...';
    }

    static trim(str: string) {
        if (!str) {
            return '';
        }

        return str.trim();
    }

    static trimToEmpty(str: string): string {
        if (typeof (str) === 'undefined' || str === null) {
            return "";
        }

        if (typeof (str) === 'string') {
            return str.trim();
        }

        if (typeof (str) === 'number') {
            return (str as number).toString();
        }

        return "";
    }

    static toUpperCase(str: string): string {
        if (str && typeof (str) === 'string') {
            return str.toUpperCase();
        }

        return str;
    }

    static trimToNull(str: string): string {
        str = Utils.trimToEmpty(str);

        if (!str) {
            return null;
        }

        return str;
    }

    static getString(zahl?: number) {
        if (zahl == null) {
            return '';
        }

        return zahl.toString();
    }

    static delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static getErrorMessage(e: any, alsJson = false): string {
        if (!e) {
            return '';
        } else if (e instanceof Error) {
            return e.message;
        } else {
            if (alsJson) {
                if (typeof (e) === 'string') {
                    return e;
                } else {
                    return JSON.stringify(e);
                }
            }

            return e;
        }
    }

    static insert(arr: any[], index: number, item: any) {
        arr.splice(index, 0, item);
    }

    static removeFromArray(arr: any[], index: number) {
        arr.splice(index, 1);
    }

    static areEqual(a: any, b: any, ...ignoreProperties: string[]) {
        if (a == b) {
            return true;
        }

        if (!a || !b) {
            return false;
        }

        // Create arrays of property names
        const aProps = Object.getOwnPropertyNames(a);
        const bProps = Object.getOwnPropertyNames(b);

        if (ignoreProperties) {
            for (const propName of ignoreProperties) {
                const index1 = aProps.indexOf(propName);

                if (index1 >= 0) {
                    aProps.splice(index1, 1);
                }

                const index2 = bProps.indexOf(propName);

                if (index2 >= 0) {
                    bProps.splice(index2, 1);
                }
            }
        }

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length != bProps.length) {
            log.debug(`Elemente haben unterschiedliche Anzahl an Properties: ${aProps.length} != ${bProps.length}`);

            return false;
        }

        for (const propName of aProps) {
            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                log.debug(`Properties sind unterschiedlich: ${a[propName]} != ${b[propName]}`);
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    }

    static getEinstellung(formularKonfiguration: AuftragsFormular, komponenteName: string, key: string, defaultValue: string): string {
        if (formularKonfiguration && formularKonfiguration.komponenten) {
            const komponente = formularKonfiguration.komponenten.find(p => p.name === komponenteName);

            if (komponente) {
                const einstellung = komponente.einstellungen.find(p => p.key === key);

                if (einstellung) {
                    if (einstellung.wert !== null && einstellung.wert !== '' && einstellung.wert !== undefined) {
                        return einstellung.wert.trim();
                    }
                }
            }
        }

        return defaultValue;
    }

    static groupBy<T>(xs: T[], key: string): { [key: string]: T[] } {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    static normalisiereTelefonnummer(telNummer: string): string {
        if (!telNummer) {
            return telNummer;
        }

        // Entferne alle ungültigen Zeichen
        telNummer = telNummer.replace(/[^\d^\+]+/g, '');

        if (telNummer.startsWith('00') || telNummer.startsWith('+')) {
            // FOrmat passt bereits
            return telNummer;
        }

        if (telNummer.startsWith('0')) {
            return '+49' + telNummer.substr(1);
        }

        return telNummer;
    }

    static next<T>(subject: BehaviorSubject<T>, value: T) {
        if (subject.getValue() !== value) {
            subject.next(value);
        }
    }

    static split(separators: string[], str: string, removeEmptyLines = true): string[] {
        if (!str) {
            return [];
        }

        let result: string[] = [];

        if (separators.length) {
            // We can use the first token as a temporary join character
            let tempChar = separators[0];

            for (var i = 1; i < separators.length; i++) {
                str = str.split(separators[i]).join(tempChar);
            }

            result = str.split(tempChar);
        } else {
            result = [str];
        }

        result = result.map(p => p.trim());

        if (removeEmptyLines) {
            result = result.filter(p => p != null && p !== '');
        }

        return result;
    }

    static isWildcardMatch(str: string, pattern: string) {
        if (!str || !pattern) {
            return false;
        }

        let regex = Utils.wildcardRegexMap.get(pattern);

        if (!regex) {
            pattern = Utils.replaceAll(pattern, '*', '.*');

            if (!pattern.startsWith('.*')) {
                pattern = '^' + pattern;
            }

            if (!pattern.endsWith('.*')) {
                pattern = pattern + "$";
            }

            regex = new RegExp(pattern);

            Utils.wildcardRegexMap.set(pattern, regex);
        }

        return regex.test(str);
    }
    
    static random(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    /**
     * https://gist.github.com/jasonrhodes/2321581
     */
    static getFieldValue(obj: any, dataField: string): any {
        if (!obj || !dataField) {
            return null;
        }

        if (!dataField.includes('.')) {
            return obj[dataField];
        }

        const arr = dataField.split('.');
        while (arr.length && (obj = obj[arr.shift()]));
        return obj;
    }

    static ersetzePlatzhalter(format: string, entity: any): string {
        let text = '';

        if (format) {
            text = format;

            // Ersetze Platzhalter
            let ttl = 10;

            do {
                let pos1 = text.indexOf('{');

                if (pos1 < 0) {
                    break;
                }

                let pos2 = text.indexOf('}', pos1 + 1);

                if (pos2 < 0) {
                    break;
                }

                let key = text.substring(pos1 + 1, pos2);
                let optional = false;

                if (key.endsWith('?')) {
                    // Optional. Nur wenn gefüllt (z.B. Montagereihenfolge = 0 nicht anzeigen)
                    key = key.substring(0, key.length - 1);
                    optional = true;
                }

                let wert: any = '';

                if (entity) {
                    wert = Utils.getFieldValue(entity, key);

                    if (wert == null) {
                        if (entity.CustomValues) {
                            wert = entity.CustomValues[key];

                            if (wert != null) {
                                return wert;
                            }
                        }
                    }

                    if (optional) {
                        if (!wert || wert == '0' || wert == 0) {
                            wert = '';
                        }
                    }
                }

                text = text.substring(0, pos1) + wert + text.substring(pos2 + 1);

            } while (ttl-- > 0);
        }

        return text;
    }

    static joinNotEmpties(separator: string, ...werte: string[]) {
        werte = werte.filter(p => p != null && typeof (p) === 'string' && p.length > 0 && p.trim().length > 0);

        return werte.join(separator);
    }

    /**
     * Enbtfernt einen eventuell vorhandenen / am Ende der URL
     */
    static normalizeUrl(url: string): string {
        url = Utils.trim(url).toLowerCase();

        if (url.endsWith('/')) {
            url = url.substring(0, url.length - 1);
        }

        return url;
    }
}
