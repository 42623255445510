import { Component, OnInit, ViewChild, Renderer2, AfterViewInit, OnDestroy, ElementRef, Output, EventEmitter } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { App } from 'src/app/api/helper/app';
import { AppConfig } from 'src/app/api/helper/app.config';
import { Point, SignaturePad } from '../signature-pad/signature-pad.component';

@Component({
    selector: 'app-unterschrift',
    templateUrl: './unterschrift.component.html',
    styleUrls: ['./unterschrift.component.scss'],
})
export class UnterschriftComponent implements OnInit, AfterViewInit, OnDestroy {
    private log = new Logger("UnterschriftComponent");

    @ViewChild('sigPad', { read: SignaturePad, static: true }) signaturePad: SignaturePad;
    @ViewChild('outer', { static: true }) outer: ElementRef;

    @Output() signaturVorhanden = new EventEmitter<boolean>();

    signaturePadOptions = {
        minWidth: App.current.signaturMinWidth,
        maxWidth: App.current.signaturMaxWidth,
        canvasWidth: 500,
        canvasHeight: 300,
        backgroundColor: 'white'
    };

    private updateViewInterval: any;
    private lastWidth: number;
    private lastHeight: number;
    private unterschriftseiteDrehen = false;

    private zeichnungVorhanden = false;
    private resizeSubscription: Subscription;

    private originalOrientation = '';

    constructor(public platform: Platform, public renderer: Renderer2, public screenOrientation: ScreenOrientation) {
        this.resizeSubscription = this.platform.resize.subscribe(() => this.onResize());
    }

    async ngOnInit() {
        try {
            App.current.klingelnBlockiert.next(true);

            this.unterschriftseiteDrehen = AppConfig.current.einstellungen.UnterschriftseiteDrehen === 'ja';

            if (this.unterschriftseiteDrehen && this.screenOrientation && App.isCordovaAvailable()) {
                // Merke die aktuelle Ausrichtung
                this.originalOrientation = this.screenOrientation.type;

                await this.screenOrientation.lock("landscape");
            }
        } catch (err) {
            this.log.warn(err);
        }
    }

    async ngOnDestroy() {
        if (this.updateViewInterval) {
            clearInterval(this.updateViewInterval);
        }

        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }

        App.current.klingelnBlockiert.next(false);

        if (this.unterschriftseiteDrehen && this.screenOrientation && App.isCordovaAvailable()) {
            // originale Ausrichtugn wiederherstellen
            if (this.originalOrientation && this.originalOrientation.startsWith("portrait")) {
                await this.screenOrientation.lock('portrait');
            }

            await this.screenOrientation.unlock();
        }
    }

    ngAfterViewInit() {
        this.log.debug('signaturePad', this.signaturePad);

        this.updateViewInterval = setInterval(() => {
            if (this.lastWidth !== this.outer.nativeElement.clientWidth ||
                this.lastHeight !== this.outer.nativeElement.clientHeight) {

                this.lastWidth = this.outer.nativeElement.clientWidth;
                this.lastHeight = this.outer.nativeElement.clientHeight;

                this.onResize();
            }
        }, 100);

        this.reset();

        setTimeout(() => {
            this.onResize();
        }, 10);
    }

    getSignaturePad(): SignaturePad {
        return this.signaturePad;
    }

    onResize(): void {
        this.log.debug('onResize');

        if (this.outer && !this.zeichnungVorhanden) {
            const signaturePad = this.getSignaturePad();

            signaturePad.set('minWidth', App.current.signaturMinWidth);
            signaturePad.set('maxWidth', App.current.signaturMaxWidth);
            signaturePad.set('canvasWidth', this.outer.nativeElement.clientWidth);
            signaturePad.set('canvasHeight', this.outer.nativeElement.clientHeight);
            // this.signaturePad.resizeCanvas();
        }
    }

    drawComplete() {
        this.log.debug('drawComplete');
        this.zeichnungVorhanden = true;
        this.signaturVorhanden.next(true);
        // will be notified of szimek/signature_pad's onEnd event
        // console.log(this.signaturePad.toDataURL());
    }

    drawStart() {
        this.log.debug('drawStart');
        // will be notified of szimek/signature_pad's onBegin event
        // console.log('begin drawing');
    }

    reset() {
        this.zeichnungVorhanden = false;
        this.signaturVorhanden.next(false);

        this.getSignaturePad().clear();
        this.onResize();
    }

    istSignaturVorhanden() {
        return this.zeichnungVorhanden;
    }

    getSignaturData(): Point[][] {
        const signaturePad = this.getSignaturePad();

        if (this.zeichnungVorhanden) {
            this.log.info('signatur data', signaturePad.toData());
            return signaturePad.toData();
        }

        return null;
    }

    getSvgImage(): string {
        const signaturePad = this.getSignaturePad();

        if (this.zeichnungVorhanden) {
            return signaturePad.toDataURL("image/svg+xml");
        }

        return null;
    }

    getImageDataUrl(): string {
        const signaturePad = this.getSignaturePad();

        if (this.zeichnungVorhanden) {
            // this.log.debug('getImageDataUrl', this.signaturePad.toDataURL());
            return signaturePad.toDataURL(); // PNG
        }

        // if (this.zeichnungVorhanden) {
        //     const ctx = this.canvasElement.getContext('2d');

        //     let x = this.minX - 5;
        //     if (x < 0) {
        //         x = 0;
        //     }

        //     let y = this.minY - 5;
        //     if (y < 0) {
        //         y = 0;
        //     }

        //     const width = this.maxX - x + 5;
        //     const height = this.maxY - y + 5;

        //     return ctx.getImageData(x, y, width, height);
        // }

        return null;
    }

}
