import { Injectable } from '@angular/core';
import { AuftragDto, AppLoginRequest, SendResult, SaveResult, PushRegistrationRequest, HostInfo, GlasAnalyseEx } from './model/model';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from './helper/app.config';
import { AppErrorHandler } from './helper/app-error-handler';
import { Logger } from './helper/app-error-logger';
import { Personal, Fahrzeug, Textdatei, AppStammdatenResult, UvvSyncResult, DokumenteSyncRequest, DokumenteSyncResult, ChangeLogResult, Nachricht, Tour, AppTourStartRequest, AppTourStartResult, Fahrzeuggruppe, AppAuftragZuweisenRequest, Waage, WiegenRequest, WiegenResult, AppLoginResult, AuftragHeader, SmartwatchKoppelnResult, DownloadAuftraegeRequest, GeraetDto, AuftragWeiterleitenRequest, AuftragWeiterleitenResult, SucheAdressenResult, SucheAdressenRequest, SucheBestellungenRequest, SucheBestellungenResult, AppUpdateInfo, AppTexteResult, SucheVertraegeResult, SucheVertraegeRequest, AuftragAnlegenResult, AuftragAnlegenRequest, Bericht, GetBerichteRequest, GetNVEResult, GetNVERequest, NVEMengenmeldungRequest, NVEMengenmeldungResult, AppLadeTourResult, AppLadeTourRequest, AppGetGlasAnalyseListeRequest, AppGetGlasAnalyseListeResult, GetTourStatusListeRequest, TourStatus, UvvSchnellpruefungRequest, UvvSchnellpruefungResult, AppGeraetegruppeWechselnRequest } from './model/swagger-model';
import { Utils } from './helper/utils';
import { HTTP } from '@ionic-native/http/ngx';
import { App } from './helper/app';

@Injectable({
    providedIn: 'root'
})
export class RemoteService {
    static instance: RemoteService = null;

    log = new Logger("RemoteService");

    hostListe: HostInfo[] = [];

    aktiverHost: HostInfo = null;

    letzterHostWechsel = 0;

    advancedHttpVerwenden = false;

    public static get apiUrl() { return AppConfig.current.host; }

    private static handleAjaxError(error: any) {
        RemoteService.instance.doHandleAjaxError(error);
    }

    public getAktiverHost(): HostInfo {
        if (!this.aktiverHost) {
            this.aktualisiereHostListe();
            this.aktiverHost = this.hostListe[0];
        }

        return this.aktiverHost;
    }

    constructor(public http: HttpClient, private advancedHttp: HTTP) {
        RemoteService.instance = this;

        this.aktualisiereHostListe();

        AppConfig.hostChanged.subscribe(() => {
            this.aktualisiereHostListe();
        });

        AppConfig.changed.subscribe(() => {
            if (AppConfig.current?.einstellungen) {
                this.advancedHttpVerwenden = AppConfig.current?.einstellungen.AdvancedHttpVerwenden === 'ja';

                if (this.advancedHttpVerwenden && !App.isCordovaAvailable()) {
                    this.advancedHttpVerwenden = false;
                }
            }
        })
    }

    private aktualisiereHostListe() {
        let host = AppConfig.current.host;

        if (host.endsWith("/")) {
            host = host.substr(0, host.length - 1);
        }

        let hostNamen = [host];

        if (host) {
            if (host.indexOf("//app.") > -1) {
                hostNamen = [];
                hostNamen.push(host.replace('//app.', '//app1.'));
                hostNamen.push(host.replace('//app.', '//app2.'));
                hostNamen.push(host.replace('//app.', '//app3.'));
                // hostNamen.push(host.replace('//app.', '//backup1.'));
            }
        }

        this.hostListe = [];

        for (const url of hostNamen) {
            this.hostListe.push({
                url: url,
                datumOnline: 0,
                failCounter: 0
            });
        }

        this.aktiverHost = this.hostListe[Math.floor(Math.random() * this.hostListe.length)];
    }

    ping(host: string): Promise<boolean> {
        if (!host.endsWith("/")) {
            host += '/';
        }

        const url = host + 'api/home/status';
        return this.getJson(url);
    }

    checkUpdate(): Promise<AppUpdateInfo> {
        return this.getJson('/api/app/check-update');
    }

    status(): Promise<boolean> {
        return this.getJson('/api/home/status');
    }

    getAuftragById(id: string): Promise<AuftragDto> {
        return this.getJson('/api/auftrag/' + id);
    }

    getAuftragByKey(key: string): Promise<AuftragDto> {
        return this.getJson('/api/auftrag-by-key/' + key);
    }

    downloadAuftraege(request: DownloadAuftraegeRequest): Promise<AuftragDto[]> {
        return this.postJson('/api/auftrag/download', request);
    }

    getAktuelleAuftragHeaders(): Promise<AuftragHeader[]> {
        return this.getJson('/api/auftrag/aktuelle-auftrag-header');
    }

    aesTest(): Promise<string> {
        return this.getText('/api/home/aestest');
    }

    login(loginRequest: AppLoginRequest): Promise<AppLoginResult> {
        return this.postJson('/api/app/login', loginRequest);
    }

    wechsleGeraetegruppe(request: AppGeraetegruppeWechselnRequest): Promise<AppLoginResult> {
        return this.postJson('/api/app/wechsle-geraetegruppe', request);
    }

    sendTextdateiZipped(textdatei: ArrayBuffer): Promise<SendResult> {
        return this.postRaw('/api/app/textdatei', textdatei);
    }

    send(textdatei: Textdatei): Promise<SendResult> {
        return this.postJson('/api/app/send', textdatei);
    }

    registerPush(request: PushRegistrationRequest): Promise<SaveResult> {
        return this.postJson('/api/app/push-registration', request);
    }

    // getStammdaten(): Promise<AppStammdatenResult> {
    //     return this.getJson('/api/app/stammdaten');
    // }

    syncStammdaten(typ: string, timestamp: number): Promise<AppStammdatenResult> {
        return this.getJson(`/api/app/sync-stammdaten/${typ}/${timestamp}`);
    }

    getAlleFahrer(): Promise<Personal[]> {
        return this.getJson('/api/app/fahrer');
    }

    getAlleGeraete(): Promise<GeraetDto[]> {
        return this.getJson('/api/app/geraete');
    }

    getFahrerByRfid(rfid): Promise<Personal> {
        return this.getJson('/api/app/fahrer-by-rfid/' + rfid);
    }

    getFahrerByPersonalnummer(personalnummer): Promise<Personal> {
        return this.getJson('/api/app/fahrer-by-personalnummer/' + personalnummer);
    }

    getAlleSmartwatches(): Promise<Fahrzeug[]> {
        return this.getJson('/api/app/smartwatches');
    }

    koppelnSmartwatch(geraeteId: string): Promise<SmartwatchKoppelnResult> {
        return this.postJson('/api/app/smartwatch-koppeln/' + geraeteId, {});
    }

    entkoppelnSmartwatch(): Promise<SmartwatchKoppelnResult> {
        return this.postJson('/api/app/smartwatch-entkoppeln', {});
    }

    getAlleFahrzeuge(): Promise<Fahrzeug[]> {
        return this.getJson('/api/app/fahrzeuge');
    }

    getAlleFahrzeuggruppen(): Promise<Fahrzeuggruppe[]> {
        return this.getJson('/api/app/fahrzeuggruppen');
    }

    syncUvv(timestamp: number): Promise<UvvSyncResult> {
        return this.getJson('/api/uvv/sync/' + timestamp);
    }

    syncDokumente(request: DokumenteSyncRequest): Promise<DokumenteSyncResult> {
        return this.postJson('/api/dokumente/sync', request);
    }

    getChangeLog(timestamp: number): Promise<ChangeLogResult> {
        return this.getJson('/api/app/changelog/all/' + timestamp);
    }

    getNeueNachrichten(fromTimestamp: number, maxCount: number = 100000): Promise<Nachricht[]> {
        return this.getJson(`/api/nachrichten/neue-nachrichten?fromTimestamp=${fromTimestamp}&maxCount=${maxCount}`);
    }

    getAktuelleTouren(): Promise<Tour[]> {
        return this.getJson('/api/app/aktuelle-touren');
    }

    ladeTour(request: AppLadeTourRequest): Promise<AppLadeTourResult> {
        return this.postJson('/api/app/lade-tour', request);
    }

    entladeTour(request: AppLadeTourRequest): Promise<AppLadeTourResult> {
        return this.postJson('/api/app/entlade-tour', request);
    }

    starteTour(request: AppTourStartRequest): Promise<AppTourStartResult> {
        return this.postJson('/api/app/starte-tour', request);
    }

    zuweisenAuftrag(request: AppAuftragZuweisenRequest): Promise<SaveResult> {
        return this.postJson('/api/auftrag/zuweisen', request);
    }

    getWaagen(lat: number, lon: number): Promise<Waage[]> {
        return this.getJson(`/api/app/get-waagen?lat=${lat}&lon=${lon}`);
    }

    wiegen(request: WiegenRequest): Promise<WiegenResult> {
        return this.postJson('/api/app/wiegen', request);
    }

    auftragWeiterleiten(request: AuftragWeiterleitenRequest): Promise<AuftragWeiterleitenResult> {
        return this.postJson('/api/app/auftrag-weiterleiten', request);
    }

    sucheAdressen(request: SucheAdressenRequest): Promise<SucheAdressenResult> {
        return this.postJson('/api/app/suche-adressen', request);
    }

    sucheBestellungen(request: SucheBestellungenRequest): Promise<SucheBestellungenResult> {
        return this.postJson('/api/app/suche-bestellungen', request);
    }

    sucheVertraege(request: SucheVertraegeRequest): Promise<SucheVertraegeResult> {
        return this.postJson('/api/app/suche-vetraege', request);
    }

    auftragAnlegen(request: AuftragAnlegenRequest): Promise<AuftragAnlegenResult> {
        return this.postJson('/api/app/auftrag-anlegen', request);
    }

    liveTextdatei(textdatei: Textdatei): Promise<Textdatei> {
        return this.postJson('/api/app/live-textdatei', textdatei);
    }

    getTexte(sprache: string): Promise<AppTexteResult> {
        return this.getJson('/api/app/texte/' + encodeURIComponent(sprache));
    }

    sendeBericht(bericht: Bericht): Promise<SaveResult> {
        return this.postJson('/api/app/sende-bericht', bericht);
    }

    loescheBericht(id: string): Promise<SaveResult> {
        return this.postJson('/api/app/delete-bericht/' + id, {});
    }

    getMeineBerichte(request: GetBerichteRequest): Promise<Bericht[]> {
        return this.postJson('/api/app/berichte', request);
    }

    getTourStatusListe(request: GetTourStatusListeRequest): Promise<TourStatus[]> {
        return this.postJson('/api/app/tourstatus-liste', request);
    }

    getNVE(request: GetNVERequest): Promise<GetNVEResult> {
        return this.postJson('/api/app/nve', request);
    }

    nveMengenmeldung(request: NVEMengenmeldungRequest): Promise<NVEMengenmeldungResult> {
        return this.postJson('/api/app/nve-mengenmeldung', request);
    }

    getGlasanlayseListe(request: AppGetGlasAnalyseListeRequest): Promise<AppGetGlasAnalyseListeResult> {
        return this.postJson('/api/glasanalyse/list', request);
    }

    speichereGlasAnalyse(textdatei: Textdatei): Promise<SaveResult> {
        return this.postJson('/api/glasanalyse', textdatei);
    }

    uvvSchnellpruefung(request: UvvSchnellpruefungRequest): Promise<UvvSchnellpruefungResult> {
        return this.postJson('/api/uvv/schnellpruefung', request);
    }

    /**
     * Headers für AJAX-Aufrufe die von anderer Stelle z.B. direkt über jQuery ausgeführt werden
     */
    // getAjaxHeaders() {
    //     const headers = {};
    //     const authToken = AppConfig.current.token; // = obj.Token; sessionStorage.getItem('auth_token');
    //     if (authToken) {
    //         headers["Authorization"] = `Bearer ${authToken}`;
    //     }

    //     headers["Language"] = this.getLanguage();
    //     return headers;
    // }

    private appendCacheBuster(url: string): string {
        if (url.indexOf('?') > 0) {
            url += '&';
        } else {
            url += '?';
        }

        url += '__=' + new Date().getTime().toString();
        return url;
    }

    public toAbsoluteUrl(url: string): string {
        if (url.startsWith("http")) {
            return url;
        } else {
            const host = this.getAktiverHost();

            if (!url.startsWith("/")) {
                url = "/" + url;
            }

            return host.url + url;
        }
    }

    private getText(url: string) {
        url = this.toAbsoluteUrl(this.appendCacheBuster(url));

        return this.get(url, 'text')
            .then(response => {
                return response;
            })
            .catch(RemoteService.handleAjaxError);
    }

    private getJson(url: string, throwOnError = false): Promise<any> {
        url = this.toAbsoluteUrl(this.appendCacheBuster(url));

        if (this.advancedHttpVerwenden) {
            this.log.debug('GET JSON ADVANCED HTTP: ' + url);

            const headers = {
                'Content-Type': 'application/json',
                'Language': this.getLanguage(),
                'X-Token': AppConfig.current.token
            };

            return new Promise((resolve, reject) => {
                this.advancedHttp.setDataSerializer('raw');

                this.advancedHttp.sendRequest(url, {
                    method: 'get',
                    headers: headers,
                    timeout: 600 * 1000 // 10 Minuten
                }).then(response => {
                    try {
                        this.log.debug('response', response);

                        const json = response.data;
                        const obj = JSON.parse(json);
                        resolve(obj);
                    } catch (err) {
                        const msg = `Fehler beim persen der Antwort von ${url}: ${Utils.getErrorMessage(err)}`;
                        this.log.warn(msg);

                        if (throwOnError) {
                            reject(new Error(msg))
                        } else {
                            resolve(null);
                        }
                    }
                }).catch(response => {
                    this.log.warn('response error: ' + JSON.stringify(response));

                    const msg = `Fehler beim Aufruf von ${url}: ${response.status} ${response.error}`;

                    RemoteService.instance.doHandleAjaxError(new Error(msg));

                    if (throwOnError) {
                        reject(new Error(msg))
                    } else {
                        resolve(null);
                    }
                });
            });
        } else {
            this.log.debug('GET JSON: ' + url);

            return this.get(url)
                .then(response => {
                    if (this.aktiverHost) {
                        this.aktiverHost.failCounter = 0;
                    }

                    return response;
                })
                .catch(RemoteService.handleAjaxError);
        }
    }

    private postJson(url: string, data: any): Promise<any> {
        url = this.toAbsoluteUrl(url);

        this.log.debug('postJson: ' + url, data);

        return this.post(url, data)
            .then(response => {
                if (this.aktiverHost) {
                    this.aktiverHost.failCounter = 0;
                }

                return response;
            })
            .catch(RemoteService.handleAjaxError);
    }

    /**
     * ACHTUNG: Im Fehlerfall wird das Promise rejected.
     * Andere Methoden geben einfach NULL / undefined zurück
     */
    private postRaw(url: string, bytes: ArrayBuffer): Promise<any> {
        url = this.toAbsoluteUrl(url);

        if (this.advancedHttpVerwenden) {
            this.log.debug('POST RAW ADVANCED HTTP: ' + url);

            const headers = {
                'Content-Type': 'application/octet-stream',
                'Language': this.getLanguage(),
                'X-Token': AppConfig.current.token
            };

            return new Promise((resolve, reject) => {
                this.advancedHttp.setDataSerializer('raw');

                this.advancedHttp.sendRequest(url, {
                    method: 'post',
                    data: bytes,
                    headers: headers,
                    timeout: 600 * 1000 // 10 Minuten
                }).then(response => {
                    try {
                        this.log.debug('response', response);

                        const json = response.data;
                        const obj = JSON.parse(json);
                        resolve(obj);
                    } catch (err) {
                        reject(new Error(`Fehler beim persen der Antwort von ${url}: ${Utils.getErrorMessage(err)}`));
                    }
                }).catch(response => {
                    this.log.warn('response error', response);
                    reject(new Error(`Fehler beim Aufruf von ${url}: ${response.status} ${response.error}`));
                });
            });
        } else {
            this.log.debug('POST RAW: ' + url);

            return new Promise((resolve, reject) => {
                const xhr: XMLHttpRequest = new XMLHttpRequest();
                let resolved = false;

                xhr.onreadystatechange = () => {
                    if (!resolved) {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                if (this.aktiverHost) {
                                    this.aktiverHost.failCounter = 0;
                                }

                                resolved = true;
                                resolve(JSON.parse(xhr.response));
                            } else if (xhr.status === 0 && !xhr.response) {
                                resolved = true;
                                reject('ERR_CONNECTION_REFUSED');
                            } else {
                                resolved = true;
                                reject(xhr.response);
                            }
                        }
                    }
                };

                xhr.open('POST', url, true);

                xhr.setRequestHeader('Content-Type', 'application/octet-stream')
                xhr.setRequestHeader('Language', this.getLanguage())
                xhr.setRequestHeader('X-Token', AppConfig.current.token)

                xhr.onerror = () => {
                    if (!resolved) {
                        resolved = true;
                        this.doHandleAjaxError(`Fehler beim Aufruf von POST ${url}`);
                        reject(xhr.response);
                    }
                }

                xhr.send(bytes);
            });
        }
    }

    // private putJson(url: string, data: any): Promise<any> {
    //     url = this.toAbsoluteUrl(url);

    //     this.log.debug('putJson: ' + url, data);

    //     return this.put(url, data)
    //         .then(response => {
    //             return response;
    //         })
    //         .catch(AppErrorHandler.handleAjaxError);
    // }

    // private deleteJson(url: string): Promise<DeleteResult> {
    //     url = this.toAbsoluteUrl(url);

    //     this.log.debug('deleteJson: ' + url);

    //     return this.delete(url)
    //         .then(response => {
    //             const result = response.json() as DeleteResult;
    //             if (!result.success) {
    //                 // TODO AppErrorHandler.instance.showError('Eintrag konnte nicht gelöscht werden: ' + result.Message);
    //             }
    //             return result;
    //         })
    //         .catch(AppErrorHandler.handleAjaxError);
    // }

    private get(url: string, responseType: any = "json"): Promise<any> {
        const headers = new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Language', this.getLanguage())
            .append('Cache-Control', 'no-cache')
            .append('Pragma', 'no-cache')
            .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
            .append('X-Token', AppConfig.current.token)
            .append('X-Geraet', AppConfig.current.geraeteNummer)
            .append('X-Mandant', AppConfig.current.mandant);

        const options = {
            headers,
            responseType
        };

        this.log.debug('options', options);

        return this.http.get(url, options).toPromise();
    }

    /**
     * Performs a request with `POST` http method.
     */
    private post(url: string, body: any): Promise<any> {
        if (this.advancedHttpVerwenden) {
            this.log.debug('POST ADVANCED HTTP: ' + url);

            const headers = {
                'Content-Type': 'application/json',
                'Language': this.getLanguage(),
                'X-Token': AppConfig.current.token
            };

            return new Promise((resolve, reject) => {
                this.advancedHttp.setDataSerializer('json');

                this.advancedHttp.sendRequest(url, {
                    method: 'post',
                    data: body,
                    headers: headers,
                    timeout: 600 * 1000 // 10 Minuten
                }).then(response => {
                    this.log.debug('response ' + url, response);

                    try {
                        const json = response.data;
                        const obj = JSON.parse(json);
                        resolve(obj);
                    } catch (err) {
                        const msg = `Fehler beim persen der Antwort von ${url}: ${Utils.getErrorMessage(err)}`;

                        this.log.warn(msg);
                        reject(new Error(msg));
                    }
                }).catch(response => {
                    this.log.warn('response error ' + url, response);

                    reject(new Error(`Fehler beim Aufruf von ${url}: ${response.status} ${response.error}`));
                });
            });
        } else {
            this.log.debug('POST: ' + url);

            const headers = new HttpHeaders()
                .append('Content-Type', 'application/json')
                .append('Language', this.getLanguage())
                .append('X-Token', AppConfig.current.token);

            const options = {
                headers
            };

            return this.http.post(url, body, options).toPromise();
        }
    }

    getLanguage(): string {
        let lang = sessionStorage.getItem('lang');
        if (!lang) {
            lang = "de";
        }
        return lang;
    }

    private doHandleAjaxError(error: any) {
        this.log.warn('doHandleAjaxError: ' + Utils.getErrorMessage(error));

        AppErrorHandler.handleAjaxError(error);

        if (this.aktiverHost && this.hostListe.length > 1) {
            this.aktiverHost.failCounter++;

            if (this.aktiverHost.failCounter >= 2 && this.letzterHostWechsel < Date.now() - 5000) {
                this.letzterHostWechsel = Date.now();

                // Wähle einen anderen Host
                // Sortiere die Host nach FehlerCount ASC, DatumOnline DESC
                const moeglicheHosts = this.hostListe.filter(p => p.url != this.aktiverHost.url);

                if (moeglicheHosts.length > 0) {
                    const naechsterHost = moeglicheHosts[Math.floor(Math.random() * moeglicheHosts.length)];

                    this.log.info(`Wächsle aktiver Host von ${this.aktiverHost.url} nach ${naechsterHost.url}`);

                    this.aktiverHost = naechsterHost;
                    this.aktiverHost.failCounter = 0;
                }
            }
        }
    }
}
