
import * as moment from 'moment';
import { Logger } from './app-error-logger';
import { Utils } from './utils';

const FAKE: any = moment()
const TRAVELPORT_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSZ'

const log = new Logger('DateHelper');

export class DateHelper {

    static parseTravelportDateOffset(input: string) {
        const hrs = +input.slice(23, 26)
        const mins = +input.slice(27, 29)

        return hrs * 60 + (hrs < 0 ? -mins : mins)
    }

    /**
     * Formatiert ein ISO-Datum im deutschen Format, d.h.
     * "2019-12-03T12:30:00.000-07:00" => "03.12.2019"
     */
    static formatDatum(input: string): string {
        if (input && input.length >= 10) {
            return input.substr(8, 2) + '.' + input.substr(5, 2) + '.' + input.substr(0, 4);
        }

        return '';
    }

    /**
     * Konvertiert einen ISO-Datums-String in die Uhrzeit HH:mm
     */
    static formatUhrzeit(input: string) {
        if (input && input.length >= 10) {
            try {
                const m = moment(input);
                return m.format('HH:mm');
            } catch (err) {
                log.warn('Fehler beim Konvertieren des Datums in Uhrzeit ' + input + ': ' + Utils.getErrorMessage(err));
            }
        }

        return '';
    }

    /**
     * Parses an ISO-8601 formatted date in this format only:
     * - "2019-12-03"
     */
    static parseIsoDateFast(input: string): moment.Moment {
        const m: any = {};

        // $FlowIgnore
        m.__proto__ = moment.prototype

        const date = new Date(input.slice(0, 10) + 'T12:00:00')

        m._isAMomentObject = true
        m._i = input
        m._f = TRAVELPORT_FORMAT
        m._tzm = 1
        m._isUTC = true
        m._locale = FAKE._locale
        m._d = date
        m._isValid = true
        m._offset = 1

        return m
    }

    /**
     * Parses an ISO-8601 formatted date in this format only:
     * - "2019-12-03T12:30:00.000-07:00"
     */
    static parseIsoDateTimeFast(input: string): moment.Moment {
        const m: any = {};

        // $FlowIgnore
        m.__proto__ = moment.prototype

        const offset = DateHelper.parseTravelportDateOffset(input)
        const date = new Date(input.slice(0, 23))

        m._isAMomentObject = true
        m._i = input
        m._f = TRAVELPORT_FORMAT
        m._tzm = offset
        m._isUTC = true
        m._locale = FAKE._locale
        m._d = date
        m._isValid = true
        m._offset = offset

        return m
    }
}