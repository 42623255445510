<ion-header>
  <ion-toolbar class="auftrag-toolbar" #toolbar>
    <ion-buttons slot="start">
      <ion-button appClick (clicked)="onAbbrechen()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title><span id="page-title-span">{{ 'Störzeiten' | translate }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="section-content-white">
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button appClick (clicked)="onNeueStoerzeit()" color="primary" *ngIf="aendernErlaubt">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div class="section">
    <div *ngIf="auftrag">
      <h1>{{ 'StoerzeitFuerAuftrag' | translate }} {{ auftrag.Auftragsnummer }}</h1>
      <p>{{ auftrag.Abholadresse?.Name1 }}</p>
      <p>{{ auftrag.Abholadresse?.Strasse }}</p>
    </div>

    <div *ngIf="tourStatus">
      <h1>{{ 'StoerzeitFuerTour' | translate }} {{ tourStatus.TourBezeichnung }}</h1>
    </div>
  
    <ion-list class="stoerzeiten-list" lines="full">
      <ion-label *ngIf="!stoerzeitenListe?.length">
        <p>{{ 'KeineEintraegeVorhanden' | translate }}</p>
      </ion-label>
  
      <ion-item *ngFor="let p of stoerzeitenListe" appClick (clicked)="onStoerzeitSelected(p)">
        <ion-label>
          <h2>{{ p.Name }}</h2>
          <p>{{ p.Dauer }} {{ 'Minuten' | translate }}</p>
          <p *ngIf="p.Bemerkung">{{ p.Bemerkung }}</p>
        </ion-label>
      </ion-item>
    </ion-list>

    <div class="zusammenfassung" *ngIf="stoerzeitenListe?.length">
      <div>Summe: {{ summe | async }} {{ 'Minuten' | translate }}</div>
    </div>
  </div>
</ion-content>