import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController } from '@ionic/angular';
import { AuftragService } from 'src/app/api/auftrag.service';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { Auftragsstatus } from 'src/app/api/model/aufteagsstatus';
import { AuftragsKomponente, WorkflowStatus } from 'src/app/api/model/swagger-model';
import { Auftragdetails } from '../auftragdetails.helper';
import { ButtonVO } from '../auftragdetails.model';
import { KomponenteBase } from './komponente';

const log = new Logger("AuftragButtonsComponent");

@Component({
    selector: 'auftrag-buttons-komponente',
    template: `
        <div class="section buttons-section buttons-section-normal" *ngIf="anzahlSichtbar">
          <ng-template ngFor let-b [ngForOf]="buttons">
            <ion-button *ngIf="istStatusuebergangErlaubt(b.workflowStatus)" appClick (clicked)="onButtonClicked(b)" expand="full" [color]="b.color" class="status-button">
              <ion-icon *ngIf="b.icon" slot="start" [name]="b.icon"></ion-icon>
              {{ b.text }}
            </ion-button>
          </ng-template>
        </div>
    `,
    styleUrls: ['./buttons-komponente.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuftragButtonsComponent extends KomponenteBase implements OnInit {

    @Input() auftragdetails: Auftragdetails;
    @Input() komponente: AuftragsKomponente; // optional, kann NULL sein (klingeln-page)
    @Input() nummer = 1;

    @Output() buttonClicked: EventEmitter<any> = new EventEmitter();

    anzahlSichtbar = 0;

    constructor(
        private router: Router,
        private alertController: AlertController,
        private actionSheetController: ActionSheetController,
        private auftragService: AuftragService,
        protected cdr: ChangeDetectorRef) {
        super(cdr);
    }

    get buttons() {
        if (this.nummer == 99) {
            return this.auftragdetails.buttonsAuftragPopup;
        } else if (this.nummer == 2) {
            return this.auftragdetails.buttons2;
        } else if (this.nummer == 3) {
            return this.auftragdetails.buttons3;
        } else {
            return this.auftragdetails.buttons;
        }
    }

    ngOnInit() {
        super.ngOnInit();

        this.aktualisiereAnzahlSichtbareButtons();

        this.subscriptions.push(this.auftragdetails.detectChanges.subscribe(() => {
            this.aktualisiereAnzahlSichtbareButtons();
        }))
    }

    aktualisiereAnzahlSichtbareButtons() {
        this.anzahlSichtbar = 0;

        for (const b of this.buttons) {
            if (this.istStatusuebergangErlaubt(b.workflowStatus)) {
                this.anzahlSichtbar++;
            }
        }

        this.cdr.detectChanges();
    }

    istStatusuebergangErlaubt(workflowStatus: WorkflowStatus) {
        log.debug('istStatusuebergangErlaubt: ' + workflowStatus?.name);

        if (!this.auftrag) {
            return false;
        }

        if (!workflowStatus) {
            return true;
        }

        return this.auftragService.istStatusuebergangErlaubt(this.auftrag, this.auftragdetails.workflow, workflowStatus);
    }

    async onButtonClicked(button: ButtonVO) {
        if (this.auftragdetails.istAuftragAbgeschlossen) {
            let erlaubt = false;

            if (button.workflowStatus) {
                erlaubt = button.workflowStatus.NachAbschlussErlaubt;
            }

            if (!erlaubt) {
                this.auftragdetails.showAuftragArchiviert();
                return;
            }
        }

        if (button.workflowStatus) {
            const result = await this.auftragdetails.statusMelden(button.workflowStatus, true);

            if (!result?.abbruch) {
                this.buttonClicked.next();
            }
        } else {
            this.auftragdetails.onStatusmeldung();
        }

        this.cdr.detectChanges();
    }
}
