<ion-header>
    <ion-toolbar class="auftrag-toolbar" #toolbar>
      <ion-buttons slot="start">
        <ion-button appClick (clicked)="onAbbrechen()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title><span id="page-title-span">{{ 'Statusmeldung' | translate }}</span></ion-title>
    </ion-toolbar>
  </ion-header>

<ion-content *ngIf="statusListe">
    <ion-list class="geraete-list" lines="full">
        <ion-item *ngFor="let status of statusListe" appClick (clicked)="onStatusSelected(status)" [detail]="true">
          <ion-label class="ion-text-wrap">
            <h2>{{ status.name }}</h2>
          </ion-label>
        </ion-item>
      </ion-list>
</ion-content>