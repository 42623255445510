import { WebView } from "@ionic-native/ionic-webview/ngx";
import { ReCoMobilBild } from "../model/swagger-model";
import { AppStorage } from './app-storage';
import { Utils } from "./utils";

export class BildHelper {
    /**
     * Gibt einen String zurück der im <img src="..." /> direkt verwendet werden kann.
     */
    static async getImgSrcFromBild(bild: ReCoMobilBild): Promise<string> {
        let src = bild.Bild;

        if (!src) {
            src = await AppStorage.current.getBildSrc(bild.BildGuid);
        }

        if (src && src.startsWith('file:')) {
            // Bild ist als Datei gespeichert und es wird hier der Pfad zurückgegeben
            src = Utils.webView.convertFileSrc(src);
        } else {
            // Bild ist als Base64 hinterlegt
            if (!src.startsWith("data:image/")) {
                src = 'data:image/jpeg;base64,' + src;
            }
        }

        return src;
    }
}