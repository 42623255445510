

import { Injectable, Optional } from '@angular/core';
import { Storage } from '@ionic/storage';
import { RemoteService } from './remote.service';

import { Subject, BehaviorSubject } from 'rxjs';
import { Logger, LogLevel } from './helper/app-error-logger';
import { AppConfig } from './helper/app.config';
import { SystemService } from './system.service';
import { StammdatenService } from './stammdaten.service';

import * as moment from 'moment';
import * as pako from 'pako';

import { AlertController, ModalController, Platform, NavController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AppStorage } from './helper/app-storage';
import { Textdatei, TextdateiTyp } from './model/swagger-model';
import { SyncResult } from './model/sync-result';
import { App } from './helper/app';
import { Utils } from './helper/utils';
import { ZeiterfassungEx } from './model/model';
import { UiHelper } from './helper/ui-helper';
import { Mutex } from 'async-mutex';
import { EinstellungenPage } from '../einstellungen/einstellungen.page';

const STORAGHE_PREFIX = 'zeiterfassung-'

@Injectable({
    providedIn: 'root'
})
export class ZeiterfassungService {
    private log = new Logger('ZeiterfassungService');

    private isReady = new BehaviorSubject<boolean>(false);
    private mutex = new Mutex();

    listChanged = new Subject<void>();

    constructor(
        private storage: Storage,
        private remoteService: RemoteService,
        private stammdatenService: StammdatenService,
        private alertCtrl: AlertController,
        private modalController: ModalController,
        private router: Router,
        private nav: NavController,
        private toastController: ToastController,
        private systemService: SystemService) {
    }

    async init() {
        await this.storage.ready();

        this.isReady.next(true);

        this.listChanged.next();

        setInterval(() => this.bereinigeDaten(), 60 * 60 * 1000);

        setTimeout(() => this.bereinigeDaten(), 30000);
    }

    async loescheDaten() {
        const release = await this.mutex.acquire();

        try {
            const keys = await this.storage.keys();
            let changed = false;

            for (const key of keys.filter(p => p.startsWith(STORAGHE_PREFIX))) {
                await this.storage.remove(key);
                changed = true;
            }

            if (changed) {
                this.listChanged.next();
            }
        } finally {
            release();
        }
    }

    async bereinigeDaten() {
        this.log.debug('bereinigeDaten START');

        const release = await this.mutex.acquire();

        try {
            // const keys = await this.storage.keys();
            // let changed = false;

            // const tage = 14; // TODO Utils.parseInt(AppConfig.current.einstellungen.ZeiterfassungTageSpeichern);

            // if (tage > 0) {
            //     const timeout = moment().add(-tage, 'days').startOf('day').toISOString();

            //     for (const key of keys.filter(p => p.startsWith(STORAGHE_PREFIX))) {
            //         const item = await this.storage.get(key) as ZeiterfassungEx;

            //         if (item) {
            //             if (item.DatumIso < timeout) {
            //                 this.log.debug('Lösche Glas-Analyse: ' + JSON.stringify(item));

            //                 await this.storage.remove(key);
            //                 changed = true;
            //             }
            //         }
            //     }
            // }

            // if (changed) {
            //     this.listChanged.next();
            // }
        } finally {
            release();
        }

        this.log.debug('bereinigeDaten FERTIG');
    }

    async getAlleZeiterfassungen(): Promise<ZeiterfassungEx[]> {
        const release = await this.mutex.acquire();

        try {
            const keys = await this.storage.keys();
            const resultList: ZeiterfassungEx[] = [];

            // const tage = 14; // TODO Utils.parseInt(AppConfig.current.einstellungen.ZeiterfassungTageSpeichern);

            // const timeout = tage > 0
            //     ? moment().add(-tage, 'days').startOf('day').toISOString()
            //     : '';

            // for (const key of keys.filter(p => p.startsWith(STORAGHE_PREFIX))) {
            //     const item = await this.storage.get(key) as ZeiterfassungEx;

            //     if (item && item.DatumIso >= timeout) {
            //         resultList.push(item);
            //     }
            // }

            return resultList;
        } finally {
            release();
        }
    }

    async getZeiterfassung(guid: string): Promise<ZeiterfassungEx> {
        await this.ready();

        const entity = await this.storage.get(STORAGHE_PREFIX + guid);

        return this.fixEntity(entity);
    }

    fixEntity(item: ZeiterfassungEx): ZeiterfassungEx {
        if (!item) {
            return item;
        }

        // TODO

        return item;
    }

    public async ready() {
        if (this.isReady.getValue()) {
            return;
        }

        return new Promise(resolve => {
            const subscription = this.isReady.subscribe((value) => {
                if (value) {
                    subscription.unsubscribe();
                    resolve(null);
                }
            });
        });
    }

    async speichernZeiterfassung(entity: ZeiterfassungEx): Promise<void> {
        this.log.debug('speichernZeiterfassung', entity);

        // if (!entity.Guid) {
        //     entity.Guid = Utils.uuid();
        // }

        // if (!entity.DatumIso) {
        //     entity.DatumIso = Utils.nowIsoDateString();
        // }

        // const key = STORAGHE_PREFIX + entity.Guid;

        // this.storage.set(key, entity);

        // this.listChanged.next();

        // const sendEntity = Utils.clone(entity);

        // // Uhrzeit soll als einfache Uhrzeit übertragen werden und nicht als ISO-Dateum (das auch in der falschen Zeitzone ist)
        // if (sendEntity.Uhrzeit && sendEntity.Uhrzeit.length > 6) {
        //     // ISO-Datum
        //     const m = Utils.parseDatum(sendEntity.Uhrzeit);
        //     sendEntity.Uhrzeit = m.format('HH:mm');
        // }

        // const textdatei: Textdatei = {
        //     typ: TextdateiTyp.Zeiterfassung,
        //     datum: moment().toISOString(),
        //     geraeteNummer: AppConfig.current.geraeteNummer,
        //     Zeiterfassung: sendEntity
        // };

        // // Kein await hier aus Performance-Gründen
        // this.systemService.sendeTextdatei(textdatei, false);
    }

    // async abschliessenZeiterfassung(entity: ZeiterfassungEx): Promise<void> {
    //     this.log.debug('abschliessenZeiterfassung', entity);

    //     await this.speichernZeiterfassung(entity);

    //     const clone: ZeiterfassungEx = Utils.clone(entity);

    //     if (clone.Bilder) {
    //         for (const bild of clone.Bilder) {
    //             if (bild.BildGuid) {
    //                 bild.Bild = await AppStorage.current.getBildBase64Data(bild.BildGuid);
    //             }
    //         }
    //     }

    //     const textdatei: Textdatei = {
    //         typ: TextdateiTyp.Zeiterfassung,
    //         key: entity.Guid,
    //         Zeiterfassung: clone,
    //         datum: moment().toISOString(),
    //         geraeteNummer: AppConfig.current.geraeteNummer
    //     };

    //     const mitGpsPosition = false;

    //     // Kein await hier aus Performance-Gründen
    //     this.systemService.sendeTextdatei(textdatei, mitGpsPosition);

    //     const toast = await this.toastController.create({
    //         message: 'Glas-Analyse wurde abgeschlossen',
    //         duration: 1500
    //     });

    //     toast.present();
    // }
}
