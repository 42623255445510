<ion-header>
  <ion-toolbar class="auftrag-toolbar" #toolbar>
    <ion-buttons slot="start">
      <ion-button appClick (clicked)="onAbbrechen()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title><span id="page-title-span">{{ 'Adresse ändern' | translate }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="adresse" class="section-content-white">
  <div class="section">
    <ion-item lines="none">
      <ion-label position="stacked">Name 1</ion-label>
      <ion-input type="text" [(ngModel)]="adresse.Name1" clearInput required></ion-input>
    </ion-item>

    <ion-item lines="none">
      <ion-label position="stacked">Name 2</ion-label>
      <ion-input type="text" [(ngModel)]="adresse.Name2" clearInput></ion-input>
    </ion-item>

    <ion-item lines="none">
      <ion-label position="stacked">Strasse + Haus-Nr.</ion-label>
      <ion-input type="text" [(ngModel)]="adresse.Strasse" clearInput></ion-input>
    </ion-item>

    <ion-item lines="none">
      <ion-label position="stacked">PLZ</ion-label>
      <ion-input type="text" [(ngModel)]="adresse.PLZ" clearInput></ion-input>
    </ion-item>

    <ion-item lines="none">
      <ion-label position="stacked">Ort</ion-label>
      <ion-input type="text" [(ngModel)]="adresse.Ort" clearInput></ion-input>
    </ion-item>

    <ion-item lines="none">
      <ion-label position="stacked">Telefon</ion-label>
      <ion-input type="text" [(ngModel)]="adresse.Telefon" clearInput></ion-input>
    </ion-item>

    <ion-item lines="none">
      <ion-label position="stacked">Kundennummer</ion-label>
      <ion-input type="text" [(ngModel)]="adresse.AdressNummer" (keyup.enter)="app.closeKeyboard($event)" enterkeyhint="done" clearInput></ion-input>
    </ion-item>

    <div class="mt-15">
      <ion-button expand="block" size="large" color="light" appClick (clicked)="onSpeichern()">OK</ion-button>
    </div>
  </div>

  <div class="section">
  </div>
</ion-content>