import { MobileWaage } from './waage';
import { Logger } from '../helper/app-error-logger';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { App } from '../helper/app';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';

import * as moment from 'moment';
import { GeraetStatusTyp, WaageProtokoll, WaageProtokollEintrag } from '../model/model';
import { Utils } from '../helper/utils';
import { AppConfig, WaageKonfiguration } from '../helper/app.config';
import { AppStorage } from '../helper/app-storage';
import { Wiegedaten } from '../model/swagger-model';

const TERMINAL = 'TRM';

export class SywatecWaage implements MobileWaage {
    private log = new Logger('SywatecWaage');

    konfiguration: WaageKonfiguration;
    adresse: string;
    status = new BehaviorSubject<GeraetStatusTyp>(GeraetStatusTyp.NichtVerbunden);
    sub: Subscription;
    aktiv: boolean;

    dagtumLetzteUhrSynchronisierung = 0;

    error = new Subject<string>();
    connected = new Subject<void>();
    disconnected = new Subject<void>();
    datenEmpfangen = new Subject<void>();
    wiegedatenEmpfangen = new Subject<Wiegedaten>();

    updateIntervalId: any = null;

    protokoll: WaageProtokoll;

    constructor(private bluetoothSerial: BluetoothSerial) {
        this.log.info('SYWATEC Waage');

        this.updateIntervalId = setInterval(() => {
            if (this.aktiv) {
                this.pruefeVerbindung();
            }
        }, 30000);
    }

    syncUhr() {
        // Gibt es hier nicht
    }

    async getProtokoll(): Promise<WaageProtokoll> {
        if (!this.protokoll) {
            this.protokoll = await AppStorage.current.get('waage-protokoll-' + this.adresse);

            if (!this.protokoll) {
                this.protokoll = {
                    waageAdresse: this.adresse,
                    eintraege: []
                }
            }

            if (!this.protokoll.waageAdresse) {
                this.protokoll.waageAdresse = this.adresse;
            }

            if (!this.protokoll.eintraege) {
                this.protokoll.eintraege = [];
            }
        }

        return this.protokoll;
    }

    getTyp() {
        return 'sywatec';
    }

    setActive(active: boolean) {
        if (this.aktiv === active) {
            // Keine Änderung
            return;
        }

        this.aktiv = active;

        if (active) {
            this.connect();
        } else {
            this.close()
        }
    }

    async connect(): Promise<any> {
        this.log.info('Connect');

        if (!App.isCordovaAvailable()) {
            this.disconnected.next();
            return;
        }

        await App.ready();

        try {
            this.log.debug('Aktiviere Bluetooth...');
            const enableResult = await this.bluetoothSerial.enable();

            this.log.debug('enableResult: ' + enableResult, enableResult);

            this.log.debug('Subscribe auf Data...');
            this.sub = this.bluetoothSerial.subscribe('>').subscribe(p => this.onDataReceived(p));

            const connectResult = await this.bluetoothSerial.connect(this.adresse).toPromise();

            this.log.info('Verbindung zur Waage', connectResult);
            this.status.next(GeraetStatusTyp.Verbunden);
            this.connected.next();
        } catch (err) {
            this.log.warn(`Connect Waage (${this.adresse}): ` + Utils.getErrorMessage(err), err);
            this.status.next(GeraetStatusTyp.NichtVerbunden);

            let errMessage = Utils.getErrorMessage(err);

            if (errMessage.indexOf('User did not enable Bluetooth') >= 0) {
                errMessage = 'Bluetooth nicht aktiv';
            }

            this.disconnected.next();
            this.error.next(errMessage);
        }
    }

    async close(): Promise<any> {
        this.log.debug('Close');

        this.status.next(GeraetStatusTyp.NichtVerbunden);

        if (!App.isCordovaAvailable()) {
            this.disconnected.next();
            return;
        }

        try {
            if (this.sub) {
                this.sub.unsubscribe();
                this.sub = null;
            }

            this.log.debug('Disconnecting bluetooth...');
            await this.bluetoothSerial.disconnect();
            this.log.debug('Disconnected');
        } catch (err) {
            this.error.next(Utils.getErrorMessage(err));
            this.log.warn(`Close Waage: ${err.message}`);
        }

        this.disconnected.next();
    }

    async dispose(): Promise<void> {
        this.log.debug('dispose');

        this.aktiv = false;

        if (this.updateIntervalId) {
            clearInterval(this.updateIntervalId);
            this.updateIntervalId = null;
        }

        await this.close();

        // https://stackoverflow.com/questions/40452979/does-subject-complete-unsubscribe-all-listeners
        this.wiegedatenEmpfangen.complete();
        this.wiegedatenEmpfangen.observers.length = 0;
    }

    async pruefeVerbindung(): Promise<void> {
        this.log.debug('pruefeVerbindung');

        if (!this.aktiv) {
            this.status.next(GeraetStatusTyp.NichtVerbunden);
            return;
        }

        if (!App.isCordovaAvailable()) {
            return;
        }

        try {
            const connected = await this.bluetoothSerial.isConnected();

            this.log.debug('isConnected', connected);

            if (connected) {
                this.status.next(GeraetStatusTyp.Verbunden);
                const maxAlter = 12 * 60 * 60 * 1000;

                this.connected.next();
            } else if (!connected) {
                this.log.debug('Waage nicht verbunden. Versuche reconnect...');

                await this.close();
                await this.connect();
            }
        } catch (err) {
            this.log.info('pruefeVerbindung', err);
            this.error.next(Utils.getErrorMessage(err));
            this.status.next(GeraetStatusTyp.Fehler);
        }
    }

    async wiegen(nr: number) {
        this.log.debug('wiegen: ' + nr);

        if (!App.isCordovaAvailable()) {
            if(nr == 2) {
                this.onDataReceived('<TRM 86&AG   302;   159;   143;  120; 1;12.01.19;10:18;034000035417    ;   274;   273;#77;3380>');
            } else {
                this.onDataReceived('<TRM 72&AG   284;   120;   164;    4; 1;03.01.12;14:56;034000035417    ;#63;3194>');
            }
        }

        // try {
        //     await this.pruefeVerbindung();

        //     if (nr == 1) {
        //         // Erste Wiegung
        //         this.senden('1#51#5#');
        //     } else if (nr == 2) {
        //         // Zweite Wiegung
        //         this.senden('1#53#5#');
        //     }

        // } catch (err) {
        //     this.log.error('wiegen: ' + Utils.getErrorMessage(err), err);
        // }
    }

    async tarieren() {
        this.log.debug('tarieren');

        // try {
        //     await this.pruefeVerbindung();
        //     this.senden('1#101#5#1#');
        // } catch (err) {
        //     this.log.error('tarieren: ' + Utils.getErrorMessage(err), err);
        // }
    }

    speichereProtokoll() {
        // Zu alte Einträge löschen
        const maxEintraege = 100;
        const protokoll = this.protokoll;

        if (protokoll.eintraege.length > maxEintraege) {
            protokoll.eintraege.splice(0, protokoll.eintraege.length - maxEintraege);
        }

        AppStorage.current.set('waage-protokoll-' + this.adresse, protokoll);
    }

    async onDataReceived(message: string) {
        try {
            this.log.debug('Waage onDataReceived', message);

            this.status.next(GeraetStatusTyp.Verbunden);
            this.datenEmpfangen.next();

            message = message.trim();

            const protokoll = await this.getProtokoll();

            const protokollEintrag: WaageProtokollEintrag = {
                timestamp: Date.now(),
                rohdaten: message,
                typ: 'unbekannt',
                daten: ''
            };

            protokoll.eintraege.push(protokollEintrag);

            this.speichereProtokoll();

            do {
                let startPos = message.indexOf('<');

                if (startPos < 0) {
                    return;
                }

                let endPos = message.indexOf('>', startPos);

                if (endPos < 0) {
                    return;
                }

                const daten = message.substring(startPos + 1, endPos);

                await this.verarbeiteNachricht(daten, protokollEintrag);

                if (message.length > endPos) {
                    message = message.substring(endPos + 1);
                } else {
                    break;
                }
            } while (message);

        } catch (err) {
            this.log.error('onDataReceived', err);
            this.error.next(Utils.getErrorMessage(err));
        }
    }

    async verarbeiteNachricht(daten: string, protokollEintrag: WaageProtokollEintrag) {
        // Wiededaten Beispiel:
        // Die daten sind hier schon ohne die <>
        // ALT (SyWa3000)
        // <TRM 72&AG   284;   120;   164;    4; 1;03.01.12;14:56;034000035417    ;#63;3194>
        // NEU (SyWa4000)
        // <TRM 86&AG   302;   159;   143;  120; 1;12.01.19;10:18;034000035417    ;   274;   273;#77;3380>

        this.log.debug('Daten: ' + daten);

        if (daten.length < 9) {
            this.log.debug('Verwerfe zu kurzes Datenpaket');
            return;
        }

        const empfaenger = daten.substring(0, 3);

        if (empfaenger != TERMINAL) {
            // TRM ist das Terminal, also wir.
            // Alles für andere Empfänger ignorieren
            this.log.warn('Ungültiger Empfänger: ' + empfaenger);
            return;
        }

        const laenge = Utils.parseInt(daten.substring(3, 6).trim());

        const posNutzdatenstart = daten.indexOf('&');

        if (posNutzdatenstart <= 0) {
            // Keien Nutzdaten gefunden
            return;
        }

        const nutzdaten = daten.substring(posNutzdatenstart + 1);
        const typ = nutzdaten.substring(0, 3).trim();

        switch (typ) {
            case 'AG':
                this.verarbeiteWiegedaten(nutzdaten.substring(3), protokollEintrag);
                break;

            default:
                protokollEintrag.daten = nutzdaten;
                protokollEintrag.typ = typ;
                break;
        }
    }

    verarbeiteWiegedaten(nutzdaten: string, protokollEintrag: WaageProtokollEintrag) {
        const parts = nutzdaten.split(';');

        const brutto = Utils.parseFloat(parts[0]);
        const tara = Utils.parseFloat(parts[1]);
        let netto = Utils.parseFloat(parts[2]);
        const wiegescheinnummer = Utils.trimToEmpty(parts[3]);
        const behaelteranzahl = Utils.parseInt(parts[4]);
        const datum = Utils.trimToEmpty(parts[5]);
        const uhrzeit = Utils.trimToEmpty(parts[6]);
        let barcode = Utils.trimToEmpty(parts[7]);

        // An den Barcode angehängte Unterstriche _ sind zu ignorieren. 
        barcode = barcode.replace('_', '');

        // TODO: Eventuell nochmal klären ob die Wiegescheinnummer die richtige Wiege-Nummer ist.
        let alibinummer1 = Utils.parseInt(wiegescheinnummer);
        let alibinummer2 = 0;

        if (parts.length >= 12) {
            // SyWa4000
            alibinummer1 = Utils.parseInt(parts[8]);
            alibinummer2 = Utils.parseInt(parts[9]);
        }

        const datumStr = datum + ' ' + uhrzeit;
        const m = moment(datumStr, 'DD.MM.YY HH:mm');

        const nettoBerechnung = AppConfig.current.einstellungen.WaageNettoBerechnung;

        if (nettoBerechnung == 'neeto-berechnen') {
            const nettoAlt = netto;
            netto = Math.abs(brutto - tara);

            this.log.info(`Netto für Wiegeung wurde berechnet: ${nettoAlt} => ${netto}`);
        }

        const wiegedaten: Wiegedaten = {
            Wiegenummer: alibinummer1,
            Wiegenummer2: alibinummer2,
            Datum: m.toISOString(),
            Wiegung1: brutto,
            Wiegung2: tara,
            Netto: netto,
            Info: nutzdaten
        };

        this.log.info('Wiegedaten gelesen', wiegedaten);

        // Wiegung speichern
        this.wiegedatenEmpfangen.next(wiegedaten);

        protokollEintrag.daten = `Nr. ${alibinummer1} (${alibinummer2}), Datum: ${datumStr}, Brutto: ${brutto}, Tara: ${tara}, Netto: ${netto}`;
        protokollEintrag.typ = 'wiegung';

        this.speichereProtokoll();
    }

    // senden(nutzdaten: string) {
    //     const checksumme = this.berechneChecksumme(nutzdaten);
    //     const message = "{" + nutzdaten + "@" + checksumme.toString() + '}';

    //     this.log.debug('senden: ' + message);

    //     this.protokoll.eintraege.push({
    //         daten: 'SEND: ' + message,
    //         typ: 'senden',
    //         timestamp: Date.now(),
    //         rohdaten: message
    //     });

    //     this.bluetoothSerial.write(message);
    // }
}
