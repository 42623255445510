import { ChangeDetectorRef, Component, NgZone, ViewChild } from '@angular/core';

import { Platform, AlertController, ActionSheetController, LoadingController, Config, NavController, IonSelect, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { File } from '@ionic-native/file/ngx';
import { EncryptionHelper } from './api/helper/encryption-helper';
import { AppConfig } from './api/helper/app.config';
import { SystemService } from './api/system.service';
import { UiHelper } from './api/helper/ui-helper';
import { Logger } from './api/helper/app-error-logger';
import { RemoteService } from './api/remote.service';
import { Router } from '@angular/router';
import { AuftragService } from './api/auftrag.service';
import { App } from './api/helper/app';
import { StammdatenService } from './api/stammdaten.service';
import { TourService } from './api/tour.service';
import { FileHelper } from './api/helper/file-helper';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import { Camera } from '@ionic-native/camera/ngx';

import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import { ClickHelper } from './api/helper/click-helper';

import * as Sentry from 'sentry-cordova';

import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { AppStorage } from './api/helper/app-storage';
import { UvvService } from './api/uvv.service';
import { WaageService } from './api/waage.service';
import { NachrichtService } from './api/nachricht.service';

import * as moment from 'moment';
import { Utils } from './api/helper/utils';
import { DruckerService } from './api/drucker.service';
import { Adresse, AdresseEntity, TextdateiTyp } from './api/model/swagger-model';
import { Constants } from './api/model/constants';
import { SmartwatchService } from './api/smartwatch.service';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { ProgloveService } from './api/proglove.service';
import { CameraHelper } from './shared/helper/camera-helper';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { GlasAnalyseService } from './api/glassanalyse.service';
import { translations } from './shared/translations';
import { BildHelper } from './api/helper/bild-helper';
import { I18N } from './api/helper/i18n';
import { AuftragEx, AuftragListItem, KlingenDaten } from './api/model/model';
import { KlingelnPage } from './auftragsliste/klingeln/klingeln.page';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    private log = new Logger('AppComponent');

    loadingSpinner: BehaviorSubject<boolean>;

    appClass = new BehaviorSubject<string>('schriftgroesse-normal');

    // public appPages = [
    //     {
    //         title: 'Start',
    //         url: '/home',
    //         icon: 'home'
    //     },
    //     {
    //         title: 'Transportaufträge',
    //         url: '/auftragsliste/aktuell',
    //         icon: 'clipboard'
    //     },
    //     {
    //         title: 'Hofaufträge',
    //         url: '/auftragsliste/hofchecker',
    //         icon: 'checkbox-outline'
    //     },
    //     {
    //         title: 'Nachrichten',
    //         url: '/nachrichten',
    //         icon: 'mail'
    //     },
    //     {
    //         title: 'System',
    //         url: '/einstellungen',
    //         icon: 'settings'
    //     }
    // ];

    farbe1 = '';
    kachelHoehe: string;

    lastMandant = '';
    lastGeraeteNummer = '';
    syncErforderlich = true;
    texteGeladenDatum = 0;

    klingelnModal: HTMLIonModalElement;
    klingelnWirdAngezeigt = false;
    klingenDatenQueue: KlingenDaten[] = [];

    app = App.current;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private storage: Storage,
        private ionicConfig: Config,
        private file: File,
        private camera: Camera,
        private router: Router,
        private dialogs: Dialogs,
        private cdr: ChangeDetectorRef,
        private nav: NavController,
        private ngZone: NgZone,
        private webView: WebView,
        private translateService: TranslateService,
        private glasAnalyseService: GlasAnalyseService,
        private systemService: SystemService,
        private tourService: TourService,
        private nachrichtService: NachrichtService,
        private waageService: WaageService,
        private smartwatchService: SmartwatchService,
        private druckerService: DruckerService,
        private remoteService: RemoteService,
        private progloveService: ProgloveService,
        public stammdatenService: StammdatenService,
        private auftragService: AuftragService,
        private uvvService: UvvService,
        private alertController: AlertController,
        private actionSheetController: ActionSheetController,
        private modalController: ModalController,
        private loadingController: LoadingController,
        private translate: TranslateService
    ) {
        this.log.info('APP START');

        try {
            this.loadingSpinner = App.current.loadingSpinner;

            App.current.ionicConfig = this.ionicConfig;
            App.current.platform = this.platform;
            App.current.storage = this.storage;
            App.current.dialogs = this.dialogs;
            App.current.ngZone = this.ngZone;

            I18N.translateService = translateService;

            CameraHelper.camera = camera;
            CameraHelper.file = file;

            Utils.webView = webView;

            AppStorage.current.init(this.storage, this.file, this.platform);

            moment.locale('de');
        } catch (err) {
            alert(Utils.getErrorMessage(err));
        }

        // this.runPerformanceTest();

        this.init();
    }

    async init() {
        try {
            App.current.init();

            App.current.configRefreshed.subscribe(() => {
                this.updateView();
                this.ladeTexte(false);
            });

            App.current.onShowHomeNavigation.subscribe(() => {
                this.starteHomeNavigation();
            });

            this.systemService.texteAktualisieren.subscribe(p => {
                this.ladeTexte(true);
            });

            this.auftragService.doKlingeln.subscribe(daten => {
                this.doKlingeln(daten);
            });

            this.auftragService.auftragGeloescht.subscribe(a => this.onAuftragGeloescht(a));

            // this.updateView();

            ClickHelper.init();

            this.initLocales();
            this.initTranslation();
            this.initializeApp();
            this.ladeTexte(true);

            setInterval(() => {
                this.syncMitServerWennErforderlich();
            }, 30000);
        } catch (err) {
            this.log.error('init: ' + Utils.getErrorMessage(err), err);

            alert(Utils.getErrorMessage(err));
        }
    }

    onAuftragGeloescht(a: AuftragListItem | AuftragEx): void {
        if (this.klingenDatenQueue?.length) {
            // Lösche Aufträge aus der Queue die gelöscht wurden.
            // Fehler ist beim Schuttkarl aufgetreten.
            // Der Klingeln-Dialog wurde lange nicht geschlossen. Ein gelöschter Auftrag wurde dann angenommen, obwohl er
            // bereits gelöscht wurde.
            this.klingenDatenQueue = this.klingenDatenQueue.filter(p => p.neueAuftraege?.length > 0 && p.neueAuftraege[0].Key != a.Key);
        }
    }

    async doKlingeln(daten: KlingenDaten) {
        const anzeige = AppConfig.current.einstellungen.KlingenJedenAuftragAnzeigen;

        if (anzeige === 'jeden') {
            // Es soll jeden Auftrag anzeln angezeigt werden
            if (daten.neueAuftraege?.length > 0) {
                // Jeden Auftrag als eigenen Datensatz behandeln
                for (const auftrag of daten.neueAuftraege) {
                    const d: KlingenDaten = {
                        ...daten,
                        neueAuftraege: [auftrag]
                    }

                    this.log.debug('Enqueue Klingeln: ' + auftrag.Key);
                    this.klingenDatenQueue.push(d);
                }

                this.anzeigenNaechstesAuftragPopup();
            }
        } else {
            // Es soll jeweils nur der letzte Auftrag angezeigt werden
            await this.doKlingelnLetzterAuftrrag(daten);
        }
    }

    async anzeigenNaechstesAuftragPopup() {
        this.log.debug('Klingeln: anzeigenNaechstesAuftragPopup');

        if (!this.klingenDatenQueue.length) {
            return;
        }

        if (this.klingelnWirdAngezeigt) {
            this.log.debug('Klingeln wird bereits angezeigt. Warte bis Fenster geschlossen wurde.');
            return;
        }

        this.klingelnWirdAngezeigt = true;

        try {
            // Hole die erste Nachricht
            const daten = this.klingenDatenQueue[0];

            this.log.debug('Anzeigen nächsten Auftrag: ' + daten.neueAuftraege[0].Key);

            // Entferne den ersten Eintrag aus der Liste
            this.klingenDatenQueue.splice(0, 1);

            if (this.klingelnModal) {
                this.log.warn('klingelnModal noch vorhanden. Wird zuerst geschlossen. Darf eigentlich nicht eintreten...');
                this.klingelnModal.dismiss();
                await this.klingelnModal.onDidDismiss();
                this.klingelnModal = null;
            }

            this.klingelnModal = await this.modalController.create({
                component: KlingelnPage,
                componentProps: {
                    'neueAuftraege': daten.neueAuftraege,
                    'aktion': daten.aktion,
                    'isAndererAuftragAktiv': !!this.auftragService.aktiverAuftrag
                }
            });

            this.log.debug('klingelnModal wird angezeigt...');
            await this.klingelnModal.present();

            await this.klingelnModal.onDidDismiss();
            this.log.debug('klingelnModal wurde geschlossen');
            this.klingelnModal = null;
        } catch (err) {
            this.log.error(Utils.getErrorMessage(err));
        }

        this.klingelnWirdAngezeigt = false;

        if (this.klingenDatenQueue.length) {
            // Nächstes Auftrags-Popup anzeigen
            this.anzeigenNaechstesAuftragPopup();
        }
    }

    async doKlingelnLetzterAuftrrag(daten: KlingenDaten) {
        const aktion = daten.aktion;
        const neueAuftraege = daten.neueAuftraege;

        if (this.klingelnModal) {
            this.log.debug('klingelnModal noch vorhanden. Wird zuerst geschlossen...');
            this.klingelnModal.dismiss();
            await this.klingelnModal.onDidDismiss();
            this.klingelnModal = null;
        }

        this.klingelnModal = await this.modalController.create({
            component: KlingelnPage,
            componentProps: {
                'neueAuftraege': neueAuftraege,
                'aktion': aktion,
                'isAndererAuftragAktiv': !!this.auftragService.aktiverAuftrag
            }
        });

        this.log.debug('klingelnModal wird angezeigt...');
        await this.klingelnModal.present();

        await this.klingelnModal.onDidDismiss();
        this.log.debug('klingelnModal wurde geschlossen');
        this.klingelnModal = null;
    }

    async ladeTexte(force: boolean) {
        let relevanteKeys: string[] = [];

        try {
            if (force) {
                const texte = localStorage.getItem('texte');

                if (texte) {
                    this.app.texte = JSON.parse(texte)

                    if (this.app.texte) {
                        let sprache = AppConfig.current.language;

                        if (!sprache) {
                            sprache = 'DE';
                        } else {
                            sprache = sprache.toUpperCase();
                        }

                        const uebersetzungen = translations[sprache];

                        if (uebersetzungen) {
                            for (const text of this.app.texte) {
                                if (text.Key != text.Text) {
                                    // Merke die originale Übersetzung
                                    const orgKey = '_org_' + text.Key;

                                    if (!uebersetzungen[orgKey]) {
                                        uebersetzungen['_org_' + text.Key] = uebersetzungen[text.Key]
                                    }

                                    uebersetzungen[text.Key] = text.Text;

                                    relevanteKeys.push(text.Key);
                                }
                            }
                        }
                    }
                }
            }
        } catch (err) {
            // ignore
        }

        try {
            if (!AppConfig.current.token) {
                return;
            }

            if (this.texteGeladenDatum > Date.now() - 1 * 60 * 60 * 1000) {
                if (!force) {
                    // Texte nur einmal je Stunde neu laden
                    return;
                }
            }

            this.texteGeladenDatum = Date.now();

            let sprache = AppConfig.current.language;

            if (!sprache) {
                sprache = 'DE';
            } else {
                sprache = sprache.toUpperCase();
            }

            const result = await this.remoteService.getTexte(sprache);

            if (result?.Items) {
                this.app.texte = result.Items;
            }

            // Überschreibe die fest eingebauten Übersetzungen
            const uebersetzungen = translations[sprache];

            if (uebersetzungen) {
                // Zuerst originale Übersetzungen wiederherstellen
                for (const key in uebersetzungen) {
                    if (typeof (key) == 'string' && key.startsWith('_org_')) {
                        const k2 = key.substring(5);
                        if (k2) {
                            uebersetzungen[k2] = uebersetzungen[key];
                            relevanteKeys.push(k2);
                        }
                    }
                }

                for (const text of this.app.texte) {
                    if (text.Key != text.Text) {
                        // Merke die originale Übersetzung
                        const orgKey = '_org_' + text.Key;

                        if (!uebersetzungen[orgKey]) {
                            uebersetzungen['_org_' + text.Key] = uebersetzungen[text.Key]
                        }

                        uebersetzungen[text.Key] = text.Text;

                        relevanteKeys.push(text.Key);
                    }
                }
            }

            this.texteGeladenDatum = Date.now();

            localStorage.setItem('texte', JSON.stringify(this.app.texte));

            relevanteKeys = Utils.distinct(relevanteKeys);

            for (const key of relevanteKeys) {
                this.translateService.set(key, uebersetzungen[key]);
            }
        } catch (err) {
            this.log.warn('Fehler beim Laden der Texte: ' + Utils.getErrorMessage(err));
            this.texteGeladenDatum = 0;
        }
    }

    async runPerformanceTest() {
        this.log.warn('PERFORMANCE TEST START');

        // const entires = await this.file.listDir(this.file.dataDirectory, '');

        // if (entires) {
        //     this.log.warn('PERFORMANCE TEST: ListDir: ' + entires.length);
        // }

        // ####################

        this.log.warn('PERFORMANCE TEST: localStorage start');

        const foo = localStorage.getItem('foo');

        this.log.warn('PERFORMANCE TEST: localStorage read: ' + foo);

        localStorage.setItem('foo', "asdf");

        this.log.warn('PERFORMANCE TEST: localStorage write');

        // ####################

        this.log.warn('PERFORMANCE TEST: storage start');

        const appStorage = await AppStorage.current.get(Constants.AUFTRAEGE, true, false);

        this.log.warn('PERFORMANCE TEST: AppStorage read: ' + appStorage);

        // ####################

        // this.log.warn('PERFORMANCE TEST: storage start');

        // const storageRead = await AppStorage.current.get('formulare');

        // this.log.warn('PERFORMANCE TEST: storage read');

        this.log.warn('PERFORMANCE TEST ENDE');
    }

    async initLocales() {
        registerLocaleData(localeDe);
        registerLocaleData(localeFr);
    }

    async initTranslation() {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('de');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translate.use('de');

        // const configData: any = {
        //     backButtonText: await this.translate.get('Zurück').toPromise(),
        //     cancelText: await this.translate.get('Abbrechen').toPromise(),
        //     dayNames: await this.translate.get('dayNames').toPromise(),
        //     dayShortNames: await this.translate.get('dayShortNames').toPromise(),
        //     doneText: await this.translate.get('Fertig').toPromise(),
        //     monthNames: await this.translate.get('monthNames').toPromise(),
        //     monthShortNames: await this.translate.get('monthShortNames').toPromise(),
        //     okText: await this.translate.get('OK').toPromise(),
        // };

        // Object.keys(configData).forEach(key => {
        //     this.log.debug('config: ' + key, configData[key]);
        //     this.config.set(key as any, configData[key]);
        // });
    }

    ngOnInit() {
        this.loadingSpinner.subscribe(() => {
            try {
                this.ngZone.run(() => {
                    this.cdr.detectChanges();
                });
            } catch (err) {
                this.log.warn(err);
            }
        });
    }

    async initializeApp() {
        UiHelper.alertCtrl = this.alertController;
        UiHelper.modalController = this.modalController;
        UiHelper.actionSheetController = this.actionSheetController;
        UiHelper.loadingController = this.loadingController;

        FileHelper.file = this.file;

        App.ready().then(() => {
            try {
                if (this.systemService.isCordovaAvailable()) {
                    this.splashScreen.hide();
                    this.statusBar.styleDefault();
                    this.statusBar.overlaysWebView(false);
                    this.statusBar.show();

                    if (environment.sentryEnabled) {
                        this.initSentry();
                    }
                }
            } catch (err) {
                this.log.error('App.ready(): ' + Utils.getErrorMessage(err), err);
            }
        });

        try {
            this.log.debug('APP INIT 1');

            this.systemService.initialize();

            this.log.debug('APP INIT 2');

            this.auftragService.init();

            this.log.debug('APP INIT 2b');

            this.uvvService.init();

            this.log.debug('APP INIT 3');

            this.waageService.init();

            this.log.debug('APP INIT 4');

            await this.initializeConfig();

            this.log.debug('APP INIT 5');

            await this.stammdatenService.init();

            this.log.debug('APP INIT 5b');

            await this.tourService.init();

            this.log.debug('APP INIT 6');

            await this.nachrichtService.init();

            this.log.debug('APP INIT 7');

            await this.druckerService.init();

            this.log.debug('APP INIT 8');

            await this.showSicherheitshinweisWennErforderlich();

            // Warte bis App initialisiert ist
            await App.ready();

            this.log.debug('APP INIT 9');

            await this.smartwatchService.init();

            this.log.debug('APP INIT 10');

            await this.progloveService.init();

            this.log.debug('APP INIT 11');

            await this.glasAnalyseService.init();

            this.log.debug('APP INIT 12');

            this.loadingSpinner.next(false);
        } catch (err) {
            this.log.error('initializeApp', err);
        }
    }

    private async initializeConfig(): Promise<void> {
        this.log.debug("initializeConfig");

        try {
            const appConfig = await this.systemService.getAppConfig();

            this.log.debug("appConfig done");

            // Initialize encryption password
            EncryptionHelper.setKey(appConfig.schluessel);

            this.log.debug("setKey done", appConfig.schluessel);

            await this.updateView();

            this.log.debug("updateView done");

            if (appConfig.mandant && appConfig.geraeteNummer) {
                this.log.debug("mandant + geraeteNummer");

                // Versuche einen Login. Muss nicht erfolgreich sein, falls keine Internetverbndung besteht
                setTimeout(async () => {
                    try {
                        const loginOk = await this.tryLogin();

                        this.log.debug("tryLogin done");

                        if (loginOk) {
                            this.log.debug("loginOk");

                            await this.syncMitServerWennErforderlich();

                            this.log.debug("syncMitServerWennErforderlich done");
                        }
                    } catch (err) {
                        this.log.error('setTimeout initializeConfig: ' + Utils.getErrorMessage(err), err);
                    }
                }, 1000);
            }
        } catch (err) {
            this.log.error('initializeConfig', err);
        }
    }

    async tryLogin(): Promise<boolean> {
        try {
            this.log.debug('tryLogin');

            const appConfig = await this.systemService.getAppConfig();

            const deviceId = await this.systemService.getDeviceId();

            // TODO: Hash des schluessels mit an den Server schicken und überprüfen
            const loginResult = await this.remoteService.login({
                appVersion: AppConfig.AppVersion,
                geraeteNummer: appConfig.geraeteNummer,
                passwort: appConfig.passwort,
                mandant: appConfig.mandant,
                deviceId
            });

            if (!loginResult) {
                this.log.warn('tryLogin: REST-Antwort ist NULL. Keine Internetverbindung?');
                return false;
            }

            if (loginResult.success) {
                appConfig.uebernehmeLoginResult(loginResult);

                await this.systemService.speichereAppConfig(appConfig);

                this.ngZone.run(() => {
                    this.nav.navigateRoot('/home');
                });
            } else {
                this.router.navigateByUrl('/einstellungen');
                UiHelper.showError('Fehlerhafte Zugangsdaten. Bitte Konfiguration überprüfen.');

                const resetErrorCodes = [
                    'INVALID_MANDANT',
                    'INVALID_DEVICE',
                    'INVALID_PASSWORD',
                ];

                // Wenn einer dieser Fehler auftritt sollen die lokalen Daten gelöscht werden
                if (resetErrorCodes.indexOf(loginResult.errorCode) >= 0) {
                    this.loescheDaten();
                }

                return false;
            }

            this.systemService.speichereEinstellungen(loginResult.einstellungen);

            return true;
        } catch (err) {
            this.log.warn('tryLogin', err);
            return false;
        }
    }

    private async loescheDaten() {
        try {
            await this.stammdatenService.loescheStammdaten();
            await this.auftragService.loescheAlleAuftraege();
            await this.auftragService.loescheAlteAuftragsDateien();
            await this.uvvService.loescheDaten();
        } catch (err) {
            this.log.error('loescheDaten', err);
        }
    }

    private async updateView(): Promise<void> {
        try {
            const appConfig = await this.systemService.getAppConfig();

            this.farbe1 = appConfig.einstellungen.Farbe1;

            const htmlElement = document.getElementsByTagName('html')[0];
            htmlElement.classList.remove('schriftgroesse-normal');
            htmlElement.classList.remove('schriftgroesse-gross');
            htmlElement.classList.remove('schriftgroesse-sehr-gross');
            htmlElement.classList.add('schriftgroesse-' + appConfig.einstellungen.Schriftgroesse);

            htmlElement.classList.remove('design-standard');
            htmlElement.classList.remove('design-v2');
            htmlElement.classList.remove('design-v3');
            htmlElement.classList.remove('design-v4');
            htmlElement.classList.remove('design-v5');
            htmlElement.classList.add('design-' + appConfig.einstellungen.Design);

            htmlElement.classList.remove('theme-standard');
            htmlElement.classList.remove('theme-modern');
            htmlElement.classList.remove('theme-kompakt');
            htmlElement.classList.add('theme-' + appConfig.einstellungen.Theme);

            const bodyElement = document.getElementsByTagName('body')[0];
            bodyElement.classList.remove('layout-standard');
            bodyElement.classList.remove('layout-kacheln');
            bodyElement.classList.add('layout-' + appConfig.einstellungen.Layout);

            if (appConfig.darkMode) {
                bodyElement.classList.add('dark');
            } else {
                bodyElement.classList.remove('dark');
            }

            const themeStyle = document.getElementById('themeStyle');

            if (themeStyle) {
                // tslint:disable-next-line:prefer-const
                let css = '';

                const farbe1 = appConfig.einstellungen.Farbe1;

                if (farbe1) {
                    css += `ion-toolbar {
                        --background: ${farbe1};
                    }
                    
                    .design-v2 ion-content.section-content .section h1 {
                        color: ${farbe1};
                    }
                    `;
                }

                const farbe2 = appConfig.einstellungen.Farbe2;

                if (farbe2) {
                    css += `ion-content.section-content {
                        --ion-background-color: ${farbe2};
                    }`;

                    // css += `ion-content.home-content {
                    //     --background: ${farbe2} !important;
                    // }`;
                }

                if (appConfig.einstellungen.CustomStlye) {
                    css += '\n' + appConfig.einstellungen.CustomStlye;
                }

                // Auftrag-Items-Height

                themeStyle.innerHTML = css;
            }

            //  #373633
        } catch (err) {
            this.log.error('updateView: ' + Utils.getErrorMessage(err), err);
        }
    }

    private async syncMitServerWennErforderlich() {
        try {
            const appConfig = await this.systemService.getAppConfig();

            if (appConfig.mandant && appConfig.geraeteNummer) {
                if (this.lastMandant !== appConfig.mandant || this.lastGeraeteNummer !== appConfig.geraeteNummer || this.syncErforderlich) {
                    this.lastMandant = appConfig.mandant;
                    this.lastGeraeteNummer = appConfig.geraeteNummer;

                    try {
                        await this.auftragService.syncAuftraegeMitServer('syncMitServerWennErforderlich');

                        this.syncErforderlich = false;
                    } catch (err) {
                        this.log.warn('syncAuftraegeMitServer', err);
                    }
                }
            }
        } catch (err) {
            this.log.error('syncMitServerWennErforderlich: ' + Utils.getErrorMessage(err), err);
        }
    }

    private async showSicherheitshinweisWennErforderlich() {
        try {
            const appConfig = await this.systemService.getAppConfig();

            if (appConfig.einstellungen.SicherheitshinweisAnzeigen === 'ja') {
                let intervallStunden = 12;

                if (appConfig.einstellungen.SicherheitshinweisIntervall || appConfig.einstellungen.SicherheitshinweisIntervall === '0') {
                    intervallStunden = parseFloat(appConfig.einstellungen.SicherheitshinweisIntervall);
                }

                const datum = await AppStorage.current.get('Sicherheitshinweis.Datum', true, true) as number;

                if (datum) {
                    const alter = Date.now() - datum;

                    if (alter < intervallStunden * 60 * 60 * 1000) {
                        this.log.info('Sicherheitshinweis muss nicht angezeigt werden. Alter = ' + alter);
                        return;
                    }
                }

                await this.showSicherheitshinweis();
            }
        } catch (err) {
            this.log.error('showSicherheitshinweisWennErforderlich: ' + Utils.getErrorMessage(err), err);
        }
    }

    private async showSicherheitshinweis() {
        let text = `Die Bedienung dieses Gerätes während der Fahrt kann die Wahrnehmung der Verkehrssituation beeinträchtigen.
Wer während der Fahrt ein Gerät bedient, handelt grob fahrlässig. Kommt es dadurch zu einem Unfall, haftet nicht die Versicherung für den Schaden,
sondern der Unfallverursacher selbst muss die Kosten des Unfalls tragen.
Bitte bedienen Sie das Gerät nur bei stehendem Fahrzeug.
Mit ihrer Anmeldung bestätigen Sie, dass Sie diese Hinweise gelesen und verstanden haben.`;

        const appConfig = await this.systemService.getAppConfig();

        if (appConfig.einstellungen.SicherheitshinweisText) {
            text = appConfig.einstellungen.SicherheitshinweisText;
        }

        const alert = await this.alertController.create({
            header: 'Wichtiger Sicherheitshinweis',
            message: text,
            backdropDismiss: false,
            cssClass: 'sicherheitshinweis',
            buttons: [
                {
                    text: 'OK',
                    handler: () => {
                        AppStorage.current.set('Sicherheitshinweis.Datum', Date.now(), true, true);
                    }
                }
            ]
        });

        await alert.present();
    }

    async initSentry() {
        try {
            const appConfig = await this.systemService.getAppConfig();

            const cordovaAvailable = this.platform.is('cordova');

            // Sentry nur aktivieren, wenn Cordova verfügbar ist. Ansonsten kommt es zu viel zu vielen Fehlen durch Plugins
            if (appConfig.einstellungen.SentryAktiv != 'nein' && cordovaAvailable) {
                let dsn = appConfig.einstellungen.SentryDsn;

                if (!dsn) {
                    dsn = 'https://1132755969034d9eac95950a11d3d5ee@sentry.io/1827319';
                }

                this.log.info('Sentry aktiv, dsn = ' + dsn);

                Sentry.init({
                    dsn,
                    release: "recomobil@" + App.version,
                    beforeSend: (event, hint) => {
                        event.extra = event.extra ?? {};

                        const appConfig = AppConfig.current;
                        event.extra.GeraeteNummer = appConfig.geraeteNummer;
                        event.extra.Mandant = appConfig.mandant;
                        event.extra.Version = App.version;
                        return event;
                    }
                });
            }
        } catch (err) {
            this.log.error('initSentry', err);
        }
    }

    async starteHomeNavigation() {
        try {
            this.log.debug('starteHomeNavigation');

            const adressen = this.stammdatenService.homeNavigationAdressen.getValue();

            if (adressen.length == 0) {
                UiHelper.showErrorOhneSentry('Keine Adresse für die Home-Navigation konfiguriert');
                return;
            }

            if (adressen.length == 1) {
                this.onAdresseHomeNavigationAusgewaehlt(adressen[0]);
                return;
            }

            const buttons: any[] = [];

            for (const adresse of adressen) {
                buttons.push({
                    text: adresse.Name1,
                    cssClass: 'secondary',
                    handler: async () => {
                        this.onAdresseHomeNavigationAusgewaehlt(adresse);
                    }
                });
            }

            buttons.push({
                text: I18N.instant('Abbrechen'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                }
            });

            const alert = await this.alertController.create({
                cssClass: 'no-text-alert no-title-alert',
                mode: 'ios',
                buttons: buttons
            });

            await alert.present();

            await alert.onDidDismiss();
        } catch (err) {
            this.log.error('starteHomeNavigation: ' + Utils.getErrorMessage(err), err);
        }
    }

    // onAdresseHomeNavigationCancel(e: any) {
    //     this.log.debug('onAdresseHomeNavigationCancel', e);
    //     this.app.homeAdressAuswahlSichtbar = false;
    // }

    // async onAdresseHomeNavigationSelected(e: CustomEvent) {
    //     this.log.debug('onAdresseHomeNavigationSelected', e);

    //     this.onAdresseHomeNavigationAusgewaehlt(e.detail.value);

    //     this.app.homeAdressAuswahlSichtbar = false;
    // }

    async onAdresseHomeNavigationAusgewaehlt(adresse: AdresseEntity) {
        this.log.debug('onAdresseHomeNavigationAusgewaehlt', adresse);

        if (!adresse) {
            return;
        }

        try {
            await this.starteNavigation(adresse);
        } catch (err) {
            UiHelper.showError(err);
        }
    }

    private async starteNavigation(adresse: AdresseEntity): Promise<void> {
        const zielAdresse: Adresse = {
            AdressNummer: adresse.Key,
            Name1: adresse.Name1,
            Name2: adresse.Name2,
            Name3: adresse.Name3,
            GeoX: adresse.GeoX,
            GeoY: adresse.GeoY,
            LKZ: adresse.LKZ,
            Ort: adresse.Ort,
            PLZ: adresse.PLZ,
            Strasse: adresse.Strasse,
            Telefon: adresse.Telefon,
            Ansprechpartner: adresse.Ansprechpartner
        };

        await this.systemService.starteNavigation(zielAdresse);

        this.systemService.sendeTextdatei({
            typ: TextdateiTyp.StartNavigation,
            Navigation: {
                Adresse: zielAdresse,
                AuftragKey: ''
            }
        }, true);
    }
}
