import { Component, OnInit, OnDestroy, ViewChild, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { ArtikelEx, ArtikelListItem, AuftragEx } from 'src/app/api/model/model';

import { SystemService } from 'src/app/api/system.service';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { Artikelgruppe, Artikel, Vertrag, Vertragsposition, Auftragstyp } from 'src/app/api/model/swagger-model';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { App } from 'src/app/api/helper/app';
import { AppConfig } from 'src/app/api/helper/app.config';
import { ArtikelSuchenComponent } from 'src/app/shared/components/artikel-suchen/artikel-suchen.component';
import { Utils } from 'src/app/api/helper/utils';

@Component({
    selector: 'app-aufrag-artikel-auswahl',
    templateUrl: './artikel-auswahl.component.html',
    styleUrls: ['./artikel-auswahl.component.scss'],
})
export class AuftragArtikelAuswahlPage implements OnInit, OnDestroy, AfterViewInit {
    log = new Logger('AuftragArtikelAuswahlPage');

    @ViewChild('searchbar', { static: false }) searchbar: IonSearchbar;

    subscriptions: Subscription[] = [];

    @Input() auftrag: AuftragEx = null;

    ausgewaehlteArtikelgruppe: ArtikelgruppeVo = null;

    artikelgruppenListe: ArtikelgruppeVo[] = [];
    vertragspositionListe: Vertragsposition[] = [];
    artikelListe: ArtikelEx[] = [];

    isVisible = false;
    artikelgruppenAnzeigen = true;
    vertraegeAnzeigen = true;
    suchtext = '';

    ansichtArtikelgruppeAuswaehlen = true;
    ansichtArtikelAuswaehlen = false;
    ansichtVertragspositionAuswaehlen = false;
    ansichtSuchen = false;
    hofAktivVerwenden = false;

    // Suche
    sucheItems: ArtikelListItem[];
    sucheSichtbareItems: ArtikelListItem[];

    constructor(
        public domSanitizationService: DomSanitizer,
        private systemService: SystemService,
        private cdr: ChangeDetectorRef,
        private stammdatenService: StammdatenService,
        private modalController: ModalController) { }

    ngOnInit() {
        this.artikelgruppenAnzeigen = AppConfig.current.einstellungen.ArtikelAuswahlUeberArtikelgruppen == 'ja';
        this.vertraegeAnzeigen = AppConfig.current.einstellungen.ArtikelAuswahlVertraegeAnzeigen == 'ja';
        this.hofAktivVerwenden = AppConfig.current.einstellungen.HofAktivVerwenden === 'ja';

        this.subscriptions.push(this.stammdatenService.artikelgruppenListe.subscribe(artikelgruppen => {
            this.artikelgruppenListe = [];

            if (this.artikelgruppenAnzeigen) {
                for (const g of artikelgruppen) {
                    const vo: ArtikelgruppeVo = {
                        artikelgruppe: g,
                        style: this.getButtonStyle(g) // '--background: ' + g.Farbe // 'background-color: ' + g.Farbe
                    };

                    this.artikelgruppenListe.push(vo);
                }
            } else {
                const vo: ArtikelgruppeVo = {
                    artikelgruppe: {
                        Name: 'Alle Artikel',
                        Beschreibung: 'Alle Artikel',
                        Deaktiviert: false
                    },
                    istAlleArtikelGruppe: true,
                    style: '--color: black;'
                };

                if (AppConfig.current.darkMode) {
                    vo.style = '--color: white;'
                }

                this.artikelgruppenListe.push(vo);
            }

            if (this.vertraegeAnzeigen) {
                if (this.auftrag && this.auftrag.Vertraege) {
                    for (const vertrag of this.auftrag.Vertraege) {
                        if (!vertrag.Bezeichnung) {
                            vertrag.Bezeichnung = 'Vertrag ' + vertrag.Vertragsnummer;
                        }

                        const vo: ArtikelgruppeVo = {
                            artikelgruppe: null,
                            vertrag: vertrag,
                            style: this.getButtonStyleVertrag(vertrag)
                        };

                        this.artikelgruppenListe.push(vo);
                    }
                }
            }

            if (this.artikelgruppenListe.length == 1) {
                this.onArtikelgruppeSelected(this.artikelgruppenListe[0]);
            }

            this.aktualisiereAnsicht();
        }));

        this.aktualisiereAnsicht();

        if (!this.artikelgruppenAnzeigen) {
            this.onSuchen();
        }
    }

    getButtonStyleVertrag(vertrag: Vertrag) {
        const css = '--color: black;';
        return css;
    }

    getButtonStyle(g: Artikelgruppe) {
        let css: string;

        if (AppConfig.current.darkMode) {
            css = '--color: white; --background: #262626;'
        } else {
            css = '--color: black;';

            if (g && g.Farbe) {
                css += '--background: ' + g.Farbe + ';';
            } else {
                // css += '--background: #e7e7e7;';
            }
        }

        return css;
    }

    async ngAfterViewInit() {
        this.log.info('ngAfterViewInit');
    }

    ngOnDestroy() {
        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    async ionViewWillEnter() {
    }

    ionViewDidEnter() {
    }

    ionViewWillLeave() {
    }

    onArtikelgruppeSelected(vo: ArtikelgruppeVo) {
        this.log.debug('onArtikelgruppeSelected', vo);
        this.ausgewaehlteArtikelgruppe = vo;

        if (vo.istAlleArtikelGruppe) {
            this.artikelListe = this.stammdatenService.artikelListe.getValue()
                .sort((a, b) => {
                    if (a.bezeichnung > b.bezeichnung) {
                        return 1;
                    } else if (a.bezeichnung < b.bezeichnung) {
                        return -1;
                    }

                    return 0;
                });
        } else if (vo.vertrag) {
            this.vertragspositionListe = vo.vertrag.Positionen
                .sort((a, b) => {
                    if (a.PosNr > b.PosNr) {
                        return 1;
                    } else if (a.PosNr < b.PosNr) {
                        return -1;
                    }

                    return 0;
                });
        } else {
            this.artikelListe = this.stammdatenService.artikelListe.getValue()
                .filter(p => p.artikelGruppe === this.ausgewaehlteArtikelgruppe.artikelgruppe.Name)
                .sort((a, b) => {
                    if (a.bezeichnung > b.bezeichnung) {
                        return 1;
                    } else if (a.bezeichnung < b.bezeichnung) {
                        return -1;
                    }

                    return 0;
                });
        }

        if (this.artikelListe) {
            if (this.hofAktivVerwenden && this.auftrag?.Auftragstyp === Auftragstyp.Hofchecker) {
                this.artikelListe = this.artikelListe.filter(p => p.HofAktiv);
            }
        }

        for (const artikel of this.artikelListe) {
            artikel.text = this.getArtikelText(artikel);
        }

        this.aktualisiereAnsicht();
    }


    onSuchItemSelected(item: ArtikelListItem) {
        if (item.vertragsposition) {
            this.onVertragspositionSelected(item.vertrag, item.vertragsposition);
        } else if (item.artikel) {
            this.onArtikelSelected(item.artikel);
        }
    }

    onVertragspositionSelected(vertrag: Vertrag, vertragsposition: Vertragsposition) {
        this.modalController.dismiss({
            'dismissed': true,
            'vertrag': vertrag,
            'vertragsposition': vertragsposition
        });
    }

    onArtikelSelected(artikel: Artikel) {
        this.modalController.dismiss({
            'dismissed': true,
            'artikel': artikel
        });
    }

    onClose() {
        if (this.ansichtSuchen) {
            if (this.artikelgruppenAnzeigen) {
                this.ansichtSuchen = false;
                this.aktualisiereAnsicht();
            } else {
                this.dismiss();
            }
        } else if (this.ausgewaehlteArtikelgruppe) {
            this.ausgewaehlteArtikelgruppe = null;
            this.aktualisiereAnsicht();
        } else {
            this.dismiss();
        }
    }

    dismiss() {
        this.modalController.dismiss({
            'dismissed': true,
            'artikel': null
        });
    }

    getIconSrc(vo: ArtikelgruppeVo) {
        if (vo.artikelgruppe.Icon) {
            return "data:image/png;base64," + vo.artikelgruppe.Icon;
        } else {
            // Empty
            return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=";
        }
    }

    async onSuchen() {
        this.log.debug('onSuchen');

        let artikelList = this.stammdatenService.artikelListe.getValue()
            .sort((a, b) => {
                if (a.bezeichnung > b.bezeichnung) {
                    return 1;
                } else if (a.bezeichnung < b.bezeichnung) {
                    return -1;
                }

                return 0;
            });

        if (artikelList) {
            if (this.hofAktivVerwenden && this.auftrag?.Auftragstyp === Auftragstyp.Hofchecker) {
                artikelList = artikelList.filter(p => p.HofAktiv);
            }
        }

        const items: ArtikelListItem[] = [];

        for (const artikel of artikelList) {
            const artikelgruppe = this.artikelgruppenListe.find(p => p.artikelgruppe && p.artikelgruppe.Name === artikel.artikelGruppe);

            const item: ArtikelListItem = {
                artikel: artikel,
                artikelgruppe: artikelgruppe?.artikelgruppe,
                style: this.getButtonStyle(artikelgruppe?.artikelgruppe)
            };

            items.push(item);
        }

        if (this.vertraegeAnzeigen) {
            if (this.auftrag && this.auftrag.Vertraege) {
                for (const vertrag of this.auftrag.Vertraege) {

                    if (vertrag.Positionen) {
                        for (const pos of vertrag.Positionen) {
                            const item: ArtikelListItem = {
                                artikel: null,
                                vertrag: vertrag,
                                vertragsposition: pos,
                                style: this.getButtonStyleVertrag(vertrag)
                            };

                            items.push(item);
                        }
                    }
                }
            }
        }

        for (const item of items) {
            const artikel = item.artikel;

            if (artikel) {

                item.text = this.getArtikelText(artikel);

                item.subtext = '';

                if (item.artikelgruppe) {
                    item.subtext = item.artikelgruppe.Name;
                }

                item.suchtext = item.artikel.bezeichnung
                    + ';' + item.artikel.Bezeichnung2
                    + ';' + item.artikel.artikelGruppe
                    + ';' + item.artikel.artikelKey
                    + ';' + item.artikel.BhAktion;

                let artikelKey = item.artikel.artikelKey as any;

                if (artikelKey) {
                    artikelKey = artikelKey.toString().padStart(10, '0');
                }

                item.sortText = '1;' + artikelKey + ';' + item.artikel.bezeichnung;

            } else if (item.vertrag) {
                item.text = item.vertragsposition.PosNr + ': ' + item.vertragsposition.Bezeichnung;
                item.subtext = '';

                item.suchtext = item.vertrag.Bezeichnung
                    + ';' + item.vertrag.Vertragsnummer
                    + ';' + item.vertragsposition.Bezeichnung
                    + ';' + item.vertragsposition.ArtikelKey
                    + ';' + item.vertragsposition.BhAktion;

                let posNr = item.vertragsposition.PosNr as any;

                if (posNr) {
                    posNr = posNr.toString().padStart(10, '0');
                }

                item.sortText = '0;' + item.vertrag.Vertragsnummer + ';' + posNr;
            } else {
                item.text = '???';
                item.subtext = '';
                item.suchtext = '';
                item.sortText = 'zzzzz';
            }

            item.suchtext = item.suchtext.toLowerCase();
        }

        items.sort((a, b) => {
            if (a.sortText < b.sortText) {
                return -1;
            } else if (a.sortText > b.sortText) {
                return 1;
            }

            return 0;
        })

        this.sucheItems = items;

        this.suchtext = '';
        this.ansichtSuchen = true;
        this.ansichtArtikelgruppeAuswaehlen = false;
        this.ansichtArtikelAuswaehlen = false;
        this.ansichtVertragspositionAuswaehlen = false;

        this.filterSichtbareSuchItems();

        this.cdr.detectChanges();

        setTimeout(() => {
            if (this.searchbar) {
                try {
                    this.searchbar.setFocus();
                } catch (err) {
                    this.log.error('setTimeout onSuchen: ' + Utils.getErrorMessage(err), err);
                }
            }
        }, 500);

        // const modal = await this.modalController.create({
        //     component: ArtikelSuchenComponent,
        //     cssClass: 'artikelsuchen-modal',
        //     swipeToClose: true,
        //     animated: true,
        //     backdropDismiss: true,
        //     showBackdrop: true,
        //     componentProps: {
        //         items: items
        //     }
        // });

        // await modal.present();

        // const result = await modal.onDidDismiss();

        // if (result && result.data) {
        //     const item = result.data as ArtikelListItem;

        //     // Timeout ist notwendig, damit erst der aktuelle Modal-Dialgo vollständig geschlossen wurde
        //     setTimeout(() => {
        //         if (item.vertragsposition) {
        //             this.onVertragspositionSelected(item.vertrag, item.vertragsposition);
        //         } else if (item.artikel) {
        //             this.onArtikelSelected(item.artikel);
        //         }
        //     });
        // }
    }

    getArtikelText(artikel: Artikel): string {
        let format = AppConfig.current.einstellungen.ArtikelListeFormat;

        if (!format) {
            format = '#artikelKey#: #bezeichnung#';
        }

        format = format.replace('#artikelKey#', Utils.getString(artikel.artikelKey))
            .replace('#bezeichnung#', Utils.trimToEmpty(artikel.bezeichnung))
            .replace('#Bezeichnung2#', Utils.trimToEmpty(artikel.Bezeichnung2))
            .replace('#einheit#', Utils.trimToEmpty(artikel.einheit))
            .replace('#artikelGruppe#', Utils.trimToEmpty(artikel.artikelGruppe))
            .replace('#BhAktion#', Utils.trimToEmpty(artikel.BhAktion))
            .replace('#LPN#', Utils.trimToEmpty(artikel.LPN))
            .replace('#GTIN#', Utils.trimToEmpty(artikel.GTIN))
            .replace('#Matchcode#', Utils.trimToEmpty(artikel.Matchcode));

        return format;
    }

    onSearchChanged(e: Event) {
        this.filterSichtbareSuchItems();

        this.cdr.detectChanges();
    }

    filterSichtbareSuchItems() {
        let sucheSichtbareItems: ArtikelListItem[];

        if (this.suchtext && this.suchtext.trim()) {
            sucheSichtbareItems = [];

            const parts: string[] = this.suchtext.toLowerCase().trim().split(' ');

            for (const item of this.sucheItems) {
                let isMatch = true;

                for (const str of parts) {
                    if (!item.suchtext.includes(str)) {
                        isMatch = false;
                        break;
                    }
                }

                if (isMatch) {
                    sucheSichtbareItems.push(item);
                }
            }
        } else {
            sucheSichtbareItems = this.sucheItems;
        }

        this.sucheSichtbareItems = sucheSichtbareItems;
    }

    aktualisiereAnsicht() {
        this.ansichtArtikelgruppeAuswaehlen = false;
        this.ansichtArtikelAuswaehlen = false;
        this.ansichtVertragspositionAuswaehlen = false;
        this.ansichtSuchen = false;

        if (this.ausgewaehlteArtikelgruppe) {
            if (this.ausgewaehlteArtikelgruppe.vertrag) {
                this.ansichtVertragspositionAuswaehlen = true;
            } else if (this.ausgewaehlteArtikelgruppe.artikelgruppe) {
                this.ansichtArtikelAuswaehlen = true;
            }
        } else {
            this.ansichtArtikelgruppeAuswaehlen = true;
        }
    }

    myHeaderFn(item: ArtikelListItem, recordIndex, records: ArtikelListItem[]) {
        const that = ArtikelSuchenComponent.instance;

        let headerGeandert = false;

        if (recordIndex <= 0) {
            headerGeandert = true;
        } else {
            const vorherigesItem = records[recordIndex - 1];

            if (!item.vertrag && vorherigesItem.vertrag) {
                headerGeandert = true;
            } else if (item.vertrag && !vorherigesItem.vertrag) {
                headerGeandert = true;
            } else if (item.artikelgruppe != vorherigesItem.artikelgruppe) {
                headerGeandert = true;
            }
        }

        if (headerGeandert) {
            if (item.vertrag) {
                return {
                    title: 'Vertrag ' + item.vertrag.Vertragsnummer + ': ' + item.vertrag.Bezeichnung
                };
            } else if (item.artikelgruppe) {
                return {
                    title: item.artikelgruppe.Name
                };
            } else {
                return {
                    title: 'Ohne Kategorie'
                };
            }
        }

        return null;
    }
}

interface ArtikelgruppeVo {
    vertrag?: Vertrag;
    artikelgruppe: Artikelgruppe;
    style: string;
    istAlleArtikelGruppe?: boolean;
}
