import { OnInit, OnChanges, OnDestroy, SimpleChanges, ChangeDetectorRef, Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { Logger } from "src/app/api/helper/app-error-logger";
import { AuftragEx } from "src/app/api/model/model";
import { AuftragsKomponente, Workflow } from "src/app/api/model/swagger-model";
import { Auftragdetails } from "../auftragdetails.helper";

const log = new Logger('KomponenteBase');

@Injectable()
export abstract class KomponenteBase implements OnInit, OnChanges, OnDestroy {
    abstract auftragdetails: Auftragdetails;
    abstract komponente: AuftragsKomponente;

    subscriptions: Subscription[] = [];

    geladen = false;

    get auftrag(): AuftragEx { return this.auftragdetails?.auftrag; }

    constructor(protected cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        // log.debug('ngOnInit');

        this.subscriptions.push(this.auftragdetails.detectChanges.subscribe(() => {
            this.cdr.detectChanges();
        }));

        this.subscriptions.push(this.auftragdetails.workflowChanged.subscribe(workflow => {
            this.onWorkflowChanged();
        }))
    }

    onWorkflowChanged() {
    }

    ngOnChanges(changes: SimpleChanges) {
        // log.debug('ngOnChanges');
    }

    init() {
        // log.debug('init');
        this.geladen = true;
    }

    ngOnDestroy(): void {
        // log.debug('ngOnDestroy');
        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    getEinstellung(key: string, defaultValue: string = ''): string {
        if (!this.komponente || !this.komponente.einstellungen) {
            return defaultValue;
        }

        const einstellung = this.komponente.einstellungen.find(p => p.key === key);

        if (einstellung) {
            if (einstellung.wert !== null && einstellung.wert !== '' && einstellung.wert !== undefined) {
                return einstellung.wert.trim();
            }
        }

        return defaultValue;
    }
}