import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IonContent, NavController, ModalController, AlertController } from '@ionic/angular';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { Personal, PersonalKontrolleDaten, Textdatei, TextdateiTyp, Unterschrift } from 'src/app/api/model/swagger-model';
import { SystemService } from 'src/app/api/system.service';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { Logger } from 'src/app/api/helper/app-error-logger';

import * as moment from 'moment';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { App } from 'src/app/api/helper/app';
import { Utils } from 'src/app/api/helper/utils';
import { DomSanitizer } from '@angular/platform-browser';
import { CameraHelper } from '../../helper/camera-helper';
import { AppStorage } from 'src/app/api/helper/app-storage';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { NfcEvent, ReCoMobilBildEx, UnterschriftEx } from 'src/app/api/model/model';
import { AppConfig } from 'src/app/api/helper/app.config';
import { UnterschriftModalPage } from '../unterschrift-modal/unterschrift-modal.page';
import { BildHelper } from 'src/app/api/helper/bild-helper';
import { I18N } from 'src/app/api/helper/i18n';

const log = new Logger('FuehrerscheinkontrolleModalPage');

const NFC_ID = 'FUEHRERSCHEINKONTROLLE';

@Component({
    selector: 'app-fuehrerscheinkontrolle-modal',
    templateUrl: './fuehrerscheinkontrolle-modal.page.html',
    styleUrls: ['./fuehrerscheinkontrolle-modal.page.scss'],
})
export class FuehrerscheinkontrolleModalPage implements OnInit, OnDestroy {

    @ViewChild('content', { read: IonContent, static: true }) content: IonContent;

    @Input() personal: Personal;
    @Input() restTage: number;

    entity: PersonalKontrolleDaten;

    subscriptions: Subscription[] = [];

    isVisible = false;
    bezeichnung = '';
    datumNaechstePruefung = '';
    abgelehnt = true;
    speichenButtonColor = 'primary';

    bild1: ReCoMobilBildEx = {};
    bild2: ReCoMobilBildEx = {};

    app = App.current;

    isAblaufdatumErfassen = true;
    isUnterschriftErfassen = true;
    isFotos = true;
    isNFCScannen = false;
    isNfcErfolgreich = false;

    ablaufdatumKey = '9000';
    letztePruefungKey = '9001';

    constructor(
        private datePicker: DatePicker,
        private modalController: ModalController,
        private cdr: ChangeDetectorRef,
        private fileOpener: FileOpener,
        public domSanitizationService: DomSanitizer,
        private stammdatenService: StammdatenService,
        private alertController: AlertController,
        private systemService: SystemService,
        private router: Router,
        private dialogs: Dialogs,
        private backgroundMode: BackgroundMode,
        private nav: NavController) { }

    ngOnInit() {
        this.entity = {
            Typ: 'Führerscheinkontrolle',
            PersonalKey: this.personal.Key,
            Personalnummer: this.personal.personalnummer,
            Vorname: this.personal.vorname,
            Nachname: this.personal.nachname,
            UnterMandant: this.personal.UnterMandant,
            Bilder: [],
            Unterschrift: {
                Bild: null,
                Datum: null,
                Guid: null,
                Name: '',
                Typ: 'Fahrer',
            }
        };

        const einstellungen = AppConfig.current.einstellungen;

        this.isAblaufdatumErfassen = einstellungen.FuehrerscheinkontrolleAblaufdatumErfassen === 'ja';
        this.isUnterschriftErfassen = einstellungen.FuehrerscheinkontrolleUnterschriftErfassen === 'ja';
        this.isFotos = einstellungen.FuehrerscheinkontrolleFotos === 'ja';
        this.isNFCScannen = einstellungen.FuehrerscheinkontrolleNFCScannen === 'ja';
        this.ablaufdatumKey = einstellungen.FuehrerscheinkontrolleAblaufdatumKey;
        this.letztePruefungKey = einstellungen.FuehrerscheinkontrolleLetztePruefungKey;

        const eigenschaft = this.personal.Eigenschaften.find(p => p.Key == this.ablaufdatumKey);

        if (eigenschaft?.Ablaufdatum) {
            this.entity.Ablaufdatum = moment(eigenschaft.Ablaufdatum).format('DD.MM.YYYY');
        }

        if (this.isNFCScannen) {
            this.isNfcErfolgreich = false;

            App.current.scanNfcBackground(
                NFC_ID,
                (nfcEvent) => this.verarbeiteNfcEvent(nfcEvent),
                {
                    prio: 100,
                    simulateSeriennummer: this.personal.rfid,
                    simulateTimeout: 5000
                }
            );
        }
    }

    async verarbeiteNfcEvent(event: NfcEvent) {
        log.debug(`verarbeiteNfcEvent: seriennummer=${event.seriennummer}, seriennummer2=${event.seriennummer2}`);

        // WICHTIG: hier direkt auf handled = true setzen, ansonsten verarbeitet die Anmelden-Page das Event nochmal
        event.handled = true;

        let seriennummer = event.seriennummer;
        let rfid = event.seriennummer;

        const sollRfid = Utils.trimToEmpty(this.personal.rfid).toUpperCase();

        if (!sollRfid) {
            await UiHelper.showAlertEx({ text: I18N.instant('Kein RFID-Tag konfiguriert'), transalte: false });
            return;
        }

        let ok = sollRfid === seriennummer;

        if (!ok && event.seriennummer2) {
            ok = sollRfid === event.seriennummer2;

            if (ok) {
                seriennummer = event.seriennummer2;
            }
        }

        if (!ok) {
            if (App.current.NfcPayloadVerwenden && event.payloadList.length) {
                for (const payload of event.payloadList) {
                    if (payload.length > 5) {
                        rfid = payload.trim().toUpperCase();

                        ok = sollRfid === rfid;

                        if (ok) {
                            seriennummer = rfid;
                            break;
                        }
                    }
                }
            }
        }

        if (ok) {
            this.entity.Rfid = seriennummer;

            this.isNfcErfolgreich = true;

            this.cdr.detectChanges();

            if (!this.isAblaufdatumErfassen && !this.isFotos && !this.isUnterschriftErfassen) {
                // Es muss nur der Führerschein gescannt werden.
                // Es kann also direkt gespeichert werden
                await Utils.delay(1500);

                this.onSpeichern();
            }
        } else {
            await UiHelper.showAlertEx({ text: I18N.instant('FuahrerscheinkontrolleNfcFalsch'), transalte: false });
        }
    }

    ngOnDestroy() {
        log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    async ionViewWillEnter() {
        log.debug('ionViewWillEnter');
        this.isVisible = true;
        this.updateView();
    }

    ionViewDidEnter() {
        log.debug('ionViewDidEnter');
        App.loading(false);
    }

    ionViewWillLeave() {
        log.debug('ionViewWillLeave');
        App.current.cancelNFC(NFC_ID);
        this.isVisible = false;
    }

    async updateView() {
        if (!this.isVisible) {
            return;
        }

        // this.bezeichnung = UvvHelper.getBezeichnung(this.gegenstand);

        // const naechstePruefung = moment(this.gegenstand.naechstePruefung);
        // const now = moment();

        // this.datumNaechstePruefung = naechstePruefung.format('DD.MM.YYYY');

        // this.restTage = naechstePruefung.diff(now, 'days');

        this.cdr.detectChanges();
    }

    dismiss(result: string) {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss(result);
    }

    async onSpeichern() {
        if (this.isFotos) {
            if (!this.bild1.Bild) {
                await UiHelper.showAlert('Bitte Führerschein-Vorderseite fotografieren');
                return;
            }

            if (!this.bild2.Bild) {
                await UiHelper.showAlert('Bitte Führerschein-Rückseite fotografieren');
                return;
            }
        }

        if (this.isNFCScannen && !this.isNfcErfolgreich) {
            await UiHelper.showAlert('Bitte NFC-Chip scannen');
            return;
        }

        if (this.isAblaufdatumErfassen) {
            if (!this.entity.Ablaufdatum) {
                await UiHelper.showAlert('Bitte Ablaufdatum erfassen');
                return;
            }

            if (!this.entity.Ablaufdatum.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/)) {
                await UiHelper.showAlert('Ungültiges Ablaufdatum');
                return;
            }

            try {
                // Versuche das eingegebene Datum zu parsen
                const ablaufdatum = moment(this.entity.Ablaufdatum, 'DD.MM.YYYY');

                if (!ablaufdatum.isValid()) {
                    await UiHelper.showAlert('Ungültiges Ablaufdatum');
                    return;
                }

                const now = moment();

                if (ablaufdatum.isBefore(now, 'day')) {
                    await UiHelper.showAlert('Führerschein ist abgelaufen.');
                    return;
                }
            } catch (err) {
                await UiHelper.showAlert('Ungültiges Ablaufdatum');
                return;
            }
        } else {
            this.entity.Ablaufdatum = null;
        }

        if (this.isUnterschriftErfassen) {
            if (!this.entity.Unterschrift?.Bild) {
                await UiHelper.showAlert('Bitte Unterschrift erfassen');
                return;
            }
        }

        if (this.isFotos) {
            this.entity.Bilder = [
                {
                    Bezeichnung: 'Vorderseite',
                    Bild: await AppStorage.current.getBildBase64Data(this.bild1.BildGuid),
                    BildGuid: this.bild1.BildGuid,
                    Datum: this.bild1.Datum,
                    Typ: 'Führerschein Vorderseite'
                },
                {
                    Bezeichnung: 'Rückseite',
                    Bild: await AppStorage.current.getBildBase64Data(this.bild2.BildGuid),
                    BildGuid: this.bild2.BildGuid,
                    Datum: this.bild2.Datum,
                    Typ: 'Führerschein Rückseite'
                },
            ];
        } else {
            this.entity.Bilder = [];
        }

        // this.abgelehnt = false;

        // const fahrer = App.current.fahrer.getValue();
        // const fahrzeug = App.current.fahrzeug.getValue();

        const textdatei: Textdatei = {
            typ: TextdateiTyp.PersonalKontrolle,
            datum: moment().toISOString(),
            geraeteNummer: AppConfig.current.geraeteNummer,
            PersonalKontrolle: this.entity,
        };

        await this.systemService.sendeTextdatei(textdatei, false);

        this.dismiss('OK');
    }

    async onIgnorieren() {
        if (this.restTage <= 0) {
            UiHelper.showAlert('Die Führerscheinprüfung kann nicht weiter verschoben werden.')
            return;
        }

        const ok = await UiHelper.confirmJaNein('Führerscheinprüfung wirklich verschieben?');

        if (!ok) {
            return;
        }

        // const fahrer = App.current.fahrer.getValue();
        // const fahrzeug = App.current.fahrzeug.getValue();

        // const textdatei: Textdatei = {
        //     typ: TextdateiTyp.TextInformation,
        //     datum: moment().toISOString(),
        //     geraeteNummer: AppConfig.current.geraeteNummer,
        //     TextInformation: {
        //         Typ: 'UvvAbgelehnt',
        //         Text: 'UVV abgelehnt: ' + this.bezeichnung + ', Datum nächste Prüfung: ' + this.datumNaechstePruefung,
        //         Fahrzeug: fahrzeug?.kennzeichen,
        //         FahrzeugKey: fahrzeug?.key,
        //         Statuszeit: moment().toISOString(),
        //         Personalnummer: fahrer?.personalnummer,
        //         PersonalKey: fahrer?.Key,
        //         Geraet: AppConfig.current.geraeteNummer,
        //         NaechstePruefung: this.datumNaechstePruefung,
        //         RestTage: this.restTage,
        //         Key: this.gegenstand.key,
        //         IdentNummer: this.gegenstand.identNummer
        //     },
        // };

        // await this.systemService.sendeTextdatei(textdatei, false);

        // this.abgelehnt = true;

        this.dismiss('Verschoben');
    }

    async goBack() {
        log.debug('goBack');

        // if (this.alertAktiv) {
        //     log.debug('goBack abbruch wegen alertAktiv');
        //     // ignoriere Hardware-Back-Button
        //     return;
        // }

        if (CameraHelper.isAktiv) {
            log.debug('goBack abbruch wegen kameraAktiv');
            // ignoriere Hardware-Back-Button
            return;
        }

        // if (this.auftrag) {
        //     if (this.auftrag.OberAuftragKey) {
        //         await this.nav.navigateBack('/auftragsliste/details/' + this.auftrag.OberAuftragKey);
        //     } else {
        //         await this.nav.navigateBack('/auftragsliste/aktuell/' + this.auftrag.Auftragstyp);
        //     }
        // } else {
        //     await this.nav.navigateRoot('/');
        // }
    }

    async onFotoAufnehmen(bild: ReCoMobilBildEx) {
        UiHelper.blurActiveElement();

        // imageData is a base64 encoded string
        // this.base64Image = "data:image/jpeg;base64," + imageData;
        let pictureSrc = await CameraHelper.takePicture({
            filenamePrefix: 'fuehrerscheinkontrolle-' + this.personal.Key
        });

        if (!pictureSrc) {
            return;
        }

        log.debug('pictureSrc', pictureSrc);

        bild.Datum = new Date().toISOString();
        bild.BildGuid = Utils.uuid();
        bild.Bild = pictureSrc;
        bild.imgSrc = await BildHelper.getImgSrcFromBild(bild);

        await AppStorage.current.set('bild-' + bild.BildGuid, pictureSrc, false);

        // await AuftragService.instance.speichereBild(bild.BildGuid, pictureSrc);

        // if (!this.auftrag.Auftragsbilder) {
        //     this.auftrag.Auftragsbilder = [];
        // }

        // this.auftrag.Auftragsbilder.push(bild);

        // await AuftragService.instance.speichereAuftrag(this.auftrag);

        // this.auftragsbilder.push(await this.createBildVo(bild));

        this.cdr.detectChanges();
    }

    frageBildAktion(): Promise<string> {
        return new Promise(async resolve => {
            const buttons: any[] = [];

            buttons.push({
                text: 'Löschen',
                role: 'destructive',
                icon: 'trash',
                handler: () => {
                    resolve('loeschen');
                }
            });

            buttons.push({
                text: 'Anzeigen',
                icon: 'image',
                handler: () => {
                    resolve('anzeigen');
                }
            });

            buttons.push({
                text: 'Abbruch',
                icon: 'close',
                role: 'cancel',
                handler: () => {
                    resolve(null);
                }
            });

            const alert = await this.alertController.create({
                cssClass: 'no-text-alert no-title-alert',
                mode: 'ios',
                buttons: buttons
            });

            await alert.present();
        });
    }

    async onBildClicked(bild: ReCoMobilBildEx) {
        const aktion = await this.frageBildAktion();

        if (aktion === 'loeschen') {
            await AppStorage.current.loescheBild(bild.BildGuid);

            bild.Bild = null;
            bild.BildGuid = null;
        } else if (aktion === 'anzeigen') {
            this.doBildAnzeigen(bild);
        }

        this.cdr.detectChanges();
    }

    async doBildAnzeigen(bild: ReCoMobilBildEx) {
        if (!App.isCordovaAvailable()) {
            UiHelper.showAlert('Bild anzeigen in Simulation nicht verfügbar', 'Simulation');
            return;
        }

        const filePath = await AppStorage.current.getBildSrc(bild.BildGuid);
        await this.fileOpener.open(filePath, 'image/jpeg');
    }

    async selectDatum() {
        log.debug('selectDatum');

        try {
            let date = new Date();

            let strDatum = this.entity.Ablaufdatum;

            if (strDatum && strDatum.length >= 8 && strDatum.length <= 10) {
                // Versuche das eingegebene Datum zu parsen
                date = moment(strDatum).toDate();
            }

            date = await this.datePicker.show({
                date: new Date(),
                mode: 'date',
                androidTheme: this.datePicker.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT
            });

            if (date) {
                const m = moment(date);
                this.entity.Ablaufdatum = m.format('DD.MM.YYYY');

                this.cdr.detectChanges();
            }
        } catch (err) {
            // UiHelper.showError(err);
        }
    }

    async starteUnterschrift() {
        UiHelper.blurActiveElement();

        if (!this.entity.Unterschrift) {
            this.entity.Unterschrift = {
                Bild: null,
                Datum: null,
                Guid: null,
                Name: '',
                Typ: 'Fahrer',
            };
        }

        this.entity.Unterschrift.Name = this.personal.vorname + ' ' + this.personal.nachname;

        const modal = await this.modalController.create({
            component: UnterschriftModalPage,
            componentProps: {
                'unterschrift': this.entity.Unterschrift,
                'titel': 'Unterschrift Führerscheinkontrolle'
            }
        });

        await modal.present();

        const result = await modal.onDidDismiss();

        if (result.data) {
            await Utils.delay(100);

            this.cdr.detectChanges();

            // this.unterschrift.Datum = new Date().toISOString();
            // this.unterschrift.Guid = Utils.uuid();
            // this.unterschrift.Bild = pictureSrc;
            // this.unterschrift.imgSrc = await BildHelper.getImgSrcFromBild(this.unterschrift.Bild);

        }
    }
}
