import { Component, OnInit, OnDestroy, ViewChild, Input, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IonContent, ModalController } from '@ionic/angular';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { AuftragEx } from 'src/app/api/model/model';

@Component({
    selector: 'auftrag-barcode-modal',
    templateUrl: './auftrag-barcode-modal.page.html',
    styleUrls: ['./auftrag-barcode-modal.page.scss'],
})
export class AuftragBarcodeModalPage implements OnInit, OnDestroy, AfterViewInit {
    log = new Logger('AuftragBarcodeModalPage');

    @ViewChild('content', { read: IonContent, static: true }) content: IonContent;

    subscriptions: Subscription[] = [];

    @Input() auftrag: AuftragEx = null;

    isVisible = false;

    constructor(
        private modalController: ModalController) { }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.log.info('ngAfterViewInit');
    }

    ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    async ionViewWillEnter() {
    }

    ionViewDidEnter() {
    }

    ionViewWillLeave() {
    }

    onClose() {
        this.dismiss();
    }

    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            'dismissed': true
        });
    }
}
