
import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

@Injectable()
export class MyHammerGestureConfig extends HammerGestureConfig {
    overrides = {
        'tap': { threshold: 75, posTreshold: 2, time: 5000  }, // default: threshold: 2, posTreshold: 10, time: 250
        'pan': { threshold: 50 }
        // 'pan': { } // default 2, 10
    };

    buildHammer(element: HTMLElement) {
        const mc = new (window as any).Hammer(element);

        for (const eventName in this.overrides) {
            if (eventName) {
                mc.get(eventName).set(this.overrides[eventName]);
            }
        }

        return mc;
    }
}
