import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { Logger } from './helper/app-error-logger';

import { AppConfig } from './helper/app.config';
import { App } from './helper/app';
import { Barcode, BarcodeEvent, ProgloveDeviceInfo, ProGloveStatusType } from './model/model';
import { Utils } from './helper/utils';
import { SystemService } from './system.service';
import { Intent, IntentOptions, RegisterBroadcastReceiverOptions, WebIntent } from '@ionic-native/web-intent/ngx';

import * as moment from 'moment';

const ACTION_DISCONNECT_INTENT = "com.proglove.api.DISCONNECT"
const ACTION_GET_STATE_INTENT = "com.proglove.api.GET_SCANNER_STATE"
const ACTION_SCANNER_STATE_INTENT = "com.proglove.api.SCANNER_STATE"
const ACTION_BARCODE_INTENT = "com.proglove.api.BARCODE"
const ACTION_BARCODE_INTENT_IVANTI = "com.wavelink.intent.action.BARCODE"
const ACTION_FEEDBACK_PLAY_SEQUENCE_INTENT = "com.proglove.api.PLAY_FEEDBACK"
const ACTION_SCANNER_CONFIG = "com.proglove.api.CONFIG"
const ACTION_SCANNER_SET_CONFIG = "com.proglove.api.SET_CONFIG"
const ACTION_BARCODE_VIA_START_ACTIVITY_INTENT = "com.proglove.api.BARCODE_START_ACTIVITY"
const ACTION_CHANGE_CONFIG_PROFILE = "com.proglove.api.CHANGE_CONFIG_PROFILE"
const ACTION_BLOCK_TRIGGER = "com.proglove.api.BLOCK_TRIGGER"
const ACTION_UNBLOCK_TRIGGER = "com.proglove.api.UNBLOCK_TRIGGER"
const ACTION_TRIGGER_UNBLOCKED_INTENT = "com.proglove.api.TRIGGER_UNBLOCKED"
const ACTION_GET_CONFIG_PROFILES = "com.proglove.api.GET_CONFIG_PROFILES"
const ACTION_CONFIG_PROFILES = "com.proglove.api.CONFIG_PROFILES"

const ACTION_DISCONNECT_DISPLAY_INTENT = "com.proglove.api.DISPLAY_DISCONNECT"
const ACTION_GET_DISPLAY_STATE_INTENT = "com.proglove.api.GET_DISPLAY_STATE"
const ACTION_DISPLAY_STATE_INTENT = "com.proglove.api.DISPLAY_STATE"
const ACTION_BUTTON_PRESSED_INTENT = "com.proglove.api.DISPLAY_BUTTON"
const ACTION_SET_SCREEN_INTENT = "com.proglove.api.SET_DISPLAY_SCREEN"
const ACTION_SET_SCREEN_RESULT_INTENT = "com.proglove.api.SET_DISPLAY_SCREEN_RESULT"

const EXTRA_SCANNER_STATE = "com.proglove.api.extra.SCANNER_STATE"
const EXTRA_DATA_STRING_PG = "com.proglove.api.extra.BARCODE_DATA"
const EXTRA_SYMBOLOGY_STRING_PG = "com.proglove.api.extra.BARCODE_SYMBOLOGY"

const EXTRA_CONFIG_BUNDLE = "com.proglove.api.extra.CONFIG_BUNDLE"
const EXTRA_CONFIG_DEFAULT_SCAN_FEEDBACK_ENABLED = "com.proglove.api.extra.config.DEFAULT_SCAN_FEEDBACK_ENABLED"
const EXTRA_CONFIG_PROFILE_ID = "com.proglove.api.extra.CONFIG_PROFILE_ID"
const EXTRA_CONFIG_PROFILE_ACTIVE_ID = "com.proglove.api.extra.CONFIG_PROFILE_ACTIVE_ID"

const EXTRA_DISPLAY_TEMPLATE_ID = "com.proglove.api.extra.TEMPLATE_ID"
const EXTRA_DISPLAY_DATA = "com.proglove.api.extra.DATA"
const EXTRA_DISPLAY_SEPARATOR = "com.proglove.api.extra.SEPARATOR"
const EXTRA_DISPLAY_DURATION = "com.proglove.api.extra.DURATION"
const EXTRA_DISPLAY_REFRESH_TYPE = "com.proglove.api.extra.REFRESH_TYPE"
const EXTRA_DISPLAY_STATE = "com.proglove.api.extra.DISPLAY_STATE"
const EXTRA_DISPLAY_BUTTON = "com.proglove.api.extra.DISPLAY_BUTTON"
const EXTRA_DISPLAY_DEVICE = "com.proglove.api.extra.DISPLAY_DEVICE_NAME"
const EXTRA_DISPLAY_SET_SCREEN_SUCCESS = "com.proglove.api.extra.DISPLAY_SET_SCREEN_SUCCESS"
const EXTRA_DISPLAY_SET_SCREEN_ERROR_TEXT = "com.proglove.api.extra.DISPLAY_SET_SCREEN_ERROR"
const EXTRA_FEEDBACK_SEQUENCE_ID = "com.proglove.api.extra.FEEDBACK_SEQUENCE_ID"

const EXTRA_REPLACE_QUEUE = "com.proglove.api.extra.REPLACE_QUEUE"

// Pick display orientation
const DISPLAY_ORIENTATION_ACTIVITY_PACKAGE_NAME = "de.proglove.connect"
const DISPLAY_ORIENTATION_ACTIVITY_CLASS_NAME = "de.proglove.coreui.activities.DisplayOrientationActivity"

const ACTION_OBTAIN_DEVICE_VISIBILITY_INFO = "com.proglove.api.OBTAIN_DEVICE_VISIBILITY_INFO"
const ACTION_RECEIVE_DEVICE_VISIBILITY_INFO = "com.proglove.api.RECEIVE_DEVICE_VISIBILITY_INFO"
const EXTRA_DEVICE_VISIBILITY_INFO_SERIAL_NUMBER = "com.proglove.api.extra.DEVICE_VISIBILITY_INFO_SERIAL_NUMBER"
const EXTRA_DEVICE_VISIBILITY_INFO_MODEL_NUMBER = "com.proglove.api.extra.DEVICE_VISIBILITY_INFO_MODEL_NUMBER"
const EXTRA_DEVICE_VISIBILITY_INFO_FIRMWARE_REVISION = "com.proglove.api.extra.DEVICE_VISIBILITY_INFO_FIRMWARE_REVISION"
const EXTRA_DEVICE_VISIBILITY_INFO_BCE_REVISION = "com.proglove.api.extra.DEVICE_VISIBILITY_INFO_BCE_REVISION"
const EXTRA_DEVICE_VISIBILITY_INFO_BATTERY_LEVEL = "com.proglove.api.extra.DEVICE_VISIBILITY_INFO_BATTERY_LEVEL"
const EXTRA_DEVICE_VISIBILITY_INFO_APP_VERSION = "com.proglove.api.extra.DEVICE_VISIBILITY_INFO_APP_VERSION"

const ACTION_CONFIGURE_ACTIVITY_GOALS = "com.proglove.api.CONFIGURE_WORKER_GOALS"
const EXTRA_ACTIVITY_GOAL_TOTAL_STEPS = "com.proglove.api.extra.WORKER_GOAL_TOTAL_STEPS"
const EXTRA_ACTIVITY_GOAL_TOTAL_SCANS = "com.proglove.api.extra.WORKER_GOAL_TOTAL_SCANS"
const EXTRA_ACTIVITY_GOAL_AVERAGE_SCAN_SPEED = "com.proglove.api.extra.WORKER_GOAL_AVERAGE_SCAN_SPEED"

const PAIRING_ACTIVITY_PACKAGE_NAME = "de.proglove.connect"
const PAIRING_ACTIVITY_CLASS_NAME = "de.proglove.coreui.activities.PairingActivity"

const CATEGORY_DEFAULT = "ndroid.intent.category.DEFAULT";

const ZEBRA_ACTION = "com.zebra.recomobil.ACTION";
const ZEBRA_RESULT_ACTION = "com.symbol.datawedge.api.RESULT_ACTION";
const ZEBRA_CATEGORY_DEFAULT = 'android.intent.category.DEFAULT';

@Injectable({
    providedIn: 'root'
})
export class ProgloveService {
    private log = new Logger('ProgloveService');

    deviceInfo = new BehaviorSubject<ProgloveDeviceInfo>({});

    status = new BehaviorSubject<ProGloveStatusType>('GETRENNT');
    letzterKontakt = new BehaviorSubject<string>('');
    barcodeFormat = new BehaviorSubject<string>('');
    barcode = new BehaviorSubject<string>('');
    letzteAntwort = new BehaviorSubject<string>('');

    barcodeReceived = new Subject<Barcode>();

    updateIntervalId: any = null;

    activeProfileId = new BehaviorSubject<string>('');
    profileIds = new BehaviorSubject<string[]>([]);
    profileIdsLoaded = new Subject<string[]>();

    currentProfile: string = null;

    barcodeReceivers: BarcodeReceiver[] = [];
    barcodeRecieverId = 0;

    constructor(
        private systemService: SystemService,
        private webIntent: WebIntent,
    ) {
    }

    async init(): Promise<void> {
        this.log.debug('init');

        await App.ready();

        if (!App.isCordovaAvailable()) {
            this.log.warn('init abbruch. Cordova nicht verfügbar');
        }

        App.current.configRefreshed.subscribe(() => this.initProglove());

        setTimeout(() => {
            this.initProglove();
        }, 10 * 1000);
    }

    private async initProglove(): Promise<void> {
        this.log.debug('initProglove');

        if (this.updateIntervalId) {
            clearInterval(this.updateIntervalId);
            this.updateIntervalId = null;
        }

        if (AppConfig.current.einstellungen?.ProgloveVerfuegbar !== 'ja') {
            this.log.debug('initProglove: ProGlove nicht aktiv');
            return;
        }

        this.initIntents();

        this.updateIntervalId = setInterval(() => {
            this.requestScannerState();
        }, 30 * 1000);
    }

    initIntents() {
        this.log.debug('initIntents');

        try {
            const filter: RegisterBroadcastReceiverOptions = {
                filterActions: [
                    ACTION_BARCODE_INTENT,
                    ACTION_BARCODE_INTENT_IVANTI,
                    ACTION_SCANNER_STATE_INTENT,
                    ACTION_DISPLAY_STATE_INTENT,
                    ACTION_BUTTON_PRESSED_INTENT,
                    ACTION_SET_SCREEN_RESULT_INTENT,
                    ACTION_TRIGGER_UNBLOCKED_INTENT,
                    ACTION_CONFIG_PROFILES,
                    ACTION_RECEIVE_DEVICE_VISIBILITY_INFO,

                    ZEBRA_ACTION,
                    ZEBRA_RESULT_ACTION
                ],
                filterCategories: [
                    CATEGORY_DEFAULT,
                    ZEBRA_CATEGORY_DEFAULT
                ]
            };

            this.webIntent.registerBroadcastReceiver(filter).subscribe((intent) => {
                this.log.debug('onBroadcastIntent', intent);
                this.onReceive(intent);
            });

            this.webIntent.onIntent().subscribe((intent) => {
                this.log.debug('onIntent', intent);
                this.onReceive(intent);
            });

            this.requestScannerState();
        } catch (err) {
            this.log.error('initIntents: ' + Utils.getErrorMessage(err), err);
        }
    }

    async pairScanner(): Promise<any> {
        this.log.debug('pairScanner');

        try {
            await this.webIntent.startActivity({
                component: {
                    package: PAIRING_ACTIVITY_PACKAGE_NAME,
                    class: PAIRING_ACTIVITY_CLASS_NAME
                }
            })
        } catch (err) {
            this.log.error('pairScanner: ' + Utils.getErrorMessage(err), err);
        }
    }

    async disconnectScanner(): Promise<any> {
        this.log.debug('disconnectScanner');

        try {
            await this.webIntent.sendBroadcast({
                action: ACTION_DISCONNECT_INTENT
            });
        } catch (err) {
            this.log.error('disconnectScanner: ' + Utils.getErrorMessage(err), err);
        }
    }

    async disconnectDisplay(): Promise<any> {
        this.log.debug('disconnectDisplay');

        try {
            await this.webIntent.sendBroadcast({
                action: ACTION_DISCONNECT_DISPLAY_INTENT
            });
        } catch (err) {
            this.log.error('disconnectDisplay: ' + Utils.getErrorMessage(err), err);
        }
    }

    updateLetzterKontakt() {
        this.letzterKontakt.next(moment().format('HH:mm:ss'));
    }

    onReceive(intent: Intent): void {
        this.log.debug('onReceive: ' + JSON.stringify(intent), intent);

        try {
            switch (intent.action) {
                case ACTION_BARCODE_INTENT:
                case ACTION_BARCODE_VIA_START_ACTIVITY_INTENT:
                    this.handleScannedBarcode(intent);
                    break;

                case ACTION_BARCODE_INTENT_IVANTI:
                    this.handleIvantiBarcode(intent);
                    break;

                case ACTION_SCANNER_STATE_INTENT:
                    this.handleScannerState(intent);
                    break;

                case ACTION_SCANNER_CONFIG:
                    this.handleScannerConfig(intent);
                    break;

                case ACTION_DISPLAY_STATE_INTENT:
                    this.handleDisplayState(intent);
                    break;

                case ACTION_BUTTON_PRESSED_INTENT:
                    this.handleButtonPressed(intent);
                    break;

                case ACTION_SET_SCREEN_RESULT_INTENT:
                    this.handleSetScreenResult(intent);
                    break;

                case ACTION_TRIGGER_UNBLOCKED_INTENT:
                    this.handleTriggerUnblocked(intent);
                    break;

                case ACTION_CONFIG_PROFILES:
                    this.handleConfigProfiles(intent);
                    break;

                case ACTION_RECEIVE_DEVICE_VISIBILITY_INFO:
                    this.handleReceiveDeviceVisibilityInfo(intent);
                    break;

                case ZEBRA_ACTION:
                    this.handleZerbaIntent(intent);
                    break;

                default:
                    if (intent.extras) {
                        // TODO
                        // if (intent.hasExtra(ApiConstants.EXTRA_DATA_STRING_PG) || intent.hasExtra(ApiConstants.EXTRA_SYMBOLOGY_STRING_PG)) {
                        //     handleScannedBarcode(it)
                        // } else {
                        //     log("Unrecognized Intent")
                        // }
                    }
                    break;
            }
        } catch (err) {
            this.log.error(Utils.getErrorMessage(err), err);
        }
    }

    handleZerbaIntent(intent: Intent) {
        this.log.debug('handleZerbaIntent');

        const data: string = intent.extras['com.symbol.datawedge.data_string'];
        const symbology: string = intent.extras['com.symbol.datawedge.label_type'];
        const decodeData: string = intent.extras['com.symbol.datawedge.decode_data'];

        this.log.info(`handleZerbaIntent: ${symbology}, ${data}`);

        this.barcodeFormat.next(symbology);
        this.barcode.next(data);

        // Prüfe ob ein Handler registriert ist, der den Barcode verarbeitet
        const event: BarcodeEvent = {
            barcode: {
                text: data,
                format: symbology,
                decodeData: decodeData,
                source: 'ZEBRA'
            },
            handled: false
        };

        for (const handler of this.barcodeReceivers) {
            if (typeof (handler.func) === 'function') {
                handler.func(event);

                if (event.handled) {
                    break;
                }
            }
        }

        if (!event.handled) {
            this.barcodeReceived.next({
                text: data,
                format: symbology,
                decodeData: decodeData,
                source: 'ZEBRA'
            });
        }

    }

    handleScannedBarcode(intent: Intent) {
        this.log.debug('handleScannedBarcode');

        const data: string = intent.extras[EXTRA_DATA_STRING_PG];
        const symbology: string = intent.extras[EXTRA_SYMBOLOGY_STRING_PG];

        this.log.info(`handleScannedBarcode: ${symbology}, ${data}`);

        this.barcodeFormat.next(symbology);
        this.barcode.next(data);

        // if (data && symbology === 'QR CODE') {
        //     if (data.substr(0, 1) === '\u001d') {
        //         data
        //     }
        // }

        // Prüfe ob ein Handler registriert ist, der den Barcode verarbeitet
        const event: BarcodeEvent = {
            barcode: {
                text: data,
                format: symbology,
                source: 'PROGLOVE'
            },
            handled: false
        };

        for (const handler of this.barcodeReceivers) {
            if (typeof (handler.func) === 'function') {
                handler.func(event);

                if (event.handled) {
                    break;
                }
            }
        }

        if (!event.handled) {
            this.barcodeReceived.next({
                text: data,
                format: symbology,
                source: 'PROGLOVE'
            });
        }

        this.updateLetzterKontakt();

        this.log.debug(`handleScannedBarcode: ${data}, ${symbology}`);
    }

    handleIvantiBarcode(intent: Intent) {
        this.log.debug('handleIvantiBarcode');
    }

    handleScannerConfig(intent: Intent) {
        this.log.debug('handleScannerConfig');
    }

    handleDisplayState(intent: Intent) {
        this.log.debug('handleDisplayState');
    }

    handleButtonPressed(intent: Intent) {
        this.log.debug('handleButtonPressed');
    }

    handleSetScreenResult(intent: Intent) {
        this.log.debug('handleSetScreenResult');
    }

    handleTriggerUnblocked(intent: Intent) {
        this.log.debug('handleTriggerUnblocked');
    }

    handleConfigProfiles(intent: Intent) {
        this.log.debug('handleConfigProfiles');

        if (intent.extras) {
            const profileIds: string[] = intent.extras[EXTRA_CONFIG_PROFILE_ID];
            const activeProfileId: string = intent.extras[EXTRA_CONFIG_PROFILE_ACTIVE_ID];

            this.log.info('PROFILE IDS: ' + profileIds, profileIds);
            this.log.info('ACTIVE PROFILE: ' + activeProfileId);

            try {
                this.activeProfileId.next(activeProfileId);

                for (const id of profileIds) {
                    this.log.info('PROFILE: ' + id);
                }

                this.profileIds.next(profileIds);
                this.profileIdsLoaded.next(profileIds);
            } catch (err) {
                this.log.error('handleConfigProfiles: ' + Utils.getErrorMessage(err));
            }
        }
    }

    handleReceiveDeviceVisibilityInfo(intent: Intent) {
        this.log.debug('handleReceiveDeviceVisibilityInfo');

        try {
            const info: ProgloveDeviceInfo = {
                serialNumber: intent.extras[EXTRA_DEVICE_VISIBILITY_INFO_SERIAL_NUMBER],
                firmwareRevision: intent.extras[EXTRA_DEVICE_VISIBILITY_INFO_FIRMWARE_REVISION],
                batteryLevel: intent.extras[EXTRA_DEVICE_VISIBILITY_INFO_BATTERY_LEVEL],
                bceRevision: intent.extras[EXTRA_DEVICE_VISIBILITY_INFO_BCE_REVISION],
                modelNumber: intent.extras[EXTRA_DEVICE_VISIBILITY_INFO_MODEL_NUMBER],
                appVersion: intent.extras[EXTRA_DEVICE_VISIBILITY_INFO_APP_VERSION],
            }

            this.deviceInfo.next(info);

            this.updateLetzterKontakt();
        } catch (err) {
            this.log.error('handleReceiveDeviceVisibilityInfo: ' + Utils.getErrorMessage(err), err);
        }
    }

    handleScannerState(intent: Intent) {
        this.log.debug('handleScannerState');

        if (intent.extras) {
            const state = intent.extras[EXTRA_SCANNER_STATE];

            switch (state) {
                case 'DISCONNECTED':
                    this.status.next('GETRENNT');
                    break;
                case 'SEARCHING':
                    this.status.next('SUCHE');
                    break;
                case 'CONNECTING':
                    this.status.next('WIRD VERBUNDEN');
                    break;
                case 'RECONNECTING':
                    this.status.next('WIRD VERBUNDEN');
                    break;
                case 'CONNECTED':
                    this.status.next('VERBUNDEN');
                    this.updateLetzterKontakt();
                    break;
                default:
                    this.log.warn('Unbekannter STATE: ' + state);
                    break;
            }
        }
        // TODO
        //  intent.getStringExtra(ApiConstants.EXTRA_SCANNER_STATE)?.let { s ->
        // log("got scanner $s")
        // notifyOnScannerStateChange(DeviceConnectionStatus.valueOf(s))
    }

    async requestScannerState() {
        await this.sendBroadcast({
            action: ACTION_GET_STATE_INTENT
        })
    }

    async requestDisplayState() {
        await this.sendBroadcast({
            action: ACTION_GET_DISPLAY_STATE_INTENT
        })
    }

    async sendDisconnectDisplay() {
        await this.sendBroadcast({
            action: ACTION_DISCONNECT_DISPLAY_INTENT
        })
    }

    async blockTrigger() {
        await this.sendBroadcast({
            action: ACTION_BLOCK_TRIGGER
        })
    }

    async unblockTrigger() {
        await this.sendBroadcast({
            action: ACTION_UNBLOCK_TRIGGER
        })
    }

    async displayText(text: string, title = '', durationMs = 2000, refreshType: 'DEFAULT' | 'FULL_REFRESH' | 'PARTIAL_REFRESH' = 'DEFAULT') {
        await this.sendBroadcast({
            action: ACTION_SET_SCREEN_INTENT,
            extras: {
                'com.proglove.api.extra.TEMPLATE_ID': 'PG1',
                'com.proglove.api.extra.DATA': `1|${title}|${text}`,
                'com.proglove.api.extra.SEPARATOR': '|',
                'com.proglove.api.extra.DURATION': durationMs,
                'com.proglove.api.extra.REFRESH_TYPE': refreshType
            }
        })
    }

    async displayError(text: string, durationMs = 2000, refreshType: 'DEFAULT' | 'FULL_REFRESH' | 'PARTIAL_REFRESH' = 'DEFAULT') {
        await this.sendBroadcast({
            action: ACTION_SET_SCREEN_INTENT,
            extras: {
                'com.proglove.api.extra.TEMPLATE_ID': 'PG1',
                'com.proglove.api.extra.DATA': `1|Fehler|${text}`,
                'com.proglove.api.extra.SEPARATOR': '|',
                'com.proglove.api.extra.DURATION': durationMs,
                'com.proglove.api.extra.REFRESH_TYPE': refreshType
            }
        })
    }

    async displayTemplate(remplateId: string, content: string, separator: string = '|', durationMs = 0, refreshType: 'DEFAULT' | 'FULL_REFRESH' | 'PARTIAL_REFRESH' = 'DEFAULT') {
        await this.sendBroadcast({
            action: ACTION_SET_SCREEN_INTENT,
            extras: {
                'com.proglove.api.extra.TEMPLATE_ID': remplateId,
                'com.proglove.api.extra.DATA': content,
                'com.proglove.api.extra.SEPARATOR': separator,
                'com.proglove.api.extra.DURATION': durationMs,
                'com.proglove.api.extra.REFRESH_TYPE': refreshType
            }
        })
    }

    async sendTestScreen(remplateId: string, content: string, separator: string = '|', durationMs = 0, refreshType: 'DEFAULT' | 'FULL_REFRESH' | 'PARTIAL_REFRESH' = 'DEFAULT') {
        await this.sendBroadcast({
            action: ACTION_SET_SCREEN_INTENT,
            extras: {
                'com.proglove.api.extra.TEMPLATE_ID': remplateId,
                'com.proglove.api.extra.DATA': content,
                'com.proglove.api.extra.SEPARATOR': separator,
                'com.proglove.api.extra.DURATION': durationMs,
                'com.proglove.api.extra.REFRESH_TYPE': refreshType
            }
        })
    }

    async setSeriennummerProfile(force = false) {
        try {
            const profil = AppConfig.current.einstellungen['ProGloveProfilSeriennummer'];

            await this.sendChangeProfile(profil, force);
        } catch (err) {
            this.log.error('setSeriennummerProfile: ' + Utils.getErrorMessage(err));
        }
    }

    async setStandardProfile(force = false) {
        try {
            const profil = AppConfig.current.einstellungen['ProGloveProfilStandard'];

            await this.sendChangeProfile(profil, force);
        } catch (err) {
            this.log.error('setStandardProfile: ' + Utils.getErrorMessage(err));
        }
    }

    async setWareneingangProfile(force = false) {
        try {
            const profil = AppConfig.current.einstellungen['ProGloveProfilWareneingang'];

            await this.sendChangeProfile(profil, force);
        } catch (err) {
            this.log.error('setStandardProfile: ' + Utils.getErrorMessage(err));
        }
    }

    async sendChangeProfile(profileId: string, force: boolean) {
        if (this.currentProfile == profileId && !force) {
            return;
        }

        this.log.debug('sendChangeProfile: ' + profileId);
        this.currentProfile = profileId;

        await this.sendBroadcast({
            action: ACTION_CHANGE_CONFIG_PROFILE,
            extras: {
                'com.proglove.api.extra.CONFIG_PROFILE_ID': profileId
            }
        });
    }

    async sendGetProfiles() {
        await this.sendBroadcast({
            action: ACTION_GET_CONFIG_PROFILES,
        })
    }

    /** 
     * @param feedbackSequenceId: 1: success, 2: error, 3: info
     * @param shouldReplaceQueue 
     */
    async triggerFeedback(feedbackSequenceId: 1 | 2 | 3, shouldReplaceQueue = false) {
        await this.sendBroadcast({
            action: ACTION_FEEDBACK_PLAY_SEQUENCE_INTENT,
            extras: {
                'com.proglove.api.extra.FEEDBACK_SEQUENCE_ID': feedbackSequenceId,
                'com.proglove.api.extra.REPLACE_QUEUE': shouldReplaceQueue
            }
        })
    }

    async sendBroadcast(options: IntentOptions) {
        this.log.debug('sendBroadcast: ' + JSON.stringify(options), options);

        try {
            if (App.isCordovaAvailable()) {
                await this.webIntent.sendBroadcast(options)
            }
        } catch (err) {
            this.log.error('sendBroadcast: ' + Utils.getErrorMessage(err), err);
        }
    }

    registerBarcodeReceiver(prio: number, func: (event: BarcodeEvent) => void): number {
        const id = ++this.barcodeRecieverId;

        this.barcodeReceivers.push({
            func,
            prio,
            id
        });

        this.barcodeReceivers.sort((a, b) => b.prio - a.prio);

        return id;
    }

    unregisterBarcodeReceiver(registrationId: number) {
        const idx = this.barcodeReceivers.findIndex(p => p.id === registrationId);

        if (idx >= 0) {
            this.barcodeReceivers.splice(idx, 1);
        }
    }
}

interface BarcodeReceiver {
    func: (event: BarcodeEvent) => void;
    prio: number;
    id: number;
}

