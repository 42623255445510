<ion-item [lines]="lines" class="ion-no-padding">
  <ion-label class="ion-text-wrap">{{ titel | translate }}</ion-label>

  <ion-label slot="end" class="text-value">{{ myValue ? jaText : neinText }}</ion-label>

  <ion-toggle *ngIf="!readOnly" [(ngModel)]="myValue" slot="end" [disabled]="disabled" mode="ios" [color]="color"></ion-toggle>

  <!-- <ion-toggle ion-toggle-text ng-true-value="so true" ng-false-value="so false" [(ngModel)]="myValue">
    Text by value: <b>{{ textByValue || 'so false' }}</b>
  </ion-toggle>

  <ion-checkbox [(ngModel)]="myValue" slot="end" [disabled]="disabled"></ion-checkbox> -->
</ion-item>