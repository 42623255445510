// tslint:disable:object-literal-key-quotes

export const translations = {
  "DE": {
    "Permissions": "Berechtigungen",
    "Permission.ShowUsers": "Benutzer anzeigen",
    "Permission.EditUsers": "Benutzer verwalten",
    "Permission.ShowProjects": "Projekte anzeigen",
    "Permission.EditProjects": "Projekte verwalten",
    "Permission.ShowApplications": "Anträge anzeigen",
    "Permission.EditApplications": "Anträge verwalten",
    "Permission.ShowDemandRequest": "Bedarfsanfragen anzeigen",
    "Permission.EditDemandRequest": "Bedarfsanfragen verwalten",
    "Permission.CreateDemandRequest": "Bedarfsanfragen erstellen",
    "Permission.ShowUserRoles": "Benutzerrollen anzeigen",
    "Permission.EditUserRoles": "Benutzerrollen verwalten",
    "Permission.ShowGeraete": "Geräte anzeigen",
    "Permission.EditGeraete": "Geräte verwalten",
    "Permission.ReadGeraete": "Geräte lesen",
    "Permission.Administration": "Administration",
    "Permission.ReadAuftrag": "Aufträge lesen",
    "Permission.ListAuftraege": "Aufträge anzeigen",
    "Permission.ShowKarte": "Karte anzeigen",
    "Permission.ProtokollAnzeigen": "Protokoll anzeigen",
    "Permission.ProtokollBearbeiten": "Protokoll bearbeiten",
    "Permission.KarteAnzeigen": "Karte anzeigen",
    "Permission.WorkflowsAnzeigen": "Workflows anzeigen",
    "Permission.WorkflowsBearbeiten": "Workflows bearbeiten",
    "Permission.FahrerBearbeiten": "Fahrer bearbeiten",
    "Permission.FahrerAnzeigen": "Fahrer anzeigen",
    "Permission.FahrzeugBearbeiten": "Fahrzeuge bearbeiten",
    "Permission.FahrzeugAnzeigen": "Fahrzeuge anzeigen",
    "Permission.ArtikelAnzeigen": "Artikel anzeigen",
    "Permission.ArtikelBearbeiten": "Artikel bearbeiten",
    "Permission.UvvKatalogAnzeigen": "UVV-Katalog anzeigen",
    "Permission.UvvKatalogBearbeiten": "UVV-Katalog bearbeiten",
    "Permission.AbfahrtskontrolleKatalogAnzeigen": "Abfahrtskontrolle-Katalog anzeigen",
    "Permission.AbfahrtskontrolleKatalogBearbeiten": "Abfahrtskontrolle-Katalog bearbeiten",
    "Permission.FormulareBearbeiten": "Formulare bearbeiten",
    "Permission.UvvPruefungenAnzeigen": "UVV-Prüfungen anzeigen",
    "Permission.UvvPruefungenBearbeiten": "UVV-Prüfungen bearbeiten",
    "Permission.UvvPruefGegenstaendeAnzeigen": "UVV-Gegenstände anzeigen",
    "Permission.UvvPruefGegenstaendeBearbeiten": "UVV-Gegenstände bearbeiten",
    "Permission.ChecklisteBearbeiten": "Checklisten bearbeiten",
    "Permission.ChecklisteAnzeigen": "Checklisten anzeigen",
    "Permission.AdresseAnzeigen": "Adressen anzeigen",
    "Permission.DokumenteBearbeiten": "Dokumente bearbeiten",
    "Permission.DruckformulareAnzeigen": "Druckformulare anzeigen",
    "Permission.DruckformulareBearbeiten": "Druckformulare bearbeiten",
    "Permission.ArtikelgruppenAnzeigen": "Artikelgruppen anzeigen",
    "Permission.ArtikelgruppenBearbeiten": "Artikelgruppen bearbeiten",
    "Permission.Sysadmin": "Sysadmin",
    "Permission.FahrzeuggruppenAnzeigen": "Fahrzeuggruppen anzeigen",
    "Permission.FahrzeuggruppenBearbeiten": "Fahrzeuggruppen bearbeiten",
    "Permission.GeraetegruppenAnzeigen": "Geraetegruppen anzeigen",
    "Permission.GeraetegruppenBearbeiten": "Geraetegruppen bearbeiten",
    "Permission.StatuszusatzinfoAnzeigen": "Statuszusatzinfo anzeigen",
    "Permission.StatuszusatzinfoBearbeiten": "Statuszusatzinfo bearbeiten",
    "Permission.Nachrichten": "Nachrichten",
    "Permission.AdresseBearbeiten": "Adressen bearbeiten",
    "Permission.WaageAnzeigen": "Waagen anzeigen",
    "Permission.WaageBearbeiten": "Waagen bearbeiten",
    "Permission.Tabellenkonfiguration": "Tabellenkonfiguration",
    "Permission.EditGeraeteEinfacheDaten": "Geräte einfache Daten ändern",
    "Permission.AuftraegeBearbeiten": "Aufträge bearbeiten",
    "Permission.AuftraegeLoeschen": "Aufträge löschen",
    "Permission.DokumenteAnzeigen": "Dokumente anzeigen",
    "Permission.Dashboard": "Übersicht (Dashboard)",
    "Permission.GastzugangAnzeigen": "Gastzugang anzeigen",
    "Permission.GastzugangBearbeiten": "Gastzugang bearbeiten",
    "Permission.StammtourAnzeigen": "Stammtour anzeigen",
    "Permission.StammtourBearbeiten": "Stammtour bearbeiten",
    "Permission.StoerzeitAnzeigen": "Störzeit anzeigen",
    "Permission.StoerzeitBearbeiten": "Störzeit bearbeiten",
    "Permission.TourenAnzeigen": "Touren anzeigen",
    "Permission.TourenBearbeiten": "Touren bearbeiten",
    "Permission.Tourenplanung": "Tourenplanung",
    "Anmelden mit Personalnummer": "Anmelden mit Personalnummer",
    "TourWiegung1": "1. Wiegung",
    "TourWiegung2": "2. Wiegung",
    "BitteWiegung1Durchfuehren": "Bitte 1. Wiegung durchführen",
    "BitteWiegung2Durchfuehren": "Bitte 2. Wiegung durchführen",
    "Permission.AbfahrtskontrolleBearbeiten": "Abfahrtskontrolle bearbeiten",
    "Permission.AbfahrtskontrolleAnzeigen": "Abfahrtskontrolle anzeigen",
    "Permission.Auswertungen": "Auswertungen",
    "Permission.TexteAnzeigen": "Texte anzeigen",
    "Permission.TexteBearbeiten": "Texte bearbeiten",
    "AuftragWiegung1": "1. Wiegung",
    "AuftragWiegung2": "2. Wiegung",
    "Beladung": "Beladung",
    "Auftrag scannen": "Auftrag scannen",
    "Tour": "Tour",
    "Home_Anmelden": "Anmelden mit Personalnummer",
    "Home_TourStarten": "Tour starten",
    "Home_TourBeenden": "Tour beenden",
    "Home_AuftragScannen": "Auftrag scannen",
    "Home_Beladung": "Beladung",
    "Home_Hofauftraege": "Hofaufträge",
    "Wareneingang": "Wareneingang",
    "Glas-Analyse": "Glas-Analyse",
    "Home_Uebersicht": "Übersicht",
    "Home_Kunden": "Kunden",
    "NagivationHome": "Home",
    "UVV-Prüfungen": "UVV-Prüfungen",
    "Dokumente": "Dokumente",
    "Abmelden": "Abmelden",
    "Smartwatch": "Smartwatch",
    "ProGlove": "ProGlove",
    "Home_System": "System",
    "Permission.VertraegeAnzeigen": "Verträge anzeigen",
    "Permission.VertraegeBearbeiten": "Verträge bearbeiten",
    "Save": "Speichern",
    "Cancel": "Abbrechen",
    "NeuenTransportauftragAnlegen": "Neuen Transportauftrag anlegen?",
    "NeuenWareneingangAnlegen": "Neuen Wareneingang erfassen?",
    "NeuenHofauftragAnlegen": "Neuen Hofauftrag anlegen?",
    "Vertrag": "Vertrag",
    "Neuer Auftrag": "Neuer Auftrag",
    "Abbrechen": "Abbrechen",
    "Home_Zeiterfassung": "Zeiterfassung",
    "SchreibgeschuetztVorStatusmeldung": "Auftrag ist Schreibgeschützt bis zu ersten Statusmeldung",
    "NavigationErlaubenWennSchreibgeschuetzt": "Navigation erlauben wenn schreibgeschützt",
    "TransportauftragLeerePositionenAusblenden": "Positionen mit Menge 0 ausblenden",
    "Auswertungen": "Auswertungen",
    "Abfahrtskontrolle": "Abfahrtskontrolle",
    "UVV-Gegenstände": "UVV-Gegenstände",
    "UVV-Katalog": "UVV-Katalog",
    "Einträge": "Einträge",
    "Name": "Name",
    "Beschreibung": "Beschreibung",
    "REST-Schnittstelle": "REST-Schnittstelle",
    "Abrechnung aktiv": "Abrechnung aktiv",
    "API-Token": "API-Token",
    "Angelegt am": "Angelegt am",
    "Vorname": "Vorname",
    "Nachname": "Nachname",
    "Email": "Email",
    "Roles": "Benutzerrollen",
    "Field is required": "Feld ist erforderlich",
    "E-Mail": "E-Mail",
    "Zugangsdaten ändern": "Zugangsdaten ändern",
    "Passwort": "Passwort",
    "Passwort (bestätigen)": "Passwort (bestätigen)",
    "Benutzer bearbeiten": "Benutzer bearbeiten",
    "Key": "Key",
    "Checkliste": "Checkliste",
    "Mandant": "Mandant",
    "ApiToken": "API-Token",
    "Latitude": "Latitude",
    "Longitude": "Longitude",
    "Max. Entfernung": "Max. Entfernung",
    "Timeout": "Timeout",
    "Icon": "Icon",
    "Nummer": "Nummer",
    "Bezeichnung": "Bezeichnung",
    "Gruppe": "Gruppe",
    "App Version": "App Version",
    "Traffic aktueller Monat": "Traffic aktueller Monat",
    "Personalnr.": "Personalnr.",
    "Personal": "Personal",
    "Zuletzt online": "Zuletzt online",
    "Fahrzeug": "Fahrzeug",
    "Anhänger": "Anhänger",
    "Letzte Position": "Letzte Position",
    "Farbe": "Farbe",
    "Letzte Anmeldung": "Letzte Anmeldung",
    "Letzte Abmeldung": "Letzte Abmeldung",
    "Angemeldet": "Angemeldet",
    "Telefon": "Telefon",
    "Freitext": "Freitext",
    "Platform": "Platform",
    "Version": "Version",
    "Hersteller": "Hersteller",
    "Model": "Model",
    "Alert Berechtigung": "Alert Berechtigung",
    "Erste Verwendung": "Erste Verwendung",
    "Letzte Verwendung": "Letzte Verwendung",
    "Abrechnung ab": "Abrechnung ab",
    "Abrechnung bis": "Abrechnung bis",
    "Preis / Monat": "Preis / Monat",
    "Einmaliger Preis ": "Einmaliger Preis ",
    "Geräte (Systemansicht)": "Geräte (Systemansicht)",
    "RoleName": "RoleName",
    "Symbol": "Symbol",
    "App-Version": "App-Version",
    "Verfügbare Waagen": "Verfügbare Waagen",
    "Importieren": "Importieren",
    "Gerätegruppe bearbeiten": "Gerätegruppe bearbeiten",
    "Kopieren": "Kopieren",
    "Exportieren": "Exportieren",
    "Zeit (h)": "Zeit (h)",
    "Intervall (m)": "Intervall (m)",
    "MeldungText": "MeldungText",
    "Standard-Dauer": "Standard-Dauer",
    "Typ": "Typ",
    "Datum": "Datum",
    "Verlauf": "Verlauf",
    "Auftragsnr.": "Auftragsnr.",
    "Gerätenr.": "Gerätenr.",
    "Gerät": "Gerät",
    "Status": "Status",
    "PushStatus": "PushStatus",
    "Abholdatum": "Abholdatum",
    "Abholzeit": "Abholzeit",
    "Erfasser": "Erfasser",
    "Adress Nr.": "Adress Nr.",
    "Name1": "Name1",
    "Name2": "Name2",
    "Strasse": "Strasse",
    "PLZ": "PLZ",
    "Ort": "Ort",
    "Kunde Nr.": "Kunde Nr.",
    "Kunde Name1": "Kunde Name1",
    "Kunde Name2": "Kunde Name2",
    "Kunde Strasse": "Kunde Strasse",
    "Kunde PLZ": "Kunde PLZ",
    "Kunde Ort": "Kunde Ort",
    "Info1": "Info1",
    "Info2": "Info2",
    "Info3": "Info3",
    "DetailInfo1": "DetailInfo1",
    "DetailInfo2": "DetailInfo2",
    "DetailInfo3": "DetailInfo3",
    "Workflow": "Workflow",
    "Statusmeldung": "Statusmeldung",
    "Statuszeit": "Statuszeit",
    "Reihenfolge": "Reihenfolge",
    "CreationDate": "CreationDate",
    "ModificationDate": "ModificationDate",
    "Lieferpositionen": "Lieferpositionen",
    "Auftrag": "Auftrag",
    "RememberMe": "Angemeldet bleiben",
    "Username": "Benutzername",
    "Geräte": "Geräte",
    "Stammdaten": "Stammdaten",
    "Adressen": "Adressen",
    "Artikelgruppen": "Artikelgruppen",
    "Artikel": "Artikel",
    "Checklisten": "Checklisten",
    "Fahrer (Personal)": "Fahrer (Personal)",
    "Fahrzeuge": "Fahrzeuge",
    "Fahrzeuggruppen": "Fahrzeuggruppen",
    "Konfiguration": "Konfiguration",
    "Benutzer": "Benutzer",
    "Benutzerrollen": "Benutzerrollen",
    "Druckformulare": "Druckformulare",
    "Einstellungen": "Einstellungen",
    "Formulare": "Formulare",
    "Gerätegruppen": "Gerätegruppen",
    "Waagen": "Waagen",
    "Workflows": "Workflows",
    "Statuszusatzinfo": "Statuszusatzinfo",
    "Texte": "Texte",
    "Import / Export": "Import / Export",
    "Benutzername": "Benutzername",
    "E-Mail-Adresse": "E-Mail-Adresse",
    "Passwort ändern": "Passwort ändern",
    "Permission.Benachrichtigungen": "Benachrichtigungen",
    "Permission.ZeiterfassungAnzeigen": "Zeiterfassung anzeigen",
    "Permission.ZeiterfassungBearbeiten": "Zeiterfassung bearbeiten",
    "Nachtmodus": "Nachtmodus",
    "Nachmodus": "Nachtmodus",
    "KeineAuftraegeGefunden": "Keine Aufträge gefunden",
    "KeineAuftraegeVorhanden": "Keine Aufträge vorhanden. Zum Aktualisieren nach unten ziehen.",
    "TourStartenAlertButtonText": "Tour starten !",
    "Aktuelle Aufträge anzeigen": "Aktuelle Aufträge anzeigen",
    "Archiv": "Archiv",
    "weitere Aufträge vorhanden": "weitere Aufträge vorhanden",
    "weiterer Auftrag vorhanden": "weiterer Auftrag vorhanden",
    "Anmerkungen": "Anmerkungen",
    "AnmerkungenHierEingeben": "Anmerkungen hier eingeben…",
    "AuftragDarfNichtGeaendertWerden": "Auftrag darf nicht geändert werden",
    "AuftragBereitsAbgeschlossen": "Auftrag wurde bereits abgeschlossen. Änderungen sind nicht mehr möglich.",
    "Start": "Start",
    "System": "System",
    "Transportaufträge": "Transportaufträge",
    "Hofaufträge": "Hofaufträge",
    "Entladeort": "Entladeort",
    "Bitte Entladeort wählen": "Bitte Entladeort wählen",
    "TourStart_Wiegen": "Wiegen",
    "TourEnde_StartWiegung": "Wiegung bei Tour-Start",
    "TourEnde_EndeWiegung": "Wiegung (voller LKW)",
    "Geplante Reihenfolge anzeigen": "Geplante Reihenfolge anzeigen",
    "Permission.GlasAnalyseAnzeigen": "Glas-Analyse anzeigen",
    "Permission.GlasAnalyseBearbeiten": "Glas-Analyse bearbeiten",
    "BitteAuswaehlenPlaceholder": "Bitte wählen",
    "BitteAnlageWaehlen": "Bitte Anlage wählen",
    "Permission.GlasAnalyseKonfiguration": "Glas-Analyse Konfiguration",
    "UVV Prüfung": "UVV Prüfung",
    "Auftrag_Abholadresse": "Adresse",
    "Auftrag_Erzeugeradresse": "Kunde",
    "Auftrag_Verwerteradresse": "Verwerter",
    "Auftrag_Fahrzeug": "Fahrzeug",
    "Auftrag_Abholdatum": "Abholdatum",
    "Auftrag_Abholzeit": "Abholzeit",
    "Permission.BerichteAnzeigen": "Berichte anzeigen",
    "Permission.BerichteBearbeiten": "Berichte bearbeiten",
    "Permission.Kalender": "Kalender",
    "Permission.NVEAnzeigen": "NVE anzeigen",
    "Permission.NVEBearbeiten": "NVE bearbeiten",
    "Permission.RegelAnzeigen": "Regeln anzeigen",
    "Permission.RegelBearbeiten": "Regeln bearbeiten",
    "ExportMessageStatus_0": "Initial",
    "ExportMessageStatus_1": "Gesendet",
    "ExportMessageStatus_2": "Verarbeitet",
    "ExportMessageStatus_3": "Fehler",
    "ExportMessageStatus_4": "Warte auf ACK",
    "Gerätegruppe": "Gerätegruppe",
    "Sonstiges": "Sonstiges",
    "Sprache": "Sprache",
    "APP Version": "APP Version",
    "Local storage": "Local storage",
    "Advanced HTTP": "Advanced HTTP",
    "System Alert Berechtigung": "System Alert Berechtigung",
    "Navigations-App": "Navigations-App",
    "Zurücksetzen": "Zurücksetzen",
    "WebSocket": "WebSocket",
    "Push-Status": "Push-Status",
    "Push-ID": "Push-ID",
    "Push-Type": "Push-Type",
    "Anzahl Artikelgruppen": "Anzahl Artikelgruppen",
    "Anzahl Artikel": "Anzahl Artikel",
    "Anzahl Workflows": "Anzahl Workflows",
    "GPS Dienst läuft": "GPS Dienst läuft",
    "GPS Dienst aktiv": "GPS Dienst aktiv",
    "GPS Auth-Status": "GPS Auth-Status",
    "GPS-Positionen (Puffer)": "GPS-Positionen (Puffer)",
    "Zugangsdaten": "Zugangsdaten",
    "Geräte-Nummer": "Geräte-Nummer",
    "Host": "Host",
    "Speichern": "Speichern",
    "Stammdaten neu laden": "Stammdaten neu laden",
    "Lokale Daten löschen": "Lokale Daten löschen",
    "Diagnosedaten senden": "Diagnosedaten senden",
    "Testdatei senden": "Testdatei senden",
    "Update prüfen": "Update prüfen",
    "Dunkler Modus": "Dunkler Modus",
    "Aktivieren": "Aktivieren",
    "Anzeigen": "Anzeigen",
    "Anmelden": "Anmelden",
    "Personalnummer": "Personalnummer",
    "Kennwort": "Kennwort",
    "Mit NFC anmelden": "Mit NFC anmelden",
    "Bitte eingeben": "Bitte eingeben",
    "Passwort eingeben": "Passwort eingeben",
    "Anmelden mit NFC-Chip": "Anmelden mit NFC-Chip",
    "Zur Anmeldung NFC-Chip scannen": "Zur Anmeldung NFC-Chip scannen",
    "Mit Personalnummer anmelden": "Mit Personalnummer anmelden",
    "Ablaufdatum": "Ablaufdatum",
    "Kein Fahrer für RFID-Chip gefunden": "Kein Fahrer für RFID-Chip gefunden",
    "Nachrichten": "Nachrichten",
    "Berichte": "Berichte",
    "Urlaubsanträge": "Urlaubsanträge",
    "Unfallberichte": "Unfallberichte",
    "Tätigkeitsberichte": "Tätigkeitsberichte",
    "an": "an",
    "aus": "aus",
    "KeineEintraegeVorhanden": "Keine Einträge vorhanden",
    "StoerzeitFuerAuftrag": "Störzeiten für Auftrag",
    "StoerzeitFuerTour": "Störzeiten für Tour",
    "Minuten": "Minuten",
    "Sekunden": "Sekunden",
    "Minute": "Minute",
    "TourNichtGestartetLoeschen": "Tour nicht gestartet. Löschen nicht möglich.",
    "ZeiterfassungOhneTourFehler": "Zeiterfassung kann ohne Tour nicht gestartet werden",
    "Tour start": "Tour start",
    "Kilometerstand": "Kilometerstand",
    "Fahrer": "Fahrer",
    "Beifahrer": "Beifahrer",
    "Bitte wählen": "Bitte wählen",
    "Beifahrer 2": "Beifahrer 2",
    "Abfahrtskontrolle Anhänger": "Abfahrtskontrolle Anhänger",
    "Foto aufnehmen": "Foto aufnehmen",
    "Zum Bearbeiten hier tippen": "Zum Bearbeiten hier tippen",
    "Eingeben": "Eingeben",
    "Uhrzeit": "Uhrzeit",
    "Driver": "Driver",
    "Passenger": "Passenger",
    "Passenger 2": "Passenger 2",
    "Trailer": "Trailer",
    "Vehicle": "Vehicle",
    "BitteEingabePruefen": "Bitte Eingabe prüfen!",
    "RFID scannen": "RFID scannen",
    "Tour scannen": "Tour scannen",
    "Tour starten": "Tour starten",
    "Tour laden": "Tour laden",
    "Tour entladen": "Tour entladen",
    "Schreiben": "Schreiben",
    "Alle Nachrichten löschen?": "Alle Nachrichten löschen?",
    "Löschen": "Löschen",
    "Ja": "Ja",
    "Nein": "Nein",
    "Nächste Prüfung": "Nächste Prüfung",
    "alle anzeigen": "alle anzeigen",
    "Bitte KM eingeben": "Bitte KM eingeben",
    "NFCErkennungLaeuft": "NFC-Erkennung läuft…",
    "RFIDWirdGescannt": "RFID wird gescannt…",
    "OK": "OK",
    "Fehlerbeschreibung": "Fehlerbeschreibung",
    "Bild wirklich löschen?": "Bild wirklich löschen?",
    "Frage": "Frage",
    "Bitte Kilometerstand angeben": "Bitte Kilometerstand angeben",
    "Bitte Ankunftskontrolle vollständig ausfüllen": "Bitte Ankunftskontrolle vollständig ausfüllen",
    "Bitte Ankunftskontrolle Anhänger vollständig ausfüllen": "Bitte Ankunftskontrolle Anhänger vollständig ausfüllen",
    "Bitte Unterschrift erfassen": "Bitte Unterschrift erfassen",
    "Bitte Namen für die Unterschrift erfassen": "Bitte Namen für die Unterschrift erfassen",
    "Bitte nehmen Sie sich genügend Zeit für die Ankunftskontrolle!": "Bitte nehmen Sie sich genügend Zeit für die Ankunftskontrolle!",
    "AnkunftskontrolleDauerFrage1": "Die vorgegebene Zeit für die Ankunftskontrolle wurde nicht eingehalten. Tour trotzdem beenden?",
    "Tour beenden?": "Tour beenden?",
    "AnkunftskontrolleDauerFrage2": "Die vorgegebene Zeit für die Ankunftskontrolle wurde nicht eingehalten. Tour trotzdem beenden?<br /><br />Es wird eine Nachricht gesendet!",
    "Bitte Abfahrtskontrolle vollständig ausfüllen": "Bitte Abfahrtskontrolle vollständig ausfüllen",
    "AbfahrtskontrolleMeldung1": "Bitte nehmen Sie sich genügend Zeit für die Abfahrtskontrolle!",
    "AbfahrtskontrolleMeldung2": "Die vorgegebene Zeit für die Abfahrtskontrolle wurde nicht eingehalten. Tour trotzdem starten?",
    "AbfahrtskontrolleMeldung3": "Die vorgegebene Zeit für die Abfahrtskontrolle wurde nicht eingehalten. Tour trotzdem starten?<br /><br />Es wird eine Nachricht gesendet!",
    "KeineWiegungTourTrotzdemStarten": "Keine Wiegung durchgeführt. Tour trotzdem starten?",
    "Wiegung": "Wiegung",
    "WiegungErfolgreich": "Wiegung erforderlich!",
    "Tour start?": "Tour start?",
    "Tour aktiv seit": "Tour aktiv seit",
    "Gestartet um": "Gestartet um",
    "Gestartet gestern": "Gestartet gestern",
    "Gestartet": "Gestartet",
    "Keine gültige Auftragsnummer": "Keine gültige Auftragsnummer",
    "Heute offene Aufträge": "Heute offene Aufträge",
    "Heute erledigt Aufträge": "Heute erledigt Aufträge",
    "Morgen": "Morgen",
    "Keine Transportaufträge": "Keine Transportaufträge",
    "1 Transportauftrag": "1 Transportauftrag",
    "aktuelle Aufträge": "aktuelle Aufträge",
    "Statusmeldung428": "Statusmeldung nicht möglich. Wiegung wurde bereits gestartet. Bitte Tour beenden und bei Bedarf neu starten.",
    "Meldung429": "<p>Statusmeldung nicht möglich. Tour ist noch nicht gestartet.</p><p>Tour jetzt starten?</p>",
    "Meldung430": "Status nicht erlaubt. Bitte einen der folgenden Status melden",
    "Meldung431": "Es wurden nicht alle Positionen fertig gemeldet. Palette wirklich fertig melden?",
    "Es muss mindestens ein Foto gemacht werden!": "Es muss mindestens ein Foto gemacht werden!",
    "Alle Aufträge": "Alle Aufträge",
    "Kommissionierung": "Kommissionierung",
    "Wiegungen": "Wiegungen",
    "Gekoppelte Waagen": "Gekoppelte Waagen",
    "Waage suchen...": "Waage suchen...",
    "Keine Wiegedaten vorhanden": "Keine Wiegedaten vorhanden",
    "Keine Waagen konfiguriert": "Keine Waagen konfiguriert",
    "Waage": "Waage",
    "MAC Adresse": "MAC Adresse",
    "Aktiv": "Aktiv",
    "Uhr synchronisieren": "Uhr synchronisieren",
    "Wiegen 1": "Wiegen 1",
    "Wiegen 2": "Wiegen 2",
    "Tarieren": "Tarieren",
    "Keine neue Waage gefunden": "Keine neue Waage gefunden",
    "TourAktivBeendenMeldung449": "Tour ist noch aktiv. Jetzt Tour beenden?",
    "WirklichAbmelden": "Wirklich abmelden?",
    "Aktuelle Tour": "Aktuelle Tour",
    "Tour beenden": "Tour beenden",
    "Zeiterfassung": "Zeiterfassung",
    "Zeiterfassung starten": "Zeiterfassung starten",
    "Tour Ende": "Tour Ende",
    "Art": "Art",
    "Arbeitszeit bearbeiten": "Arbeitszeit bearbeiten",
    "Wiegen": "Wiegen",
    "Tätigkeitsbericht": "Tätigkeitsbericht",
    "Personaldaten": "Personaldaten",
    "Tätigkeit": "Tätigkeit",
    "Übernachtung": "Übernachtung",
    "Beschreibung der Tätigkeit": "Beschreibung der Tätigkeit",
    "Neuer Urlaubsantrag": "Neuer Urlaubsantrag",
    "Abteilung": "Abteilung",
    "Urlaub": "Urlaub",
    "Angemeldete Beifahrer": "Angemeldete Beifahrer",
    "Beifahrer hinzufügen": "Beifahrer hinzufügen",
    "Beifahrer NFC scannen": "Beifahrer NFC scannen",
    "BeifahrerListeNichtGeladen": "Liste der Beifahrer konnte nicht geladen werden. Bitte überprüfen Sie Ihre Internetverbindung.",
    "Beifahrer wurde gescannnt": "Beifahrer wurde gescannnt",
    "NfcKeinBeifahrerGefunden": "Kein Beifahrer für RFID-Chip gefunden",
    "BeifahrerAlsFahrerMeldung473": "Sie sind bereits als Fahrer für diese Tour angemeldet. Sie können sich nicht erneut als Beifahrer anmelden.",
    "BeifahrerBereitsAngemeldet": "Sie sind bereits als Beifahrer angemeldet.",
    "MaxZweiBeifahrerMoeglich": "Es können maximal 2 Beifahrer angemeldet werden",
    "BeifahrerNichtGefunden": "Beifahrer nicht gefunden",
    "BeifahrerWurdeHinzugefuegt": "Beifahrer wurde hinzugefügt",
    "Ein Anhänger kann nicht als Fahrzeug gescannt werden": "Ein Anhänger kann nicht als Fahrzeug gescannt werden",
    "Ein LKW kann nicht als Anhänger gescannt werden": "Ein LKW kann nicht als Anhänger gescannt werden",
    "AngemeldetSeit": "Angemeldet seit",
    "Hinzufügen": "Hinzufügen",
    "NFC scannen": "NFC scannen",
    "Beifahrer abmelden": "Beifahrer abmelden",
    "Hallo": "Hallo",
    "FuehrerscheinkontrolleText1": "die Führerscheinkontrolle ist fällig.",
    "FuehrerscheinkontrolleTextFoto": "Bitte fotografieren Sie die Vorder- und Rückseite Ihres Führerscheins.",
    "Foto Vorderseite": "Foto Vorderseite",
    "Foto Rückseite": "Foto Rückseite",
    "FuehrerscheinkontrolleTextNFC": "Bitte scannen Sie den NFC-Chip auf ihrem Führerschein.",
    "FuahrerscheinkontrolleNfcFalsch": "Ungültiger NFC-Chip",
    "Fahrerkarten": "Fahrerkarten",
    "Führerscheinkontrolle": "Führerscheinkontrolle",
    "Später": "Später",
    "Führerschein erfolgreich gescannt": "Führerschein erfolgreich gescannt",
    "Bitte Ablaufdatum erfassen": "Bitte Ablaufdatum erfassen",
    "Führerscheinprüfung wirklich verschieben?": "Führerscheinprüfung wirklich verschieben?",
    "Abmelden?": "Abmelden?",
    "Bitte NFC-Chip scannen": "Bitte NFC-Chip scannen",
    "Suchen": "Suchen",
    "Archiv anzeigen": "Archiv anzeigen",
    "Übersicht": "Übersicht",
    "Bitte Entladeort auswählen": "Bitte Entladeort auswählen",
    "Von": "Von",
    "Bis": "Bis",
    "Zeitausgleich": "Zeitausgleich",
    "Urlaub 2": "Urlaub 2",
    "Urlaub 3": "Urlaub 3",
    "Urlaub 4": "Urlaub 4",
    "Unterschrift erforderlich": "Unterschrift erforderlich",
    "Zum unterschreiben hier tippen": "Zum unterschreiben hier tippen",
    "Unterschrift": "Unterschrift",
    "Wird geladen...": "Wird geladen...",
    "Funktion im Browser nicht verfügbar": "Funktion im Browser nicht verfügbar"
  },
  "EN": {
    "Permissions": "Authorizations",
    "Permission.ShowUsers": "Show users",
    "Permission.EditUsers": "Manage users",
    "Permission.ShowProjects": "Show projects",
    "Permission.EditProjects": "Manage projects",
    "Permission.ShowApplications": "Show applications",
    "Permission.EditApplications": "Manage applications",
    "Permission.ShowDemandRequest": "Show requirement requests",
    "Permission.EditDemandRequest": "Manage requirement requests",
    "Permission.CreateDemandRequest": "Create requirement requests",
    "Permission.ShowUserRoles": "Show user roles",
    "Permission.EditUserRoles": "Manage user roles",
    "Permission.ShowGeraete": "Show devices",
    "Permission.EditGeraete": "Manage devices",
    "Permission.ReadGeraete": "Read devices",
    "Permission.Administration": "Administration",
    "Permission.ReadAuftrag": "Read orders",
    "Permission.ListAuftraege": "Show orders",
    "Permission.ShowKarte": "Show map",
    "Permission.ProtokollAnzeigen": "Show protocol",
    "Permission.ProtokollBearbeiten": "Edit protocol",
    "Permission.KarteAnzeigen": "Show map",
    "Permission.WorkflowsAnzeigen": "Show workflows",
    "Permission.WorkflowsBearbeiten": "Edit workflows",
    "Permission.FahrerBearbeiten": "Edit driver",
    "Permission.FahrerAnzeigen": "Show driver",
    "Permission.FahrzeugBearbeiten": "Edit vehicles",
    "Permission.FahrzeugAnzeigen": "Show vehicles",
    "Permission.ArtikelAnzeigen": "Show article",
    "Permission.ArtikelBearbeiten": "Edit article",
    "Permission.UvvKatalogAnzeigen": "Show UVV catalog",
    "Permission.UvvKatalogBearbeiten": "Edit UVV catalog",
    "Permission.AbfahrtskontrolleKatalogAnzeigen": "Show departure control catalog",
    "Permission.AbfahrtskontrolleKatalogBearbeiten": "Edit departure control catalog",
    "Permission.FormulareBearbeiten": "Edit forms",
    "Permission.UvvPruefungenAnzeigen": "Show UVV inspections",
    "Permission.UvvPruefungenBearbeiten": "Process UVV inspections",
    "Permission.UvvPruefGegenstaendeAnzeigen": "Display UVV objects",
    "Permission.UvvPruefGegenstaendeBearbeiten": "Edit UVV objects",
    "Permission.ChecklisteBearbeiten": "Edit checklists",
    "Permission.ChecklisteAnzeigen": "Show checklists",
    "Permission.AdresseAnzeigen": "Show addresses",
    "Permission.DokumenteBearbeiten": "Edit documents",
    "Permission.DruckformulareAnzeigen": "Show print forms",
    "Permission.DruckformulareBearbeiten": "Edit print forms",
    "Permission.ArtikelgruppenAnzeigen": "Show article groups",
    "Permission.ArtikelgruppenBearbeiten": "Edit article groups",
    "Permission.Sysadmin": "Sysadmin",
    "Permission.FahrzeuggruppenAnzeigen": "Show vehicle groups",
    "Permission.FahrzeuggruppenBearbeiten": "Edit vehicle groups",
    "Permission.GeraetegruppenAnzeigen": "Show device groups",
    "Permission.GeraetegruppenBearbeiten": "Edit device groups",
    "Permission.StatuszusatzinfoAnzeigen": "Display additional status information",
    "Permission.StatuszusatzinfoBearbeiten": "Edit additional status info",
    "Permission.Nachrichten": "News",
    "Permission.AdresseBearbeiten": "Edit addresses",
    "Permission.WaageAnzeigen": "Show scales",
    "Permission.WaageBearbeiten": "Edit scales",
    "Permission.Tabellenkonfiguration": "Table configuration",
    "Permission.EditGeraeteEinfacheDaten": "Devices Change simple data",
    "Permission.AuftraegeBearbeiten": "Edit orders",
    "Permission.AuftraegeLoeschen": "Delete orders",
    "Permission.DokumenteAnzeigen": "Show documents",
    "Permission.Dashboard": "Overview (Dashboard)",
    "Permission.GastzugangAnzeigen": "Show guest access",
    "Permission.GastzugangBearbeiten": "Edit guest access",
    "Permission.StammtourAnzeigen": "Show regular tour",
    "Permission.StammtourBearbeiten": "Edit regular tour",
    "Permission.StoerzeitAnzeigen": "Display fault time",
    "Permission.StoerzeitBearbeiten": "Edit fault time",
    "Permission.TourenAnzeigen": "Show tours",
    "Permission.TourenBearbeiten": "Edit tours",
    "Permission.Tourenplanung": "Tour planning",
    "Anmelden mit Personalnummer": "Log in with personnel number",
    "TourWiegung1": "1. Weighing",
    "TourWiegung2": "2. Weighing",
    "BitteWiegung1Durchfuehren": "Please carry out 1st weighing",
    "BitteWiegung2Durchfuehren": "Please carry out 2nd weighing",
    "Permission.AbfahrtskontrolleBearbeiten": "Edit departure control",
    "Permission.AbfahrtskontrolleAnzeigen": "Show departure control",
    "Permission.Auswertungen": "Evaluations",
    "Permission.TexteAnzeigen": "Show texts",
    "Permission.TexteBearbeiten": "Edit texts",
    "AuftragWiegung1": "1. Weighing",
    "AuftragWiegung2": "2. Weighing",
    "Beladung": "Loading",
    "Auftrag scannen": "Scan order",
    "Tour": "Tour",
    "Home_Anmelden": "Log in with personnel number",
    "Home_TourStarten": "Start tour",
    "Home_TourBeenden": "End tour",
    "Home_AuftragScannen": "Scan order",
    "Home_Beladung": "Loading",
    "Home_Hofauftraege": "Court orders",
    "Wareneingang": "Incoming goods",
    "Glas-Analyse": "Glass analysis",
    "Home_Uebersicht": "Overview",
    "Home_Kunden": "Customers",
    "NagivationHome": "Home",
    "UVV-Prüfungen": "UVV inspections",
    "Dokumente": "Documents",
    "Abmelden": "Log out",
    "Smartwatch": "Smartwatch",
    "ProGlove": "ProGlove",
    "Home_System": "System",
    "Permission.VertraegeAnzeigen": "Show contracts",
    "Permission.VertraegeBearbeiten": "Edit contracts",
    "Save": "Save",
    "Cancel": "Cancel",
    "NeuenTransportauftragAnlegen": "Create a new transport request?",
    "NeuenWareneingangAnlegen": "Enter a new goods receipt?",
    "NeuenHofauftragAnlegen": "Create a new yard order?",
    "Vertrag": "Contract",
    "Neuer Auftrag": "New order",
    "Abbrechen": "Cancel",
    "Home_Zeiterfassung": "Time recording",
    "SchreibgeschuetztVorStatusmeldung": "Order is write-protected until the first status message",
    "NavigationErlaubenWennSchreibgeschuetzt": "Allow navigation if read-only",
    "TransportauftragLeerePositionenAusblenden": "Hide items with quantity 0",
    "Auswertungen": "Evaluations",
    "Abfahrtskontrolle": "Departure control",
    "UVV-Gegenstände": "UVV objects",
    "UVV-Katalog": "UVV catalog",
    "Einträge": "Entries",
    "Name": "Name",
    "Beschreibung": "Description",
    "REST-Schnittstelle": "REST interface",
    "Abrechnung aktiv": "Billing active",
    "API-Token": "API token",
    "Angelegt am": "Created on",
    "Vorname": "First name",
    "Nachname": "Last name",
    "Email": "Email",
    "Roles": "User roles",
    "Field is required": "Field is required",
    "E-Mail": "E-mail",
    "Zugangsdaten ändern": "Change access data",
    "Passwort": "Password",
    "Passwort (bestätigen)": "Password (confirm)",
    "Benutzer bearbeiten": "Edit user",
    "Key": "Key",
    "Checkliste": "Checklist",
    "Mandant": "Client",
    "ApiToken": "API token",
    "Latitude": "Latitude",
    "Longitude": "Longitude",
    "Max. Entfernung": "Max. Distance",
    "Timeout": "Timeout",
    "Icon": "Icon",
    "Nummer": "Number",
    "Bezeichnung": "Designation",
    "Gruppe": "Group",
    "App Version": "App version",
    "Traffic aktueller Monat": "Traffic current month",
    "Personalnr.": "Personnel no.",
    "Personal": "Personnel",
    "Zuletzt online": "Last online",
    "Fahrzeug": "Vehicle",
    "Anhänger": "Trailer",
    "Letzte Position": "Last position",
    "Farbe": "Color",
    "Letzte Anmeldung": "Last registration",
    "Letzte Abmeldung": "Last deregistration",
    "Angemeldet": "Registered",
    "Telefon": "Telephone",
    "Freitext": "Free text",
    "Platform": "Platform",
    "Version": "Version",
    "Hersteller": "Manufacturer",
    "Model": "Model",
    "Alert Berechtigung": "Alert authorization",
    "Erste Verwendung": "First use",
    "Letzte Verwendung": "Last use",
    "Abrechnung ab": "Billing from",
    "Abrechnung bis": "Settlement until",
    "Preis / Monat": "Price / month",
    "Einmaliger Preis ": "One-off price",
    "Geräte (Systemansicht)": "Devices (system view)",
    "RoleName": "RoleName",
    "Symbol": "Icon",
    "App-Version": "App version",
    "Verfügbare Waagen": "Available scales",
    "Importieren": "Import",
    "Gerätegruppe bearbeiten": "Edit device group",
    "Kopieren": "Copy",
    "Exportieren": "Export",
    "Zeit (h)": "Time (h)",
    "Intervall (m)": "Interval (m)",
    "MeldungText": "MessageText",
    "Standard-Dauer": "Standard duration",
    "Typ": "Type",
    "Datum": "Date",
    "Verlauf": "Course",
    "Auftragsnr.": "Order no.",
    "Gerätenr.": "Device no.",
    "Gerät": "Device",
    "Status": "Status",
    "PushStatus": "PushStatus",
    "Abholdatum": "Collection date",
    "Abholzeit": "Pick-up time",
    "Erfasser": "Collector",
    "Adress Nr.": "Address no.",
    "Name1": "Name1",
    "Name2": "Name2",
    "Strasse": "Street",
    "PLZ": "ZIP CODE",
    "Ort": "Location",
    "Kunde Nr.": "Customer no.",
    "Kunde Name1": "Customer Name1",
    "Kunde Name2": "Customer name2",
    "Kunde Strasse": "Customer Street",
    "Kunde PLZ": "Customer Postcode",
    "Kunde Ort": "Customer Location",
    "Info1": "Info1",
    "Info2": "Info2",
    "Info3": "Info3",
    "DetailInfo1": "DetailInfo1",
    "DetailInfo2": "DetailInfo2",
    "DetailInfo3": "DetailInfo3",
    "Workflow": "Workflow",
    "Statusmeldung": "Status message",
    "Statuszeit": "Status time",
    "Reihenfolge": "Sequence",
    "CreationDate": "CreationDate",
    "ModificationDate": "ModificationDate",
    "Lieferpositionen": "Delivery items",
    "Auftrag": "Order",
    "RememberMe": "Stay logged in",
    "Username": "User name",
    "Geräte": "Devices",
    "Stammdaten": "Master data",
    "Adressen": "Addresses",
    "Artikelgruppen": "Article groups",
    "Artikel": "Article",
    "Checklisten": "Checklists",
    "Fahrer (Personal)": "Driver (personnel)",
    "Fahrzeuge": "Vehicles",
    "Fahrzeuggruppen": "Vehicle groups",
    "Konfiguration": "Configuration",
    "Benutzer": "Users",
    "Benutzerrollen": "User roles",
    "Druckformulare": "Print forms",
    "Einstellungen": "Settings",
    "Formulare": "Forms",
    "Gerätegruppen": "Device groups",
    "Waagen": "Scales",
    "Workflows": "Workflows",
    "Statuszusatzinfo": "Additional status info",
    "Texte": "Texts",
    "Import / Export": "Import / Export",
    "Benutzername": "User name",
    "E-Mail-Adresse": "E-mail address",
    "Passwort ändern": "Change password",
    "Permission.Benachrichtigungen": "Notifications",
    "Permission.ZeiterfassungAnzeigen": "Show time recording",
    "Permission.ZeiterfassungBearbeiten": "Edit time recording",
    "Nachtmodus": "Dark mode",
    "Nachmodus": "Dark mode",
    "KeineAuftraegeGefunden": "No orders found",
    "KeineAuftraegeVorhanden": "No orders available. Pull down to refresh.",
    "TourStartenAlertButtonText": "Start tour !",
    "Aktuelle Aufträge anzeigen": "Show current orders",
    "Archiv": "Archive",
    "weitere Aufträge vorhanden": "further orders available",
    "weiterer Auftrag vorhanden": "further order available",
    "Anmerkungen": "Notes",
    "AnmerkungenHierEingeben": "Enter comments here..",
    "AuftragDarfNichtGeaendertWerden": "Order must not be changed",
    "AuftragBereitsAbgeschlossen": "Order has already been completed. Changes are no longer possible.",
    "Start": "Start",
    "System": "System",
    "Transportaufträge": "Transportation orders",
    "Hofaufträge": "Court orders",
    "Entladeort": "Unloading point",
    "Bitte Entladeort wählen": "Please select unloading location",
    "TourStart_Wiegen": "Weighing",
    "TourEnde_StartWiegung": "Weighing at the start of the tour",
    "TourEnde_EndeWiegung": "Weighing (full truck)",
    "Geplante Reihenfolge anzeigen": "Show planned sequence",
    "Permission.GlasAnalyseAnzeigen": "Show glass analysis",
    "Permission.GlasAnalyseBearbeiten": "Edit glass analysis",
    "BitteAuswaehlenPlaceholder": "Please select",
    "BitteAnlageWaehlen": "Please select system",
    "Permission.GlasAnalyseKonfiguration": "Glass analysis configuration",
    "UVV Prüfung": "UVV inspection",
    "Auftrag_Abholadresse": "Address",
    "Auftrag_Erzeugeradresse": "Customer",
    "Auftrag_Verwerteradresse": "Recycler",
    "Auftrag_Fahrzeug": "Vehicle",
    "Auftrag_Abholdatum": "Collection date",
    "Auftrag_Abholzeit": "Pick-up time",
    "Permission.BerichteAnzeigen": "Show reports",
    "Permission.BerichteBearbeiten": "Edit reports",
    "Permission.Kalender": "Calendar",
    "Permission.NVEAnzeigen": "Show NVE",
    "Permission.NVEBearbeiten": "Edit NVE",
    "Permission.RegelAnzeigen": "Show rules",
    "Permission.RegelBearbeiten": "Edit rules",
    "ExportMessageStatus_0": "Initial",
    "ExportMessageStatus_1": "Sent",
    "ExportMessageStatus_2": "Processed",
    "ExportMessageStatus_3": "Error",
    "ExportMessageStatus_4": "Wait for ACK",
    "Gerätegruppe": "Device group",
    "Sonstiges": "Miscellaneous",
    "Sprache": "Language",
    "APP Version": "APP version",
    "Local storage": "Local storage",
    "Advanced HTTP": "Advanced HTTP",
    "System Alert Berechtigung": "System Alert Authorization",
    "Navigations-App": "Navigation app",
    "Zurücksetzen": "Reset",
    "WebSocket": "WebSocket",
    "Push-Status": "Push status",
    "Push-ID": "Push ID",
    "Push-Type": "Push type",
    "Anzahl Artikelgruppen": "Number of article groups",
    "Anzahl Artikel": "Number of articles",
    "Anzahl Workflows": "Number of workflows",
    "GPS Dienst läuft": "GPS service running",
    "GPS Dienst aktiv": "GPS service active",
    "GPS Auth-Status": "GPS Auth-Status",
    "GPS-Positionen (Puffer)": "GPS positions (buffer)",
    "Zugangsdaten": "Access data",
    "Geräte-Nummer": "Device number",
    "Host": "Host",
    "Speichern": "Save",
    "Stammdaten neu laden": "Reload master data",
    "Lokale Daten löschen": "Delete local data",
    "Diagnosedaten senden": "Send diagnostic data",
    "Testdatei senden": "Send test file",
    "Update prüfen": "Check update",
    "Dunkler Modus": "Dark mode",
    "Aktivieren": "Activate",
    "Anzeigen": "Display",
    "Anmelden": "Log in",
    "Personalnummer": "Personnel number",
    "Kennwort": "Password",
    "Mit NFC anmelden": "Log in with NFC",
    "Bitte eingeben": "Please enter",
    "Passwort eingeben": "Enter password",
    "Anmelden mit NFC-Chip": "Log in with NFC chip",
    "Zur Anmeldung NFC-Chip scannen": "Scan NFC chip to log in",
    "Mit Personalnummer anmelden": "Log in with personnel number",
    "Ablaufdatum": "Expiration date",
    "Kein Fahrer für RFID-Chip gefunden": "No driver found for RFID chip",
    "Nachrichten": "Messages",
    "Berichte": "Reports",
    "Urlaubsanträge": "Vacation requests",
    "Unfallberichte": "Accident reports",
    "Tätigkeitsberichte": "Activity reports",
    "an": "on",
    "aus": "from",
    "KeineEintraegeVorhanden": "No entries available",
    "StoerzeitFuerAuftrag": "Fault times for order",
    "StoerzeitFuerTour": "Fault times for tour",
    "Minuten": "Minutes",
    "Sekunden": "Seconds",
    "Minute": "Minute",
    "TourNichtGestartetLoeschen": "Tour not started. Deletion not possible.",
    "ZeiterfassungOhneTourFehler": "Time recording cannot be started without a tour",
    "Tour start": "Tour start",
    "Kilometerstand": "Mileage",
    "Fahrer": "Driver",
    "Beifahrer": "Passenger",
    "Bitte wählen": "Please select",
    "Beifahrer 2": "Passenger 2",
    "Abfahrtskontrolle Anhänger": "Trailer departure check",
    "Foto aufnehmen": "Take a photo",
    "Zum Bearbeiten hier tippen": "Tap here to edit",
    "Eingeben": "Enter",
    "Uhrzeit": "Time",
    "Driver": "Driver",
    "Passenger": "Passenger",
    "Passenger 2": "Passenger 2",
    "Trailer": "Trailer",
    "Vehicle": "Vehicle",
    "BitteEingabePruefen": "Please check input!",
    "RFID scannen": "Scan RFID",
    "Tour scannen": "Scan tour",
    "Tour starten": "Start tour",
    "Tour laden": "Load tour",
    "Tour entladen": "Unload tour",
    "Schreiben": "Write",
    "Alle Nachrichten löschen?": "Delete all messages?",
    "Löschen": "Delete",
    "Ja": "Yes",
    "Nein": "No",
    "Nächste Prüfung": "Next test",
    "alle anzeigen": "show all",
    "Bitte KM eingeben": "Please enter KM",
    "NFCErkennungLaeuft": "NFC recognition is running..",
    "RFIDWirdGescannt": "RFID is scanned..",
    "OK": "OK",
    "Fehlerbeschreibung": "Error description",
    "Bild wirklich löschen?": "Really delete the image?",
    "Frage": "Question",
    "Bitte Kilometerstand angeben": "Please state mileage",
    "Bitte Ankunftskontrolle vollständig ausfüllen": "Please fill in the arrival check completely",
    "Bitte Ankunftskontrolle Anhänger vollständig ausfüllen": "Please fill in the arrival control tag completely",
    "Bitte Unterschrift erfassen": "Please enter your signature",
    "Bitte Namen für die Unterschrift erfassen": "Please enter names for the signature",
    "Bitte nehmen Sie sich genügend Zeit für die Ankunftskontrolle!": "Please allow sufficient time for the arrival check!",
    "AnkunftskontrolleDauerFrage1": "The specified time for the arrival check was not adhered to. Finish the tour anyway?",
    "Tour beenden?": "Finish the tour?",
    "AnkunftskontrolleDauerFrage2": "The specified time for the arrival check was not adhered to. Finish the tour anyway?<br /><br />A message will be sent!",
    "Bitte Abfahrtskontrolle vollständig ausfüllen": "Please fill in the departure check completely",
    "AbfahrtskontrolleMeldung1": "Please allow sufficient time for the departure check!",
    "AbfahrtskontrolleMeldung2": "The specified time for the departure check was not adhered to. Start the tour anyway?",
    "AbfahrtskontrolleMeldung3": "The specified time for the departure check was not adhered to. Start the tour anyway?<br /><br />A message will be sent!",
    "KeineWiegungTourTrotzdemStarten": "No weighing performed. Start the tour anyway?",
    "Wiegung": "Weighing",
    "WiegungErfolgreich": "Weighing required!",
    "Tour start?": "Tour start?",
    "Tour aktiv seit": "Tour active since",
    "Gestartet um": "Started at",
    "Gestartet gestern": "Started yesterday",
    "Gestartet": "Started",
    "Keine gültige Auftragsnummer": "No valid order number",
    "Heute offene Aufträge": "Open orders today",
    "Heute erledigt Aufträge": "Orders completed today",
    "Morgen": "Tomorrow",
    "Keine Transportaufträge": "No transport orders",
    "1 Transportauftrag": "1 transport order",
    "aktuelle Aufträge": "current orders",
    "Statusmeldung428": "Status message not possible. Weighing has already started. Please end tour and restart if necessary.",
    "Meldung429": "<p>Status message not possible. Tour has not yet started.</p><p>Start tour now?</p>",
    "Meldung430": "Status not allowed. Please report one of the following statuses",
    "Meldung431": "Not all items have been reported as complete. Are you sure that the pallet is ready?",
    "Es muss mindestens ein Foto gemacht werden!": "At least one photo must be taken!",
    "Alle Aufträge": "All orders",
    "Kommissionierung": "Order picking",
    "Wiegungen": "Weighing",
    "Gekoppelte Waagen": "Coupled scales",
    "Waage suchen...": "Search scales...",
    "Keine Wiegedaten vorhanden": "No weighing data available",
    "Keine Waagen konfiguriert": "No scales configured",
    "Waage": "Scales",
    "MAC Adresse": "MAC address",
    "Aktiv": "Active",
    "Uhr synchronisieren": "Synchronize clock",
    "Wiegen 1": "Cradle 1",
    "Wiegen 2": "Cradle 2",
    "Tarieren": "Taring",
    "Keine neue Waage gefunden": "No new scales found",
    "TourAktivBeendenMeldung449": "Tour is still active. End tour now?",
    "WirklichAbmelden": "Really unsubscribe?",
    "Aktuelle Tour": "Current tour",
    "Tour beenden": "End tour",
    "Zeiterfassung": "Time recording",
    "Zeiterfassung starten": "Start time recording",
    "Tour Ende": "Tour end",
    "Art": "Kind",
    "Arbeitszeit bearbeiten": "Edit working time",
    "Wiegen": "Weighing",
    "Tätigkeitsbericht": "Activity report",
    "Personaldaten": "Personnel data",
    "Tätigkeit": "Activity",
    "Übernachtung": "Overnight stay",
    "Beschreibung der Tätigkeit": "Description of the activity",
    "Neuer Urlaubsantrag": "New vacation request",
    "Abteilung": "Department",
    "Urlaub": "Vacation",
    "Angemeldete Beifahrer": "Registered passengers",
    "Beifahrer hinzufügen": "Add passenger",
    "Beifahrer NFC scannen": "Scan passenger NFC",
    "BeifahrerListeNichtGeladen": "List of passengers could not be loaded. Please check your internet connection.",
    "Beifahrer wurde gescannnt": "Passenger was scanned",
    "NfcKeinBeifahrerGefunden": "No passenger found for RFID chip",
    "BeifahrerAlsFahrerMeldung473": "You are already registered as a driver for this tour. You cannot register again as a passenger.",
    "BeifahrerBereitsAngemeldet": "You are already registered as a passenger.",
    "MaxZweiBeifahrerMoeglich": "A maximum of 2 co-drivers can be registered",
    "BeifahrerNichtGefunden": "Passenger not found",
    "BeifahrerWurdeHinzugefuegt": "Passenger was added",
    "Ein Anhänger kann nicht als Fahrzeug gescannt werden": "A trailer cannot be scanned as a vehicle",
    "Ein LKW kann nicht als Anhänger gescannt werden": "A truck cannot be scanned as a trailer",
    "AngemeldetSeit": "Registered since",
    "Hinzufügen": "Add",
    "NFC scannen": "NFC scan",
    "Beifahrer abmelden": "Log out passenger",
    "Hallo": "Hello",
    "FuehrerscheinkontrolleText1": "the driver's license check is due.",
    "FuehrerscheinkontrolleTextFoto": "Please take a photo of the front and back of your driver's license.",
    "Foto Vorderseite": "Photo front",
    "Foto Rückseite": "Photo back",
    "FuehrerscheinkontrolleTextNFC": "Please scan the NFC chip on your driver's license.",
    "FuahrerscheinkontrolleNfcFalsch": "Invalid NFC chip",
    "Fahrerkarten": "Driver cards",
    "Führerscheinkontrolle": "Driver's license check",
    "Später": "Later",
    "Führerschein erfolgreich gescannt": "Driver's license successfully scanned",
    "Bitte Ablaufdatum erfassen": "Please enter expiration date",
    "Führerscheinprüfung wirklich verschieben?": "Can you really postpone your driving test?",
    "Abmelden?": "Log out?",
    "Bitte NFC-Chip scannen": "Please scan NFC chip",
    "Suchen": "Search",
    "Archiv anzeigen": "Show archive",
    "Übersicht": "Overview",
    "Bitte Entladeort auswählen": "Please select unloading location",
    "Von": "From",
    "Bis": "Until",
    "Zeitausgleich": "Time compensation",
    "Urlaub 2": "Vacation 2",
    "Urlaub 3": "Vacation 3",
    "Urlaub 4": "Vacation 4",
    "Unterschrift erforderlich": "Signature required",
    "Zum unterschreiben hier tippen": "Type here to sign",
    "Unterschrift": "Signature",
    "Wird geladen...": "Loading...",
    "Funktion im Browser nicht verfügbar": "Function not available in the browser"
  },
  "PL": {
    "Permissions": "Zezwolenia",
    "Permission.ShowUsers": "Pokaż użytkowników",
    "Permission.EditUsers": "Zarządzanie użytkownikami",
    "Permission.ShowProjects": "Pokaż projekty",
    "Permission.EditProjects": "Zarządzanie projektami",
    "Permission.ShowApplications": "Pokaż aplikacje",
    "Permission.EditApplications": "Zarządzanie aplikacjami",
    "Permission.ShowDemandRequest": "Zapytania dotyczące wymagań pokazowych",
    "Permission.EditDemandRequest": "Zarządzanie zamówieniami zakupu",
    "Permission.CreateDemandRequest": "Tworzenie zapytań o wymagania",
    "Permission.ShowUserRoles": "Wyświetlanie ról użytkowników",
    "Permission.EditUserRoles": "Zarządzanie rolami użytkowników",
    "Permission.ShowGeraete": "Pokaż urządzenia",
    "Permission.EditGeraete": "Zarządzanie urządzeniami",
    "Permission.ReadGeraete": "Czytaj urządzenia",
    "Permission.Administration": "Administracja",
    "Permission.ReadAuftrag": "Przeczytaj zamówienia",
    "Permission.ListAuftraege": "Pokaż zamówienia",
    "Permission.ShowKarte": "Pokaż mapę",
    "Permission.ProtokollAnzeigen": "Pokaż protokół",
    "Permission.ProtokollBearbeiten": "Edytuj protokół",
    "Permission.KarteAnzeigen": "Pokaż mapę",
    "Permission.WorkflowsAnzeigen": "Pokaż przepływy pracy",
    "Permission.WorkflowsBearbeiten": "Edycja przepływów pracy",
    "Permission.FahrerBearbeiten": "Edytuj sterownik",
    "Permission.FahrerAnzeigen": "Pokaż kierowcę",
    "Permission.FahrzeugBearbeiten": "Edycja pojazdów",
    "Permission.FahrzeugAnzeigen": "Pokaż pojazdy",
    "Permission.ArtikelAnzeigen": "Pokaż artykuł",
    "Permission.ArtikelBearbeiten": "Edytuj artykuł",
    "Permission.UvvKatalogAnzeigen": "Pokaż katalog UVV",
    "Permission.UvvKatalogBearbeiten": "Edytuj katalog UVV",
    "Permission.AbfahrtskontrolleKatalogAnzeigen": "Pokaż katalog kontroli odlotów",
    "Permission.AbfahrtskontrolleKatalogBearbeiten": "Edytuj katalog kontroli odlotów",
    "Permission.FormulareBearbeiten": "Edycja formularzy",
    "Permission.UvvPruefungenAnzeigen": "Pokaż inspekcje UVV",
    "Permission.UvvPruefungenBearbeiten": "Kontrole UVV procesu",
    "Permission.UvvPruefGegenstaendeAnzeigen": "Wyświetlanie obiektów UVV",
    "Permission.UvvPruefGegenstaendeBearbeiten": "Edycja obiektów UVV",
    "Permission.ChecklisteBearbeiten": "Edycja list kontrolnych",
    "Permission.ChecklisteAnzeigen": "Wyświetlanie list kontrolnych",
    "Permission.AdresseAnzeigen": "Pokaż adresy",
    "Permission.DokumenteBearbeiten": "Edycja dokumentów",
    "Permission.DruckformulareAnzeigen": "Pokaż formularze drukowania",
    "Permission.DruckformulareBearbeiten": "Edycja formularzy drukowania",
    "Permission.ArtikelgruppenAnzeigen": "Pokaż grupy artykułów",
    "Permission.ArtikelgruppenBearbeiten": "Edycja grup artykułów",
    "Permission.Sysadmin": "Sysadmin",
    "Permission.FahrzeuggruppenAnzeigen": "Pokaż grupy pojazdów",
    "Permission.FahrzeuggruppenBearbeiten": "Edycja grup pojazdów",
    "Permission.GeraetegruppenAnzeigen": "Wyświetlanie grup urządzeń",
    "Permission.GeraetegruppenBearbeiten": "Edycja grup urządzeń",
    "Permission.StatuszusatzinfoAnzeigen": "Wyświetlanie dodatkowych informacji o stanie",
    "Permission.StatuszusatzinfoBearbeiten": "Edycja dodatkowych informacji o statusie",
    "Permission.Nachrichten": "Aktualności",
    "Permission.AdresseBearbeiten": "Edytuj adresy",
    "Permission.WaageAnzeigen": "Pokaż wagę",
    "Permission.WaageBearbeiten": "Edycja wag",
    "Permission.Tabellenkonfiguration": "Konfiguracja tabeli",
    "Permission.EditGeraeteEinfacheDaten": "Urządzenia Zmień proste dane",
    "Permission.AuftraegeBearbeiten": "Edytuj zamówienia",
    "Permission.AuftraegeLoeschen": "Usuń zamówienia",
    "Permission.DokumenteAnzeigen": "Pokaż dokumenty",
    "Permission.Dashboard": "Przegląd (pulpit nawigacyjny)",
    "Permission.GastzugangAnzeigen": "Pokaż dostęp gościa",
    "Permission.GastzugangBearbeiten": "Edycja dostępu gościa",
    "Permission.StammtourAnzeigen": "Pokaż regularną trasę",
    "Permission.StammtourBearbeiten": "Edytuj regularną trasę",
    "Permission.StoerzeitAnzeigen": "Wyświetlanie czasu usterki",
    "Permission.StoerzeitBearbeiten": "Edytuj czas błędu",
    "Permission.TourenAnzeigen": "Pokaż wycieczki",
    "Permission.TourenBearbeiten": "Edycja wycieczek",
    "Permission.Tourenplanung": "Planowanie podróży",
    "Anmelden mit Personalnummer": "Zaloguj się za pomocą numeru personalnego",
    "TourWiegung1": "1. Ważenie",
    "TourWiegung2": "2. Ważenie",
    "BitteWiegung1Durchfuehren": "Należy przeprowadzić pierwsze ważenie",
    "BitteWiegung2Durchfuehren": "Prosimy o przeprowadzenie drugiego ważenia",
    "Permission.AbfahrtskontrolleBearbeiten": "Edycja kontroli odlotów",
    "Permission.AbfahrtskontrolleAnzeigen": "Pokaż kontrolę odlotów",
    "Permission.Auswertungen": "Analizy",
    "Permission.TexteAnzeigen": "Pokaż teksty",
    "Permission.TexteBearbeiten": "Edycja tekstów",
    "AuftragWiegung1": "1. Ważenie",
    "AuftragWiegung2": "2. Ważenie",
    "Beladung": "Ładowanie",
    "Auftrag scannen": "Kolejność skanowania",
    "Tour": "Wycieczka",
    "Home_Anmelden": "Zaloguj się za pomocą numeru personalnego",
    "Home_TourStarten": "Rozpoczęcie wycieczki",
    "Home_TourBeenden": "Koniec wycieczki",
    "Home_AuftragScannen": "Kolejność skanowania",
    "Home_Beladung": "Ładowanie",
    "Home_Hofauftraege": "Nakazy sądowe",
    "Wareneingang": "Przychodzące towary",
    "Glas-Analyse": "Analiza szkła",
    "Home_Uebersicht": "Przegląd",
    "Home_Kunden": "Klienci",
    "NagivationHome": "Strona główna",
    "UVV-Prüfungen": "Inspekcje UVV",
    "Dokumente": "Dokumenty",
    "Abmelden": "Wylogowanie",
    "Smartwatch": "Smartwatch",
    "ProGlove": "ProGlove",
    "Home_System": "System",
    "Permission.VertraegeAnzeigen": "Pokaż umowy",
    "Permission.VertraegeBearbeiten": "Edycja umów",
    "Save": "Zapisz",
    "Cancel": "Anuluj",
    "NeuenTransportauftragAnlegen": "Utworzyć nowe żądanie transportu?",
    "NeuenWareneingangAnlegen": "Wprowadzić nowy paragon?",
    "NeuenHofauftragAnlegen": "Utworzyć nowe zamówienie na placu?",
    "Vertrag": "Umowa",
    "Neuer Auftrag": "Nowe zamówienie",
    "Abbrechen": "Anuluj",
    "Home_Zeiterfassung": "Rejestrowanie czasu",
    "SchreibgeschuetztVorStatusmeldung": "Zamówienie jest chronione przed zapisem do czasu pierwszego komunikatu o stanie",
    "NavigationErlaubenWennSchreibgeschuetzt": "Zezwalaj na nawigację, jeśli zabezpieczone przed zapisem",
    "TransportauftragLeerePositionenAusblenden": "Ukryj elementy z ilością 0",
    "Auswertungen": "Analizy",
    "Abfahrtskontrolle": "Kontrola odlotów",
    "UVV-Gegenstände": "Obiekty UVV",
    "UVV-Katalog": "Katalog UVV",
    "Einträge": "Wpisy",
    "Name": "Nazwa",
    "Beschreibung": "Opis",
    "REST-Schnittstelle": "Interfejs REST",
    "Abrechnung aktiv": "Rozliczenia aktywne",
    "API-Token": "Token API",
    "Angelegt am": "Utworzono dnia",
    "Vorname": "Imię",
    "Nachname": "Nazwisko",
    "Email": "E-mail",
    "Roles": "Role użytkowników",
    "Field is required": "Pole jest wymagane",
    "E-Mail": "E-mail",
    "Zugangsdaten ändern": "Zmiana danych dostępu",
    "Passwort": "Hasło",
    "Passwort (bestätigen)": "Hasło (potwierdź)",
    "Benutzer bearbeiten": "Edytuj użytkownika",
    "Key": "Klucz",
    "Checkliste": "Lista kontrolna",
    "Mandant": "Klient",
    "ApiToken": "Token API",
    "Latitude": "Szerokość geograficzna",
    "Longitude": "Długość geograficzna",
    "Max. Entfernung": "Maks. Odległość",
    "Timeout": "Limit czasu",
    "Icon": "Ikona",
    "Nummer": "Liczba",
    "Bezeichnung": "Oznaczenie",
    "Gruppe": "Grupa",
    "App Version": "Wersja aplikacji",
    "Traffic aktueller Monat": "Ruch w bieżącym miesiącu",
    "Personalnr.": "Personel nr.",
    "Personal": "Personel",
    "Zuletzt online": "Ostatni online",
    "Fahrzeug": "Pojazd",
    "Anhänger": "Zwiastun",
    "Letzte Position": "Ostatnia pozycja",
    "Farbe": "Kolor",
    "Letzte Anmeldung": "Ostatnia rejestracja",
    "Letzte Abmeldung": "Ostatnie wyrejestrowanie",
    "Angemeldet": "Zalogowany",
    "Telefon": "Telefon",
    "Freitext": "Dowolny tekst",
    "Platform": "Platforma",
    "Version": "Wersja",
    "Hersteller": "Producent",
    "Model": "Model",
    "Alert Berechtigung": "Autoryzacja alertu",
    "Erste Verwendung": "Pierwsze użycie",
    "Letzte Verwendung": "Ostatnie użycie",
    "Abrechnung ab": "Rozliczenie od",
    "Abrechnung bis": "Rozliczenie do",
    "Preis / Monat": "Cena / miesiąc",
    "Einmaliger Preis ": "Cena jednorazowa",
    "Geräte (Systemansicht)": "Urządzenia (widok systemu)",
    "RoleName": "RoleName",
    "Symbol": "Symbol",
    "App-Version": "Wersja aplikacji",
    "Verfügbare Waagen": "Dostępne wagi",
    "Importieren": "Import",
    "Gerätegruppe bearbeiten": "Edycja grupy urządzeń",
    "Kopieren": "Kopia",
    "Exportieren": "Eksport",
    "Zeit (h)": "Czas (h)",
    "Intervall (m)": "Interwał (m)",
    "MeldungText": "MessageText",
    "Standard-Dauer": "Standardowy czas trwania",
    "Typ": "Typ",
    "Datum": "Data",
    "Verlauf": "Kurs",
    "Auftragsnr.": "Nr zamówienia.",
    "Gerätenr.": "Nr urządzenia.",
    "Gerät": "Urządzenie",
    "Status": "Status",
    "PushStatus": "PushStatus",
    "Abholdatum": "Data odbioru",
    "Abholzeit": "Czas odbioru",
    "Erfasser": "Kolekcjoner",
    "Adress Nr.": "Adres nr.",
    "Name1": "Nazwa1",
    "Name2": "Nazwa2",
    "Strasse": "Ul",
    "PLZ": "KOD POCZTOWY",
    "Ort": "Lokalizacja",
    "Kunde Nr.": "Nr klienta.",
    "Kunde Name1": "Nazwa klienta1",
    "Kunde Name2": "Nazwa klienta2",
    "Kunde Strasse": "Ulica klienta",
    "Kunde PLZ": "Kod pocztowy klienta",
    "Kunde Ort": "Lokalizacja klienta",
    "Info1": "Info1",
    "Info2": "Info2",
    "Info3": "Info3",
    "DetailInfo1": "DetailInfo1",
    "DetailInfo2": "DetailInfo2",
    "DetailInfo3": "DetailInfo3",
    "Workflow": "Przepływ pracy",
    "Statusmeldung": "Komunikat o stanie",
    "Statuszeit": "Czas statusu",
    "Reihenfolge": "Sekwencja",
    "CreationDate": "CreationDate",
    "ModificationDate": "ModificationDate",
    "Lieferpositionen": "Elementy dostawy",
    "Auftrag": "Zamówienie",
    "RememberMe": "Pozostań zalogowany",
    "Username": "Nazwa użytkownika",
    "Geräte": "Urządzenia",
    "Stammdaten": "Dane podstawowe",
    "Adressen": "Adresy",
    "Artikelgruppen": "Grupy artykułów",
    "Artikel": "Artykuł",
    "Checklisten": "Listy kontrolne",
    "Fahrer (Personal)": "Kierowca (personel)",
    "Fahrzeuge": "Pojazdy",
    "Fahrzeuggruppen": "Grupy pojazdów",
    "Konfiguration": "Konfiguracja",
    "Benutzer": "Użytkownicy",
    "Benutzerrollen": "Role użytkowników",
    "Druckformulare": "Drukowanie formularzy",
    "Einstellungen": "Ustawienia",
    "Formulare": "Formularze",
    "Gerätegruppen": "Grupy urządzeń",
    "Waagen": "Wagi",
    "Workflows": "Przepływy pracy",
    "Statuszusatzinfo": "Dodatkowe informacje o stanie",
    "Texte": "Teksty",
    "Import / Export": "Import / Eksport",
    "Benutzername": "Nazwa użytkownika",
    "E-Mail-Adresse": "Adres e-mail",
    "Passwort ändern": "Zmiana hasła",
    "Permission.Benachrichtigungen": "Powiadomienia",
    "Permission.ZeiterfassungAnzeigen": "Wyświetlanie nagrywania czasu",
    "Permission.ZeiterfassungBearbeiten": "Edycja nagrywania czasu",
    "Nachtmodus": "Tryb nocny",
    "Nachmodus": "Tryb nocny",
    "KeineAuftraegeGefunden": "Nie znaleziono żadnych zamówień",
    "KeineAuftraegeVorhanden": "Brak dostępnych zamówień. Pociągnij w dół, aby odświeżyć.",
    "TourStartenAlertButtonText": "Rozpocznij wycieczkę!",
    "Aktuelle Aufträge anzeigen": "Pokaż bieżące zamówienia",
    "Archiv": "Archiwum",
    "weitere Aufträge vorhanden": "dostępne są dalsze zamówienia",
    "weiterer Auftrag vorhanden": "dostępne są dalsze zamówienia",
    "Anmerkungen": "Uwagi",
    "AnmerkungenHierEingeben": "Wpisz komentarz tutaj..",
    "AuftragDarfNichtGeaendertWerden": "Kolejność nie może zostać zmieniona",
    "AuftragBereitsAbgeschlossen": "Zamówienie zostało już zrealizowane. Zmiany nie są już możliwe.",
    "Start": "Start",
    "System": "System",
    "Transportaufträge": "Zlecenia transportowe",
    "Hofaufträge": "Nakazy sądowe",
    "Entladeort": "Punkt rozładunku",
    "Bitte Entladeort wählen": "Wybierz miejsce rozładunku",
    "TourStart_Wiegen": "Ważenie",
    "TourEnde_StartWiegung": "Ważenie na początku trasy",
    "TourEnde_EndeWiegung": "Ważenie (cała ciężarówka)",
    "Geplante Reihenfolge anzeigen": "Pokaż zaplanowaną sekwencję",
    "Permission.GlasAnalyseAnzeigen": "Pokaż analizę szkła",
    "Permission.GlasAnalyseBearbeiten": "Edycja analizy szkła",
    "BitteAuswaehlenPlaceholder": "Wybierz",
    "BitteAnlageWaehlen": "Wybierz system",
    "Permission.GlasAnalyseKonfiguration": "Konfiguracja analizy szkła",
    "UVV Prüfung": "Kontrola UVV",
    "Auftrag_Abholadresse": "Adres",
    "Auftrag_Erzeugeradresse": "Klient",
    "Auftrag_Verwerteradresse": "Recykler",
    "Auftrag_Fahrzeug": "Pojazd",
    "Auftrag_Abholdatum": "Data odbioru",
    "Auftrag_Abholzeit": "Czas odbioru",
    "Permission.BerichteAnzeigen": "Pokaż raporty",
    "Permission.BerichteBearbeiten": "Edycja raportów",
    "Permission.Kalender": "Kalendarz",
    "Permission.NVEAnzeigen": "Pokaż NVE",
    "Permission.NVEBearbeiten": "Edytuj NVE",
    "Permission.RegelAnzeigen": "Zasady pokazu",
    "Permission.RegelBearbeiten": "Edytuj zasady",
    "ExportMessageStatus_0": "Początkowy",
    "ExportMessageStatus_1": "Wysłany",
    "ExportMessageStatus_2": "Przetworzone",
    "ExportMessageStatus_3": "Błąd",
    "ExportMessageStatus_4": "Oczekiwanie na ACK",
    "Gerätegruppe": "Grupa urządzeń",
    "Sonstiges": "Różne",
    "Sprache": "Język",
    "APP Version": "Wersja APP",
    "Local storage": "Lokalna pamięć masowa",
    "Advanced HTTP": "Zaawansowany HTTP",
    "System Alert Berechtigung": "Autoryzacja alertu systemowego",
    "Navigations-App": "Aplikacja nawigacyjna",
    "Zurücksetzen": "Reset",
    "WebSocket": "WebSocket",
    "Push-Status": "Stan naciśnięcia",
    "Push-ID": "Push ID",
    "Push-Type": "Typ push",
    "Anzahl Artikelgruppen": "Liczba grup artykułów",
    "Anzahl Artikel": "Liczba artykułów",
    "Anzahl Workflows": "Liczba przepływów pracy",
    "GPS Dienst läuft": "Uruchomiona usługa GPS",
    "GPS Dienst aktiv": "Aktywna usługa GPS",
    "GPS Auth-Status": "Status autoryzacji GPS",
    "GPS-Positionen (Puffer)": "Pozycje GPS (bufor)",
    "Zugangsdaten": "Dane dostępu",
    "Geräte-Nummer": "Numer urządzenia",
    "Host": "Gospodarz",
    "Speichern": "Zapisz",
    "Stammdaten neu laden": "Przeładuj dane podstawowe",
    "Lokale Daten löschen": "Usuwanie danych lokalnych",
    "Diagnosedaten senden": "Wysyłanie danych diagnostycznych",
    "Testdatei senden": "Wyślij plik testowy",
    "Update prüfen": "Sprawdź aktualizację",
    "Dunkler Modus": "Tryb ciemny",
    "Aktivieren": "Aktywuj",
    "Anzeigen": "Wyświetlacze",
    "Anmelden": "Zaloguj się",
    "Personalnummer": "Numer personelu",
    "Kennwort": "Hasło",
    "Mit NFC anmelden": "Logowanie za pomocą NFC",
    "Bitte eingeben": "Wprowadź",
    "Passwort eingeben": "Wprowadź hasło",
    "Anmelden mit NFC-Chip": "Logowanie za pomocą chipu NFC",
    "Zur Anmeldung NFC-Chip scannen": "Zeskanuj chip NFC, aby się zalogować",
    "Mit Personalnummer anmelden": "Zaloguj się przy użyciu numeru personalnego",
    "Ablaufdatum": "Data ważności",
    "Kein Fahrer für RFID-Chip gefunden": "Nie znaleziono sterownika dla chipa RFID",
    "Nachrichten": "Aktualności",
    "Berichte": "Raporty",
    "Urlaubsanträge": "Prośby o urlop",
    "Unfallberichte": "Raporty z wypadków",
    "Tätigkeitsberichte": "Raporty z działalności",
    "an": "na",
    "aus": "z",
    "KeineEintraegeVorhanden": "Brak dostępnych wpisów",
    "StoerzeitFuerAuftrag": "Czasy awarii dla zamówienia",
    "StoerzeitFuerTour": "Czasy awarii dla trasy",
    "Minuten": "Minuty",
    "Sekunden": "Sekundy",
    "Minute": "Minuta",
    "TourNichtGestartetLoeschen": "Wycieczka nie została rozpoczęta. Usunięcie niemożliwe.",
    "ZeiterfassungOhneTourFehler": "Rejestracji czasu nie można uruchomić bez trasy",
    "Tour start": "Początek wycieczki",
    "Kilometerstand": "Przebieg",
    "Fahrer": "Kierowca",
    "Beifahrer": "Pasażer",
    "Bitte wählen": "Wybierz",
    "Beifahrer 2": "Pasażer 2",
    "Abfahrtskontrolle Anhänger": "Kontrola odjazdu przyczepy",
    "Foto aufnehmen": "Zrób zdjęcie",
    "Zum Bearbeiten hier tippen": "Stuknij tutaj, aby edytować",
    "Eingeben": "Enter",
    "Uhrzeit": "Pora dnia",
    "Driver": "Kierowca",
    "Passenger": "Pasażer",
    "Passenger 2": "Pasażer 2",
    "Trailer": "Zwiastun",
    "Vehicle": "Pojazd",
    "BitteEingabePruefen": "Sprawdź wprowadzone dane!",
    "RFID scannen": "Skanowanie RFID",
    "Tour scannen": "Skanowanie trasy",
    "Tour starten": "Rozpoczęcie wycieczki",
    "Tour laden": "Ładowanie trasy",
    "Tour entladen": "Wycieczka rozładunkowa",
    "Schreiben": "Pisanie",
    "Alle Nachrichten löschen?": "Usunąć wszystkie wiadomości?",
    "Löschen": "Usuń",
    "Ja": "Tak",
    "Nein": "Nie",
    "Nächste Prüfung": "Następny test",
    "alle anzeigen": "pokaż wszystko",
    "Bitte KM eingeben": "Wprowadź KM",
    "NFCErkennungLaeuft": "Rozpoznawanie NFC działa..",
    "RFIDWirdGescannt": "RFID jest skanowany..",
    "OK": "OK",
    "Fehlerbeschreibung": "Opis błędu",
    "Bild wirklich löschen?": "Naprawdę usunąć obraz?",
    "Frage": "Pytanie",
    "Bitte Kilometerstand angeben": "Prosimy o podanie przebiegu",
    "Bitte Ankunftskontrolle vollständig ausfüllen": "Prosimy o pełne wypełnienie formularza przybycia",
    "Bitte Ankunftskontrolle Anhänger vollständig ausfüllen": "Prosimy o pełne wypełnienie karty kontroli przybycia",
    "Bitte Unterschrift erfassen": "Wprowadź podpis",
    "Bitte Namen für die Unterschrift erfassen": "Wprowadź nazwy dla podpisu",
    "Bitte nehmen Sie sich genügend Zeit für die Ankunftskontrolle!": "Prosimy o zapewnienie wystarczającej ilości czasu na sprawdzenie przybycia!",
    "AnkunftskontrolleDauerFrage1": "Określony czas kontroli przybycia nie został dotrzymany. Mimo to zakończyć wycieczkę?",
    "Tour beenden?": "Zakończyć wycieczkę?",
    "AnkunftskontrolleDauerFrage2": "Określony czas kontroli przybycia nie był przestrzegany. Czy mimo to zakończyć wycieczkę? <br /><br />Wiadomość zostanie wysłana!",
    "Bitte Abfahrtskontrolle vollständig ausfüllen": "Prosimy o kompletne wypełnienie czeku wyjazdu",
    "AbfahrtskontrolleMeldung1": "Prosimy o pozostawienie wystarczającej ilości czasu na odprawę wyjazdową!",
    "AbfahrtskontrolleMeldung2": "Określony czas kontroli odlotu nie został dotrzymany. Mimo to rozpocząć wycieczkę?",
    "AbfahrtskontrolleMeldung3": "Określona godzina odprawy nie została dotrzymana. Czy mimo to rozpocząć wycieczkę? <br /><br />Wiadomość zostanie wysłana!",
    "KeineWiegungTourTrotzdemStarten": "Nie przeprowadzono ważenia. Mimo to rozpocząć trasę?",
    "Wiegung": "Ważenie",
    "WiegungErfolgreich": "Wymagane ważenie!",
    "Tour start?": "Początek wycieczki?",
    "Tour aktiv seit": "Trasa aktywna od",
    "Gestartet um": "Rozpoczęty o godz",
    "Gestartet gestern": "Zaczęło się wczoraj",
    "Gestartet": "Rozpoczęty",
    "Keine gültige Auftragsnummer": "Brak prawidłowego numeru zamówienia",
    "Heute offene Aufträge": "Otwarte zamówienia już dziś",
    "Heute erledigt Aufträge": "Zamówienia zrealizowane dzisiaj",
    "Morgen": "Jutro",
    "Keine Transportaufträge": "Brak zleceń transportowych",
    "1 Transportauftrag": "1 zlecenie transportowe",
    "aktuelle Aufträge": "aktualne zamówienia",
    "Statusmeldung428": "Komunikat o stanie nie jest możliwy. Ważenie już się rozpoczęło. Zakończ trasę i w razie potrzeby uruchom ją ponownie.",
    "Meldung429": "<p> Komunikat o stanie nie jest możliwy. Wycieczka jeszcze się nie rozpoczęła.</p><p>Rozpocznij wycieczkę teraz?</p>",
    "Meldung430": "Status niedozwolony. Zgłoś jeden z następujących statusów",
    "Meldung431": "Nie wszystkie elementy zostały zgłoszone jako kompletne. Czy paleta jest na pewno gotowa?",
    "Es muss mindestens ein Foto gemacht werden!": "Przynajmniej jedno zdjęcie musi zostać zrobione!",
    "Alle Aufträge": "Wszystkie zamówienia",
    "Kommissionierung": "Kompletacja zamówień",
    "Wiegungen": "Ważenie",
    "Gekoppelte Waagen": "Skale sprzężone",
    "Waage suchen...": "Wyszukiwanie wag...",
    "Keine Wiegedaten vorhanden": "Brak dostępnych danych ważenia",
    "Keine Waagen konfiguriert": "Brak skonfigurowanych wag",
    "Waage": "Wagi",
    "MAC Adresse": "Adres MAC",
    "Aktiv": "Aktywny",
    "Uhr synchronisieren": "Synchronizacja zegara",
    "Wiegen 1": "Kołyska 1",
    "Wiegen 2": "Cradle 2",
    "Tarieren": "Taring",
    "Keine neue Waage gefunden": "Nie znaleziono nowych wag",
    "TourAktivBeendenMeldung449": "Trasa jest nadal aktywna. Zakończyć trasę teraz?",
    "WirklichAbmelden": "Naprawdę się wyrejestrowałeś?",
    "Aktuelle Tour": "Aktualna trasa",
    "Tour beenden": "Koniec wycieczki",
    "Zeiterfassung": "Rejestrowanie czasu",
    "Zeiterfassung starten": "Rozpoczęcie nagrywania czasu",
    "Tour Ende": "Koniec wycieczki",
    "Art": "Rodzaj",
    "Arbeitszeit bearbeiten": "Edycja czasu pracy",
    "Wiegen": "Ważenie",
    "Tätigkeitsbericht": "Raport z działalności",
    "Personaldaten": "Dane osobowe",
    "Tätigkeit": "Aktywność",
    "Übernachtung": "Nocleg",
    "Beschreibung der Tätigkeit": "Opis działania",
    "Neuer Urlaubsantrag": "Nowy wniosek o urlop",
    "Abteilung": "Dział",
    "Urlaub": "Wakacje",
    "Angemeldete Beifahrer": "Zarejestrowani pasażerowie",
    "Beifahrer hinzufügen": "Dodaj współkierowcę",
    "Beifahrer NFC scannen": "Skanowanie pasażerów NFC",
    "BeifahrerListeNichtGeladen": "Nie można załadować listy pasażerów. Sprawdź połączenie internetowe.",
    "Beifahrer wurde gescannnt": "Pasażer został przeskanowany",
    "NfcKeinBeifahrerGefunden": "Nie znaleziono pasażera dla chipa RFID",
    "BeifahrerAlsFahrerMeldung473": "Jesteś już zarejestrowany jako kierowca na tę wycieczkę. Nie możesz zarejestrować się ponownie jako pasażer.",
    "BeifahrerBereitsAngemeldet": "Jesteś już zarejestrowany jako pasażer.",
    "MaxZweiBeifahrerMoeglich": "Można zarejestrować maksymalnie 2 pilotów",
    "BeifahrerNichtGefunden": "Nie znaleziono pasażera",
    "BeifahrerWurdeHinzugefuegt": "Pasażer został dodany",
    "Ein Anhänger kann nicht als Fahrzeug gescannt werden": "Przyczepa nie może być skanowana jako pojazd",
    "Ein LKW kann nicht als Anhänger gescannt werden": "Samochód ciężarowy nie może być skanowany jako przyczepa",
    "AngemeldetSeit": "Zarejestrowany od",
    "Hinzufügen": "Dodaj",
    "NFC scannen": "Skanowanie NFC",
    "Beifahrer abmelden": "Wyloguj współkierowcę",
    "Hallo": "Witam",
    "FuehrerscheinkontrolleText1": "kontrola prawa jazdy jest wymagana.",
    "FuehrerscheinkontrolleTextFoto": "Prosimy o zrobienie zdjęcia przedniej i tylnej strony prawa jazdy.",
    "Foto Vorderseite": "Zdjęcie z przodu",
    "Foto Rückseite": "Zdjęcie z tyłu",
    "FuehrerscheinkontrolleTextNFC": "Zeskanuj chip NFC na swoim prawie jazdy.",
    "FuahrerscheinkontrolleNfcFalsch": "Nieprawidłowy chip NFC",
    "Fahrerkarten": "Karty kierowcy",
    "Führerscheinkontrolle": "Kontrola prawa jazdy",
    "Später": "Później",
    "Führerschein erfolgreich gescannt": "Prawo jazdy zostało pomyślnie zeskanowane",
    "Bitte Ablaufdatum erfassen": "Wprowadź datę wygaśnięcia",
    "Führerscheinprüfung wirklich verschieben?": "Czy naprawdę mogę przełożyć egzamin na prawo jazdy?",
    "Abmelden?": "Wylogować się?",
    "Bitte NFC-Chip scannen": "Zeskanuj chip NFC",
    "Suchen": "Wyszukiwanie",
    "Archiv anzeigen": "Pokaż archiwum",
    "Übersicht": "Przegląd",
    "Bitte Entladeort auswählen": "Wybierz miejsce rozładunku",
    "Von": "Od",
    "Bis": "Do",
    "Zeitausgleich": "Wyrównanie czasu",
    "Urlaub 2": "Holiday 2",
    "Urlaub 3": "Holiday 3",
    "Urlaub 4": "Wakacje 4",
    "Unterschrift erforderlich": "Wymagany podpis",
    "Zum unterschreiben hier tippen": "Wpisz tutaj, aby podpisać",
    "Unterschrift": "Podpis",
    "Wird geladen...": "Ładowanie...",
    "Funktion im Browser nicht verfügbar": "Funkcja niedostępna w przeglądarce"
  }
};
