<div>
  <ion-searchbar [(ngModel)]="suchtext" (ionChange)="onSearchChanged()" (ionCancel)="onSearchChanged()" placeholder="{{ 'Suchen' | translate }}" *ngIf="searchbarSichtbar | async" #searchbar></ion-searchbar>

  <div *ngFor="let vo of sichtbarePositionen" class="auftragsposition section">

    <h1>{{ vo.auftragsposition.PosNr }}. Position
      <span class="" *ngIf="vo.auftragsposition.BhAktion">({{ vo.auftragsposition.BhAktion }})</span>
      <span class="neu" *ngIf="vo.auftragsposition.IstNeu">(neu)</span>
    </h1>

    <ion-button appClick (clicked)="onPositionLoeschen($event, vo)" class="del-button" fill="clear" *ngIf="vo.auftragsposition.IstNeu && !vo.auftragsposition.IstGesendet">
      <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>

    <div class="artikel-bezeichnung" appClick (clicked)="onArtikelAendern(vo)">
      <div class="preLine">{{ vo.auftragsposition.Bezeichnung }}</div>
      <div *ngIf="vo.auftragsposition.LPN" class="preLine">LPN: {{ vo.auftragsposition.LPN }}</div>
      <div *ngIf="vo.auftragsposition.GTIN" class="preLine">GTIN: {{ vo.auftragsposition.GTIN }}</div>
    </div>

    <!-- Menge änderbar -->
    <app-input-number *ngIf="vo.mengeVariante == 'aenderbar'"
                      titel="Menge ({{ vo.auftragsposition.Einheit }}) {{ getSollMengeText(vo) }}"
                      lines="none"
                      type="decimal"
                      cssClass="menge-aenderbar"
                      (valueChanged)="onMengeGeaendert(vo)"
                      [(ngModel)]="vo.auftragsposition.Menge"
                      [disabled]="istAuftragReadOnly"
                      [isInvalid]="vo.mengeUngueltig"
                      [min]="0"></app-input-number>


    <!-- Menge Standard oder Null -->
    <app-input-number *ngIf="vo.mengeVariante == 'standard-oder-null'"
                      titel="Menge ({{ vo.auftragsposition.Einheit }}) {{ getSollMengeText(vo) }}"
                      lines="none"
                      type="decimal"
                      cssClass="menge-standard-oder-null"
                      (valueChanged)="onMengeGeaendert(vo)"
                      [(ngModel)]="vo.auftragsposition.Menge"
                      [erlaubteWerte]="[0, vo.StandardMenge]"
                      [disabled]="istAuftragReadOnly"
                      [isInvalid]="vo.mengeUngueltig"
                      [min]="0"></app-input-number>

    <!-- Menge nicht änderbar-->
    <app-input-number *ngIf="vo.mengeVariante == 'nicht-aenderbar'"
                      titel="Menge ({{ vo.auftragsposition.Einheit }})"
                      lines="none"
                      type="decimal"
                      cssClass="menge-nicht-aenderbar"
                      (valueChanged)="onMengeGeaendert(vo)"
                      [(ngModel)]="vo.auftragsposition.Menge"
                      [disabled]="istAuftragReadOnly"
                      [isInvalid]="vo.mengeUngueltig"
                      [readOnly]="true"
                      [min]="0"></app-input-number>

    <!-- Gewicht -->
    <app-input-number *ngIf="vo.auftragsposition.GewichtInKgAktiv && (vo.auftragsposition.Menge != 0 || positionsEigenschaftenImmerAnzeigen)"
                      titel="Gewicht (KG)"
                      lines="none"
                      type="decimal"
                      (valueChanged)="onGewichtGeaendert(vo)"
                      [(ngModel)]="vo.auftragsposition.GewichtInKg"
                      [isInvalid]="false"
                      [disabled]="istAuftragReadOnly"
                      [isInvalid]="vo.gewichtUngueltig"
                      [required]="vo.auftragsposition.GewichtInKgPflicht"
                      [clearInput]="true"
                      [plusMinusButtons]="false"
                      [min]="0"></app-input-number>

    <ion-item *ngIf="vo.LaderaumAuswahlAktiv && (vo.auftragsposition.Menge > 0 || positionsEigenschaftenImmerAnzeigen)" lines="none" class="ion-no-padding mt-15 input-select-item">
      <ion-label>Laderaum</ion-label>

      <ion-select class="laderaum-select" slot="end" interface="popover" [okText]="'OK' | translate" [cancelText]="'Abbrechen' | translate" placeholder="Bitte auswählen" [(ngModel)]="vo.auftragsposition.Laderaum" (ionChange)="onLaderaumGeaendert(vo)" [disabled]="istAuftragReadOnly" [required]="true">
        <ion-select-option *ngFor="let p of laderaeume" [value]="p.key">{{ p.bezeichnung }}</ion-select-option>
      </ion-select>
    </ion-item>

    <ng-container *ngIf="(vo.auftragsposition.Menge > 0 || positionsEigenschaftenImmerAnzeigen)">
      <ng-template ngFor let-p [ngForOf]="vo.auftragsposition.Eigenschaften">

        <ng-container *ngIf="p.Typ == 'checkbox'">
          <app-input-checkbox
                              [titel]="p.Bezeichnung"
                              [(ngModel)]="p.Wert"
                              [disabled]="istAuftragReadOnly"></app-input-checkbox>
        </ng-container>

        <ng-container *ngIf="p.Typ == 'text'">
          <app-input-text
                          (lostFocus)="onEigenschaftLostFocus(vo, p)"
                          [titel]="p.Bezeichnung"
                          [(ngModel)]="p.Wert"
                          [pattern]="p.Regex"
                          [uppercase]="p.Uppercase"
                          [disabled]="istAuftragReadOnly"></app-input-text>
        </ng-container>

        <ng-container *ngIf="p.Typ == 'barcode'">
          <app-input-text
                          (lostFocus)="onEigenschaftLostFocus(vo, p)"
                          [titel]="p.Bezeichnung"
                          [(ngModel)]="p.Wert"
                          [pattern]="p.Regex"
                          [uppercase]="p.Uppercase"
                          [scanNfcButton]="false"
                          [scanBarcodeButton]="true"
                          [disabled]="istAuftragReadOnly">
          </app-input-text>
        </ng-container>

        <ng-container *ngIf="p.Typ == 'barcode+nfc'">
          <app-input-text
                          (lostFocus)="onEigenschaftLostFocus(vo, p)"
                          [titel]="p.Bezeichnung"
                          [(ngModel)]="p.Wert"
                          [pattern]="p.Regex"
                          [uppercase]="p.Uppercase"
                          [scanNfcButton]="true"
                          [scanBarcodeButton]="true"
                          [disabled]="istAuftragReadOnly">
          </app-input-text>
        </ng-container>

        <ng-container *ngIf="p.Typ == 'nfc'">
          <app-input-text
                          (lostFocus)="onEigenschaftLostFocus(vo, p)"
                          [titel]="p.Bezeichnung"
                          [(ngModel)]="p.Wert"
                          [pattern]="p.Regex"
                          [uppercase]="p.Uppercase"
                          [scanNfcButton]="true"
                          [scanBarcodeButton]="false"
                          [disabled]="istAuftragReadOnly">
          </app-input-text>
        </ng-container>

        <ng-container *ngIf="p.Typ == 'datum'">
          <app-input-datum
                           [titel]="p.Bezeichnung"
                           [(ngModel)]="p.Wert"
                           [disabled]="istAuftragReadOnly">
          </app-input-datum>
        </ng-container>

        <ng-container *ngIf="p.Typ == 'ganzzahl'">
          <app-input-number
                            [titel]="p.Bezeichnung"
                            lines="none"
                            type="ganzzahl"
                            (lostFocus)="onEigenschaftLostFocus(vo, p)"
                            [(ngModel)]="p.Wert"
                            [disabled]="istAuftragReadOnly"
                            [clearInput]="true"
                            [plusMinusButtons]="false"
                            [min]="0"></app-input-number>
        </ng-container>

        <ng-container *ngIf="p.Typ == 'dezimalzahl'">
          <app-input-number
                            [titel]="p.Bezeichnung"
                            lines="none"
                            type="dezimalzahl"
                            (lostFocus)="onEigenschaftLostFocus(vo, p)"
                            [(ngModel)]="p.Wert"
                            [disabled]="istAuftragReadOnly"
                            [clearInput]="true"
                            [plusMinusButtons]="false"
                            [min]="0"></app-input-number>
        </ng-container>

        <ng-container *ngIf="p.Typ == 'readonly'">
          <ion-item lines="none" class="ion-no-padding">
            <ion-label position="stacked" class="stacked-input-label">{{ p.Bezeichnung }}</ion-label>
            <ion-input type="text" [(ngModel)]="p.Wert" readonly class="input-readonly" enterkeyhint="done"></ion-input>
            <!-- (ionBlur)="onEigenschaftLostFocus(vo, p)" (keyup.enter)="app.closeKeyboard($event)"  -->
          </ion-item>
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-template ngFor let-b [ngForOf]="vo.behaelter">

      <ion-item-group class="mt-15">
        <ion-item-divider *ngIf="vo.behaelter && vo.behaelter.length > 1">
          <ion-label>{{ b.name }}</ion-label>
        </ion-item-divider>

        <ion-item lines="none" class="ion-no-padding">
          <ion-label position="stacked" class="stacked-input-label">{{ 'Behälter-Nr.' | translate }}</ion-label>

          <ion-input
                     *ngIf="behaelterNummerNurZiffern"
                     inputmode="numeric"
                     type="text"
                     min="0"
                     step="1"
                     pattern="\d+"
                     [class.invalid-value]="b.behaelterNrUngueltig"
                     [(ngModel)]="b.behaelterNr"
                     clearInput
                     [readonly]="isBehaelterNummerReadOnly"
                     [disabled]="istAuftragReadOnly"
                     (ionBlur)="onBehaelterNrLostFocus(vo, b)"
                     (ionChange)="onBehaelterNrChanged(vo, b)"
                     [required]="true"
                     (keyup.enter)="app.closeKeyboard($event)"
                     enterkeyhint="done"></ion-input>

          <ion-input
                     *ngIf="!behaelterNummerNurZiffern"
                     type="text"
                     [class.invalid-value]="b.behaelterNrUngueltig"
                     [(ngModel)]="b.behaelterNr"
                     clearInput
                     [readonly]="isBehaelterNummerReadOnly"
                     [disabled]="istAuftragReadOnly"
                     (ionBlur)="onBehaelterNrLostFocus(vo, b)"
                     (ionChange)="onBehaelterNrChanged(vo, b)"
                     [required]="true"
                     (keyup.enter)="app.closeKeyboard($event)"
                     enterkeyhint="done"></ion-input>

          <ion-button *ngIf="!istAuftragReadOnly && behaelterBarcodeButtonSichtbar" slot="end" class="barcode-button" appClick (clicked)="scanBehaelterBarcode(vo, b)" size="default" color="light">
            <ion-icon name="barcode-outline"></ion-icon>
          </ion-button>
          <ion-button *ngIf="!istAuftragReadOnly && behaelterNfcButtonSichtbar" slot="end" class="nfc-button" appClick (clicked)="scanBehaelterNfc($event, vo, b)" size="default" color="light">NFC</ion-button>
        </ion-item>

        <p *ngIf="b.manuelleNummer">{{ b.manuelleNummer }}</p>

        <ion-item *ngIf="b.abstellortAktiv" lines="none" class="ion-no-padding mt-15">
          <ion-label position="stacked" class="stacked-input-label">{{ 'Abstellort' | translate }}</ion-label>
          <ionic-selectable [(ngModel)]="b.abstellort" [items]="abstellortListe" itemValueField="Key" itemTextField="Name1" [canSearch]="true" (onChange)="abstellortChanged(b)" closeButtonText="Abbrechen" searchPlaceholder="{{ 'Suchen' | translate }}" searchFailText="Keine Adresse gefunden"
                            [required]="true">
          </ionic-selectable>
        </ion-item>

        <ion-item *ngIf="b.abstellortAktiv" lines="none" class="ion-no-padding mt-15">
          <ion-label position="stacked" class="stacked-input-label">{{ 'Zustand' | translate }}</ion-label>

          <ion-radio-group [(ngModel)]="b.zustand" class="fuellstand" [required]="true">
            <ion-item class="ion-no-padding" lines="none">
              <ion-label>Voll</ion-label>
              <ion-radio slot="start" value="voll"></ion-radio>
            </ion-item>

            <ion-item class="ion-no-padding" lines="none">
              <ion-label>Leer</ion-label>
              <ion-radio slot="start" value="leer"></ion-radio>
            </ion-item>

            <ion-item class="ion-no-padding" lines="none">
              <ion-label>Beschädigt</ion-label>
              <ion-radio slot="start" value="beschaedigt"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-item>
      </ion-item-group>
    </ng-template>

    <!-- <ng-container *ngIf="istAuftragReadOnly">
      <ng-template ngFor let-p [ngForOf]="vo.auftragsposition.Eigenschaften">
        <ion-item lines="none">
          <ion-label>{{ p.Bezeichnung }}</ion-label>
          <ion-input type="text" [value]="p.Wert" clearInput disabled></ion-input>
        </ion-item>
      </ng-template>
    </ng-container> -->

    <!-- Wiegungen -->
    <ion-list *ngIf="vo.auftragsposition.Wiegungen && vo.auftragsposition.Wiegungen.length">
      <ion-list-header>
        <ion-label>Wiegungen</ion-label>
      </ion-list-header>

      <ion-item *ngFor="let w of vo.auftragsposition.Wiegungen" class="no-padding-end">
        <ion-label>{{ w.Wiegenummer }}, Net: {{ w.Netto }} kg, W1: {{ w.Wiegung1 }} kg, W2: {{ w.Wiegung2 }} kg</ion-label>
        <ion-button slot="end" fill="clear" class="delete-item-button" appClick (clicked)="onWiegungEntfernen(vo, w)" color="danger">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-list>

    <div class="ion-paddingxxx" *ngIf="vo.wiegungZuordnenAnzeigen">
      <ion-button expand="block" size="default" color="light" appClick (clicked)="onWiegungenZuordnen(vo)">Wiegung zuordnen</ion-button>
    </div>

    <app-auftragsposition-wiegen *ngIf="vo.wiegenAktiv" [auftrag]="auftrag" [auftragsposition]="vo.auftragsposition" [auftragdetails]="auftragdetails" (changed)="onWiegungChanged(vo)"></app-auftragsposition-wiegen>

    <!-- Seriennummern -->
    <div *ngIf="vo.auftragsposition.SeriennummerErforderlich">
      <ion-list appClick (clicked)="onSeriennummernBearbeiten(vo)">
        <ion-list-header>
          <ion-label>Seriennummern</ion-label>
        </ion-list-header>

        <ion-item *ngFor="let e of vo.auftragsposition.Elemente" class="no-padding-end">
          <ion-label *ngIf="e.Nummer">{{ e.Nummer }}</ion-label>
          <ion-label *ngIf="!e.Nummer" class="seriennummer-fehlt">Fehlt</ion-label>
        </ion-item>
      </ion-list>

      <ion-button expand="block" size="default" color="light" appClick (clicked)="onSeriennummernBearbeiten(vo)">Seriennummern bearbeiten</ion-button>
    </div>

    <!-- Drucken für Wareneingang -->
    <div class="ion-paddingxxx" *ngIf="isPositionDruckenMoeglich">
      <ion-button expand="block" size="default" color="light" appClick (clicked)="onPositionDrucken(vo)">Drucken</ion-button>
    </div>
  </div>

  <div class="section buttons-section" *ngIf="artikelHinzufuegenButtonAnzeigen">
    <ion-button appClick (clicked)="onPositionHinzufuegen()" class="button-md button-border" expand="full" color="light">Artikel hinzufügen</ion-button>
  </div>
</div>

<ng-container *ngIf="isLeerePositionenAusblenden">
  <div class="section buttons-section" *ngIf="anzahlPositionAusgeblendet > 0">
    <ion-button appClick (clicked)="onAllePositionenAnzeigen()" class="button-md button-border" expand="full" fill="clear">
      <span *ngIf="anzahlPositionAusgeblendet === 1">1 weitere Position anzeigen</span>
      <span *ngIf="anzahlPositionAusgeblendet > 1">{{ anzahlPositionAusgeblendet }} weitere Positionen anzeigen</span>
    </ion-button>
  </div>

  <!-- <div class="section buttons-section" *ngIf="anzahlLeerePositionenWerdenAngezeigt > 0">
    <ion-button appClick (clicked)="onLeerePositionenAusblenden()" class="button-md button-border" expand="full" fill="clear">
      <span *ngIf="anzahlLeerePositionenWerdenAngezeigt === 1">1 Position ausblenden</span>
      <span *ngIf="anzahlLeerePositionenWerdenAngezeigt > 1">{{ anzahlLeerePositionenWerdenAngezeigt }} Positionen ausblenden</span>
    </ion-button>
  </div> -->
</ng-container>

<ion-select #wiegungenSelect [hidden]="true" (ionChange)="onWiegungSelected($event)" [multiple]="true" [okText]="'OK' | translate" [cancelText]="'Abbrechen' | translate" *ngIf="wiegungAuswahlAuftragsposition">
  <ion-label>Wiegungen</ion-label>
  <ion-select-option *ngFor="let w of wiegedatenListe" [value]="w">{{ w.Wiegenummer }}, Net: {{ w.Netto }} kg</ion-select-option>
</ion-select>