import { ErrorHandler } from '@angular/core';
import { Logger } from './app-error-logger';
import { UiHelper } from './ui-helper';

declare const sourceMappedStackTrace: any;

export class AppErrorHandler implements ErrorHandler {

    static instance: AppErrorHandler;

    static log = new Logger('AppErrorHandler');

    errorLogCount = 0;

    static handleUncaughtError(msg, url, lineNo, columnNo, error): boolean {
        const errorData = {
            msg,
            url,
            lineNo,
            columnNo,
            error
        };

        AppErrorHandler.logError(errorData);

        return false;
    }

    static handleAjaxError(error: any) {
        AppErrorHandler.log.warn('handleAjaxError: ' + error.message, error);

        AppErrorHandler.logError(error);

        // if (error.status === 403) {
        //     // Forbidden
        //     // Wahrscheinlich ist eine Session abgelaufen oder ein Token ist nicht mehr gültig.
        //     // Es müsste nun entweder ein erneuter Login versucht werden, oder eine entsprechende
        //     // Fehlermeldung angezeigt werden.
        //     if (accountService.isLoggedIn()) {
        //         appService.confirm({
        //             text: 'Es ist ein Fehler beim Zugriff aud den Dienst aufgetreten. Neu anmelden?',
        //             type: ConfirmType.WARNING
        //         }).then(confirmResult => {
        //             if (confirmResult) {
        //                 accountService.logout();
        //                 appService.navigate(['/account/login']);
        //             }
        //         });

        //         return Promise.reject(error.message || error);
        //     }
        // }

        // TODO: Übersetzen oder auch komplett ausbauen
        let msg = '';

        if (error._body) {
            msg = error._body;
        }

        if (!msg) {
            msg = error.message;
        }

        if (!msg || typeof (msg) === 'undefined') {
            // TODO: Translate
            msg = 'Es ist ein unbekannter Fehler beim Aufruf des Dienstes aufgetreten. Bitte prüfen Sie Ihre Eingabe oder versuchen Sie den Aufruf zu einem späteren Zeitpunkt erneut.';
        }

        const url = error.url;
        if (url) {
            msg += `\n\nURL:${url} (Status: ${error.status})`;
        }

        if (typeof (msg) === "string") {
            // UiHelper.showError(msg);
        } else {
            console.log('cannot show error. Not of type string', msg);
        }

        // return Promise.reject(msg);
    }

    /**
     * Protokolliert den Fehler auf Server-Seite
     */
    static logError(error: any) {
        // TODO
    }

    static promiseRejected(reason: any) {
        AppErrorHandler.logError(reason);
    }

    constructor() {
        AppErrorHandler.instance = this;

        // Nicht behandelte Fehler global fangen
        window.onerror = AppErrorHandler.handleUncaughtError;
    }

    public handleError(error: Error) {
        AppErrorHandler.logError(error);
        return false;
    }
}
