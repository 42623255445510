<ion-item [lines]="lines">
  <ion-label class="ion-text-wrap">{{ titel | translate }}</ion-label>

  <div slot="end" class="input-outer">

    <ion-input
               [inputmode]="inputmode"
               type="text"
               [class.input-upper-case]="uppercase"
               [class.invalid-value]="isInvalid"
               [class.input-readonly]="readOnly"
               [autocapitalize]="autocapitalize"
               [disabled]="disabled"
               [required]="required"
               [readonly]="readOnly"
               [(ngModel)]="myValue"
               [pattern]="pattern"
               (ionBlur)="onBlur($event)"
               (keyup.enter)="onEnter($event)"
               [enterkeyhint]="enterkeyhint"
               #input></ion-input>

    <div class="buttons" *ngIf="!isExtraButtonZeile">
      <ion-button
                  *ngIf="scanBarcodeButton"
                  [disabled]="disabled"
                  class="barcode-button inline-plusminus-button"
                  appClick (clicked)="scanBarcode()"
                  size="default"
                  color="light"
                  slot="end">
        <ion-icon name="barcode-outline"></ion-icon>
      </ion-button>

      <ion-button *ngIf="scanNfcButton"
                  [disabled]="disabled"
                  class="nfc-button inline-plusminus-button"
                  appClick (clicked)="scanNfc()"
                  size="default"
                  color="light"
                  slot="end">NFC</ion-button>

      <ion-button *ngIf="isClearButtonVisible"
                  class="clear-button inline-plusminus-button"
                  [disabled]="disabled"
                  appClick (clicked)="onClearValue()"
                  size="default"
                  color="light"
                  slot="end">
        <ion-icon name="create-outline" mode="ios" class="clear-icon"></ion-icon>
      </ion-button>

    </div>
  </div>
</ion-item>

<div class="buttons buttons-extra" *ngIf="isExtraButtonZeile">
  <ion-button
              *ngIf="scanBarcodeButton"
              [disabled]="disabled"
              class="barcode-button inline-plusminus-button"
              appClick (clicked)="scanBarcode()"
              size="default"
              color="light"
              slot="end">
    <ion-icon name="barcode-outline"></ion-icon>
  </ion-button>

  <ion-button *ngIf="scanNfcButton"
              [disabled]="disabled"
              class="nfc-button inline-plusminus-button"
              appClick (clicked)="scanNfc()"
              size="default"
              color="light"
              slot="end">NFC</ion-button>
</div>