<ion-header>
  <ion-toolbar class="uvv-toolbar" #toolbar>
    <ion-buttons slot="start">
      <ion-button appClick (clicked)="onClose()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title><span id="page-title-span">{{ 'UVV-Prüfung' | translate }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="section-content">
  <div class="section" *ngIf="geladen">
    <h1 class="bh-nummer">{{ gegenstand.manuelleNummer }} </h1>

    <div class="text bh-bezeichnung">
      {{ gegenstand.bezeichnung }}
    </div>

    <form [formGroup]="form">
      <!-- <ion-item lines="none">
        <ion-label position="stacked">{{ 'Nummer' | translate }}</ion-label>
        <ion-input class="display-input" type="text" formControlName="manuelleNummer" readonly></ion-input>
      </ion-item> -->
      <!-- <ion-item lines="none">
        <ion-label position="stacked">{{ 'Bezeichnung' | translate }}</ion-label>
        <ion-input class="display-input" type="text" formControlName="bezeichnung" readonly></ion-input>
      </ion-item> -->

      <ion-item class="display-item" lines="none">
        <ion-label position="stacked">{{ 'Prüfung nach Katalog' | translate }}</ion-label>
        <ion-input class="display-input" type="text" formControlName="katalogName" readonly></ion-input>
      </ion-item>

      <ion-item class="display-item" lines="none">
        <ion-label position="stacked">{{ 'Prüfer' | translate }}</ion-label>
        <ion-input class="display-input" type="text" formControlName="fahrerName" readonly></ion-input>
      </ion-item>

      <ion-item class="display-item" lines="none">
        <ion-label position="stacked">{{ 'Nächste Prüfung' | translate }}</ion-label>
        <ion-input class="display-input" type="text" formControlName="datumNaechstePruefung" readonly></ion-input>
      </ion-item>

      <ion-item class="display-item" lines="none">
        <ion-label position="stacked">{{ 'Datum' | translate }}</ion-label>
        <ion-input class="display-input" type="text" formControlName="datum" readonly></ion-input>
      </ion-item>

      <ion-item class="display-item" lines="none" *ngIf="(app.behaelterMitNfc | async)">
        <ion-label position="stacked">{{ 'RFID' | translate }}</ion-label>
        <ion-input class="display-input" type="text" formControlName="identNummer" readonly *ngIf="gegenstand && gegenstand.identNummer"></ion-input>
        <ion-input class="display-input red" type="text" readonly *ngIf="gegenstand && !gegenstand.identNummer">Kein RFID-Chip zugeordnet</ion-input>
      </ion-item>

      <div *ngIf="(app.behaelterMitNfc | async) && gegenstand && !gegenstand.identNummer">
        <ion-button expand="block" size="default" color="light" appClick (clicked)="onRfidScannen()">RFID-Chip scannen</ion-button>
      </div>
    </form>

    <p class="katalog-text" *ngIf="geladen && katalog && katalog.text">{{ katalog.text }}</p>
  </div>

  <div *ngIf="checklistEintraege && checklistEintraege.length && uvvBerechtigt">
    <div class="section ion-activatable">
      <h1>{{ 'Prüfungen' | translate }}</h1>
      <div>
        <table class="app-table">
          <tbody>
            <ng-container *ngFor="let item of checklistEintraege">
              <tr [class.no-bottom-border]="item.nok" class="{{ item.cssClass }}">
                <td>{{ item.eintrag.bezeichnung }}</td>
                <td class="buttons">
                  <ion-button slot="end" [color]="item.okButtonColor" appClick (clicked)="onChecklistItemChanged($event, item, true)" class="toggle-button">
                    <ion-icon name="checkmark"></ion-icon>
                  </ion-button>
                  <ion-button slot="end" [color]="item.nokButtonColor" appClick (clicked)="onChecklistItemChanged($event, item, false)" class="toggle-button">
                    <ion-icon name="close"></ion-icon>
                  </ion-button>
                </td>
              </tr>

              <tr *ngIf="item.nok">
                <td [attr.colspan]="2">
                  <ion-textarea [(ngModel)]="item.anmerkungen" [placeholder]="'Fehlerbeschreibung' | translate" autoGrow="true" color="danger"></ion-textarea>

                  <div class="fotos-container" *ngIf="item.bilder && item.bilder.length">
                    <ion-img *ngFor="let p of item.bilder" class="bild" src="{{ p.src }}" appClick (clicked)="onBildClicked(item, p)"></ion-img>

                  </div>
                  <ion-button color="light" appClick (clicked)="onFotoAufnehmen($event, item)" class="icon-button-with-text button-border">
                    <ion-icon name="camera"></ion-icon> {{ 'Foto aufnehmen' | translate }}
                  </ion-button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="section" *ngIf="uvvBerechtigt">
    <ng-container *ngIf="standortsichtbar || zustandSichtbar">
      <h1>{{ 'Sonstiges' | translate }}</h1>
      <form [formGroup]="form" *ngIf="geladen">
        <ion-item lines="none" *ngIf="standortsichtbar">
          <ion-label position="stacked" class="stacked-input-label">{{ 'Standort' | translate }}</ion-label>
          <ionic-selectable placeholder="Bitte wählen..." formControlName="standort" [items]="standortListe" itemValueField="Key" itemTextField="Name1" [canSearch]="true" (onChange)="standortChanged($event)" closeButtonText="Abbrechen" searchPlaceholder="{{ 'Suchen' | translate }}"
                            searchFailText="Keine Adresse gefunden" [required]="false">
          </ionic-selectable>
        </ion-item>

        <ion-item lines="none" *ngIf="zustandSichtbar">
          <ion-label position="stacked" class="stacked-input-label">{{ 'Zustand' | translate }}</ion-label>

          <ion-radio-group formControlName="zustand" class="fuellstand">
            <ion-item class="ion-no-padding" lines="none">
              <ion-label>Voll</ion-label>
              <ion-radio slot="start" value="voll"></ion-radio>
            </ion-item>

            <ion-item class="ion-no-padding" lines="none">
              <ion-label>Leer</ion-label>
              <ion-radio slot="start" value="leer"></ion-radio>
            </ion-item>

            <ion-item class="ion-no-padding" lines="none">
              <ion-label>Beschädigt</ion-label>
              <ion-radio slot="start" value="beschaedigt"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-item>

        <ion-item lines="none" *ngIf="auswahlReparierenVerschrottenSichtbar">
          <ion-label position="stacked" class="stacked-input-label">{{ 'Aktion' | translate }}</ion-label>

          <ion-radio-group formControlName="aktion" class="fuellstand">
            <ion-item class="ion-no-padding" lines="none">
              <ion-label>Reparieren</ion-label>
              <ion-radio slot="start" value="Reparieren"></ion-radio>
            </ion-item>

            <ion-item class="ion-no-padding" lines="none">
              <ion-label>Verschrotten</ion-label>
              <ion-radio slot="start" value="Verschrotten"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-item>
      </form>
    </ng-container>

    <ion-button *ngIf="geladen" expand="block" size="large" [color]="startenButtonColor" appClick (clicked)="onAbschliessen($event)">Fertig</ion-button>
    <ion-button *ngIf="geladen && rfidTauschErlaubt" expand="block" size="default" color="light" appClick (clicked)="onRfidTausch()">RFID Tausch</ion-button>
  </div>
</ion-content>