
import * as moment from 'moment';
import { TourStatusEx } from '../model/model';
import { TourStatus } from '../model/swagger-model';
import { UiHelper } from './ui-helper';
import { Logger } from './app-error-logger';
import { App } from './app';
import { AppStorage } from './app-storage';

const log = new Logger('TourHelper');

export class TourHelper {
    static async speichereAktuelleTour(): Promise<void> {
        const aktuelleTour = App.current.aktuelleTour.getValue();

        log.debug('speichereAktuelleTour: ' + aktuelleTour?.Key, aktuelleTour);

        await AppStorage.current.set('aktuelleTour', aktuelleTour, true, true);
    }

    static getPauseGesamtdauerSekunden(tourStatus: TourStatusEx): number {

        // if (!tourStatus.Stoerzeiten) {
        //     tourStatus.Stoerzeiten = [];
        // }

        // const aktuellePause = TourHelper.getAktuellePauseStoerzeit(tourStatus);

        let pauseDauerSekunden = 0;

        // for (const stoerzeit of tourStatus.Stoerzeiten) {
        //     if (stoerzeit.Typ === 'Pause') {

        //         if (stoerzeit === aktuellePause) {
        //             const startMoment = moment(stoerzeit.ErfassungsDatum);
        //             const now = moment();
        //             const sekunden = now.diff(startMoment, 'seconds');

        //             pauseDauerSekunden += sekunden;
        //         } else {
        //             pauseDauerSekunden += stoerzeit.Dauer * 60;
        //         }
        //     }
        // }

        return pauseDauerSekunden;
    }

    static getAktuellePauseStoerzeit(tourStatus: TourStatusEx) {
        UiHelper.showErrorLight('TODO 7');

        // if (!tourStatus.Stoerzeiten) {
        //     tourStatus.Stoerzeiten = [];
        // }

        // for (const stoerzeit of tourStatus.Stoerzeiten) {
        //     if (stoerzeit.Key === 'AKTIVEPAUSE') {
        //         return stoerzeit;
        //     }
        // }

        return null;
    }

    static getStartMoment(tourStatus: TourStatus): moment.Moment {
        let startzeit: moment.Moment;

        if (tourStatus.StartDatum) {

        } else if (tourStatus.Datum && tourStatus.Uhrzeit) {
            startzeit = moment(tourStatus.Datum + ' ' + tourStatus.Uhrzeit + ':00', 'DD.MM.YYYY HH:mm:ss', 'de');
        } else {
            startzeit = moment(tourStatus.Statuszeit);
        }

        return startzeit;
    }
}