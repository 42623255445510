import { Md5 } from 'ts-md5/dist/md5';

import * as CryptoJS from 'crypto-js';
import * as pako from 'pako';

// const pako = require('pako');

/**
 * see: https://stackoverflow.com/questions/19094547/aes-encryption-in-c-sharp-and-decryption-in-cryptojs
 */
export class EncryptionHelper {
    /**
     * Der Schlüssel zum ent- und verschlüsseln
     */
    private static key: string = null;

    private static keyHash: string = null;

    private static salt = "ZbQev28Q6LBLwKy";

    private static fixedSalt = "HaOLorJ3XH3brRtPQ26R4qMsIQlyd3z3dlyr2Dtzbkq3mNrQUOSlfvKD";

    /**
     * Berechnet den Hash-Wert eines Passworts.
     * Implementierung gleich wie im Server. Darf nicht einseitig geändert werden.
     */
    static hashPasswort(passwort: string, salt = '') {
        if (!passwort) {
            passwort = '';
        }

        const hashIterations = 100;

        const text = passwort + EncryptionHelper.fixedSalt + salt;

        let hash: any = text;

        for (let i = 0; i < hashIterations; i++) {
            hash = new CryptoJS.SHA256(hash);
        }

        return hash.toString(CryptoJS.enc.Base64);
    }

    static md5(str: string) {
        const md5 = new Md5();

        if (!str) {
            str = '';
        }

        return md5.appendStr(str).end().toString();
    }

    static setKey(key: string) {
        const md5 = new Md5();

        if (!key) {
            key = '';
        }

        EncryptionHelper.key = key;
        EncryptionHelper.keyHash = md5.appendStr(key + EncryptionHelper.salt).end().toString().substr(0, 4);
    }

    static decryptObject(daten: string): any {
        const json = EncryptionHelper.decrypt(daten);

        if (json) {
            return JSON.parse(json);
        } else {
            return null;
        }
    }

    static decrypt(daten: string): string {
        if (!daten.startsWith('enc:')) {
            throw new Error('Verschlüsselte Daten müssen mit der Zeichenfolge enc: beginnen.');
        }

        // Die Daten müssen in folgender Form ausgebaut sein: enc:1234:xxxxxxxx
        // wobei 1234 die ersten 4 Zeichen eines MD5-Hashes des verwendeten Passwortes sind.
        const passwordHash = daten.substr(4, 4);

        // Prüfe ob des Hash-Wert übereinstimmt
        if (passwordHash !== EncryptionHelper.keyHash) {
            throw new Error('Die Daten wurden mit einem anderen Schlüssel verschlüsselt. Soll = ' + EncryptionHelper.keyHash + ', Ist = ' + passwordHash);
        }

        const base64Daten = daten.substr(9);

        // Decrypt AES-128

        // Creating the Vector Key
        const iv = CryptoJS.enc.Hex.parse('af25cba273f752ddd31ac9f053e68b22');

        // Encoding the Password in from UTF8 to byte array
        const pass = CryptoJS.enc.Utf8.parse(EncryptionHelper.key);

        // Encoding the Salt in from UTF8 to byte array
        const salt = CryptoJS.enc.Utf8.parse("7p4dmNrq8hpPwXWA2Pp");

        // Creating the key in PBKDF2 format to be used during the decryption
        const key128Bits100Iterations = CryptoJS.PBKDF2(pass.toString(CryptoJS.enc.Utf8), salt, { keySize: 128 / 32, iterations: 100 });

        // Enclosing the test to be decrypted in a CipherParams object as supported by the CryptoJS libarary
        const cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(base64Daten)
        });

        // Decrypting the string contained in cipherParams using the PBKDF2 key
        const decrypted = CryptoJS.AES.decrypt(cipherParams, key128Bits100Iterations, { mode: CryptoJS.mode.CBC, iv, padding: CryptoJS.pad.Pkcs7 });
        const decryptedHexString = decrypted.toString();

        // console.log('decryptedHexString', decryptedHexString);

        const byteArray = EncryptionHelper.hexToBytes(decryptedHexString);

        // console.log('byteArray', byteArray);

        const unzipped = pako.inflate(byteArray);

        // console.log('unzipped', unzipped);

        const text = new TextDecoder().decode(unzipped);

        return text;
    }

    static hexToBytes(hex: string): any[] {
        const bytes = [];

        for (let c = 0; c < hex.length; c += 2) {
            bytes.push(parseInt(hex.substr(c, 2), 16));
        }

        return bytes;
    }
}
