
import * as moment from 'moment';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { Auftrag, Personal, Fahrzeug, Auftragsposition, ReportElement } from 'src/app/api/model/swagger-model';
import { Utils } from 'src/app/api/helper/utils';
import { FormatHelper } from 'src/app/api/helper/format-helper';
import { AuftragEx } from 'src/app/api/model/model';

const log = new Logger('DruckerHelper');

export class DruckerHelper {
    static erstellePlatzhalter(auftrag: AuftragEx, fahrer: Personal, fahrzeug: Fahrzeug, position: Auftragsposition) {
        const now = moment();

        const platzhalter: any = {
            'AktuellesDatum': now.format('DD.MM.YYYY'),
            'AktuelleUhrzeit': now.format('HH:mm'),
            'Key': auftrag.Key,
            'Statuszeit': auftrag.Statuszeit,
            'Auftragsstatus': auftrag.Auftragsstatus,
            'Statusmeldung': auftrag.Statusmeldung,
            'StatusmeldungKey': auftrag.StatusmeldungKey,
            'Auftragsnummer': auftrag.Auftragsnummer,
            'AusdruckNummer': auftrag.AusdruckNummer,
            'Reihenfolge': auftrag.Reihenfolge,
            'Geraet': auftrag.Geraet,
            'Personalnummer': auftrag.Personalnummer,
            'Barcode': auftrag.Barcode,
            'Auftragstyp': auftrag.Auftragstyp,
            'Mandant': auftrag.Mandant,
            'AAbholdatum': auftrag.AAbholdatum,
            'AAbholzeitVon': auftrag.AAbholzeitVon,
            'AAbholzeitBis': auftrag.AAbholzeitBis,
            'AErfasser': auftrag.AErfasser,
            'Abholadresse': auftrag.Abholadresse,
            'AAbladedatum': auftrag.AAbladedatum,
            'AAbladezeitVon': auftrag.AAbladezeitVon,
            'AAbladezeitBis': auftrag.AAbladezeitBis,
            'AHaupflaufContNr': auftrag.AHaupflaufContNr,
            'Quittungsname': auftrag.Quittungsname,
            'WorkflowName': auftrag.WorkflowName,
            'Anmerkungen': auftrag.Anmerkungen,
            'FahrzeugKennung': auftrag.FahrzeugKennung,
            'FahrzeugKennung2': auftrag.FahrzeugKennung2,
            'FahrzeugKennung3': auftrag.FahrzeugKennung3,
            'Info1': auftrag.Info1,
            'Info2': auftrag.Info2,
            'Info3': auftrag.Info3,
            'DetailInfo1': auftrag.DetailInfo1,
            'DetailInfo2': auftrag.DetailInfo2,
            'DetailInfo3': auftrag.DetailInfo3,
            'Kundennummer': auftrag.Erzeugeradresse?.AdressNummer,
            'BezahlungBetrag': auftrag.BezahlungBetrag,
            'BezahlungArt': auftrag.BezahlungArt,
            'IstBezahlt': auftrag.IstBezahlt,
            'SummeBrutto': FormatHelper.formatPreis(auftrag.SummeBrutto),
            'SummeNetto': FormatHelper.formatPreis(auftrag.SummeNetto),
            'SummeMwst': FormatHelper.formatPreis(auftrag.SummeMwst),
            'Abholadresse.AdressNummer': auftrag.Abholadresse?.AdressNummer,
            'Abholadresse.Name1': auftrag.Abholadresse?.Name1,
            'Abholadresse.Name2': auftrag.Abholadresse?.Name2,
            'Abholadresse.Name3': auftrag.Abholadresse?.Name3,
            'Abholadresse.NameVollstaendig': [auftrag.Abholadresse?.Name1, auftrag.Abholadresse?.Name2, auftrag.Abholadresse?.Name3].filter(p => p).join('\n'),
            'Abholadresse.Strasse': auftrag.Abholadresse?.Strasse,
            'Abholadresse.PLZ': auftrag.Abholadresse?.PLZ,
            'Abholadresse.Ort': auftrag.Abholadresse?.Ort,
            'Abholadresse.LKZ': auftrag.Abholadresse?.LKZ,
            'Abholadresse.Telefon': auftrag.Abholadresse?.Telefon,
            'Abholadresse.LieferscheinEmail': auftrag.Abholadresse?.LieferscheinEmail,
            'Abholadresse.Ansprechpartner': auftrag.Abholadresse?.Ansprechpartner,
            'Abholadresse.GeoX': auftrag.Abholadresse?.GeoX,
            'Abholadresse.GeoY': auftrag.Abholadresse?.GeoY,
            'Abholadresse.PortalPwd': auftrag.Abholadresse?.PortalPwd,
            'Erzeugeradresse.AdressNummer': auftrag.Erzeugeradresse?.AdressNummer,
            'Erzeugeradresse.Name1': auftrag.Erzeugeradresse?.Name1,
            'Erzeugeradresse.Name2': auftrag.Erzeugeradresse?.Name2,
            'Erzeugeradresse.Name3': auftrag.Erzeugeradresse?.Name3,
            'Erzeugeradresse.NameVollstaendig': [auftrag.Erzeugeradresse?.Name1, auftrag.Erzeugeradresse?.Name2, auftrag.Erzeugeradresse?.Name3].filter(p => p).join('\n'),
            'Erzeugeradresse.Strasse': auftrag.Erzeugeradresse?.Strasse,
            'Erzeugeradresse.PLZ': auftrag.Erzeugeradresse?.PLZ,
            'Erzeugeradresse.Ort': auftrag.Erzeugeradresse?.Ort,
            'Erzeugeradresse.LKZ': auftrag.Erzeugeradresse?.LKZ,
            'Erzeugeradresse.Telefon': auftrag.Erzeugeradresse?.Telefon,
            'Erzeugeradresse.Ansprechpartner': auftrag.Erzeugeradresse?.Ansprechpartner,
            'Erzeugeradresse.GeoX': auftrag.Erzeugeradresse?.GeoX,
            'Erzeugeradresse.GeoY': auftrag.Erzeugeradresse?.GeoY,
            'Erzeugeradresse.PortalPwd': auftrag.Erzeugeradresse?.PortalPwd,
            'Verwerteradresse.AdressNummer': auftrag.Verwerteradresse?.AdressNummer,
            'Verwerteradresse.Name1': auftrag.Verwerteradresse?.Name1,
            'Verwerteradresse.Name2': auftrag.Verwerteradresse?.Name2,
            'Verwerteradresse.Name3': auftrag.Verwerteradresse?.Name3,
            'Verwerteradresse.NameVollstaendig': [auftrag.Verwerteradresse?.Name1, auftrag.Verwerteradresse?.Name2, auftrag.Verwerteradresse?.Name3].filter(p => p).join('\n'),
            'Verwerteradresse.Strasse': auftrag.Verwerteradresse?.Strasse,
            'Verwerteradresse.PLZ': auftrag.Verwerteradresse?.PLZ,
            'Verwerteradresse.Ort': auftrag.Verwerteradresse?.Ort,
            'Verwerteradresse.LKZ': auftrag.Verwerteradresse?.LKZ,
            'Verwerteradresse.Telefon': auftrag.Verwerteradresse?.Telefon,
            'Verwerteradresse.Ansprechpartner': auftrag.Verwerteradresse?.Ansprechpartner,
            'Verwerteradresse.GeoX': auftrag.Verwerteradresse?.GeoX,
            'Verwerteradresse.GeoY': auftrag.Verwerteradresse?.GeoY,
            'Fahrzeugkennzeichen': fahrzeug?.kennzeichen,
            'Fahrer.Vorname': fahrer?.vorname,
            'Fahrer.Nachname': fahrer?.nachname,
            'Fahrer.Personalnummer': fahrer?.personalnummer,
        };

        if (auftrag.Unterschriften) {
            platzhalter['UnterschriftERZ.Name'] = '';
            platzhalter['UnterschriftERZ.Datum'] = '';
            platzhalter['UnterschriftBEF.Name'] = '';
            platzhalter['UnterschriftBEF.Datum'] = '';
            platzhalter['UnterschriftENT.Name'] = '';
            platzhalter['UnterschriftENT.Datum'] = '';

            for (const u of auftrag.Unterschriften) {
                platzhalter['Unterschrift' + u.Typ + '.Name'] = u.Name;
                platzhalter['Unterschrift' + u.Typ + '.Datum'] = u.Datum;
            }
        }

        if (auftrag.Zusatzdaten) {
            for (const key in auftrag.Zusatzdaten) {
                if (Object.prototype.hasOwnProperty.call(auftrag.Zusatzdaten, key)) {
                    platzhalter['Zusatzdaten.' + key] = auftrag.Zusatzdaten[key];
                }
            }
        }

        platzhalter['MwstSatz1'] = '';
        platzhalter['MwstSatz2'] = '';
        platzhalter['MwstSatz3'] = '';
        platzhalter['MwstNettoBetrag1'] = '';
        platzhalter['MwstNettoBetrag2'] = '';
        platzhalter['MwstNettoBetrag3'] = '';
        platzhalter['MwstBetrag1'] = '';
        platzhalter['MwstBetrag2'] = '';
        platzhalter['MwstBetrag3'] = '';
        platzhalter['MwstVorhanden1'] = false;
        platzhalter['MwstVorhanden2'] = false;
        platzhalter['MwstVorhanden3'] = false;

        if (auftrag.MwstBetraege) {
            for (let i = 0; i < auftrag.MwstBetraege.length; i++) {
                const eintrag = auftrag.MwstBetraege[i];
                const nummer = i + 1;

                platzhalter['MwstVorhanden' + nummer] = true;
                platzhalter['MwstSatz' + nummer] = eintrag.MwstSatz;
                platzhalter['MwstNettoBetrag' + nummer] = FormatHelper.formatPreis(eintrag.NettoBetrag);
                platzhalter['MwstBetrag' + nummer] = FormatHelper.formatPreis(eintrag.MwstBetrag);
            }
        }

        if (position) {
            platzhalter.PosNr = position.PosNr;
            platzhalter.ArtikelKey = position.ArtikelKey;
            platzhalter.Bezeichnung = position.Bezeichnung;
            platzhalter.Bezeichnung2 = position.Bezeichnung2;
            platzhalter.Menge = position.Menge;
            platzhalter.Einheit = position.Einheit;
            platzhalter.EinzelVK = FormatHelper.formatEP(position.EinzelVK);
            platzhalter.NettoBetrag = FormatHelper.formatPreis(position.NettoBetrag);
            platzhalter.MwstSatz = position.MwstSatz;
            platzhalter.MwstBetrag = FormatHelper.formatPreis(position.MwstBetrag);
            platzhalter.GewichtInKg = position.GewichtInKg;
            platzhalter.BhAktion = position.BhAktion;
            platzhalter.Anmerkungen = position.Anmerkungen;
            platzhalter.GTIN = position.GTIN;
            platzhalter.CHARGE = '';
            platzhalter.MHD = '';
            platzhalter.MHDGS = '';
            platzhalter.GTINX = '00000000000000';
            platzhalter.MHDGS = '000000';

            if (position.GTIN) {
                platzhalter.GTINX = position.GTIN.toString().padStart(14, '0');
            }

            if (position.Eigenschaften) {
                for (const eigenschaft of position.Eigenschaften) {
                    try {
                        platzhalter[eigenschaft.Bezeichnung] = eigenschaft.Wert;
                        platzhalter[eigenschaft.Key] = eigenschaft.Wert;
                        platzhalter[eigenschaft.Bezeichnung + "MitText"] = eigenschaft.Bezeichnung + ": " + eigenschaft.Wert;

                        if (eigenschaft.Key === 'MHD' && eigenschaft.Wert && eigenschaft.Wert) {
                            const datum = Utils.parseDatum(eigenschaft.Wert);

                            if (datum) {
                                platzhalter['MHDGS'] = datum.format('YYMMDD');
                            }
                        }
                    } catch (err) {
                        // ignore
                    }
                }
            }

            if (position.Behaelter) {
                // platzhalter.Behaelter = be;
            }
        }

        return platzhalter;
    }

    static isDruckbedingungOk(e: ReportElement, auftrag: Auftrag, fahrer: Personal, fahrzeug: Fahrzeug, platzhalter: any, position: Auftragsposition = null): boolean {
        if (e.DruckBedingung) {
            try {
                // tslint:disable:only-arrow-functions
                // tslint:disable:no-shadowed-variable
                // tslint:disable:no-eval
                const Auftrag = auftrag;
                const Fahrer = fahrer;
                const Fahrzeug = fahrzeug;
                const Position = position;
                const X = platzhalter;

                return eval(e.DruckBedingung);
            } catch (err) {
                log.error(`Fehlerhafte Druckbedingung '${e.DruckBedingung}': ${Utils.getErrorMessage(err)}`, err);
                return false;
            }
        }

        return true;
    }
}