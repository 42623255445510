import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalController, NavController } from '@ionic/angular';
import { AuftragService } from 'src/app/api/auftrag.service';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { App } from 'src/app/api/helper/app';
import { WorkflowStatus, GeraetDto } from 'src/app/api/model/swagger-model';
import { AuftragEx } from 'src/app/api/model/model';
import { RemoteService } from 'src/app/api/remote.service';
import { AppConfig } from 'src/app/api/helper/app.config';
import { IAuftragdetails } from '../auftragdetails/iauftragdetails';


@Component({
    selector: 'app-auftrag-weiterleiten',
    templateUrl: './auftrag-weiterleiten.page.html',
    styleUrls: ['./auftrag-weiterleiten.page.scss'],
})
export class AuftragWeiterleitenPage implements OnInit, AfterViewInit, OnDestroy {
    log = new Logger("AuftragWeiterleitenPage");

    @Input() auftrag: AuftragEx;
    @Input() workflowStatus: WorkflowStatus;
    @Input() auftragdetails: IAuftragdetails;

    subscriptions: Subscription[] = [];
    geraeteListe: GeraetDto[];

    constructor(
        private auftragService: AuftragService,
        private modalController: ModalController,
        private remoteService: RemoteService,
        private cdr: ChangeDetectorRef) { }

    async ionViewWillEnter() {
        this.log.debug('ionViewWillEnter');
    }

    ionViewDidEnter() {
        App.current.backAction = () => this.onAbbrechen();
    }

    async ionViewWillLeave() {
        this.log.debug('ionViewWillLeave');
        App.current.backAction = null;
        App.loading(false);
    }

    async ngOnInit() {
        App.loading(true);

        try {
            const geraeteListe = await this.remoteService.getAlleGeraete();

            App.loading(false);

            if (!geraeteListe) {
                return;
            }

            this.geraeteListe = geraeteListe.filter(p => p.GeraeteNummer !== AppConfig.current.geraeteNummer);

            this.cdr.detectChanges();
        } catch (err) {
            UiHelper.showError(err);
        }
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    async onGeraetSelected(dto: GeraetDto) {
        this.log.debug('onGeraetSelected');

        const ok = await UiHelper.confirmJaNein(`Auftrag ${this.auftrag.Auftragsnummer} wirklich an Gerät ${dto.Bezeichnung} (${dto.GeraeteNummer}) weiterleiten?`)

        if (!ok) {
            return;
        }

        try {
            App.loading(true);

            const result = await this.remoteService.auftragWeiterleiten({
                AuftragKey: this.auftrag.Key,
                GeraeteNummer: dto.GeraeteNummer
            });

            App.loading(false);

            if (!result) {
                UiHelper.showFehlerKommunikation();
                return;
            }

            if (result.ErrorMessage) {
                UiHelper.showError(result.ErrorMessage);
                return;
            }

            await UiHelper.showAlert('Auftrag wurde erfolgreich weitergeleitet', 'Erfolgreich', true);

            await this.auftragService.loescheAuftrag(this.auftrag);

            this.modalController.dismiss({
                'weitergeleitet': true
            });
        } catch (err) {
            UiHelper.showError(err);
        }
    }

    onAbbrechen() {
        this.dismiss();
    }

    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss(false);
    }
}
