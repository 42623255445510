

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { RemoteService } from './remote.service';

import { Subject, BehaviorSubject } from 'rxjs';
import { Logger } from './helper/app-error-logger';
import { AppConfig } from './helper/app.config';
import { SystemService } from './system.service';
import { StammdatenService } from './stammdaten.service';

import * as moment from 'moment';

import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AppStorage } from './helper/app-storage';
import { GlasAnalyseMeldung, Textdatei, TextdateiTyp } from './model/swagger-model';
import { App } from './helper/app';
import { Utils } from './helper/utils';
import { GlasAnalyseErinnerungPushMessage, GlasAnalyseEx, GlasAnalysePushMessage, PushMessageType } from './model/model';
import { UiHelper } from './helper/ui-helper';
import { Mutex } from 'async-mutex';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { DateHelper } from './helper/date-helper';

declare var cordova: any;

const STORAGHE_PREFIX = 'glas-'

const log = new Logger('GlasAnalyseService');

@Injectable({
    providedIn: 'root'
})
export class GlasAnalyseService {

    static instance: GlasAnalyseService;

    private isReady = new BehaviorSubject<boolean>(false);
    private mutex = new Mutex();

    /** TRUE, wenn die Maske für eine neue Glas-Analyse gerade angezeigt wird */
    neueGlasnanalyseAktiv = false;

    neueGlasanalyseAnforderung = new Subject<void>();

    glasanalyseErfassen = new Subject<GlasAnalyseEx>();

    listChanged = new Subject<void>();

    /**
     * Liste der aktuellen Glas-Analysen
     */
    listItems: GlasAnalyseEx[] = [];

    private meldungenIntervalId: any = null;

    /**
     * Enthält je Meldung einen Eintrag wann die letzte Meldung dazu angezeigt wurde
     */
    private letzteMeldungen: { [key: string]: number; } = {};

    /**
     * Liste mit Meldungen die gerade angezeigt werden.
     * Wird verwendet, damit Meldungen nicht doppelt angezeigt werden
     */
    private aktiveMeldungen: { [key: string]: boolean; } = {};

    private pruefeMeldungenLaeuft = false;

    app = App.current;

    constructor(
        private storage: Storage,
        private remoteService: RemoteService,
        private stammdatenService: StammdatenService,
        private alertCtrl: AlertController,
        private modalController: ModalController,
        private router: Router,
        private nav: NavController,
        private backgroundMode: BackgroundMode,
        private toastController: ToastController,
        private systemService: SystemService) {

        GlasAnalyseService.instance = this;
    }

    async init() {
        await this.storage.ready();

        this.isReady.next(true);

        this.listChanged.next();

        // Einal je 10 Minuten die Daten bereinigen
        setInterval(() => this.bereinigeDaten(), 10 * 60 * 1000);

        // 30 Sekunden nach Start die Daten bereinigen
        setTimeout(() => this.bereinigeDaten(), 30000);

        this.systemService.webSocketNachrichtEmpfangen.subscribe(message => {
            switch (message.t) {
                case PushMessageType.GlasAnalyse:
                    this.verarbeiteGlasAnalysePushMessages(message as GlasAnalysePushMessage);
                    break;

                case PushMessageType.GlasAnalyseErinnerung:
                    this.verarbeiteGlasAnalyseErinnerungPushMessages(message as GlasAnalyseErinnerungPushMessage);
                    break;
            }
        });

        App.current.configRefreshed.subscribe(() => this.onConfigRefreshed());
    }

    async loescheDaten() {
        const release = await this.mutex.acquire();

        try {
            const keys = await this.storage.keys();
            let changed = false;

            for (const key of keys.filter(p => p.startsWith(STORAGHE_PREFIX))) {
                await this.storage.remove(key);
                changed = true;
            }

            if (changed) {
                this.listChanged.next();
            }
        } finally {
            release();
        }
    }

    async bereinigeDaten() {
        log.debug('bereinigeDaten START');

        const release = await this.mutex.acquire();

        try {
            const keys = await this.storage.keys();
            let changed = false;

            const tage = Utils.parseInt(AppConfig.current.einstellungen.GlasAnalyseTageSpeichern);

            if (tage > 0) {
                const timeout = moment().add(-tage, 'days').startOf('day').toISOString();

                for (const key of keys.filter(p => p.startsWith(STORAGHE_PREFIX))) {
                    const item = await this.storage.get(key) as GlasAnalyseEx;

                    if (item) {
                        if (item.DatumIso < timeout) {
                            log.debug('Lösche Glas-Analyse: ' + JSON.stringify(item));

                            await this.storage.remove(key);
                            changed = true;
                        }
                    }
                }
            }

            if (changed) {
                this.listChanged.next();
            }
        } finally {
            release();
        }

        log.debug('bereinigeDaten FERTIG');
    }

    // async getAlleGlasAnalysen(anlage: string): Promise<GlasAnalyseEx[]> {
    //     try {

    //         const keys = await this.storage.keys();
    //         const resultList: GlasAnalyseEx[] = [];

    //         const tage = Utils.parseInt(AppConfig.current.einstellungen.GlasAnalyseTageSpeichern);

    //         const timeout = tage > 0
    //             ? moment().add(-tage, 'days').startOf('day').toISOString()
    //             : '';

    //         for (const key of keys.filter(p => p.startsWith(STORAGHE_PREFIX))) {
    //             const item = await this.storage.get(key) as GlasAnalyseEx;

    //             if (item && item.DatumIso >= timeout) {
    //                 this.fixEntity(item);

    //                 resultList.push(item);
    //             }
    //         }

    //         return resultList;
    //     } finally {
    //         release();
    //     }
    // }

    // async getGlasAnalyse(guid: string): Promise<GlasAnalyseEx> {
    //     await this.ready();

    //     const entity = await this.storage.get(STORAGHE_PREFIX + guid);

    //     return this.fixEntity(entity);
    // }

    fixEntity(item: GlasAnalyseEx): GlasAnalyseEx {
        if (!item) {
            return item;
        }

        try {
            if (item.Uhrzeit && item.Uhrzeit.length >= 4 && item.Uhrzeit.length <= 5 && item.Uhrzeit.includes(":")) {
                log.warn('Uhrzeit wird korrigiert: ' + item.Uhrzeit);

                // Die Uhrzeit ist kein Timestamp, sondern im Format "07:00".
                // Das tritt auf, wenn eine Uhrzeit manuell geändert wurde
                const m = moment(item.Uhrzeit, 'HH:mm');
                item.Uhrzeit = m.toISOString();
            }
        } catch (err) {
            log.error(Utils.getErrorMessage(err) + ": " + JSON.stringify(item));
        }

        // TODO

        return item;
    }

    public async ready() {
        if (this.isReady.getValue()) {
            return;
        }

        return new Promise(resolve => {
            const subscription = this.isReady.subscribe((value) => {
                if (value) {
                    subscription.unsubscribe();
                    resolve(null);
                }
            });
        });
    }

    async speichernGlasAnalyse(entity: GlasAnalyseEx): Promise<boolean> {
        log.info('speichernGlasAnalyse', entity);

        if (!entity.Guid) {
            entity.Guid = Utils.uuid();
        }

        if (!entity.DatumIso) {
            entity.DatumIso = Utils.nowIsoDateString();
        }

        this.fixEntity(entity);

        const key = STORAGHE_PREFIX + entity.Guid;

        this.storage.set(key, entity);

        this.listChanged.next();

        const sendEntity = Utils.clone(entity);

        if (sendEntity.Bilder) {
            for (const bild of sendEntity.Bilder) {
                if (bild.BildGuid) {
                    bild.Bild = await AppStorage.current.getBildBase64Data(bild.BildGuid);
                }
            }
        }

        // Uhrzeit soll als einfache Uhrzeit übertragen werden und nicht als ISO-Datum (das auch in der falschen Zeitzone ist)
        if (sendEntity.Uhrzeit && sendEntity.Uhrzeit.length > 6) {
            // ISO-Datum
            const m = Utils.parseDatum(sendEntity.Uhrzeit);
            sendEntity.Uhrzeit = m.format('HH:mm');
        }

        App.loading(true);

        const textdatei: Textdatei = {
            typ: TextdateiTyp.GlasAnalyse,
            datum: moment().toISOString(),
            geraeteNummer: AppConfig.current.geraeteNummer,
            GlasAnalyse: sendEntity
        };

        await this.systemService.aktualisiereTextdatei(textdatei, false);

        const result = await this.remoteService.speichereGlasAnalyse(textdatei);

        App.loading(false);

        if (!result) {
            UiHelper.showFehlerKommunikation();
            return false;
        }

        if (result.message) {
            UiHelper.showError(result.message);
            return false;
        }

        return true;
    }

    // async abschliessenGlasAnalyse(entity: GlasAnalyseEx): Promise<void> {
    //     log.info('abschliessenGlasAnalyse', entity);

    //     await this.speichernGlasAnalyse(entity);

    //     const clone: GlasAnalyseEx = Utils.clone(entity);

    //     if (clone.Bilder) {
    //         for (const bild of clone.Bilder) {
    //             if (bild.BildGuid) {
    //                 bild.Bild = await AppStorage.current.getBildBase64Data(bild.BildGuid);
    //             }
    //         }
    //     }

    //     const textdatei: Textdatei = {
    //         typ: TextdateiTyp.GlasAnalyse,
    //         key: entity.Guid,
    //         GlasAnalyse: clone,
    //         datum: moment().toISOString(),
    //         geraeteNummer: AppConfig.current.geraeteNummer
    //     };

    //     const mitGpsPosition = false;

    //     // Kein await hier aus Performance-Gründen
    //     this.systemService.sendeTextdatei(textdatei, mitGpsPosition);

    //     const toast = await this.toastController.create({
    //         message: 'Glas-Analyse wurde abgeschlossen',
    //         duration: 1500
    //     });

    //     toast.present();
    // }


    onConfigRefreshed(): void {
        log.debug('onConfigRefreshed');
        this.aktualisiereMeldungenTimer();
    }

    aktualisiereMeldungenTimer() {
        log.debug('aktualisiereMeldungenTimer')

        if (this.meldungenIntervalId) {
            clearInterval(this.meldungenIntervalId);
            this.meldungenIntervalId = null;
        }

        if (AppConfig.current.glasAnalyse?.Meldungen?.length) {
            log.debug('Glas-Analyse Meldungen konfiguriert. Starte Intervall');
            this.meldungenIntervalId = setInterval(() => this.pruefeMeldungenAnzeigen(), 15 * 1000);
        }
    }

    async pruefeMeldungenAnzeigen() {
        try {
            if (this.pruefeMeldungenLaeuft) {
                return;
            }

            // Keine Prüfung, wenn aktuell schon eine Alert-Nachricht angezeigt wird
            if (UiHelper.istAlertSichtbar) {
                return;
            }

            this.pruefeMeldungenLaeuft = true;

            if (!this.app.fahrerAngemeldet.getValue()) {
                return;
            }

            if (!AppConfig.current.glasAnalyse?.Meldungen?.length) {
                return;
            }

            // Alt: Konfiguierbare Meldungen anzeigen.
            // Wird nicht mehr benötigt, da die Glas-Analysen vom Server generiert und als Push-Nachricht verschickt werden
            // for (const meldung of AppConfig.current.glasAnalyse.Meldungen) {
            //     if (!meldung.Intervall || meldung.Intervall < 0 || !meldung.MeldungText) {
            //         // Deaktiviert
            //         continue;
            //     }

            //     let anzeigen = false;

            //     let letzteAnzeige = this.letzteMeldungen[meldung.Key];

            //     if (!letzteAnzeige) {
            //         // Die Meldung wurde noch nie angezeigt.
            //         // Sie soll aber nicht direkt beim Start angezeigt werden. Deshalb gehe davon aus, dass die 
            //         // Meldung erst im Intervall nach dem Start angezeigt werden soll (auf die voll Stunde abrunden).
            //         const m = moment().startOf('hour');
            //         letzteAnzeige = m.valueOf();
            //         this.letzteMeldungen[meldung.Key] = letzteAnzeige;
            //     }

            //     if (meldung.Intervall === 60 || (meldung.Intervall > 60 && meldung.Intervall % 60 === 0)) {
            //         // Immer zur vollen Stunde anzeigen
            //         // Deshalb runden auf die letzte/nächste volle Stunde
            //         // https://stackoverflow.com/questions/17691202/round-up-round-down-a-momentjs-moment-to-nearest-minute
            //         // Es soll aber alles bis 45 Minuten nach der vollen Stunde auf die letzte volle Stunde abgerundet werden.
            //         // D.h. wenn man um 12:35 Uhr eine Prüfung macht, dann soll die nächste um 13:00 Uhr angezeigt werden.
            //         // Deshalb nur das add(15, 'minutes');
            //         const m = moment(letzteAnzeige)
            //             .add(15, 'minutes')
            //             .startOf('hour');

            //         letzteAnzeige = m.valueOf();
            //     }

            //     const millisekundenSeitLetzterAnzeige = Date.now() - letzteAnzeige;
            //     const minutenSeitLetzterAnzeige = millisekundenSeitLetzterAnzeige / (60 * 1000);

            //     if (Math.round(minutenSeitLetzterAnzeige) >= meldung.Intervall) {
            //         // Es ist mal wieder Zeit
            //         anzeigen = true;
            //     }

            //     if (anzeigen) {
            //         // Hinweis soll angezeigt werden
            //         // Prüfe ob diese Warnung bereits angezeigt wird
            //         if (!this.aktiveMeldungen[meldung.Key]) {
            //             this.letzteMeldungen[meldung.Key] = Date.now();
            //             this.aktiveMeldungen[meldung.Key] = true;

            //             if (App.isCordovaAvailable()) {
            //                 try {
            //                     const localNotifications = (cordova.plugins as any).notification?.local;

            //                     localNotifications.schedule({
            //                         title: 'Glas-Analyse',
            //                         text: meldung.MeldungText,
            //                         vibrate: true,
            //                         wakeup: true,
            //                         badge: 1,
            //                         priority: 1,
            //                         lockscreen: true,
            //                         foreground: false,
            //                         launch: true,
            //                         sound: 'res://platform_default'
            //                         // actions: 'yes-no'
            //                     });

            //                     this.backgroundMode.wakeUp();
            //                     this.backgroundMode.moveToForeground();
            //                 } catch (err) {
            //                     log.warn(Utils.getErrorMessage(err));
            //                 }
            //             }

            //             await this.systemService.beepFuerNeueNachricht();

            //             if (meldung.Typ === 'Analyse') {
            //                 // Aufforderung Glas-Analyse durchzuführen
            //                 await this.showAnalyseMeldung(meldung);
            //             } else {
            //                 // Einfacher Hinweis
            //                 await UiHelper.showAlert(meldung.MeldungText, null, true);
            //             }

            //             this.letzteMeldungen[meldung.Key] = Date.now();
            //             this.aktiveMeldungen[meldung.Key] = false;
            //         }
            //     }
            // }
        } catch (err) {
            log.error("pruefeArbeitszeitwarnungAnzeigen: " + Utils.getErrorMessage(err), err);
        } finally {
            this.pruefeMeldungenLaeuft = false;
        }
    }

    async verarbeiteGlasAnalysePushMessages(message: GlasAnalysePushMessage) {
        log.debug('verarbeiteGlasAnalysePushMessages', message);

        const glasAnalyse = message.GlasAnalyse;

        if (glasAnalyse.Anlage != AppConfig.current.aktuelleAnlage) {
            return;
        }

        if (!glasAnalyse.Pruefergebnis) {
            let meldung = 'Analyse erforderlich!';

            if (!App.istImVordergrund) {
                // Notification, wenn die App nicht im Vordergrund ist
                await this.showGlasAnalyseNotification(meldung);
            }

            if (!this.app.fahrerAngemeldet.getValue()) {
                return;
            }

            if (!this.neueGlasnanalyseAktiv && !UiHelper.istAlertSichtbar) {
                // Es wird derzeit keine Glas-Anlage-Erfassungs-Seite angezeigt.
                await UiHelper.showAlert(meldung, null, true);

                // Navigiere zur Glas-Analyse, falls nicht bereits aktiv
                await this.nav.navigateForward('/glasanalyse');

                await Utils.delay(500);

                this.glasanalyseErfassen.next(glasAnalyse);
            } else {
                // Es wird bereits eine Glas-Analyse erfasst. DEshalb nur die Liste aktualisieren
                this.listChanged.next();
            }
        } else {
            // GlasAnalyse wurde nur zur Info geschickt, damit die Liste aktualisiert wird
            this.listChanged.next();
        }
    }

    async verarbeiteGlasAnalyseErinnerungPushMessages(message: GlasAnalysePushMessage) {
        log.debug('verarbeiteGlasAnalyseErinnerungPushMessages: ' + JSON.stringify(message));

        let glasAnalyse = message.GlasAnalyse;

        if (glasAnalyse.Anlage != AppConfig.current.aktuelleAnlage) {
            return;
        }

        log.info('Fehlende Glas-Analyse: ' + JSON.stringify(glasAnalyse));

        // Prüfe ob es noch einen ältern sichtbaren Eintrag gibt.
        // Der Server sendet immer nur Erinnerungen für den aktuellen Tag
        if (this.listItems) {
            let list = this.listItems.filter(p => !p.Pruefergebnis && p.DatumMitUhrzeit < glasAnalyse.DatumMitUhrzeit && p.Anlage == AppConfig.current.aktuelleAnlage);

            if (list?.length) {
                // letzten Eintrag. Hier ist absteigend sortiert
                glasAnalyse = list[list.length - 1];
                log.info('Fehlende Glas-Analyse aus Liste: ' + JSON.stringify(glasAnalyse));
            }
        }

        // let uhrzeit = DateHelper.formatUhrzeit(glasAnalyse.Uhrzeit);
        let datumUhrzeit = DateHelper.formatDatum(glasAnalyse.DatumIso) + ' ' + DateHelper.formatUhrzeit(glasAnalyse.Uhrzeit) + ' Uhr';

        let meldung = `Analyse für ${datumUhrzeit} fehlt!`;

        // if (glasAnalyse.Uhrzeit?.length >= 10) {
        //     const m = moment(glasAnalyse.Uhrzeit);
        //     const now = moment();

        //     if (m.isBefore(now, 'day')) {
        //         meldung = `Analyse für Gestern ${uhrzeit} Uhr fehlt!`;
        //     }
        // }

        if (!App.istImVordergrund) {
            // Notification, wenn die App nicht im Vordergrund ist
            await this.showGlasAnalyseNotification(meldung);
        }

        if (!this.app.fahrerAngemeldet.getValue()) {
            return;
        }

        if (this.neueGlasnanalyseAktiv) {
            // Wir sind bereits mitten in einer Glas-Analyse. Deshalb nicht nochmal nerven
            return;
        }

        if (UiHelper.istAlertSichtbar) {
            // Es wird bereits ein Dialog angezeigt
            return;
        }

        await UiHelper.showAlert(meldung, 'Glas-Analyse', true);

        // Navigiere zur Glas-Analyse, falls nicht bereits aktiv
        await this.nav.navigateForward('/glasanalyse');

        await Utils.delay(500);

        this.glasanalyseErfassen.next(glasAnalyse);
    }

    async showGlasAnalyseNotification(meldung: string) {
        if (!App.isCordovaAvailable()) {
            return;
        }

        const localNotifications = (cordova.plugins as any).notification?.local;

        localNotifications.schedule({
            title: 'Glas-Analyse',
            text: meldung,
            vibrate: true,
            wakeup: true,
            badge: 0,
            priority: 2,
            lockscreen: true,
            foreground: true,
            launch: true,
            channelName: 'Glas-Analyse',
            sound: 'res://platform_default',
        });

        // Turn screen on
        this.backgroundMode.wakeUp();

        // Turn screen on and show app even locked
        cordova.plugins.backgroundMode.unlock();

        this.backgroundMode.moveToForeground();

        await this.systemService.beepFuerNeueNachricht();
    }

    async showAnalyseMeldung(meldung: GlasAnalyseMeldung) {
        if (!this.neueGlasnanalyseAktiv) {
            await UiHelper.showAlert(meldung.MeldungText, null, true);

            // Navigiere zur Glas-Analyse, falls nicht bereits aktiv
            await this.nav.navigateForward('/glasanalyse');

            await Utils.delay(500);

            this.neueGlasanalyseAnforderung.next();
        }

        // const buttons: any[] = [];

        // let auswahl = '';

        // buttons.push({
        //     text: 'Ablehnen',
        //     role: 'destructive',
        //     icon: 'trash',
        //     handler: () => {
        //         auswahl = 'ablehnen';
        //     }
        // });

        // buttons.push({
        //     text: 'Anzeigen',
        //     icon: 'image',
        //     handler: () => {
        //         auswahl = 'ablehnen';
        //     }
        // });

        // const alert = await this.alertController.create({
        //     cssClass: 'no-text-alert no-title-alert',
        //     mode: 'ios',
        //     buttons: buttons
        // });

        // await alert.present();

        // await alert.onDidDismiss();
    }
}
