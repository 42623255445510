<div *ngIf="auftrag && auftragsposition">
  <ng-container *ngFor="let wiegung of auftragsposition.Wiegungen2">
    <!-- Normale Wiegung oder Leergewicht -->
    <ion-item lines="none" *ngIf="wiegung.Typ !== 2">
      <ion-label position="stacked">{{ wiegung.Bezeichnung }}</ion-label>
      <div class="wiegung-ausgabe" *ngIf="wiegung.Text">
        {{ wiegung.Text }}
      </div>
      <!-- <ion-input inputmode="numeric" type="text" class="input-readonly with-border" [(ngModel)]="wiegung.Text" readonly></ion-input> -->
    </ion-item>

    <!-- Handeingabe -->
    <ion-item lines="none" *ngIf="wiegung.Typ === 2">
      <ion-label position="stacked">{{ wiegung.Bezeichnung }}</ion-label>
      <ion-input
                 inputmode="numeric"
                 type="text"
                 (ionChange)="onGewichtChanged(wiegung)"
                 [(ngModel)]="wiegung.Gewicht"
                 id="wiegung-{{ auftragsposition.PosNr }}-{{ wiegung.Nummer }}-input"
                 (keyup.enter)="app.closeKeyboard($event)" enterkeyhint="done"
                 clearInput required></ion-input>
    </ion-item>

    <ion-item lines="none" *ngIf="wiegung.Typ === 3">
      <ion-label position="stacked">Einheit</ion-label>
      <ion-select placeholder="{{ 'Bitte Einheit auswählen' | translate }}" [(ngModel)]="wiegung.Einheit" (ngModelChange)="onGewichtChanged(wiegung)" interface="popover" required [okText]="'OK' | translate" [cancelText]="'Abbrechen' | translate">
        <ion-select-option *ngFor="let a of einheiten" [value]="a">{{ a }}</ion-select-option>
      </ion-select>
    </ion-item>

    <ng-container *ngIf="!auftragdetails.isLieferungAendernGesperrt">
      <ion-button size="large" class="auftragsposition-wiegen-button" [color]="getWiegenButtonColor(wiegung.Nummer, 1)" appClick (clicked)="onWiegen(wiegung.Nummer)">
        <ion-icon *ngIf="wiegung.Erfolgreich" slot="start" icon="checkmark" class="icon-checkmark"></ion-icon>
        Wiegen
      </ion-button>

      <ion-button *ngIf="wiegenHandeingabeErlaubt" size="large" class="auftragsposition-handeingabe-button item-text-wrap" [color]="getWiegenButtonColor(wiegung.Nummer, 2)" appClick (clicked)="onHandeingabe(wiegung.Nummer)">Hand&shy;eingabe</ion-button>
      <ion-button *ngIf="wiegenLeergewichtErlaubt" size="large" class="auftragsposition-leergewicht-button" [color]="getWiegenButtonColor(wiegung.Nummer, 3)" appClick (clicked)="onLeergewicht(wiegung.Nummer)">Leer&shy;gewicht</ion-button>
    </ng-container>
  </ng-container>

  <div *ngIf="auftragsposition.NettoGewicht" class="netto-gewicht">Netto: <span class="netto-gewicht-span" [class.ungueltig]="auftragsposition.NettoGewicht < 0">{{ auftragsposition.NettoGewicht }}</span> {{ auftragsposition.Wiegungen2[0].Einheit }}</div>
</div>

<ion-select #waagenSelect [hidden]="true" (ionChange)="onWaageSelected($event)" [multiple]="false" [okText]="'OK' | translate" [cancelText]="'Abbrechen' | translate" [(ngModel)]="ausgewaehlteWaage" class="waagen-select" interface="action-sheet" mode="ios">
  <ion-label>Waage</ion-label>
  <ion-select-option *ngFor="let w of waagen" [value]="w">{{ w.Name }}</ion-select-option>
</ion-select>