import { Injectable } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';
import { Logger } from './helper/app-error-logger';

import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { AppConfig, WaageKonfiguration } from './helper/app.config';
import { App } from './helper/app';
import { MobileWaage } from './waagen/waage';
import { WelvaartsWaage } from './waagen/welvaarts-waage';
import { GeraetStatusTyp, BluetoothDevice, AuftragEx, AuftragspositionEx } from './model/model';
import { AppStorage } from './helper/app-storage';
import { ToastController } from '@ionic/angular';
import { Utils } from './helper/utils';
import { SystemService } from './system.service';
import { Wiegedaten, TextdateiTyp, Textdatei, Waage, WiegenResult, GpsPosition, WiegenRequest } from './model/swagger-model';

import * as moment from 'moment';
import { RemoteService } from './remote.service';
import { UiHelper } from './helper/ui-helper';
import { SywatecWaage } from './waagen/sywatec-waage';

@Injectable({
    providedIn: 'root'
})
export class WaageService {
    static instance: WaageService;

    private log = new Logger('WaageService');

    anzahlWiegungen = new BehaviorSubject<number>(0);
    waageStatus = new BehaviorSubject<GeraetStatusTyp>(GeraetStatusTyp.Unbekannt);

    private updateIntervalId: any;

    private waagen: MobileWaage[] = [];

    public wiegedaten = new BehaviorSubject<Wiegedaten[]>([]);

    private waageSubscriptions: { [adresse: string]: Subscription[]; } = {};

    constructor(
        private bluetoothSerial: BluetoothSerial,
        private systemService: SystemService,
        private remoteService: RemoteService,
        private toastController: ToastController) {

        WaageService.instance = this;
    }

    async init(): Promise<void> {
        this.log.debug('init');

        await App.ready();

        const dbWiegedaten: Wiegedaten[] = await AppStorage.current.get('wiegedaten', true, false);

        if (dbWiegedaten) {
            this.wiegedaten.next(dbWiegedaten);
            this.anzahlWiegungen.next(dbWiegedaten.length)
        }

        App.current.configRefreshed.subscribe(() => this.initWaage());

        setTimeout(() => {
            this.initWaage();
        }, 10 * 1000);
    }

    /**
     * Wird aufgerufen wenn Einstellungen geändert wurden
     */
    async restart(): Promise<void> {
        this.log.info('restart');
        await this.initWaage();
    }

    private async initWaage() {
        this.log.debug('initWaage');

        if (this.updateIntervalId) {
            clearInterval(this.updateIntervalId);
            this.updateIntervalId = null;
        }

        if (!App.current.waageVerfuegbar.getValue()) {
            this.waageStatus.next(GeraetStatusTyp.NichtVerbunden);
            return;
        }

        const waageKonfigurationen = this.getWaageKonfigurationen();

        // Schließe Waagen-Implementierungen von nicht mehr vorhandennen Waagen
        for (const waage of [...this.waagen]) {
            let disposeErforderlich = false;

            const waageKonfig = waageKonfigurationen.find(p => p.macAdresse == waage.adresse);

            if (!waageKonfig) {
                // Waage wurde gelöscht
                disposeErforderlich = true;
            } else {
                if (waageKonfig.typ && waageKonfig.typ !== waage.getTyp()) {
                    // Typ hat sich geändert
                    disposeErforderlich = true;
                }
            }

            if (disposeErforderlich) {
                this.disposeWaage(waage);
            }
        }

        // Alle nicht mehr aktiven Waagen zuerst shließen
        for (const waage of [...this.waagen]) {
            if (!waage.konfiguration.aktiv) {
                waage.setActive(false);
            }
        }

        // Initialisiere neue Waagen
        for (const waageConfig of waageKonfigurationen) {
            let waage = this.waagen.find(p => p.adresse == waageConfig.macAdresse);

            if (!waage) {
                waage = this.erstelleWaage(waageConfig);

                if (waage) {
                    const subscriptions: Subscription[] = [];

                    subscriptions.push(waage.error.subscribe(e => this.onError(waage, e)));
                    subscriptions.push(waage.status.subscribe(s => this.onStatusChanged(waage, s)));
                    // subscriptions.push(waage.disconnected.subscribe(w => this.onStatusChanged(waage, GeraetStatusTyp.NichtVerbunden)));
                    // subscriptions.push(waage.datenEmpfangen.subscribe(w => this.onStatusChanged(waage, GeraetStatusTyp.Verbunden)));
                    subscriptions.push(waage.wiegedatenEmpfangen.subscribe(w => this.onWiegedatenEmpfangen(waage, w)));

                    this.waagen.push(waage);
                }
            }

            if (waage) {
                waage.setActive(waageConfig.aktiv);
            }
        }
    }

    getWaageKonfigurationen(): WaageKonfiguration[] {
        const appConfig = AppConfig.current;

        if (!appConfig.gekoppelteWaagen) {
            appConfig.gekoppelteWaagen = [];
        }

        return appConfig.gekoppelteWaagen;
    }

    getAktiveWaageKonfigurationen(): WaageKonfiguration[] {
        return this.getWaageKonfigurationen().filter(p => p.aktiv);
    }

    private erstelleWaage(konfiguration: WaageKonfiguration): MobileWaage {
        this.log.debug('erstelleWaage: ', konfiguration);

        let waage: MobileWaage;

        if (konfiguration.typ === 'welvaarts') {
            waage = new WelvaartsWaage(this.bluetoothSerial);
        } else if (konfiguration.typ === 'sywatec') {
            waage = new SywatecWaage(this.bluetoothSerial);
        } else {
            throw new Error('Unbekannter Waagen-Typ: ' + konfiguration.typ);
        }

        waage.adresse = konfiguration.macAdresse;
        waage.konfiguration = konfiguration;

        return waage;
    }

    private async disposeWaage(waage: MobileWaage) {
        this.log.info('disposeWaage', waage);

        try {
            const subscriptions = this.waageSubscriptions[waage.adresse];

            if (subscriptions) {
                subscriptions.forEach(p => p.unsubscribe());
                delete this.waageSubscriptions[waage.adresse];
            }
        } catch (err) {
            this.log.error('disposeWaage 1', err);
        }

        try {
            waage.dispose();
        } catch (err) {
            this.log.error('disposeWaage 2', err);
        }

        const index = this.waagen.indexOf(waage);

        if (index >= 0) {
            this.waagen.splice(index, 1);
        }
    }

    // private async close() {
    //     this.log.info('Close');

    //     if (!App.isCordovaAvailable()) {
    //         return;
    //     }

    //     try {
    //         if (this.waage) {
    //             const w = this.waage;
    //             this.waage = null;
    //             await w.dispose();
    //         }
    //     } catch (err) {
    //         this.log.error('Close', err);
    //     }

    //     this.waageSubscriptions.forEach(p => p.unsubscribe());
    //     this.waageSubscriptions = [];
    // }

    async sucheBluetoothGeraete(): Promise<BluetoothDevice[]> {
        this.log.debug('sucheBluetoothGeraete');

        const resultList: BluetoothDevice[] = [];

        resultList.push(... await this.getGekoppelteBluetoothGeraete());

        if (App.isCordovaAvailable()) {
            try {
                const unpairedDevices: BluetoothDevice[] = await this.bluetoothSerial.discoverUnpaired();

                this.log.info('Unpaired Devices: ' + JSON.stringify(unpairedDevices));

                for (const device of unpairedDevices) {
                    if (!device.name) {
                        device.name = device.address;
                    }

                    if (!resultList.find(p => p.address == device.address)) {
                        resultList.push(device);
                    }
                }
            } catch (err) {
                this.log.error('sucheBluetoothGeraete: ' + Utils.getErrorMessage(err), err);
            }
        }

        this.log.debug('sucheBluetoothGeraete result: ' + JSON.stringify(resultList));

        return resultList;
    }

    async getGekoppelteBluetoothGeraete(): Promise<BluetoothDevice[]> {
        this.log.debug('getGekoppelteBluetoothGeraete');

        if (!App.isCordovaAvailable()) {
            return [{
                id: '111111',
                address: '111111',
                name: ''
            }, {
                id: '222222',
                address: '222222',
                name: ''
            }, {
                id: '333333',
                address: '333333',
                name: ''
            }];
        }

        this.log.debug('Aktiviere Bluetooth...');
        const success = await this.bluetoothSerial.enable();

        if (!success) {
            this.log.warn('Bluetooth konnte nicht aktiviert werden');
            return null;
        }

        const list = await this.bluetoothSerial.list();

        this.log.info('Bluetooth Geräte: ' + JSON.stringify(list));

        return list;
    }

    async onStatusChanged(waage: MobileWaage, status: GeraetStatusTyp) {
        this.log.debug('onStatusChanged: ' + status);

        let gesamtStatus = GeraetStatusTyp.NichtVerbunden;

        switch (status) {
            case GeraetStatusTyp.Verbunden:
                gesamtStatus = GeraetStatusTyp.Verbunden;
                break;

            default:
                for (const w of this.waagen) {
                    if (w.status.getValue() == GeraetStatusTyp.Verbunden) {
                        gesamtStatus = GeraetStatusTyp.Verbunden;
                        break;
                    }
                }
        }

        if (this.waageStatus.getValue() != gesamtStatus) {
            this.waageStatus.next(gesamtStatus);
        }
    }

    async onError(waage: MobileWaage, error: string) {
        this.log.warn(`Fehler von Waage '${waage.konfiguration.name}': ${error}`);

        this.waageStatus.next(GeraetStatusTyp.Fehler);

        // const toast = await this.toastController.create({
        //     message: `Fehler von Waage '${waage.konfiguration.name}': ${error}`,
        //     duration: 5000,
        //     color: 'warning'
        // });

        // toast.present();
    }

    async loescheAlleWiegedaten() {
        this.log.debug('loescheAlleWiegedaten');

        const wiegedaten = this.wiegedaten.getValue();

        const toast = await this.toastController.create({
            message: 'Wiegungen wurden gelöscht',
            duration: 1500,
            color: 'medium'
        });

        toast.present();

        this.setzeWiegedatenListe([]);

        const textdatei: Textdatei = {
            typ: TextdateiTyp.GeloeschteWiegungen,
            datum: moment().toISOString(),
            geraeteNummer: AppConfig.current.geraeteNummer,
            GeloeschteWiegungen: {
                Personalnummer: App.current.getPersonalnummer(),
                FahrzeugKey: App.current.getFahrzeugKey(),
                Wiegungen: [...wiegedaten]
            }
        };

        this.systemService.sendeTextdatei(textdatei, true);
    }

    async setzeWiegedatenListe(wiegedaten: Wiegedaten[]) {
        this.log.debug('setzeWiegedatenListe: ' + wiegedaten.length);

        await AppStorage.current.set('wiegedaten', wiegedaten);

        this.wiegedaten.next(wiegedaten);
        this.anzahlWiegungen.next(wiegedaten.length);
    }

    async onWiegedatenEmpfangen(waage: MobileWaage, wiegedaten: Wiegedaten) {
        this.log.debug('onWiegedatenEmpfangen', wiegedaten);

        this.waageStatus.next(GeraetStatusTyp.Verbunden);

        const toast = await this.toastController.create({
            message: 'Wiegung empfangen: ' + wiegedaten.Netto + ' kg',
            duration: 1500,
            color: 'medium'
        });

        toast.present();

        if (AppConfig.current.einstellungen.WaageSoundBeiWiegungAktiv == 'ja') {
            this.systemService.beepFuerNeueNachricht();
        }

        const list = this.wiegedaten.getValue();
        list.push(wiegedaten);

        this.setzeWiegedatenListe(list);
    }

    getWaageKonfigurationByMacAdresse(macAdresse: string): WaageKonfiguration {
        const appConfig = AppConfig.current;

        return appConfig.gekoppelteWaagen.find(p => p.macAdresse && p.macAdresse.toLowerCase() == macAdresse.toLowerCase());
    }

    getWaage(adresse: string): MobileWaage {
        this.log.debug('getWaage: ' + adresse, this.waagen);
        return this.waagen.find(p => p.adresse == adresse);
    }

    async getRemoteWaagenInReichweite(): Promise<Waage[]> {
        if (!App.isInternetAvailable()) {
            return [];
        }

        let position: GpsPosition;

        if (AppConfig.current.einstellungen.StationaereWaagenGpsPruefen === 'ja') {
            if (AppConfig.current.einstellungen.WiegenMitExakterGpsPosition == 'ja') {
                // Versuche eine bessere Position zu ermitteln
                position = await this.systemService.getExakteGpsPosition('wiegen Remote', true);
            } else {
                position = await this.systemService.getAktuelleGpsPosition('getRemoteWaagenInReichweite', 30);
            }

            if (!position) {
                this.log.warn('Aktuelle Position konnte nicht bestimmt werden. Verwende letzte bekannte Position.');

                position = this.systemService.letzteGpsPosition;
            };
        }

        if (!position) {
            this.log.warn('Keine GPS-Position bekannt');

            position = {
                Longitude: 0,
                Latitude: 0
            }
        }

        try {
            let waagen = await this.remoteService.getWaagen(position.Latitude, position.Longitude);

            if (waagen) {
                if (AppConfig.current.UnterMandant) {
                    // Nur Waagen zum passenden Unter-Mandant anzeigen
                    waagen = waagen.filter(p => !p.UnterMandant || p.UnterMandant == AppConfig.current.UnterMandant);
                }

                return waagen;
            }
        } catch (err) {
            this.log.error(Utils.getErrorMessage(err));
        }

        return [];
    }

    async wiegenRemote(request: WiegenRequest): Promise<WiegenResult> {
        this.log.debug('wiegenRemote: ' + request.WaageKey);

        let position = this.systemService.letzteGpsPosition;

        if (AppConfig.current.einstellungen.WiegenMitExakterGpsPosition == 'ja') {
            if (!position || position.Accuracy > App.current.PositionenExaktMeter) {
                this.log.info('wiegenRemote: Position ist zu ungenau. Erneut nach einer exakten GPS-Position suchen');

                // Versuche eine bessere Position zu ermitteln
                position = await this.systemService.getExakteGpsPosition('wiegen Remote', true);

                if (!position) {
                    return {
                        Success: false,
                        Message: 'GPS-Position konnte nicht bestimmt werden.'
                    }
                }
            }
        }

        try {
            request.Position = position;
            request.GeraeteNummer = AppConfig.current.geraeteNummer;
            request.FahrzeugKey = App.current.getFahrzeugKey();
            request.FehrzeugKennzeichen = App.current.getFahrzeugKennzeichen();

            const tour = App.current.aktuelleTour.getValue();

            if (tour) {
                request.TourBezeichnung = tour.TourBezeichnung;
                request.TourKey = tour.TourKey;
            }

            const fahrer = App.current.fahrer.getValue();

            if (fahrer) {
                request.PersonalKey = fahrer.Key;
                request.Personalnummer = fahrer.personalnummer;
                request.Vorname = fahrer.vorname;
                request.Nachname = fahrer.nachname;
            }

            const result = await this.remoteService.wiegen(request);

            if (result) {
                return result;
            } else {
                return {
                    Success: false,
                    Message: 'Kommunikationsfehler'
                }
            }
        } catch (err) {
            this.log.error(Utils.getErrorMessage(err));

            return {
                Success: false,
                Message: Utils.getErrorMessage(err)
            }
        }
    }
}
