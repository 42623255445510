import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalController, NavController } from '@ionic/angular';
import { AuftragService } from 'src/app/api/auftrag.service';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { App } from 'src/app/api/helper/app';
import { Druckformular, Auftrag, Workflow, WorkflowStatus, BezahlartTyp } from 'src/app/api/model/swagger-model';
import { StatusmeldungZusatzdaten, AuftragEx, AuftragspositionEx } from 'src/app/api/model/model';
import { IAuftragdetails } from '../auftragdetails/iauftragdetails';


@Component({
    selector: 'app-auftrag-bezahlen',
    templateUrl: './auftrag-bezahlen.page.html',
    styleUrls: ['./auftrag-bezahlen.page.scss'],
})
export class AuftragBezahlenPage implements OnInit, AfterViewInit, OnDestroy {
    log = new Logger("AuftragBezahlenPage");

    @Input() auftrag: AuftragEx;
    @Input() workflowStatus: WorkflowStatus;
    @Input() auftragdetails: IAuftragdetails;

    subscriptions: Subscription[] = [];

    druckformulare: Druckformular[];
    druckformularName = '';

    bezahlungArt = BezahlartTyp.Bar;

    positionen: AuftragspositionEx[] = [];

    constructor(
        private auftragService: AuftragService,
        private modalController: ModalController,
        private cdr: ChangeDetectorRef) { }


    ngOnInit() {
        this.log.debug('ngOnInit');
        this.auftragService.aktualisierePreise(this.auftrag, 'bezahlen init');
    }

    async ionViewWillEnter() {
        this.log.debug('ionViewWillEnter');

        try {
            // Nur Positionen mit Menge anzeigen
            this.positionen = this.auftrag.Positionen.filter(p => p.Menge);

            this.cdr.detectChanges();
        } catch (err) {
            this.positionen = [];

            UiHelper.showError(err);
            setTimeout(() => this.onAbbrechen(), 0);
            return;
        }
    }

    ionViewDidEnter() {
        App.current.backAction = () => this.onAbbrechen();
    }

    async ionViewWillLeave() {
        this.log.debug('ionViewWillLeave');
        App.current.backAction = null;
        App.loading(false);
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    async onStorno() {
        if (!this.auftrag.IstBezahlt) {
            return;
        }

        if (!this.auftrag.BezahlungBetrag) {
            this.auftrag.BezahlungBetrag = 0;
        }

        try {
            let ok = await UiHelper.confirmJaNein(`${this.auftrag.BezahlungBetrag.toFixed(2)} € wirklich stornieren?`, 'Storno')

            if (!ok) {
                App.loading(false);
                return;
            }

            const zusatzdaten: StatusmeldungZusatzdaten = {
                istBezahlung: true,
                bezahlungArt: BezahlartTyp.Storno,
                bezahlungBetrag: this.auftrag.BezahlungBetrag
            };

            ok = await this.auftragService.statusmeldung(this.auftrag, this.workflowStatus, zusatzdaten);

            if (!ok) {
                App.loading(false);
                return;
            }

            this.auftrag.BezahlungArt = BezahlartTyp.Unbekannt;
            this.auftrag.BezahlungBetrag = 0;
            this.auftrag.IstBezahlt = false;

            await this.auftragService.speichereAuftrag(this.auftrag);

            App.loading(false);

            UiHelper.showAlert('Buchung wurde storniert');

            this.cdr.detectChanges();
        } catch (err) {
            App.loading(false);
            UiHelper.showError(err);
        }
    }

    async onGeldErhalten() {
        try {
            if (!this.bezahlungArt) {
                UiHelper.showAlert('Bitte ein Zahlungsvariante (BAR, EC-Karte) auswählen');
                return;
            }

            const betrag = this.auftrag.SummeBrutto;

            let text;

            switch (this.bezahlungArt) {
                case BezahlartTyp.Bar:
                    text = `Haben Sie wirklich ${betrag.toFixed(2)} € BAR erhalten?`;
                    break;
                case BezahlartTyp.EC:
                    text = `Haben Sie wirklich ${betrag.toFixed(2)} € per EC-Karte erhalten?`;
                    break;
                default:
                    text = `Haben Sie wirklich ${betrag.toFixed(2)} € erhalten?`;
                    break;
            }

            let ok = await UiHelper.confirmJaNein(text, 'Geld erhalten?')

            if (ok) {
                App.loading(true);

                const zusatzdaten: StatusmeldungZusatzdaten = {
                    istBezahlung: true,
                    bezahlungArt: this.bezahlungArt,
                    bezahlungBetrag: betrag
                };

                ok = await this.auftragService.statusmeldung(this.auftrag, this.workflowStatus, zusatzdaten);

                if (!ok) {
                    App.loading(false);
                    return;
                }

                this.auftrag.BezahlungArt = this.bezahlungArt;
                this.auftrag.BezahlungBetrag = betrag;
                this.auftrag.IstBezahlt = true;

                await this.auftragService.speichereAuftrag(this.auftrag);

                this.onAbbrechen();

                App.loading(false);
            }
        } catch (err) {
            this.log.error('onDrucken', err);
            App.loading(false);
            UiHelper.hideLoading();
            UiHelper.showError(err);
        }
    }

    onAbbrechen() {
        this.dismiss();
    }

    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            'dismissed': true
        });
    }

    onRadioGroupChange(e: Event) {
        this.log.debug('onRadioGroupChange', e);
        const event = e as CustomEvent;
        this.bezahlungArt = event.detail.value;
    }
}
