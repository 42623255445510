import { UvvPruefGegenstand, UvvKatalog } from '../model/swagger-model';

import * as moment from 'moment';
import { Logger } from './app-error-logger';
import { App } from './app';

export class UvvHelper {
    private static log = new Logger("UvvHelper");

    static erstelleSuchtext(auftrag: UvvPruefGegenstand): string {
        let suchtexte = [];

        suchtexte.push(auftrag.identNummer);
        suchtexte.push(auftrag.bezeichnung);
        suchtexte.push(auftrag.manuelleNummer);
        suchtexte.push(auftrag.typ);
        suchtexte.push(auftrag.katalogName);

        suchtexte = suchtexte.filter(p => p);

        return suchtexte.join('; ').toLowerCase();
    }

    static getBezeichnung(gegenstand: UvvPruefGegenstand): string {
        let bezeichnung = '';

        if (gegenstand.manuelleNummer) {
            bezeichnung = gegenstand.manuelleNummer;

            if (gegenstand.identNummer && gegenstand.identNummer != gegenstand.manuelleNummer) {
                bezeichnung += ' / ' + gegenstand.identNummer;
            }
        } else {
            bezeichnung = gegenstand.identNummer;
        }

        if (gegenstand.bezeichnung) {
            bezeichnung += ': ' + gegenstand.bezeichnung;
        }

        return bezeichnung;
    }

    static istUvvUeberfaellig(gegenstand: UvvPruefGegenstand): boolean {
        if (gegenstand && gegenstand.naechstePruefung) {
            try {
                const naechstePruefung = moment(gegenstand.naechstePruefung);
                const now = moment();

                return naechstePruefung < now.startOf('day');
            } catch (err) {
                UvvHelper.log.error('istUvvUeberfaellig: ' + err.message, { gegenstand });
            }
        }

        return false;
    }

    static istFaelligFuerPruefung(gegenstand: UvvPruefGegenstand): boolean {
        if (gegenstand && gegenstand.naechstePruefung) {
            try {
                const naechstePruefung = moment(gegenstand.naechstePruefung);
                const tage = App.current.uvvNaechstePruefungTageAnzeigen.getValue();
                const pruefDatum = moment().add(tage, 'days');

                return naechstePruefung < pruefDatum;
            } catch (err) {
                UvvHelper.log.error('istFaelligFuerPruefung: ' + err.message, { gegenstand });
            }
        }

        return false;
    }

    static berechneNaechstePruefung(katalog: UvvKatalog, gegenstand: UvvPruefGegenstand): string {
        if (katalog.intervall > 0) {
            let intervall = katalog.intervall;

            switch (katalog.intervallTyp) {
                case 'wochen':
                    if (intervall > 999) {
                        intervall = 999;
                    }

                    return moment().add(intervall, 'week').startOf('day').toISOString(true);

                case 'monate':
                    if (intervall > 999) {
                        intervall = 999;
                    }

                    return moment().add(intervall, 'months').startOf('day').toISOString(true);

                case 'jahre':
                    if (intervall > 99) {
                        intervall = 99;
                    }

                    return moment().add(intervall, 'years').startOf('day').toISOString(true);

                default: // tage
                    if (intervall > 9999) {
                        intervall = 9999;
                    }

                    return moment().add(intervall, 'days').startOf('day').toISOString(true);
            }
        }

        // Sollte nie eintreten
        return moment().add(10, 'years').startOf('day').toISOString(true);
    }

    static areEqual(a: UvvPruefGegenstand, b: UvvPruefGegenstand) {
        if (!a || !b) {
            return false;
        }

        if (a.key != b.key) {
            return false;
        }
        if (a.identNummer != b.identNummer) {
            return false;
        }
        if (a.manuelleNummer != b.manuelleNummer) {
            return false;
        }
        if (a.typ != b.typ) {
            return false;
        }
        if (a.katalogName != b.katalogName) {
            return false;
        }
        if (a.id != b.id) {
            return false;
        }
        if (a.bezeichnung != b.bezeichnung) {
            return false;
        }
        if (a.zustand != b.zustand) {
            return false;
        }

        // Sonderbehandlung f�r Datum.
        // Es soll "2047-05-25T00:00:00.000+02:00" identisch sein
        //     mit "2047-05-25T00:00:00+02:00"
        if (!UvvHelper.areDatesEqual(a.naechstePruefung, b.naechstePruefung)) {
            return false;
        }

        if (!UvvHelper.areDatesEqual(a.letztePruefung, b.letztePruefung)) {
            return false;
        }

        return true;
    }

    static areDatesEqual(a: string, b: string) {
        if (!a || !b) {
            return false;
        }

        try {
            const days = moment(a).diff(moment(b), 'days');

            if (days == 0) {
                return true;
            }
        } catch (err) {
            UvvHelper.log.warn('areDatesEqual: ' + err.message);
        }

        return false;
    }
}
