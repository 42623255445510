import { Injectable, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Auftragsstatus } from './model/aufteagsstatus';
import { SyncResult } from './model/sync-result';
import { RemoteService } from './remote.service';
import { AuftragDto, AuftragUpdatePushMessage, DownloadFile, AuftragEx, StatusmeldungZusatzdaten, AuftragListItem, UnterschriftEx, PositionsInfo, SyncAuftragStatus, KlingenDaten, ZeitEintragEx, SpeichereAuftragOptions } from './model/model';
import { File, Entry } from '@ionic-native/file/ngx';

import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { Logger } from './helper/app-error-logger';
import { AppConfig } from './helper/app.config';
import { SystemService } from './system.service';
import { NotificationEventResponse } from '@ionic-native/push/ngx';
import { StammdatenService } from './stammdaten.service';
import { FileHelper } from './helper/file-helper';
import { App } from './helper/app';
import { AlertController, ModalController, Platform, NavController, ToastController } from '@ionic/angular';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Textdatei, WorkflowStatus, Auftrag, ReCoMobilBild, BezahlartTyp, Workflow, Auftragstyp, TextdateiTyp, Auftragsposition, MengeOptionTyp, AuftragHeader, Empfangsbestaetigung, EmpfangsbestaetigungElementTyp, EmpfangsbestaetigungStatusTyp, ZeitEintrag, ArbeitsartKonfiguration, TourTyp, ZeitEintragZuordnungTyp } from './model/swagger-model';
import { AppStorage } from './helper/app-storage';
import { Mutex } from 'async-mutex';

import * as moment from 'moment';
import * as pako from 'pako';
import { Utils } from './helper/utils';
import { AuftragHelper } from './helper/auftrag-helper';
import { UiHelper } from './helper/ui-helper';
import { Constants } from './model/constants';
import { AppService, IAuftragService } from './helper/app-services';
import { Zeiterfassung } from './helper/zeiterfassung-helper';

declare var cordova: any;

@Injectable({
    providedIn: 'root'
})
export class AuftragService implements IAuftragService {
    static instance: AuftragService;

    private log = new Logger("AuftragService");

    auftraegeGeaendert = new Subject<void>();
    auftragGeaendert = new Subject<AuftragListItem | AuftragEx>();
    auftragGeloescht = new Subject<AuftragListItem | AuftragEx>();
    aktuellerauftragWurdeGeaendert = new Subject<void>();
    doKlingeln = new Subject<KlingenDaten>();

    anzahlAktuelleTransportauftraege = new BehaviorSubject<number>(0);
    anzahlAktuelleHofcheckerAuftraege = new BehaviorSubject<number>(0);
    anzahlAktuelleKommissionierAuftraege = new BehaviorSubject<number>(0);
    anzahlAktuelleBeladungAuftraege = new BehaviorSubject<number>(0);

    alleAuftraege: AuftragListItem[] = null;

    neueAuftraege = new BehaviorSubject<Auftrag[]>([]);

    /**
     * Liste mit Push-Notifications die noch verarbeitet werden müssen.
     */
    notitifcationZurVerarbeitung: NotificationEventResponse[] = [];

    neuerAuftragAlert: HTMLIonAlertElement = null;

    auftraegeLadenPromise: Promise<AuftragListItem[]>;
    letzterEmpfangenerAuftrag: Auftrag;
    aktiverAuftrag: Auftrag = null;

    syncAuftraegeMitServerPromise: Promise<SyncResult> = null;

    auftragSyncErforderlich = false;

    mutex = new Mutex();
    getAlleAuftraegeMutex = new Mutex();
    aktualisiereAuftragListItemMutex = new Mutex();

    syncAuftragStatus = new BehaviorSubject<SyncAuftragStatus>(null);

    /**
     * List mit Empfangsbestaetigungen die noch geschickt werden müssen
     */
    empfangsbestaetigungen: Empfangsbestaetigung[] = [];
    empfangsbestaetigungTimeout: any;

    letzterAuftragSyncDatum = 0;
    klingelnSubscription: Subscription;

    klingelnIndex = 0;

    /**
     * Der Key des unterbrochenen Auftrags als Tour-Pause gemeldet wurde
     */
    zeiterfassungLetzterAuftragKey: string = null;

    constructor(
        private storage: Storage,
        private remoteService: RemoteService,
        private stammdatenService: StammdatenService,
        private alertCtrl: AlertController,
        private modalController: ModalController,
        private nav: NavController,
        private ngZone: NgZone,
        private toastController: ToastController,
        private platform: Platform,
        private file: File,
        private backgroundMode: BackgroundMode,
        private systemService: SystemService) {

        AppService.auftrag = this;
        AuftragService.instance = this;
    }

    async init() {
        this.systemService.auftraegeAktualisieren.subscribe(() => this.syncAuftraegeMitServer('auftraegeAktualisieren'));
        this.systemService.auftragUpdate.subscribe((pushMessage) => this.verarbeiteAuftragUpdatePushMessage(pushMessage));

        this.systemService.pushNotificationEmpfangen.subscribe(notification => {
            if (notification.additionalData) {
                const typ = notification.additionalData.typ;

                if (typ === "auftrag") {
                    this.notitifcationZurVerarbeitung.push(notification);
                    this.verarbeitePushNotifications();
                }
            }
        });

        this.ladeAuftraegeVonStorage();

        await App.ready();

        setInterval(() => this.update(), 60000);

        setInterval(() => this.bereinigeDaten(), 60 * 60 * 1000);

        setTimeout(() => this.bereinigeDaten(), 30000);
    }

    /**
     * Löscht alte, archivierte Aufträge
     */
    async bereinigeDaten() {
        this.log.info('bereinigeDaten');

        const release = await this.mutex.acquire();

        const loeschListe: AuftragListItem[] = [];
        try {
            const maxAlterInTagen = App.current.alteAuftraegeLoeschenNachTagen.getValue();

            this.log.debug(`AlteAuftraegeLoeschenNachTagen: ${maxAlterInTagen}`);

            if (maxAlterInTagen <= 0) {
                return;
            }

            const auftraege = await this.getAlleAuftraegeListItems();

            if (auftraege) {
                const abgeschlosseneAuftraege = auftraege.filter(p => p.Auftragsstatus >= Auftragsstatus.Abgeschlossen);

                const now = moment();

                for (const auftrag of abgeschlosseneAuftraege) {
                    let loeschen = false;

                    try {
                        const datum = moment(auftrag.Statuszeit);
                        const alter = moment.duration(now.diff(datum));

                        const alterInTagen = alter.asDays();

                        if (alterInTagen > maxAlterInTagen) {
                            loeschen = true;
                        }
                    } catch (err) {
                        this.log.error('bereinigeDaten (2): ' + Utils.getErrorMessage(err));
                        loeschen = true;
                    }

                    if (loeschen) {
                        loeschListe.push(auftrag);
                    }
                }
            }
        } catch (err) {
            this.log.error('bereinigeDaten: ' + Utils.getErrorMessage(err), err);
        } finally {
            release();
        }

        if (loeschListe.length) {
            for (const auftragListItem of loeschListe) {
                try {
                    this.log.info('Alter Auftrag wird gelöscht: ' + auftragListItem.Key);
                    await this.loescheAuftragListItem(auftragListItem);
                } catch (err) {
                    this.log.error('bereinigeDaten (3): ' + Utils.getErrorMessage(err));
                }
            }
        }
    }

    async update() {
        try {
            this.verarbeitePushNotifications();
        } catch (err) {
            this.log.error('update (1): ' + Utils.getErrorMessage(err));
        }

        try {
            if (!this.auftragSyncErforderlich) {
                // Das Intervall ist in Minuten (Standard: 30)
                const aktualisierenIntervall = Utils.parseInt(AppConfig.current.einstellungen.AuftraegeSynchronisierenIntervall);

                if (aktualisierenIntervall) {
                    const intervallMillisekunden = aktualisierenIntervall * 60 * 1000;
                    const now = Date.now();

                    if (now >= this.letzterAuftragSyncDatum + intervallMillisekunden) {
                        const von = AppConfig.current.einstellungen.AuftraegeSynchronisierenUhrzeitVon;
                        const bis = AppConfig.current.einstellungen.AuftraegeSynchronisierenUhrzeitBis;
                        const uhrzeit = moment().format('HH:mm');

                        if (uhrzeit >= von && uhrzeit <= bis) {
                            this.log.info('Aufträge synchronisieren Intervall');
                            this.letzterAuftragSyncDatum = now;
                            this.auftragSyncErforderlich = true;
                        }
                    }
                }
            }
        } catch (err) {
            this.log.error('update (AuftraegeSynchronisierenIntervall): ' + Utils.getErrorMessage(err));
        }

        try {
            if (this.auftragSyncErforderlich) {
                this.log.info('auftragSyncErforderlich erforderlich');

                if (this.systemService.isConnectedToServer()) {
                    this.syncAuftraegeMitServer('update');
                }
            }
        } catch (err) {
            this.log.error('update (2): ' + Utils.getErrorMessage(err));
        }

        if (this.empfangsbestaetigungen.length && !this.empfangsbestaetigungTimeout) {
            this.log.warn('Sende Empfangsbestaetigungen ohne laufenden Timeout.');
            this.starteEmpfangsbestaetigungTimeout();
        }
    }

    async getAuftrag(key: string, remoteErlauben = true, cache = true): Promise<AuftragEx> {
        let auftrag = await AppStorage.current.get('auftrag-' + key, cache) as AuftragEx;

        if (App.loescheAlleDatenAktiv) {
            this.log.warn('getAuftrag. Lösche alle Daten läuft. return NULL: ' + key);
            return null;
        }

        if (auftrag && auftrag.Auftragsstatus == 95) {
            // Auftrag ist gelöscht
            auftrag = null;
        }

        if (!auftrag && this.alleAuftraege) {
            this.log.info('Auftrag nicht gefunden: ' + key);

            const listItem = this.alleAuftraege.find(p => p.Key == key);

            if (listItem && listItem.Auftragsstatus < 90) {
                this.log.warn(`Auftrag ${key} nicht gefunden. ListItem aber vorhanden. Daten sind kaputt. Kompletten sync anstoßen...`);

                const ok = await UiHelper.confirmJaNein('Es wurde ein Fehler in den Auftragsdaten festgestellt. Aufträge neu synchronisieren?');

                if (!ok) {
                    return null;
                }

                this.alleAuftraege = [];
                await this.syncAuftraegeMitServer('getAuftrag: ' + key);

                auftrag = await AppStorage.current.get('auftrag-' + key, cache) as AuftragEx;
            }

            // const listItem = this.alleAuftraege.find(p => p.Key == key);

            // if (listItem) {
            //     this.log.warn(`Auftrag ${key} nicht gefunden. Verwende ListItem. Sollte bald nicht mehr nötig sein!`);
            //     auftrag = listItem as any;

            //     // Hier kein Await wg. Performance
            //     this.speichereAuftrag(auftrag);
            // }
        }

        if (auftrag) {
            this.fixAuftrag(auftrag);
            this.aktualisierePreise(auftrag, 'getAuftrag');
        }

        return auftrag;
    }

    async getAktuelleAuftraege(auftragstyp: Auftragstyp, auftragOberKey: string = null): Promise<AuftragListItem[]> {
        let auftraege = await this.getAlleAuftraegeListItems(auftragstyp);

        let aktuelleAuftraege = this.filterAktuelleAuftraege(auftraege, auftragstyp, auftragOberKey);

        return aktuelleAuftraege;
    }

    filterAktuelleAuftraege(auftraege: AuftragListItem[], auftragstyp: Auftragstyp, auftragOberKey: string = null): AuftragListItem[] {
        if (auftragOberKey) {
            auftraege = auftraege.filter(p => p.OberAuftragKey === auftragOberKey);
        } else {
            auftraege = auftraege.filter(p => !p.OberAuftragKey);
        }

        let aktuelleAuftraege = auftraege.filter(p => p.Auftragsstatus < Auftragsstatus.Abgeschlossen);

        if (auftragstyp == Auftragstyp.Transportauftrag) {
            const tourModus = AppConfig.current.einstellungen.TourStartModus;

            if (tourModus == 'auswaehlen' || tourModus == 'scannen') {
                const tourOptional = AppConfig.current.einstellungen.TourAuswahlOptional == 'ja';

                // Beim Tour-Start wird eine Tour explizit ausgewählt. Es sollen dann nur die Aufträge zur aktuellen Tour angezeigt werden
                let aktuelleTour = App.current.aktuelleTour.getValue();

                if (!aktuelleTour) {
                    aktuelleTour = App.current.aktuellGeladeneTour.getValue();
                }

                if (aktuelleTour && aktuelleTour.TourKeys && aktuelleTour.TourKeys.length > 1) {
                    aktuelleAuftraege = aktuelleAuftraege.filter(p => aktuelleTour.TourKeys.includes(p.TourKey));
                } else if (aktuelleTour && aktuelleTour.TourKey && aktuelleTour.TourTyp == TourTyp.Standard) {
                    aktuelleAuftraege = aktuelleAuftraege.filter(p => p.TourKey == aktuelleTour.TourKey);
                } else {
                    // TODO: Dieser Kommentar muss nochmal überarbeitet werden
                    // Tour ist nicht erforderlich und es wurde auch keine Tour ausgewählt.
                    // Es sollen nur Aufträge angezeigt werden die dem Fahrzeug direkt zugeordnet wurden
                    // und nicht zu einer Tour gehören
                    if (tourOptional) {
                        // Es muss nicht zwingend eine Tour ausgewählt werden, d.h. der Benutzer hat beim Tour-Start z.B. "Ohne Tour" ausgewählt.
                        // Es sollen hier nur die Aufträge angezeigt werden die:
                        // - Keiner Tour zugeordnet sind
                        // - Der aktuellen Tour zugeordnet sind
                        // - die aktuelle Tour eine Ad-Hoc-Tour ist und der Auftrag auch einer Ad-Hoc-Tour zugeordnet ist.
                        // Speziell der letzte Fall ist wichtig, wenn ein Auftrag in einer AdHoc-Tour angelegt wurde, dann die Tour beendet und erneut (ohne Tour) gestartet wird.
                        // Dann stimmen die TourKeys nicht mehr. Der angelegte Auftrag muss aber noch angezeigt werden.
                        if (aktuelleTour) {
                            if (aktuelleTour.TourTyp === TourTyp.AdHoc) {
                                aktuelleAuftraege = aktuelleAuftraege.filter(p =>
                                    !p.TourKey
                                    || p.TourKey == aktuelleTour.TourKey
                                    || (p.TourKey && Utils.isAdHocTourKey(p.TourKey))
                                );
                            } else {
                                // Es wurde eine "Standard"-Tour ausgewählt.
                                // Nur die Aufträge anzeigen, die auch dieser Tour zugeordnet sind.
                                aktuelleAuftraege = aktuelleAuftraege.filter(p => !p.TourKey || p.TourKey == aktuelleTour.TourKey);
                            }
                        } else {
                            // Es ist keine Tour ausgewählt. Deshalb nur Aufträge anzeigen die keiner Tour zugeordnet sind.
                            // TODO: Eventuell auch alle Aufträge von AdHoc-Touren anzeigen ??
                            aktuelleAuftraege = aktuelleAuftraege.filter(p => !p.TourKey);
                        }
                    } else {
                        // Der Anwender muss erst eine Tour auswählen bevor er Aufträge sehen darf.
                        aktuelleAuftraege = [];
                    }
                }
            } else if (AppConfig.current.einstellungen.AuftragFahrzeugFilterAktiv === 'ja') {
                const fahrzeugKennzeichen = App.current.getFahrzeugKennzeichen();

                if (fahrzeugKennzeichen) {
                    // Nur Aufträge für das angemeldete Fahrzeug anzeigen
                    aktuelleAuftraege = aktuelleAuftraege.filter(p => p.FahrzeugKennung == fahrzeugKennzeichen || p.FahrzeugKennung2 == fahrzeugKennzeichen || p.FahrzeugKennung3 == fahrzeugKennzeichen);
                }
            }

            this.anzahlAktuelleTransportauftraege.next(aktuelleAuftraege.length);
        }

        return aktuelleAuftraege;
    }

    async aktualisiereAnzahlAktuelleAuftraege() {
        const aktuelleTransportauftraege = await this.getAktuelleAuftraege(Auftragstyp.Transportauftrag);
        const aktuelleHofauftraege = await this.getAktuelleAuftraege(Auftragstyp.Hofchecker);
        const aktuelleKommissionierAuftraege = await this.getAktuelleAuftraege(Auftragstyp.Kommissionierung);

        this.anzahlAktuelleTransportauftraege.next(aktuelleTransportauftraege.length);
        this.anzahlAktuelleHofcheckerAuftraege.next(aktuelleHofauftraege.length);
        this.anzahlAktuelleKommissionierAuftraege.next(aktuelleKommissionierAuftraege.filter(p => !p.OberAuftragKey).length);

        // TODO
        this.anzahlAktuelleBeladungAuftraege.next(0);
    }

    async getArchivierteAuftraege(auftragstyp: Auftragstyp): Promise<AuftragListItem[]> {
        const auftraege = await this.getAlleAuftraegeListItems(auftragstyp);

        return auftraege
            .filter(p => p.Auftragsstatus >= Auftragsstatus.Abgeschlossen && p.Auftragsstatus < Auftragsstatus.Geloescht)
            .filter(p => !p.OberAuftragKey);
    }

    async getUnterauftragListItems(oberAuftragKey: string): Promise<AuftragListItem[]> {
        this.log.debug('getUnterauftragListItems: ' + oberAuftragKey);

        const alleAuftraege = await this.getAlleAuftraegeListItems();

        const oberAuftrag = await this.getAuftrag(oberAuftragKey, true);

        if (!oberAuftrag) {
            this.log.warn('Oberauftrag nicht gefunden: ' + oberAuftragKey);
            return [];
        }

        let unterauftraege: AuftragListItem[];

        if (oberAuftrag.UnterauftragKeys) {
            unterauftraege = [];

            for (const key of oberAuftrag.UnterauftragKeys) {
                const auftrag = alleAuftraege.find(p => p.Key === key && p.Auftragsstatus != Auftragsstatus.Geloescht);

                if (auftrag) {
                    unterauftraege.push(auftrag);
                } else {
                    this.log.warn('Unterauftrag nicht gefunden: ' + key);
                }
            }
        } else {
            // Fallback. Sollte eigentlich nicht mehr benötigt werden
            this.log.warn('Oberauftrag hat keine UnterauftragKeys-Eigenschaft: ' + oberAuftragKey);
            unterauftraege = alleAuftraege.filter(p => p.OberAuftragKey === oberAuftragKey && p.Auftragsstatus != Auftragsstatus.Geloescht);
        }

        if (AppConfig.current.einstellungen.KommissionierungPaletteLabel === 'stellplatz') {
            for (const a of unterauftraege) {
                a.label = a.stellplatzLkw;
            }
        } else {
            for (const a of unterauftraege) {
                a.label = a.Info1;
            }
        }

        unterauftraege.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            } else if (a.label > b.label) {
                return 1;
            }

            return 0;
        });

        return unterauftraege;
    }

    async getAlleAuftraegeListItems(auftragstyp: Auftragstyp = 999): Promise<AuftragListItem[]> {
        // this.log.debug('getAlleAuftraegeListItems: ' + auftragstyp);

        if (auftragstyp === Auftragstyp.Unbekannt) {
            this.log.error('auftragstyp is Unbekannt. Bitte Aufruf prüfen !');
        }

        const release = await this.getAlleAuftraegeMutex.acquire();

        try {
            let auftraegeListItems = this.alleAuftraege;

            if (!auftraegeListItems) {
                auftraegeListItems = await this.ladeAuftraegeVonStorage();

                if (!auftraegeListItems) {
                    auftraegeListItems = [];
                }

                for (const listItem of auftraegeListItems) {
                    this.fixAuftragListItem(listItem);
                    this.aktualisiereSortFelder(listItem);
                }

                this.alleAuftraege = auftraegeListItems;
            }

            if (auftragstyp !== 999) {
                auftraegeListItems = auftraegeListItems.filter(p => p.Auftragstyp === auftragstyp);
            }

            for (const item of auftraegeListItems) {
                item.istZugewiesen = item.Geraet === AppConfig.current.geraeteNummer && item.Auftragsstatus >= Auftragsstatus.Zugewiesen;
                item.istAnderemGraetZugewiesen = item.Geraet && item.Geraet !== AppConfig.current.geraeteNummer;
                item.oldIstZugewiesen = item.istZugewiesen;
            }

            const geloescht = this.loescheDoppelteAuftraege(auftraegeListItems);

            if (geloescht) {
                await AppStorage.current.set(Constants.AUFTRAEGE, auftraegeListItems);

                this.alleAuftraege = auftraegeListItems;
            }

            return auftraegeListItems;
        } finally {
            release();
        }
    }

    async ladeAuftraegeVonStorage(): Promise<AuftragListItem[]> {
        if (!this.auftraegeLadenPromise) {
            this.log.info("Lade Aufträge vom Storage");
            this.auftraegeLadenPromise = AppStorage.current.get(Constants.AUFTRAEGE);
        }

        let auftraege = await this.auftraegeLadenPromise;

        if (!auftraege) {
            auftraege = [];
        }

        this.log.debug("Lade Aufträge vom Storage (fertig): " + auftraege.length);

        return auftraege;
    }

    async speichereAuftrag(auftrag: AuftragEx, optionen: SpeichereAuftragOptions = null): Promise<void> {
        this.log.info('speichereAuftrag: ' + auftrag.Key);

        const defaultOptionen: SpeichereAuftragOptions = {
            listItemAktualisieren: true
        };

        optionen = Object.assign(defaultOptionen, optionen);

        if (App.loescheAlleDatenAktiv) {
            this.log.warn('Lösche alle Daten läuft. Auftrag wird nicht gespeichert: ' + auftrag.Key);
            return;
        }

        this.fixAuftrag(auftrag);

        // Stelle sichere, dass keine Bild-Daten enthalten sind. Bilder werden extra gespeichert.
        if (auftrag.Auftragsbilder) {
            for (const bild of auftrag.Auftragsbilder) {
                bild.Bild = null;
            }
        }

        // if (auftrag.Positionen) {
        //     for (const position of auftrag.Positionen) {
        //         if (position.Eigenschaften) {
        //             for (const eigenschaft of position.Eigenschaften) {
        //                 // if (eigenschaft.Typ == 'datum') {
        //                 //     eigenschaft.Wert = Utils.toIsoDateString(eigenschaft.Wert);
        //                 // }
        //             }
        //         }
        //     }
        // }

        AppStorage.current.set('auftrag-' + auftrag.Key, auftrag, true, false);

        if (optionen.listItemAktualisieren) {
            await this.aktualisiereAuftragListItem(auftrag);
        }
    }

    async aktualisiereAuftragListItem(auftrag: AuftragEx) {
        this.log.debug('aktualisiereAuftragListItem: ' + auftrag.Key);

        const release = await this.aktualisiereAuftragListItemMutex.acquire();

        try {
            this.log.debug('aktualisiereAuftragListItem mutex erhalten: ' + auftrag.Key);

            const listItems = await this.getAlleAuftraegeListItems();
            const auftragListItem = this.erstelleAuftragListItem(auftrag);

            const index = listItems.findIndex(p => p.Key === auftrag.Key);

            if (index >= 0) {
                this.log.debug(`aktualisiereAuftragListItem Auftrag ${auftrag.Key} bereits in Liste vorhanden an Index ${index}`);

                const oldItem = listItems[index];

                if (JSON.stringify(oldItem) === JSON.stringify(auftragListItem)) {
                    // Keine Aktualisierung notwendig
                    return;
                }

                listItems[index] = auftragListItem;
            } else {
                this.log.debug(`aktualisiereAuftragListItem Auftrag ${auftrag.Key} ist neu`);
                listItems.push(auftragListItem);
            }

            this.fixAuftragListItem(auftragListItem);

            await this.speichereLokaleAuftraege(listItems);

            this.auftragGeaendert.next(auftragListItem);
        } finally {
            release();
        }
    }

    async speichereAuftragListItems() {
        this.log.debug('speichereAuftragListItems');

        const release = await this.aktualisiereAuftragListItemMutex.acquire();

        try {
            this.log.debug('aktualisiereAuftragListItem mutex erhalten');

            const listItems = await this.getAlleAuftraegeListItems();

            await this.speichereLokaleAuftraege(listItems);
        } finally {
            release();
        }
    }

    getAuftragListItem(key: string) {
        const listItem = this.alleAuftraege.find(p => p.Key === key);

        if (listItem) {
            this.fixAuftragListItem(listItem);
        }

        return listItem;
    }

    erstelleAuftragListItem(auftrag: AuftragEx): AuftragListItem {
        const positionsInfo: PositionsInfo[] = [];

        if (auftrag.Positionen) {
            for (const position of auftrag.Positionen) {
                let data = positionsInfo.find(p => p.artikelKey == position.ArtikelKey);

                if (!data) {
                    data = {
                        artikelKey: position.ArtikelKey,
                        bezeichnung: position.Bezeichnung,
                        menge: 0,
                        einheit: position.Einheit
                    };

                    positionsInfo.push(data);
                }

                if (position.Menge) {
                    data.menge += position.Menge;
                }
            }
        }

        if (auftrag.Zusatzdaten?.IstVollePalette) {
            auftrag.istVollePalette = Utils.isTrue(auftrag.Zusatzdaten?.IstVollePalette);
        }

        auftrag.label = this.getAuftragLabel(auftrag);

        const app = App.current;

        const listItem: AuftragListItem = {
            Key: auftrag.Key,
            OberAuftragKey: auftrag.OberAuftragKey,
            TourKey: auftrag.TourKey,
            Statuszeit: auftrag.Statuszeit,
            Auftragsstatus: auftrag.Auftragsstatus,
            Auftragsnummer: auftrag.Auftragsnummer,
            Reihenfolge: auftrag.Reihenfolge,
            Geraet: auftrag.Geraet,
            GeraeteGruppe: auftrag.GeraeteGruppe,
            Personalnummer: auftrag.Personalnummer,
            PersonalKey: auftrag.PersonalKey,
            Auftragstyp: auftrag.Auftragstyp,
            AAbholdatum: auftrag.AAbholdatum,
            AAbholzeitVon: auftrag.AAbholzeitVon,
            AAbholzeitBis: auftrag.AAbholzeitBis,
            AbholzeitText: auftrag.AbholzeitText,
            AErfasser: auftrag.AErfasser,
            Abholadresse: auftrag.Abholadresse,
            FahrzeugKennung: auftrag.FahrzeugKennung,
            istVollePalette: auftrag.istVollePalette,
            FahrzeugKennung2: auftrag.FahrzeugKennung2,
            FahrzeugKennung3: auftrag.FahrzeugKennung3,
            Info1: Utils.stripDotted(auftrag.Info1, app.auftragInfoTextMaxLaenge),
            Info2: Utils.stripDotted(auftrag.Info2, app.auftragInfoTextMaxLaenge),
            Info3: Utils.stripDotted(auftrag.Info3, app.auftragInfoTextMaxLaenge),
            UnterMandant: auftrag.UnterMandant,
            suchtext: AuftragHelper.erstelleSuchtext(auftrag),
            metadaten: auftrag.metadaten,
            istListItem: true,
            Gelesen: auftrag.Gelesen,
            itemHeight: null,
            info1MaxHeight: null,
            info2MaxHeight: null,
            info3MaxHeight: null,
            IstNeuanlage: auftrag.IstNeuanlage,
            Icon: auftrag.Icon,
            positionsInfo,
            istAusgeblendet: false,
            stellplatzLkw: auftrag.Zusatzdaten?.StellplatzLkw,
            PaletteId: auftrag.Zusatzdaten?.PaletteId,
            Zusatzdaten: auftrag.Zusatzdaten,
            label: auftrag.label,
            auftragsFarbe: auftrag.auftragsFarbe,
            zeiterfassungLaeuft: auftrag.zeiterfassungLaeuft,
            zeiterfassungUnterbrochen: auftrag.zeiterfassungUnterbrochen,
            GruppeKey: auftrag.GruppeKey,
            GruppeBezeichnung: auftrag.GruppeBezeichnung,
            ERPStatusFarbe: auftrag.ERPStatusFarbe,
            manuelleReihenfolge: auftrag.manuelleReihenfolge,
        };

        this.aktualisiereSortFelder(listItem);

        return listItem;
    }

    getAuftragLabel(auftrag: AuftragEx | AuftragListItem): string {
        if (auftrag.OberAuftragKey && auftrag.Auftragstyp === Auftragstyp.Kommissionierung) {
            // Es handelt sich um eine Palette

            if (AppConfig.current.einstellungen.KommissionierungPaletteLabel === 'stellplatz') {
                if (auftrag.Zusatzdaten?.StellplatzLkw) {
                    return auftrag.Zusatzdaten?.StellplatzLkw;
                }
            } else {
                return auftrag.Info1;
            }
        }

        return auftrag.Info1;
    }

    aktualisiereSortFelder(auftrag: AuftragListItem) {
        if (!auftrag.abholdatumTimestamp) {
            const m = AuftragHelper.getAbholDatumMoment(auftrag);

            if (m) {
                // https://momentjs.com/docs/
                auftrag.abholdatumTimestamp = +m.valueOf();
            } else {
                // Kein Abholdatum. Setze auf einen kleinen Wert
                auftrag.abholdatumTimestamp = 1;
            }
        }

        if (!auftrag.abholuhrzeitTimestamp) {
            const m = AuftragHelper.getAbholUhrzeitMoment(auftrag);

            if (m) {
                // https://momentjs.com/docs/
                auftrag.abholuhrzeitTimestamp = +m.valueOf();
            } else {
                // Kein Abholdatum. Setze auf einen kleinen Wert
                auftrag.abholuhrzeitTimestamp = 1;
            }
        }
    }

    async loescheAuftragListItem(listItem: AuftragListItem): Promise<void> {
        this.log.info('loescheAuftragListItem: ' + listItem.Key);

        const auftrag = await this.getAuftrag(listItem.Key, false);

        if (auftrag) {
            await this.loescheAuftrag(auftrag);
        } else {
            this.log.warn('Auftrag nicht gefunden: ' + listItem.Key);

            const release = await this.mutex.acquire();

            try {
                const auftraege = await this.getAlleAuftraegeListItems();

                const index = auftraege.findIndex(p => p && p.Key === listItem.Key);

                if (index >= 0) {
                    auftraege.splice(index, 1);

                    await this.speichereLokaleAuftraege(auftraege);
                }

                AppStorage.current.remove('auftrag-' + listItem.Key);
            } finally {
                release();
            }
        }
    }

    async loescheAuftrag(auftrag: Auftrag): Promise<void> {
        this.log.info('loescheAuftrag: ' + auftrag.Key);

        const release = await this.mutex.acquire();

        this.log.info('loescheAuftrag: mutex erhalten: ' + auftrag.Key);

        try {
            const auftragListItems = await this.getAlleAuftraegeListItems();

            if (auftrag.Auftragsbilder) {
                for (const bild of auftrag.Auftragsbilder) {
                    this.log.debug('loescheAuftrag lösche Bild: ' + bild.BildGuid);

                    await this.loescheBild(bild.BildGuid);
                }
            }

            const loeschKeys: string[] = [];
            loeschKeys.push(auftrag.Key);

            if (auftrag.UnterauftragKeys?.length) {
                // Lösche auch die Unteraufträge
                for (const key of auftrag.UnterauftragKeys) {
                    loeschKeys.push(key);
                }
            }

            for (const key of loeschKeys) {
                const index = auftragListItems.findIndex(p => p.Key === key);

                if (index >= 0) {
                    this.log.info(`loescheAuftrag ${key} an index=${index}`);

                    auftragListItems.splice(index, 1);
                }

                AppStorage.current.remove('auftrag-' + key);
            }

            await this.speichereLokaleAuftraege(auftragListItems);
        } catch (err) {
            this.log.error('loescheAuftrag: ' + Utils.getErrorMessage(err));
        } finally {
            release();
        }
    }

    private fixAuftragListItem(auftrag: AuftragListItem) {
        if (!auftrag.Key && auftrag.Auftragsnummer) {
            auftrag.Key = auftrag.Auftragsnummer.toString();
        }

        if ((auftrag as any).Aboladresse) {
            auftrag.Abholadresse = (auftrag as any).Aboladresse;
        }

        if (!auftrag.Auftragsstatus) {
            auftrag.Auftragsstatus = Auftragsstatus.Neu;
        }

        if (!auftrag.Abholadresse) {
            auftrag.Abholadresse = {
                Name1: 'n/a'
            };
        }

        if (!auftrag.metadaten) {
            auftrag.metadaten = {
                empfangsDatum: moment().toISOString(),
                modificationDate: moment().toISOString(),
                version: 1
            };
        }

        if (auftrag.Auftragstyp !== null) {
            if (typeof (auftrag.Auftragstyp) === 'string') {
                auftrag.Auftragstyp = parseInt(auftrag.Auftragstyp, 10);
            }
        }

        auftrag.istZugewiesen = auftrag.Geraet === AppConfig.current.geraeteNummer && auftrag.Auftragsstatus >= Auftragsstatus.Zugewiesen;
        auftrag.istAnderemGraetZugewiesen = auftrag.Geraet && auftrag.Geraet !== AppConfig.current.geraeteNummer;
        auftrag.oldIstZugewiesen = auftrag.istZugewiesen;

        if (!auftrag.Reihenfolge) {
            auftrag.Reihenfolge = 0;
        }

        if (!auftrag.manuelleReihenfolge) {
            auftrag.manuelleReihenfolge = 0;
        }

        const app = App.current;

        if (auftrag.Info1 && auftrag.Info1.length > app.auftragInfoTextMaxLaenge) {
            auftrag.Info1 = Utils.stripDotted(auftrag.Info1, app.auftragInfoTextMaxLaenge);
        }

        if (auftrag.Info2 && auftrag.Info2.length > app.auftragInfoTextMaxLaenge) {
            auftrag.Info2 = Utils.stripDotted(auftrag.Info2, app.auftragInfoTextMaxLaenge);
        }

        if (auftrag.Info3 && auftrag.Info3.length > app.auftragInfoTextMaxLaenge) {
            auftrag.Info3 = Utils.stripDotted(auftrag.Info3, app.auftragInfoTextMaxLaenge);
        }

        if (auftrag.Zusatzdaten?.IstVollePalette) {
            auftrag.istVollePalette = Utils.isTrue(auftrag.Zusatzdaten?.IstVollePalette);
        }

        auftrag.label = this.getAuftragLabel(auftrag);
    }

    private fixAuftrag(auftrag: AuftragEx) {
        this.log.debug('fixAuftrag: ' + auftrag.Key);

        if (!auftrag.Key && auftrag.Auftragsnummer) {
            auftrag.Key = auftrag.Auftragsnummer.toString();
        }

        if ((auftrag as any).Aboladresse) {
            auftrag.Abholadresse = (auftrag as any).Aboladresse;
        }

        if (!auftrag.Auftragsstatus) {
            auftrag.Auftragsstatus = Auftragsstatus.Neu;
        }

        if (!auftrag.Abholadresse) {
            auftrag.Abholadresse = {
                Name1: 'n/a'
            };
        }

        if (!auftrag.Verwerteradresse) {
            auftrag.Verwerteradresse = {};
        }

        if (!auftrag.Erzeugeradresse) {
            auftrag.Erzeugeradresse = {};
        }

        if (!auftrag.metadaten) {
            auftrag.metadaten = {
                empfangsDatum: moment().toISOString(),
                modificationDate: moment().toISOString(),
                version: 1
            };
        }

        if (!auftrag.Optionen) {
            auftrag.Optionen = {
                UnterschriftErforderlich: 'nein',
                BildErforderlich: 'ja'
            };
        }

        if (!auftrag.Anlagen) {
            auftrag.Anlagen = [];
        }

        if (!auftrag.Hinweistexte) {
            auftrag.Hinweistexte = [];
        }

        if (!auftrag.statusmeldungen) {
            auftrag.statusmeldungen = [];
        }

        if (!auftrag.manuelleReihenfolge) {
            auftrag.manuelleReihenfolge = 0;
        }

        if (!auftrag.Unterschriften) {
            auftrag.Unterschriften = [];
        }

        if (auftrag.Auftragstyp !== null) {
            if (typeof (auftrag.Auftragstyp) === 'string') {
                auftrag.Auftragstyp = parseInt(auftrag.Auftragstyp, 10);
            }
        }

        if (!auftrag.Positionen) {
            auftrag.Positionen = [];
        }

        if (!auftrag.RetourPositionen) {
            auftrag.RetourPositionen = [];
        }

        if (!auftrag.Auftragsbilder) {
            auftrag.Auftragsbilder = [];
        }

        if (!auftrag.Protokoll) {
            auftrag.Protokoll = [];
        }

        if (auftrag.Zusatzdaten?.IstVollePalette) {
            auftrag.istVollePalette = Utils.isTrue(auftrag.Zusatzdaten?.IstVollePalette);
        }

        auftrag.label = this.getAuftragLabel(auftrag);

        this.updateStatus(auftrag);

        // Zahlen-Felder von evtl. String in Number umwandeln
        for (const position of auftrag.Positionen) {
            if (typeof (position.Menge) === 'string' && position.Menge !== '') {
                position.Menge = Utils.parseFloat(position.Menge);
            }

            if (typeof (position.MengeSoll) === 'string' && position.MengeSoll !== '') {
                position.MengeSoll = Utils.parseFloat(position.MengeSoll);
            }

            if (typeof (position.MengeIst) === 'string' && position.MengeIst !== '') {
                position.MengeIst = Utils.parseFloat(position.MengeIst);
            }

            if (typeof (position.GewichtInKg) === 'string' && position.GewichtInKg !== '') {
                position.GewichtInKg = Utils.parseFloat(position.GewichtInKg);

                if (isNaN(position.GewichtInKg)) {
                    position.GewichtInKg = null;
                }
            }

            if (position.Eigenschaften) {
                for (const eigenschaft of position.Eigenschaften) {
                    if (eigenschaft.Typ && typeof (eigenschaft.Typ) === 'string') {
                        eigenschaft.Typ = eigenschaft.Typ.trim().toLowerCase();
                    }
                }
            }

            AuftragHelper.sortiereEigenschaften(position.Eigenschaften);

            if (auftrag.Auftragsstatus < 90) {
                if (AppConfig.current.einstellungen.TransportauftragMengeImmerEingeben === 'ja') {
                    if (position.MengeIst === null || typeof (position.MengeIst) === 'undefined') {
                        // Es wurde noch keine Ist-Menge eingegeben
                        this.log.debug('TransportauftragMengeImmerEingeben: Es wurde noch keine Ist-Menge eingegeben');

                        if (position.MengeSoll === null || typeof (position.MengeSoll) === 'undefined') {
                            // Merke die aktuelle Menge als Soll-Menge
                            position.MengeSoll = position.Menge;
                        }

                        position.Menge = null;
                    }
                } else {
                    if (position.Menge === null || typeof (position.Menge) === 'undefined' || (position.Menge as any) === '') {
                        if (position.MengeSoll) {
                            // Soll-Menge ist vorhanden. Es wurde wohl die Einstellung TransportauftragMengeImmerEingeben zurückgesetzt
                            this.log.debug('TransportauftragMengeImmerEingeben: Soll-Menge ist vorhanden');
                            position.Menge = position.MengeSoll;
                        }
                    }
                }
            }
        }
    }

    async speichereLokaleAuftraege(auftraege: AuftragListItem[]): Promise<void> {
        this.log.debug('speichereLokaleAuftraege: ' + auftraege.length);

        this.alleAuftraege = auftraege;

        await AppStorage.current.set(Constants.AUFTRAEGE, auftraege);

        this.log.debug('speichereLokaleAuftraege #1');

        this.auftraegeGeaendert.next();

        await this.aktualisiereAnzahlAktuelleAuftraege();

        // this.anzahlAktuelleTransportauftraege.next(auftraege.filter(p => p.Auftragstyp === Auftragstyp.Transportauftrag && p.Auftragsstatus < Auftragsstatus.Abgeschlossen).length);
        // this.anzahlAktuelleHofcheckerAuftraege.next(auftraege.filter(p => p.Auftragstyp === Auftragstyp.Hofchecker && p.Auftragsstatus < Auftragsstatus.Abgeschlossen).length);

        this.log.debug('speichereLokaleAuftraege (fertig)');
    }

    async syncAuftraegeMitServer(info: string): Promise<SyncResult> {
        if (this.syncAuftraegeMitServerPromise) {
            return this.syncAuftraegeMitServerPromise;
        }

        this.syncAuftraegeMitServerPromise = new Promise(async (resolve, reject) => {
            try {
                this.log.info('syncAuftraegeMitServer: ' + info);

                const status: SyncAuftragStatus = {
                    anzahlGesamt: 0,
                    index: 0,
                    text: 'Verbindung wird hergestellt...'
                };

                this.syncAuftragStatus.next(status);

                // Lade alle aktuellen Aufträge vom Server
                const auftragHeaders: AuftragHeader[] = await this.remoteService.getAktuelleAuftragHeaders();

                if (!auftragHeaders) {
                    this.log.warn('syncAuftraegeMitServer: isInternetAvailable=' + App.isInternetAvailable() + ', info=' + info);

                    this.syncAuftragStatus.next({
                        text: 'Fehler'
                    });

                    reject("Fehler beim Laden der Aufträge vom Server. Bitte Internetverbindung kontrollieren.");
                    return;
                }

                this.letzterAuftragSyncDatum = Date.now();

                status.index = 0;
                status.anzahlGesamt = auftragHeaders.length;
                this.syncAuftragStatus.next(status);

                this.auftragSyncErforderlich = false;

                const lokaleAuftraege = await this.getAlleAuftraegeListItems();

                this.loescheDoppelteAuftraege(lokaleAuftraege);

                this.log.debug('syncAuftraegeMitServer.auftragHeaders (remote): ' + auftragHeaders.length);
                this.log.debug('syncAuftraegeMitServer.lokaleAuftraege (lokal): ' + lokaleAuftraege.length);

                let geaendert = false;
                let anzahlNeu = 0;

                // IDs der aufträge die heruntergeladen werden müssen
                const downloadAuftragsHeader: AuftragHeader[] = [];

                // Prüfe für jeden einzelnen Auftrag ob die lokale Version vorhanden und aktuell ist
                for (const auftragHeader of auftragHeaders) {
                    status.index++;
                    status.text = `${status.index} / ${status.anzahlGesamt}`;
                    this.syncAuftragStatus.next(status);

                    const index = lokaleAuftraege.findIndex(p => p.Key === auftragHeader.key);
                    let lokalerAuftrag: AuftragListItem = null;
                    let downloadErforderlich = true;

                    if (index >= 0) {
                        lokalerAuftrag = lokaleAuftraege[index];

                        // this.log.debug('lokaler Auftrag gefunden für key=' + auftragHeader.key);

                        if (!lokalerAuftrag.metadaten || lokalerAuftrag.metadaten.version < auftragHeader.version) {
                            // Lokale version ist veraltet. lokale Version verwerfen
                            this.log.debug('Lokale version ist veraltet. lokale Version verwerfen key=' + auftragHeader.key);

                            // TODO: Prüfe ob Bearbeitung schon begonnen wurde.
                            // Wenn ja, darf der Auftrag nicht importiert werden. eventuell hinweis anzeigen

                            lokaleAuftraege.splice(index, 1);
                            lokalerAuftrag = null;
                            geaendert = true;
                            downloadErforderlich = true;
                        } else {
                            downloadErforderlich = false;

                            if (lokalerAuftrag.Auftragsstatus < auftragHeader.Auftragsstatus) {
                                lokalerAuftrag.Auftragsstatus = auftragHeader.Auftragsstatus;
                                geaendert = true;
                            }

                            if (lokalerAuftrag.Geraet != auftragHeader.GeraeteNummer) {
                                lokalerAuftrag.Geraet = auftragHeader.GeraeteNummer;
                                geaendert = true;
                            }

                            if (lokalerAuftrag.PersonalKey != auftragHeader.PersonalKey) {
                                lokalerAuftrag.PersonalKey = auftragHeader.PersonalKey;
                                this.log.debug(`PersonalKey geändert ${lokalerAuftrag.Key}: ${lokalerAuftrag.PersonalKey} => ${auftragHeader.PersonalKey}`);
                                geaendert = true;
                            }
                        }
                    }

                    if (auftragHeader.Auftragsstatus == 95) {
                        // Auftrag wurde gelöscht. Kein Download !
                        downloadErforderlich = false;
                    }

                    if (downloadErforderlich) {
                        // Vom Server werden derzeit doppelte Keys geliefert.
                        // Merke alle schon geprüfte Keys, damit Aufträge nicht doppelt heruntergladen werden
                        if (downloadAuftragsHeader.find(p => p.key === auftragHeader.key)) {
                            this.log.warn('Server hat doppelte Aufträge geliefert. Wird ignoriert: ' + auftragHeader.key);
                            continue;
                        }

                        this.log.debug(`Download erforderlich: ${auftragHeader.key}, id=${auftragHeader.id}`);
                        downloadAuftragsHeader.push(auftragHeader);
                    }
                }

                const neueAuftragsListe: AuftragEx[] = [];

                if (downloadAuftragsHeader.length) {
                    this.log.info('Download Aufträge by IDs: ' + downloadAuftragsHeader.length);

                    const resultList = await this.remoteService.downloadAuftraege({
                        IDs: downloadAuftragsHeader.map(p => p.id)
                    });

                    if (resultList) {
                        for (const auftragDto of resultList) {
                            const auftrag = this.entpackeAuftragsdaten(auftragDto);

                            if (auftrag) {
                                // Oeben wird zwar geprüft
                                await this.uebernehmeLokaleAuftragsdaten(auftrag);

                                await this.pruefeAktiverAuftrag(auftrag);

                                // Erstelle Eintrag für die Liste
                                const auftragListItem = this.erstelleAuftragListItem(auftrag);

                                // Speichere den eigentlichen Auftrag
                                this.fixAuftrag(auftrag);

                                AppStorage.current.set('auftrag-' + auftrag.Key, auftrag, false);

                                this.log.debug(`AuftragListItem ${auftrag.Key} zur Liste der lokalen Aufträge hinzufügen`);

                                // Liste aktualisieren
                                lokaleAuftraege.push(auftragListItem);

                                neueAuftragsListe.push(auftrag);

                                anzahlNeu++;
                                geaendert = true;
                            }
                        }
                    }
                }

                // Lösche Aufträge die nicht mehr auf dem Server sind
                for (let i = 0; i < lokaleAuftraege.length; i++) {
                    const auftragListItem = lokaleAuftraege[i];

                    if (auftragListItem) {
                        const index = auftragHeaders.findIndex(p => p.key === auftragListItem.Key && p.Auftragsstatus !== 95);

                        if (index < 0) {
                            // Auftrag nicht mehr auf Server vorhanden.
                            let behalten = false;
                            let behaltenGrund = '';

                            if (auftragListItem.Auftragsstatus === Auftragsstatus.Abgeschlossen) {
                                // Für das Archiv noch etwas aufbewahen
                                behalten = true;
                                behaltenGrund = "Abgeschlossen, für Archiv";
                            }

                            if (auftragListItem.IstNeuanlage) {
                                // Neuanlagen sind nie auf dem Server vorhanden
                                behalten = true;
                                behaltenGrund = "Neuanlage";
                            }

                            if (auftragListItem.Auftragstyp === Auftragstyp.Kommissionierung) {
                                // Aufträge werden auf dem Server erst gelöscht, wenn sie nicht mehr benötigt werden.
                                // Deshalb auch auf der App löschen, auch aus dem Archiv
                                behalten = false;
                            }

                            if (!behalten) {
                                this.log.warn(`Auftrag ${auftragListItem.Key} mit Status ${auftragListItem.Auftragsstatus} ist nicht mehr auf Server vorhanden. Wird gelöscht.`)
                                lokaleAuftraege.splice(i, 1);
                                i--;
                            } else {
                                this.log.info(`Lokaler Auftrag ${auftragListItem.Key} ist nicht mehr auf dem Server vorhanden. Behalten, weil: ${behaltenGrund}`);
                            }
                        }
                    }
                }

                // Lösche fehlerhafte Aufträge
                for (let i = 0; i < lokaleAuftraege.length; i++) {
                    const auftragListItem = lokaleAuftraege[i];

                    if (auftragListItem) {
                        let kaputt = false;

                        if (auftragListItem.Auftragsstatus >= 90) {
                            if (!auftragListItem.Abholadresse && !auftragListItem.Info1 && !auftragListItem.Info2 && !auftragListItem.Info3) {
                                kaputt = true;
                            }
                        }

                        if (kaputt) {
                            this.log.warn(`Auftrag an Index ${i} ist kaputt. Wird gelöscht.`);
                            lokaleAuftraege.splice(i, 1);
                            i--;
                        }
                    } else {
                        this.log.warn(`Auftrag an Index ${i} null. Wird gelöscht.`)
                        lokaleAuftraege.splice(i, 1);
                        i--;
                    }
                }

                // // Entferne Dateianhänge aus dem Ausfrag und speichere sie auf der Festplatte
                // const isIOS = this.platform.is('ios');
                // const isAndroid = this.platform.is('android');

                // for (const auftrag of lokaleAuftraege) {
                //     if (auftrag.Anlagen) {
                //         for (const anlage of auftrag.Anlagen) {
                //             if (anlage.Inhalt && anlage.Dateiname) {
                //                 const bytes = atob(anlage.Inhalt);

                //                 if (isAndroid) {
                //                     await this.file.writeFile(this.file.dataDirectory + '/auftrag/' + auftrag.Key, anlage.Dateiname, bytes, { replace: true });
                //                 } else if (isIOS) {
                //                     await this.file.writeFile(this.file.documentsDirectory + 'ReCoMobil/auftrag/' + auftrag.Key, anlage.Dateiname, bytes, { replace: true });
                //                 }

                //                 anlage.Inhalt = null;
                //             }
                //         }
                //     }
                // }

                await this.speichereLokaleAuftraege(lokaleAuftraege);

                if (neueAuftragsListe.length) {
                    this.verarbeiteNeueAuftraege(neueAuftragsListe, true);

                    this.sendeEmpfangsbestaetigungFuerNeueAuftraege(neueAuftragsListe);
                }

                if (geaendert) {
                    this.log.info('aufträge geändert');
                    this.auftraegeGeaendert.next();
                }

                await this.aktualisiereAnzahlAktuelleAuftraege();

                resolve({
                    success: true,
                    newItems: anzahlNeu
                });
            } catch (err) {
                this.log.error('syncAuftraegeMitServer', err);

                resolve({
                    errorMessage: err,
                    success: false
                });
            } finally {
                this.syncAuftraegeMitServerPromise = null;
            }
        });

        return this.syncAuftraegeMitServerPromise;
    }

    sendeEmpfangsbestaetigungFuerNeueAuftraege(neueAuftragsListe: AuftragEx[]) {
        this.log.debug('sendeEmpfangsbestaetigungFuerNeueAuftraege: ' + neueAuftragsListe.length);

        if (!neueAuftragsListe.length) {
            return;
        }

        for (const auftrag of neueAuftragsListe) {
            this.empfangsbestaetigungen.push({
                Typ: EmpfangsbestaetigungElementTyp.Auftrag,
                Key: auftrag.Key,
                Status: EmpfangsbestaetigungStatusTyp.Empfangen
            });
        }

        this.starteEmpfangsbestaetigungTimeout();
    }

    loescheDoppelteAuftraege(auftraege: AuftragListItem[]): boolean {
        let geloescht = false;

        for (let i = 0; i < auftraege.length; i++) {
            const a1 = auftraege[i];

            for (let k = i + 1; k < auftraege.length; k++) {
                const a2 = auftraege[k];

                if (a2.Key == a1.Key) {
                    // Doppelte Auftrag. Lösche den Auftrag mit dem niedrigeren Status
                    this.log.warn(`Auftrag ${a1.Key} ist doppelt an index ${i} und index ${k}. Duplikat wird gelöscht.`);
                    geloescht = true;

                    if (a2.Auftragsstatus <= a1.Auftragsstatus) {
                        this.log.warn(`Lösche Auftrag ${a1.Key} bei index ${k} (1)`);
                        auftraege.splice(k, 1);
                        k--;
                    } else {
                        this.log.warn(`Lösche Auftrag ${a1.Key} bei index ${i} (1)`);
                        auftraege.splice(i, 1);
                        i--;
                        break;
                    }
                }
            }
        }

        return geloescht;
    }

    async verarbeiteNeueAuftraege(neueAuftraege: AuftragEx[], mitNotification: boolean): Promise<void> {
        this.log.debug(`verarbeiteNeueAuftraege (mitNotification=${mitNotification})`);

        if (!neueAuftraege.length) {
            return;
        }

        const config = AppConfig.current;

        this.neueAuftraege.next(neueAuftraege);

        const hofauftraege = neueAuftraege.filter(p => p.Auftragstyp == Auftragstyp.Hofchecker);
        const transportauftraege = neueAuftraege.filter(p => p.Auftragstyp == Auftragstyp.Transportauftrag);

        let klingelnAktiv = true;

        if (App.current.nurKlingelnWennAngemeldet.getValue()) {
            if (App.current.anmeldungErforderlich.getValue()) {
                if (App.current.fahrerAngemeldet.getValue()) {
                    if (AppConfig.current.einstellungen.Transportauftraege === 'ja') {
                        // Es muss auch ein Fahrzeug angemeldet sein
                        if (!App.current.fahrzeug.getValue()) {
                            this.log.info('Fahrzeug ist nicht angemeldet. Neue Aufträge werden ignoriert');
                            klingelnAktiv = false;
                        }
                    }
                } else {
                    this.log.info('Fahrer ist nicht angemeldet. Neue Aufträge werden ignoriert');
                    klingelnAktiv = false;
                }
            }
        }

        this.log.debug(`verarbeiteNeueAuftraege: klingelnAktiv=${klingelnAktiv})`);

        // Eine Liste 
        const klingenQueue = [];

        if (config.einstellungen) {
            if (hofauftraege.length) {
                const aktion = config.einstellungen.AktionBeiNeuenHofauftraegen;

                this.log.debug('AktionBeiNeuenHofauftraegen: ' + aktion);

                if (aktion == "klingeln" || aktion === 'beep') {
                    if (klingelnAktiv) {
                        this.log.debug('wakeUp + moveToForeground');
                        this.backgroundMode.wakeUp();
                        this.backgroundMode.moveToForeground();
                    }

                    if (mitNotification) {
                        const localNotifications = (cordova.plugins as any).notification?.local;

                        localNotifications.addActions('yes-no', [
                            { id: 'yes', title: 'Yes' },
                            { id: 'no', title: 'No' }
                        ]);

                        const auftrag = hofauftraege[0];

                        let title = 'Neuer Hofauftrag';
                        let text = auftrag.FahrzeugKennung;

                        if (hofauftraege.length > 1) {
                            title = hofauftraege.length + ' neue Hofaufträge';
                            text = 'Es wurden neue Hofaufträge empfangen';
                        }

                        localNotifications.schedule({
                            title,
                            text,
                            vibrate: true,
                            wakeup: true,
                            badge: 1,
                            priority: 1,
                            lockscreen: true,
                            foreground: true,
                            actions: 'yes-no'
                        });
                    }

                    if (klingelnAktiv) {
                        await this.klingeln(aktion, hofauftraege);
                    }
                }
            } else if (transportauftraege.length) {
                const aktion = config.einstellungen.AktionBeiNeuenTransportauftraegen;

                const neueTransportauftraege = transportauftraege.filter(p => !p.istAktualisiert);
                const aktualisierteTransportauftraege = transportauftraege.filter(p => p.istAktualisiert);

                const klingelnAuftraege: AuftragEx[] = [];

                if (neueTransportauftraege.length) {
                    this.log.debug(transportauftraege.length + ' neue Transportaufträge. Aktion = ' + aktion);

                    if (aktion == "klingeln" || aktion === 'beep') {
                        klingelnAuftraege.push(...neueTransportauftraege);
                    }
                }

                if (aktualisierteTransportauftraege.length) {
                    const aktion2 = config.einstellungen.AktionBeiAktualisiertenTransportauftraegen;
                    this.log.debug(aktualisierteTransportauftraege.length + ' aktualisierte Transportaufträge. Aktion = ' + aktion2);

                    if (aktion2 == "klingeln" || aktion2 === 'beep') {
                        klingelnAuftraege.push(...aktualisierteTransportauftraege);
                    }
                }

                if (klingelnAuftraege.length) {
                    if (aktion == "klingeln" || aktion === 'beep') {
                        if (klingelnAktiv) {
                            this.backgroundMode.wakeUp();
                            this.backgroundMode.moveToForeground();
                        }

                        if (mitNotification) {
                            const localNotifications = (cordova.plugins as any).notification?.local;

                            localNotifications.addActions('yes-no', [
                                { id: 'yes', title: 'Yes' },
                                { id: 'no', title: 'No' }
                            ]);

                            const auftrag = klingelnAuftraege[0];

                            let title = 'Neuer Transportauftrag';
                            let text = auftrag.FahrzeugKennung;

                            if (hofauftraege.length > 1) {
                                title = hofauftraege.length + ' neue Transportauftrag';
                                text = 'Es wurden neue Transportauftrag empfangen';
                            }

                            localNotifications.schedule({
                                title,
                                text,
                                vibrate: true,
                                wakeup: true,
                                badge: 1,
                                priority: 1,
                                lockscreen: true,
                                foreground: true,
                                actions: 'yes-no'
                            });
                        }

                        if (klingelnAktiv) {
                            await this.klingeln(aktion, klingelnAuftraege);
                        }
                    }
                }
            }
        }
    }

    async klingeln(aktion: string, neueAuftraege: Auftrag[]) {
        this.log.debug(`klingeln: ${aktion}, neueAufträge=${neueAuftraege.length}`);

        if (App.current.klingelnBlockiert.getValue()) {
            // Klingeln nicht erlaubt. Ansonsten stört das bei der Eingabe.
            // Beim Verlassen soll aber geklingelt werden
            if (this.klingelnSubscription) {
                this.klingelnSubscription.unsubscribe();
                this.klingelnSubscription = null;
            }

            this.klingelnSubscription = App.current.klingelnBlockiert.subscribe((isAktiv) => {
                if (!isAktiv) {
                    // Details wurden verlassen. Jetzt verzögert klingeln
                    this.klingelnSubscription.unsubscribe();
                    this.klingeln(aktion, neueAuftraege);
                }
            });

            return;
        }

        const config = AppConfig.current;

        if (config.einstellungen.KlingenJedenAuftragAnzeigen === 'jeden') {
            // Jeder Auftrag muss einzeln angezeigt werden, deshalb hier direkt ohne Verzögerung anzeigen.
            // Ansonsten wird nur der letzte Auftrag angezeigt !
            this.doKlingeln.next({
                aktion: aktion,
                neueAuftraege: neueAuftraege
            });

            return;
        }

        let klingelnDelay = Utils.parseIntMitDefault(config.einstellungen.KlingelnDelay, 5000);

        // Es muss immer einen Delay geben
        if (klingelnDelay < 100) {
            klingelnDelay = 100;
        }

        const idx = ++this.klingelnIndex;

        setTimeout(() => {
            if (idx === this.klingelnIndex) {
                this.doKlingeln.next({
                    aktion: aktion,
                    neueAuftraege: neueAuftraege
                })
            }
        }, klingelnDelay);
    }

    private getEmpfangsDatum(auftrag: AuftragEx): moment.Moment {
        this.fixAuftrag(auftrag);

        if (auftrag.metadaten.empfangsDatum) {
            return moment(auftrag.metadaten.empfangsDatum);
        }

        return moment("1970-01-01");
    }

    entpackeAuftragsdaten(dto: AuftragDto): AuftragEx {
        let auftrag: AuftragEx = null;

        try {
            if (dto.auftrag) {
                // Auftrag unverschlüsselt übertragen (aber eventl. komprimiert und base64 kodiert)
                if (dto.auftrag.startsWith("{")) {
                    // nicht komprimiert übertragen
                    auftrag = JSON.parse(dto.auftrag);
                } else {
                    // komprimiert und base64 kodiert übertragen
                    const docodedData = atob(dto.auftrag);
                    const unzipped = pako.inflate(docodedData, { to: 'string' });

                    auftrag = JSON.parse(unzipped);
                }
            } else {
                throw new Error('Keine gültigen Daten in AuftragDTO empfangen');
            }
        } catch (err) {
            this.log.error('Fehler beim Entpacken der Auftragsdaten: ' + Utils.getErrorMessage(err), err);
            this.log.error('Fehler beim Entpacken der Auftragsdaten Auftrag', dto.auftrag);
        }

        this.log.debug('entpackeAuftragsdaten', auftrag);

        if (auftrag) {
            this.fixAuftrag(auftrag);

            auftrag.metadaten.empfangsDatum = moment().toISOString();
            auftrag.metadaten.version = dto.version;

            const downloadFileList: DownloadFile[] = [];

            for (const anlage of auftrag.Anlagen) {
                if (anlage.Dateiname && anlage.Inhalt.startsWith("data:")) {
                    const guid = anlage.Inhalt.substring(5);

                    if (guid) {
                        const isIOS = this.platform.is('ios');
                        const isAndroid = this.platform.is('android');

                        let directory = null;

                        if (isAndroid) {
                            directory = this.file.dataDirectory + 'auftrag/' + auftrag.Key;
                        } else if (isIOS) {
                            directory = this.file.documentsDirectory + 'ReCoMobil/auftrag/' + auftrag.Key;
                        }

                        anlage.Pfad = directory + '/' + anlage.Dateiname;

                        if (directory) {
                            downloadFileList.push({
                                auftragKey: auftrag.Key,
                                filename: anlage.Dateiname,
                                status: 0,
                                guid,
                                size: anlage.Size,
                                errorCount: 0,
                                filePath: anlage.Pfad
                            });
                        } else {
                            this.log.warn('Datei kann nicht heruntergeladen werden. Kein Ziel-Verzeichnis: ' + anlage.Dateiname);
                        }
                    }
                }
            }

            if (downloadFileList.length) {
                this.systemService.enqueueDownloadFiles(downloadFileList);
            }
        }

        return auftrag;
    }

    async getAuftragsBilder(auftrag: AuftragEx): Promise<ReCoMobilBild[]> {
        const bilder: ReCoMobilBild[] = [];

        if (auftrag && auftrag.Auftragsbilder) {
            for (const bildRef of auftrag.Auftragsbilder) {
                const src = await this.getBildSrc(bildRef.BildGuid);

                if (src) {
                    // Erstelle eine Kopie des Bild-Objektes. Das Objekt im Auftrag soll nicht das Bild direkt enthalten
                    const copy = Object.assign({}, bildRef);
                    copy.Bild = src;

                    bilder.push(copy);
                }
            }
        }

        return bilder;
    }

    aktualisierePreise(auftrag: AuftragEx, info: string) {
        this.log.debug('aktualisierePreise: ' + auftrag.Key + ' (' + info + ')');

        auftrag.SummeBrutto = 0;
        auftrag.SummeNetto = 0;
        auftrag.SummeMwst = 0;
        auftrag.PreiseGueltig = true;
        auftrag.MwstBetraege = [];

        // Brutto der Einzelpositionen berechnen
        for (const p of auftrag.Positionen) {
            if (typeof (p.Menge) === 'string' && p.Menge !== '') {
                p.Menge = Utils.parseFloat(p.Menge);
            }

            // EinzelVK: Netto-Betrag pro Stück
            if (typeof (p.EinzelVK) === 'number') {
                p.NettoBetrag = Math.round(p.Menge * p.EinzelVK * 100) / 100.0;

                if (typeof (p.MwstSatz) === 'number') {
                    // const einzelBrutto = p.EinzelVK + p.EinzelVK * (p.MwstSatz / 100);

                    // p.Brutto = einzelBrutto * p.Menge;
                    // p.MwstBetrag = p.Brutto  / (1 + (p.MwstSatz / 100)); //  p.Menge * p.EinzelVK;
                    // p.NettoBetrag = p.Brutto - p.MwstBetrag;

                    p.MwstBetrag = Math.round(p.NettoBetrag * (p.MwstSatz / 100) * 100) / 100.0;
                    p.Brutto = Math.round((p.NettoBetrag + p.MwstBetrag) * 100) / 100.0;

                    if (p.NettoBetrag) {
                        let mwstEintrag = auftrag.MwstBetraege.find(x => x.MwstSatz == p.MwstSatz);

                        if (!mwstEintrag) {
                            mwstEintrag = { MwstSatz: p.MwstSatz, MwstBetrag: 0, NettoBetrag: 0 };
                            auftrag.MwstBetraege.push(mwstEintrag);
                        }

                        mwstEintrag.NettoBetrag += p.NettoBetrag;
                        mwstEintrag.MwstBetrag += p.MwstBetrag;
                    }
                } else {
                    p.MwstBetrag = 0;
                    p.Brutto = 0;
                    auftrag.PreiseGueltig = false;
                }
            } else if (p.Menge == 0) {
                p.NettoBetrag = 0;
                p.Brutto = 0;
            } else {
                p.NettoBetrag = null;
                p.Brutto = null;
                auftrag.PreiseGueltig = false;
            }
        }

        if (auftrag.PreiseGueltig) {
            auftrag.MwstBetraege.sort((a, b) => a.MwstSatz - b.MwstSatz);

            for (const p of auftrag.Positionen) {
                if (p.Menge) {
                    auftrag.SummeBrutto += p.Brutto;
                    auftrag.SummeNetto += p.NettoBetrag;
                    auftrag.SummeMwst += p.MwstBetrag;
                }
            }
        } else {
            auftrag.MwstBetraege = [];
        }

        this.log.debug(`aktualisierePreise Ergebnis. Key: ${auftrag.Key}, PreiseGueltig: ${auftrag.PreiseGueltig}, SummeBrutto: ${auftrag.SummeBrutto}, SummeNetto: ${auftrag.SummeNetto}, SummeMwst: ${auftrag.SummeMwst}`);
    }

    getBildSrc(guid: string): Promise<string> {
        return AppStorage.current.getBildSrc(guid);
    }

    getBildBase64Data(guid: string): Promise<string> {
        return AppStorage.current.getBildBase64Data(guid);
    }

    async speichereBild(guid: string, src: string) {
        this.log.debug(`speichereBild: guid=${guid}`); // , src=${src}

        if (!guid || !src) {
            return null;
        }

        await AppStorage.current.set('bild-' + guid, src, false);
    }

    async loescheBild(guid: string): Promise<void> {
        if (!guid) {
            return;
        }

        try {
            const src = await this.getBildSrc(guid);

            if (src && src.startsWith('file:')) {
                const filename = FileHelper.getFilename(src);
                const directory = FileHelper.getDirectory(src);

                try {
                    await this.file.removeFile(directory, filename);
                } catch (err) {
                    this.log.warn('error removing file', err);
                }
            }

            await AppStorage.current.remove('bild-' + guid);
        } catch (err) {
            this.log.error('loescheBild ' + guid + ': ' + Utils.getErrorMessage(err));
        }
    }

    async loescheAlteAuftragsDateien(): Promise<void> {
        this.log.debug('loescheAlteAuftragsDateien');

        const isIOS = this.platform.is('ios');
        const isAndroid = this.platform.is('android');

        let entires: Entry[] = null;

        try {
            if (isAndroid) {
                entires = await this.file.listDir(this.file.dataDirectory, "auftrag");
            } else if (isIOS) {
                entires = await this.file.listDir(this.file.documentsDirectory, "ReCoMobil/auftrag");
            }

            if (entires) {
                const auftraege = await this.getAlleAuftraegeListItems();

                for (const entry of entires) {
                    if (entry.isDirectory) {
                        const auftragKey = entry.name;

                        if (!auftraege.find(p => p.Key == auftragKey)) {
                            // Auftrag nicht mehr vorhanden. Dateien können gelöscht werden
                            this.log.info('Lösche Verzeichnis: ' + entry.fullPath);

                            if (isAndroid) {
                                await this.file.removeRecursively(this.file.dataDirectory, "auftrag/" + entry.name);
                            } else if (isIOS) {
                                await this.file.removeRecursively(this.file.documentsDirectory, "ReCoMobil/auftrag/" + + entry.name);
                            }
                        }
                    }
                }
            }
        } catch (err) {
            // Nicht schlimm. tritt imemr beim erstmaligen Einrichten auf
            this.log.warn('loescheAlteAuftragsDateien', err);
        }
    }

    async loescheAlleAuftraege(): Promise<void> {
        this.log.warn('Lösche alle Aufträge');

        await AppStorage.current.set(Constants.AUFTRAEGE, []);

        this.alleAuftraege = [];
        this.auftraegeLadenPromise = null;

        this.auftraegeGeaendert.next();

        this.anzahlAktuelleTransportauftraege.next(0);
        this.anzahlAktuelleHofcheckerAuftraege.next(0);
        this.anzahlAktuelleKommissionierAuftraege.next(0);
        this.anzahlAktuelleBeladungAuftraege.next(0);
    }

    async statusmeldung(auftrag: AuftragEx, status: WorkflowStatus, zusatzdaten: StatusmeldungZusatzdaten = null, textdateiTyp: TextdateiTyp = TextdateiTyp.Auftrag): Promise<boolean> {
        this.log.info(`statusmeldung: ${auftrag?.Key}, ${status?.name}`, { auftrag, status });

        if (status.auftragsstatus) {
            if (auftrag.Auftragsstatus < status.auftragsstatus) {
                auftrag.Auftragsstatus = status.auftragsstatus;
            }
        }

        AuftragHelper.BerechneAktiveDauerInSekunden(auftrag);

        try {
            let personalnummer = null;
            let personalKey = null;

            const fahrer = App.current.fahrer.getValue();

            if (fahrer) {
                personalnummer = fahrer.personalnummer;
                personalKey = fahrer.Key;
            }

            /**
             * Erzeuge eine Kopie des Auftrags nur mit den relevanten Daten
             */
            const sendAuftrag: Auftrag = {
                Key: auftrag.Key,
                Statusmeldung: status.name,
                StatusmeldungKey: status.key,
                IstNeuanlage: auftrag.IstNeuanlage,
                Auftragsnummer: auftrag.Auftragsnummer,
                Auftragsstatus: auftrag.Auftragsstatus,
                Auftragstyp: auftrag.Auftragstyp,
                WorkflowName: auftrag.WorkflowName,
                UnterMandant: auftrag.UnterMandant,
                PersonalKey: personalKey,
                Personalnummer: personalnummer,
                Mandant: auftrag.Mandant,
                AHaupflaufContNr: auftrag.AHaupflaufContNr,
                TourKey: auftrag.TourKey,
                Fahrzeug: App.current.getFahrzeugKennzeichen(),
                FahrzeugKey: App.current.getFahrzeugKey(),
                AnhaengerKey: App.current.getAnhaengerKey(),
                Anhaenger: App.current.getAnhaengerKennzeichen(),
                GeraeteGruppe: AppConfig.current.GeraeteGruppe,
                Protokoll: auftrag.Protokoll,
                Zusatzdaten: auftrag.Zusatzdaten
            };

            if (auftrag.IstNeuanlage) {
                sendAuftrag.Abholadresse = auftrag.Abholadresse;
            }

            const aktuelleTour = App.current.aktuelleTour.getValue();

            if (aktuelleTour) {
                // Eine vordefinierte Tour soll nicht mit einem Dummy-Tour überschrieben.
                // Beispiel MAYER. Hier steht in den Touren z.B. "10U2".
                // Das soll nicht mit der GUID (3b97502c-c260-426e-9a15-100308236f2a) aus der Adhoc-Tour überschrieben werden
                let auftragIstEchteTourNummer = auftrag.TourKey && auftrag.TourKey.length < 15;
                let isAdhocTour = aktuelleTour.TourTyp === TourTyp.AdHoc;

                if (auftragIstEchteTourNummer && isAdhocTour) {
                    // Nicht überschreiben
                } else {
                    auftrag.TourKey = aktuelleTour.TourKey;
                    sendAuftrag.TourKey = aktuelleTour.TourKey;
                }

                sendAuftrag.BeifahrerKey = aktuelleTour.BeifahrerKey;
                sendAuftrag.BeifahrerKey2 = aktuelleTour.BeifahrerKey2;
            }

            if (status.auftragsdatenSenden) {
                if (!auftrag.Positionen) {
                    auftrag.Positionen = [];
                }

                sendAuftrag.Anmerkungen = auftrag.Anmerkungen;
                sendAuftrag.Positionen = JSON.parse(JSON.stringify(auftrag.Positionen)); // clone
                sendAuftrag.ChecklistenErgebnis = auftrag.ChecklistenErgebnis;
                sendAuftrag.Zeiten = Zeiterfassung.getAuftragszeiten(auftrag);

                // Formatiere Datum-Eigenschaften.
                // Durch den ion-datepicker werden sie bisher im Format "2020-11-05T07:45:39.24+01:00" gespeichert.
                for (const position of sendAuftrag.Positionen) {
                    if (position.Menge !== null && typeof (position.Menge) !== 'undefined') {
                        position.Menge = Utils.parseFloat(position.Menge);
                    }

                    if (position.GewichtInKg !== null && typeof (position.GewichtInKg) !== 'undefined') {
                        position.GewichtInKg = Utils.parseFloat(position.GewichtInKg);

                        if (isNaN(position.GewichtInKg)) {
                            position.GewichtInKg = null;
                        }
                    }

                    if (position.Eigenschaften) {
                        for (const eigenschaft of position.Eigenschaften) {
                            if (eigenschaft.Typ == 'datum' && eigenschaft.Wert && eigenschaft.Wert.length > 20) {
                                // Es handelt sich wohl um ein Datum im ISO-Format. Sollte eigentlich nicht mehr auftreten
                                try {
                                    eigenschaft.Wert = moment(eigenschaft.Wert).format('DD.MM.YYYY');
                                } catch (err) {
                                    this.log.error('Fehler beim Parsen des Datums: ' + err, eigenschaft);
                                }
                            }
                        }
                    }
                }

                for (const pos of auftrag.Positionen) {
                    // Merke, dass die Position bereits einmal gesendet wurde.
                    // Das ist wichtig für neue Auftragspositionen. Sie dürfen nach dem Senden nicht mehr gelöscht werden.
                    pos.IstGesendet = true;
                }
            }

            if (status.GeopositionExaktErfassen) {
                const gpsPosition = await this.systemService.getExakteGpsPosition('statusmeldung');

                if (!gpsPosition) {
                    UiHelper.showErrorOhneSentry('GPS-Position konnte nicht bestimmt werden.');
                    return false;
                }

                sendAuftrag.GeoposGeraetX = gpsPosition.Longitude;
                sendAuftrag.GeoposGeraetY = gpsPosition.Latitude;
            } else if (status.geopositionSenden) {
                const gpsPosition = await this.systemService.getAktuelleGpsPosition('geopositionSenden');

                if (gpsPosition) {
                    sendAuftrag.GeoposGeraetX = gpsPosition.Longitude;
                    sendAuftrag.GeoposGeraetY = gpsPosition.Latitude;
                } else {
                    sendAuftrag.GeoposGeraetX = 0;
                    sendAuftrag.GeoposGeraetY = 0;
                }
            }

            let bilderFuerVetaerinaer = false;

            if (status.auftragsstatus === Auftragsstatus.Abgeschlossen) {
                const aktiveRegeln = AuftragHelper.getZutreffendeRegeln(auftrag);

                for (const regel of aktiveRegeln) {
                    if (regel.Aktion?.BilderFuerVeterinear) {
                        // Die Bilder sollen für Veterinäere freigegeben werden.
                        // Das wird für die ZTN Schweinewoche benötigt.
                        bilderFuerVetaerinaer = true;
                    }
                }
            }

            if (status.bilderSenden) {
                sendAuftrag.Auftragsbilder = [];

                for (const auftragsbild of auftrag.Auftragsbilder) {
                    if (auftragsbild.BildGuid) {
                        const bildBase64 = await this.getBildBase64Data(auftragsbild.BildGuid);

                        if (bildBase64) {
                            sendAuftrag.Auftragsbilder.push({
                                Datum: auftragsbild.Datum,
                                BildGuid: auftragsbild.BildGuid,
                                Bild: bildBase64,
                                FuerVeterinaer: bilderFuerVetaerinaer
                            });
                        }
                    }
                }
            }

            if (status.unterschriftSenden && auftrag.Unterschriften) {
                sendAuftrag.Unterschriften = auftrag.Unterschriften.filter(p => p.Name && p.Bild);

                for (const unterschrift of sendAuftrag.Unterschriften) {
                    const unterschriftEx = unterschrift as UnterschriftEx;
                    unterschriftEx.Data = undefined;
                }
            }

            let extraText = '';

            if (zusatzdaten) {
                if (zusatzdaten.druckFormular) {
                    sendAuftrag.DruckFormularName = zusatzdaten.druckFormular;
                    sendAuftrag.DruckFormularDaten = zusatzdaten.druckDaten;

                    extraText = zusatzdaten.druckFormular;
                }

                if (zusatzdaten.istBezahlung) {
                    sendAuftrag.BezahlungArt = zusatzdaten.bezahlungArt;
                    sendAuftrag.BezahlungBetrag = zusatzdaten.bezahlungBetrag;

                    extraText = zusatzdaten.bezahlungBetrag.toFixed(2) + ' € ';

                    if (zusatzdaten.bezahlungArt == BezahlartTyp.Bar) {
                        extraText += ' Bar';
                    } else if (zusatzdaten.bezahlungArt == BezahlartTyp.EC) {
                        extraText += ' EC';
                    } else if (zusatzdaten.bezahlungArt == BezahlartTyp.Storno) {
                        extraText += ' STORNO !';
                    }
                }

                if (zusatzdaten.zusatzinfo && zusatzdaten.zusatzinfo.Felder && zusatzdaten.zusatzinfo.Felder.length) {
                    extraText += ' (';

                    for (let i = 0; i < zusatzdaten.zusatzinfo.Felder.length; i++) {
                        const feld = zusatzdaten.zusatzinfo.Felder[i];

                        if (i > 0) {
                            extraText += ', ';
                        }

                        extraText += `${feld.Bezeichnung}: ${feld.Wert}`;
                    }

                    extraText += ')';
                }

                sendAuftrag.StatusmeldungZusatzinfo = zusatzdaten.zusatzinfo;
            }

            this.log.debug('sendAuftrag', sendAuftrag);

            const textdatei: Textdatei = {
                typ: textdateiTyp,
                key: auftrag.Key,
                auftrag: sendAuftrag,
                datum: moment().toISOString(),
                geraeteNummer: AppConfig.current.geraeteNummer,
            };

            if (!auftrag.statusmeldungen) {
                auftrag.statusmeldungen = [];
            }

            auftrag.statusmeldungen.push({
                datum: moment().toISOString(),
                name: status.name,
                statusId: status.id,
                extraText,
                personalKey: personalKey,
                personalName: App.current.getPersonalName(),
                personalnummer: App.current.getPersonalnummer(),
                abholstelleFertig: status.AbholstelleFertig
            });

            auftrag.Statuszeit = moment().toISOString();

            auftrag.auftragsFarbe = status.AuftragsFarbe;

            if (auftrag.Auftragsstatus == Auftragsstatus.Abgelehnt
                || auftrag.Auftragsstatus == Auftragsstatus.Weiterleiten
                || auftrag.Auftragsstatus == Auftragsstatus.Unterbrochen
                || auftrag.Auftragsstatus == Auftragsstatus.Abgeschlossen
                || auftrag.Auftragsstatus == Auftragsstatus.Abgelehnt
                || auftrag.Auftragsstatus == Auftragsstatus.Geloescht) {

                if (auftrag.zeiterfassungLaeuft) {
                    this.log.info('Zeiterfassung wird beendet: ' + auftrag.Key);

                    await Zeiterfassung.unterbrecheAuftragZeiterfassung(auftrag);
                }
            }

            // Nur speichern falls es sich nicht um einen geklonten Auftrag (für den Ausdruck) handelt
            if (!auftrag.istClone) {
                await this.speichereAuftrag(auftrag);
            }

            // Kein await hier aus Performance-Gründen
            let mitGpsPosition = false;

            if (auftrag.Auftragstyp == Auftragstyp.Transportauftrag) {
                mitGpsPosition = AppConfig.current.einstellungen.GpsPositionBeiStatusmeldungTransportauftrag === 'ja';
            } else if (auftrag.Auftragstyp == Auftragstyp.Hofchecker) {
                mitGpsPosition = AppConfig.current.einstellungen.GpsPositionBeiStatusmeldungHofauftrag === 'ja';
            }

            this.systemService.sendeTextdatei(textdatei, mitGpsPosition);

            return true;
        } catch (err) {
            UiHelper.showError(err);
            return false;
        }
    }

    private async verarbeiteAuftragUpdatePushMessage(pushMessage: AuftragUpdatePushMessage) {
        this.log.info('verarbeiteAuftragUpdatePushMessage', pushMessage);

        const auftrag = await this.getAuftrag(pushMessage.key);

        if (pushMessage.auftragsstatus === Auftragsstatus.Geloescht) {
            this.log.info('PUSH: Auftrag gelöscht: ' + pushMessage.key);

            if (auftrag) {
                let backupDatenSenden = false;

                let meldungAnzeigen = AppConfig.current.einstellungen.AuftragGeloeschtMeldungImmerAnzeigen === 'ja';

                if (auftrag.Auftragsstatus > Auftragsstatus.Zugewiesen && auftrag.Auftragsstatus < Auftragsstatus.Abgeschlossen) {
                    backupDatenSenden = true;
                } else if (auftrag.Auftragsstatus < Auftragsstatus.Zugewiesen) {
                    // Auftrag wurde zwar noch nicht gestartet (Statusmeldung).
                    // Es wurden aber vielleicht schon Daten eingegeben...
                    if (auftrag.Auftragsbilder && auftrag.Auftragsbilder.length > 0) {
                        backupDatenSenden = true;
                    }

                    if (auftrag.Unterschriften && auftrag.Unterschriften.length > 0) {
                        backupDatenSenden = true;
                    }

                    if (auftrag.Anmerkungen) {
                        backupDatenSenden = true;
                    }
                }

                if (backupDatenSenden) {
                    this.log.warn('Auftrag wurde schon bearbeitet und soll gelöscht werden. Schicke die aktuellen Daten als Backup an den Server. Auftragsstatus = ' + auftrag.Auftragsstatus);

                    const status: WorkflowStatus = {
                        key: '000',
                        name: 'Auftrag gelöscht',
                        auftragsdatenSenden: true,
                        bildErforderlich: false,
                        geopositionSenden: false,
                        bilderSenden: true,
                        unterschriftSenden: true,
                    };

                    await this.statusmeldung(auftrag, status, null, TextdateiTyp.AuftragBackup);

                    meldungAnzeigen = true;
                }

                if (meldungAnzeigen) {
                    UiHelper.showAlert(`Auftrag ${auftrag.Auftragsnummer} wurde gelöscht!\n\n${auftrag.Abholadresse?.Name1}`);

                    auftrag.loeschMeldungWurdeAngezeigt = true;

                    if (AppConfig.current.einstellungen.AuftragGeloeschtMeldungAlsNotification === 'ja') {
                        if (!this.systemService.istImVordergrund.getValue()) {
                            const localNotifications = (cordova.plugins as any).notification?.local;

                            localNotifications.schedule({
                                title: 'Auftrag gelöscht',
                                text: `Auftrag ${auftrag.Auftragsnummer} wurde gelöscht!\n\n${auftrag.Abholadresse?.Name1}`,
                                vibrate: true,
                                wakeup: true,
                                badge: 0,
                                priority: 2,
                                lockscreen: true,
                                foreground: true,
                                launch: true,
                                channelName: 'Auftrag',
                                sound: 'res://platform_default',
                            });

                            // Turn screen on
                            this.backgroundMode.wakeUp();

                            // Turn screen on and show app even locked
                            cordova.plugins.backgroundMode.unlock();

                            this.backgroundMode.moveToForeground();

                            await this.systemService.beepFuerNeueNachricht();
                        }
                    }
                } else {
                    auftrag.loeschMeldungWurdeAngezeigt = false;
                }

                await this.loescheAuftrag(auftrag);

                this.auftragGeloescht.next(auftrag);
                this.auftraegeGeaendert.next();
            } else {
                // Prüfe ob der ListItem noch vorhanden ist (war Fehler bei BTU !)
                this.log.info('Auftrag kann nicht gelöscht werden. Nicht vorhanden: ' + pushMessage.key);

                const auftragListItem = await this.getAuftragListItem(pushMessage.key);

                if (auftragListItem) {
                    this.log.warn('AuftragListItem noch vorhanden. Wird gelöscht: ' + auftragListItem.Key);

                    this.loescheAuftragListItem(auftragListItem);
                }
            }
        } else {
            this.log.info('PUSH: Auftrag wurde aktualisiert: ' + pushMessage.key);

            if (auftrag) {
                if (auftrag.Auftragsstatus != Auftragsstatus.Abgeschlossen) {
                    let downloadErlaubt = true;

                    if (auftrag.Auftragstyp === Auftragstyp.Kommissionierung) {
                        this.log.info(`Kommissionierungsauftrag ${auftrag.Key} wurde aktualisiert und Gerät ${pushMessage.geraet} zugewiesen.`);

                        // Auftrag soll auf allen Geräten sichtbar sein. Übernehme einfach das Gerät und den Status
                        auftrag.Auftragsstatus = pushMessage.auftragsstatus;
                        auftrag.Geraet = pushMessage.geraet;
                        auftrag.PersonalKey = pushMessage.personalKey;
                        await this.speichereAuftrag(auftrag);

                        // TODO: Prüfen
                        // downloadErlaubt = false;
                    } else {
                        if (pushMessage.geraet && pushMessage.geraet != AppConfig.current.geraeteNummer) {
                            this.log.info(`Auftrag ${auftrag.Key} wurde Gerät ${pushMessage.geraet} zugewiesen. Wir sind Gerät ${AppConfig.current.geraeteNummer}. Auftrag wird gelöscht.`);
                            // Auftrag wurde einem anderen Gerät zugewiesen.
                            // Der Auftrag soll damit in dieser App nicht mehr sichtbar sein
                            await this.loescheAuftrag(auftrag);
                            this.auftragGeloescht.next(auftrag);
                            this.auftraegeGeaendert.next();
                            downloadErlaubt = false;
                        } else {
                            this.log.info(`Auftrag ${auftrag.Key} wurde aktualisiert und Gerät ${pushMessage.geraet} zugewiesen.`);

                            if (auftrag.Auftragsstatus < pushMessage.auftragsstatus) {
                                auftrag.Auftragsstatus = pushMessage.auftragsstatus;
                            }

                            auftrag.Geraet = pushMessage.geraet;
                            await this.speichereAuftrag(auftrag);
                        }
                    }

                    if (pushMessage.geaendert && downloadErlaubt) {
                        this.log.info(`Auftrag ${auftrag.Key} wurde geändert und muss neu geladen werden.`);
                        this.downloadAuftragByKey(pushMessage.key);
                    }
                } else {
                    this.log.warn('Auftrag wurde nicht aktualisiert, da schon bearbeitet. Auftragsstatus = ' + auftrag.Auftragsstatus);
                }
            } else {
                if (pushMessage.geraet && pushMessage.geraet != AppConfig.current.geraeteNummer) {
                    this.log.debug(`Push-Message für Auftrag empfaengen der bereits einem anderem Gerät zugeordnet wurde. Wird ignoriert.`);
                } else {
                    // Auftrag ist noch nicht vorhanden. jetzt laden
                    this.downloadAuftragByKey(pushMessage.key);
                }
            }
        }

        this.aktualisiereAnzahlAktuelleAuftraege();
    }

    private async verarbeitePushNotification(notification: NotificationEventResponse): Promise<void> {
        this.log.info('verarbeitePushNotification', notification);

        const auftragId = notification.additionalData.auftragId;
        const auftragKey = notification.additionalData.key;
        const version = notification.additionalData.version;

        if (!auftragId) {
            return;
        }

        if (auftragKey && version) {
            const auftrag = await this.getAuftrag(auftragKey);

            if (auftrag) {
                if (auftrag.metadaten.version >= version) {
                    this.log.debug('Kein Update notwendig. Auftrag schon vorhanden.', auftrag);
                    return;
                }
            }
        }

        const foreground: boolean = notification.additionalData.foreground;

        // When the app is not running and the user taps the notification, the app get TWO notifications
        // (as described above). But the first is with coldstart=false, second with coldstart=true.
        // https://github.com/phonegap/phonegap-plugin-push/issues/2549
        const coldstart: boolean = notification.additionalData.foreground;

        // TODO: Aktion auswerten. neu|aktualisiert|geloescht|archiviert... ?
        // const aktion = notification.additionalData.aktion;

        await this.downloadAuftragById(auftragId);
    }

    async downloadAuftragByKey(key: string) {
        this.log.info('downloadAuftragByKey: ' + key);

        if (App.loescheAlleDatenAktiv) {
            this.log.warn('downloadAuftragByKey 1. Lösche alle Daten läuft. Abbruch: ' + key);
            return;
        }

        const auftragDto = await this.remoteService.getAuftragByKey(key);

        if (App.loescheAlleDatenAktiv) {
            this.log.warn('downloadAuftragByKey 2. Lösche alle Daten läuft. Abbruch: ' + key);
            return;
        }

        if (auftragDto) {
            this.log.info('downloadAuftragByKey: ' + key + ' erfolgreich geladen');

            if (auftragDto.NichtFuerGeraet) {
                this.log.warn('Auftrag ist nicht für Gerät bestimmt. Wird ignoriert: ' + key);
                return;
            }

            const auftrag = this.entpackeAuftragsdaten(auftragDto);

            this.log.debug('entpackter auftrag: ' + key, auftrag);

            if (auftrag) {
                this.letzterEmpfangenerAuftrag = auftrag;

                // Prüfe ob der Auftrag schon existiert.
                // Wenn ja, sollen eventuell bereits eingegebene Daten übernommen werden
                await this.uebernehmeLokaleAuftragsdaten(auftrag);

                await this.pruefeAktiverAuftrag(auftrag);

                // Kein await. Ist beim Start viel zu langsam
                this.speichereAuftrag(auftrag);

                this.empfangsbestaetigungen.push({
                    Typ: EmpfangsbestaetigungElementTyp.Auftrag,
                    Key: auftrag.Key,
                    Status: EmpfangsbestaetigungStatusTyp.Empfangen,
                });

                if (App.loescheAlleDatenAktiv) {
                    this.log.warn('downloadAuftragByKey 3. Lösche alle Daten läuft. Abbruch: ' + key);
                    return;
                }

                this.starteEmpfangsbestaetigungTimeout();

                // Klingeln, bei Bedarf. Keine Notification, da ja schon eine Notification empfangen wurde
                this.verarbeiteNeueAuftraege([auftrag], false);

                // setTimeout(() => {
                //     if (this.letzterEmpfangenerAuftrag.Key == auftrag.Key) {
                //         // Klingeln, bei Bedarf. Keine Notification, da ja schon eine Notification empfangen wurde
                //         this.verarbeiteNeueAuftraege([auftrag], false);
                //     } else {
                //         this.log.debug('Es wurde in der Zwischenzeit ein weiterer Auftrag empfangen. Nicht klingeln.');
                //     }
                // }, 1000);

            }
        } else {
            this.log.info('downloadAuftragByKey: ' + key + ' Fehler beim Download');

            // Fehler beim Download. Aufträge müssen sobald es geht synchronisiert werden
            this.auftragSyncErforderlich = true;
        }
    }

    starteEmpfangsbestaetigungTimeout() {
        if (this.empfangsbestaetigungTimeout) {
            clearTimeout(this.empfangsbestaetigungTimeout);
            this.empfangsbestaetigungTimeout = null;
        }

        this.empfangsbestaetigungTimeout = setTimeout(() => {
            this.empfangsbestaetigungTimeout = null;

            if (this.empfangsbestaetigungen.length) {
                const textdatei: Textdatei = {
                    typ: TextdateiTyp.Empfangsbestaetigung,
                    datum: moment().toISOString(),
                    geraeteNummer: AppConfig.current.geraeteNummer,
                    Empfangsbestaetigungen: this.empfangsbestaetigungen
                };

                // Kein await hier
                this.systemService.sendeTextdatei(textdatei, false);

                this.empfangsbestaetigungen = [];
            }
        }, 3000);
    }

    private async uebernehmeLokaleAuftragsdaten(auftrag: AuftragEx) {
        auftrag.istAktualisiert = false;

        const lokalerAuftrag = await this.getAuftrag(auftrag.Key, false);

        if (lokalerAuftrag) {
            this.log.debug('uebernehmeLokaleAuftragsdaten. lokalerAuftrag existiert bereits. übernehme bereits vorhandene Daten: ' + auftrag.Key);

            let datenUebernommen = false;

            if (lokalerAuftrag.Auftragsstatus === Auftragsstatus.Abgelehnt) {
                // Auftrag soll überschrieben werden wenn sich das Auftragsdatum geändert hat.
                // Beispiel: Der Fahrer lehnt einen Auftrag an Tag x ab, bekommt ihm aber am nächsten Tag erneut.
                // Dann soll er weider angezeigt werden
                if (lokalerAuftrag.AAbholdatum != auftrag.AAbholdatum) {
                    this.log.info(`uebernehmeLokaleAuftragsdaten. lokalerAuftrag wurde abgelehnt an mit Abholdatum ${lokalerAuftrag.AAbholdatum}. Neuer Auftrag hat Abholdatum ${auftrag.AAbholdatum}. Es werden keine Daten übernommen: ${auftrag.Key}`);
                    return false;
                }
            }

            // Merke, dass es sich um eine Aktualisierung des Auftrags handelt.
            // Wird benötigt um das Klingeln unterscheiden zu können
            auftrag.istAktualisiert = true;

            if (lokalerAuftrag.Auftragsstatus >= Auftragsstatus.InBearbeitung) {
                this.log.debug(`uebernehmeLokaleAuftragsdaten: lokalerAuftrag wurde bereits gestartet (Status: ${lokalerAuftrag.Auftragsstatus}). übernehme nur neue Positionen: ` + auftrag.Key);

                datenUebernommen = true;

                if (lokalerAuftrag.Positionen) {
                    let positionen = [...lokalerAuftrag.Positionen];

                    if (auftrag.Positionen && auftrag.Positionen.length > lokalerAuftrag.Positionen.length) {
                        this.log.debug(`uebernehmeLokaleAuftragsdaten: empfangener Auftrag hat mehr Positionen als der lokale Auftrag. übernehme nur neue Positionen: ` + auftrag.Key);

                        for (let i = lokalerAuftrag.Positionen.length; i < auftrag.Positionen.length; i++) {
                            let position = auftrag.Positionen[i];

                            this.log.debug(`uebernehmeLokaleAuftragsdaten: Neue Position: Nr. ${position.PosNr}, ${position.Menge} ${position.Einheit} ${position.Bezeichnung}: ` + auftrag.Key);
                            positionen.push(position);
                        }
                    }

                    auftrag.Positionen = positionen;
                } else {
                    this.log.debug(`uebernehmeLokaleAuftragsdaten: lokalerAuftrag hat keine Positionen. Übernehme alle neuen Positionen: ` + auftrag.Key);
                }
            }

            if (lokalerAuftrag.Auftragsstatus > auftrag.Auftragsstatus) {
                auftrag.Auftragsstatus = lokalerAuftrag.Auftragsstatus;
                datenUebernommen = true;
            }

            if (lokalerAuftrag.Anmerkungen) {
                this.log.debug(`uebernehmeLokaleAuftragsdaten: Übernehme Anmerkungen ${auftrag.Key}: ${lokalerAuftrag.Anmerkungen}`);
                auftrag.Anmerkungen = lokalerAuftrag.Anmerkungen;
                datenUebernommen = true;
            }

            if (lokalerAuftrag.Auftragsbilder && lokalerAuftrag.Auftragsbilder.length) {
                this.log.debug(`uebernehmeLokaleAuftragsdaten: Übernehme Auftragsbilder ${auftrag.Key}: ${lokalerAuftrag.Auftragsbilder.length}`);
                auftrag.Auftragsbilder = lokalerAuftrag.Auftragsbilder;
                datenUebernommen = true;
            }

            if (lokalerAuftrag.Unterschriften && lokalerAuftrag.Unterschriften.length) {
                this.log.debug(`uebernehmeLokaleAuftragsdaten: Übernehme Unterschriften ${auftrag.Key}: ${lokalerAuftrag.Unterschriften.length}`);
                auftrag.Unterschriften = lokalerAuftrag.Unterschriften;
                datenUebernommen = true;
            }

            if (lokalerAuftrag.ChecklistenErgebnis?.length) {
                this.log.debug(`uebernehmeLokaleAuftragsdaten: Übernehme ChecklistenErgebnis ${auftrag.Key}: ${lokalerAuftrag.ChecklistenErgebnis.length}`);
                auftrag.ChecklistenErgebnis = lokalerAuftrag.ChecklistenErgebnis;
                datenUebernommen = true;
            }

            if (lokalerAuftrag.statusmeldungen?.length) {
                this.log.debug(`uebernehmeLokaleAuftragsdaten: Übernehme Statusmeldungen ${auftrag.Key}: ${lokalerAuftrag.statusmeldungen.length}`);
                auftrag.statusmeldungen = lokalerAuftrag.statusmeldungen;
                datenUebernommen = true;
            }

            if (lokalerAuftrag.Zeiten?.length) {
                this.log.debug(`uebernehmeLokaleAuftragsdaten: Übernehme Zeiten ${auftrag.Key}: ${lokalerAuftrag.Zeiten.length}`);
                auftrag.Zeiten = lokalerAuftrag.Zeiten;
                datenUebernommen = true;
            }

            if (lokalerAuftrag.manuelleReihenfolge > 0) {
                auftrag.manuelleReihenfolge = lokalerAuftrag.manuelleReihenfolge;
                // datenUebernommen = true; // muss hier nicht gesetzt werden.
            }

            auftrag.zeiterfassungLaeuft = lokalerAuftrag.zeiterfassungLaeuft;
            auftrag.zeiterfassungUnterbrochen = lokalerAuftrag.zeiterfassungUnterbrochen;

            if (!auftrag.statusmeldungen) {
                auftrag.statusmeldungen = [];
            }

            if (datenUebernommen) {
                auftrag.statusmeldungen.push({
                    datum: moment().toISOString(),
                    name: 'Auftrag wurde aktualisiert.',
                    statusId: null,
                    extraText: '',
                    personalKey: App.current.getPersonalKey(),
                    personalName: App.current.getPersonalName(),
                    personalnummer: App.current.getPersonalnummer()
                });
            }
        }
    }

    async downloadAuftragById(auftragId: string) {
        this.log.info('downloadAuftragById: ' + auftragId);

        const auftragDto = await this.remoteService.getAuftragById(auftragId);

        if (auftragDto) {
            const auftrag = this.entpackeAuftragsdaten(auftragDto);

            this.log.debug('entpackter auftrag', auftrag);

            if (auftrag) {
                this.letzterEmpfangenerAuftrag = auftrag;

                await this.pruefeAktiverAuftrag(auftrag);

                // Kein await. Ist beim Start viel zu langsam
                this.speichereAuftrag(auftrag);

                this.empfangsbestaetigungen.push({
                    Key: auftrag.Key,
                    Status: EmpfangsbestaetigungStatusTyp.Empfangen,
                    Typ: EmpfangsbestaetigungElementTyp.Auftrag
                });

                this.starteEmpfangsbestaetigungTimeout();

                setTimeout(() => {
                    if (this.letzterEmpfangenerAuftrag.Key == auftrag.Key) {
                        // Klingeln, bei Bedarf. Keine Notification, da ja schon eine Notification empfangen wurde
                        this.verarbeiteNeueAuftraege([auftrag], false);
                    } else {
                        this.log.debug('Es wurde in der Zwischenzeit ein weiterer Auftrag empfangen. Nicht klingeln.');
                    }
                }, 1000);
            }
        }
    }

    private async pruefeAktiverAuftrag(auftrag: Auftrag): Promise<void> {
        if (this.aktiverAuftrag && this.aktiverAuftrag.Key == auftrag.Key) {
            this.log.warn('Aktueller auftrag wurde geändert: ' + auftrag.Key);

            // Der empfangene Auftrag wird gerade bearbeitet. Das ist scheisse, da beim Verlassen z.B. des Auftrag-Lieferung-Dialogs (Auftragspositionen)
            // der Auftrag gespeichert wird.
            this.setzeAktivenAuftrag(null, 'pruefeAktiverAuftrag');

            this.aktuellerauftragWurdeGeaendert.next();

            await Utils.delay(100);

            // Eventuelle Dialoge (Menge ändern etc.) schließen
            try {
                const topModal = await this.modalController.getTop();

                if (topModal) {
                    await this.modalController.dismiss();
                }
            } catch (err) {
                this.log.warn('Fehler beim Schließen des aktuellen Modals: ' + Utils.getErrorMessage(err));
            }

            await this.nav.navigateRoot('/home');

            await Utils.delay(250);

            const toast = await this.toastController.create({
                message: 'Auftrag ' + auftrag.Key + ' wurde aktualisiert',
                duration: 1500,
                color: 'danger'
            });

            toast.present();
        }
    }

    public istAktiverAuftrag(auftrag: Auftrag): boolean {
        if (!this.aktiverAuftrag) {
            this.log.info('IST aktiver Auftrag ' + auftrag.Key + ': FALSE (weil NULL)');
            return false;
        }

        const result = this.aktiverAuftrag.Key == auftrag.Key;

        if (result) {
            this.log.debug('IST aktiver Auftrag ' + auftrag.Key + ': TRUE');
        } else {
            this.log.info('IST aktiver Auftrag ' + auftrag.Key + ': FALSE (aktiver Auftrag ist ' + this.aktiverAuftrag.Key + ')');
        }

        return result;
    }

    public setzeAktivenAuftrag(auftrag: Auftrag, info: string) {
        if (auftrag) {
            this.log.info('SET aktiver Auftrag: ' + auftrag.Key + ' ' + info);
        } else {
            this.log.info('SET aktiver Auftrag: NULL' + ' ' + info);
        }

        this.aktiverAuftrag = auftrag;
    }

    private async verarbeitePushNotifications() {
        if (!this.notitifcationZurVerarbeitung.length) {
            return;
        }

        this.log.info('verarbeitePushNotifications (auftrag): ' + this.notitifcationZurVerarbeitung.length);

        const count = this.notitifcationZurVerarbeitung.length;

        for (let i = 0; i < count; i++) {
            const notitifcation = this.notitifcationZurVerarbeitung.pop();

            if (!notitifcation) {
                // Kann beim parallelen Verarbeiten auftreten
                break;
            }

            try {
                await this.verarbeitePushNotification(notitifcation);
            } catch (err) {
                this.log.error('Fehler beim verarbeiten der Push-Notitifcation: ' + err);

                const obj = notitifcation as any;

                if (!obj.failedCounter) {
                    obj.failedCounter = 1;
                } else {
                    obj.failedCounter++;
                }

                if (obj.failedCounter < 100) {
                    // Erneut probieren
                    this.notitifcationZurVerarbeitung.push(notitifcation);
                }
            }
        }
    }

    istStatusuebergangErlaubt(auftrag: AuftragEx, workflow: Workflow, status: WorkflowStatus) {
        let letzteStatusId = 'initial';

        if (!auftrag) {
            return true;
        }

        if (!workflow || workflow.fallback) {
            return true;
        }

        if (auftrag.statusmeldungen && auftrag.statusmeldungen.length > 0) {
            const id = auftrag.statusmeldungen[auftrag.statusmeldungen.length - 1].statusId;

            if (!id) {
                return true;
            }

            letzteStatusId = id.toString();
        }

        if (!status.erlaubteVorgaengerStatus) {
            return true;
        }

        const erlaubt = status.erlaubteVorgaengerStatus[letzteStatusId];

        if (erlaubt === false) {
            return false;
        }

        // Erlaubt oder nicht konfiguriert
        return true;
    }

    getErlaubteVorgaengerStatus(workflow: Workflow, status: WorkflowStatus): string[] {
        if (!workflow) {
            return [];
        }

        if (!status.erlaubteVorgaengerStatus) {
            return [];
        }

        const resultList: string[] = [];

        for (const id in status.erlaubteVorgaengerStatus) {
            if (id === 'initial') {
                continue;
            }

            if (status.erlaubteVorgaengerStatus.hasOwnProperty(id)) {
                const value = status.erlaubteVorgaengerStatus[id];

                if (value !== false) {
                    const s2 = workflow.status.find(p => p.id && p.id.toString() == id);

                    if (s2) {
                        resultList.push(s2.name);
                    }
                }
            }
        }

        return resultList;
    }

    getAktuellErlaubteStatusMeldungen(auftrag: AuftragEx, workflow: Workflow): WorkflowStatus[] {
        const resultList: WorkflowStatus[] = [];

        let letzterStatus = 'initial';

        if (auftrag.statusmeldungen && auftrag.statusmeldungen.length > 0) {
            letzterStatus = auftrag.statusmeldungen[auftrag.statusmeldungen.length - 1].name;
        }

        for (const status of workflow.status) {
            if (!status.erlaubteVorgaengerStatus) {
                resultList.push(status);
                continue;
            }

            if (status.erlaubteVorgaengerStatus[letzterStatus] !== false) {
                resultList.push(status);
            }
        }

        return resultList;
    }

    getStandardMenge(p: Auftragsposition): number {
        if (p.StandardMenge == null || typeof (p.StandardMenge) === 'undefined') {
            const artikel = this.stammdatenService.getAlleArtikel().getValue().find(x => x.artikelKey as any == p.ArtikelKey);

            if (artikel) {
                return artikel.StandardMenge;
            }
        } else if (p.StandardMenge) {
            return p.StandardMenge;
        }

        this.log.warn(`Keine Standard-Menge für Auftragsposition ${p.Bezeichnung} mit ArtikelKey '${p.ArtikelKey}' vorhanden`);

        return 1;
    }

    getMengeOption(p: Auftragsposition): MengeOptionTyp {
        let option: MengeOptionTyp;

        if (p.MengeOption === null || typeof (p.MengeOption) === 'undefined') {
            const artikel = this.stammdatenService.getAlleArtikel().getValue().find(x => x.artikelKey as any == p.ArtikelKey);

            if (artikel) {
                option = artikel.MengeOption;
            }
        } else {
            option = p.MengeOption;
        }

        if (!option) {
            option = MengeOptionTyp.Aenderbar;
        }

        return option;
    }

    updateStatus(a: AuftragEx | AuftragListItem) {
        a.status = '';
        a.statusText = '';

        if (a.Zusatzdaten?.Status) {
            a.status = a.Zusatzdaten.Status;

            switch (a.status) {
                case 'BereitsVerladen':
                    a.statusText = 'Bereits Verladen';
                    break;

                case 'BereitsKommissioniert':
                    a.statusText = 'Bereits Kommissioniert';
                    break;

                case 'TeilweiseVorbereitet':
                    a.statusText = 'Teilweise Vorbereitet';
                    break;

                case 'Vollpalette':
                    a.statusText = 'Vollpalette';
                    break;

                case 'NichtKommissionieren':
                    a.statusText = 'NICHT KOMMISSIONIEREN !';
                    break;

                case 'ReserveStatus1':
                    a.statusText = 'Reserve 1';
                    break;

                case 'ReserveStatus2':
                    a.statusText = 'Reserve 2';
                    break;
            }
        }
    }

    async starteZeiterfassung(auftrag: AuftragEx, art: ArbeitsartKonfiguration = null): Promise<boolean> {
        if (AuftragHelper.isAuftragImArchiv(auftrag)) {
            return false;
        }

        let aktuelleTour = App.current.aktuelleTour.getValue();

        if (!aktuelleTour) {
            UiHelper.showError('Keine Tour gestartet. Zeiterfassung nicht möglich');
            return false;
        }

        const zeitEintrag: ZeitEintragEx = {
            Guid: Utils.uuid(),
            StartDatum: Utils.nowIsoDateString(),
            Typ: 'Zeiterfassung',
            Zuordnung: ZeitEintragZuordnungTyp.Auftrag,
            AuftragKey: auftrag.Key,
            Auftragsnummer: auftrag.Auftragsnummer,
            TourKey: aktuelleTour.Key,
            auftragName1: auftrag.Abholadresse?.Name1,
            auftragName2: auftrag.Abholadresse?.Name2,
            auftragInfo1: auftrag.Info1,
            auftragInfo2: auftrag.Info2,
            auftragInfo3: auftrag.Info3,
        };

        if (art) {
            zeitEintrag.ArtKey = art.Key;
            zeitEintrag.ArtName = art.Name;
            zeitEintrag.ArtReferenz = art.Referenz;
        }

        const ok = await Zeiterfassung.starteAuftragZeiterfassung(auftrag, zeitEintrag);

        if (!ok) {
            return false;
        }

        await this.speichereAuftrag(auftrag);

        return true;
    }

    async unterbrecheZeiterfassung(auftrag: AuftragEx) {
        this.log.info('Unterbreche Zeiterfassung: ' + auftrag?.Key);

        await Zeiterfassung.unterbrecheAuftragZeiterfassung(auftrag);

        await this.speichereAuftrag(auftrag);
    }

    // async unterbrecheLaufendeAuftragszeiten(): Promise<boolean> {        
    //     const alleAuftraege = await this.getAlleAuftraegeListItems();
    //     const laufendeAuftraegeListItems = alleAuftraege.filter(p => p.zeiterfassungLaeuft);

    //     if (!laufendeAuftraegeListItems.length) {
    //         return true;
    //     }

    //     const auftragZeiterfassungAktiv = AppConfig.current.einstellungen.AuftragZeiterfassungAktiv == 'ja';

    //     if (!auftragZeiterfassungAktiv) {
    //         return true;
    //     }

    //     let auftragInfo: string;

    //     if (laufendeAuftraegeListItems.length == 1) {
    //         auftragInfo = 'Auftrag ' + laufendeAuftraegeListItems[0].Auftragsnummer;
    //     } else {
    //         auftragInfo = laufendeAuftraegeListItems.length + ' Aufträge';
    //     }

    //     const ok = await UiHelper.confirmJaNein(`Zeiterfassung für ${auftragInfo} läuft noch. Laufende Zeiterfassung beenden?`);

    //     if (!ok) {
    //         return false;
    //     }

    //     if (laufendeAuftraegeListItems.length) {
    //         for (const listItem of laufendeAuftraegeListItems) {
    //             const auftrag = await this.getAuftrag(listItem.Key, false);

    //             if (auftrag) {
    //                 await this.unterbrecheZeiterfassung(auftrag);
    //             }
    //         }
    //     }

    //     return true;
    // }
}
