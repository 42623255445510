<ion-header>
  <ion-toolbar class="auftrag-toolbar" #toolbar>
    <ion-buttons slot="start">
      <ion-button appClick (clicked)="onIgnorieren()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title><span id="page-title-span">{{ 'Führerscheinkontrolle' | translate }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content #content class="default section-content-white">
  <div class="section pb-0">
    <h1>{{ 'Hallo' | translate }} {{ personal.vorname }} {{ personal.nachname }},</h1>
    <p>{{ 'FuehrerscheinkontrolleText1' | translate }}</p>

    <p *ngIf="isFotos">{{ 'FuehrerscheinkontrolleTextFoto' | translate }}</p>

    <!-- <p *ngIf="isNFCScannen">{{ 'FuehrerscheinkontrolleTextNFC' | translate }}</p> -->
  </div>

  <div *ngIf="isNFCScannen" class="nfc-container" [class.erfolgreich]="isNfcErfolgreich">
    <div class="nfc-inner">
      <div class="icon-outer" *ngIf="!isNfcErfolgreich">
        <img src="/assets/icon/nfc.png" class="nfc-icon" />
        
        <div class="nfc-text-1">{{ 'FuehrerscheinkontrolleTextNFC' | translate }}</div>
      </div>
  
      <div class="icon-outer" *ngIf="isNfcErfolgreich">
        <app-checkmark></app-checkmark>
  
        <div class="nfc-text-2">{{ 'Führerschein erfolgreich gescannt' | translate }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="isFotos">
    <div class="section">
      <h1>{{ 'Foto Vorderseite' | translate }}</h1>

      <div class="foto-container">
        <img *ngIf="bild1.Bild" class="bild" [src]="domSanitizationService.bypassSecurityTrustUrl(bild1.imgSrc)" appClick (clicked)="onBildClicked(bild1)" />
        <img *ngIf="!bild1.Bild" class="bild nicht-vorhanden" src="assets/img/fuehrerschein-vorderseite.png" appClick (clicked)="onFotoAufnehmen(bild1)" />
      </div>

      <ion-button appClick (clicked)="onFotoAufnehmen(bild1)" class="button-md button-border" expand="full" color="light">{{ 'Foto aufnehmen' | translate }}</ion-button>
    </div>

    <div class="section">
      <h1>{{ 'Foto Rückseite' | translate }}</h1>

      <div class="foto-container">
        <img *ngIf="bild2.Bild" class="bild" [src]="domSanitizationService.bypassSecurityTrustUrl(bild2.imgSrc)" appClick (clicked)="onBildClicked(bild2)" />
        <img *ngIf="!bild2.Bild" class="bild nicht-vorhanden" src="assets/img/fuehrerschein-rueckseite.png" appClick (clicked)="onFotoAufnehmen(bild2)" />
      </div>

      <ion-button appClick (clicked)="onFotoAufnehmen(bild2)" class="button-md button-border" expand="full" color="light">{{ 'Foto aufnehmen' | translate }}</ion-button>
    </div>
  </div>

  <div class="section" *ngIf="isAblaufdatumErfassen">
    <ion-item lines="none">
      <ion-label position="stacked">{{ 'Ablaufdatum' | translate }}</ion-label>
      <ion-input type="text" [(ngModel)]="entity.Ablaufdatum" pattern="^\d{1,2}\.\d{1,2}\.\d{4}$" (keyup.enter)="app.closeKeyboard($event)" enterkeyhint="done" clearInput></ion-input>
      <ion-button class="datum-button" appClick (clicked)="selectDatum()" size="default" color="light" slot="end">...</ion-button>
    </ion-item>

  </div>

  <div class="section unterschrift-section" *ngIf="isUnterschriftErfassen && entity.Unterschrift">
    <h1>{{ 'Unterschrift' | translate }}</h1>

    <div class="ion-activatable" appClick (clicked)="starteUnterschrift()">
      <div *ngIf="entity.Unterschrift.Bild">
        <div class="unterschrift-container vorhanden">
          <img [src]="domSanitizationService.bypassSecurityTrustUrl(entity.Unterschrift.Bild)" />
        </div>
      </div>
      <div *ngIf="!entity.Unterschrift.Bild">
        <div class="unterschrift-container erforderlich">
          {{ 'Unterschrift erforderlich' | translate }}.<br />{{ 'Zum unterschreiben hier tippen' | translate }}.
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <ion-button expand="full" [color]="speichenButtonColor" class="button-large" appClick (clicked)="onSpeichern()">{{ 'Speichern' | translate }}</ion-button>
    <ion-button expand="full" color="dark" class="button-default" fill="clear" appClick (clicked)="onIgnorieren()">{{ 'Später' | translate }}...</ion-button>
  </div>
</ion-content>