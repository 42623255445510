import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { App } from 'src/app/api/helper/app';
import { NavController, IonInput, ToastController, ModalController } from '@ionic/angular';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { MandantFormulare, UvvPruefGegenstand, UvvPruefung, UvvPruefungEintrag, UvvKatalog, UvvEintrag, AdresseEntity, Textdatei, TextdateiTyp } from 'src/app/api/model/swagger-model';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { Camera } from '@ionic-native/camera/ngx';
import { FileHelper } from 'src/app/api/helper/file-helper';
import { File } from '@ionic-native/file/ngx';
import * as moment from 'moment';
import { ClickHelper } from 'src/app/api/helper/click-helper';
import { UvvService } from 'src/app/api/uvv.service';
import { UvvHelper } from 'src/app/api/helper/uvv-helper';
import { AppStorage } from 'src/app/api/helper/app-storage';
import { NFC, Ndef } from '@ionic-native/nfc/ngx';
import { Subscription } from 'rxjs';
import { NfcEvent, UvvPruefGegenstandEx } from 'src/app/api/model/model';
import { SystemService } from 'src/app/api/system.service';
import { AppConfig } from 'src/app/api/helper/app.config';
import { Utils } from 'src/app/api/helper/utils';
import { UvvRfidTauschPage } from '../uvv-rfid-tausch/uvv-rfid-tausch.page';
import { ImageHelper } from 'src/app/api/helper/image-helper';

const log = new Logger('UvvDetailsPage');

const FREIGABE = 'FREIGABE';

@Component({
    selector: 'app-uvv-details',
    templateUrl: './uvv-details.page.html',
    styleUrls: ['./uvv-details.page.scss'],
})
export class UvvDetailsPage implements OnInit {
    @ViewChild('kilometerstand', { read: IonInput, static: false }) kilometerstand: IonInput;

    @Input() gegenstand: UvvPruefGegenstandEx;

    /** Handelt es sich um eine Schnellprüfung von der Startseite? Das wird für Kunden gemacht die nur das Prüfdatum sehen wollen */
    @Input() isSchnellpruefung: boolean;

    form: UntypedFormGroup;
    katalog: UvvKatalog;

    app = App.current;
    mandanFormulare: MandantFormulare;

    standortListe: AdresseEntity[] = [];

    checklistEintraege: ChecklistEintragVO[] = [];
    fahrzeugKennzeichen = '';
    geladen = false;
    checklisteOk = true;
    startenButtonColor = 'primary';
    kameraAktiv = false;
    alertAktiv = false;
    pruefungAbgeschlossen = false;
    standortsichtbar = true;
    zustandSichtbar = true;
    nfcScanAktiv = false;
    rfidTauschErlaubt = true;
    uvvBerechtigt = true;
    auswahlReparierenVerschrottenSichtbar = false;

    nfcSubscriptions: Subscription[] = [];
    nfcRegistrationId: number;

    constructor(
        public formBuilder: UntypedFormBuilder,
        private camera: Camera,
        private file: File,
        private webView: WebView,
        private stammdatenService: StammdatenService,
        private uvvService: UvvService,
        private systemService: SystemService,
        private modalController: ModalController,
        private toastController: ToastController,
        public domSanitizationService: DomSanitizer,
        private cdr: ChangeDetectorRef) {

        this.form = this.formBuilder.group({
            fahrerName: ['', Validators.nullValidator],
            manuelleNummer: ['', Validators.nullValidator],
            bezeichnung: ['', Validators.nullValidator],
            gegenstandNummer: ['', Validators.nullValidator],
            datumNaechstePruefung: ['', Validators.nullValidator],
            datum: ['', Validators.nullValidator],
            katalogName: ['', Validators.nullValidator],
            standort: ['', Validators.nullValidator],
            zustand: ['', Validators.nullValidator],
            identNummer: ['', Validators.nullValidator],
            aktion: ['', Validators.nullValidator],
        });
    }

    static async show(gegenstand: UvvPruefGegenstand, isSchnellpruefung = false): Promise<void> {
        const uvvDetailsModal = await UiHelper.modalController.create({
            component: UvvDetailsPage,
            componentProps: {
                'gegenstand': gegenstand,
                'isSchnellpruefung': isSchnellpruefung
            }
        });

        log.debug('UvvDetailsPage wird angezeigt...');
        await uvvDetailsModal.present();
        await uvvDetailsModal.onDidDismiss();
        log.debug('UvvDetailsPage wurde geschlossen');
    }

    async ngOnInit() {
        log.debug('ngOnInit', this.gegenstand);

        if (this.geladen) {
            // View bereits geladen. nicht neu laden
            return;
        }

        if (this.gegenstand == null) {
            UiHelper.showError('UVV-Gegenstand nicht gesetzt');
            this.goBack();
            return;
        }

        this.katalog = this.uvvService.uvvKataloge.getValue().find(p => p.name == this.gegenstand.katalogName);

        if (!this.katalog) {
            UiHelper.showError(`Prüf-Katalog '${this.gegenstand.katalogName}' nicht gefunden. Bitte informieren Sie Ihren Administrator.`);
            this.goBack();
            return;
        }

        if (AppConfig.current.einstellungen.AnmeldungErforderlich === 'ja') {
            const fahrer = App.current.fahrer.getValue();

            if (!fahrer) {
                if (this.isSchnellpruefung) {
                    this.uvvBerechtigt = false;
                } else {
                    UiHelper.showAlert('Nicht angemeldet. UVV-Prüfung nicht möglich');
                    this.goBack();
                    return;
                }
            }

            if (fahrer) {
                if (!fahrer.uvvBerechtigt) {
                    this.uvvBerechtigt = false;
                    // UiHelper.showAlert('Sie sind nicht zur UVV-Prüfung berechtigt. Bitte kontaktieren Sie Ihren Administrator.');
                    // this.goBack();
                    // return;
                }
            }
        } else {
            this.uvvBerechtigt = false;
        }

        this.standortsichtbar = AppConfig.current.einstellungen.UvvBehaelterstandort === 'ja'
        this.zustandSichtbar = AppConfig.current.einstellungen.UvvZustand === 'ja'

        let pruefung = await this.uvvService.getAktiveUvvPruefung(this.gegenstand);

        if (!pruefung) {
            pruefung = {
                Datum: moment().toISOString(),
                Status: 0,
                Eintraege: []
            };
        }

        // if (!pruefung.StandortAdressKey) {
        //     pruefung.StandortAdressKey = this.gegenstand.standortAdressKey;
        // }

        if (!pruefung.Zustand && this.gegenstand.zustand == 'beschaedigt') {
            pruefung.Zustand = this.gegenstand.zustand;
        }

        this.detectChanges();

        this.pruefungAbgeschlossen = false;

        this.checklistEintraege = [];

        const eintraege = [...this.katalog.eintraege];

        eintraege.push({
            key: FREIGABE,
            bezeichnung: AppConfig.current.einstellungen['UvvFreigabeText'],
            pflicht: true,
            fotoPflicht: false,
            beschreibungPflicht: false,
        });

        for (const p of eintraege) {
            const vo: ChecklistEintragVO = {
                eintrag: p,
                okButtonColor: 'light',
                nokButtonColor: 'light',
                anmerkungen: '',
                bilder: [],
            };

            if (p.key === FREIGABE) {
                vo.cssClass = 'freigabe-eintrag';
            }

            this.checklistEintraege.push(vo);

            // Prüfe ob bereits Daten eingegeben wurden und fülle die Liste entsprechend
            const item = pruefung.Eintraege.find(x => x.Key && x.Key === p.key);

            if (item) {
                // Daten sind bereits vorhanden
                vo.ok = item.Status === 'OK';
                vo.nok = item.Status === 'NOK';

                if (vo.nok) {
                    vo.anmerkungen = item.Anmerkungen;
                    vo.bilder = [];

                    if (item.Bilder) {
                        for (const bild of item.Bilder) {
                            vo.bilder.push({
                                bild: bild.Bild,
                                guid: bild.BildGuid,
                                datum: bild.Datum,
                                src: null
                            });
                        }

                        this.updateBilder(vo);
                    }
                }

                this.aktualisiereButtonColor(vo);
            }
        }

        this.form.get('manuelleNummer').setValue(this.gegenstand.manuelleNummer);
        this.form.get('bezeichnung').setValue(this.gegenstand.bezeichnung);
        this.form.get('gegenstandNummer').setValue(UvvHelper.getBezeichnung(this.gegenstand));
        this.form.get('datum').setValue(moment().format('DD.MM.YYYY HH:mm'));

        if (this.gegenstand.naechstePruefung) {
            this.form.get('datumNaechstePruefung').setValue(moment(this.gegenstand.naechstePruefung).format('DD.MM.YYYY'));
        } else {
            this.form.get('datumNaechstePruefung').setValue('n/a');
        }

        this.form.get('katalogName').setValue(this.katalog.name);
        this.form.get('zustand').setValue(pruefung.Zustand);
        this.form.get('identNummer').setValue(this.gegenstand.identNummer);

        const fahrzeug = this.app.fahrzeug.getValue();

        if (fahrzeug) {
            this.fahrzeugKennzeichen = fahrzeug.kennzeichen;
        }

        this.standortListe = await this.stammdatenService.getAdressen();

        if (pruefung.StandortAdressKey) {
            const standort = this.standortListe.find(p => p.Key === pruefung.StandortAdressKey);
            this.form.get('standort').setValue(standort);
            // standort
            // this.standort = this.standortListe.find(p => p.Key === pruefung.StandortAdressKey);
        }

        this.rfidTauschErlaubt = Utils.isTrue(AppConfig.current.einstellungen.UvvRfidTauschErlaubt);

        this.geladen = true;

        this.aktualisiereVollstaendig();

        this.nfcRegistrationId = this.systemService.registerNfcReceiver(10, (nfcEvent) => this.verarbeiteNfcEvent(nfcEvent));

        this.detectChanges();
    }

    async ionViewWillEnter() {
        log.debug('ionViewWillEnter');

        await this.uvvService.ready();

        const fahrer = App.current.fahrer.getValue();

        if (fahrer) {
            this.form.get('fahrerName').setValue(fahrer.vorname + ' ' + fahrer.nachname);
        }

        this.cdr.detectChanges();
    }

    private async updateBilder(vo: ChecklistEintragVO): Promise<void> {
        if (!vo.bilder) {
            vo.bilder = [];
        }

        for (const bild of vo.bilder) {
            let src = bild.bild;

            if (src && src.startsWith('file:')) {
                // Bild ist als Datei gespeichert und es wird hier der Pfad zurückgegeben
                src = this.webView.convertFileSrc(src);
            } else {
                // Bild ist als Base64 hinterlegt
                if (!src.startsWith("data:image/")) {
                    src = 'data:image/jpeg;base64,' + src;
                }
            }

            bild.src = src;
        }
    }

    ionViewDidEnter() {
    }

    ionViewWillLeave() {
        try {
            this.systemService.unregisterNfcReceiver(this.nfcRegistrationId);

            if (this.geladen && !this.pruefungAbgeschlossen) {
                this.speichern();
            }
        } catch (err) {
            log.error('ionViewWillLeave', err);
        }
    }

    standortChanged(e) {
        log.debug('standortChanged', e);
        // this.standort = e.value;

        // if (e.value) {
        //     this.standortKey = e.value.Key;
        // } else {
        //     this.standortKey = null;
        // }
    }

    createUvvPruefung(): UvvPruefung {
        const pruefung: UvvPruefung = {
            Eintraege: [],
            Datum: moment().toISOString(),
            Status: 0,
            GegenstandKey: this.gegenstand.key,
            KatalogName: this.katalog.name,
            Pruefergebnis: this.getFreigabeErteilt(),
            Zustand: this.form.get('zustand').value,
            StandortAdressKey: null,
            Aktion: null
        };

        const fahrer = App.current.fahrer.getValue();

        if (fahrer) {
            pruefung.PrueferPersonalnummer = fahrer.personalnummer;
            pruefung.Pruefer = fahrer.vorname + ' ' + fahrer.nachname;
        }

        const standort = this.form.get('standort').value;
        if (standort) {
            pruefung.StandortAdressKey = standort.Key;
        }

        for (const vo of this.checklistEintraege) {
            const item: UvvPruefungEintrag = {
                Key: vo.eintrag.key,
                Name: vo.eintrag.bezeichnung,
                Status: null
            };

            if (vo.anmerkungen) {
                item.Anmerkungen = vo.anmerkungen;
            }

            if (vo.ok) {
                item.Status = 'OK';
            } else if (vo.nok) {
                item.Status = 'NOK';
            }

            if (vo.bilder && vo.bilder.length) {
                item.Bilder = [];

                for (const bildVo of vo.bilder) {
                    item.Bilder.push({
                        Bild: bildVo.bild,
                        BildGuid: bildVo.guid,
                        Datum: bildVo.datum
                    });
                }
            }

            pruefung.Eintraege.push(item);
        }

        const freigabe = this.getFreigabeErteilt();

        if (freigabe === 'NOK') {
            pruefung.Aktion = this.form.get('aktion').value;
        }

        return pruefung;
    }

    async onAbschliessen(e: Event) {
        ClickHelper.click(e, () => this.doAbschliessen());
    }

    async doAbschliessen() {
        try {
            this.form.updateValueAndValidity();

            if (!this.form.valid) {
                UiHelper.showAlert('Formular nicht vollständig ausgefüllt');
                return;
            }

            for (const p of this.checklistEintraege) {
                if (!p.ok && !p.nok) {
                    UiHelper.showAlert('Bitte Prüfungen vollständig ausfüllen', null);
                    return;
                }

                if (p.nok) {
                    if (p.bilder.length <= 0 && p.eintrag.fotoPflicht) {
                        UiHelper.showAlert(`Bitte Foto für '${p.eintrag.bezeichnung}' aufnehmen`, null);
                        return;
                    }

                    if (!p.anmerkungen && p.eintrag.beschreibungPflicht) {
                        UiHelper.showAlert(`Bitte Fehlerbeschreibung für '${p.eintrag.bezeichnung}' eingeben`, null);
                        return;
                    }
                }
            }

            const freigabe = this.getFreigabeErteilt();

            if (freigabe == 'NOK') {
                // Es muss mindestens ein Eintrag NOK sein.
                let einEintragNok = false;

                for (const p of this.checklistEintraege) {
                    if (p.eintrag.key != FREIGABE && p.nok) {
                        einEintragNok = true;
                        break;
                    }
                }

                if (!einEintragNok) {
                    const freigabeAnmerkung = this.checklistEintraege.find(p => p.eintrag.key == FREIGABE).anmerkungen;

                    if (!freigabeAnmerkung) {
                        UiHelper.showAlert('Bitte Fehlerbeschreibung erfassen.', null);
                        return;
                    }
                }

                this.aktualisiereVollstaendig()

                if (this.auswahlReparierenVerschrottenSichtbar) {
                    const aktion = this.form.get('aktion');

                    if (!aktion.value) {
                        UiHelper.showAlert('Bitte Aktion auswählen.', null);
                        return;
                    }
                }

                const ok = await UiHelper.confirmJaNein('UVV-Prüfung wirklich abschließen?');

                if (!ok) {
                    return;
                }

            } else if (freigabe == 'OK') {
                let einEintragNok = false;

                for (const p of this.checklistEintraege) {
                    if (!p.ok && !p.nok) {
                        UiHelper.showAlert('Bitte Prüfungen vollständig ausfüllen', null);
                        return;
                    }

                    if (p.nok) {
                        einEintragNok = true;
                    }

                    // if (p.nok) {
                    //     // Foto ist pflicht, falls nicht konfiguriert
                    //     if (p.bilder.length <= 0 && p.eintrag.fotoPflicht !== false) {
                    //         UiHelper.showAlert(`Bitte Foto für '${p.eintrag.name}' aufnehmen`, null);
                    //         return;
                    //     }

                    //     // Anmekrung ist optional, falls nicht konfiguriert
                    //     if (!p.anmerkungen && p.eintrag.beschreibungPflicht === true) {
                    //         UiHelper.showAlert(`Bitte Fehlerbeschreibung für '${p.eintrag.name}' eingeben`, null);
                    //         return;
                    //     }
                    // }
                }

                if (einEintragNok) {
                    const ok = await UiHelper.confirmJaNein('Es wurde mindestens eine Prüfung mit Nicht-OK angegeben. Freigabe trotzdem erteilen?');

                    if (!ok) {
                        return;
                    }
                } else {
                    const ok = await UiHelper.confirmJaNein('Prüfung wirklich abschließen?');

                    if (!ok) {
                        return;
                    }
                }
            } else {
                UiHelper.showAlert('Freigabe nicht erteilt', null);
                return;
            }

            const uvvPruefung = this.createUvvPruefung();

            await this.uvvService.abschliessenPruefung(uvvPruefung);

            this.pruefungAbgeschlossen = true;

            this.goBack();
        } catch (err) {
            UiHelper.showError(err);
        }
    }

    onChecklistItemChanged(e: Event, eintrag: ChecklistEintragVO, ok: boolean) {
        ClickHelper.click(e, () => this.doChecklistItemChanged(eintrag, ok));
    }

    doChecklistItemChanged(eintrag: ChecklistEintragVO, ok: boolean) {
        log.info('doChecklistItemChanged', ok);

        if (ok) {
            eintrag.ok = true;
            eintrag.nok = false;
        } else {
            eintrag.ok = false;
            eintrag.nok = true;
        }

        this.aktualisiereButtonColor(eintrag);
        this.aktualisiereChecklisteOk();
        this.aktualisiereVollstaendig();

        this.speichern();

        this.detectChanges();
    }

    async speichern(): Promise<void> {
        const uvvPruefung = this.createUvvPruefung();

        await this.uvvService.speicherePruefung(uvvPruefung);
    }

    aktualisiereButtonColor(vo: ChecklistEintragVO) {
        if (vo.ok) {
            vo.okButtonColor = 'success';
            vo.nokButtonColor = 'light';
        } else if (vo.nok) {
            vo.okButtonColor = 'light';
            vo.nokButtonColor = 'danger';
        } else {
            vo.okButtonColor = 'light';
            vo.nokButtonColor = 'light';
        }
    }

    aktualisiereChecklisteOk() {
        this.checklisteOk = true;

        for (const vo of this.checklistEintraege) {
            if (vo.nok) {
                this.checklisteOk = false;
                break;
            }
        }
    }

    aktualisiereVollstaendig() {
        let allesOk = true;

        // const kilometerstand = this.form.get('kilometerstand').value;

        // if (!kilometerstand) {
        //     vollstaendig = false;
        // }

        for (const vo of this.checklistEintraege) {
            if (!vo.ok) {
                allesOk = false;
                break;
            }
        }

        const freigabe = this.getFreigabeErteilt();

        if (freigabe === 'OK') {
            if (allesOk) {
                this.startenButtonColor = 'success';
            } else {
                this.startenButtonColor = 'light';
            }
        } else if (freigabe === 'NOK') {
            this.startenButtonColor = 'danger';
        } else {
            this.startenButtonColor = 'light';
        }

        if (freigabe === 'NOK' && AppConfig.current.einstellungen.UvvAuswahlAktion === 'reparieren-verschrotten') {
            this.auswahlReparierenVerschrottenSichtbar = true;
        } else {
            this.auswahlReparierenVerschrottenSichtbar = false;
        }
    }

    getFreigabeErteilt(): 'OK' | 'NOK' | null {
        const eintrag = this.checklistEintraege.find(p => p.eintrag.key == FREIGABE);

        if (eintrag) {
            if (eintrag.ok) {
                return 'OK';
            }

            if (eintrag.nok) {
                return 'NOK';
            }
        }

        return null;
    }

    onFotoAufnehmen(e: Event, vo: ChecklistEintragVO) {
        ClickHelper.click(e, () => this.doFotoAufnehmen(vo));
    }

    async doFotoAufnehmen(vo: ChecklistEintragVO) {
        // imageData is a base64 encoded string
        // this.base64Image = "data:image/jpeg;base64," + imageData;
        let pictureSrc: string = null;

        this.kameraAktiv = true;

        try {
            pictureSrc = await this.camera.getPicture({
                destinationType: this.camera.DestinationType.FILE_URI,
                encodingType: this.camera.EncodingType.JPEG,
                mediaType: this.camera.MediaType.PICTURE,
                sourceType: this.camera.PictureSourceType.CAMERA,
                quality: App.current.qualitaetFotos,
                targetWidth: App.current.aufloesungFotos,
                targetHeight: App.current.aufloesungFotos,
                correctOrientation: true
            });

            // Beispiel: pictureSrc='file:///storage/emulated/0/Android/data/de.recomobil.app/cache/1570482608018.jpg'

            if (pictureSrc && pictureSrc.startsWith('file:')) {
                // Extract just the filename. Result example: cdv_photo_003.jpg
                const tempFilename = FileHelper.getFilename(pictureSrc);

                // Now, the opposite. Extract the full path, minus filename.
                // Result example: file:///var/mobile/Containers/Data/Application
                // /E4A79B4A-E5CB-4E0C-A7D9-0603ECD48690/tmp/
                const tempBaseFilesystemPath = FileHelper.getDirectory(pictureSrc);

                // Get the Data directory on the device.
                // Result example: file:///var/mobile/Containers/Data/Application
                // /E4A79B4A-E5CB-4E0C-A7D9-0603ECD48690/Library/NoCloud/
                const newBaseFilesystemPath = this.file.dataDirectory;
                const newFilename = FileHelper.removeIllegalFilenameChars('uvv-pruefung-' + this.gegenstand.key + '-' + vo.eintrag.key + '-' + tempFilename);

                const entry = await this.file.copyFile(tempBaseFilesystemPath, tempFilename, newBaseFilesystemPath, newFilename);

                log.info('copyFile', entry);

                // Result example: file:///var/mobile/Containers/Data/Application
                // /E4A79B4A-E5CB-4E0C-A7D9-0603ECD48690/Library/NoCloud/cdv_photo_003.jpg
                pictureSrc = newBaseFilesystemPath + newFilename;

                let text = AppConfig.current.einstellungen.FotosTextUVV;

                if (text) {
                    const now = moment();

                    const platzhalter: any = {
                        'AktuellesDatum': now.format('DD.MM.YYYY'),
                        'AktuelleUhrzeit': now.format('HH:mm'),
                        'Fahrzeug': App.current.getFahrzeugKennzeichen(),
                        'PersonalKey': App.current.getPersonalKey(),
                        'Personalnummer': App.current.getPersonalnummer(),
                        'PersonalName': App.current.getPersonalName(),
                        'Key': this.gegenstand.key,
                        'Bezeichnung': this.gegenstand.bezeichnung,
                        'IdentNummer': this.gegenstand.identNummer,
                        'ManuelleNummer': this.gegenstand.manuelleNummer,
                        'Zustand': this.gegenstand.zustand,
                        'Kennzeichen': this.gegenstand.Kennzeichen,
                        'Herstellernummer': this.gegenstand.Herstellernummer,
                        'Guid': this.gegenstand.Guid,
                    }

                    if (text.includes('{GPS}')) {
                        platzhalter['GPS'] = await SystemService.instance.getAktuelleGpsPositionStr('Foto', 10);
                    }

                    const base64DataUrl = await this.file.readAsDataURL(newBaseFilesystemPath, newFilename);

                    const blob = await ImageHelper.writeImageText(base64DataUrl, text, platzhalter);

                    await this.file.writeExistingFile(newBaseFilesystemPath, newFilename, blob);
                }
            }

        } catch (err) {
            log.warn(err);

            if (err === "cordova_not_available") {
                err = 'Kamera im Browser nicht verfügbar.';

                // Derzeit nur zum testen
                pictureSrc = 'R0lGODlhPQBEAPeoAJosM//AwO/AwHVYZ/z595kzAP/s7P+goOXMv8+fhw/v739/f+8PD98fH/8mJl+fn/9ZWb8/PzWlwv///6wWGbImAPgTEMImIN9gUFCEm/gDALUL'
                    + 'DN8PAD6atYdCTX9gUNKlj8wZAKUsAOzZz+UMAOsJAP/Z2ccMDA8PD/95eX5NWvsJCOVNQPtfX/8zM8+QePLl38MGBr8JCP+zs9myn/8GBqwpAP/GxgwJCPny78lzYLgjAJ8vAP9'
                    + 'fX/+MjMUcAN8zM/9wcM8ZGcATEL+QePdZWf/29uc/P9cmJu9MTDImIN+/r7+/vz8/P8VNQGNugV8AAF9fX8swMNgTAFlDOICAgPNSUnNWSMQ5MBAQEJE3QPIGAM9AQMqGcG9vb6'
                    + 'MhJsEdGM8vLx8fH98AANIWAMuQeL8fABkTEPPQ0OM5OSYdGFl5jo+Pj/+pqcsTE78wMFNGQLYmID4dGPvd3UBAQJmTkP+8vH9QUK+vr8ZWSHpzcJMmILdwcLOGcHRQUHxwcK9PT'
                    + '9DQ0O/v70w5MLypoG8wKOuwsP/g4P/Q0IcwKEswKMl8aJ9fX2xjdOtGRs/Pz+Dg4GImIP8gIH0sKEAwKKmTiKZ8aB/f39Wsl+LFt8dgUE9PT5x5aHBwcP+AgP+WltdgYMyZfyyw'
                    + 'z78AAAAAAAD///8AAP9mZv///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
                    + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
                    + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAKgALAAAAAA9AEQAAAj/AFEJH'
                    + 'EiwoMGDCBMqXMiwocAbBww4nEhxoYkUpzJGrMixogkfGUNqlNixJEIDB0SqHGmyJSojM1bKZOmyop0gM3Oe2liTISKMOoPy7GnwY9CjIYcSRYm0aVKSLmE6nfq05QycVLPuhDrx'
                    + 'BlCtYJUqNAq2bNWEBj6ZXRuyxZyDRtqwnXvkhACDV+euTeJm1Ki7A73qNWtFiF+/gA95Gly2CJLDhwEHMOUAAuOpLYDEgBxZ4GRTlC1fDnpkM+fOqD6DDj1aZpITp0dtGCDhr+f'
                    + 'VuCu3zlg49ijaokTZTo27uG7Gjn2P+hI8+PDPERoUB318bWbfAJ5sUNFcuGRTYUqV/3ogfXp1rWlMc6awJjiAAd2fm4ogXjz56aypOoIde4OE5u/F9x199dlXnnGiHZWEYbGpsA'
                    + 'EA3QXYnHwEFliKAgswgJ8LPeiUXGwedCAKABACCN+EA1pYIIYaFlcDhytd51sGAJbo3onOpajiihlO92KHGaUXGwWjUBChjSPiWJuOO/LYIm4v1tXfE6J4gCSJEZ7YgRYUNrkji'
                    + '9P55sF/ogxw5ZkSqIDaZBV6aSGYq/lGZplndkckZ98xoICbTcIJGQAZcNmdmUc210hs35nCyJ58fgmIKX5RQGOZowxaZwYA+JaoKQwswGijBV4C6SiTUmpphMspJx9unX4Kaimj'
                    + 'Dv9aaXOEBteBqmuuxgEHoLX6Kqx+yXqqBANsgCtit4FWQAEkrNbpq7HSOmtwag5w57GrmlJBASEU18ADjUYb3ADTinIttsgSB1oJFfA63bduimuqKB1keqwUhoCSK374wbujvOS'
                    + 'u4QG6UvxBRydcpKsav++Ca6G8A6Pr1x2kVMyHwsVxUALDq/krnrhPSOzXG1lUTIoffqGR7Goi2MAxbv6O2kEG56I7CSlRsEFKFVyovDJoIRTg7sugNRDGqCJzJgcKE0ywc0ELm6'
                    + 'KBCCJo8DIPFeCWNGcyqNFE06ToAfV0HBRgxsvLThHn1oddQMrXj5DyAQgjEHSAJMWZwS3HPxT/QMbabI/iBCliMLEJKX2EEkomBAUCxRi42VDADxyTYDVogV+wSChqmKxEKCDAY'
                    + 'FDFj4OmwbY7bDGdBhtrnTQYOigeChUmc1K3QTnAUfEgGFgAWt88hKA6aCRIXhxnQ1yg3BCayK44EWdkUQcBByEQChFXfCB776aQsG0BIlQgQgE8qO26X1h8cEUep8ngRBnOy74E'
                    + '9QgRgEAC8SvOfQkh7FDBDmS43PmGoIiKUUEGkMEC/PJHgxw0xH74yx/3XnaYRJgMB8obxQW6kL9QYEJ0FIFgByfIL7/IQAlvQwEpnAC7DtLNJCKUoO/w45c44GwCXiAFB/OXAAT'
                    + 'QryUxdN4LfFiwgjCNYg+kYMIEFkCKDs6PKAIJouyGWMS1FSKJOMRB/BoIxYJIUXFUxNwoIkEKPAgCBZSQHQ1A2EWDfDEUVLyADj5AChSIQW6gu10bE/JG2VnCZGfo4R4d0sdQoB'
                    + 'AHhPjhIB94v/wRoRKQWGRHgrhGSQJxCS+0pCZbEhAAOw==';
            } else if (err == 20) {
                await UiHelper.showAlert('ReCoMobil hat kein Zugriff auf die Kamera. Bitte Berechtigung für die Kamera in den App-Einstellungen aktivieren', 'Fehler', true);
            } else if (err == 'No Image Selected') {
                // Aufnahme wurde abgebrochen
            } else {
                UiHelper.showError('Fehler Kamera: ' + Utils.getErrorMessage(err), 'Fehler', false, false);
            }
        }

        if (pictureSrc) {
            log.debug('pictureSrc', pictureSrc);

            const bild: BildVO = {
                datum: moment().toISOString(),
                bild: pictureSrc,
                guid: Utils.uuid(),
                src: null
            };

            if (!vo.bilder) {
                vo.bilder = [];
            }

            vo.bilder.push(bild);

            await AppStorage.current.set('bild-' + bild.guid, pictureSrc, false);
        }

        this.updateBilder(vo);

        this.speichern();

        this.detectChanges();

        setTimeout(() => {
            this.kameraAktiv = false;
        }, 250);
    }

    async onBildClicked(item: ChecklistEintragVO, bild: BildVO) {
        const ok = await UiHelper.confirmJaNein('Bild wirklich löschen?', 'Frage');

        if (ok) {
            const idx = item.bilder.indexOf(bild);

            if (idx > -1) {

                item.bilder.splice(idx, 1);

                await this.updateBilder(item);

                FileHelper.deleteFile(bild.src);
            }
        }
    }

    async onClose() {
        log.debug('onClose');
        this.goBack();
    }

    async goBack() {
        if (this.kameraAktiv || this.alertAktiv) {
            // ignoriere Hardware-Back-Button
            return;
        }

        log.debug('goBack');

        this.modalController.dismiss({
            'dismissed': true
        });
    }

    private detectChanges() {
        try {
            this.cdr.detectChanges();
        } catch (err) {
            // ignore
        }
    }

    closeRfid() {
        this.nfcScanAktiv = false;

        UiHelper.hideLoading();
    }

    async onRfidScannen() {
        if (!App.isCordovaAvailable()) {
            UiHelper.showAlert('Cordova nicht verfügbar');
            return;
        }

        this.nfcScanAktiv = true;

        const loading = await UiHelper.presentLoading('RFIDWirdGescannt', true);

        loading.onDidDismiss().then(() => {
            this.closeRfid();
        });

        // try {
        //     log.debug('addNdefListener');

        //     this.nfcSubscriptions.push(this.nfc.addNdefListener(async () => {
        //         log.debug('NFC listener added');
        //     }, (err) => {
        //         log.warn('NFC fehler', err);
        //         this.closeRfid();
        //         UiHelper.showError(err);
        //     }).subscribe(async (event) => {
        //         log.info('NFC event', event);
        //         this.verarbeiteNfcEvent(event);
        //     }));

        //     log.debug('NFC addNdefFormatableListener');

        //     this.nfcSubscriptions.push(this.nfc.addNdefFormatableListener(async () => {
        //         log.debug('NFC addNdefFormatableListener added');
        //     }, (err) => {
        //         log.warn('NFC addNdefFormatableListener fehler: ' + JSON.stringify(err), err);
        //         this.closeRfid();
        //         UiHelper.showError(err);
        //     }).subscribe(async (event) => {
        //         log.debug('NFC ndef-formatable event: ' + JSON.stringify(event));
        //         this.verarbeiteNfcEvent(event);
        //     }));
        // } catch (err) {
        //     UiHelper.showError(err);
        // }
    }

    async verarbeiteNfcEvent(event: NfcEvent) {
        log.debug('verarbeiteNfcEvent', event);

        if (!this.nfcScanAktiv) {
            // Nicht verarbeiten. Es soll gerade nichtg escannt werden
            return;
        }

        try {
            this.closeRfid();

            let rfid = event.seriennummer;

            if (App.current.NfcPayloadVerwenden && event.payloadList.length) {
                for (const payload of event.payloadList) {
                    if (payload.length > 5) {
                        rfid = payload.trim().toUpperCase();
                    }
                }
            }

            if (rfid) {
                const ok = await UiHelper.confirmJaNein(`RFID '${rfid}' dem Prüfgegenstand ${this.gegenstand.manuelleNummer} jetzt zuordnen?`);

                if (ok) {
                    // Prüfe ob die neue RFID-Nummer bereits in Verwendung ist
                    const neueRfidPruefen = AppConfig.current.einstellungen.UvvNeueRfidPruefen;

                    if (neueRfidPruefen !== 'aus') {
                        const vorhandenerGegenstand = this.uvvService.getBehaelterByBehaelterNr(rfid, true);

                        if (vorhandenerGegenstand && vorhandenerGegenstand.key !== this.gegenstand.key) {
                            if (neueRfidPruefen === 'fehler') {
                                UiHelper.showAlert(`RFID '${rfid}' bereits in Verwendung von '${UvvHelper.getBezeichnung(vorhandenerGegenstand)}'. Nummer kann nicht doppelt vergeben werden.`);
                                return;
                            } else if (neueRfidPruefen === 'warnung') {
                                const ok = await UiHelper.confirmJaNein(`ACHTUNG: RFID '${rfid}' bereits in Verwendung von '${UvvHelper.getBezeichnung(vorhandenerGegenstand)}'. Wirklich doppelt vergeben?`);

                                if (!ok) {
                                    return;
                                }
                            }
                        }
                    }

                    this.gegenstand.identNummer = rfid;

                    this.form.get('identNummer').setValue(this.gegenstand.identNummer);

                    await this.uvvService.speichereGegenstand(this.gegenstand);

                    this.cdr.detectChanges();

                    const textdatei: Textdatei = {
                        typ: TextdateiTyp.UvvGegenstandAktualisiert,
                        datum: moment().toISOString(),
                        geraeteNummer: AppConfig.current.geraeteNummer,
                        stammdaten: {
                            Uvv: [{
                                Bezeichnung: this.gegenstand.bezeichnung,
                                IdentNummer: this.gegenstand.identNummer,
                                Key: this.gegenstand.key,
                                ManuelleNummer: this.gegenstand.manuelleNummer,
                                Typ: this.gegenstand.typ,
                                LetztePruefung: this.gegenstand.letztePruefung,
                                NaechstePruefung: this.gegenstand.naechstePruefung,
                                UvvKatalog: this.gegenstand.katalogName
                            }]
                        }
                    };

                    this.systemService.sendeTextdatei(textdatei, true);
                }
            }
        } catch (err) {
            log.error('verarbeiteNfcEvent: ' + err, err);
        }
    }

    async onRfidTausch() {
        log.info('onRfidTausch');

        const uvvDetailsModal = await UiHelper.modalController.create({
            component: UvvRfidTauschPage,
            componentProps: {
                'gegenstand': this.gegenstand
            }
        });

        log.debug('UvvRfidTauschPage wird angezeigt...');
        await uvvDetailsModal.present();
        await uvvDetailsModal.onDidDismiss();
        log.debug('UvvRfidTauschPage wurde geschlossen');

        this.form.get('identNummer').setValue(this.gegenstand.identNummer);

        this.cdr.detectChanges();
    }
}

interface ChecklistEintragVO {
    eintrag: UvvEintrag;
    ok?: boolean;
    nok?: boolean;
    okButtonColor?: string;
    nokButtonColor?: string;
    anmerkungen?: string;
    bilder: BildVO[];
    cssClass?: string;
}

interface BildVO {
    src: string;
    bild?: string;
    guid: string;
    datum: string;
}