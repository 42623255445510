<table class="app-table lieferung-table" *ngIf="!customAnsicht">
  <tbody>
    <ng-template ngFor let-vo [ngForOf]="positionen">
      <tr class="position-header" *ngIf="!istEinfacheAnsicht(vo.position)" [class.highlighted]="vo.istHighlighted | async">
        <td *ngIf="posNrSichtbar" class="posnr-col">{{ vo.position.PosNr }}.</td>
        <td *ngIf="mengeSichtbar" class="menge-col">{{ vo.position.Menge }}</td>
        <td *ngIf="einheitSichtbar" class="einheit-col">{{ vo.position.Einheit }}</td>
        <td *ngIf="gewichtSichtbar" class="gewicht-col">{{ vo.position.GewichtInKg }} <span *ngIf="vo.position.GewichtInKg != null">kg</span></td>
        <td *ngIf="bruttoSichtbar" class="preis-col" [class.mit-ep]="vo.position.Menge && vo.position.Menge > 1 && vo.position.EinzelVK">
          <div *ngIf="vo.position.NettoBetrag === null">
            <span class="preis-ungueltig">??? €</span>
          </div>
          <div *ngIf="vo.position.NettoBetrag !== null">
            {{ vo.position.NettoBetrag | preis }} €
          </div>
          <div *ngIf="(epImmerAnzeigen || (vo.position.Menge && vo.position.Menge > 1)) && vo.position.EinzelVK" class="einzelpreis">
            EP {{ vo.position.EinzelVK | ep }} €
          </div>
        </td>
        <td *ngIf="istPlusMinusButtonsAnzeigen(vo.position)" class="plusminus-buttons" rowspan="2">
          <ion-button slot="end" color="light" appClick (clicked)="reduziereMenge(vo.position)" class="plusminus-button minus-button">-</ion-button>
          <ion-button slot="end" color="light" appClick (clicked)="erhoeheMenge(vo.position)" class="plusminus-button plus-button">+</ion-button>
        </td>
      </tr>
      <tr *ngIf="bezeichnungSichtbar && !istEinfacheAnsicht(vo.position)" class="position-bezeichnung" [class.highlighted]="vo.istHighlighted | async">
        <td *ngIf="posNrSichtbar" class="posnr-col">{{ vo.position.PosNr }}.</td>
        <td class="beschreibung-col" [attr.colspan]="bezeichnungColspan">{{ vo.position.Bezeichnung }}<span *ngIf="vo.position.BhAktion"> ({{ vo.position.BhAktion }})</span></td>
      </tr>

      <tr class="position-header" *ngIf="istEinfacheAnsicht(vo.position)" [class.highlighted]="vo.istHighlighted | async">
        <td *ngIf="posNrSichtbar" class="posnr-col">{{ vo.position.PosNr }}.</td>
        <td *ngIf="mengeSichtbar" class="menge-col">{{ vo.position.Menge }}</td>
        <td *ngIf="einheitSichtbar" class="einheit-col">{{ vo.position.Einheit }}</td>
        <td *ngIf="bezeichnungSichtbar" class="beschreibung-col">{{ vo.position.Bezeichnung }}<span *ngIf="vo.position.BhAktion"> ({{ vo.position.BhAktion }})</span></td>
      </tr>
    </ng-template>

    <ng-container *ngIf="bruttoSichtbar && summeBrutto && auftrag.PreiseGueltig">
      <tr class="summe-netto">
        <td [attr.colspan]="summeColspan">Netto</td>
        <td class="preis-col">{{ summeNetto | preis }} €</td>
      </tr>

      <tr *ngFor="let mwstSumme of mwstSummen" class="summe-mwst">
        <td [attr.colspan]="summeColspan">MwSt. {{ mwstSumme.mwstSatz }}%</td>
        <td class="preis-col">{{ mwstSumme.betrag | preis }} €</td>
      </tr>

      <tr class="summe">
        <td [attr.colspan]="summeColspan">Summe</td>
        <td class="preis-col">{{ summeBrutto | preis }} €</td>
      </tr>
    </ng-container>

    <ng-container *ngIf="bruttoSichtbar && !auftrag.PreiseGueltig">
      <tr class=" preis-ungueltig">
        <td [attr.colspan]="summeColspan + 2">Preis kann nicht berechnet werden</td>
      </tr>
    </ng-container>

    <ng-container *ngIf="summeAnzahlSichtbar">
      <tr class="summe">
        <td [attr.colspan]="3">Gesamt: {{ summeAnzahl }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>

<table class="app-table lieferung-table custom-Kommissionierung1" *ngIf="customAnsicht === 'Kommissionierung1'">
  <tbody>
    <ng-template ngFor let-vo [ngForOf]="positionen">
      <tr [class.highlighted]="vo.istHighlighted | async">
        <td class="menge-col">{{ vo.position.Menge }} x</td>
        <td class="posnr-lpn">{{ vo.position.LPN }}</td>
        <td class="beschreibung-col">{{ vo.position.Bezeichnung }}<span *ngIf="vo.position.ArtikelKey">, ARTNR: {{ vo.position.ArtikelKey }}</span></td>
        <!-- <td class="artikel-col">{{ vo.position.ArtikelKey }}</td> -->
        <!-- <td class="charge-col"></td> -->
        <td class="checkbox-col">
          <ion-checkbox [(ngModel)]="vo.position.Fertig" (ionChange)="onFertigChanged(vo, $event)" [disabled]="vo.checkboxDisabled"></ion-checkbox>
        </td>
      </tr>
    </ng-template>

    <ng-container *ngIf="summeAnzahlSichtbar">
      <tr class="summe">
        <td [attr.colspan]="4">Gesamt: {{ summeAnzahl }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>

<table class="app-table lieferung-table custom-wareneingang" *ngIf="customAnsicht === 'wareneingang'">
  <tbody>
    <ng-template ngFor let-vo [ngForOf]="positionen">
      <tr [class.highlighted]="vo.istHighlighted | async">
        <td class="menge-col"><div>{{ vo.position.Menge }}</div></td>
        <td class="beschreibung-col">{{ vo.position.Bezeichnung }}</td>
        <!-- <td class="checkbox-col">
          <ion-checkbox [(ngModel)]="vo.position.Fertig" (ionChange)="onFertigChanged(vo)"></ion-checkbox>
        </td> -->
      </tr>
    </ng-template>

    <ng-container *ngIf="summeAnzahlSichtbar">
      <tr class="summe">
        <td [attr.colspan]="4">Gesamt: {{ summeAnzahl }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>

<table class="app-table lieferung-table custom-001" *ngIf="customAnsicht === '001'">
  <tbody>
    <ng-template ngFor let-vo [ngForOf]="positionen">
      <tr class="position-header" [class.highlighted]="vo.istHighlighted | async">
        <td *ngIf="posNrSichtbar" class="posnr-col">{{ vo.position.PosNr }}.</td>
        <td *ngIf="mengeSichtbar" class="menge-col">{{ vo.position.Menge }}</td>
        <td *ngIf="einheitSichtbar" class="einheit-col">{{ vo.position.Einheit }}</td>
        <td *ngIf="bezeichnungSichtbar" class="beschreibung-col">{{ vo.position.Bezeichnung }}<span *ngIf="vo.position.BhAktion"> ({{ vo.position.BhAktion }})</span></td>
        <td *ngIf="istPlusMinusButtonsAnzeigen(vo.position)" class="plusminus-buttons">
          <ion-button slot="end" color="light" size="default" appClick (clicked)="erhoeheMenge(vo.position)" class="plusminus-button plus-button">+</ion-button>
          <ion-button slot="end" color="light" appClick (clicked)="reduziereMenge(vo.position)" class="plusminus-button minus-button">-</ion-button>
        </td>
      </tr>
    </ng-template>

    <ng-container *ngIf="summeAnzahlSichtbar">
      <tr class="summe">
        <td [attr.colspan]="3">Gesamt: {{ summeAnzahl }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>