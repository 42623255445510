import { Pipe, PipeTransform } from '@angular/core';
import { FormatHelper } from 'src/app/api/helper/format-helper';

/**
 * Preis (Summe) mit 2 Nachkommastellen
 */
@Pipe({ name: 'preis' })
export class PreisPipe implements PipeTransform {
    transform(value: number, args: string[] = []): any {
        if (!value) {
            return value;
        };

        return FormatHelper.formatPreis(value);
    }
}
