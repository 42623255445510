<ion-item [lines]="lines" [style]="style">
  <ion-label class="ion-text-wrap">{{ titel | translate }}</ion-label>

  <div slot="end" class="input-outer">
    <ion-note slot="end" appClick (clicked)="selectUhrzeit($event)">{{ myValue }}</ion-note> <!--  | date: 'HH:mm' -->

    <!-- <ion-input
               inputmode="text"
               type="text"
               [class.input-upper-case]="true"
               [class.invalid-value]="isInvalid"
               [class.input-readonly]="readOnly"
               [disabled]="disabled"
               [readonly]="readOnly"
               [(ngModel)]="myValue"
               [pattern]="pattern"
               (ionBlur)="onBlur($event)"
               (keyup.enter)="onEnter($event)"
               [enterkeyhint]="enterkeyhint"
               #input></ion-input> -->

    <ion-popover cssClass="width-80" #popover>
      <ng-template>
        <div class="time-picker-title">{{ titel }}</div>
        <ion-datetime
                      presentation="time"
                      [(ngModel)]="myValue"
                      [minuteValues]="minuteValues"
                      size="cover"></ion-datetime>
        <div class="time-picker-footer">
          <ion-button
                      appClick (clicked)="closeInputPopover()"
                      size="default"
                      color="light">OK</ion-button>
        </div>
      </ng-template>
    </ion-popover>

    <div class="buttons">
      <ion-button class="datum-button inline-plusminus-button"
                  [disabled]="disabled"
                  appClick (clicked)="selectUhrzeit($event)"
                  size="default"
                  color="light"
                  slot="end">
        <ion-icon name="time-outline"></ion-icon>
      </ion-button>
    </div>

  </div>
</ion-item>