
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { Utils } from 'src/app/api/helper/utils';
import { FileHelper } from 'src/app/api/helper/file-helper';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { App } from 'src/app/api/helper/app';
import { AppConfig } from 'src/app/api/helper/app.config';

import * as moment from 'moment';
import { SystemService } from 'src/app/api/system.service';
import { ImageHelper } from 'src/app/api/helper/image-helper';

const log = new Logger('CameraHelper');

export interface PictureOptions {
    filenamePrefix: string;
    text?: string;
    textPlatzhalter?: any;
}

export class CameraHelper {
    static isAktiv = false;

    static camera: Camera;
    static file: File;

    static async takePicture(options: PictureOptions): Promise<string> {
        let pictureSrc: string;

        CameraHelper.isAktiv = true;

        try {
            pictureSrc = await CameraHelper.camera.getPicture({
                destinationType: this.camera.DestinationType.FILE_URI,
                encodingType: this.camera.EncodingType.JPEG,
                mediaType: this.camera.MediaType.PICTURE,
                sourceType: this.camera.PictureSourceType.CAMERA,
                quality: App.current.qualitaetFotos,
                targetWidth: App.current.aufloesungFotos,
                targetHeight: App.current.aufloesungFotos,
                correctOrientation: true,
            });

            // Beispiel: pictureSrc='file:///storage/emulated/0/Android/data/de.recomobil.app/cache/1570482608018.jpg'

            if (pictureSrc && pictureSrc.startsWith('file:')) {
                // Extract just the filename. Result example: cdv_photo_003.jpg
                const tempFilename = FileHelper.getFilename(pictureSrc);

                // Now, the opposite. Extract the full path, minus filename.
                // Result example: file:///var/mobile/Containers/Data/Application
                // /E4A79B4A-E5CB-4E0C-A7D9-0603ECD48690/tmp/
                const tempBaseFilesystemPath = FileHelper.getDirectory(pictureSrc);

                // Get the Data directory on the device.
                // Result example: file:///var/mobile/Containers/Data/Application
                // /E4A79B4A-E5CB-4E0C-A7D9-0603ECD48690/Library/NoCloud/
                const newBaseFilesystemPath = this.file.dataDirectory;
                const newFilename = FileHelper.removeIllegalFilenameChars(options.filenamePrefix + '-' + tempFilename);

                const entry = await this.file.copyFile(tempBaseFilesystemPath, tempFilename, newBaseFilesystemPath, newFilename);

                log.debug('copyFile', entry);

                // Result example: file:///var/mobile/Containers/Data/Application
                // /E4A79B4A-E5CB-4E0C-A7D9-0603ECD48690/Library/NoCloud/cdv_photo_003.jpg
                pictureSrc = newBaseFilesystemPath + newFilename;

                let text = options.text;

                if (text == null) {
                    text = AppConfig.current.einstellungen.FotosTextSonstige;
                }

                if (text) {
                    const now = moment();

                    let platzhalter: any = {
                        'AktuellesDatum': now.format('DD.MM.YYYY'),
                        'AktuelleUhrzeit': now.format('HH:mm'),
                        'Fahrzeug': App.current.getFahrzeugKennzeichen(),
                        'PersonalKey': App.current.getPersonalKey(),
                        'Personalnummer': App.current.getPersonalnummer(),
                        'PersonalName': App.current.getPersonalName(),
                    }

                    if (text.includes('{GPS}')) {
                        platzhalter['GPS'] = await SystemService.instance.getAktuelleGpsPositionStr('Foto', 10);
                    }

                    if (options.textPlatzhalter) {
                        platzhalter = {
                            ...platzhalter,
                            ...options.textPlatzhalter
                        };
                    }

                    const base64DataUrl = await this.file.readAsDataURL(newBaseFilesystemPath, newFilename);

                    const blob = await ImageHelper.writeImageText(base64DataUrl, text, platzhalter);

                    await this.file.writeExistingFile(newBaseFilesystemPath, newFilename, blob);
                }

                // await this.camera.cleanup();
            }

        } catch (err) {
            log.warn('camera error: ' + Utils.getErrorMessage(err), err);

            if (err === "cordova_not_available") {
                err = 'Kamera im Browser nicht verfügbar.';

                // Derzeit nur zum testen
                pictureSrc = 'R0lGODlhPQBEAPeoAJosM//AwO/AwHVYZ/z595kzAP/s7P+goOXMv8+fhw/v739/f+8PD98fH/8mJl+fn/9ZWb8/PzWlwv///6wWGbImAPgTEMImIN9gUFCEm/gDALUL'
                    + 'DN8PAD6atYdCTX9gUNKlj8wZAKUsAOzZz+UMAOsJAP/Z2ccMDA8PD/95eX5NWvsJCOVNQPtfX/8zM8+QePLl38MGBr8JCP+zs9myn/8GBqwpAP/GxgwJCPny78lzYLgjAJ8vAP9'
                    + 'fX/+MjMUcAN8zM/9wcM8ZGcATEL+QePdZWf/29uc/P9cmJu9MTDImIN+/r7+/vz8/P8VNQGNugV8AAF9fX8swMNgTAFlDOICAgPNSUnNWSMQ5MBAQEJE3QPIGAM9AQMqGcG9vb6'
                    + 'MhJsEdGM8vLx8fH98AANIWAMuQeL8fABkTEPPQ0OM5OSYdGFl5jo+Pj/+pqcsTE78wMFNGQLYmID4dGPvd3UBAQJmTkP+8vH9QUK+vr8ZWSHpzcJMmILdwcLOGcHRQUHxwcK9PT'
                    + '9DQ0O/v70w5MLypoG8wKOuwsP/g4P/Q0IcwKEswKMl8aJ9fX2xjdOtGRs/Pz+Dg4GImIP8gIH0sKEAwKKmTiKZ8aB/f39Wsl+LFt8dgUE9PT5x5aHBwcP+AgP+WltdgYMyZfyyw'
                    + 'z78AAAAAAAD///8AAP9mZv///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
                    + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
                    + 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAKgALAAAAAA9AEQAAAj/AFEJH'
                    + 'EiwoMGDCBMqXMiwocAbBww4nEhxoYkUpzJGrMixogkfGUNqlNixJEIDB0SqHGmyJSojM1bKZOmyop0gM3Oe2liTISKMOoPy7GnwY9CjIYcSRYm0aVKSLmE6nfq05QycVLPuhDrx'
                    + 'BlCtYJUqNAq2bNWEBj6ZXRuyxZyDRtqwnXvkhACDV+euTeJm1Ki7A73qNWtFiF+/gA95Gly2CJLDhwEHMOUAAuOpLYDEgBxZ4GRTlC1fDnpkM+fOqD6DDj1aZpITp0dtGCDhr+f'
                    + 'VuCu3zlg49ijaokTZTo27uG7Gjn2P+hI8+PDPERoUB318bWbfAJ5sUNFcuGRTYUqV/3ogfXp1rWlMc6awJjiAAd2fm4ogXjz56aypOoIde4OE5u/F9x199dlXnnGiHZWEYbGpsA'
                    + 'EA3QXYnHwEFliKAgswgJ8LPeiUXGwedCAKABACCN+EA1pYIIYaFlcDhytd51sGAJbo3onOpajiihlO92KHGaUXGwWjUBChjSPiWJuOO/LYIm4v1tXfE6J4gCSJEZ7YgRYUNrkji'
                    + '9P55sF/ogxw5ZkSqIDaZBV6aSGYq/lGZplndkckZ98xoICbTcIJGQAZcNmdmUc210hs35nCyJ58fgmIKX5RQGOZowxaZwYA+JaoKQwswGijBV4C6SiTUmpphMspJx9unX4Kaimj'
                    + 'Dv9aaXOEBteBqmuuxgEHoLX6Kqx+yXqqBANsgCtit4FWQAEkrNbpq7HSOmtwag5w57GrmlJBASEU18ADjUYb3ADTinIttsgSB1oJFfA63bduimuqKB1keqwUhoCSK374wbujvOS'
                    + 'u4QG6UvxBRydcpKsav++Ca6G8A6Pr1x2kVMyHwsVxUALDq/krnrhPSOzXG1lUTIoffqGR7Goi2MAxbv6O2kEG56I7CSlRsEFKFVyovDJoIRTg7sugNRDGqCJzJgcKE0ywc0ELm6'
                    + 'KBCCJo8DIPFeCWNGcyqNFE06ToAfV0HBRgxsvLThHn1oddQMrXj5DyAQgjEHSAJMWZwS3HPxT/QMbabI/iBCliMLEJKX2EEkomBAUCxRi42VDADxyTYDVogV+wSChqmKxEKCDAY'
                    + 'FDFj4OmwbY7bDGdBhtrnTQYOigeChUmc1K3QTnAUfEgGFgAWt88hKA6aCRIXhxnQ1yg3BCayK44EWdkUQcBByEQChFXfCB776aQsG0BIlQgQgE8qO26X1h8cEUep8ngRBnOy74E'
                    + '9QgRgEAC8SvOfQkh7FDBDmS43PmGoIiKUUEGkMEC/PJHgxw0xH74yx/3XnaYRJgMB8obxQW6kL9QYEJ0FIFgByfIL7/IQAlvQwEpnAC7DtLNJCKUoO/w45c44GwCXiAFB/OXAAT'
                    + 'QryUxdN4LfFiwgjCNYg+kYMIEFkCKDs6PKAIJouyGWMS1FSKJOMRB/BoIxYJIUXFUxNwoIkEKPAgCBZSQHQ1A2EWDfDEUVLyADj5AChSIQW6gu10bE/JG2VnCZGfo4R4d0sdQoB'
                    + 'AHhPjhIB94v/wRoRKQWGRHgrhGSQJxCS+0pCZbEhAAOw==';
            } else if (err == 20) {
                await UiHelper.showAlert('ReCoMobil hat kein Zugriff auf die Kamera. Bitte Berechtigung für die Kamera in den App-Einstellungen aktivieren', 'Fehler', true);
            } else if (err == 'No Image Selected') {
                // Aufnahme wurde abgebrochen
            } else {
                UiHelper.showError('Fehler Kamera: ' + Utils.getErrorMessage(err), 'Fehler', false, false);
            }
        }

        setTimeout(() => {
            CameraHelper.isAktiv = false;
        }, 250);

        return pictureSrc;
    }
}