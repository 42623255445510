import { Component, OnInit, OnDestroy, ViewChild, Input, AfterViewInit, forwardRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logger } from 'src/app/api/helper/app-error-logger';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { App } from 'src/app/api/helper/app';
import { IonInput } from '@ionic/angular';

const log = new Logger('InputCheckbox');

@Component({
    selector: 'app-input-checkbox',
    templateUrl: './input-checkbox.component.html',
    styleUrls: ['./input-checkbox.component.scss'],
    providers: [{   // <================================================ ADD THIS
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputCheckbox),
        multi: true
    }]
})
export class InputCheckbox implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit, OnChanges {

    @ViewChild('input', { read: IonInput, static: true }) input: IonInput;

    @Input() titel: string;
    @Input() lines = 'none';

    @Input() disabled = false;
    @Input() readOnly = false;
    @Input() color = 'primary';    
    @Input() jaText = 'JA';
    @Input() neinText = 'NEIN';

    @Output() valueChanged = new EventEmitter<any>();

    subscriptions: Subscription[] = [];

    app = App.current;

    private _value: boolean;

    // Whatever name for this (myValue) you choose here, use it in the .html file.
    public get myValue(): boolean { return this._value }

    public set myValue(v: boolean) {
        // log.debug('set myValue ' + this.titel + ': ' + v, v);

        if (v == null) {
            v = false;
        }

        // Strings etc. ind bool umwandeln
        v = !!v;

        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        // log.debug('changed ' + this.titel, changes);

        this.updateType();
    }

    onChange = (_) => {
    };

    onTouched = () => { };

    writeValue(value: any): void {
        this.myValue = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    ngOnInit() {
        this.updateType();
    }

    async ngAfterViewInit() {
    }

    ngOnDestroy() {
    }

    async ionViewWillEnter() {
    }

    ionViewDidEnter() {
    }

    ionViewWillLeave() {
    }

    updateType() {
        // log.debug('updateType ' + this.titel, this._value);

        if (this._value == null) {
            this._value = false;
        }
    }

    onValueChange(e: any) {
        this.valueChanged.next(this._value);
    }
}
