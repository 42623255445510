<ion-content #content>
  <ion-card *ngIf="auftrag" style="--background: white;">
    <ion-card-header>
      <ion-card-title *ngIf="isHofauftrag">{{ 'Neuer Hofauftrag' | translate }}</ion-card-title>
      <ion-card-title *ngIf="isTransportauftrag">{{ 'Neuer Transportauftrag' | translate }}</ion-card-title>
    </ion-card-header>

    <ion-card-content *ngIf="formularKonfiguration">
      <div *ngFor="let c of formularKonfiguration.komponenten">
        <!-- Fahrzeug-Kennzeichen -->
        <div *ngIf="c.name === 'Fahrzeug-Kennzeichen' && auftrag.FahrzeugKennung">
          <div class="section">
            <div class="fahrzeug">
              <div class="kennzeichen"><span class="blau"><img src="assets/img/euro-sterne-28.png" /></span><span class="kennzeichen-text">{{ auftrag.FahrzeugKennung }}</span></div>
            </div>
          </div>
        </div>

        <!-- Abholadresse -->
        <div *ngIf="c.name === 'Abholadresse'">
          <div class="section ion-activatable">
            <div class="adresse">
              <app-adresse [auftrag]="auftrag" [adresse]="auftrag.Abholadresse" title="{{ 'Einsatzort' | translate }}" [formularkonfiguration]="c" [buttonsAnzeigen]="false"></app-adresse>
            </div>
          </div>
        </div>

        <!-- Lieferung -->
        <div *ngIf="c.name === 'Lieferung'">
          <div class="section ion-activatable">
            <div class="lieferung-liste">
              <app-lieferung [auftrag]="auftrag" [formularkonfiguration]="c" ansicht="klingeln"></app-lieferung>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isButtonsVorhanden" class="klingeln-container">
        <auftrag-buttons-komponente [auftragdetails]="auftragdetails"
                                    [nummer]="99"
                                    (buttonClicked)="onStatusButtonClicked()"></auftrag-buttons-komponente>
      </div>

      <div *ngIf="!isAnzeigenErlaubt">
        <ion-button expand="full" color="primary" appClick (clicked)="onIgnorieren()">{{ 'OK' | translate }}</ion-button>
      </div>

      <div *ngIf="isAnzeigenErlaubt">
        <ion-button expand="full" color="primary" appClick (clicked)="onAuftragAnzeigen()">{{ 'Anzeigen' | translate }}</ion-button>
        <ion-button expand="full" color="dark" fill="clear" appClick (clicked)="onIgnorieren()">{{ 'Schließen' | translate }}</ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>