import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class BarcodeAuthGuard implements CanDeactivate<Comment> {

    static barcodeScanAktiv = false;

    constructor() { }

    canDeactivate(component: Comment, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (BarcodeAuthGuard.barcodeScanAktiv) {
            return false;
        }

        return true;
    }
}
