import { Component, OnInit, Input, SimpleChanges, OnChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BarcodeScanner, BarcodeScanResult } from '@ionic-native/barcode-scanner/ngx';
import { AlertController, ModalController } from '@ionic/angular';
import { App } from 'src/app/api/helper/app';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { Utils } from 'src/app/api/helper/utils';
import { AuftragEx, AuftragspositionEx, BarcodeEvent } from 'src/app/api/model/model';
import { Auftragstyp, Kontakt, ReCoMobilElement } from 'src/app/api/model/swagger-model';
import { ProgloveService } from 'src/app/api/proglove.service';
import { SystemService } from 'src/app/api/system.service';
import { IAuftragdetails } from 'src/app/auftragsliste/auftragdetails/iauftragdetails';
import { BarcodeAuthGuard } from '../../barcode.guard';

const log = new Logger("SeriennummernListeComponent");

@Component({
    selector: 'app-seriennummern-liste',
    templateUrl: './seriennummern-liste.component.html',
    styleUrls: ['./seriennummern-liste.component.scss'],
})
export class SeriennummernListeComponent implements OnInit, OnChanges, OnDestroy {
    @Input() kontakte: Kontakt[];

    @Input() auftrag: AuftragEx;
    @Input() auftragdetails: IAuftragdetails;
    @Input() position: AuftragspositionEx;

    private barcodeRegistrationId: number;
    private isVisible = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private alertController: AlertController,
        private barcodeScanner: BarcodeScanner,
        private progloveService: ProgloveService,
        private systemService: SystemService,
        private modalController: ModalController,) {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.auftrag) {
            this.refresh();
        }
    }

    async ngOnInit() {
        if (!this.position.Elemente) {
            this.position.Elemente = [];
        }

        let menge = Utils.parseInt(this.position.Menge);

        if (typeof (menge) === 'number' && menge <= 100) {
            while (this.position.Elemente.length < menge) {
                this.position.Elemente.push({
                    Pos: this.position.Elemente.length + 1,
                    Nummer: '',
                    Typ: 'Seriennummer'
                })
            }
        }

        this.position.Elemente.sort((a, b) => a.Pos - b.Pos);

        this.barcodeRegistrationId = this.progloveService.registerBarcodeReceiver(100, (event) => this.handleBarcodeReceivedEvent(event, true));

        await this.progloveService.setSeriennummerProfile(true);

        this.isVisible = true;

        this.refresh();
    }

    async ngOnDestroy() {
        this.isVisible = false;

        this.progloveService.unregisterBarcodeReceiver(this.barcodeRegistrationId);

        if (this.auftrag?.Auftragstyp === Auftragstyp.Wareneingang) {
            await this.progloveService.setWareneingangProfile(true);
        } else {
            await this.progloveService.setStandardProfile(true);
        }
    }

    handleBarcodeReceivedEvent(event: BarcodeEvent, vonProGlove = false): void {
        if (this.isVisible) {
            event.handled = true;

            // Prüfe ob Nummer schon vorhanden ist. Wenn ja, nicht doppelt scannen
            for (const element of this.position.Elemente) {
                if (element.Nummer === event.barcode.text) {
                    log.info('Seriennummer wird ignoriert. Bereits vorhanden: ' + event.barcode.text);

                    if (vonProGlove) {
                        this.progloveService.triggerFeedback(3);
                    } else {
                        UiHelper.showAlert('Seriennummer bereits erfasst', 'Doppelt');
                    }

                    return;
                }
            }

            for (const element of this.position.Elemente) {
                if (!element.Nummer) {
                    element.Nummer = event.barcode.text;

                    if (vonProGlove) {
                        this.progloveService.triggerFeedback(1);
                    }

                    break;
                }
            }

            if (!this.position.Elemente.find(p => !p.Nummer)) {
                // alle Seriennummern sind vorhanden
                this.onClose();
                return;
            }

            this.cdr.detectChanges();
        }
    }

    refresh() {
        log.debug('refresh');
    }

    onClose() {
        log.debug('onClose');
        this.modalController.dismiss(null);
    }

    onEntfernen(element: ReCoMobilElement) {
        log.debug('onEntfernen', element);

        if (this.position?.Menge) {
            if (this.position.Elemente.length > this.position.Menge) {
                const index = this.position.Elemente.indexOf(element, 0);

                if (index >= 0) {
                    this.position.Elemente.splice(index, 1);
                }
            }
        }

        element.Nummer = '';
        this.cdr.detectChanges();
    }

    async onBarcodeScan() {
        BarcodeAuthGuard.barcodeScanAktiv = true;

        try {
            let barcodeData: BarcodeScanResult;

            if (App.isCordovaAvailable()) {
                barcodeData = await this.barcodeScanner.scan({
                    showTorchButton: true,
                });
            } else {
                await App.delay(500);

                const nummer = 10000000 + Math.round(Math.random() * 10000000);

                barcodeData = {
                    cancelled: false,
                    text: nummer.toString(),
                    format: 'CODE_39'
                };
            }

            if (barcodeData && barcodeData.text && !barcodeData.cancelled) {
                let format: string = barcodeData.format;

                await this.handleBarcodeReceivedEvent({
                    barcode: {
                        text: barcodeData.text,
                        format: format
                    },
                    handled: false
                });
            }

        } catch (err) {
            UiHelper.showError(err);
        }

        setTimeout(() => BarcodeAuthGuard.barcodeScanAktiv = false, 500);
    }

    async onManuelleEingabe() {
        log.debug('onManuelleEingabe');

        const alert = await this.alertController.create({
            cssClass: 'input-alert',
            message: 'Bitte Seriennummer eingeben',
            inputs: [
                {
                    name: 'name',
                    type: 'number',
                    placeholder: '',
                    value: ''
                }
            ],
            buttons: [
                {
                    text: 'Abbruch',
                    role: 'cancel',
                    handler: data => {
                        // abgebrochen
                    }
                },
                {
                    text: 'OK',
                    role: 'accept',
                    handler: data => {
                        if (data.name) {
                            this.handleBarcodeReceivedEvent({
                                barcode: {
                                    text: data.name,
                                    format: 'MANUELL'
                                },
                                handled: false
                            });
                        }
                    }
                }
            ]
        });

        await alert.present();

        await Utils.delay(200);

        const firstInput: any = document.querySelector('ion-alert input');

        if (firstInput) {
            firstInput.focus();
        }

        const result = await alert.onDidDismiss();
    }
}
