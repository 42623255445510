<ion-header>
    <ion-toolbar class="auftrag-toolbar" #toolbar>
      <ion-buttons slot="start">
        <ion-button appClick (clicked)="onAbbrechen()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title><span id="page-title-span">{{ 'Bezahlen' | translate }}</span></ion-title>
    </ion-toolbar>
  </ion-header>

<ion-content *ngIf="auftrag">
    <div class="table-container">
        <h1>Auftrag</h1>

        <!-- <div *ngIf="!auftrag.PreiseGueltig" class="preise-ungueltig-meldung">
            Preise konnten nicht vollständig ermittelt werden.
        </div> -->

        <table class="app-table">
            <tbody>
                <ng-template ngFor let-p [ngForOf]="positionen">
                    <tr class="position-header">
                        <td class="menge-col">{{ p.Menge }}</td>
                        <td class="einheit-col">{{ p.Einheit }}</td>
                        <td class="preis-col" rowspan="2">
                            <div>
                                {{ p.NettoBetrag | preis }} €
                            </div>
                            <div *ngIf="p.Menge && p.Menge > 1 && p.MwstSatz" class="einzelpreis">
                                EP {{ (p.EinzelVK) | ep }} € <!--  + (p.EinzelVK * p.MwstSatz / 100) -->
                            </div>
                        </td>
                    </tr>
                    <tr class="position-bezeichnung">
                        <td class="beschreibung-col" [attr.colspan]="2">{{ p.Bezeichnung }}<span *ngIf="p.BhAktion"> ({{ p.BhAktion }})</span></td>
                    </tr>
                </ng-template>

                <tr class="summe-netto">
                    <td [attr.colspan]="2">Netto</td>
                    <td class="preis-col">{{ auftrag.SummeNetto | preis }} €</td>
                </tr>
                <tr class="summe-mwst">
                    <td [attr.colspan]="2">Mwst</td>
                    <td class="preis-col">{{ auftrag.SummeMwst | preis }} €</td>
                </tr>
                <tr class="summe">
                    <td [attr.colspan]="2">Summe</td>
                    <td class="preis-col">{{ auftrag.SummeBrutto | preis }} €</td>
                </tr>
            </tbody>
        </table>
    </div>

    <ion-list *ngIf="!auftrag.IstBezahlt">
        <ion-list-header>
            <ion-label>{{ 'Bezahlung' | translate }}</ion-label>
        </ion-list-header>

        <ion-radio-group [value]="bezahlungArt" (ionChange)="onRadioGroupChange($event)">
            <ion-item lines="none">
                <ion-label>BAR</ion-label>
                <ion-radio slot="start" [value]="1"></ion-radio>
            </ion-item>
            <ion-item lines="none">
                <ion-label>EC-Karte</ion-label>
                <ion-radio slot="start" [value]="2"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>

    <div *ngIf="auftrag.IstBezahlt && auftrag.BezahlungBetrag" class="bezahlt-meldung">
        {{ auftrag.BezahlungBetrag.toFixed(2) }} € wurden bezahlt
    </div>

    <div style="margin: 15px">
        <ion-button *ngIf="!auftrag.IstBezahlt" appClick (clicked)="onGeldErhalten()" expand="full" size="large" color="primary">Geld erhalten</ion-button>
        <ion-button *ngIf="auftrag.IstBezahlt" appClick (clicked)="onStorno()" expand="full" size="large" color="light">Storno</ion-button>
        <ion-button appClick (clicked)="onAbbrechen()" expand="full" size="default" color="light">Abbrechen</ion-button>
    </div>
</ion-content>