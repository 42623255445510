import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavController, IonSelect, ModalController } from '@ionic/angular';
import { AuftragService } from 'src/app/api/auftrag.service';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { App } from 'src/app/api/helper/app';
import { Druckformular, Workflow, WorkflowStatus } from 'src/app/api/model/swagger-model';
import { ZebraDrucker } from 'src/app/shared/helper/zebra-drucker';
import { AppConfig, DruckerKonfiguration } from 'src/app/api/helper/app.config';
import { AppStorage } from 'src/app/api/helper/app-storage';
import { StatusmeldungZusatzdaten, AuftragEx, AuftragspositionEx, DruckOptions } from 'src/app/api/model/model';
import { DruckerService } from 'src/app/api/drucker.service';
import { HtmlDrucker } from 'src/app/shared/helper/html-drucker';
import { Utils } from 'src/app/api/helper/utils';
import { IAuftragdetails } from '../auftragdetails/iauftragdetails';


@Component({
    selector: 'app-auftrag-drucken',
    templateUrl: './auftrag-drucken.page.html',
    styleUrls: ['./auftrag-drucken.page.scss'],
})
export class AuftragDruckenPage implements OnInit, AfterViewInit, OnDestroy {
    log = new Logger("AuftragDruckenPage");

    @ViewChild(IonSelect, { static: false }) druckerSelect: IonSelect;

    subscriptions: Subscription[] = [];

    @Input() auftrag: AuftragEx;
    @Input() position: AuftragspositionEx;
    @Input() workflowStatus: WorkflowStatus;
    @Input() auftragdetails: IAuftragdetails;
    @Input() druckformularName = '';
    @Input() sofortDrucken = false;

    druckformulare: Druckformular[];
    workflow: Workflow;

    druckerAuswahl: DruckerKonfiguration[] = [];
    druckerMacAdresse: string;
    anzahlExemplare = 1;

    isVisible = false;

    constructor(
        private auftragService: AuftragService,
        private stammdatenService: StammdatenService,
        private druckerService: DruckerService,
        private router: Router,
        private modalController: ModalController) { }

    async ngOnInit() {
        this.log.debug('ngOnInit');
        this.isVisible = true;

        App.current.backAction = () => this.onAbbrechen();

        try {
            this.druckerAuswahl = this.druckerService.getAktiveDrucker();

            if (!this.druckerAuswahl.length) {
                if (App.isCordovaAvailable()) {
                    await UiHelper.showAlert('Kein aktiver Drucker vorhanden');
                    setTimeout(() => this.onAbbrechen());
                    return;
                } else {
                    await UiHelper.showAlert('ACHTUNG: Drucken in Simulation nicht verfügbar', 'Simulation', true);
                }
            }

            this.druckerMacAdresse = await AppStorage.current.get('letzterAusgewaehlterDrucker', true, true);

            if (this.druckerAuswahl.length == 1) {
                this.druckerMacAdresse = this.druckerAuswahl[0].macAdresse;
            }

            this.workflow = this.auftragdetails.workflow;

            this.auftragService.setzeAktivenAuftrag(this.auftrag, 'drucken');
            
            const appConfig = AppConfig.current;

            if (this.druckformularName) {
                this.log.debug('Es wurde ein Druckformular als Parameter übergaben: ' + this.druckformularName);
            }
            else if (appConfig.einstellungen.DruckerStandardFormular){
                // Falls ein Standardformualr ausgewählt wurde.
                this.druckformularName = appConfig.einstellungen.DruckerStandardFormular;
            }
            else {
                this.druckformularName = await AppStorage.current.get('letztesDruckFormularName', true, true);
            }

            this.druckformulare = await this.stammdatenService.getDruckformulare();

            if (this.druckformulare.length == 0) {
                await UiHelper.showAlert('Kein Druckformular gefunden');
                setTimeout(() => this.onAbbrechen(), 0);
                return;
            }

            if (!appConfig.gekoppelteDrucker || appConfig.gekoppelteDrucker.length == 0) {
                if (App.isCordovaAvailable()) {
                    UiHelper.showError('Kein Drucker konfiguriert');
                    this.router.navigateByUrl('/einstellungen/drucker');
                    this.dismiss();
                    return;
                }
            }

            this.updateAnzahlExemplare();

            if (this.sofortDrucken) {
                setTimeout(() => {
                    this.onDrucken()
                }, 100);
            }
        } catch (err) {
            UiHelper.showError(err);
            setTimeout(() => this.onAbbrechen(), 0);
            return;
        }
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];

        this.log.debug('ionViewWillLeave');
        App.current.backAction = null;
        this.isVisible = false;
    }

    onAbbrechen() {
        if (this.isVisible) {
            this.dismiss();
        }
    }

    async onSpeichern() {
        if (this.isVisible) {
            this.dismiss();
        }
    }

    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            'dismissed': true
        });
    }

    async onDrucken() {
        const druckformular = this.druckformulare.find(p => p.Name == this.druckformularName);

        if (!druckformular) {
            UiHelper.showAlert('Bitte ein Druckformular auswählen');
            return;
        }

        if (!this.druckerMacAdresse) {
            UiHelper.showAlert('Bitte einen Drucker auswählen');
            return;
        }

        AppStorage.current.set('letztesDruckFormularName', this.druckformularName, true, true);
        AppStorage.current.set('letzterAusgewaehlterDrucker', this.druckerMacAdresse, true, true);

        const drucker = this.druckerAuswahl.find(p => p.macAdresse == this.druckerMacAdresse);

        const druckAuftraege: AuftragEx[] = [];

        if (this.position) {
            // Es soll nur die eine Position gedruckt werden (z.B Wareneingang)
            const druckAuftrag = { ... this.auftrag }; // JSON.parse(JSON.stringify(this.auftrag));
            druckAuftrag.istClone = true;
            druckAuftrag.Positionen = [this.position];
            druckAuftraege.push(druckAuftrag);

        } else if (druckformular.AusdruckJeArtikelgruppe && this.auftrag.Positionen.length > 1) {
            // Gruppiere die Auftragpositionen je Artikel-Gruppe

            const artikelListe = this.stammdatenService.artikelListe.getValue();

            let positionen = [... this.auftrag.Positionen];

            // Es sollen nur Positionen mit Menge <> 0 gedruckt werden (ZTN)
            positionen = positionen.filter(p => p.Menge);

            // Zuerst die ArtikelGruppe in den Positionen hinzufügen...
            for (const p of positionen) {
                // WICHTIG: Hier darf kein === sein. Der ArtikelKey ist manchmal eine number
                const artikel = artikelListe.find(x => x.Key == p.ArtikelKey || (p.ArtikelKey && x.Key == p.ArtikelKey.toString()));

                if (artikel) {
                    p.ArtikelGruppe = artikel.artikelGruppe;
                } else {
                    p.ArtikelGruppe = '?';
                }
            }

            const groups = Utils.groupBy(positionen, 'ArtikelGruppe');

            for (const key in groups) {
                if (Object.prototype.hasOwnProperty.call(groups, key)) {
                    // Clone Auftrag
                    const druckAuftrag = { ... this.auftrag }; // JSON.parse(JSON.stringify(this.auftrag));
                    druckAuftrag.istClone = true;
                    druckAuftrag.Positionen = groups[key];
                    druckAuftraege.push(druckAuftrag);
                }
            }
        } else if (druckformular.AusdruckJeLieferung && this.auftrag?.Lieferungen?.length > 1) {
            // Gruppiere die Auftragpositionen je Artikel-Gruppe
            for (const lieferung of this.auftrag.Lieferungen) {
                const druckAuftrag = { ... this.auftrag }; // JSON.parse(JSON.stringify(this.auftrag));
                druckAuftrag.istClone = true;
                druckAuftrag.Positionen = lieferung.Positionen;
                druckAuftrag.Abholadresse = lieferung.Abholadresse;
                druckAuftrag.Info1 = lieferung.Info1;
                druckAuftrag.Info2 = lieferung.Info2;
                druckAuftrag.Info3 = lieferung.Info3;
                druckAuftrag.Info4 = lieferung.Info4;
                druckAuftrag.Info5 = lieferung.Info5;
                druckAuftrag.DetailInfo1 = lieferung.DetailInfo1;
                druckAuftrag.DetailInfo2 = lieferung.DetailInfo2;
                druckAuftrag.DetailInfo3 = lieferung.DetailInfo3;
                druckAuftraege.push(druckAuftrag);
            }
        } else {
            druckAuftraege.push(this.auftrag);
        }

        try {
            let counter = 0;
            let ok = true;

            if (this.anzahlExemplare < 1) {
                this.anzahlExemplare = 1;
            }

            if (this.anzahlExemplare > 10) {
                this.anzahlExemplare = 10;
            }

            const options: DruckOptions = {
                anzahl: 1 // this.anzahlExemplare
            }

            for (let i = 0; i < this.anzahlExemplare; i++) {
                for (const druckAuftrag of druckAuftraege) {
                    druckAuftrag.AusdruckNummer = (++counter) + "/" + druckAuftraege.length;

                    this.auftragService.aktualisierePreise(druckAuftrag, 'onDrucken');

                    switch (AppConfig.current.einstellungen.DruckerTyp) {
                        case 'Druckdienst':
                            ok = ok && await this.druckeAuftragStandard(druckformular, drucker, druckAuftrag);
                            break;

                        case 'zebra':
                            ok = ok && await this.druckeAuftragZpl(druckformular, drucker, druckAuftrag, options);
                            break;

                        default:
                            throw new Error('unbekannter DruckerTyp: ' + AppConfig.current.einstellungen.DruckerTyp);
                    }

                    await Utils.delay(250);
                }
            }

            if (ok) {
                this.onAbbrechen();
            }
        } catch (err) {
            this.log.error('onDrucken', err);
            UiHelper.hideLoading();
            UiHelper.showError(err);
        }
    }

    async druckeAuftragZpl(druckformular: Druckformular, drucker: DruckerKonfiguration, druckAuftrag: AuftragEx, options: DruckOptions): Promise<boolean> {
        const fahrer = App.current.fahrer.getValue();
        const fahrzeug = App.current.fahrzeug.getValue();

        const zebraDrucker = new ZebraDrucker(druckformular, druckAuftrag, fahrzeug, fahrer);

        const zpl = await zebraDrucker.erstelleBelegZplCode(options);

        const ok = await this.druckerService.druckeZpl(zpl, drucker);

        if (ok) {
            if (this.workflowStatus) {
                const zusatzdaten: StatusmeldungZusatzdaten = {
                    druckFormular: druckformular.Name,
                    druckDaten: zpl
                };

                await this.auftragService.statusmeldung(druckAuftrag, this.workflowStatus, zusatzdaten);
            }

            return true;
        } else {
            return false;
        }
    }

    async druckeAuftragStandard(druckformular: Druckformular, drucker: DruckerKonfiguration, druckAuftrag: AuftragEx): Promise<boolean> {
        const fahrer = App.current.fahrer.getValue();
        const fahrzeug = App.current.fahrzeug.getValue();

        const htmlDrucker = new HtmlDrucker(druckformular, druckAuftrag, fahrzeug, fahrer);

        const html = await htmlDrucker.erstelleHtml();

        const ok = await this.druckerService.druckeHtml(html, drucker);

        if (ok && this.workflowStatus) {
            const zusatzdaten: StatusmeldungZusatzdaten = {
                druckFormular: druckformular.Name,
                druckDaten: html
            };

            await this.auftragService.statusmeldung(druckAuftrag, this.workflowStatus, zusatzdaten);

            return true;
        } else {
            return false;
        }
    }

    onRadioGroupChange(e) {
        this.log.debug('onRadioGroupChange', e);

        if (e.detail.value && e.detail.value != this.druckformularName) {
            this.druckformularName = e.detail.value;
            this.updateAnzahlExemplare();
        }
    }

    updateAnzahlExemplare() {
        if (this.druckformularName && this.druckformulare?.length) {
            const druckformular = this.druckformulare.find(p => p.Name === this.druckformularName);

            if (druckformular) {
                let sollAnzahl = 1;

                if (this.auftragdetails?.auftrag?.IstBezahlt) {
                    sollAnzahl = druckformular.AnzahlExemplareWennBezahlt;
                } else {
                    sollAnzahl = druckformular.AnzahlExemplareStandard;
                }

                if (sollAnzahl) {
                    this.anzahlExemplare = sollAnzahl;
                }
            }
        }
    }
}
