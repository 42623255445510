<ion-header>
  <ion-toolbar class="uvv-toolbar" #toolbar>
    <ion-buttons slot="start">
      <ion-button appClick (clicked)="onClose()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title><span id="page-title-span">{{ 'RFID Tauschen' | translate }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="section-content">
  <div class="section">
    <h1>{{ 'Neuer RFID-Chip' | translate }}</h1>

    <form [formGroup]="form" *ngIf="geladen">
      <ion-item lines="none">
        <ion-label position="stacked">{{ 'Prüfgegenstand' | translate }}</ion-label>
        <ion-input class="display-input" type="text" formControlName="gegenstandNummer" readonly></ion-input>
      </ion-item>

      <ion-item class="display-item" lines="none">
        <ion-label position="stacked">{{ 'Alte RFID' | translate }}</ion-label>
        <ion-input class="display-input" type="text" formControlName="alteIdentNummer" readonly></ion-input>
      </ion-item>
      
      <ion-item lines="none">
        <ion-label position="stacked">{{ 'Neue RFID' | translate }}</ion-label>
        <ion-input type="text" formControlName="identNummer" placeholder="Bitte scannen"></ion-input>
      </ion-item>
    </form>

    <!-- <div class="mb-25">
      <ion-button expand="block" size="default" color="light" appClick (clicked)="onRfidScannen()">RFID-Chip scannen</ion-button>
    </div> -->

    <ion-button *ngIf="geladen" expand="block" size="large" color="primary" appClick (clicked)="onSpeichern()">Speichern</ion-button>
    <ion-button *ngIf="geladen" expand="block" size="default" color="light" appClick (clicked)="onClose()">Abbrechen</ion-button>
  </div>
</ion-content>