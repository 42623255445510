import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuftragEx } from 'src/app/api/model/model';
import { IonTextarea, ModalController } from '@ionic/angular';
import { AuftragService } from 'src/app/api/auftrag.service';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { App } from 'src/app/api/helper/app';
import { Utils } from 'src/app/api/helper/utils';

@Component({
    selector: 'app-auftrag-anmerkungen',
    templateUrl: './auftrag-anmerkungen.page.html',
    styleUrls: ['./auftrag-anmerkungen.page.scss'],
})
export class AuftragAnmerkungenPage implements OnInit, AfterViewInit, OnDestroy {
    log = new Logger("AuftragAnmerkungenPage");

    @ViewChild('textarea', { static: false }) textarea: IonTextarea;

    @Input() auftrag: AuftragEx;

    subscriptions: Subscription[] = [];

    constructor(
        private auftragService: AuftragService,
        private modalController: ModalController,
    ) { }

    async ionViewWillLeave() {
        this.log.debug('ionViewWillLeave');
    }

    ngOnInit() {
        App.current.klingelnBlockiert.next(true);
    }

    ngAfterViewInit() {
        this.auftragService.setzeAktivenAuftrag(this.auftrag, 'anmerkungen');

        App.current.backAction = () => this.onAbbrechen();

        setTimeout(() => {
            try {
                if (this.textarea) {
                    this.textarea.setFocus();
                }
            } catch (err) {
                this.log.error('setTimeout textarea: ' + Utils.getErrorMessage(err), err);
            }
        }, 500);
    }

    async ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        App.current.backAction = null;

        if (this.auftrag) {
            if (this.auftragService.istAktiverAuftrag(this.auftrag)) {
                await this.auftragService.speichereAuftrag(this.auftrag);

                // this.auftragService.setzeAktivenAuftrag(null, 'anmerkungen exit');
            } else {
                this.log.warn(`ngOnDestroy: Auftrag ${this.auftrag.Key} ist niht der aktive Auftrag`);
            }
        } else {
            this.log.warn('ngOnDestroy: Auftrag is NULL');
        }

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];

        App.current.klingelnBlockiert.next(false);
    }

    onAbbrechen() {
        this.dismiss();
    }

    async onSpeichern() {
        this.dismiss();
    }

    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            'dismissed': true
        });
    }
}
