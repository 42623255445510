import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, NgZone, Input, EventEmitter, Output } from '@angular/core';
import { AuftragEx, ReCoMobilEigenschaft, Eigenschaft, AuftragspositionEx, NfcEvent } from 'src/app/api/model/model';
import { AlertController, ModalController, IonSelect, IonSearchbar } from '@ionic/angular';
import { AuftragService } from 'src/app/api/auftrag.service';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { BarcodeScanner, BarcodeScanResult } from '@ionic-native/barcode-scanner/ngx';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { App } from 'src/app/api/helper/app';
import { NFC } from '@ionic-native/nfc/ngx';
import { Artikel, AdresseEntity, UvvPruefGegenstand, Auftragsposition, GpsPosition, MengeOptionTyp, Vertragsposition, Vertrag, Wiegedaten, AuftragsFormular, Auftragstyp, ReCoMobilBehaelterbewegung } from 'src/app/api/model/swagger-model';
import { ClickHelper } from 'src/app/api/helper/click-helper';
import { TranslateService } from '@ngx-translate/core';
import { Auftragsstatus } from 'src/app/api/model/aufteagsstatus';
import { UvvService } from 'src/app/api/uvv.service';
import { UvvHelper } from 'src/app/api/helper/uvv-helper';
import { StringHelper } from 'src/app/api/helper/string-helper';
import { UvvPruefungModalPage } from 'src/app/shared/modal/uvv-pruefung-modal/uvv-pruefung-modal.page';
import { AppConfig } from 'src/app/api/helper/app.config';
import { AuftragArtikelAuswahlPage } from '../artikel-auswahl/artikel-auswahl.component';
import { BarcodeAuthGuard } from 'src/app/shared/barcode.guard';
import { SystemService } from 'src/app/api/system.service';
import { WaageService } from 'src/app/api/waage.service';
import { Utils } from 'src/app/api/helper/utils';
import { delay } from 'rxjs/operators';
import { DatePicker } from '@ionic-native/date-picker/ngx';

import * as moment from 'moment';
import { IAuftragdetails } from '../auftragdetails/iauftragdetails';
import { Constants } from 'src/app/api/model/constants';
import { AuftragHelper } from 'src/app/api/helper/auftrag-helper';
import { I18N } from 'src/app/api/helper/i18n';

@Component({
    selector: 'app-auftrag-lieferung-details',
    templateUrl: './auftrag-lieferung-details.page.html',
    styleUrls: ['./auftrag-lieferung-details.page.scss'],
})
export class AuftragLieferungDetailsPage implements OnInit, AfterViewInit, OnDestroy {
    log = new Logger("AuftragLieferungDetailsPage");

    @ViewChild('content', { static: false }) content: any;
    @ViewChild('wiegungenSelect', { read: IonSelect, static: false }) wiegungenSelect: IonSelect;
    @ViewChild('searchbar', { static: false }) searchbar: IonSearchbar;

    @Input() auftrag: AuftragEx;
    @Input() auftragdetails: IAuftragdetails;
    @Input() artikelHinzufuegenButtonAnzeigen = false;
    @Input() plusMinusButtonAnzeigen = true;

    subscriptions: Subscription[] = [];
    artikelList: Artikel[] = [];
    abstellortListe: AdresseEntity[] = [];
    formularKonfiguration: AuftragsFormular;
    isBarcodeFeld = false;
    allePositionen: AuftragspositionVO[] = [];
    sichtbarePositionen: AuftragspositionVO[] = [];
    barcodeZiel: any = null;
    barcodeZielTyp = '';
    barcodeZielAuftragsposition: AuftragspositionVO = null;
    behaelterBarcodeButtonSichtbar = true;
    behaelterNfcButtonSichtbar = true;
    istAuftragReadOnly = false;
    positionHinzufuegenErlaubt = new BehaviorSubject<boolean>(true);
    plusMinusButtons = false;
    laderaeume: LaderaumVO[] = [];
    laderaumAuswahlAktiv = false;
    behaelterNummerNurZiffern = false;
    isPositionDruckenMoeglich = false;
    isBehaelterNummerReadOnly = false;

    searchbarSichtbar = new BehaviorSubject<boolean>(false);
    suchtext = '';

    nfcAlert: HTMLIonAlertElement = null;
    nfcAktiv = false;
    sollMengeAnzeigen = false;

    dayNames = Constants.dayNames;
    uvvPruefungModal: HTMLIonModalElement;
    wiegedatenListe: Wiegedaten[] = [];
    wiegungAuswahlAuftragsposition: AuftragspositionVO;

    positionsEigenschaftenImmerAnzeigen = false;

    title = new BehaviorSubject('Lieferung');

    app = App.current;
    nfcRegistrationId: number;

    isLeerePositionenAusblenden = false;
    anzahlPositionAusgeblendet = 0;
    anzahlLeerePositionenWerdenAngezeigt = 0;
    isLeerePositionAnzeigen = false;

    constructor(
        private auftragService: AuftragService,
        private uvvService: UvvService,
        private modalController: ModalController,
        private stammdatenService: StammdatenService,
        private systemService: SystemService,
        private barcodeScanner: BarcodeScanner,
        private alertController: AlertController,
        private cdr: ChangeDetectorRef,
        private datePicker: DatePicker,
        private ngZone: NgZone,
        private waageService: WaageService,
        private translate: TranslateService) {

    }

    async ngOnInit() {
        this.log.debug('ngOnInit');

        App.current.klingelnBlockiert.next(true);

        this.behaelterNummerNurZiffern = AppConfig.current.einstellungen.BehaelterNummerNurZiffern === 'ja';
        this.sollMengeAnzeigen = AppConfig.current.einstellungen.TransportauftragMengeSollAnzeigen === 'ja';

        if (this.auftrag == null) {
            UiHelper.showError('Auftrag nicht gesetzt');
            return;
        }

        await this.auftragdetails.pruefeZeiterfassungGestartet();

        this.isBehaelterNummerReadOnly = false;

        switch (this.auftrag.Auftragstyp) {
            case Auftragstyp.Transportauftrag:
                this.positionHinzufuegenErlaubt.next(AppConfig.current.einstellungen.TransportauftraegePositionHinzufuegenErlaubt === 'ja');
                this.isBehaelterNummerReadOnly = AppConfig.current.einstellungen.TransportauftraBehaelterNummerHandeingabeErlaubt === 'nein';
                this.isLeerePositionenAusblenden = Utils.isTrue(AppConfig.current.einstellungen.TransportauftragLeerePositionenAusblenden);
                break;

            case Auftragstyp.Hofchecker:
                this.positionHinzufuegenErlaubt.next(AppConfig.current.einstellungen.HofauftraegePositionHinzufuegenErlaubt === 'ja');
                break;

            case Auftragstyp.Kommissionierung:
                this.positionHinzufuegenErlaubt.next(AppConfig.current.einstellungen.KommissionierungPositionHinzufuegenErlaubt === 'ja');
                break;

            case Auftragstyp.Wareneingang:
                this.positionHinzufuegenErlaubt.next(true); // TODO: Eventuell konfigurierbar machen
                this.isPositionDruckenMoeglich = true;
                break;
        }

        if (this.auftragdetails.isLieferungAendernGesperrt) {
            this.positionHinzufuegenErlaubt.next(false);
        }

        this.positionsEigenschaftenImmerAnzeigen = AppConfig.current.einstellungen.PositionsEigenschaftenImmerAnzeigen === 'ja';

        this.formularKonfiguration = await this.stammdatenService.getAuftragsFormularKonfiguration(this.auftrag);

        this.title.next(Utils.getEinstellung(this.formularKonfiguration, 'Lieferung', 'Titel', 'Lieferung'));

        this.istAuftragReadOnly = this.auftragdetails.isLieferungAendernGesperrt;

        this.behaelterNfcButtonSichtbar = App.current.behaelterMitNfc.getValue();
        this.behaelterBarcodeButtonSichtbar = App.current.behaelterMitBarcode.getValue();

        await this.ladeAbstellorte();

        this.laderaeume = [];

        if (this.auftrag.Auftragstyp === Auftragstyp.Transportauftrag) {
            this.laderaumAuswahlAktiv = AppConfig.current.einstellungen.TransportauftragLaderaumAuswahlAktiv === 'ja';

            if (this.laderaumAuswahlAktiv) {
                const fahrzeug = App.current.fahrzeug.getValue();

                if (fahrzeug && fahrzeug.Laderaeume && fahrzeug.Laderaeume.length > 1) {
                    for (const laderaum of fahrzeug.Laderaeume) {
                        const vo: LaderaumVO = {
                            key: fahrzeug.key + '-' + laderaum.Nummer,
                            nummer: laderaum.Nummer,
                            bezeichnung: laderaum.Nummer + ': ' + laderaum.Bezeichnung
                        };

                        if (!vo.bezeichnung) {
                            vo.bezeichnung = laderaum.Nummer.toString();
                        }

                        this.laderaeume.push(vo);
                    }
                } else {
                    this.laderaumAuswahlAktiv = false;
                }
            }
        }

        this.artikelList = this.stammdatenService.getAlleArtikel().getValue();

        this.updatePositionen();

        await this.ladeArtikelListe();

        this.auftragService.setzeAktivenAuftrag(this.auftrag, 'lieferung');

        this.nfcRegistrationId = this.systemService.registerNfcReceiver(0, (nfcEvent) => this.verarbeiteNfcEvent(nfcEvent));

        this.subscriptions.push(this.auftragdetails.beaforePageLeave.subscribe(() => this.onBeforePageLeave()));
    }

    private updatePositionen() {
        this.log.debug('updatePositionen');

        let artikelAenderbar = false;

        if (this.auftrag.Auftragstyp === Auftragstyp.Transportauftrag) {
            artikelAenderbar = AppConfig.current.einstellungen.TransportauftragArtikelAendern === 'ja';
        } else if (this.auftrag.Auftragstyp === Auftragstyp.Hofchecker) {
            artikelAenderbar = AppConfig.current.einstellungen.HofauftragArtikelAendern === 'ja';
        } else if (this.auftrag.Auftragstyp === Auftragstyp.Kommissionierung) {
            artikelAenderbar = AppConfig.current.einstellungen.KommissionierungArtikelAendern === 'ja';
        } else if (this.auftrag.Auftragstyp === Auftragstyp.Wareneingang) {
            artikelAenderbar = true; // TODO: eventuell konfigurierbar machen
        }

        this.allePositionen = [];

        for (const position of this.auftrag.Positionen) {
            const vo: AuftragspositionVO = {
                auftragsposition: position,
                artikel: {
                    artikelKey: position.ArtikelKey as any,
                    bezeichnung: position.Bezeichnung,
                    einheit: position.Einheit,
                },
                behaelter: null,
                mengeUngueltig: false,
                gewichtUngueltig: false,
                StandardMenge: position.StandardMenge,
                MengeOption: position.MengeOption,
                wiegungZuordnenAnzeigen: false,
                LaderaumAuswahlAktiv: false,
                suchtext: '',
                wiegenAktiv: false,
                isArtikelAenderbar: artikelAenderbar || position.IstNeu,
                mengeVariante: 'aenderbar'
            };

            if (!position.Eigenschaften) {
                position.Eigenschaften = [];
            }

            if (vo.MengeOption == 1) {
                vo.mengeVariante = 'standard-oder-null';

                if (!vo.StandardMenge) {
                    vo.StandardMenge = this.auftragService.getStandardMenge(vo.auftragsposition);
                }
            } else if (vo.MengeOption == 2) {
                vo.mengeVariante = 'nicht-aenderbar';
            }

            const eigenschaftenUppercase = AppConfig.current.einstellungen.AlleEigenschaftenUppercase === 'ja';

            for (const eigenschaft of position.Eigenschaften) {
                if (!eigenschaft.Typ) {
                    eigenschaft.Typ = 'text';
                }

                if (eigenschaftenUppercase) {
                    eigenschaft.Uppercase = true;
                }
            }

            // Hier extra mit == prüfen. Kann Zahl oder String sein
            const artikel = this.artikelList.find(p => p.artikelKey == vo.artikel.artikelKey);

            if (artikel) {
                // MengeOption
                switch (AppConfig.current.einstellungen.DatenherkunftMengeOption) {
                    case 'auftrag':
                        // Keine Änderung
                        break;

                    case 'artikel':
                        vo.MengeOption = artikel.MengeOption;
                        break;

                    default:
                        if (vo.MengeOption === null || typeof (vo.MengeOption) === 'undefined') {
                            vo.MengeOption = artikel.MengeOption;
                        }
                        break;
                }

                // StandardMenge
                switch (AppConfig.current.einstellungen.DatenherkunftStandardMenge) {
                    case 'auftrag':
                        // Keine Änderung
                        break;

                    case 'artikel':
                        vo.StandardMenge = artikel.StandardMenge;
                        break;

                    default:
                        if (vo.StandardMenge === null || typeof (vo.StandardMenge) === 'undefined') {
                            vo.StandardMenge = artikel.StandardMenge;
                        }
                        break;
                }

                // AuftragWiegungAktiv
                switch (AppConfig.current.einstellungen.DatenherkunftAuftragWiegungAktiv) {
                    case 'auftrag':
                        // Keine Änderung
                        break;

                    case 'artikel':
                        position.AuftragWiegungAktiv = artikel.AuftragWiegungAktiv;
                        break;

                    default:
                        if (position.AuftragWiegungAktiv === null || typeof (position.AuftragWiegungAktiv) === 'undefined') {
                            position.AuftragWiegungAktiv = artikel.AuftragWiegungAktiv;
                        }
                        break;
                }

                // LaderaumAuswahlAktiv
                switch (AppConfig.current.einstellungen.DatenherkunftLaderaumAuswahlAktiv) {
                    case 'auftrag':
                        // Keine Änderung
                        break;

                    case 'artikel':
                        vo.LaderaumAuswahlAktiv = artikel.LaderaumAuswahlAktiv;
                        break;

                    default:
                        if (this.laderaumAuswahlAktiv && artikel.LaderaumAuswahlAktiv) {
                            if (position.LaderaumAuswahlAktiv === null || typeof (position.LaderaumAuswahlAktiv) === 'undefined') {
                                // Nicht in Auftragsposition definiert, aber im Artikel.
                                // übernehme deshalb den Wert vom Artikel
                                vo.LaderaumAuswahlAktiv = true;
                            }
                        }
                        break;
                }

                // SeriennummerErforderlich
                switch (AppConfig.current.einstellungen.DatenherkunftSeriennummerErforderlich) {
                    case 'auftrag':
                        // Keine Änderung
                        break;

                    case 'artikel':
                        position.SeriennummerErforderlich = artikel.SeriennummerErforderlich;
                        break;

                    default:
                        if (artikel.SeriennummerErforderlich) {
                            if (position.SeriennummerErforderlich === null || typeof (position.SeriennummerErforderlich) === 'undefined') {
                                position.SeriennummerErforderlich = artikel.SeriennummerErforderlich;
                            }
                        }
                        break;
                }

                if (this.auftrag.Auftragstyp === Auftragstyp.Kommissionierung) {
                    // Chargenpflicht kommt immer aus dem Artikel
                    // Derzeit nur für Kommissionierungs-Aufträge setzen.
                    // Wird sonst nirgends benötigt
                    position.Chargenpflicht = artikel.Chargenpflicht;
                }
            }

            // Wird in RCL gefüllt aus Artikel "Wiegemöglichkeit auf App aktivieren"
            if (position.AuftragWiegungAktiv) {
                if (this.auftrag.Auftragstyp === Auftragstyp.Transportauftrag) {
                    vo.wiegenAktiv = AppConfig.current.einstellungen.TransportauftragPositionWiegenVerfuegbar === 'ja';
                } else if (this.auftrag.Auftragstyp === Auftragstyp.Hofchecker) {
                    vo.wiegenAktiv = AppConfig.current.einstellungen.HofauftraegePositionWiegenVerfuegbar === 'ja';
                }
            }

            if (App.current.waageVerfuegbar.getValue()) {
                // TODO: Klären wann Wiegungen zugeordnet werden können
                // Für STAMPFL konnten früher die Wiegungen zu allen Positionen zugeordnet werden.
                // Für HUBERT SCHMID haben wir das umgebaut, so dass nur noch Positionen mit "AuftragWiegungAktiv" erlaubt sind.
                // Muss noch bei STAMPF getestet werden (11.01.2023)
                // Muss beim STAMPF und HUBERT SCHMID geprüft werden
                // Das Verhalten war hier davor so, dass Wiegungen zu allen Positionen zugeordnet werden konnten.
                vo.wiegungZuordnenAnzeigen = !!position.AuftragWiegungAktiv;
            }

            if (this.laderaumAuswahlAktiv) {
                if (position.LaderaumAuswahlAktiv) {
                    vo.LaderaumAuswahlAktiv = true;
                }
            }

            this.updateSeriennummern(vo);
            this.updateBehaelter(vo);
            this.validiereMenge(vo);
            this.validiereGewicht(vo);

            vo.suchtext = `${position.PosNr};${position.Bezeichnung};${position.ArtikelKey};${position.Einheit}`

            if (vo.behaelter) {
                for (const b of vo.behaelter) {
                    if (b.behaelterNr) {
                        vo.suchtext += ';' + b.behaelterNr;
                    }
                    if (b.manuelleNummer) {
                        vo.suchtext += ';' + b.manuelleNummer;
                    }
                    if (b.zustand) {
                        vo.suchtext += ';' + b.zustand;
                    }
                }
            }

            if (!vo.auftragsposition.Eigenschaften) {
                vo.auftragsposition.Eigenschaften = [];
            }

            for (const e of vo.auftragsposition.Eigenschaften) {
                if (e.Bezeichnung) {
                    vo.suchtext += ';' + e.Bezeichnung;
                }
                if (e.Wert) {
                    vo.suchtext += ';' + e.Wert;
                }
            }

            vo.suchtext = vo.suchtext.toLowerCase();

            this.allePositionen.push(vo);
        }

        this.filterSichtbareAuftragspositionen();

        this.detectChanges();
    }

    updateSeriennummern(vo: AuftragspositionVO) {
        const position = vo.auftragsposition;

        if (vo.auftragsposition?.SeriennummerErforderlich) {
            if (!position.Elemente) {
                position.Elemente = [];
            }
        } else {
            position.Elemente = [];
            return;
        }

        let anzahl = Math.ceil(vo.auftragsposition.Menge);

        if (anzahl > 100) {
            anzahl = 100;
        }

        if (position.Elemente.length > anzahl) {
            position.Elemente = position.Elemente.filter(p => p.Nummer);
        }

        while (position.Elemente.length < anzahl) {
            position.Elemente.push({ Pos: 0, Typ: 'Seriennummer' });
        }

        for (let i = 0; i < position.Elemente.length; i++) {
            const element = position.Elemente[i];
            element.Pos = i + 1;
        }
    }

    onMengeGeaendert(vo: AuftragspositionVO) {
        this.log.debug('onMengeGeaendert: ' + vo.auftragsposition.Menge);

        if (vo.auftragsposition.Menge != null) {
            vo.auftragsposition.Menge = Utils.parseFloat(vo.auftragsposition.Menge);

            this.updateBehaelter(vo);
            this.updateSeriennummern(vo);
        }

        vo.auftragsposition.MengeIst = vo.auftragsposition.Menge;

        this.validiereMenge(vo);
        this.validiereGewicht(vo);

        this.auftragdetails.starteAuftragWennErforderlich('Menge geändert');
    }

    onGewichtGeaendert(vo: AuftragspositionVO) {
        this.log.debug('onGewichtGeaendert');

        if (this.auftragdetails) {
            this.auftragdetails.starteAuftragWennErforderlich('onGewichtGeaendert');
        }

        this.validiereGewicht(vo);
    }

    onLaderaumGeaendert(vo: AuftragspositionVO) {
        this.log.debug('onLaderaumGeaendert');
    }

    private updateBehaelter(vo: AuftragspositionVO) {
        this.log.debug('updateBehaelter');

        if (!vo.behaelter) {
            vo.behaelter = [];

            if (vo.auftragsposition.Behaelter) {
                for (const behaelter of vo.auftragsposition.Behaelter) {
                    const b: BehaelterbewegungVO = {
                        aktion: behaelter.Aktion as any,
                        behaelterNr: behaelter.BehaelterNr,
                        name: 'Behälter',
                        isScan: behaelter.IsScan,
                        abstellortKey: behaelter.Abstellort,
                        zustand: behaelter.Zustand,
                        position: behaelter.Position
                        // aktiv: false
                    };

                    if (behaelter.IsScan) {
                        b.gescannteBehaelterNr = behaelter.BehaelterNr;
                    }

                    vo.behaelter.push(b);
                }
            }
        }

        const oldBehaelter = [...vo.behaelter];
        const neueBehaelter: BehaelterbewegungVO[] = [];

        const abstellortVerwenden = App.current.behaelterAbstellorteVerwenden.getValue();

        if (vo.auftragsposition.BhAktion && vo.auftragsposition.Menge > 0) {
            const bhAktion = vo.auftragsposition.BhAktion.toLowerCase().trim();
            let anzahl = Math.ceil(vo.auftragsposition.Menge);

            if (anzahl > 100) {
                anzahl = 100;
            }

            switch (bhAktion) {
                case 'stellen':
                    for (let i = 1; i <= anzahl; i++) {
                        const name = anzahl > 1 ? i + '. Behälter' : 'Behälter';

                        neueBehaelter.push({
                            name,
                            aktion: 'stellen',
                            behaelterNr: '',
                            position: null
                        });
                    }
                    break;

                case 'tauschen':
                    for (let i = 1; i <= anzahl; i++) {
                        const name = anzahl > 1 ? i + '. Steller' : 'Steller';

                        neueBehaelter.push({
                            name,
                            aktion: 'stellen',
                            behaelterNr: '',
                            position: null
                        });
                    }

                    for (let i = 1; i <= anzahl; i++) {
                        const name = anzahl > 1 ? i + '. Abzieher' : 'Abzieher';

                        neueBehaelter.push({
                            name,
                            aktion: 'abziehen',
                            behaelterNr: '',
                            abstellortAktiv: abstellortVerwenden,
                            position: null
                        });
                    }
                    break;

                case 'abziehen':
                    for (let i = 1; i <= anzahl; i++) {
                        const name = anzahl > 1 ? i + '. Behälter' : 'Behälter';

                        neueBehaelter.push({
                            name,
                            aktion: 'abziehen',
                            behaelterNr: '',
                            abstellortAktiv: abstellortVerwenden,
                            position: null
                        });
                    }
                    break;

                case 'entleeren':
                    for (let i = 1; i <= anzahl; i++) {
                        const name = anzahl > 1 ? i + '. Behälter' : 'Behälter';

                        neueBehaelter.push({
                            name,
                            aktion: 'entleeren',
                            behaelterNr: '',
                            position: null
                        });
                    }
                    break;

                case 'umsetzen':
                    for (let i = 1; i <= anzahl; i++) {
                        const name = anzahl > 1 ? i + '. Behälter' : 'Behälter';

                        neueBehaelter.push({
                            name,
                            aktion: 'umsetzen',
                            behaelterNr: '',
                            position: null
                        });
                    }
                    break;

                case 'direktbeladung':
                case 'warten':
                    for (let i = 1; i <= anzahl; i++) {
                        const name = anzahl > 1 ? i + '. Behälter' : 'Behälter';

                        neueBehaelter.push({
                            name,
                            aktion: 'direktbeladung',
                            behaelterNr: '',
                            position: null
                        });
                    }
                    break;
            }

            // distinct
            const aktionen = neueBehaelter.map(p => p.aktion).filter((value, index, self) => self.indexOf(value) === index);

            const standardAbstellort = this.abstellortListe.find(p => p.IsStandardContainerAbstellort);

            // Übernehme bereits vorhandene Daten
            for (const aktion of aktionen) {
                const oldList = oldBehaelter.filter(p => p.aktion == aktion);
                const newList = neueBehaelter.filter(p => p.aktion == aktion);

                for (let i = 0; i < oldList.length; i++) {
                    if (i < newList.length) {
                        const old = oldList[i];
                        const newItem = newList[i];

                        if (!old.abstellortKey && standardAbstellort) {
                            old.abstellortKey = standardAbstellort.Key;
                        }

                        if (old.abstellortKey) {
                            newItem.abstellort = this.abstellortListe.find(p => p.Key === old.abstellortKey);
                        }

                        newItem.behaelterNr = old.behaelterNr;
                        newItem.abstellortKey = old.abstellortKey;
                        newItem.zustand = old.zustand;
                        newItem.position = old.position;
                        newItem.gescannteBehaelterNr = old.gescannteBehaelterNr;

                        if (old.behaelterNr) {
                            const uvvBehaelter = this.uvvService.getBehaelterByBehaelterNr(old.behaelterNr, old.isScan);

                            this.updateBehaelterManuelleNummer(newItem, uvvBehaelter);
                        }
                    }
                }
            }
        }

        vo.behaelter = neueBehaelter;

        this.detectChanges();
    }

    private detectChanges() {
        try {
            this.cdr.detectChanges();
        } catch (err) {
            // ignroe
        }
    }

    ngAfterViewInit() {
        if (AppConfig.current.einstellungen.FocusErstePosition === 'ja' && !this.istAuftragReadOnly) {
            setTimeout(() => {
                const input = document.querySelector('input.native-input') as HTMLInputElement;

                if (input) {
                    input.focus();
                    input.select();
                }
            }, 500);
        }
    }

    verarbeiteNfcEvent(event: NfcEvent) {
        this.log.debug('verarbeiteNfcEvent');

        try {
            this.log.debug('NFC seriennummer: ' + event.seriennummer);

            this.ngZone.run(() => {
                let barcode: string;

                if (App.current.NfcPayloadVerwenden && event.payload) {
                    barcode = event.payload;
                } else {
                    barcode = event.seriennummer;
                }

                if (this.barcodeZielTyp === 'Behaelterbewegung' && this.barcodeZiel) {
                    const vo = this.barcodeZiel as BehaelterbewegungVO;
                    vo.behaelterNr = barcode.trim();
                    vo.gescannteBehaelterNr = barcode.trim();
                    vo.isScan = true;

                    // Position hier nur asynchron holen und in Behälterbewegung merken
                    this.systemService.getAktuelleGpsPosition('Behälter NFC').then(p => vo.position = p);

                    this.pruefeBehaelterNr(vo);

                } else if (this.barcodeZielTyp === 'Eigenschaft' && this.barcodeZiel) {
                    const vo = this.barcodeZiel as ReCoMobilEigenschaft;
                    vo.Wert = barcode.trim();
                }

                this.barcodeZielTyp = '';

                this.detectChanges();
            });

            if (this.nfcAlert) {
                this.nfcAlert.dismiss();
                this.nfcAlert = null;
            }
        } catch (err) {
            this.log.error('verarbeiteNfcEvent: ' + err, err);
        }
    }

    /**
     * Übernimtm die Daten aus dem Formular in den Auftrag (nur die, die nicht sowieso direkt geschrieben wurden)
     */
    aktualisiereAuftrag() {
        this.log.debug('aktualisiereAuftrag: ' + this.auftrag.Key);

        const behaelterPruefung = App.current.behaelterNummerPruefung.getValue();

        if (this.auftrag) {
            for (const positionVo of this.allePositionen) {
                positionVo.auftragsposition.Behaelter = [];
                positionVo.auftragsposition.MengeIst = positionVo.auftragsposition.Menge;

                if (positionVo.behaelter) {
                    for (const b of positionVo.behaelter) {
                        const behaelter: ReCoMobilBehaelterbewegung = {
                            Aktion: b.aktion,
                            BehaelterNr: b.behaelterNr,
                            Abstellort: b.abstellortKey,
                            Zustand: b.zustand,
                            Position: b.position,
                            IsScan: b.behaelterNr === b.gescannteBehaelterNr
                        };

                        if (b.abstellort) {
                            behaelter.Abstellort = b.abstellort.Key;
                        }

                        if (b.behaelterNrUngueltig && behaelterPruefung === 'fehler') {
                            // Ungültige Behälter-Nummer nicht übernehmen
                            behaelter.BehaelterNr = '';
                            behaelter.Position = null;
                        }

                        positionVo.auftragsposition.Behaelter.push(behaelter);
                    }
                }

                if (this.laderaumAuswahlAktiv) {
                    if (positionVo.auftragsposition.Laderaum) {
                        // Noch die Laderaum-Nummer aktualisieren.
                        const vo = this.laderaeume.find(p => p.key == positionVo.auftragsposition.Laderaum);

                        if (vo) {
                            positionVo.auftragsposition.LaderaumNummer = vo.nummer;
                        }
                    }
                }
            }
        }
    }

    /**
     * Runs when the page is about to be destroyed and have its elements removed.
     */
    async ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];

        this.systemService.unregisterNfcReceiver(this.nfcRegistrationId);

        this.log.debug('ionViewWillLeave');

        this.aktualisiereAuftrag();

        if (this.auftragService.istAktiverAuftrag(this.auftrag)) {
            await this.auftragService.speichereAuftrag(this.auftrag);

            // this.auftragService.setzeAktivenAuftrag(null, 'lieferung details exit');
        }

        App.current.klingelnBlockiert.next(false);

        this.auftragdetails.auftragspositionenGeaendert.next();
    }

    /**
     * Wird aufgerufen bevor eine Wizard-Seite verlassen wird.
     */
    onBeforePageLeave(): void {
        this.log.debug('onBeforePageLeave');

        // Auftrag muss aktualisiert werden, damit die Validierung funktioniert
        this.aktualisiereAuftrag();
    }

    onReset() {
    }

    async ladeAbstellorte() {
        const adressen = await this.stammdatenService.getAdressen();
        this.abstellortListe = adressen.filter(p => p.IsContainerAbstellort);
    }

    async ladeArtikelListe() {
        const sub = this.stammdatenService.getAlleArtikel().subscribe(data => {
            this.artikelList = data;

            // this.log.debug('artikelList', this.artikelList);

            this.artikellisteGeladen();

            // Sortieren nach angegebener Sortierung
            // this.artikelList.sort((a, b) => a.sortierung - b.sortierung);
        });

        this.subscriptions.push(sub);
    }

    artikellisteGeladen() {
        if (!this.auftrag) {
            return;
        }

        let geaendert = false;

        // Korrigiere fehlende Eigenschafts-Typen
        for (const position of this.auftrag.Positionen) {
            // Hier extra mit == prüfen. Kann Zahl oder String sein
            const artikel = this.artikelList.find(p => p.artikelKey as any == position.ArtikelKey);

            if (artikel) {
                geaendert = geaendert || this.aktualisierePositionsEigenschaften(position, artikel);
            }
        }

        if (geaendert) {
            this.updatePositionen();
        } else {
            this.detectChanges();
        }
    }

    aktualisierePositionsEigenschaftenAusVertragsposition(position: Auftragsposition, vertragsposition: Vertragsposition): boolean {
        if (!vertragsposition.Eigenschaften) {
            return false;
        }

        let geaendert = false;

        if (!position.Eigenschaften) {
            position.Eigenschaften = [];
        }

        if (!position.Einheit) {
            position.Einheit = vertragsposition.Einheit;
        }

        if (position.GewichtInKgAktiv === null || typeof (position.GewichtInKgAktiv) === 'undefined') {
            position.GewichtInKgAktiv = vertragsposition.GewichtInKgAktiv;
        }

        if (position.GewichtInKgPflicht === null || typeof (position.GewichtInKgPflicht) === 'undefined') {
            position.GewichtInKgPflicht = vertragsposition.GewichtInKgPflicht;
        }

        if (!position.Bezeichnung) {
            position.Bezeichnung = vertragsposition.Bezeichnung;
        }

        for (const eigenschaft of position.Eigenschaften) {
            if (!eigenschaft.Typ) {
                const artikelEigenschaft = vertragsposition.Eigenschaften.find(p => p.Bezeichnung === eigenschaft.Bezeichnung);

                if (artikelEigenschaft) {
                    eigenschaft.Typ = artikelEigenschaft.Typ;
                    this.log.debug('Typ aktualisiert: ' + eigenschaft.Typ, position);
                    geaendert = true;
                }
            }
        }

        // Fehlende Eigenschaften hinzufügen
        if (position.IstNeu) {
            for (const eigenschaft of vertragsposition.Eigenschaften) {
                const positionEigenschaft = position.Eigenschaften.find(p => p.Bezeichnung === eigenschaft.Bezeichnung);

                if (!positionEigenschaft) {
                    this.log.debug('Eigenschaft zu position hinzufügen', { position, eigenschaft });

                    position.Eigenschaften.push({
                        Bezeichnung: eigenschaft.Bezeichnung,
                        Typ: eigenschaft.Typ,
                        Wert: '',
                        Sortierung: eigenschaft.Sortierung
                    });

                    geaendert = true;
                }
            }

            AuftragHelper.sortiereEigenschaften(position.Eigenschaften);
        }

        return geaendert;
    }

    aktualisierePositionsEigenschaften(position: Auftragsposition, artikel: Artikel): boolean {
        if (!artikel.eigenschaften) {
            return false;
        }

        let geaendert = false;

        if (!position.Eigenschaften) {
            position.Eigenschaften = [];
        }

        if (!position.Einheit) {
            position.Einheit = artikel.einheit;
        }

        // if (position.MengeOption === null || typeof (position.MengeOption) === 'undefined') {
        //     position.MengeOption = artikel.MengeOption;
        // }

        // if (position.StandardMenge === null || typeof (position.StandardMenge) === 'undefined') {
        //     position.StandardMenge = artikel.StandardMenge;
        // }

        // if (position.StandardMenge !== null && typeof (position.StandardMenge) !== 'undefined') {
        //     if (position.Menge === null || typeof (position.Menge) === 'undefined') {
        //         position.Menge = position.StandardMenge;
        //     }
        // }

        if (position.GewichtInKgAktiv === null || typeof (position.GewichtInKgAktiv) === 'undefined') {
            position.GewichtInKgAktiv = artikel.GewichtInKgAktiv;
        }

        if (position.GewichtInKgPflicht === null || typeof (position.GewichtInKgPflicht) === 'undefined') {
            position.GewichtInKgPflicht = artikel.GewichtInKgPflicht;
        }

        if (!position.Bezeichnung) {
            position.Bezeichnung = artikel.bezeichnung;
        }

        const allEigenschaftenUppercase = AppConfig.current.einstellungen.AlleEigenschaftenUppercase === 'ja';

        for (const eigenschaft of position.Eigenschaften) {
            const artikelEigenschaft = artikel.eigenschaften.find(p => p.bezeichnung === eigenschaft.Bezeichnung);

            if (artikelEigenschaft) {
                if (!eigenschaft.Typ) {
                    eigenschaft.Typ = artikelEigenschaft.typ;
                    this.log.debug('Typ aktualisiert: ' + eigenschaft.Typ, position);
                    geaendert = true;
                }

                if (artikelEigenschaft.Regex && eigenschaft.Regex != artikelEigenschaft.Regex) {
                    eigenschaft.Regex = artikelEigenschaft.Regex;
                    this.log.debug('Regex aktualisiert: ' + eigenschaft.Regex, position);
                    geaendert = true;
                }

                let uppercase = artikelEigenschaft.Uppercase;

                if (allEigenschaftenUppercase) {
                    uppercase = true;
                }

                if (uppercase != null && eigenschaft.Uppercase != uppercase) {
                    eigenschaft.Uppercase = uppercase;
                    this.log.debug('Uppercase aktualisiert: ' + eigenschaft.Uppercase, position);
                    geaendert = true;
                }
            }
        }

        // Fehlende Eigenschaften hinzufügen
        if (position.IstNeu) {
            for (const eigenschaft of artikel.eigenschaften) {
                const positionEigenschaft = position.Eigenschaften.find(p => p.Bezeichnung === eigenschaft.bezeichnung);

                if (!positionEigenschaft) {
                    this.log.debug('Eigenschaft zu position hinzufügen', { position, eigenschaft });

                    position.Eigenschaften.push({
                        Key: eigenschaft.key,
                        Bezeichnung: eigenschaft.bezeichnung,
                        Typ: eigenschaft.typ,
                        Regex: eigenschaft.Regex,
                        Uppercase: eigenschaft.Uppercase,
                        Wert: '',
                        Sortierung: eigenschaft.sortierung
                    });

                    geaendert = true;
                }
            }

            if (geaendert) {
                AuftragHelper.sortiereEigenschaften(position.Eigenschaften);
            }
        }

        return geaendert;
    }

    validiereMenge(vo: AuftragspositionVO) {
        vo.mengeUngueltig = false;

        if (typeof (vo.auftragsposition.Menge) === 'string' && vo.auftragsposition.Menge !== '') {
            vo.auftragsposition.Menge = Utils.parseFloat(vo.auftragsposition.Menge);
        }

        if (vo.auftragsposition.Menge === null || typeof (vo.auftragsposition.Menge) === 'undefined' || (vo.auftragsposition.Menge as any) === '') {
            vo.mengeUngueltig = true;
        }

        if (vo.auftragsposition.MengeOption === MengeOptionTyp.StandardOderNull) {
            if (vo.auftragsposition.Menge != vo.auftragsposition.StandardMenge && vo.auftragsposition.Menge !== 0 && vo.auftragsposition.StandardMenge) {
                vo.mengeUngueltig = true;
                this.log.warn(`Menge ungültig: ArtikelKey=${vo.auftragsposition.ArtikelKey}, Option=${vo.auftragsposition.MengeOption}, StandardMenge=${vo.auftragsposition.StandardMenge}, Menge=${vo.auftragsposition.Menge}`);
            }
        } else if (vo.auftragsposition.MengeOption === MengeOptionTyp.AenderbarGroesserNull) {
            if (vo.auftragsposition.Menge <= 0) {
                vo.mengeUngueltig = true;
                this.log.warn(`Menge ungültig: ArtikelKey=${vo.auftragsposition.ArtikelKey}, Option=${vo.auftragsposition.MengeOption}, Menge=${vo.auftragsposition.Menge}`);
            }
        } else {
            if (vo.auftragsposition.Menge === null || typeof (vo.auftragsposition.Menge) === 'undefined') {
                vo.mengeUngueltig = true;
            }
        }
    }

    validiereGewicht(vo: AuftragspositionVO) {
        vo.gewichtUngueltig = false;

        if (vo.auftragsposition.Menge && vo.auftragsposition.GewichtInKgAktiv && vo.auftragsposition.GewichtInKgPflicht) {
            if (!vo.auftragsposition.GewichtInKg) {
                vo.gewichtUngueltig = true;
            }
        }
    }

    artikelChanged(vo: AuftragspositionVO) {
        this.log.debug('artikelChanged', vo);

        // tslint:disable-next-line:triple-equals
        const artikel = this.artikelList.find(p => p.artikelKey == vo.artikel.artikelKey);

        this.log.info('artikel', vo.artikel);
        this.log.info('artikelList', this.artikelList);
        this.log.info('auftragsposition vo', vo);
        this.log.info('artikel', artikel);

        if (artikel) {
            vo.auftragsposition.ArtikelKey = artikel.artikelKey as any;
            vo.auftragsposition.Bezeichnung = artikel.bezeichnung;
            vo.auftragsposition.Einheit = artikel.einheit;

            vo.auftragsposition.Eigenschaften = [];

            for (const p of artikel.eigenschaften) {
                vo.auftragsposition.Eigenschaften.push({
                    Bezeichnung: p.bezeichnung,
                    Typ: p.typ,
                    Wert: '',
                    Sortierung: p.sortierung
                });
            }

            AuftragHelper.sortiereEigenschaften(vo.auftragsposition.Eigenschaften);
        }

        this.log.debug('artikelChanged after', vo);

        this.cdr.detectChanges();
    }

    abstellortChanged(b: BehaelterbewegungVO) {
        this.log.debug('abstellortChanged', b);

        if (b.abstellort) {
            b.abstellortKey = b.abstellort.Key;
        }

        this.cdr.detectChanges();
    }

    async onArtikelAendern(vo: AuftragspositionVO) {
        if (this.auftragdetails.isLieferungAendernGesperrt) {
            this.auftragdetails.showAuftragSchreibgeschuetzt();
            return;
        }

        if (!vo.auftragsposition) {
            this.log.warn('vo.auftragsposition ist NULL');
            return;
        }

        if (!vo.isArtikelAenderbar) {
            return;
        }

        const modal = await this.modalController.create({
            component: AuftragArtikelAuswahlPage,
            componentProps: {
                'auftrag': this.auftrag
            }
        });

        this.log.debug('AuftragArtikelAuswahlPage wird angezeigt...');
        await modal.present();

        const modalResult = await modal.onDidDismiss();
        this.log.debug('AuftragArtikelAuswahlPage wurde geschlossen', modalResult);

        const artikel = modalResult.data.artikel as Artikel;
        const vertragsposition = modalResult.data.vertragsposition as Vertragsposition;

        if (artikel) {
            vo.artikel = artikel;

            if (!vo.auftragsposition.IstNeu && !vo.auftragsposition.ArtikelWurdeGeaendert) {
                if (vo.auftragsposition.ArtikelKey != artikel.artikelKey as any) {
                    vo.auftragsposition.ArtikelWurdeGeaendert = true;
                }
            }

            vo.auftragsposition.Bezeichnung = artikel.bezeichnung;
            vo.auftragsposition.ArtikelKey = artikel.artikelKey as any;
            vo.auftragsposition.Einheit = artikel.einheit;
            vo.auftragsposition.Eigenschaften = []; //

            this.aktualisierePositionsEigenschaften(vo.auftragsposition, artikel);

            this.updatePositionen();

            this.cdr.detectChanges();
        } else if (vertragsposition) {
            vo.artikel = null; // vertragsposition;
            vo.vertragsposition = vertragsposition;

            if (!vo.auftragsposition.IstNeu && !vo.auftragsposition.ArtikelWurdeGeaendert) {
                if (vo.auftragsposition.ArtikelKey != vertragsposition.ArtikelKey as any) {
                    vo.auftragsposition.ArtikelWurdeGeaendert = true;
                }
            }

            vo.auftragsposition.Bezeichnung = vertragsposition.Bezeichnung;
            vo.auftragsposition.ArtikelKey = vertragsposition.ArtikelKey as any;
            vo.auftragsposition.Eigenschaften = []; //

            this.aktualisierePositionsEigenschaftenAusVertragsposition(vo.auftragsposition, vertragsposition);

            this.updatePositionen();

            this.cdr.detectChanges();
        }
    }

    async onPositionHinzufuegen() {
        if (this.auftragdetails.isLieferungAendernGesperrt) {
            this.auftragdetails.showAuftragSchreibgeschuetzt();
            return;
        }

        const modal = await this.modalController.create({
            component: AuftragArtikelAuswahlPage,
            componentProps: {
                'auftrag': this.auftrag
            }
        });

        this.log.debug('AuftragArtikelAuswahlPage wird angezeigt...');
        await modal.present();

        const modalResult = await modal.onDidDismiss();
        this.log.debug('AuftragArtikelAuswahlPage wurde geschlossen', modalResult);

        const artikel = modalResult.data.artikel as Artikel;
        const vertrag = modalResult.data.vertrag as Vertrag;
        const vertragsposition = modalResult.data.vertragsposition as Vertragsposition;

        if (artikel) {
            let posNr = 1;

            for (const p of this.auftrag.Positionen) {
                if (p.PosNr >= posNr) {
                    posNr = p.PosNr + 1;
                }
            }

            let menge = 1;

            if (typeof (artikel.StandardMenge) == 'number') {
                menge = artikel.StandardMenge;
            }

            if (menge == 0 && artikel.einheit) {
                const einheit = artikel.einheit.toLowerCase();

                if (einheit == 'stk' || einheit == 'st' || einheit == 'pau') {
                    // Menge 0 macht bei Stück-Artikeln keinen Sinn
                    menge = 1;
                }
            }

            const position: Auftragsposition = {
                PosNr: posNr,
                ArtikelKey: artikel.artikelKey as any,
                Bezeichnung: artikel.bezeichnung,
                Bezeichnung2: artikel.Bezeichnung2,
                EinzelVK: artikel.einzelVK,
                MwstSatz: artikel.mwstSatz,
                BhAktion: artikel.BhAktion,
                IstNeu: true,
                IstGesendet: false,
                Eigenschaften: [],
                Menge: menge
            };

            position.MengeOption = artikel.MengeOption;
            position.StandardMenge = artikel.StandardMenge;

            // Wenn ein Artikel ausgewählt wurde soll geprüft werden ob ein Vertrag für diesen Artikel vorhanden ist.
            // Wenn ja, dann soll der Preis aus dem Vertrag verwendet werden (Beispiel: ZTN).
            let passendeVertragsposition: Vertragsposition = this.suchePassendeVertragspositionFuerArtikel(artikel);

            if (passendeVertragsposition) {
                // Es wurde eine Vertragsposition mit diesem Artikel gefunden.
                // => Verwende den Preis aus der Vertragsposition
                position.EinzelVK = passendeVertragsposition.EinzelVK;
                position.MwstSatz = passendeVertragsposition.MwstSatz;
            }

            position.SeriennummerErforderlich = artikel.SeriennummerErforderlich;
            position.AuftragWiegungAktiv = artikel.AuftragWiegungAktiv;
            position.LaderaumAuswahlAktiv = artikel.LaderaumAuswahlAktiv;

            this.auftrag.Positionen.push(position);

            this.aktualisierePositionsEigenschaften(position, artikel);

            this.updatePositionen();

            this.cdr.detectChanges();

            setTimeout(() => {
                if (this.content) {
                    this.content.scrollToBottom(300);
                }
            }, 100);
        } else if (vertragsposition) {
            let posNr = 1;

            for (const p of this.auftrag.Positionen) {
                if (p.PosNr >= posNr) {
                    posNr = p.PosNr + 1;
                }
            }

            const position: Auftragsposition = {
                PosNr: posNr,
                ArtikelKey: vertragsposition.ArtikelKey,
                Bezeichnung: vertragsposition.Bezeichnung,
                IstNeu: true,
                IstGesendet: false,
                Eigenschaften: [],
                Menge: +vertragsposition.Menge,
                Vertragsposition: vertragsposition.PosNr,
                EinzelVK: vertragsposition.EinzelVK,
                MwstSatz: vertragsposition.MwstSatz,
                BhAktion: vertragsposition.BhAktion,
            };

            if (vertrag) {
                position.Vertragsnummer = vertrag.Vertragsnummer;
            }

            this.auftrag.Positionen.push(position);

            this.aktualisierePositionsEigenschaftenAusVertragsposition(position, vertragsposition);

            this.updatePositionen();

            setTimeout(() => {
                if (this.content) {
                    this.content.scrollToBottom(300);
                }
            }, 100);
        }

        // return;


        // const selectables = this.artikelSelectables.toArray();

        // if (selectables.length) {
        //     const selectable = selectables[selectables.length - 1];
        //     await selectable.open();

        //     const subscription = selectable.onClose.subscribe(() => {
        //         subscription.unsubscribe();

        //         const artikel = selectable.value;

        //         if (!artikel || !artikel.bezeichnung) {
        //             // Es wurde kein Artikel ausgewählt. Position deshalb wieder löschen
        //             this.auftrag.Positionen.pop();

        //             this.updatePositionen();
        //         }
        //     });
        // }

        // setTimeout(() => this.content.scrollToBottom(300), 100);
    }

    suchePassendeVertragspositionFuerArtikel(artikel: Artikel): Vertragsposition {
        if (!this.auftrag.Vertraege?.length) {
            // Kein Vertrag vorhanden
            return null;
        }

        const strArtikelKey = artikel.artikelKey?.toString();

        for (const vertrag of this.auftrag.Vertraege) {
            if (!vertrag.Positionen) {
                continue;
            }

            for (const position of vertrag.Positionen) {
                if (position.ArtikelKey == strArtikelKey) {
                    // Passende Vertragspoisition gefunden. Verwende den Preis aus dieser Vertragsposition
                    return position;
                }
            }
        }

        return null;
    }

    async onPositionLoeschen(e: Event, vo: AuftragspositionVO) {
        if (!vo.auftragsposition.IstNeu) {
            return;
        }

        if (vo.auftragsposition.IstGesendet) {
            // Die Position wurde bereits ein ReCoLog übertragen. Sie darf deshalb nicht mehr gelöscht werden.
            // In ReCoLog ist das Löschen der Positionen (noch) nicht implementiert.
            return;
        }

        if (this.auftragdetails.isLieferungAendernGesperrt) {
            this.auftragdetails.showAuftragSchreibgeschuetzt();
            return;
        }

        const ok = await UiHelper.confirmJaNein(`Position ${vo.auftragsposition.Bezeichnung} wirklich löschen?`, 'Löschen');

        if (!ok) {
            return;
        }

        const index = this.auftrag.Positionen.findIndex(p => p.PosNr === vo.auftragsposition.PosNr);

        if (index >= 0) {
            this.auftrag.Positionen.splice(index, 1);

            this.updatePositionen();
        }
    }

    async scanBehaelterBarcode(vo: AuftragspositionVO, b: BehaelterbewegungVO) {
        BarcodeAuthGuard.barcodeScanAktiv = true;

        try {
            let barcodeData;

            if (App.isCordovaAvailable()) {
                barcodeData = await this.barcodeScanner.scan({
                    showTorchButton: true,
                });
            } else {
                await App.delay(500);

                barcodeData = {
                    cancelled: false,
                    text: 'DEMO',
                    format: 'CODE_39'
                };
            }

            this.log.debug(`scanBehaelterBarcode: ${barcodeData.text}`);

            if (barcodeData.text && !barcodeData.cancelled) {
                b.behaelterNr = barcodeData.text;
                b.gescannteBehaelterNr = barcodeData.text;
                b.isScan = true;

                // Position hier nur asynchron holen und in Behälterbewegung merken
                this.systemService.getAktuelleGpsPosition('Behälter Barcode').then(position => b.position = position);

                if (this.auftragdetails) {
                    this.auftragdetails.starteAuftragWennErforderlich('onBehaelterNrLostFocus');
                }

                setTimeout(() => {
                    this.pruefeBehaelterNr(b);
                }, 150);
            }
        } catch (err) {
            UiHelper.showError(err);
        }

        setTimeout(() => BarcodeAuthGuard.barcodeScanAktiv = false, 500);
    }

    async selectDatum(vo: any, p: ReCoMobilEigenschaft) {
        this.log.debug('selectDatum: ' + p.Bezeichnung);

        try {
            let date = new Date();

            if (p.Wert && p.Wert.length >= 8 && p.Wert.length <= 10) {
                // Versuche das eingegebene Datum zu parsen
                date = moment(p.Wert).toDate();
            }

            date = await this.datePicker.show({
                date: new Date(),
                mode: 'date',
                androidTheme: this.datePicker.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT
            });

            const m = moment(date);

            p.Wert = m.format('DD.MM.YYYY');
        } catch (err) {
            // UiHelper.showError(err);
        }
    }

    async scanEigenschaftNfc(vo: any, p: ReCoMobilEigenschaft) {
        this.log.debug('scanEigenschaftNfc');

        BarcodeAuthGuard.barcodeScanAktiv = true;
        this.barcodeZiel = p;
        this.barcodeZielTyp = 'Eigenschaft';

        if (this.nfcAlert) {
            this.nfcAlert.dismiss();
            this.nfcAlert = null;
        }

        this.nfcAlert = await this.alertController.create({
            mode: 'ios',
            message: I18N.instant('NFCErkennungLaeuft'),
            buttons: [{
                text: I18N.instant('Abbrechen'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                }
            },
            ]
        });

        if (!App.isCordovaAvailable()) {
            setTimeout(() => {
                this.nfcAlert.dismiss();
                p.Wert = 'DEMO12345';
            }, 2000);
        }

        await this.nfcAlert.present();

        await this.nfcAlert.onDidDismiss();

        if (this.auftragdetails) {
            this.auftragdetails.starteAuftragWennErforderlich('scanEigenschaftNfc');
        }

        setTimeout(() => BarcodeAuthGuard.barcodeScanAktiv = false, 500);
    }

    async scanBehaelterNfc(e: Event, vo: AuftragspositionVO, b: BehaelterbewegungVO) {
        ClickHelper.click(e, async () => {
            BarcodeAuthGuard.barcodeScanAktiv = true;

            this.barcodeZiel = b;
            this.barcodeZielAuftragsposition = vo;
            this.barcodeZielTyp = 'Behaelterbewegung';

            if (this.nfcAlert) {
                this.nfcAlert.dismiss();
                this.nfcAlert = null;
            }

            this.nfcAlert = await this.alertController.create({
                mode: 'ios',
                message: I18N.instant('NFCErkennungLaeuft'),
                buttons: [{
                    text: I18N.instant('Abbrechen'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                },
                ]
            });

            if (!App.isCordovaAvailable()) {
                setTimeout(() => {
                    this.nfcAlert.dismiss();
                    b.behaelterNr = 'E00401005872eEB8';

                    this.pruefeBehaelterNr(b);
                }, 2000);
            }

            await this.nfcAlert.present();

            await this.nfcAlert.onDidDismiss();

            if (this.auftragdetails) {
                await this.auftragdetails.starteAuftragWennErforderlich('scanBehaelterNfc');
            }

            setTimeout(() => BarcodeAuthGuard.barcodeScanAktiv = false, 500);
        });
    }

    // async onBehaelterFocus(b: BehaelterbewegungVO) {
    //     this.log.debug('onBehaelterFocus', b);

    //     if (this.nfcAktiv) {
    //         b.aktiv = true;
    //     }

    //     setTimeout(() => {
    //         this.isBarcodeFeld = true;
    //         this.barcodeZiel = b;
    //         this.barcodeZielTyp = 'Behaelterbewegung';

    //         this.detectChanges();
    //     }, 250);
    // }

    // onBehaelterLostFocus(b: BehaelterbewegungVO) {
    //     this.log.debug('onBehaelterLostFocus', b);
    //     b.aktiv = false;

    //     setTimeout(() => {
    //         this.isBarcodeFeld = false;
    //         this.barcodeZiel = null;
    //         this.barcodeZielTyp = null;

    //         this.detectChanges();
    //     }, 150);

    //     setTimeout(() => {
    //         this.log.info('document.activeElement', document.activeElement);
    //     }, 500);
    // }

    async onEigenschaftFocus(vo: AuftragspositionVO, p: Eigenschaft) {
        this.log.debug('onEigenschaftFocus', { vo, p });

        setTimeout(() => {
            this.isBarcodeFeld = true;
            this.barcodeZiel = p;
            this.barcodeZielTyp = 'Behaelterbewegung';

            this.detectChanges();
        }, 250);
    }

    onEigenschaftLostFocus(vo: AuftragspositionVO, p: Eigenschaft) {
        this.log.debug('onEigenschaftLostFocus', { vo, p });

        setTimeout(() => {
            this.isBarcodeFeld = false;
            this.barcodeZiel = null;
            this.barcodeZielTyp = null;

            if (p.Wert) {
                if (this.auftragdetails) {
                    this.auftragdetails.starteAuftragWennErforderlich('onEigenschaftLostFocus');
                }
            }

            this.detectChanges();
        }, 150);
    }

    // onEigenschaftDatumLostFocus(vo: AuftragspositionVO, b: BehaelterbewegungVO) {
    //     this.log.debug('onEigenschaftDatumLostFocus', { vo, b });

    //     if (b.behaelterNr) {
    //         if (this.auftragdetails) {
    //             this.auftragdetails.starteAuftragWennErforderlich('onBehaelterNrLostFocus');
    //         }
    //     }
    // }

    onBehaelterNrLostFocus(vo: AuftragspositionVO, b: BehaelterbewegungVO) {
        this.log.debug('onBehaelterNrLostFocus', { vo, b });

        if (b.behaelterNr) {
            if (this.auftragdetails) {
                this.auftragdetails.starteAuftragWennErforderlich('onBehaelterNrLostFocus');
            }
        }

        setTimeout(() => {
            this.pruefeBehaelterNr(b);

            if (b.behaelterNr) {
                if (b.changed) {
                    this.systemService.getAktuelleGpsPosition('Behälter manuell').then(position => b.position = position);
                }
            } else {
                b.position = null;
            }

            b.changed = false;
        }, 150);
    }

    onBehaelterNrChanged(vo: AuftragspositionVO, b: BehaelterbewegungVO) {
        // hier nichts machen. wird mit jedem tastendruck ausgelöst
        b.changed = true;
    }

    async pruefeBehaelterNr(b: BehaelterbewegungVO): Promise<void> {
        if (StringHelper.isBlank(b.behaelterNr)) {
            b.manuelleNummer = null;
            return;
        }

        const uvvBehaelter = this.uvvService.getBehaelterByBehaelterNr(b.behaelterNr, b.isScan);

        const fahrer = App.current.fahrer.getValue();

        this.log.debug('pruefeBehaelterNr', {
            uvvBehaelter,
            fahrer
        });

        if (uvvBehaelter) {
            if (App.current.uvvBerechtigt.getValue()) {
                if (UvvHelper.istFaelligFuerPruefung(uvvBehaelter)) {
                    this.uvvPruefungModal = await this.modalController.create({
                        component: UvvPruefungModalPage,
                        componentProps: {
                            'gegenstand': uvvBehaelter
                        }
                    });

                    this.log.debug('uvvPruefungModal wird angezeigt...');
                    await this.uvvPruefungModal.present();

                    await this.uvvPruefungModal.onDidDismiss();
                    this.log.debug('uvvPruefungModal wurde geschlossen');
                    this.uvvPruefungModal = null;

                    this.updateBehaelterManuelleNummer(b, uvvBehaelter);

                    return;
                }
            }

            // Für nicht UVV-Berechtigte Fahrer muss geprüft werden ob die UVV abgelaufen ist
            if (UvvHelper.istUvvUeberfaellig(uvvBehaelter)) {
                const abgelaufeneUvvPruefung = AppConfig.current.einstellungen.BehaelterNummerAbgelaufeneUvvPruefung;

                if (abgelaufeneUvvPruefung === 'fehler' || abgelaufeneUvvPruefung === 'warnung') {
                    const ablaufDatum = moment(uvvBehaelter.naechstePruefung);

                    await UiHelper.showAlert('Die UVV-Prüfung für den Behälter ' + b.behaelterNr + ' ist abgelaufen: ' + ablaufDatum.format('DD.MM.YYYY'), null, true);

                    if (abgelaufeneUvvPruefung === 'fehler' && b.aktion == 'stellen') {
                        // Behälter-Nummer darf nicht übernommen werden
                        b.behaelterNr = '';
                        b.manuelleNummer = '';
                    }
                }
            }

            if (AppConfig.current.einstellungen.BehaelterNummerBeschaedigungPruefung) {
                this.log.debug('BehaelterNummerBeschaedigungPruefung', uvvBehaelter);

                if (uvvBehaelter.zustand === 'beschaedigt') {
                    if (b.aktion == 'stellen') {
                        await UiHelper.showAlert('Behälter ist beschädigt und darf nicht verwendet werden', 'Beschädigung', true);
                    } else {
                        await UiHelper.showAlert('Behälter ist beschädigt', 'Achtung', true);
                    }

                    if (AppConfig.current.einstellungen.BehaelterNummerBeschaedigungPruefung === 'fehler' && b.aktion == 'stellen') {
                        // Behälter-Nummer darf nicht übernommen werden
                        b.behaelterNr = '';
                        b.manuelleNummer = '';
                    }
                }
            }
        }

        if (!uvvBehaelter) {
            const behaelterPruefung = App.current.behaelterNummerPruefung.getValue();

            if (behaelterPruefung === 'fehler') {
                UiHelper.showAlert('Ungültige Behälter-Nummer: ' + b.behaelterNr);
            } else if (behaelterPruefung === 'warnung') {
                UiHelper.showAlert('Behälter-Nummer nicht gefunden: ' + b.behaelterNr);
            }
        }

        this.updateBehaelterManuelleNummer(b, uvvBehaelter);
    }

    private updateBehaelterManuelleNummer(b: BehaelterbewegungVO, uvvBehaelter: UvvPruefGegenstand) {
        if (uvvBehaelter) {
            b.behaelterNrUngueltig = false;
            b.manuelleNummer = '';

            if (b.behaelterNr) {
                if (uvvBehaelter.manuelleNummer) {
                    b.manuelleNummer += uvvBehaelter.manuelleNummer;
                }

                if (uvvBehaelter.bezeichnung) {
                    b.manuelleNummer += ' - ' + uvvBehaelter.bezeichnung;
                }

                if (b.manuelleNummer == b.behaelterNr) {
                    b.manuelleNummer = '';
                }
            }
        } else {
            b.behaelterNrUngueltig = true;
            b.manuelleNummer = null;
        }
    }

    istPlusMinusButtonsAnzeigen(vo: AuftragspositionVO) {
        if (this.auftragdetails.isLieferungAendernGesperrt) {
            return false;
        }

        if (!this.plusMinusButtonAnzeigen) {
            return false;
        }

        // if (!this.plusMinusButtons) {
        //     // Die Buttons sollen generell nicht angezeigt werden
        //     return false;
        // }

        const mengeOption = this.getMengeOption(vo);

        if (mengeOption === MengeOptionTyp.NichtAenderbar) {
            return false;
        }

        // TODO hier eventuell einbauen, das minus Button nicht mehr angezeigt wird bei 1

        // let einheit = vo.auftragsposition.Einheit;

        // if (einheit) {
        //     einheit = einheit.toLowerCase();

        //     if (einheit == "t" || einheit == 'to' || einheit == 'tonnen') {
        //         // Tonnen müssen genau erfasst werden
        //         return false;
        //     }
        // }

        return true;
    }

    erhoeheMenge(p: AuftragspositionVO) {
        this.log.debug('erhoeheMenge');

        if (this.auftragdetails.isLieferungAendernGesperrt) {
            this.auftragdetails.showAuftragSchreibgeschuetzt();
            return;
        }

        const mengeOption = this.getMengeOption(p);

        if (p.auftragsposition) {
            p.auftragsposition.Menge = Utils.parseFloat(p.auftragsposition.Menge);
        }

        switch (mengeOption) {
            case MengeOptionTyp.AenderbarGroesserNull:
            case MengeOptionTyp.Aenderbar:
                if (!p.auftragsposition.Menge) {
                    p.auftragsposition.Menge = 0;
                }

                p.auftragsposition.Menge = Math.round(p.auftragsposition.Menge + 1);

                break;
            case MengeOptionTyp.StandardOderNull:
                const standardMenge = this.auftragService.getStandardMenge(p);

                if (p.auftragsposition.Menge == standardMenge) {
                    UiHelper.showAlert('Menge kann nicht erhöht werden. Bitte zusätzliche Auftragsposition hinzufügen.');
                } else {
                    p.auftragsposition.Menge = standardMenge;
                }
                break;
            case MengeOptionTyp.NichtAenderbar:
                return;
        }

        this.onMengeGeaendert(p);
    }

    reduziereMenge(p: AuftragspositionVO) {
        this.log.debug('reduziereMenge');

        if (this.auftragdetails.isLieferungAendernGesperrt) {
            this.auftragdetails.showAuftragSchreibgeschuetzt();
            return;
        }

        const mengeOption = this.getMengeOption(p);

        if (p.auftragsposition) {
            p.auftragsposition.Menge = Utils.parseFloat(p.auftragsposition.Menge);
        }

        switch (mengeOption) {
            case MengeOptionTyp.Aenderbar:
                if (p.auftragsposition.Menge >= 1) {
                    p.auftragsposition.Menge = Math.round(p.auftragsposition.Menge - 1);
                } else {
                    p.auftragsposition.Menge = 0;
                }

                break;
            case MengeOptionTyp.AenderbarGroesserNull:
                if (p.auftragsposition.Menge >= 2) {
                    p.auftragsposition.Menge = Math.round(p.auftragsposition.Menge - 1);
                } else {
                    // nicht erlauben
                }

                break;
            case MengeOptionTyp.StandardOderNull:
                p.auftragsposition.Menge = 0;
                break;
            case MengeOptionTyp.NichtAenderbar:
                return;
        }

        this.onMengeGeaendert(p);
    }

    getMengeOption(p: AuftragspositionVO): MengeOptionTyp {
        let option: MengeOptionTyp;

        if (p.MengeOption === null || typeof (p.MengeOption) === 'undefined') {
            const artikel = this.stammdatenService.getAlleArtikel().getValue().find(x => x.artikelKey as any == p.auftragsposition.ArtikelKey);

            if (artikel) {
                option = artikel.MengeOption;
            }
        } else {
            option = p.MengeOption;
        }

        if (!option) {
            option = MengeOptionTyp.Aenderbar;
        }

        return option;
    }

    /**
     * Die Wiegungen haben sich geändert.
     */
    onWiegungChanged(vo: AuftragspositionVO) {
        if (vo.auftragsposition.Wiegungen2) {
            if (vo.auftragsposition.NettoGewicht) {
                // Beide Wiegungen erfolgreich
                this.berechneMengeAusNettoGewicht(vo);
            }
        }
    }

    berechneMengeAusNettoGewicht(vo: AuftragspositionVO) {
        const nettoGewicht = vo.auftragsposition.NettoGewicht;

        vo.auftragsposition.GewichtInKg = nettoGewicht;

        let einheit = vo.auftragsposition.Einheit;

        if (einheit) {
            einheit = einheit.trim().toLowerCase();

            if (einheit == 'kg') {
                vo.auftragsposition.Menge = nettoGewicht;
            } else if (einheit == 'to' || einheit == 't') {
                vo.auftragsposition.Menge = nettoGewicht / 1000;
            }
        }

        this.cdr.detectChanges();
    }

    onWiegungenZuordnen(vo: AuftragspositionVO) {
        const wiegedatenListe = this.waageService.wiegedaten.getValue();

        if (wiegedatenListe.length == 0) {
            UiHelper.showAlert('Keine Wiegungen vorhanden');
            return;
        }

        if (!vo.auftragsposition.Wiegungen) {
            vo.auftragsposition.Wiegungen = [];
        }

        if (wiegedatenListe.length == 1) {
            vo.auftragsposition.Wiegungen.push(wiegedatenListe[0]);
            wiegedatenListe.splice(0, 1);
            this.waageService.setzeWiegedatenListe(wiegedatenListe);

            this.berechneMengeAusWiegungen(vo);
        } else {
            this.wiegungAuswahlAuftragsposition = vo;
            this.wiegedatenListe = wiegedatenListe;
            this.cdr.detectChanges();

            setTimeout(() => {
                this.wiegungenSelect.open();
            });
        }
    }

    onWiegungSelected(e: Event) {
        this.log.debug('onWiegungSelected', e);

        try {
            const event = e as CustomEvent;

            const vo = this.wiegungAuswahlAuftragsposition;

            if (!vo.auftragsposition.Wiegungen) {
                vo.auftragsposition.Wiegungen = [];
            }

            const wiegedatenListe = this.waageService.wiegedaten.getValue();
            const ausgewaehlteWiegungen = event.detail.value as Wiegedaten[];

            if (ausgewaehlteWiegungen) {
                for (const w of ausgewaehlteWiegungen) {
                    const idx = wiegedatenListe.indexOf(w);

                    if (idx >= 0) {
                        // Entferne Eintrag aus den verfügbaren Wiegungen
                        wiegedatenListe.splice(idx, 1);

                        // Und zur Auftragsposition hinzufügen
                        vo.auftragsposition.Wiegungen.push(w);
                    }
                }

                this.waageService.setzeWiegedatenListe(wiegedatenListe);

                this.berechneMengeAusWiegungen(vo);
            }
        } catch (err) {
            UiHelper.showError(err);
        }

        this.wiegungAuswahlAuftragsposition = null;
    }

    onWiegungEntfernen(vo: AuftragspositionVO, wiegung: Wiegedaten) {
        if (!vo.auftragsposition.Wiegungen) {
            vo.auftragsposition.Wiegungen = [];
        }

        const idx = vo.auftragsposition.Wiegungen.indexOf(wiegung);

        if (idx >= 0) {
            vo.auftragsposition.Wiegungen.splice(0, 1);

            const wiegedatenListe = this.waageService.wiegedaten.getValue();
            wiegedatenListe.push(wiegung);
            this.waageService.setzeWiegedatenListe(wiegedatenListe);

            this.berechneMengeAusWiegungen(vo);
        }
    }

    berechneMengeAusWiegungen(vo: AuftragspositionVO) {
        if (!vo.auftragsposition.Wiegungen) {
            vo.auftragsposition.Wiegungen = [];
        }

        let summeNetto = 0;

        for (const w of vo.auftragsposition.Wiegungen) {
            if (w.Netto) {
                summeNetto += Utils.parseFloat(w.Netto);
            }
        }

        vo.auftragsposition.GewichtInKg = summeNetto;

        let einheit = vo.auftragsposition.Einheit;

        if (einheit) {
            einheit = einheit.trim().toLowerCase();

            if (einheit == 'kg') {
                vo.auftragsposition.Menge = summeNetto;
            } else if (einheit == 'to' || einheit == 't') {
                vo.auftragsposition.Menge = summeNetto / 1000;
            }
        }

        this.cdr.detectChanges();
    }

    async onSuchen() {
        this.log.debug('onSuchen');
        this.searchbarSichtbar.next(true);

        this.cdr.detectChanges();

        await delay(200);

        try {
            this.searchbar.setFocus();
        } catch (err) {
            this.log.error('setTimeout onSuchen: ' + Utils.getErrorMessage(err), err);
        }
    }

    onSearchChanged() {
        this.log.debug('onSearchChanged');

        this.filterSichtbareAuftragspositionen();

        // this.detectChanges('onSearchChanged');
    }

    filterSichtbareAuftragspositionen() {
        this.log.debug('filterSichtbareAuftragspositionen');

        let liste: AuftragspositionVO[];

        if (this.suchtext && this.suchtext.trim()) {
            liste = [];

            const parts: string[] = this.suchtext.toLowerCase().trim().split(' ');

            for (const vo of this.allePositionen) {
                let isMatch = true;

                for (const str of parts) {
                    if (!vo.suchtext.includes(str)) {
                        isMatch = false;
                        break;
                    }
                }

                if (isMatch) {
                    liste.push(vo);
                }
            }
        } else {
            liste = this.allePositionen;
        }

        this.anzahlPositionAusgeblendet = 0;
        this.anzahlLeerePositionenWerdenAngezeigt = 0;

        if (this.isLeerePositionenAusblenden) {
            // Leere Positionen (Menge = 0) sollen nicht angezeigt werden, außer der Benutzer will sie explizit anzeigen
            let alteListe = [...liste];
            let neueListe = liste.filter(p => p.auftragsposition.MengeSoll || p.auftragsposition.Menge || p.auftragsposition.IstNeu);

            if (this.isLeerePositionAnzeigen) {
                this.anzahlLeerePositionenWerdenAngezeigt = alteListe.length - neueListe.length;
            } else {
                this.anzahlPositionAusgeblendet = alteListe.length - neueListe.length;
                liste = neueListe;
            }
        }

        this.log.debug('filterSichtbareAuftragspositionen. anzahl=' + liste.length);

        this.sichtbarePositionen = liste;
    }

    onUpperCaseEigenschaftChange(eigenschaft: Eigenschaft) {
        if (eigenschaft && typeof (eigenschaft.Wert) === 'string') {
            eigenschaft.Wert = eigenschaft.Wert.toUpperCase();
        }
    }

    getSollMengeText(vo: AuftragspositionVO) {
        if (this.sollMengeAnzeigen && vo.auftragsposition) {
            if (vo.auftragsposition.MengeSoll) {
                return '(SOLL: ' + vo.auftragsposition.MengeSoll + ')';
            }
        }

        return '';
    }

    onPositionDrucken(vo: AuftragspositionVO) {
        this.log.debug('onPositionDrucken', vo);

        this.auftragdetails.druckenAuftragsposition(vo.auftragsposition, false);
    }

    onSeriennummernBearbeiten(vo: AuftragspositionVO) {
        this.log.debug('onSeriennummernBearbeiten', vo);

        this.auftragdetails.seriennummernBearbeiten(vo.auftragsposition);
    }

    onAllePositionenAnzeigen() {
        this.log.info('onAllePositionenAnzeigen');

        this.isLeerePositionAnzeigen = true;
        this.filterSichtbareAuftragspositionen();

        this.cdr.detectChanges();
    }

    onLeerePositionenAusblenden() {
        this.log.info('onLeerePositionenAusblenden');

        this.isLeerePositionAnzeigen = false;
        this.filterSichtbareAuftragspositionen();

        this.cdr.detectChanges();
    }
}

interface AuftragspositionVO {
    auftragsposition: AuftragspositionEx;
    artikel: Artikel;
    vertragsposition?: Vertragsposition;
    behaelter: BehaelterbewegungVO[];
    mengeUngueltig: boolean;
    gewichtUngueltig: boolean;
    MengeOption: MengeOptionTyp;
    StandardMenge: number;
    wiegungZuordnenAnzeigen: boolean;
    LaderaumAuswahlAktiv: boolean;
    suchtext: string;
    wiegenAktiv: boolean;
    isArtikelAenderbar: boolean;
    seriennummernText?: string;
    mengeVariante: 'aenderbar' | 'standard-oder-null' | 'nicht-aenderbar';
}

interface BehaelterbewegungVO {
    name: string;
    aktion: 'stellen' | 'entleeren' | 'abziehen' | 'umsetzen' | 'direktbeladung';
    behaelterNr: string;
    manuelleNummer?: string;
    behaelterNrUngueltig?: boolean;
    isScan?: boolean;

    // Zum anzeigen von "NFC aktiv"
    aktiv?: boolean;

    // Soll der Abstellort ausgewählt werden?
    abstellortAktiv?: boolean;

    // Nur temporär fpr ionic-selectable
    abstellort?: AdresseEntity;

    // Adresse des Abstellorts
    abstellortKey?: string;
    zustand?: string; // voll / leer

    position?: GpsPosition;

    changed?: boolean;

    gescannteBehaelterNr?: string;
}

interface LaderaumVO {
    key: string;
    nummer: number;
    bezeichnung: string;
}

