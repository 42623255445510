import { Component, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Logger } from 'src/app/api/helper/app-error-logger';

@Component({
    selector: 'app-checkmark',
    templateUrl: './checkmark.component.html',
    styleUrls: ['./checkmark.component.scss'],
})
export class CheckmarkComponent implements OnInit, OnChanges, OnDestroy {
    log = new Logger("CheckmarkComponent");

    constructor(
        private cdr: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

}
