<ion-app>
  <div class="overlay-spinner" *ngIf="loadingSpinner | async">
    <ion-spinner color="medium"></ion-spinner>
  </div>

  <!-- <div class="overlay-buttons" *ngIf="app.overlayButtonsVisible | async">
    <div class="overlay-buttons-backgrop"></div>
    <div class="button-container">
      <ion-button *ngFor="let p of app.overlayButtons | async">{{ p.Text }}</ion-button>
    </div>
  </div> -->

  <ion-split-pane contentId="main-content" when="(min-width: 9999px)">
    <ion-menu type="overlay" [swipeGesture]="false" contentId="main-content">
      <app-sidemenu></app-sidemenu>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>