<ion-header>
  <ion-toolbar class="auftrag-toolbar" #toolbar>
    <ion-buttons slot="start">
      <ion-button appClick (clicked)="onClose()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title><span id="page-title-span">{{ 'Artikel auswählen' | translate }}</span></ion-title>
  </ion-toolbar>
</ion-header>


<ion-content #content>
  <!-- Artikelgruppe auswählen -->
  <ng-container *ngIf="ansichtArtikelgruppeAuswaehlen">
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button appClick (clicked)="onSuchen()">
        <ion-icon name="search"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <ion-list class="artikel-list" lines="full">
      <ion-item *ngFor="let vo of artikelgruppenListe" appClick (clicked)="onArtikelgruppeSelected(vo)" [detail]="true" [style]="domSanitizationService.bypassSecurityTrustStyle(vo.style)">
        <ion-avatar slot="start" *ngIf="vo.artikelgruppe">
          <img [src]="getIconSrc(vo)">
        </ion-avatar>
        <ion-label *ngIf="vo.artikelgruppe">
          <h2>{{ vo.artikelgruppe.Name }}</h2>
          <p class="beschreibung" *ngIf="vo.artikelgruppe.Beschreibung">{{ vo.artikelgruppe.Beschreibung }}</p>
        </ion-label>
        <ion-label *ngIf="vo.vertrag">
          <h2>{{ vo.vertrag.Bezeichnung }}</h2>
        </ion-label>
      </ion-item>
    </ion-list>
  </ng-container>

  <!-- Artikel auswählen -->
  <ng-container *ngIf="ansichtArtikelAuswaehlen">
    <ion-list class="artikel-list">
      <ion-item appClick (clicked)="onClose()">
        <ion-icon name="arrow-back"></ion-icon>
        <ion-label>{{ 'Zurück' | translate }}</ion-label>
      </ion-item>
      <ion-item *ngFor="let a of artikelListe" appClick (clicked)="onArtikelSelected(a)">
        <ion-label>{{ a.text }}</ion-label>
      </ion-item>
    </ion-list>
  </ng-container>

  <!-- Vertragsposition auswählen -->
  <ng-container *ngIf="ansichtVertragspositionAuswaehlen">
    <ion-list class="artikel-list">
      <ion-item appClick (clicked)="onClose()">
        <ion-icon name="arrow-back"></ion-icon>
        <ion-label>{{ 'Zurück' | translate }}</ion-label>
      </ion-item>
      <ion-item *ngFor="let pos of vertragspositionListe" appClick (clicked)="onVertragspositionSelected(ausgewaehlteArtikelgruppe.vertrag, pos)">
        <ion-label>{{ pos.ArtikelKey }}: {{ pos.Bezeichnung }} ({{ pos.Menge }} {{ pos.Einheit }})</ion-label>
      </ion-item>
    </ion-list>
  </ng-container>

  <!-- Suchen -->
  <ng-container *ngIf="ansichtSuchen">
    <ion-searchbar [(ngModel)]="suchtext" (ionChange)="onSearchChanged($event)" (ionCancel)="onSearchChanged($event)" placeholder="{{ 'Suchen' | translate }}" #searchbar></ion-searchbar>

    <ion-virtual-scroll *ngIf="sucheSichtbareItems.length" [items]="sucheSichtbareItems" [headerFn]="myHeaderFn" approxItemHeight="88px" #virtualScroll>
      <ion-item-divider *virtualHeader="let header">
        <ion-label>{{ header.title }} <span class="subtitle" *ngIf="header.subtitle">{{ header.subtitle }}</span></ion-label>
      </ion-item-divider>

      <ion-item *virtualItem="let item" class='artikelitem' appClick (clicked)="onSuchItemSelected(item)">
        <ion-label>
          <h2>{{ item.text }}</h2>
        </ion-label>
      </ion-item>
    </ion-virtual-scroll>
  </ng-container>
</ion-content>