<ion-content *ngIf="unterschrift">
  <div class="upper-content"> {{ titel | translate }} </div>
  <app-unterschrift (signaturVorhanden)="onSignaturVorhanden(unterschriftComponent)" #unterschriftComponent></app-unterschrift>
  <div class="lower-content">{{ unterschrift.Name }}, {{ datumHeute }}</div>
</ion-content>

<canvas #hiddenCanvans id="hiddenCanvas"></canvas>

<img *ngIf="logo" class="unterschrift-logo-img" src="{{'data:image/jpg;base64,' + logo}}" />

<div class="buttons">
  <ion-button [color]="speichernButtonColor" [disabled]="speichernDisabled" appClick (clicked)="onSpeichern($event)">
    <ion-icon name="checkmark"></ion-icon>
  </ion-button>
  <ion-button color="warning" appClick (clicked)="onReset($event)">
    <ion-icon name="refresh"></ion-icon>
  </ion-button>
  <ion-button color="light" appClick (clicked)="onAbbrechen($event)">
    <ion-icon name="close"></ion-icon>
  </ion-button>
</div>