import { AuftragListItem } from '../model/model';
import { Auftrag } from '../model/swagger-model';

export interface IAuftragService {
    aktiverAuftrag: Auftrag;
    alleAuftraege: AuftragListItem[];
}

export class AppService {
    static auftrag: IAuftragService;
}
