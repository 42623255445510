import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AuftragEx, AuftragspositionEx } from 'src/app/api/model/model';
import { AuftragsKomponente, Auftragsposition, MengeOptionTyp } from 'src/app/api/model/swagger-model';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { AuftragService } from 'src/app/api/auftrag.service';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { BehaviorSubject, Subscription } from 'rxjs';
import { App } from 'src/app/api/helper/app';
import { Auftragdetails } from 'src/app/auftragsliste/auftragdetails/auftragdetails.helper';
import { IAuftragdetails } from 'src/app/auftragsliste/auftragdetails/iauftragdetails';
import { Utils } from 'src/app/api/helper/utils';
import { AppConfig } from 'src/app/api/helper/app.config';

@Component({
    selector: 'app-lieferung',
    templateUrl: './lieferung.component.html',
    styleUrls: ['./lieferung.component.scss'],
})
export class LieferungComponent implements OnInit, OnChanges, OnDestroy {
    log = new Logger("LieferungComponent");

    @Input() auftragdetails: IAuftragdetails;
    @Input() auftrag: AuftragEx;
    @Input() title: string;
    @Input() canNavigate: boolean;
    @Input() canCollapse: boolean;
    @Input() collapsed: boolean;
    @Input() ansicht: string;

    @Input() formularkonfiguration: AuftragsKomponente;
    @Input() highlightPosition: number;

    posNrSichtbar = false;
    mengeSichtbar = true;
    einheitSichtbar = true;
    bezeichnungSichtbar = true;
    bruttoSichtbar = true;
    plusMinusButtons = false;
    gewichtSichtbar = false;
    epImmerAnzeigen = false;

    customAnsicht = '';

    summeAnzahlSichtbar = false;
    summeAnzahl = '';

    summeColspan = 4;
    bezeichnungColspan = 4;
    summeBrutto: number = null;
    summeNetto: number = null;

    mwstSummen: MwstSumme[] = [];
    positionen: PositionVO[] = [];

    subscriptions: Subscription[] = [];

    constructor(
        private cdr: ChangeDetectorRef,
        private stammdatenService: StammdatenService,
        private auftragService: AuftragService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.log.debug('changes: highlightPosition=' + this.highlightPosition);

        for (const vo of this.positionen) {
            const wert = vo.position.PosNr == this.highlightPosition;
            this.log.debug('changes: wert=' + wert);
            vo.istHighlighted.next(wert);
        }
    }

    ngOnInit() {
        this.log.debug('ngOnInit: highlightPosition=' + this.highlightPosition);

        if (!this.formularkonfiguration) {
            this.log.warn('keine Formularkonfiguration !');

            this.formularkonfiguration = {
                einstellungen: [
                    { key: 'Titel', wert: 'Lieferung' },
                    { key: 'Menge', wert: 'ja' },
                    { key: 'Einheit', wert: 'ja' },
                    { key: 'Bezeichnung', wert: 'ja' },
                    { key: 'Brutto', wert: 'ja' },
                    { key: 'PlusMinusButtons', wert: 'nein' },
                ]
            };
        } else {
            this.log.debug('Formularkonfiguration', this.formularkonfiguration);
        }


        this.epImmerAnzeigen = Utils.isTrue(AppConfig.current.einstellungen.EPImmerAnzeigen);

        if (!this.formularkonfiguration.einstellungen) {
            this.formularkonfiguration.einstellungen = [];
        }

        for (const c of this.formularkonfiguration.einstellungen) {
            if (c.key === 'Menge') {
                this.mengeSichtbar = c.wert == 'ja';
            } else if (c.key === 'Einheit') {
                this.einheitSichtbar = c.wert == 'ja';
            } else if (c.key === 'Bezeichnung') {
                this.bezeichnungSichtbar = c.wert == 'ja';
            } else if (c.key === 'Brutto') {
                this.bruttoSichtbar = c.wert == 'ja';
            } else if (c.key === 'PlusMinusButtons') {
                this.plusMinusButtons = c.wert == 'ja';
            } else if (c.key === 'PosNr') {
                this.posNrSichtbar = c.wert == 'ja';
            } else if (c.key === 'GewichtInKg') {
                this.gewichtSichtbar = c.wert == 'ja';
            } else if (c.key === 'SummeMenge') {
                this.summeAnzahlSichtbar = c.wert == 'ja';
            } else if (c.key === 'Custom') {
                this.customAnsicht = c.wert;
            }
        }

        if (this.auftragdetails) {
            this.subscriptions.push(this.auftragdetails.detectChanges.subscribe(() => this.cdr.detectChanges()));

            this.subscriptions.push(this.auftragdetails.auftragspositionenGeaendert.subscribe(() => this.refresh()));
        }

        this.refresh();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    aktualisiereSumme(info: string) {
        this.log.debug('aktualisiereSumme: ' + info);

        this.mwstSummen = [];

        if (this.bruttoSichtbar) {
            let summeNetto = 0.0;
            let summeBrutto = 0.0;
            let mwstGueltig = true;
            let summeGueltig = true;

            for (const p of this.auftrag.Positionen) {
                if (p.Menge == 0) {
                    // Position ignorieren
                    continue;
                }

                if (p.NettoBetrag != null && p.Brutto != null && typeof (p.EinzelVK) === 'number') {
                    summeNetto += p.NettoBetrag;
                    summeBrutto += p.Brutto;
                } else {
                    summeBrutto = null;
                    summeGueltig = false;
                    mwstGueltig = false;
                    break;
                }

                if (typeof (p.MwstSatz) === 'number' && typeof (p.MwstBetrag) === 'number') {
                    let mwstSumme = this.mwstSummen.find(x => x.mwstSatz == p.MwstSatz);

                    if (!mwstSumme) {
                        mwstSumme = { mwstSatz: p.MwstSatz, betrag: 0 };
                        this.mwstSummen.push(mwstSumme);
                    }

                    mwstSumme.betrag += p.MwstBetrag;
                } else {
                    mwstGueltig = false;
                }
            }

            if (summeGueltig) {
                this.summeBrutto = summeBrutto;
                this.summeNetto = summeNetto;
            }
        }

        this.summeAnzahl = '';

        if (this.summeAnzahlSichtbar) {
            const summenMap: any = {};

            for (const p of this.auftrag.Positionen) {
                if (!p.Menge) {
                    // Position ignorieren
                    continue;
                }

                let einheit = p.Einheit;

                if (!einheit) {
                    einheit = 'St';
                }

                let anzahl = summenMap[einheit];

                if (!anzahl) {
                    anzahl = 0;
                }

                summenMap[einheit] = anzahl + p.Menge;
            }

            for (const key in summenMap) {
                if (Object.prototype.hasOwnProperty.call(summenMap, key)) {
                    const anzahl = summenMap[key];

                    if (this.summeAnzahl) {
                        this.summeAnzahl += ', ';
                    }

                    this.summeAnzahl += anzahl + ' ' + key;
                }
            }
        }
    }

    berechneColSpans() {
        let anzahl = 0;

        if (this.mengeSichtbar) {
            anzahl++;
        }

        if (this.einheitSichtbar) {
            anzahl++;
        }

        if (this.gewichtSichtbar) {
            anzahl++;
        }

        if (this.bruttoSichtbar) {
            anzahl++;
        }

        // if (true) { // TODO: Plus / Minus - Buttons anzeigen
        //     anzahl++;
        // }

        this.bezeichnungColspan = anzahl;
        this.summeColspan = anzahl - 1;
    }

    onToggle() {
        if (this.canCollapse) {
            this.collapsed = !this.collapsed;
        }
    }

    istPlusMinusButtonsAnzeigen(p: Auftragsposition) {
        if (!this.plusMinusButtons) {
            // Die Buttons sollen generell nicht angezeigt werden
            return false;
        }

        if (this.ansicht === 'klingeln') {
            return false;
        }

        const mengeOption = this.getMengeOption(p);

        if (mengeOption === MengeOptionTyp.NichtAenderbar) {
            return false;
        }

        if (this.auftragdetails.isLieferungAendernGesperrt) {
            return false;
        }

        return true;
    }

    istEinfacheAnsicht(p: Auftragsposition): boolean {
        if (!this.bruttoSichtbar && !this.plusMinusButtons) {
            return true;
        }

        return false;
    }

    istHighlighted(p: Auftragsposition): boolean {
        this.log.debug('istHighlighted: ' + p.PosNr);
        return this.highlightPosition === p.PosNr;
    }

    erhoeheMenge(p: Auftragsposition) {
        this.log.debug('erhoeheMenge');

        if (this.auftragdetails.isLieferungAendernGesperrt) {
            this.auftragdetails.showAuftragSchreibgeschuetzt();
            return;
        }

        const mengeOption = this.getMengeOption(p);

        switch (mengeOption) {
            case MengeOptionTyp.AenderbarGroesserNull:
            case MengeOptionTyp.Aenderbar:
                if (!p.Menge) {
                    p.Menge = 0;
                }

                p.Menge = Math.round(p.Menge + 1);

                break;
            case MengeOptionTyp.StandardOderNull:
                const standardMenge = this.auftragService.getStandardMenge(p);

                if (p.Menge == standardMenge) {
                    UiHelper.showAlert('Menge kann nicht erhöht werden. Bitte zusätzliche Auftragsposition hinzufügen.');
                } else {
                    p.Menge = standardMenge;
                }
                break;
            case MengeOptionTyp.NichtAenderbar:
                return;
        }

        this.onMengeGeaendert(p);
    }

    reduziereMenge(p: Auftragsposition) {
        this.log.debug('reduziereMenge');

        if (this.auftragdetails.isLieferungAendernGesperrt) {
            this.auftragdetails.showAuftragSchreibgeschuetzt();
            return;
        }

        const mengeOption = this.getMengeOption(p);

        switch (mengeOption) {
            case MengeOptionTyp.Aenderbar:
                if (p.Menge >= 1) {
                    p.Menge = Math.round(p.Menge - 1);
                } else {
                    p.Menge = 0;
                }

                break;
            case MengeOptionTyp.AenderbarGroesserNull:
                if (p.Menge >= 2) {
                    p.Menge = Math.round(p.Menge - 1);
                } else {
                    // nicht erlauben
                }
        
                break;
            case MengeOptionTyp.StandardOderNull:
                p.Menge = 0;
                break;
            case MengeOptionTyp.NichtAenderbar:
                return;
        }

        this.onMengeGeaendert(p);
    }

    onMengeGeaendert(p: Auftragsposition) {
        p.MengeIst = p.Menge;

        this.auftragService.aktualisierePreise(this.auftrag, 'onMengeGeaendert');
        this.aktualisiereSumme('onMengeGeaendert: ' + p.PosNr);

        if (this.auftragdetails) {
            this.auftragdetails.starteAuftragWennErforderlich('onMengeGeaendert');
        }
    }

    getMengeOption(p: Auftragsposition): MengeOptionTyp {
        let option: MengeOptionTyp;

        if (p.MengeOption === null || typeof (p.MengeOption) === 'undefined') {
            const artikel = this.stammdatenService.getAlleArtikel().getValue().find(x => x.artikelKey as any == p.ArtikelKey);

            if (artikel) {
                option = artikel.MengeOption;
            }
        } else {
            option = p.MengeOption;
        }

        if (!option) {
            option = MengeOptionTyp.Aenderbar;
        }

        return option;
    }

    refresh() {
        this.log.debug('refresh');

        if (this.auftrag) {
            this.auftragService.aktualisierePreise(this.auftrag, 'refresh');
        }

        this.aktualisiereSumme('refresh');

        this.berechneColSpans();

        this.positionen = [];

        const isChargenverfolgungNVE = Utils.isTrue(AppConfig.current.einstellungen.KommissionierungChargenverfolgungNVE);
        
        for (const pos of this.auftrag.Positionen) {
            const vo: PositionVO = {
                position: pos,
                istHighlighted: new BehaviorSubject<boolean>(false),
                checkboxDisabled: false
            }

            if (isChargenverfolgungNVE && pos.Chargenpflicht) {
                // TODO: Für nicht chargenpflichtige Artikel heir nicht auf disabled setzen
                vo.checkboxDisabled = true;
            }

            this.positionen.push(vo);
        }
    }

    onFertigChanged(vo: PositionVO, e: any) {
        this.log.debug('onFertigChanged', e);

        // if (Utils.isTrue(AppConfig.current.einstellungen.KommissionierungChargenverfolgungNVE)) {
        //     UiHelper.showErrorLight('Artikel muss gescannt werden');
        //     return;
        // }

        // let checked = e.detail.checked;

        // vo.position.Fertig = checked;

        this.auftragdetails.onAuftragspositionFertigChanged(vo.position);
    }
}

interface PositionVO {
    position: AuftragspositionEx;
    istHighlighted: BehaviorSubject<boolean>;
    checkboxDisabled: boolean;
}

interface MwstSumme {
    mwstSatz: number;
    betrag: number;
}
