import * as Sentry from 'sentry-cordova';
import { environment } from 'src/environments/environment';
import { AppFileLogger } from './app-file-logger';
import { AppConfig } from './app.config';
import { IApp } from './iapp';

export enum LogLevel {
    DEV = 5,
    DEBUG = 10,
    INFO = 20,
    WARN = 30,
    ERROR = 40,
    FATAL = 50
}

export class Logger {

    static app: IApp;

    static sentryCount = 0;

    static globalLogLevel = environment.logLevel;

    static fileLogger: AppFileLogger;

    static fileLoggerAktiv = true;

    customLogLevel = LogLevel.ERROR;

    constructor(public name: string, logLevel: LogLevel = null) {
        if (logLevel) {
            this.customLogLevel = logLevel;
        }
    }

    dev(text: string, obj: any = null) {
        this._log(LogLevel.DEV, text, obj);
    }

    debug(text: string, obj: any = null) {
        this._log(LogLevel.DEBUG, text, obj);
    }

    info(text: string, obj: any = null) {
        this._log(LogLevel.INFO, text, obj);
    }

    warn(text: string, obj: any = null) {
        this._log(LogLevel.WARN, text, obj);
    }

    error(text: string, obj: any = null) {
        this._log(LogLevel.ERROR, text, obj);
    }

    fatal(text: string, obj: any = null) {
        this._log(LogLevel.FATAL, text, obj);
    }

    private _log(level: LogLevel, text: string, obj: any) {
        if (level < Logger.globalLogLevel && level < this.customLogLevel) {
            return;
        }

        const strLevel: string = LogLevel[level];

        // // TODO: Padding-Funktion verwenden
        // if(strLevel.length === 4) {
        //     strLevel += ' ';
        // }

        const date = this.getFormattedTime(new Date());
        let color = 'black';

        let logToSentry = false;

        if (level === LogLevel.DEV) {
            color = '#A9A9A9';
        } else if (level === LogLevel.DEBUG) {
            color = '#A9A9A9';
        } else if (level === LogLevel.INFO) {
            color = '#1E90FF';
        } else if (level === LogLevel.WARN) {
            color = '#FF8C00';
        } else if (level === LogLevel.ERROR) {
            color = 'red';
            logToSentry = true;
        } else if (level === LogLevel.FATAL) {
            color = '#B22222';
            logToSentry = true;
        }

        const cssColor = 'color: ' + color + ';';

        if (typeof (obj) !== 'undefined' && obj != null) {
            console.log('%c' + strLevel + ' ' + date + ': ' + this.name + ': ' + text, cssColor, obj);
        } else {
            console.log('%c' + strLevel + ' ' + date + ': ' + this.name + ': ' + text, cssColor);
        }

        if (obj && level >= LogLevel.ERROR) {
            console.log('ERROR OBJ: ' + JSON.stringify(obj));
        }

        if (Logger.fileLogger && Logger.fileLoggerAktiv) {
            try {
                Logger.fileLogger.log(strLevel + ' ' + this.name + ': ' + text);
            } catch (err) {
                console.log('%c' + strLevel + ' ' + date + ': ' + this.name + ': FEHLER FILE LOGGER: ' + err, cssColor);
            }
        }

        if (logToSentry) {
            if (text == 'cordova_not_available') {
                // ignore
                return;
            }

            Logger.sentryCount++;

            if (Logger.sentryCount < 5) {
                try {
                    Sentry.withScope((scope) => {
                        scope.setExtra('mandant', AppConfig.current.mandant);
                        scope.setExtra('geraeteNummer', AppConfig.current.geraeteNummer);

                        if (Logger.app) {
                            scope.setExtra('appVersionNumber', Logger.app.getVersionNumber());
                            scope.setExtra('fahrzeugKey', Logger.app.getFahrzeugKey());
                            scope.setExtra('personalnummer', Logger.app.getPersonalnummer());
                        }

                        scope.setExtra('obj', obj);
                        Sentry.captureMessage('%c' + strLevel + ' ' + date + ': ' + this.name + ': ' + text);
                    });

                } catch (ex) {
                    // ignore
                    console.log('error sending to sentry.io', ex);
                }
            }
        }
    }

    getFormattedTime(date: Date) {
        const d = date || new Date();
        const z = n => ('0' + n).slice(-2);
        const zz = n => ('00' + n).slice(-3);
        return `${z(d.getHours())}-${z(d.getMinutes())}-${z(d.getSeconds())}.${zz(d.getMilliseconds())}`;
    }
}
