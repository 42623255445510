<div class="adresse" *ngIf="adresse && adresse.Name1">
  <div class="adresse-row">
    <div class="adresse-name" *ngIf="nameSichtbar">{{ adresse.Name1 }}</div>
    <div class="adresse-name2" *ngIf="nameSichtbar && adresse.Name2">{{ adresse.Name2 }}</div>
    <div class="adresse-name3" *ngIf="nameSichtbar && adresse.Name3">{{ adresse.Name3 }}</div>
    <div class="adresse-strasse" *ngIf="strasseSichtbar">{{ adresse.Strasse }}</div>
    <div class="adresse-plzort"><span *ngIf="plzSichtbar">{{ adresse.PLZ }}</span>&nbsp;<span *ngIf="ortSichtbar">{{ adresse.Ort }}</span></div>
    <div class="adresse-behnr" *ngIf="behoerdlicheNummerSichtbar && adresse.BehoerdlicheNummer"><span *ngIf="behoerdlicheNummerBezeichnung">{{ behoerdlicheNummerBezeichnung }}: </span>{{ adresse.BehoerdlicheNummer }}</div>

    <ion-button fill="clear" class="button48" appClick (clicked)="onNavigation($event)" *ngIf="navigationErlaubt && buttonsAnzeigen">
      <ion-icon slot="icon-only" name="navigate"></ion-icon>
    </ion-button>
  </div>

  <div class="adresse-telefon-outer" *ngIf="telefonSichtbar && kontakte.length">
    <div class="adresse-telefon" *ngFor="let kontakt of kontakte">
      <div *ngIf="kontakt.Name" class="ansprechpartner">{{ kontakt.Name }}</div>
      <div>Tel: {{ kontakt.Telefon }}</div>
    </div>

    <ion-button fill="clear" class="button48" *ngIf="buttonsAnzeigen" appClick (clicked)="onAnrufen($event)">
      <ion-icon slot="icon-only" name="call"></ion-icon>
    </ion-button>
  </div>
</div>