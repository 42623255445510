import { BehaviorSubject, Subject } from 'rxjs';
import { AppEinstellungen } from '../model/model';
import { AppLoginResult, ArbeitszeitKonfiguration, GlasAnalyseEinstellungen, StoerzeitKonfiguration } from '../model/swagger-model';

export class AppConfig {
    static current = new AppConfig();

    static loaded = new BehaviorSubject<AppConfig>(AppConfig.current);

    static changed = new Subject<void>();

    static hostChanged = new Subject<void>();

    static readonly AppVersion = 1;

    host = 'https://app.recomobil.de';

    token = '';

    language = 'DE';

    mandant: string;

    geraeteNummer: string;

    GeraeteBezeichnung: string;
    GeraeteGruppe: string;
    UnterMandant: string;
    Anlagen?: string[];

    /**
     * Die aktuelle Anlage für die Glas-Analyse
     */
    aktuelleAnlage: string = null;

    darkMode = false;

    /**
     * Schlüssel zum ver-/entschlüsseln des Nachrichten.
     * Muss mit dem Schlüssel in ReCoLog übereinstimmen
     */
    schluessel: string;

    /**
     * Passwort zur Authentifizierung am Mandanten.
     * Wird NICHT zur verschlüsslung verwendet.
     */
    passwort: string;

    /**
     * Standard-Klingelton für neue Aufträge
     */
    klingeltonName = 'On Time';

    /**
     * Anwendung beim Beenden im Hintergrund aktiv lassen
     */
    anwendungAktivLassen = true;

    // Liste mit schon einmal verbundenen Druckern
    gekoppelteDrucker: DruckerKonfiguration[];

    // Liste mit schon einmal verbundenen Druckern
    gekoppelteWaagen: WaageKonfiguration[];

    einstellungen: AppEinstellungen = {
    };

    arbeitszeitKonfiguration?: ArbeitszeitKonfiguration;

    stoerzeitKonfiguration?: StoerzeitKonfiguration;

    glasAnalyse?: GlasAnalyseEinstellungen;

    /**
     * Berechtigung für SYSTEM_ALERT_WINDOW vorhanden
     */
    permissionSystemAlertWindow = false;

    geraetegruppen: string[] = [];

    setHost(host: string) {
        if (this.host !== host) {
            this.host = host;
            AppConfig.hostChanged.next();
        }
    }

    uebernehmeLoginResult(loginResult: AppLoginResult) {
        this.GeraeteBezeichnung = loginResult.GeraeteBezeichnung;
        this.GeraeteGruppe = loginResult.GeraeteGruppe;
        this.UnterMandant = loginResult.UnterMandant;
        this.einstellungen = loginResult.einstellungen;
        this.token = loginResult.token;
        this.arbeitszeitKonfiguration = loginResult.ArbeitszeitKonfiguration;
        this.stoerzeitKonfiguration = loginResult.StoerzeitKonfiguration;
        this.glasAnalyse = loginResult.GlasAnalyse;
        this.Anlagen = loginResult.Anlagen;

        if (loginResult.Geraetegruppen) {
            this.geraetegruppen = loginResult.Geraetegruppen;
        }
    }
}

export interface DruckerKonfiguration {
    typ: 'ZEBRA' | string | null;
    macAdresse: string;
    name: string;
    zuletztVerwendet: string;
    aktiv: boolean;
    istVorkonfiguriert: boolean;
}

export interface WaageKonfiguration {
    typ: string | null;
    macAdresse: string;
    name: string;
    zuletztVerwendet: string;
    aktiv: boolean;
    istVorkonfiguriert: boolean;
}
