import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { IonContent, NavController, ModalController, ActionSheetController, ToastController, AlertController } from '@ionic/angular';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { AuftragEx, AuftragListItem } from 'src/app/api/model/model';
import { AuftragsFormular, AuftragsKomponente, Auftragstyp } from 'src/app/api/model/swagger-model';
import { SystemService } from 'src/app/api/system.service';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { AppConfig } from 'src/app/api/helper/app.config';
import { App } from 'src/app/api/helper/app';
import { Auftragdetails } from 'src/app/auftragsliste/auftragdetails/auftragdetails.helper';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { AuftragService } from 'src/app/api/auftrag.service';
import { Utils } from 'src/app/api/helper/utils';

@Component({
    selector: 'app-klingeln',
    templateUrl: './klingeln.page.html',
    styleUrls: ['./klingeln.page.scss'],
})
export class KlingelnPage implements OnInit, OnDestroy {
    log = new Logger('KlingelnPage');

    @ViewChild('content', { read: IonContent, static: true }) content: IonContent;

    @Input() neueAuftraege: AuftragEx[] = null;
    @Input() aktion: 'klingeln' | 'beep' | 'keine' = null;
    @Input() isAndererAuftragAktiv: boolean;

    subscriptions: Subscription[] = [];

    auftrag: AuftragEx = null;

    formularKonfiguration: AuftragsFormular;

    isAnzeigenErlaubt = false;
    isHofauftrag = false;
    isTransportauftrag = false;
    klingelnAktiv = false;
    klingelnAbbrechen = false;
    isVisible = false;
    isButtonsVorhanden = false;

    auftragdetails: Auftragdetails = null;
    buttonsKomponente: AuftragsKomponente = null;

    constructor(
        private route: ActivatedRoute,
        private systemService: SystemService,
        private modalController: ModalController,
        private cdr: ChangeDetectorRef,
        private stammdatenService: StammdatenService,
        private router: Router,
        private dialogs: Dialogs,
        private webView: WebView,
        private auftragService: AuftragService,
        private toastController: ToastController,
        private actionSheetController: ActionSheetController,
        private fileOpener: FileOpener,
        private camera: Camera,
        private file: File,
        private alertController: AlertController,
        private backgroundMode: BackgroundMode,
        private nav: NavController) {
    }

    ngOnInit() {
        this.log.info("KLINGELN " + this.auftrag?.Key);
        this.isAnzeigenErlaubt = !this.isAndererAuftragAktiv ||
            AppConfig.current.einstellungen.KlingenAuftragAnzeigenErlaubt === 'ja';

        this.subscriptions.push(this.auftragService.auftragGeloescht.subscribe((a) => this.onAuftragGeloescht(a)));
    }

    async onAuftragGeloescht(a: AuftragEx | AuftragListItem) {
        if (a.Key == this.auftrag?.Key) {
            // TODO: Dialog schließen
            this.log.info('Auftrag wurde gelöscht. Klingeln-Dialog wird geschlossen: ' + a.Key);

            // 10 ms warten, damit der eigene Auftrag zuerst aus der Queue gelöscht wird und dann der Dialog geschlossen wird.
            await Utils.delay(10);

            this.dismiss();
        }
    }

    ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    async ionViewWillEnter() {
        this.log.debug('ionViewWillEnter');
        this.isVisible = true;
        this.updateView();
    }

    ionViewDidEnter() {
        this.log.debug('ionViewDidEnter');
    }

    ionViewWillLeave() {
        this.log.debug(`ionViewWillLeave. klingelnAktiv=${this.klingelnAktiv}`);
        this.isVisible = false;
        this.klingelnAbbrechen = true;

        if (this.klingelnAktiv) {
            this.klingelnAktiv = false;
            this.systemService.stoppeKlingeln();
            // this.systemService.stoppeKlingelton();
        }
    }

    async updateView() {
        if (!this.isVisible) {
            return;
        }

        const neueAuftraege = this.neueAuftraege;

        this.log.debug('updateView', neueAuftraege);

        this.auftrag = null;

        if (neueAuftraege.length === 0) {
            this.dismiss();
            return;

        } else if (neueAuftraege.length === 1) {
            this.auftrag = neueAuftraege[0];
        } else {
            // TODO
            this.auftrag = neueAuftraege[0];
        }

        if (this.auftrag) {
            this.isHofauftrag = this.auftrag.Auftragstyp == Auftragstyp.Hofchecker;
            this.isTransportauftrag = this.auftrag.Auftragstyp == Auftragstyp.Transportauftrag;

            this.formularKonfiguration = await this.stammdatenService.getAuftragsFormularKonfiguration(this.auftrag);
        } else {
            // Kann eigentlich nicht sein
            this.formularKonfiguration = await this.stammdatenService.getAuftragsFormularKonfiguration({
                Auftragstyp: Auftragstyp.Hofchecker,
                WorkflowName: 'Standard'
            } as any);
        }

        this.log.debug('after updateView', {
            auftrag: this.auftrag,
            formularKonfiguration: this.formularKonfiguration
        });

        const appConfig = AppConfig.current;

        if (appConfig.einstellungen) {
            if (appConfig.einstellungen.Farbe1) {
                if (this.content) {
                    const toolbarElement = (this.content as any).el;
                    toolbarElement.style.setProperty('--background', appConfig.einstellungen.Farbe1);
                }
            }
        }

        this.klingeln();

        await this.initAuftragdetails();

        if (this.neueAuftraege.length == 1) {
            // Annehmen / Ablehnen-Buttons für Schuttkarl anzeigen
            this.isButtonsVorhanden = this.auftragdetails.buttonsAuftragPopup?.length > 0;
        } else {
            this.isButtonsVorhanden = false;
        }

        this.cdr.detectChanges();
    }

    async initAuftragdetails() {
        this.log.debug(`initAuftragdetails`);

        this.disposeAuftragdetails();

        if (!this.auftrag) {
            return;
        }

        this.auftragdetails = new Auftragdetails(
            this.webView,
            this.toastController,
            this.router,
            this.modalController,
            this.actionSheetController,
            this.camera,
            this.file,
            this.fileOpener,
            this.alertController,
            this.stammdatenService
        );

        this.auftragdetails.auftrag = this.auftrag;

        this.auftragdetails.darstellung = 'standard';
        this.auftragdetails.workflow = await this.stammdatenService.getWorkflow(this.auftrag.WorkflowName);
        this.auftragdetails.formularKonfiguration = await this.stammdatenService.getAuftragsFormularKonfiguration(this.auftrag, 'standard');

        await this.auftragdetails.init();
    }

    disposeAuftragdetails() {
        if (this.auftragdetails) {
            this.auftragdetails.dispose();
            this.auftragdetails = null;
        }
    }

    private async klingeln() {
        this.log.debug(`klingeln: klingelnAktiv=${this.klingelnAktiv}, aktion=${this.aktion}`);

        if (this.klingelnAktiv) {
            if (this.aktion === 'beep') {
                // Nicht nochmal
                return;
            }

            // Es wird noch für einen anderen Auftrag geklingelt.
            // Es muss erst der vorherige Auftrag abgebrochen werden
            this.klingelnAbbrechen = true;

            while (this.klingelnAktiv) {
                this.log.debug(`klingeln: klingelnAktiv=${this.klingelnAktiv}, delay 100`);
                await App.delay(100);
            }

            return;
        }

        this.klingelnAktiv = true;
        this.klingelnAbbrechen = false;

        if (this.aktion === "klingeln") {
            this.log.debug(`klingeln: klingelnAktiv = true (1)`);
            this.klingelnAktiv = true;
            this.systemService.starteKlingeln();
        } else if (this.aktion === "beep") {
            this.log.debug(`klingeln: klingelnAktiv = true (2)`);
            this.klingelnAktiv = true;
            await this.systemService.beepFuerNeueNachricht();
            this.klingelnAktiv = false;
        }
    }

    dismiss() {
        this.log.debug(`dismiss`);

        this.klingelnAbbrechen = true;

        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            'dismissed': true
        });
    }

    async onAuftragAnzeigen() {
        this.log.debug(`onAuftragAnzeigen`);

        await this.router.navigateByUrl('/auftragsliste/details/' + this.auftrag.Key);
        //// await this.nav.navigateRoot('/auftragsliste/details/' + this.auftrag.Key);
        this.dismiss();
    }

    onIgnorieren() {
        this.dismiss();
    }

    onStatusButtonClicked() {
        this.log.debug('onStatusButtonClicked');
        this.dismiss();
    }
}
