import { App } from "./app";
import { Logger } from "./app-error-logger";
import { AppConfig } from "./app.config";
import { StringHelper } from "./string-helper";

const log = new Logger('ImageHelper');

export class ImageHelper {

    static async writeImageText(dataUrl: string, text: string, platzhalter: any): Promise<Blob> {
        const image = await ImageHelper.dataUrlToImage(dataUrl);

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = image.width;
        canvas.height = image.height;

        ctx.drawImage(image, 0, 0, image.width, image.height);

        // Muss hier oben schon gesetzt werden, damit die Texte richtig gemessen werden
        ctx.font = AppConfig.current.einstellungen.FotosTextFont;
        ctx.fillStyle = AppConfig.current.einstellungen.FotosTextFarbe;
        ctx.textAlign = "center";
        ctx.textBaseline = 'top';

        // Platzhalter ersetzen
        // Platzhalter sind in der Form {Auftragsnummer}
        if (platzhalter && text && text.indexOf('{') >= 0) {
            for (const key in platzhalter) {
                if (platzhalter.hasOwnProperty(key)) {
                    let value = platzhalter[key];

                    if (typeof (value) === 'undefined' || value == null || value === 'undefined') {
                        value = '';
                    }

                    text = StringHelper.ersetzePlatzhalter(text, key, value)
                }
            }
        }

        let bottom = Math.round(canvas.height * 0.95);
        let lines = text.split('\n').filter(p => p.trim());

        const metrics = ctx.measureText(lines[0]);
        let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
        let y = bottom - fontHeight * (lines.length - 1);
        let x = image.width / 2;

        // let textMaxWidth = 0;

        // for (let i = 0; i < lines.length; i++) {
        //     const width = ctx.measureText(lines[i]).width;
        //     if (width > textMaxWidth) {
        //         textMaxWidth = width;
        //     }
        // }

        // Etwas Padding
        // let padding = 5;
        // textMaxWidth += (padding * 2);
        // let bgHeight = (fontHeight * lines.length) + (padding * 2);

        // Ein Rechteck für den Hintergrudn zeichnen
        // ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
        // ctx.fillRect(x - (textMaxWidth / 2), y - padding, textMaxWidth, bgHeight);

        // Text
        // ctx.font = AppConfig.current.einstellungen.FotosTextFont;
        // ctx.fillStyle = AppConfig.current.einstellungen.FotosTextFarbe;
        // ctx.textAlign = "center";
        // ctx.textBaseline = 'top';

        for (let i = 0; i < lines.length; i++) {
            ctx.fillText(lines[i], x, y);
            y += fontHeight;
        }

        return await new Promise((resolve) => {
            canvas.toBlob(resolve, 'image/jpeg', 0.85); // implied image/png format
        });
    }

    static svgString2Image(svgString, width, height, format): Promise<string> {
        return new Promise((resolve, reject) => {
            // set default for format parameter
            format = format ? format : 'png';

            // SVG data URL from SVG string
            const svgData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));

            // create canvas in memory(not in DOM)
            const canvas = document.createElement('canvas');

            // get canvas context for drawing on canvas
            const context = canvas.getContext('2d');

            // set canvas size
            canvas.width = width;
            canvas.height = height;

            // create image in memory(not in DOM)
            const image = new Image();

            // later when image loads run this
            image.onload = function () { // async (happens later)
                // clear canvas
                context.clearRect(0, 0, width, height);
                // draw image with SVG data to canvas
                context.drawImage(image, 0, 0, width, height);
                // snapshot canvas as png
                const pngData = canvas.toDataURL('image/' + format);
                // pass png data URL to callback
                resolve(pngData);
            };

            // start loading SVG data into in memory image
            image.src = svgData;
        });
    }

    static async dataUrlToImageData(dataUrl: string, maxWidth: number = 9999, maxHeight: number = 9999): Promise<ImageData> {
        const image = await ImageHelper.dataUrlToImage(dataUrl);

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const width = Math.min(image.width, maxWidth);
        const height = Math.min(image.height, maxHeight);

        canvas.width = width;
        canvas.height = height;
        context.drawImage(image, 0, 0, width, height);

        return context.getImageData(0, 0, canvas.width, canvas.height);
    }

    static dataUrlToImage(dataUrl: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            if (!dataUrl) {
                return resolve(null);
            }

            const image = new Image();

            let isResolved = false;

            image.addEventListener('load', () => {
                isResolved = true;
                resolve(image);
            }, false);

            image.src = dataUrl;

            setTimeout(() => {
                if (!isResolved) {
                    log.error('Timeout beim Laden der dataUrl: ' + dataUrl);
                    resolve(null);
                }
            }, 5000);
        });
    }

    static cropImage(imageData: ImageData): HTMLCanvasElement {
        const pixels = imageData;
        const l = pixels.data.length;

        const bound = {
            top: null,
            left: null,
            right: null,
            bottom: null
        };

        for (let i = 0; i < l; i += 4) {
            if (pixels.data[i + 3] !== 0) {
                const x = (i / 4) % imageData.width;

                // tslint:disable-next-line:no-bitwise
                const y = ~~((i / 4) / imageData.width);

                if (bound.top === null) {
                    bound.top = y;
                }

                if (bound.left === null) {
                    bound.left = x;
                } else if (x < bound.left) {
                    bound.left = x;
                }

                if (bound.right === null) {
                    bound.right = x;
                } else if (bound.right < x) {
                    bound.right = x;
                }

                if (bound.bottom === null) {
                    bound.bottom = y;
                } else if (bound.bottom < y) {
                    bound.bottom = y;
                }
            }
        }

        const trimHeight = bound.bottom - bound.top + 1;
        const trimWidth = bound.right - bound.left + 1;

        const canvas = document.createElement('canvas');
        canvas.width = imageData.width;
        canvas.height = imageData.height;
        const ctx = canvas.getContext('2d');
        ctx.putImageData(imageData, 0, 0);

        const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

        const copy = document.createElement('canvas').getContext('2d');
        copy.canvas.width = trimWidth;
        copy.canvas.height = trimHeight;
        copy.putImageData(trimmed, 0, 0);

        // open new window with trimmed image:
        return copy.canvas;
    }
}
