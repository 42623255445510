import { Component, OnInit, OnDestroy, ViewChild, Input, AfterViewInit, forwardRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logger } from 'src/app/api/helper/app-error-logger';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { App } from 'src/app/api/helper/app';
import { IonInput } from '@ionic/angular';
import { Utils } from 'src/app/api/helper/utils';

const log = new Logger('InputNumber');

@Component({
    selector: 'app-input-number',
    templateUrl: './input-number.component.html',
    styleUrls: ['./input-number.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputNumber),
        multi: true
    }]
})
export class InputNumber implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit, OnChanges {

    @ViewChild('input', { read: IonInput, static: true }) input: IonInput;

    @Input() titel: string;
    @Input() lines = 'none';
    @Input() placeholder = '';

    @Input() max = undefined; // 999999999;
    @Input() min = 0;
    @Input() disabled = false;
    @Input() readOnly = false;
    @Input() type = 'ganzzahl';
    @Input() enterkeyhint = 'done';
    @Input() required = false;
    @Input() clearInput = false;
    @Input() isInvalid = false;
    @Input() erlaubteWerte: number[] = null;
    @Input() plusMinusButtons = true;
    @Input() cssClass = '';
    @Input() einheit = '';
    @Input() step = 1;
    @Input() anzahlNachkommastellen = -1;
    @Input() noEmptySpace = false;
    @Input() paddingStart = undefined;
    @Input() emptySpaceClass: string = '';

    @Output() valueChanged = new EventEmitter<any>();
    @Output() lostFocus = new EventEmitter<any>();

    subscriptions: Subscription[] = [];

    isVisible = false;

    istGanzzahl = true;
    istDezimalzahl = false;
    inputmode = 'numeric';

    pattern = '\\d*';

    isMinusButtonVisible = false;
    isPlusButtonVisible = false;
    isClearButtonVisible = false;
    isEmptySpaceVisible = false;

    style = '';

    app = App.current;

    private _value: number;

    // Whatever name for this (myValue) you choose here, use it in the .html file.
    public get myValue(): number { return this._value }

    public set myValue(v: number) {
        if (v !== this._value) {
            if (v != null) {
                if (v < this.min) {
                    v = this.min;
                }

                if (this.max && v > this.max) {
                    v = this.max;
                }
            }

            if (this.anzahlNachkommastellen > 0) {
                // Hier kann es zu Rundungswerten kommen.
                // z.B. wird 0.06 zu 0.060000000000000001
                // Der Zahlenwert muss deshalb als String formatiert werden.
                v = Utils.formatFloat(v, this.anzahlNachkommastellen) as any;
            }

            this._value = v;
            this.onChange(v);
        }
    }

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        // log.debug('changed ' + this.titel, changes);

        this.updateType();
    }

    onChange = (_) => {
    };

    onTouched = () => { };

    writeValue(value: any): void {
        this.myValue = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    ngOnInit() {
        this.updateType();
    }

    async ngAfterViewInit() {
    }

    ngOnDestroy() {
    }

    async ionViewWillEnter() {
    }

    ionViewDidEnter() {
    }

    ionViewWillLeave() {
    }

    change(delta: number) {
        if (this.disabled || this.readOnly) {
            return;
        }

        let value = this.myValue;

        if (!value) {
            value = 0;
        }

        if (typeof (value) === 'string') {
            switch (this.type) {
                case 'integer':
                case 'ganzzahl':
                    value = Utils.parseInt(value);
                    break;

                case 'dezimalzahl':
                case 'decimalzahl':
                case 'decimal':
                    value = Utils.parseFloat(value);
                    break;
            }
        }

        if (this.erlaubteWerte?.length) {
            // Suche den Index in den erlaubten Werten
            let idx = this.erlaubteWerte.indexOf(value);

            if (idx < 0) {
                idx = 0;
            }

            if (delta < 0) {
                idx--;
            } else {
                idx++;
            }

            if (idx < 0) {
                idx = 0;
            } else if (idx >= this.erlaubteWerte.length) {
                idx = this.erlaubteWerte.length - 1;
            }

            value = this.erlaubteWerte[idx];
        } else {
            value += delta;

            if (this.max && value > this.max) {
                value = this.max;
            }

            if (value < this.min) {
                value = this.min;
            }
        }

        if (this.anzahlNachkommastellen > 0) {
            // Hier kann es zu Rundungswerten kommen.
            // z.B. wird 0.06 zu 0.060000000000000001
            // Der Zahlenwert muss deshalb als String formatiert werden.
            value = Utils.formatFloat(value, this.anzahlNachkommastellen) as any;
        }

        this.myValue = value;
        this.valueChanged.next(this.myValue);
    }

    onClearValue() {
        log.debug('onClearValue');
        
        this.myValue = null;
        this.valueChanged.next(this._value);

        this.input.setFocus();
    }

    setFocus() {
        try {
            this.input.setFocus();
        } catch (err) {
            log.warn(Utils.getErrorMessage(err), err);
        }
    }

    updateType() {
        this.istGanzzahl = false;
        this.istDezimalzahl = false;

        switch (this.type) {
            case 'integer':
            case 'ganzzahl':
                this.istGanzzahl = true;
                this.pattern = '\\d+';
                this.inputmode = 'numeric';
                break;

            case 'dezimalzahl':
            case 'decimalzahl':
            case 'decimal':
                this.istDezimalzahl = true;
                this.pattern = '^[0-9]+([,.][0-9]+)?$';
                this.inputmode = 'decimal';
                break;
        }

        this.isPlusButtonVisible = false;
        this.isMinusButtonVisible = false;
        this.isClearButtonVisible = false;

        if (this.plusMinusButtons && !this.disabled && !this.readOnly) {
            this.isPlusButtonVisible = true;
            this.isMinusButtonVisible = true;
        }

        if (this.clearInput && !this.isMinusButtonVisible) {
            this.isClearButtonVisible = true;
        }

        if (!this.isMinusButtonVisible && !this.isClearButtonVisible) {
            this.isEmptySpaceVisible = true;
        }

        if (this.noEmptySpace) {
            this.isEmptySpaceVisible = false;
        }

        this.style = '';

        if (this.paddingStart != null) {
            this.style += '--padding-start: ' + this.paddingStart;
        }
    }

    onBlur(e: any) {
        // log.debug('onBlur: ' + this.titel, e);

        if (this._value != null) {
            if (this._value < this.min) {
                this.myValue = this.min;
            }

            if (this.max && this._value > this.max) {
                this.myValue = this.max;
            }
        }

        if (this.anzahlNachkommastellen > 0) {
            let value = this._value;

            if (typeof value === 'string') {
                value = Utils.parseFloat(value);
            }

            this.myValue = Utils.formatFloat(value, this.anzahlNachkommastellen) as any;
        }

        this.lostFocus.next(e);
        this.valueChanged.next(this.myValue);
    }

    onValueChange(e: any) {
        this.valueChanged.next(this._value);
    }

    onIonChange(e: any) {
    }

    async onEnter(e) {
        if (this.enterkeyhint === 'next') {
            Utils.focusNextInputElement();

            // const domElement = e.srcElement?.parentElement as HTMLIonInputElement;

            // if (domElement) {
            //     const nextField = domElement.nextElementSibling as HTMLIonInputElement;
            //     if (nextField) {
            //         nextField.setFocus();
            //     }
            // }
        } else {
            this.app.closeKeyboard(e);
        }
    }
}
