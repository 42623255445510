import * as pako from 'pako';

export class WebWorkerCode {
    static prepareWebSocketMessage(obj: any): any {        
        const json = JSON.stringify(obj);

        // Nur komprimieren, falls lang genug
        const zipped = json.length > 250 ? pako.deflate(json) : null;

        if (!zipped || json.length < zipped.length * 1.3) {
            return json;
        } else {
            return zipped;
        }
    }
}