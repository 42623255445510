import { Injectable } from '@angular/core';

import { SwUpdate, UnrecoverableStateEvent, UpdateActivatedEvent, UpdateAvailableEvent } from '@angular/service-worker';
import { Logger } from './helper/app-error-logger';
import { UiHelper } from './helper/ui-helper';
import { Utils } from './helper/utils';

const log = new Logger('UpdateService');

@Injectable()
export class UpdateService {
    constructor(private swUpdate: SwUpdate) {
    }

    init() {
        log.info('Init update service...');

        this.swUpdate.available.subscribe(e => this.onUpdateAvailable(e));
        this.swUpdate.activated.subscribe(e => this.onUpdateActivated(e));
        this.swUpdate.unrecoverable.subscribe(e => this.onUpdateUnrecoverable(e));

        // this.swUpdate.available.subscribe(evt => {
        //     const snack = this.snackbar.open('Update Available', 'Reload');
        //     snack
        //         .onAction()
        //         .subscribe(() => {
        //             window.location.reload();
        //         });

        //     setTimeout(() => {
        //         snack.dismiss();
        //     }, 6000);
        // });

        // Direkt nach dem Start auf Updates prüfen
        setTimeout(() => this.checkForUpdate(), 10 * 1000);
    }

    async checkForUpdate() {
        log.info('checkForUpdate');

        try {
            await this.swUpdate.checkForUpdate();
        } catch (err) {
            log.warn(Utils.getErrorMessage(err), err);
        }
    }

    async onUpdateAvailable(e: UpdateAvailableEvent) {
        log.info('onUpdateAvailable', e);

        log.info('activateUpdate');
        await this.swUpdate.activateUpdate();

        const ok = await UiHelper.confirmJaNein('Update installieren?');

        if (ok) {
            window.location.reload();
        }
    }

    onUpdateActivated(e: UpdateActivatedEvent) {
        log.info('onUpdateActivated', e);
    }

    onUpdateUnrecoverable(e: UnrecoverableStateEvent) {
        log.warn('onUpdateUnrecoverable', e);
        window.location.reload();
    }
}