import { Logger, LogLevel } from './app-error-logger';
import { Storage } from '@ionic/storage';
import { File } from '@ionic-native/file/ngx';
import { HashSet } from 'src/app/shared/helper/hashset';
import { Platform } from '@ionic/angular';
import { FileHelper } from './file-helper';
import { Utils } from './utils';
import { Constants } from '../model/constants';
import { App } from './app';
import { AuftragEx } from '../model/model';

export class AppStorage {
    static current: AppStorage = new AppStorage();

    private log = new Logger('AppStorage');

    private storageReady = false;

    private platform: Platform = null;
    private storage: Storage = null;
    private file: File = null;

    /**
     * Ist TRUE während die lokalen Daten gelöscht werden
     */
    datenWerdenGeloescht = false;

    dataCache = {};

    private constructor() {
    }

    init(storage: Storage, file: File, platform: Platform) {
        this.log.info('INIT');

        this.storage = storage;
        this.file = file;
        this.platform = platform;

        App.ready().then(() => {
            this.storage.ready().then(() => {
                this.log.info('STORAGE READY');
                this.storageReady = true;
            });
        });

        this.bereinigen();
    }

    async get(key: string, cache = true, useLocalStorage = false): Promise<any> {
        if (cache) {
            this.log.debug('GET: ' + key);
        } else {
            this.log.debug('GET: ' + key + '(NO CACHE)');
        }

        if (cache) {
            if (this.dataCache.hasOwnProperty(key)) {
                this.log.debug('GET: ' + key + ' (CACHE HIT)');
                return this.dataCache[key];
            }

            if (useLocalStorage) {
                // Prüfen ob die Daten im localStorage vorhanden sind.
                // Ist viel schneller als SQLigth über "storage"
                const json = localStorage.getItem(key);

                if (json) {
                    this.log.debug('GET: ' + key + ' from localStorage');

                    const dataFromLocalStorage = JSON.parse(json);
                    this.dataCache[key] = dataFromLocalStorage;
                    return dataFromLocalStorage;
                }
            }
        }

        if (!this.storageReady) {
            this.log.debug('Waiting for storage ready...');
            await App.ready();
        }

        const data = await this.storage.get(key);

        if (cache && data) {
            this.dataCache[key] = data;
        }

        return data;
    }

    async set(key: string, data: any, cache = true, useLocalStorage = false): Promise<void> {
        if (cache) {
            this.log.debug('SET: ' + key + ' (cache, useLocalStorage=' + useLocalStorage + ')', data);
        } else {
            this.log.debug('SET: ' + key + ' (useLocalStorage=' + useLocalStorage + ')', data);
        }

        // // debugg
        // if (key.startsWith("auftrag-")) {
        //     const auftrag = data as AuftragEx;

        //     if (auftrag.Positionen?.length) {
        //         this.log.debug('SET: ' + key + ' Behälter:' + auftrag.Positionen[0].Behaelter[0]?.BehaelterNr);
        //     }
        // }

        if (cache) {
            this.dataCache[key] = data;

            if (useLocalStorage) {
                // Auch im localStorage speichern
                try {
                    localStorage.setItem(key, JSON.stringify(data));
                } catch (err) {
                    this.log.warn(`Fehler beim Speichern im localStorage (${key}): ${Utils.getErrorMessage(err)}`, data);
                    localStorage.removeItem(key);
                }
            } else {
                localStorage.removeItem(key);
            }
        } else {
            // Eintrag soll nicht im Cache gespeichert werden. Ein eventueller Eintrag im Cache muss aber gelöscht werden
            // Das tritt z.B. beim Aktualisieren von Aufträgen auf. Wenn der Auftrag schon angezeigt wurde, befindet er sich im Cache.
            // Wenn er dann aktualisiert wird, muss der Cache geleert werden
            delete this.dataCache[key];

            localStorage.removeItem(key);
        }

        if (!this.storageReady) {
            await this.storage.ready();
        }

        this.log.debug('storage set: ' + key);

        await this.storage.set(key, data);
    }

    async clear() {
        this.log.debug('CLEAR');

        // TODO: local storage löschen

        this.dataCache = {};

        if (!this.storageReady) {
            await this.storage.ready();
        }

        await this.storage.clear();
    }

    async remove(key: string) {
        this.log.debug('REMOVE: ' + key);

        localStorage.removeItem(key);

        delete this.dataCache[key];

        if (!this.storageReady) {
            await this.storage.ready();
        }

        await this.storage.remove(key);
    }

    async getBildBase64Data(guid: string): Promise<string> {
        const src = await this.getBildSrc(guid);

        if (!src) {
            return null;
        }

        let bildBase64 = '';

        if (src && src.startsWith('file:')) {
            // Bild wurde als Pfad abgespeichert. Datei jetzt einlesen
            // Beispiel: file:///data/user/0/de.recomobil.app/files/auftrag-2011-1570483448148.jpg

            const filename = FileHelper.getFilename(src);
            const directory = FileHelper.getDirectory(src);

            bildBase64 = await this.file.readAsDataURL(directory, filename);
        } else {
            bildBase64 = src;
        }

        if (bildBase64.startsWith("data:image/")) {
            // An den Server nur die Base64-Daten schicken
            const pos = bildBase64.indexOf('base64,');

            if (pos) {
                bildBase64 = bildBase64.substring(pos + 7);
            }
        }

        return bildBase64;
    }

    async loescheBild(guid: string): Promise<void> {
        if (!guid) {
            return;
        }

        try {
            const src = await this.getBildSrc(guid);

            if (src && src.startsWith('file:')) {
                const filename = FileHelper.getFilename(src);
                const directory = FileHelper.getDirectory(src);

                try {
                    await this.file.removeFile(directory, filename);
                } catch (err) {
                    this.log.warn('error removing file', err);
                }
            }

            await AppStorage.current.remove('bild-' + guid);
        } catch (err) {
            this.log.error('loescheBild ' + guid + ': ' + Utils.getErrorMessage(err));
        }
    }

    async getBildSrc(guid: string): Promise<string> {
        if (!guid) {
            this.log.warn('getBildSrc guid is null');
            return null;
        }

        const src = await AppStorage.current.get('bild-' + guid, false) as string;

        if (!src) {
            this.log.warn('getBildSrc src is null, guid=' + guid);
            return null;
        }

        return src;
    }

    /**
     * Wird initial aufgerufen
     */
    async bereinigen() {
        this.log.info('bereinigen');

        for (const key in localStorage) {
            if (key) {
                if (key.startsWith('auftrag-')
                    || key == 'aktuelleTour'
                    || key == Constants.AUFTRAEGE
                    || key == 'uvv-gegenstaende'
                    || key == 'nachrichten') {

                    this.log.info('bereinigen: Remove from localStorage: ' + key);
                    localStorage.removeItem(key);
                }
            }
        }
    }

    /**
     * Wird nur beim expliziten Löschen der lokalen Daten aufgerufen
     */
    async loescheAlleDaten() {
        this.log.warn('LÖSCHE ALLE DATEN');

        this.datenWerdenGeloescht = true;

        try {

            for (const key in localStorage) {
                if (key) {
                    if (key.startsWith('auftrag-') || key == 'aktuelleTour' || key == Constants.AUFTRAEGE || key == 'uvv-gegenstaende' || key == 'nachrichten') {
                        this.log.info('DELETE LOCAL STORAGE: ' + key);
                        localStorage.removeItem(key);
                    }
                }
            }

            const keys = await this.storage.keys();

            for (const key of keys) {
                if (key.startsWith('auftrag-') || key.startsWith('bild-') || key == Constants.AUFTRAEGE || key == 'uvv-gegenstaende' || key == 'nachrichten') {
                    this.log.info('DELETE STORAGE: ' + key);
                    this.storage.remove(key);
                }
            }
        } catch (err) {
            this.log.error(err);
        }

        this.datenWerdenGeloescht = false;
        this.dataCache = {};
    }
}
