import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalController, NavController } from '@ionic/angular';
import { AuftragService } from 'src/app/api/auftrag.service';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { App } from 'src/app/api/helper/app';
import { WorkflowStatus, GeraetDto } from 'src/app/api/model/swagger-model';
import { AuftragEx } from 'src/app/api/model/model';
import { RemoteService } from 'src/app/api/remote.service';
import { AppConfig } from 'src/app/api/helper/app.config';
import { IAuftragdetails } from '../auftragdetails/iauftragdetails';


@Component({
    selector: 'app-auftrag-statusmeldung',
    templateUrl: './auftrag-statusmeldung.page.html',
    styleUrls: ['./auftrag-statusmeldung.page.scss'],
})
export class AuftragStatusmeldungPage implements OnInit, AfterViewInit, OnDestroy {
    log = new Logger("AuftragStatusmeldungPage");

    @Input() auftragdetails: IAuftragdetails;
    @Input() statusListe: WorkflowStatus[];

    subscriptions: Subscription[] = [];

    constructor(
        private auftragService: AuftragService,
        private modalController: ModalController,
        private remoteService: RemoteService,
        private cdr: ChangeDetectorRef) { }

    async ionViewWillEnter() {
        this.log.debug('ionViewWillEnter');
    }

    ionViewDidEnter() {
        App.current.backAction = () => this.onAbbrechen();
    }

    async ionViewWillLeave() {
        this.log.debug('ionViewWillLeave');
        App.current.backAction = null;
    }

    async ngOnInit() {
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    async onStatusSelected(status: WorkflowStatus) {
        this.log.debug('onStatusSelected: ' + status?.name);

        this.modalController.dismiss({
            'workflowStatus': status
        });
    }

    onAbbrechen() {
        this.dismiss();
    }

    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss(false);
    }
}
