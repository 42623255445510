import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IonContent, NavController, ModalController } from '@ionic/angular';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { Textdatei, TextdateiTyp, UvvPruefGegenstand } from 'src/app/api/model/swagger-model';
import { SystemService } from 'src/app/api/system.service';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { UvvHelper } from 'src/app/api/helper/uvv-helper';

import * as moment from 'moment';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { App } from 'src/app/api/helper/app';
import { AppConfig } from 'src/app/api/helper/app.config';
import { UvvDetailsPage } from '../../components/uvv-details/uvv-details.page';
import { Utils } from 'src/app/api/helper/utils';

@Component({
    selector: 'app-uvv-pruefung-modal',
    templateUrl: './uvv-pruefung-modal.page.html',
    styleUrls: ['./uvv-pruefung-modal.page.scss'],
})
export class UvvPruefungModalPage implements OnInit, OnDestroy {
    log = new Logger('UvvPruefungModalPage');

    @ViewChild('content', { read: IonContent, static: true }) content: IonContent;

    @Input() gegenstand: UvvPruefGegenstand = null;

    subscriptions: Subscription[] = [];

    isVisible = false;
    bezeichnung = '';
    datumNaechstePruefung = '';
    restTage = 0;
    abgelehnt = true;

    constructor(
        private route: ActivatedRoute,
        private systemService: SystemService,
        private modalController: ModalController,
        private cdr: ChangeDetectorRef,
        private stammdatenService: StammdatenService,
        private router: Router,
        private dialogs: Dialogs,
        private backgroundMode: BackgroundMode,
        private nav: NavController) { }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    async ionViewWillEnter() {
        this.log.debug('ionViewWillEnter');
        this.isVisible = true;
        this.updateView();
    }

    ionViewDidEnter() {
        this.log.debug('ionViewDidEnter');
    }

    ionViewWillLeave() {
        this.log.debug('ionViewWillLeave');
        this.isVisible = false;
    }

    async updateView() {
        if (!this.isVisible) {
            return;
        }

        this.bezeichnung = UvvHelper.getBezeichnung(this.gegenstand);

        const naechstePruefung = moment(this.gegenstand.naechstePruefung);
        const now = moment();

        this.datumNaechstePruefung = naechstePruefung.format('DD.MM.YYYY');

        this.restTage = naechstePruefung.diff(now, 'days');

        this.cdr.detectChanges();
    }

    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            'dismissed': true
        });
    }

    async onAnzeigen(e: Event) {
        this.abgelehnt = false;

        const fahrer = App.current.fahrer.getValue();
        const fahrzeug = App.current.fahrzeug.getValue();

        const textdatei: Textdatei = {
            typ: TextdateiTyp.TextInformation,
            datum: moment().toISOString(),
            geraeteNummer: AppConfig.current.geraeteNummer,
            TextInformation: {
                Typ: 'UvvAkzeptiert',
                Text: 'UVV akzeptiert: ' + this.bezeichnung + ', Datum nächste Prüfung: ' + this.datumNaechstePruefung,
                Fahrzeug: fahrzeug?.kennzeichen,
                FahrzeugKey: fahrzeug?.key,
                Statuszeit: moment().toISOString(),
                Personalnummer: fahrer?.personalnummer,
                PersonalKey: fahrer?.Key,
                Geraet: AppConfig.current.geraeteNummer,
                NaechstePruefung: this.datumNaechstePruefung,
                RestTage: this.restTage,
                Key: this.gegenstand.key,
                IdentNummer: this.gegenstand.identNummer
            },
        };

        await this.systemService.sendeTextdatei(textdatei, false);

        this.dismiss();

        await Utils.delay(100);

        await UvvDetailsPage.show(this.gegenstand);
    }

    async onAblehnen() {
        const ok = await UiHelper.confirmJaNein('UVV-Prüfung wirklich ablehnen?');

        if (!ok) {
            return;
        }

        const fahrer = App.current.fahrer.getValue();
        const fahrzeug = App.current.fahrzeug.getValue();

        const textdatei: Textdatei = {
            typ: TextdateiTyp.TextInformation,
            datum: moment().toISOString(),
            geraeteNummer: AppConfig.current.geraeteNummer,
            TextInformation: {
                Typ: 'UvvAbgelehnt',
                Text: 'UVV abgelehnt: ' + this.bezeichnung + ', Datum nächste Prüfung: ' + this.datumNaechstePruefung,
                Fahrzeug: fahrzeug?.kennzeichen,
                FahrzeugKey: fahrzeug?.key,
                Statuszeit: moment().toISOString(),
                Personalnummer: fahrer?.personalnummer,
                PersonalKey: fahrer?.Key,
                Geraet: AppConfig.current.geraeteNummer,
                NaechstePruefung: this.datumNaechstePruefung,
                RestTage: this.restTage,
                Key: this.gegenstand.key,
                IdentNummer: this.gegenstand.identNummer
            },
        };

        await this.systemService.sendeTextdatei(textdatei, false);

        this.abgelehnt = true;

        this.dismiss();
    }
}
