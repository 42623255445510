export class AppConsole {
    static consoleLogs: any[] = [];
    static maxConsoleLogs = 5000;
    static consoleHolder: any;

    static init() {
        // tslint:disable-next-line:only-arrow-functions
        AppConsole.consoleHolder = ((oldConsole) => {
            return {
                log: (text, ...optionalParams: any[]) => {
                    AppConsole.add('LOG:  ' + text);

                    if (typeof (oldConsole.log) === 'function') {
                        oldConsole.log(text, ...optionalParams);
                    }
                },
                info: (text, ...optionalParams: any[]) => {
                    AppConsole.add('INFO: ' + text);

                    if (typeof (oldConsole.info) === 'function') {
                        oldConsole.info(text, ...optionalParams);
                    }
                },
                warn: (text, ...optionalParams: any[]) => {
                    AppConsole.add('WARN: ' + text);

                    if (typeof (oldConsole.warn) === 'function') {
                        oldConsole.warn(text, ...optionalParams);
                    }
                },
                error: (text, ...optionalParams: any[]) => {
                    AppConsole.add('ERR:  ' + text);

                    if (typeof (oldConsole.error) === 'function') {
                        oldConsole.error(text, ...optionalParams);
                    }
                }
            };
        })(window.console);

        (window as any).console = AppConsole.consoleHolder;
    }

    static add(str: string) {
        AppConsole.consoleLogs.push(str);

        if (AppConsole.consoleLogs.length > AppConsole.maxConsoleLogs) {
            AppConsole.consoleLogs.splice(0, 1);
        }
    }
}
