import { Component, OnInit, OnDestroy, ViewChild, Input, AfterViewInit, forwardRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logger } from 'src/app/api/helper/app-error-logger';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { App } from 'src/app/api/helper/app';
import { AlertController, IonInput, IonModal, IonPopover } from '@ionic/angular';

import * as moment from 'moment';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { Utils } from 'src/app/api/helper/utils';
import { AppConfig } from 'src/app/api/helper/app.config';

const log = new Logger('InputUhrzeit');

@Component({
    selector: 'app-input-uhrzeit',
    templateUrl: './input-uhrzeit.component.html',
    styleUrls: ['./input-uhrzeit.component.scss'],
    providers: [{   // <================================================ ADD THIS
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputUhrzeit),
        multi: true
    }]
})
export class InputUhrzeit implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit, OnChanges {
    @ViewChild('input', { read: IonInput, static: true }) input: IonInput;
    @ViewChild('popover', { read: IonPopover }) popover: IonPopover;

    @Input() titel: string;
    @Input() lines = 'none';

    @Input() disabled = false;
    @Input() readOnly = false;
    @Input() enterkeyhint = 'next';
    @Input() required = false;
    @Input() clearInput = false;
    @Input() isInvalid = false;
    @Input() paddingStart = undefined;
    @Input() pattern = '\\d\\d:\\d\\d';

    @Output() valueChanged = new EventEmitter<any>();
    @Output() lostFocus = new EventEmitter<any>();

    minuteValues: number[] = [];

    subscriptions: Subscription[] = [];

    isVisible = false;

    isClearButtonVisible = false;
    isEmptySpaceVisible = false;

    style = '';

    app = App.current;

    private _value: string;

    minutenIntervall = 1;

    // Whatever name for this (myValue) you choose here, use it in the .html file.
    public get myValue(): string { return this._value }

    public set myValue(v: string) {
        if (v !== this._value) {
            if (v && typeof (v) === 'string' && v.length > 10) {
                // ISO-Datum
                try {
                    const m = moment(v);
                    v = m.format('HH:mm');
                } catch (err) {
                    log.error('Fehler beim parsen der Uhrzeit ' + v + ': ' + Utils.getErrorMessage(err));
                }
            }

            v = this.rundenUhrzeit(v);

            this._value = v;
            this.onChange(v);

            if (this.isVisible) {
                this.validate();
            }
        }
    }

    constructor(
        private alertController: AlertController,
        private datePicker: DatePicker,
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        // log.debug('changed ' + this.titel, changes);

        this.updateType();
    }

    onChange = (_) => {
        if (this.isVisible) {
            this.validate();
        }
    };

    onTouched = () => { };

    writeValue(value: any): void {
        this.myValue = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    ngOnInit() {
        this.isVisible = true;
        this.initMinuteValues();
        this.updateType();
        this.validate();
    }

    initMinuteValues() {
        this.minutenIntervall = Utils.parseInt(AppConfig.current.einstellungen.UhrzeitMinutenIntervall);

        if (!this.minutenIntervall || this.minutenIntervall < 1) {
            this.minutenIntervall = 1;
        }

        this.minuteValues = [];

        for (let i = 0; i < 60; i += this.minutenIntervall) {
            this.minuteValues.push(i);
        }
    }

    rundenUhrzeit(str: string): string {
        if (this.minutenIntervall > 1 && str && typeof (str) === 'string' && str.length >= 4 && str.length <= 5) {
            let idx = str.indexOf(':');

            if (idx) {
                let stunden = str.substring(0, idx);
                let minuten = Utils.parseInt(str.substring(idx + 1));

                minuten = Math.round(minuten / this.minutenIntervall) * this.minutenIntervall;

                let strMinuten = minuten.toString();

                if (strMinuten.length < 2) {
                    strMinuten = "0" + strMinuten;
                }

                str = stunden + ":" + strMinuten;
            }
        }

        return str;
    }


    async ngAfterViewInit() {
        // log.debug('ngAfterViewInit ' + this.titel);

        setTimeout(() => {
            this.validate();
        });
    }

    ngOnDestroy() {
    }

    async ionViewWillEnter() {
    }

    ionViewDidEnter() {
    }

    ionViewWillLeave() {
    }

    onClearValue() {
        this.myValue = null;
        this.valueChanged.next(this._value);

        this.input.setFocus();
    }

    updateType() {
        this.isClearButtonVisible = false;


        // if (this.plusMinusButtons && !this.disabled && !this.readOnly) {
        //     this.isPlusButtonVisible = true;
        //     this.isMinusButtonVisible = true;
        // }

        if (this.clearInput) {
            this.isClearButtonVisible = true;
        }

        if (!this.isClearButtonVisible) {
            this.isEmptySpaceVisible = true;
        }

        this.style = '';

        if (this.paddingStart != null) {
            this.style += '--padding-start: ' + this.paddingStart;
        }
    }

    onBlur(e: any) {
        this.lostFocus.next(e);
        this.valueChanged.next(this._value);
        this.validate();
    }

    onValueChange(e: any) {
        this.valueChanged.next(this._value);
        this.validate();
    }

    async selectUhrzeit(e: any) {
        log.debug('selectUhrzeit: ' + this.titel);

        if (this.readOnly) {
            return;
        }

        await this.popover.present(e);

        // try {
        //     if (!App.isCordovaAvailable()) {
        //         this.myValue = moment().format('HH:mm');
        //         this.valueChanged.next(this._value);
        //         this.validate();
        //         return;
        //     }

        //     let date = new Date();

        //     // if (this._value && this._value.length >= 8 && this._value.length <= 10) {
        //     //     // Versuche das eingegebene Uhrzeit zu parsen
        //     //     date = moment(this._value).toDate();
        //     // }

        //     date = await this.datePicker.show({
        //         date: new Date(),
        //         mode: 'time',
        //         locale: 'de-DE',
        //         titleText: this.titel,
        //         is24Hour: true,
        //         androidTheme: this.datePicker.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT
        //     });

        //     const m = moment(date);

        //     this.myValue = m.format('HH:mm');
        //     this.valueChanged.next(this._value);
        //     this.validate();
        // } catch (err) {
        //     // UiHelper.showError(err);
        // }
    }

    async closeInputPopover() {
        if (!this.myValue) {
            // Es wurde auf "OK" geklickt, aber das Datum nicht geändert. D.h. es soll das aktuelle Datum übernommen werden
            this.myValue = moment().toISOString();
        }

        await this.popover.dismiss();
    }

    validate(): boolean {
        log.debug('validate ' + this.titel + ': ' + this.pattern + ', value=' + this._value);

        if (this.pattern && this.pattern != '.*') {
            this.isInvalid = false;

            try {
                const wert = Utils.trimToEmpty(this._value);

                if (!wert.match(this.pattern)) {
                    this.isInvalid = true;
                }

            } catch (err) {
                log.warn('Fehler in Regex ' + this.pattern + ': ' + Utils.getErrorMessage(err));
            }
        }

        return !this.isInvalid;
    }

    async onEnter(e) {
        if (this.enterkeyhint === 'next') {
            Utils.focusNextInputElement();
        } else {
            this.app.closeKeyboard(e);
        }
    }

}
