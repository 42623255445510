import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, Input, Output } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { IonContent, NavController, ModalController } from '@ionic/angular';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { AuftragEx } from 'src/app/api/model/model';
import { AuftragsFormular, StatusmeldungZusatzinfo, StatuszusatzinfoFeld, WorkflowStatus } from 'src/app/api/model/swagger-model';
import { SystemService } from 'src/app/api/system.service';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { AppConfig } from 'src/app/api/helper/app.config';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { delay } from 'rxjs/operators';
import { App } from 'src/app/api/helper/app';

import * as moment from 'moment';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { Utils } from 'src/app/api/helper/utils';

@Component({
    selector: 'app-statuszusatzinfo-modal',
    templateUrl: './statuszusatzinfo-modal.page.html',
    styleUrls: ['./statuszusatzinfo-modal.page.scss'],
})
export class StatuszusatzinfoModalPage implements OnInit, OnDestroy {
    log = new Logger('StatuszusatzinfoModalPage');

    @ViewChild('content', { read: IonContent, static: true }) content: IonContent;

    @Input() auftrag: AuftragEx = null;
    @Input() workflowStatus: WorkflowStatus = null;

    felder: FeldVO[] = [];

    subscriptions: Subscription[] = [];

    formularKonfiguration: AuftragsFormular;

    isVisible = false;

    app = App.current;

    constructor(
        private route: ActivatedRoute,
        private systemService: SystemService,
        private modalController: ModalController,
        private cdr: ChangeDetectorRef,
        private datePicker: DatePicker,
        private stammdatenService: StammdatenService,
        private router: Router,
        private dialogs: Dialogs,
        private backgroundMode: BackgroundMode,
        private nav: NavController) { }

    ngOnInit() {
        this.felder = [];

        if (!this.workflowStatus.statuszusatzinfo) {
            return;
        }

        if (this.workflowStatus.statuszusatzinfo.Felder) {
            for (const feld of this.workflowStatus.statuszusatzinfo.Felder) {
                if (!feld.Typ) {
                    feld.Typ = 'text';
                }

                const vo = {
                    Key: feld.Key,
                    Bezeichnung: feld.Bezeichnung,
                    Typ: feld.Typ,
                    Wert: null,
                    Erforderlich: feld.Erforderlich
                };

                if (feld.Typ === 'checkbox') {
                    vo.Wert = false;
                }

                if (feld.StandardWert != null && feld.StandardWert != '') {
                    if (feld.Typ === 'checkbox') {
                        vo.Wert = Utils.isTrue(feld.StandardWert);
                    } else if (feld.Typ === 'ganzzahl') {
                        vo.Wert = Utils.parseInt(feld.StandardWert);
                    } else if (feld.Typ === 'dezimalzahl') {
                        vo.Wert = Utils.parseFloat(feld.StandardWert);
                    } else {
                        vo.Wert = feld.StandardWert;
                    }
                }

                this.felder.push(vo);
            }
        }
    }

    ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    async ionViewWillEnter() {
        this.log.debug('ionViewWillEnter');
        this.isVisible = true;
        this.updateView();
    }

    async ionViewDidEnter() {
        this.log.debug('ionViewDidEnter');

        await delay(400);

        const firstInput: any = document.querySelector('.zusatzinfo-content input');

        if (firstInput) {
            firstInput.focus();
        }
    }

    ionViewWillLeave() {
        this.log.debug('ionViewWillLeave');
        this.isVisible = false;
    }

    async updateView() {
        if (!this.isVisible) {
            return;
        }

        this.log.debug('updateView');

        if (!this.auftrag) {
            this.dismiss();
            return;
        }

        this.log.debug('after updateView', {
            auftrag: this.auftrag,
            formularKonfiguration: this.formularKonfiguration
        });

        const appConfig = AppConfig.current;

        if (appConfig.einstellungen) {
            if (appConfig.einstellungen.Farbe1) {
                if (this.content) {
                    const toolbarElement = (this.content as any).el;
                    toolbarElement.style.setProperty('--background', appConfig.einstellungen.Farbe1);
                }
            }
        }

        this.cdr.detectChanges();
    }


    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            'dismissed': true
        });
    }

    async onOK() {
        this.log.debug('onOK');

        for (const vo of this.felder) {
            if (vo.Erforderlich) {
                if (vo.Wert === null || vo.Wert === '') {
                    UiHelper.showAlert(`Bitte '${vo.Bezeichnung}' eingeben`);
                    return;
                }

                if (vo.Typ === 'checkbox') {
                    if (!vo.Wert) {
                        UiHelper.showAlert(`'${vo.Bezeichnung}' ist erforderlich`);
                        return;
                    }
                }
            }
        }

        const zusatzinfo: StatusmeldungZusatzinfo = {
            Felder: []
        }

        for (const vo of this.felder) {
            let wert = vo.Wert;

            if (vo.Typ === 'checkbox') {
                if (wert) {
                    wert = 'ja';
                } else {
                    wert = 'nein';
                }
            }

            if (vo.Typ === 'uhrzeit') {
                if (wert && typeof (wert) === 'string' && wert.length > 10) {
                    const m = Utils.parseDatum(wert);
                    wert = m.format('HH:mm');
                }
            }

            zusatzinfo.Felder.push({
                Key: vo.Key,
                Bezeichnung: vo.Bezeichnung,
                Wert: wert
            })
        }

        this.modalController.dismiss({
            'dismissed': true,
            'zusatzinfo': zusatzinfo
        });
    }

    onAbbrechen() {
        this.log.debug('onAbbrechen');
        this.dismiss();
    }

    async selectDatum(feld: FeldVO) {
        this.log.debug('selectDatum');

        try {
            let date = new Date();

            let strDatum = feld.Wert;

            if (strDatum && strDatum.length >= 8 && strDatum.length <= 10) {
                // Versuche das eingegebene Datum zu parsen
                date = moment(strDatum).toDate();
            }

            date = await this.datePicker.show({
                date: new Date(),
                mode: 'date',
                androidTheme: this.datePicker.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT
            });

            if (date) {
                const m = moment(date);
                feld.Wert = m.format('DD.MM.YYYY');

                this.cdr.detectChanges();
            }
        } catch (err) {
            // UiHelper.showError(err);
        }
    }
}

interface FeldVO {
    Key: string;
    Bezeichnung: string;
    Typ: string;
    Wert: any;
    Erforderlich: boolean;
}