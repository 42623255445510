import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ModalController, PopoverController } from '@ionic/angular';
import { AuftragService } from 'src/app/api/auftrag.service';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { App } from 'src/app/api/helper/app';
import { Stoerzeit, TourStatus } from 'src/app/api/model/swagger-model';
import { AuftragEx } from 'src/app/api/model/model';
import { RemoteService } from 'src/app/api/remote.service';
import { IAuftragdetails } from 'src/app/auftragsliste/auftragdetails/iauftragdetails';
import { UiHelper } from 'src/app/api/helper/ui-helper';

@Component({
    selector: 'app-stoerzeiten',
    templateUrl: './stoerzeiten.page.html',
    styleUrls: ['./stoerzeiten.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoerzeitenPage implements OnInit, AfterViewInit, OnDestroy {
    log = new Logger("StoerzeitenPage");

    @Input() tourStatus: TourStatus;
    @Input() auftragdetails: IAuftragdetails;

    subscriptions: Subscription[] = [];
    stoerzeitenListe: Stoerzeit[];

    auftrag: AuftragEx;
    aendernErlaubt = false;

    summe = new BehaviorSubject<number>(0);

    constructor(
        private auftragService: AuftragService,
        private modalController: ModalController,
        private popoverController: PopoverController,
        private remoteService: RemoteService,
        private cdr: ChangeDetectorRef) { }

    async ionViewWillEnter() {
        this.log.debug('ionViewWillEnter');
    }

    ionViewDidEnter() {
        App.current.backAction = () => this.onAbbrechen();
    }

    async ionViewWillLeave() {
        this.log.debug('ionViewWillLeave');
        App.current.backAction = null;
        App.loading(false);
    }

    async ngOnInit() {
        
        UiHelper.showErrorLight('TODO 2');

        // if (this.auftragdetails) {
        //     // Störzeiten zum Auftrag
        //     this.auftrag = this.auftragdetails.auftrag;
        //     this.aendernErlaubt = this.auftrag.Auftragsstatus < Auftragsstatus.Abgeschlossen;

        //     if (!this.auftrag.Stoerzeiten) {
        //         this.auftrag.Stoerzeiten = [];
        //     }

        //     this.stoerzeitenListe = [...this.auftrag.Stoerzeiten];
        // } else if (this.tourStatus) {
        //     // Störzeiten zur Tour
        //     this.aendernErlaubt = true;

        //     if (!this.tourStatus.Stoerzeiten) {
        //         this.tourStatus.Stoerzeiten = [];
        //     }

        //     this.stoerzeitenListe = [...this.tourStatus.Stoerzeiten];
        // }

        // if (!this.stoerzeitenListe) {
        //     this.stoerzeitenListe = [];
        // }

        // this.aktualisieren();

        // this.cdr.detectChanges();
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];
    }

    async onStoerzeitSelected(item: Stoerzeit) {
        this.log.debug('onStoerzeitSelected');

        UiHelper.showErrorLight('TODO 1');

        // const modal = await this.popoverController.create({
        //     component: StoerzeitEditPage,
        //     animated: false,
        //     backdropDismiss: true,
        //     cssClass: 'stoerzeit-popover',
        //     componentProps: {
        //         'stoerzeit': item,
        //         'typ': item.Typ,
        //         'isAendern': true
        //     }
        // });

        // await modal.present();

        // const result = await modal.onDidDismiss();

        // if (result.data) {
        //     if (result.data === 'loeschen') {
        //         if (this.auftrag) {
        //             const idx = this.auftrag.Stoerzeiten.findIndex(p => p.Guid == item.Guid);

        //             if (idx >= 0) {
        //                 this.auftrag.Stoerzeiten.splice(idx, 1);

        //                 this.auftragService.speichereAuftrag(this.auftrag);
        //             }
        //         } else if (this.tourStatus) {
        //             const idx = this.tourStatus.Stoerzeiten.indexOf(item);

        //             if (idx >= 0) {
        //                 this.tourStatus.Stoerzeiten.splice(idx, 1);

        //                 // TODO
        //             }
        //         }

        //     } else {
        //         // speichern
        //         const neueStoerzeit = result.data as Stoerzeit;

        //         item.Bemerkung = neueStoerzeit.Bemerkung;
        //         item.Key = neueStoerzeit.Key;
        //         item.Name = neueStoerzeit.Name;
        //         item.Dauer = neueStoerzeit.Dauer;

        //         if (this.auftrag) {
        //             this.auftragService.speichereAuftrag(this.auftrag);
        //         }
        //     }

        //     this.aktualisieren();
        //     this.cdr.detectChanges();
        // }
    }

    onAbbrechen() {
        this.dismiss();
    }

    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss(false);
    }

    async onNeueStoerzeit() {
        this.log.debug('onNeueStoerzeit');

        if (!this.aendernErlaubt) {
            return;
        }

        UiHelper.showErrorLight('TODO 4');

        // let typ: string;

        // if (this.auftrag) {
        //     typ = 'Auftrag';
        // } else {
        //     typ = 'Tour';
        // }

        // const modal = await this.popoverController.create({
        //     component: StoerzeitEditPage,
        //     animated: false,
        //     backdropDismiss: true,
        //     cssClass: 'stoerzeit-popover',
        //     componentProps: {
        //         'stoerzeit': {} as Stoerzeit,
        //         'typ': typ
        //     }
        // });

        // await modal.present();

        // const result = await modal.onDidDismiss();

        // if (result.data) {
        //     const stoerzeit = result.data as Stoerzeit;

        //     if (stoerzeit.Name) {
        //         if (this.auftrag) {
        //             this.auftrag.Stoerzeiten.push(stoerzeit);
        //             this.stoerzeitenListe = [...this.auftrag.Stoerzeiten];

        //             this.auftragService.speichereAuftrag(this.auftrag);
        //         } else if (this.tourStatus) {
        //             this.tourStatus.Stoerzeiten.push(stoerzeit);
        //             this.stoerzeitenListe = [...this.tourStatus.Stoerzeiten];
        //         }
        //     }

        //     this.aktualisieren();
        //     this.cdr.detectChanges();
        // }
    }

    private aktualisieren() {
        
        UiHelper.showErrorLight('TODO 5');

        // if (this.auftrag) {
        //     this.stoerzeitenListe = [...this.auftrag.Stoerzeiten];
        // } else if (this.tourStatus) {
        //     this.stoerzeitenListe = [...this.tourStatus.Stoerzeiten];
        // }

        // if (this.stoerzeitenListe) {
        //     let summe = 0;

        //     if (this.stoerzeitenListe.length) {
        //         summe = this.stoerzeitenListe.filter(p => p.Dauer).map(p => p.Dauer).reduce((prev, curr) => prev + curr);
        //     }

        //     this.summe.next(summe);
        // }
    }
}
