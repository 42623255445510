

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { RemoteService } from './remote.service';

import { Subject, BehaviorSubject } from 'rxjs';
import { Logger } from './helper/app-error-logger';
import { SystemService } from './system.service';
import { StammdatenService } from './stammdaten.service';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { App } from './helper/app';
import { Mutex } from 'async-mutex';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { BerichtEx } from './model/model';
import { UiHelper } from './helper/ui-helper';
import { Utils } from './helper/utils';
import { AppStorage } from './helper/app-storage';

declare var cordova: any;

const STORAGHE_PREFIX = 'bericht-'

const log = new Logger('BerichteService');

@Injectable({
    providedIn: 'root'
})
export class BerichteService {

    private isReady = new BehaviorSubject<boolean>(false);
    private mutex = new Mutex();

    listChanged = new Subject<void>();

    app = App.current;

    constructor(
        private storage: Storage,
        private remoteService: RemoteService,
        private stammdatenService: StammdatenService,
        private alertCtrl: AlertController,
        private modalController: ModalController,
        private router: Router,
        private nav: NavController,
        private backgroundMode: BackgroundMode,
        private toastController: ToastController,
        private systemService: SystemService) {
    }

    async init() {
        await this.storage.ready();

        this.isReady.next(true);

        this.listChanged.next();

        // Einal je 10 Minuten die Daten bereinigen
        setInterval(() => this.bereinigeDaten(), 10 * 60 * 1000);

        // 30 Sekunden nach Start die Daten bereinigen
        setTimeout(() => this.bereinigeDaten(), 30000);

        App.current.configRefreshed.subscribe(() => this.onConfigRefreshed());
    }

    async loescheDaten() {
        const release = await this.mutex.acquire();

        try {
            const keys = await this.storage.keys();
            let changed = false;

            for (const key of keys.filter(p => p.startsWith(STORAGHE_PREFIX))) {
                await this.storage.remove(key);
                changed = true;
            }

            if (changed) {
                this.listChanged.next();
            }
        } finally {
            release();
        }
    }

    async bereinigeDaten() {
        log.debug('bereinigeDaten START');

        // const release = await this.mutex.acquire();

        // try {
        //     const keys = await this.storage.keys();
        //     let changed = false;

        //     const tage = Utils.parseInt(AppConfig.current.einstellungen.GlasAnalyseTageSpeichern);

        //     if (tage > 0) {
        //         const timeout = moment().add(-tage, 'days').startOf('day').toISOString();

        //         for (const key of keys.filter(p => p.startsWith(STORAGHE_PREFIX))) {
        //             const item = await this.storage.get(key) as GlasAnalyseEx;

        //             if (item) {
        //                 if (item.DatumIso < timeout) {
        //                     log.debug('Lösche Glas-Analyse: ' + JSON.stringify(item));

        //                     await this.storage.remove(key);
        //                     changed = true;
        //                 }
        //             }
        //         }
        //     }

        //     if (changed) {
        //         this.listChanged.next();
        //     }
        // } finally {
        //     release();
        // }

        log.debug('bereinigeDaten FERTIG');
    }

    async getAlleBerichte(): Promise<BerichtEx[]> {
        const release = await this.mutex.acquire();

        try {
            const keys = await this.storage.keys();
            const resultList: BerichtEx[] = [];

            // const tage = Utils.parseInt(AppConfig.current.einstellungen.GlasAnalyseTageSpeichern);

            // const timeout = tage > 0
            //     ? moment().add(-tage, 'days').startOf('day').toISOString()
            //     : '';

            // for (const key of keys.filter(p => p.startsWith(STORAGHE_PREFIX))) {
            //     const item = await this.storage.get(key) as GlasAnalyseEx;

            //     if (item && item.DatumIso >= timeout) {
            //         this.fixEntity(item);

            //         resultList.push(item);
            //     }
            // }

            return resultList;
        } finally {
            release();
        }
    }

    // async getGlasAnalyse(guid: string): Promise<BerichtEx> {
    //     await this.ready();

    //     const entity = await this.storage.get(STORAGHE_PREFIX + guid);

    //     return this.fixEntity(entity);
    // }

    fixEntity(item: BerichtEx): BerichtEx {
        if (!item) {
            return item;
        }

        // try {
        //     if (item.Uhrzeit && item.Uhrzeit.length >= 4 && item.Uhrzeit.length <= 5 && item.Uhrzeit.includes(":")) {
        //         log.warn('Uhrzeit wird korrigiert: ' + item.Uhrzeit);

        //         // Die Uhrzeit ist kein Timestamp, sondern im Format "07:00".
        //         // Das tritt auf, wenn eine Uhrzeit manuell geändert wurde
        //         const m = moment(item.Uhrzeit, 'HH:mm');
        //         item.Uhrzeit = m.toISOString();
        //     }
        // } catch (err) {
        //     log.error(Utils.getErrorMessage(err) + ": " + JSON.stringify(item));
        // }

        // TODO

        return item;
    }

    public async ready() {
        if (this.isReady.getValue()) {
            return;
        }

        return new Promise(resolve => {
            const subscription = this.isReady.subscribe((value) => {
                if (value) {
                    subscription.unsubscribe();
                    resolve(null);
                }
            });
        });
    }

    async sendeBericht(entity: BerichtEx): Promise<boolean> {
        log.info('sendeBericht', entity);

        const fahrer = App.current.fahrer.getValue();

        if (!fahrer) {
            UiHelper.showAlert('Nicht angemeldet. Bericht senden nicht erlaubt.');
            return false;
        }

        entity.Personal = {
            Key: fahrer.Key,
            Vorname: fahrer.vorname,
            Nachname: fahrer.nachname,
            Personalnummer: fahrer.personalnummer,
        };

        entity.Datum = Utils.nowIsoDateString();
        entity.Status = 'NEU';

        await UiHelper.showLoading('Wird gesendet...');

        // Copy
        const copy: BerichtEx = JSON.parse(JSON.stringify(entity));

        if (copy.Bilder) {
            for (const bild of copy.Bilder) {
                if (bild.BildGuid) {
                    bild.Bild = await AppStorage.current.getBildBase64Data(bild.BildGuid);
                }
            }
        }

        const result = await this.remoteService.sendeBericht(copy);

        UiHelper.hideLoading();

        if (!result) {
            return false;
        }

        if (!result.success) {
            UiHelper.showError(result.message);
            return false;
        }

        return true;
    }

    onConfigRefreshed(): void {
        log.debug('onConfigRefreshed');
        // this.aktualisiereMeldungenTimer();
    }
}
