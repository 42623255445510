import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { AuftragEx, AuftragspositionEx } from 'src/app/api/model/model';
import { ModalController, IonSelect, IonSearchbar } from '@ionic/angular';
import { AuftragService } from 'src/app/api/auftrag.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { App } from 'src/app/api/helper/app';
import { Artikel, AdresseEntity, GpsPosition, MengeOptionTyp, Vertragsposition, Wiegedaten } from 'src/app/api/model/swagger-model';

import { AppConfig } from 'src/app/api/helper/app.config';
import { Utils } from 'src/app/api/helper/utils';
import { AuftragLieferungDetailsPage } from '../auftrag-lieferung-details/auftrag-lieferung-details.page';
import { IAuftragdetails } from '../auftragdetails/iauftragdetails';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { Constants } from 'src/app/api/model/constants';

@Component({
    selector: 'app-auftrag-lieferung',
    templateUrl: './auftrag-lieferung.page.html',
    styleUrls: ['./auftrag-lieferung.page.scss'],
})
export class AuftragLieferungPage implements OnInit, AfterViewInit, OnDestroy {
    log = new Logger("AuftragLieferungPage");

    @ViewChild('content', { static: false }) content: any;
    @ViewChild('wiegungenSelect', { read: IonSelect, static: false }) wiegungenSelect: IonSelect;
    @ViewChild('searchbar', { static: false }) searchbar: IonSearchbar;
    @ViewChild('auftragLieferungDetails', { static: false }) auftragLieferungDetails: AuftragLieferungDetailsPage;

    @Input() auftrag: AuftragEx;
    @Input() auftragdetails: IAuftragdetails;

    subscriptions: Subscription[] = [];
    leavWurdeAufgerufen = false;

    suchButtonAnzeigen = new BehaviorSubject<boolean>(false);
    searchbarSichtbar = new BehaviorSubject<boolean>(false);
    suchtext = '';

    dayNames = Constants.dayNames;
    uvvPruefungModal: HTMLIonModalElement;
    wiegedatenListe: Wiegedaten[] = [];
    wiegungAuswahlAuftragsposition: AuftragspositionVO;

    positionsEigenschaftenImmerAnzeigen = false;

    title = new BehaviorSubject('Lieferung');

    positionHinzufuegenErlaubt = true;

    constructor(
        private auftragService: AuftragService,
        private modalController: ModalController,
        private cdr: ChangeDetectorRef) { }

    async ngOnInit() {
        this.suchButtonAnzeigen.next(AppConfig.current.einstellungen.AuftragspositionenSuchButtonAnzeigen === 'ja');

        this.subscriptions.push(this.auftragService.aktuellerauftragWurdeGeaendert.subscribe(() => {
            this.dismiss();
        }));
    }

    ngAfterViewInit() {
        if (this.auftragLieferungDetails) {
            this.subscriptions.push(this.auftragLieferungDetails.positionHinzufuegenErlaubt.subscribe(erlaubt => {
                this.positionHinzufuegenErlaubt = true;
                this.cdr.detectChanges();
            }));
        }
    }

    ionViewDidEnter() {
        App.current.backAction = () => this.onAbbrechen();

        this.auftragService.setzeAktivenAuftrag(this.auftrag, 'lieferung');
    }

    async ionViewWillLeave() {
        this.log.debug('ionViewWillLeave');

        this.leavWurdeAufgerufen = true;

        App.current.backAction = null;

        if (this.auftragLieferungDetails) {
            this.auftragLieferungDetails.aktualisiereAuftrag();
        }

        if (this.auftragService.istAktiverAuftrag(this.auftrag)) {
            await Utils.delay(50);

            await this.auftragService.speichereAuftrag(this.auftrag);

            // this.auftragService.setzeAktivenAuftrag(null, 'lieferung exit');
        } else {
            this.log.warn('ionViewWillLeave: nicht aktiver Auftrag ' + this.auftrag.Key);
        }
    }

    /**
     * Runs when the page is about to be destroyed and have its elements removed.
     */
    ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];

        if (!this.leavWurdeAufgerufen) {
            // Das tritt normalerweise nur im wizard-Modus auf.
            // hier fehlt das ionWillLead-Event
            this.ionViewWillLeave();
        }
    }

    async onAbbrechen() {
        this.log.debug('onAbbrechen');
        await this.doSpeichern();
    }

    async onSpeichern() {
        this.log.debug('onSpeichern');
        this.doSpeichern();
    }

    async doSpeichern() {
        this.log.info('doSpeichern: ' + this.auftrag.Key, this.auftrag);
        this.dismiss();
    }

    onReset() {
    }

    dismiss() {
        this.modalController.dismiss({
            'dismissed': true
        });
    }
}

interface AuftragspositionVO {
    auftragsposition: AuftragspositionEx;
    artikel: Artikel;
    vertragsposition?: Vertragsposition;
    behaelter: BehaelterbewegungVO[];
    mengeUngueltig: boolean;
    MengeOption: MengeOptionTyp;
    StandardMenge: number;
    wiegungZuordnenAnzeigen: boolean;
    LaderaumAuswahlAktiv: boolean;
    suchtext: string;
    wiegenAktiv: boolean;
}

interface BehaelterbewegungVO {
    name: string;
    aktion: 'stellen' | 'entleeren' | 'abziehen' | 'umsetzen' | 'direktbeladung';
    behaelterNr: string;
    manuelleNummer?: string;
    behaelterNrUngueltig?: boolean;
    isScan?: boolean;

    // Zum anzeigen von "NFC aktiv"
    aktiv?: boolean;

    // Soll der Abstellort ausgewählt werden?
    abstellortAktiv?: boolean;

    // Nur temporär fpr ionic-selectable
    abstellort?: AdresseEntity;

    // Adresse des Abstellorts
    abstellortKey?: string;
    zustand?: string; // voll / leer

    position?: GpsPosition;

    changed?: boolean;
}


