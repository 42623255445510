import { Logger } from './app-error-logger';

export class ClickHelper {

    static log = new Logger('ClickHelper');

    static coordinates = [];
    static threshold = 25;
    static timeout = 2500;
    static tapDelay = 100;

    static cancelTap = true;

    static init() {

        // no touch support
        document.addEventListener("click", ClickHelper.onClickEvent, true);
        // document.addEventListener("touchstart", ClickHelper.resetCoordinates, true);
        // document.addEventListener("touchend", ClickHelper.registerCoordinates, true);
    }

    // static resetCoordinates(e: Event) {
    //     console.log('resetCoordinates', e);

    //     ClickHelper.coordinates = [];
    // }

    // static popCoordinates() {
    //     ClickHelper.coordinates.splice(0, 1);
    // }

    // static registerCoordinates(ev: TouchEvent) {
    //     console.log('registerCoordinates', ev);

    //     // touchend is triggered on every releasing finger
    //     // changed touches always contain the removed touches on a touchend
    //     // the touches object might contain these also at some browsers (firefox os)
    //     // so touches - changedTouches will be 0 or lower, like -1, on the final touchend
    //     if (ev.touches.length - ev.changedTouches.length <= 0) {
    //         const touch = ev.changedTouches[0];
    //         ClickHelper.coordinates.push([touch.clientX, touch.clientY]);

    //         setTimeout(ClickHelper.popCoordinates, ClickHelper.timeout);
    //     }
    // }

    static onClickEvent(ev: MouseEvent) {
        //// console.log('onClickEvent', ev);
        ClickHelper.cancelTap = true;

        // for (const c of ClickHelper.coordinates) {
        //     const x = c[0];
        //     const y = c[1];

        //     // within the range, so prevent the click
        //     if (Math.abs(ev.clientX - x) < ClickHelper.threshold && Math.abs(ev.clientY - y) < ClickHelper.threshold) {
        //         console.log('prevented');
        //         ev.stopPropagation();
        //         ev.preventDefault();
        //         break;
        //     }
        // }
    }

    static click(e: Event, func: () => void) {
        // ClickHelper.log.debug('click', e);

        if (e && e.type === 'tap') {
            ClickHelper.cancelTap = false;

            // Delay and wait if we receive a click event
            setTimeout(() => {
                if (!ClickHelper.cancelTap) {
                    func();
                } else {
                    ClickHelper.log.debug('tap canceled');
                }
            }, ClickHelper.tapDelay);
        } else {
        }

        func();

        if (document.activeElement instanceof HTMLElement) {
            // Verhindern dass wieder nach oben gescrollt wird zu einem Eingabefeld
            document.activeElement.blur();
        }

        if (e && e.target instanceof HTMLElement) {
            // Verhindern dass wieder nach oben gescrollt wird zu einem Eingabefeld
            // this.log.debug('focus target element');
            e.target.focus();
        }
    }

    // static ckeckClick(e: Event): Promise<boolean> {
    //     ClickHelper.log.debug('ckeckClick', e);

    //     return new Promise((resolve, reject) => {
    //         if (e.type !== 'tap') {
    //             ClickHelper.log.debug('ckeckClick != tap => true');
    //             resolve(true);
    //             return;
    //         }

    //         // Delay and wait if we receive a click event
    //         setTimeout(() => {
    //             if (ClickHelper.cancelTap) {
    //                 resolve(false);
    //             } else {
    //                 resolve(true);
    //             }
    //         }, ClickHelper.tapDelay);
    //     });
    // }
}
