import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { App } from 'src/app/api/helper/app';
import { NavController, IonInput, ToastController, ModalController } from '@ionic/angular';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { MandantFormulare, UvvPruefGegenstand, UvvPruefung, UvvPruefungEintrag, UvvKatalog, UvvEintrag, AdresseEntity, Textdatei, TextdateiTyp } from 'src/app/api/model/swagger-model';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { Camera } from '@ionic-native/camera/ngx';
import { FileHelper } from 'src/app/api/helper/file-helper';
import { File } from '@ionic-native/file/ngx';
import * as moment from 'moment';
import { ClickHelper } from 'src/app/api/helper/click-helper';
import { UvvService } from 'src/app/api/uvv.service';
import { UvvHelper } from 'src/app/api/helper/uvv-helper';
import { AppStorage } from 'src/app/api/helper/app-storage';
import { NFC, Ndef } from '@ionic-native/nfc/ngx';
import { Subscription } from 'rxjs';
import { NfcEvent, UvvPruefGegenstandEx } from 'src/app/api/model/model';
import { SystemService } from 'src/app/api/system.service';
import { AppConfig } from 'src/app/api/helper/app.config';
import { Utils } from 'src/app/api/helper/utils';

const log = new Logger('UvvRfidTauschPage');

@Component({
    templateUrl: './uvv-rfid-tausch.page.html',
    styleUrls: ['./uvv-rfid-tausch.page.scss'],
})
export class UvvRfidTauschPage implements OnInit {
    @Input() gegenstand: UvvPruefGegenstandEx;

    form: UntypedFormGroup;
    katalog: UvvKatalog;

    app = App.current;
    mandanFormulare: MandantFormulare;

    standortListe: AdresseEntity[] = [];

    geladen = false;
    startenButtonColor = 'primary';

    nfcSubscriptions: Subscription[] = [];
    nfcRegistrationId: number;

    constructor(
        public formBuilder: UntypedFormBuilder,
        private camera: Camera,
        private file: File,
        private webView: WebView,
        private stammdatenService: StammdatenService,
        private uvvService: UvvService,
        private systemService: SystemService,
        private modalController: ModalController,
        private toastController: ToastController,
        public domSanitizationService: DomSanitizer,
        private cdr: ChangeDetectorRef) {

        this.form = this.formBuilder.group({
            gegenstandNummer: ['', Validators.nullValidator],
            identNummer: ['', Validators.required],
            alteIdentNummer: ['', Validators.nullValidator]
        });
    }

    async ngOnInit() {
        log.debug('ngOnInit', this.gegenstand);

        if (this.geladen) {
            // View bereits geladen. nicht neu laden
            return;
        }

        if (this.gegenstand == null) {
            UiHelper.showError('UVV-Gegenstand nicht gesetzt');
            this.goBack();
            return;
        }

        const fahrer = App.current.fahrer.getValue();

        if (!fahrer) {
            UiHelper.showAlert('Nicht angemeldet. RFID-Tausch nicht möglich');
            this.goBack();
            return;
        }

        if (!fahrer.uvvBerechtigt) {
            UiHelper.showAlert('Sie sind nicht zur UVV-Prüfung berechtigt. Bitte kontaktieren Sie Ihren Administrator.');
            this.goBack();
            return;
        }

        this.katalog = this.uvvService.uvvKataloge.getValue().find(p => p.name == this.gegenstand.katalogName);

        if (!this.katalog) {
            UiHelper.showError(`Prüf-Katalog '${this.gegenstand.katalogName}' nicht gefunden. Bitte informieren Sie Ihren Administrator.`);
            this.goBack();
            return;
        }

        this.detectChanges();

        this.form.get('gegenstandNummer').setValue(UvvHelper.getBezeichnung(this.gegenstand));
        this.form.get('alteIdentNummer').setValue(this.gegenstand.identNummer);
        this.form.get('identNummer').setValue('');

        this.nfcRegistrationId = this.systemService.registerNfcReceiver(20, (nfcEvent) => this.verarbeiteNfcEvent(nfcEvent));

        this.geladen = true;

        this.detectChanges();
    }

    async ionViewWillEnter() {
        log.debug('ionViewWillEnter');

        await this.uvvService.ready();

        this.cdr.detectChanges();
    }

    ionViewDidEnter() {
    }

    ionViewWillLeave() {
        try {
            this.systemService.unregisterNfcReceiver(this.nfcRegistrationId);
        } catch (err) {
            log.error('ionViewWillLeave', err);
        }
    }

    async onSpeichern() {
        try {
            this.form.updateValueAndValidity();

            if (!this.form.valid) {
                UiHelper.showAlert('Formular nicht vollständig ausgefüllt');
                return;
            }

            const rfid = this.form.get('identNummer').value;

            // Prüfe ob die neue RFID-Nummer bereits in Verwendung ist
            const neueRfidPruefen = AppConfig.current.einstellungen.UvvNeueRfidPruefen;

            if (neueRfidPruefen !== 'aus') {
                const vorhandenerGegenstand = this.uvvService.getBehaelterByBehaelterNr(rfid, true);

                if (vorhandenerGegenstand && vorhandenerGegenstand.key !== this.gegenstand.key) {
                    if (neueRfidPruefen === 'fehler') {
                        UiHelper.showAlert(`RFID '${rfid}' bereits in Verwendung von '${UvvHelper.getBezeichnung(vorhandenerGegenstand)}'. Nummer kann nicht doppelt vergeben werden.`);
                        return;
                    } else if (neueRfidPruefen === 'warnung') {
                        const ok = await UiHelper.confirmJaNein(`ACHTUNG: RFID '${rfid}' bereits in Verwendung von '${UvvHelper.getBezeichnung(vorhandenerGegenstand)}'. Wirklich doppelt vergeben?`);

                        if (!ok) {
                            return;
                        }
                    }
                }
            }

            this.gegenstand.identNummer = rfid;

            await this.uvvService.speichereGegenstand(this.gegenstand);

            this.cdr.detectChanges();

            const textdatei: Textdatei = {
                typ: TextdateiTyp.UvvGegenstandAktualisiert,
                datum: moment().toISOString(),
                geraeteNummer: AppConfig.current.geraeteNummer,
                stammdaten: {
                    Uvv: [{
                        Bezeichnung: this.gegenstand.bezeichnung,
                        IdentNummer: this.gegenstand.identNummer,
                        Key: this.gegenstand.key,
                        ManuelleNummer: this.gegenstand.manuelleNummer,
                        Typ: this.gegenstand.typ,
                        LetztePruefung: this.gegenstand.letztePruefung,
                        NaechstePruefung: this.gegenstand.naechstePruefung,
                        UvvKatalog: this.gegenstand.katalogName
                    }]
                }
            };

            this.systemService.sendeTextdatei(textdatei, true);

            const toast = await this.toastController.create({
                message: 'RFID-Nummer erfolgreich gespeichert',
                duration: 1500,
                color: 'success'
            });

            toast.present();

            this.goBack();
        } catch (err) {
            UiHelper.showError(err);
        }
    }

    async onClose() {
        log.debug('onClose');
        this.goBack();
    }

    async goBack() {
        // if (this.alertAktiv) {
        //     // ignoriere Hardware-Back-Button
        //     return;
        // }

        log.debug('goBack');

        this.modalController.dismiss({
            'dismissed': true
        });
    }

    private detectChanges() {
        try {
            this.cdr.detectChanges();
        } catch (err) {
            // ignore
        }
    }

    // async onRfidScannen() {
    //     if (!App.isCordovaAvailable()) {
    //         UiHelper.showAlert('Cordova nicht verfügbar');
    //         return;
    //     }

    //     const loading = await UiHelper.presentLoading('RFIDWirdGescannt', true);

    //     loading.onDidDismiss().then(() => {
    //         this.closeRfid();
    //     });

    //     try {
    //         log.debug('addNdefListener');

    //         this.nfcSubscriptions.push(this.nfc.addNdefListener(async () => {
    //             log.debug('NFC listener added');
    //         }, (err) => {
    //             log.warn('NFC fehler', err);
    //             this.closeRfid();
    //             UiHelper.showError(err);
    //         }).subscribe(async (event) => {
    //             log.info('NFC event', event);
    //             this.verarbeiteNfcEvent(event);
    //         }));

    //         log.debug('NFC addNdefFormatableListener');

    //         this.nfcSubscriptions.push(this.nfc.addNdefFormatableListener(async () => {
    //             log.debug('NFC addNdefFormatableListener added');
    //         }, (err) => {
    //             log.warn('NFC addNdefFormatableListener fehler: ' + JSON.stringify(err), err);
    //             this.closeRfid();
    //             UiHelper.showError(err);
    //         }).subscribe(async (event) => {
    //             log.debug('NFC ndef-formatable event: ' + JSON.stringify(event));
    //             this.verarbeiteNfcEvent(event);
    //         }));
    //     } catch (err) {
    //         UiHelper.showError(err);
    //     }
    // }

    async verarbeiteNfcEvent(event: NfcEvent) {
        log.debug('verarbeiteNfcEvent', event);

        try {
            const seriennummer = event.seriennummer;
            let rfid = seriennummer;

            if (App.current.NfcPayloadVerwenden && event.payloadList.length) {
                for (const payload of event.payloadList) {
                    if (payload.length > 5) {
                        rfid = payload.trim().toUpperCase();
                    }
                }
            }

            if (rfid) {
                this.form.get('identNummer').setValue(rfid);

                this.cdr.detectChanges();
            }
        } catch (err) {
            log.error('verarbeiteNfcEvent: ' + err, err);
        }
    }
}

interface ChecklistEintragVO {
    eintrag: UvvEintrag;
    ok?: boolean;
    nok?: boolean;
    okButtonColor?: string;
    nokButtonColor?: string;
    anmerkungen?: string;
    bilder: BildVO[];
    cssClass?: string;
}

interface BildVO {
    src: string;
    bild?: string;
    guid: string;
    datum: string;
}