import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { App } from 'src/app/api/helper/app';
import { AppConfig } from 'src/app/api/helper/app.config';
import { AuftragsKomponente } from 'src/app/api/model/swagger-model';

@Component({
    selector: 'app-sidemenu',
    template: `
    <ion-header>
      <ion-toolbar>
        <ion-title class="sidemenu-title" [style.background-color]="farbe1 | async">
          ReCoMobil
          <img *ngIf="logo | async" class="logo-img" src="{{'data:image/jpg;base64,' + (logo | async)}}" />
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-menu-toggle auto-hide="false">
          <ion-item [routerDirection]="'root'" routerLink="/home">
            <ion-icon slot="start" name="home-outline"></ion-icon>
            <ion-label>{{ 'Start' | translate }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false" *ngIf="(app.fahrerAngemeldet | async) && (app.transportauftraegeVerfuegbar | async)">
          <ion-item [routerDirection]="'root'" routerLink="/auftragsliste/aktuell">
            <ion-icon slot="start" name="clipboard-outline"></ion-icon>
            <ion-label>{{ 'Transportaufträge' | translate }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false" *ngIf="(app.fahrerAngemeldet | async) && (app.hofauftraegeVerfuegbar | async)">
          <ion-item [routerDirection]="'root'" routerLink="/auftragsliste/hofchecker">
            <ion-icon slot="start" name="checkbox-outline"></ion-icon>
            <ion-label>{{ 'Hofaufträge' | translate }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false" *ngIf="(app.fahrerAngemeldet | async) && (app.nachrichtenVerfuegbar | async)">
          <ion-item [routerDirection]="'root'" routerLink="/nachrichten">
            <ion-icon slot="start" name="mail-outline"></ion-icon>
            <ion-label>{{ 'Nachrichten' | translate }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false">
          <ion-item [routerDirection]="'root'" routerLink="/einstellungen">
            <ion-icon slot="start" name="settings-outline"></ion-icon>
            <ion-label>{{ 'System' | translate }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
    `,
    styles: [`
    ion-content {
        position: absolute;
        bottom: 0;
        top: 130px;
    }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidemenuComponent implements OnInit {

    app = App.current;

    farbe1 = new BehaviorSubject<string>(null);
    logo = new BehaviorSubject<string>(null);

    constructor(
        private router: Router,
        public domSanitizationService: DomSanitizer,
        protected cdr: ChangeDetectorRef) {

        this.app.configRefreshed.subscribe(() => this.updateView());
    }

    ngOnInit() {
        this.updateView();
    }

    updateView() {
        if(AppConfig.current?.einstellungen) {
            this.logo.next(AppConfig.current.einstellungen.Logo);
            this.farbe1.next(AppConfig.current.einstellungen.Farbe1);
        }
    }
}
