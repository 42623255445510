import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
        pathMatch: 'full'
    },
    {
        path: 'start',
        loadChildren:  () => import('./home/home.module').then(m => m.HomePageModule),
    },
    {
        path: 'home',
        loadChildren:  () => import('./home/home.module').then(m => m.HomePageModule),
    },
    {
        path: 'home',
        redirectTo: 'auftragsliste',
        pathMatch: 'full'
    },
    {
        path: 'list',
        loadChildren:  () => import('./list/list.module').then(m => m.ListPageModule)
    },
    { path: 'benutzereinstellungen', loadChildren:  () => import('./benutzereinstellungen/benutzereinstellungen.module').then(m => m.BenutzerEinstellungenPageModule) },
    { path: 'einstellungen', loadChildren:  () => import('./einstellungen/einstellungen.module').then(m => m.EinstellungenPageModule) },
    { path: 'dashboard', loadChildren:  () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule) },
    { path: 'nachrichten', loadChildren:  () => import('./nachrichten/nachrichten.module').then(m => m.NachrichtenPageModule) },
    { path: 'auftragsliste', loadChildren:  () => import('./auftragsliste/auftragsliste.module').then(m => m.AuftragslistePageModule) },
    { path: 'anmelden', loadChildren:  () => import('./anmelden/anmelden.module').then(m => m.AnmeldenPageModule) },
    { path: 'tourendaten', loadChildren: () => import( './tourendaten/tourendaten.module').then(m => m.TourendatenPageModule) },
    { path: 'tour', loadChildren:  () => import('./tour/tour.module').then(m => m.TourPageModule) },
    { path: 'uvv', loadChildren:  () => import('./uvv/uvv.module').then(m => m.UvvModule) },
    { path: 'dokumente', loadChildren:  () => import('./dokumente/dokument.module').then(m => m.DokumentModule) },
    { path: 'kunden', loadChildren:  () => import('./kunden/kunden.module').then(m => m.KundenModule) },
    { path: 'smartwatch', loadChildren:  () => import('./smartwatch/smartwatch.module').then(m => m.SmartwatchModule) },
    { path: 'proglove', loadChildren:  () => import('./proglove/proglove.module').then(m => m.ProGloveModule) },
    { path: 'glasanalyse', loadChildren:  () => import('./glasanalyse/glasanalyse.module').then(m => m.GlasanalyseModule) },
    { path: 'zeiterfassung', loadChildren:  () => import('./zeiterfassung/zeiterfassung.module').then(m => m.ZeiterfassungModule) },
    { path: 'berichte', loadChildren: () => import( './berichte/berichte.module').then(m => m.BerichteModule) },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
