import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";
import { Logger } from "./app-error-logger";

const log = new Logger('MyMissingTranslationHandler');

export class MyMissingTranslationHandler implements MissingTranslationHandler {

    private seenKeys: string[] = [];

    handle(params: MissingTranslationHandlerParams) {
        if (this.seenKeys.findIndex(p => p == params.key) < 0) {
            log.debug(`Missing translation: ${params.key}`);
            this.seenKeys.push(params.key);
        }

        return params.key;
    }
}