import { Injectable } from '@angular/core';
import { RemoteService } from './remote.service';
import { AppConfig } from './helper/app.config';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { LokalesDokument, DownloadFile, FahrzeugDto, PushMessageType, PushMessageHeader, FahrzeugUpdatePushMessage, AuftragEx, PersonalEx } from './model/model';
import { Logger } from './helper/app-error-logger';
import { SystemService } from './system.service';
import { MandantFormulare, Personal, AuftragsFormular, Artikel, Checkliste, Workflow, DokumenteSyncRequest, Dokument, ChangeLogDto, ElementTyp, ChangeType, AdresseEntity, Artikelgruppe, Druckformular, Auftragstyp, Fahrzeug, Fahrzeuggruppe, Smartwatch, Adresse, Regel } from './model/swagger-model';
import { Auftragsstatus } from './model/aufteagsstatus';
import { AppStorage } from './helper/app-storage';
import { App } from './helper/app';
import { Utils } from './helper/utils';
import { FileHelper } from './helper/file-helper';
import { File, Entry } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';

import * as moment from 'moment';
import * as pako from 'pako';

import { NotificationEventResponse } from '@ionic-native/push/ngx';
import { Constants } from './model/constants';
import { AppService } from './helper/app-services';

const ONE_DAY = 24 * 60 * 60 * 1000; // ms
const ZWANZIG_STUNDEN = 20 * 60 * 60 * 1000; // ms
const EINE_STUNDE = 1 * 60 * 60 * 1000; // ms
const ZEHN_MINUTEN = 10 * 60 * 1000; // ms

const CHANGELOG_INTERVAL = ZEHN_MINUTEN;

@Injectable({
    providedIn: 'root'
})
export class StammdatenService {
    public static instance: StammdatenService = null;

    private log = new Logger("StammdatenService");

    public artikelListe = new BehaviorSubject<Artikel[]>([]);
    public artikelgruppenListe = new BehaviorSubject<Artikelgruppe[]>([]);
    public workflows = new BehaviorSubject<Workflow[]>([]);
    public formulare = new BehaviorSubject<MandantFormulare>(null);
    public checklisten = new BehaviorSubject<Checkliste[]>([]);
    public homeNavigationAdressen = new BehaviorSubject<AdresseEntity[]>([]);
    public verwerterAdressen = new BehaviorSubject<AdresseEntity[]>([]);

    private stammdatenGeladen = false;
    private stammdatenNichtAktuell = true;
    private datenLadenPromise: Promise<any>;

    private letztesChangeLogSyncDatum = 0;

    anzahlNeueDokumente = new BehaviorSubject<number>(0);

    /**
     * Liste mit allen bekannten Dokumenten.
     * ACHTUNG: Diese liste enthält auch Dokumente die für andere Fahrzeuge / Fahrer als angemeldet sind.
     * Die APP muss die richtigen Dokumente herausfiltern.
     */
    dokumente = new BehaviorSubject<LokalesDokument[]>([]);

    /**
     * Liste mit allen bekannten Regeln.
     */
    regeln = new BehaviorSubject<Regel[]>([]);

    stammdatenAktualisierenDraw = 0;

    lieferanten: Adresse[] = [];

    /**
     * Liste mit Push-Notifications die noch verarbeitet werden müssen.
     */
    // notitifcationZurVerarbeitung: NotificationEventResponse[] = [];

    constructor(
        private storage: Storage,
        private platform: Platform,
        private remoteService: RemoteService,
        private systemService: SystemService,
        private file: File) {

        StammdatenService.instance = this;

        this.systemService.stammdatenAktualisieren.subscribe(() => {
            const draw = ++this.stammdatenAktualisierenDraw;

            setTimeout(() => {
                if (draw == this.stammdatenAktualisierenDraw) {
                    this.aktualisiereAlleStammdaten('stammdatenAktualisieren');
                }
            }, 3000);
        });

        this.systemService.fahrerAktualisieren.subscribe(async () => {
            this.log.debug('fahrerAktualisieren');
            await this.invalidateDbSet('fahrer');
            // this.aktualisiereFahrerListe();
        });

        this.systemService.fahrzeugeAktualisieren.subscribe(async () => {
            this.log.debug('fahrzeugeAktualisieren');
            await this.invalidateDbSet('fahrzeuge');
            await this.invalidateDbSet('fahrzeuggruppen');
            // this.aktualisiereFahrzeugListe();
        });

        this.systemService.pushNotificationEmpfangen.subscribe(notification => {
            if (notification.additionalData) {
                const typ = notification.additionalData.typ;

                if (typ === "fahrzeug-update") {
                    this.verarbeiteFahrzeugUpdatePushNotification(notification);
                }
            }
        });

        App.current.fahrer.subscribe(f => this.syncDokumenteMitServer('fahrer geändert'));
        App.current.fahrzeug.subscribe(f => this.syncDokumenteMitServer('fahrzeug geändert'));

        this.systemService.webSocketConnected.subscribe((connected) => {
            if (connected) {
                if (this.letztesChangeLogSyncDatum < Date.now() - CHANGELOG_INTERVAL) {
                    this.syncChangeLog();
                }
            }
        });

        this.systemService.webSocketNachrichtEmpfangen.subscribe((message) => {
            if (message) {
                switch (message.t) {
                    case PushMessageType.FahrzeugUpdate:
                        this.verarbeiteFahrzeugUpdatePushMessage(message as FahrzeugUpdatePushMessage);
                        break;
                }
            }
        });

        this.systemService.changelogAktualisieren.subscribe(() => {
            this.log.debug('changelogAktualisieren event');
            this.syncChangeLog();
        });

        setInterval(() => {
            if (this.systemService.isConnectedToServer()) {
                if (this.stammdatenNichtAktuell) {
                    this.aktualisiereStammdaten('setInterval stammdatenNichtAktuell');
                }

                if (this.letztesChangeLogSyncDatum < Date.now() - CHANGELOG_INTERVAL) {
                    this.syncChangeLog();
                }
            }
        }, 60000);

        // 15 Sekunden nach Start direkt Change-Log synchronisieren
        setTimeout(() => {
            if (this.letztesChangeLogSyncDatum < Date.now() - CHANGELOG_INTERVAL) {
                this.syncChangeLog();
            }
        }, 15000);
    }

    async verarbeiteFahrzeugUpdatePushNotification(notification: NotificationEventResponse) {
        this.log.debug('verarbeiteFahrzeugUpdatePushNotification', notification);

        const gzipEntity = notification.additionalData.entity;

        if (!gzipEntity) {
            this.log.warn('verarbeiteFahrzeugUpdatePushNotification keine gzipEntity');
            return;
        }

        try {
            const docodedData = atob(gzipEntity);
            const unzipped = pako.inflate(docodedData, { to: 'string' });

            const fahrzeug: FahrzeugDto = JSON.parse(unzipped);

            this.verarbeiteFahrzeugUpdate(fahrzeug);
        } catch (err) {
            this.log.error('verarbeiteFahrzeugUpdatePushNotification: ' + Utils.getErrorMessage(err));
        }
    }

    async verarbeiteFahrzeugUpdatePushMessage(pushMessage: FahrzeugUpdatePushMessage) {
        this.log.debug('verarbeiteFahrzeugUpdatePushMessage', pushMessage);

        if (!pushMessage.Fahrzeuge) {
            this.log.warn('verarbeiteFahrzeugUpdatePushMessage keine Fahrzeuge');
            return;
        }

        try {
            for (const fahrzeug of pushMessage.Fahrzeuge) {
                this.verarbeiteFahrzeugUpdate(fahrzeug);
            }
        } catch (err) {
            this.log.error('verarbeiteFahrzeugUpdatePushMessage: ' + Utils.getErrorMessage(err));
        }
    }

    async verarbeiteFahrzeugUpdate(fahrzeug: FahrzeugDto) {
        this.log.debug('verarbeiteFahrzeugUpdate', fahrzeug);

        try {
            if (fahrzeug && fahrzeug.Key) {
                const fahrzeugListe = await this.getFahrzeugListe(false);

                const vorhandenesFahrzeug = fahrzeugListe.find(p => p.key === fahrzeug.Key);

                if (vorhandenesFahrzeug) {
                    this.log.debug(`Fahrzeug ${fahrzeug.Key} wurde aktualisiert`);

                    // Aktualisieren
                    vorhandenesFahrzeug.Abfahrtskontrolle = fahrzeug.Abfahrtskontrolle;
                    vorhandenesFahrzeug.Laderaeume = fahrzeug.Laderaeume;
                    vorhandenesFahrzeug.kennzeichen = fahrzeug.Kennzeichen;
                    vorhandenesFahrzeug.rfid = fahrzeug.Rfid;
                    vorhandenesFahrzeug.fahrzeugGruppe = fahrzeug.FahrzeugGruppe;
                    vorhandenesFahrzeug.Leergewicht = fahrzeug.Leergewicht;

                    if (App.current.getFahrzeugKey() === vorhandenesFahrzeug.key) {
                        App.current.fahrzeug.next(vorhandenesFahrzeug);
                    }

                    const angemeldetesFahrzeug: Fahrzeug = await AppStorage.current.get(Constants.ANGEMELDETES_FAHRZEUG, true, true);

                    if (angemeldetesFahrzeug && angemeldetesFahrzeug.key == vorhandenesFahrzeug.key) {
                        await AppStorage.current.set(Constants.ANGEMELDETES_FAHRZEUG, vorhandenesFahrzeug, true, true);
                    }

                    const angemeldeterAnhaenger: Fahrzeug = await AppStorage.current.get(Constants.ANGEMELDETER_ANHAENGER, true, true);

                    if (angemeldeterAnhaenger && angemeldeterAnhaenger.key == vorhandenesFahrzeug.key) {
                        await AppStorage.current.set(Constants.ANGEMELDETER_ANHAENGER, vorhandenesFahrzeug, true, true);
                    }

                    // const aktuelleTour = App.current.aktuelleTour.getValue();

                    // if (aktuelleTour && aktuelleTour.FahrzeugKey === vorhandenesFahrzeug.key) {
                    //     aktuelleTour.Fahrzeug = vorhandenesFahrzeug;
                    // }
                } else {
                    // Neu anlegen
                    this.log.debug(`Fahrzeug ${fahrzeug.Key} wurde neu angelegt`);

                    fahrzeugListe.push({
                        id: fahrzeug.Id,
                        key: fahrzeug.Key,
                        fahrzeugGruppe: fahrzeug.FahrzeugGruppe,
                        Abfahrtskontrolle: fahrzeug.Abfahrtskontrolle,
                        Laderaeume: fahrzeug.Laderaeume,
                        kennzeichen: fahrzeug.Kennzeichen,
                        rfid: fahrzeug.Rfid,
                        standardFahrer: fahrzeug.StandardFahrer,
                        Leergewicht: fahrzeug.Leergewicht
                    });
                }

                this.updateDbSet('fahrzeuge', fahrzeugListe);
            }
        } catch (err) {
            this.log.error('verarbeiteFahrzeugUpdatePushNotification: ' + Utils.getErrorMessage(err));
        }
    }

    async syncChangeLog() {
        this.log.debug('syncChangeLog');

        if (!AppConfig.current.mandant || !AppConfig.current.geraeteNummer) {
            this.log.warn('syncChangeLog wird nicht ausgeführt, da Mandant oder Gerätenummer nicht gesetzt');
            return;
        }

        this.letztesChangeLogSyncDatum = Date.now();

        try {
            let timestamp = await AppStorage.current.get('changelog-timestamp');

            if (!timestamp) {
                timestamp = 0;
            }

            const changeLogResult = await this.remoteService.getChangeLog(timestamp);

            if (changeLogResult) {
                if (changeLogResult.fullSyncRequired) {
                    await this.aktualisiereAlleStammdaten('syncChangeLog fullSyncRequired');
                } else {
                    for (const changeLog of changeLogResult.changeLogs) {
                        try {
                            await this.verarbeiteChangeLog(changeLog);
                        } catch (err) {
                            this.log.error('verarbeiteChangeLog', err);
                        }
                    }
                }

                AppStorage.current.set('changelog-timestamp', changeLogResult.timestamp);
            } else {
                this.log.warn('syncChangeLog: Fehler beim Aufruf des Server. isInternetAvailable=' + App.isInternetAvailable());
            }

        } catch (err) {
            this.log.warn('syncChangeLog', err);
        }
    }

    async verarbeiteChangeLog(changeLog: ChangeLogDto) {
        switch (changeLog.elementTyp) {
            case ElementTyp.Adresse:
                await this.verarbeiteAdresseChangeLog(changeLog);
                break;

            case ElementTyp.Personal:
                await this.verarbeitePersonalChangeLog(changeLog);
                break;

            default:
                this.log.debug('Nicht implementierter ElementTyp: ' + changeLog.elementTyp);
                break;
        }
    }

    async verarbeiteAdresseChangeLog(changeLog: ChangeLogDto) {
        switch (changeLog.changeType) {
            case ChangeType.Neu:
            case ChangeType.Geaendert:
                this.speichereAdresse(changeLog.element);
                break;

            case ChangeType.Geloescht:
                this.loescheAdresse(changeLog.key);
                break;
        }
    }

    async speichereAdresse(entity: AdresseEntity): Promise<void> {
        const adressen = await this.getAdressen();

        const idx = adressen.findIndex(p => p.Key == entity.Key);

        if (idx >= 0) {
            adressen[idx] = entity;
        } else {
            adressen.push(entity);
        }

        await AppStorage.current.set('adressen', adressen);
    }

    async loescheAdresse(key: string): Promise<void> {
        const adressen = await this.getAdressen();
        const idx = adressen.findIndex(p => p.Key == key);

        if (idx >= 0) {
            Utils.removeFromArray(adressen, idx);
        }

        await AppStorage.current.set('adressen', adressen);
    }

    async verarbeitePersonalChangeLog(changeLog: ChangeLogDto) {
        switch (changeLog.changeType) {
            case ChangeType.Neu:
            case ChangeType.Geaendert:
                if (changeLog.element) {
                    this.speicherePersonal(changeLog.element);
                } else {
                    this.log.warn('Personal Changelog ohne element empfangen: ' + changeLog.key)
                }
                break;

            case ChangeType.Geloescht:
                this.loeschePersonal(changeLog.key);
                break;
        }
    }

    async speicherePersonal(entity: Personal): Promise<void> {
        const list = await this.getFahrerListe(false);

        const idx = list.findIndex(p => p.personalnummer == entity.personalnummer);

        if (idx >= 0) {
            list[idx] = entity;
        } else {
            list.push(entity);
        }

        const angemeldeterFahrer = App.current.fahrer.getValue();
        if (angemeldeterFahrer && angemeldeterFahrer.personalnummer == entity.personalnummer) {
            App.current.fahrer.next(entity);
        }

        this.updateDbSet('fahrer', list);
    }

    async loeschePersonal(personalnummer: string): Promise<void> {
        const list = await this.getFahrerListe(false);
        const idx = list.findIndex(p => p.personalnummer == personalnummer);

        if (idx >= 0) {
            Utils.removeFromArray(list, idx);
        }

        this.updateDbSet('fahrer', list);
    }

    async init(): Promise<void> {
        this.log.info('init');

        try {
            // Hier kein "await". Ansonsten verzögert sich der App-Start. Es soll nur ein Update angestoßen werden, falls notwendig
            this.getAlleArtikel();
            this.ladeDatenVonStorage();
        } catch (err) {
            this.log.error('init', err);
        }
    }

    async ladeDatenVonStorage(): Promise<void> {
        if (!this.datenLadenPromise) {
            this.log.info("Lade Stammdaten vom Storage...");

            this.datenLadenPromise = new Promise(async resolve => {
                let artikelgruppen = await AppStorage.current.get('artikelgruppen');
                let artikelListe = await AppStorage.current.get('artikelListe');
                let workflows = await AppStorage.current.get('workflows');
                let formulare = await AppStorage.current.get('formulare');
                let checklisten = await AppStorage.current.get('checklisten');
                let adressen = await AppStorage.current.get('adressen');
                let dokumente = await AppStorage.current.get('dokumente');
                let druckformulare = await AppStorage.current.get('druckformulare');
                let regeln = await AppStorage.current.get('regeln');

                if (!artikelgruppen) {
                    artikelgruppen = [];
                }
                if (!artikelListe) {
                    artikelListe = [];
                }
                if (!workflows) {
                    workflows = [];
                }
                if (!formulare) {
                    formulare = [];
                }
                if (!checklisten) {
                    checklisten = [];
                }
                if (!adressen) {
                    adressen = [];
                }
                if (!dokumente) {
                    dokumente = [];
                }
                if (!druckformulare) {
                    druckformulare = [];
                }

                if (!regeln) {
                    regeln = [];
                }

                // Lösche fehlerhafte Einträge
                dokumente = dokumente.filter(p => p != null);

                this.artikelListe.next(artikelListe);
                this.artikelgruppenListe.next(artikelgruppen);
                this.workflows.next(workflows);
                this.formulare.next(formulare);
                this.checklisten.next(checklisten);
                this.dokumente.next(dokumente);
                this.regeln.next(regeln);

                this.aktualisiereListen();

                resolve(null);
            });
        }

        await this.datenLadenPromise;

        this.log.debug("Lade Stammdatenn vom Storage fertig");
    }

    getAlleArtikel(): BehaviorSubject<Artikel[]> {
        // if (this.artikelListe.getValue().length === 0) {
        //     this.log.info('artikelListe ist leer. jetzt laden');
        //     this.ladeArtikelList();
        // }

        return this.artikelListe;
    }

    async getMandantFormulare(): Promise<MandantFormulare> {
        let formulare = this.formulare.getValue();

        if (formulare) {
            return formulare;
        }

        formulare = await AppStorage.current.get('formulare');

        if (formulare) {
            return formulare;
        }

        await this.aktualisiereStammdaten('getMandantFormulare');

        return this.formulare.getValue();
    }

    async getAuftragsFormularKonfiguration(auftrag: AuftragEx, darstellung: string = ''): Promise<AuftragsFormular> {
        if (!auftrag) {
            this.log.error('getAuftragsFormularKonfiguration: Parameter auftrag darf nicht null sein');
            return null;
        }

        let workflowName = auftrag.WorkflowName;

        if (darstellung === 'beladung') {
            workflowName = 'Beladung';
        }

        const workflow = await this.getWorkflow(workflowName);

        let formularKonfiguration;

        if (workflow && workflow.Formular && workflow.Formular.komponenten && workflow.Formular.komponenten.length) {
            // Verwende das Formular aus dem Workflow.
            // Das sollte in Zukunft immer verwendet werden!
            formularKonfiguration = workflow.Formular;

            this.log.debug('getAuftragsFormularKonfiguration: Verwende Formular aus Workflow ' + workflow.name);
        }

        const auftragstyp = auftrag.Auftragstyp;

        if (!formularKonfiguration) {
            // Fallback für alte Konfigurationen
            this.log.warn('getAuftragsFormularKonfiguration: Fallback auf Mandant-Formulare');
            const mandanFormulare = await this.getMandantFormulare();

            if (mandanFormulare) {
                if (auftragstyp == Auftragstyp.Transportauftrag) {
                    formularKonfiguration = mandanFormulare.transportauftrag;
                } else if (auftragstyp == Auftragstyp.Hofchecker) {
                    formularKonfiguration = mandanFormulare.hofauftrag;
                } else if (auftragstyp == Auftragstyp.Kommissionierung) {
                    formularKonfiguration = mandanFormulare.kommissionierung;
                }
            }

        }
        if (!formularKonfiguration || !formularKonfiguration.komponenten) {
            this.log.warn('getAuftragsFormularKonfiguration: Fallback auf Standard-Formular');
            formularKonfiguration = this.erstelleStandardFormulareKonfiguration(auftragstyp);
        }

        return formularKonfiguration;
    }

    private erstelleStandardFormulareKonfiguration(auftragstyp: Auftragstyp): AuftragsFormular {
        if (auftragstyp == Auftragstyp.Hofchecker) {
            return {
                komponenten: [
                    {
                        sort: 1,
                        name: 'Fahrzeug-Kennzeichen',
                    },
                    {
                        sort: 2,
                        name: 'Abholadresse',
                        einstellungen: [
                            { key: 'Name', wert: 'ja' },
                            { key: 'Strasse', wert: 'ja' },
                            { key: 'Ort', wert: 'ja' },
                            { key: 'PLZ', wert: 'ja' },
                        ]
                    },
                    {
                        sort: 3,
                        name: 'Lieferung',
                        einstellungen: [
                            { key: 'Menge', wert: 'ja' },
                            { key: 'Einheit', wert: 'ja' },
                            { key: 'Bezeichnung', wert: 'ja' },
                            { key: 'Brutto', wert: 'nein' },
                        ]
                    },
                    {
                        sort: 4,
                        name: 'Hinweistexte',
                        einstellungen: []
                    },
                    {
                        sort: 5,
                        name: 'Bilder',
                        einstellungen: []
                    },
                    {
                        sort: 6,
                        name: 'Anmerkungen',
                        einstellungen: []
                    },
                    {
                        sort: 7,
                        name: 'Unterschrift',
                        einstellungen: []
                    },
                    {
                        sort: 8,
                        name: 'Statusmeldungen',
                        einstellungen: []
                    },
                ]
            };
        } else {
            return {
                komponenten: [
                    {
                        sort: 2,
                        name: 'Abholadresse',
                        einstellungen: [
                            { key: 'Name', wert: 'ja' },
                            { key: 'Strasse', wert: 'ja' },
                            { key: 'Ort', wert: 'ja' },
                            { key: 'PLZ', wert: 'ja' },
                        ]
                    },
                    {
                        sort: 3,
                        name: 'Lieferung',
                        einstellungen: [
                            { key: 'Menge', wert: 'ja' },
                            { key: 'Einheit', wert: 'ja' },
                            { key: 'Bezeichnung', wert: 'ja' },
                            { key: 'Brutto', wert: 'nein' },
                        ]
                    },
                    {
                        sort: 4,
                        name: 'Hinweistexte',
                        einstellungen: []
                    },
                    {
                        sort: 5,
                        name: 'Bilder',
                        einstellungen: []
                    },
                    {
                        sort: 6,
                        name: 'Anmerkungen',
                        einstellungen: []
                    },
                    {
                        sort: 7,
                        name: 'Unterschrift',
                        einstellungen: []
                    },
                    {
                        sort: 8,
                        name: 'Statusmeldungen',
                        einstellungen: []
                    },
                ]
            };
        }
    }

    async getWorkflow(workflowName: string): Promise<Workflow> {
        if (!workflowName) {
            this.log.error('workflowName nicht gesetzt. Verwende Standard-Workflow !');
            workflowName = 'Standard';
        }

        let workflow = this.workflows.getValue().find(p => p.name == workflowName);

        if (!workflow) {
            const dbWorkflows = await AppStorage.current.get('workflows') as Workflow[];

            if (dbWorkflows) {
                workflow = dbWorkflows.find(p => p.name == workflowName);
            }
        }

        if (!workflow) {
            if (this.systemService.isConnectedToServer()) {
                // Noch keine Workflows in der lokalen DB gefunden. Jetzt vom Server aktualisieren
                await this.aktualisiereStammdaten('getWorkflow worklow nicht gefunden');
            }

            workflow = this.workflows.getValue().find(p => p.name == workflowName);
        }

        if (!workflow) {
            this.log.error('Kein Workflow gefunden. Erstelle DUMMY-Workflow: ' + workflowName);

            // Fallback
            workflow = {
                name: 'Standard',
                fallback: true,
                einstellungen: {},
                status: [
                    {
                        key: 'Start',
                        name: 'Start',
                        icon: 'play',
                        sortierung: 0,
                        geopositionSenden: true,
                        bildErforderlich: false,
                        anmerkungErforderlich: false,
                        auftragsstatus: Auftragsstatus.InBearbeitung
                    } as any,
                    {
                        key: 'Fertig',
                        name: 'Fertig',
                        icon: 'checkmark',
                        sortierung: 90,
                        auftragsdatenSenden: true,
                        bilderSenden: true,
                        unterschriftSenden: true,
                        unterschriftErforderlich: true,
                        auftragAbschliessen: true,
                        auftragsstatus: Auftragsstatus.Abgeschlossen
                    } as any
                ]
            };
        }

        return workflow;
    }

    private async ladeArtikelList(): Promise<void> {
        // Pruefe ob die Artikel-Liste schon in die interne Datenbank geladen wurde. Wenn ja, diese als Standard verwenden
        const dbList = await AppStorage.current.get('artikelListe') as Artikel[];

        if (!dbList || !this.stammdatenGeladen) {
            // Noch keine Artikel in der lokalen DB gefunden. Jetzt vom Server aktualisieren
            await this.aktualisiereStammdaten('ladeArtikelList');
        } else {
            // Verwende die Artikel aus der internen DB
            this.artikelListe.next(dbList);
        }
    }

    public async loescheStammdaten(): Promise<void> {
        this.log.info('loescheStammdaten');

        try {
            await AppStorage.current.set('artikelListe', []);
            await AppStorage.current.set('workflows', []);
            await AppStorage.current.set('formulare', []);
            await AppStorage.current.set('checklisten', []);
            await AppStorage.current.set('dokumente', []);
            await AppStorage.current.set('adressen', []);
            await AppStorage.current.set('artikelgruppen', []);
            await AppStorage.current.set('druckformulare', []);
            await AppStorage.current.set('regeln', []);

            this.artikelgruppenListe.next([]);
            this.artikelListe.next([]);
            this.workflows.next([]);
            this.checklisten.next([]);
            this.formulare.next(null);
            this.dokumente.next([]);
            this.regeln.next([]);

            AppStorage.current.set('changelog-timestamp', 0);

            this.stammdatenGeladen = false;
            this.stammdatenNichtAktuell = true;
        } catch (err) {
            this.log.error('loescheStammdaten', err);
        }
    }

    /**
     * Aktualisiert alle Stammdaten
     */
    async aktualisiereAlleStammdaten(info: string): Promise<void> {
        this.log.debug('aktualisiereAlleStammdaten: ' + info);

        this.stammdatenNichtAktuell = true;

        if (this.systemService.isConnectedToServer()) {
            this.log.debug('stammdatenAktualisieren');
            await this.aktualisiereStammdaten('aktualisiereAlleStammdaten');
            await this.aktualisiereFahrerListe();
            await this.aktualisiereFahrzeuggruppenListe();
            await this.aktualisiereFahrzeugListe();
            await this.syncDokumenteMitServer('aktualisiereAlleStammdaten');
        } else {
            this.log.debug('stammdatenAktualisieren (nicht mit server verbunden)');
        }
    }

    public async aktualisiereStammdaten(info: string, forceFullSync = false): Promise<void> {
        this.log.info('aktualisiereStammdaten: ' + info + ', forceFullSync' + forceFullSync);

        try {
            const appConfig = await this.systemService.getAppConfig();

            if (!appConfig.mandant || !appConfig.geraeteNummer) {
                // noch nicht initialisiert
                return;
            }

            let timestamp: number = await AppStorage.current.get('stammdaten-timestamp');

            if (!timestamp) {
                timestamp = 0;
            } else {
                // Timestamp etwas zurücksetzen, um eventuelle dazwischen gespeicherte Daten nicht zu verpassen
                timestamp -= 5000;
            }

            if (forceFullSync) {
                timestamp = 0;
            }

            const typ = 'agr-art-wrk-frm-chk-adr-drck-reg';

            const stammdatenResult = await this.remoteService.syncStammdaten(typ, timestamp);

            if (!stammdatenResult) {
                this.log.warn('aktualisiereStammdaten: Keien Daten empfangen');
                return;
            }

            this.log.debug('stammdatenResult', stammdatenResult);

            if (stammdatenResult.artikelgruppen) {
                await AppStorage.current.set('artikelgruppen', stammdatenResult.artikelgruppen);
            }

            if (stammdatenResult.artikel) {
                await AppStorage.current.set('artikelListe', stammdatenResult.artikel);
            }

            if (stammdatenResult.workflows) {
                await AppStorage.current.set('workflows', stammdatenResult.workflows);
            }

            if (stammdatenResult.formulare) {
                await AppStorage.current.set('formulare', stammdatenResult.formulare);
            }

            if (stammdatenResult.checklisten) {
                await AppStorage.current.set('checklisten', stammdatenResult.checklisten);
            }

            if (stammdatenResult.adressen) {
                await AppStorage.current.set('adressen', stammdatenResult.adressen);
            }

            if (stammdatenResult.druckformulare) {
                await AppStorage.current.set('druckformulare', stammdatenResult.druckformulare);
            }

            if (stammdatenResult.regeln) {
                await AppStorage.current.set('regeln', stammdatenResult.regeln);
            }

            if (stammdatenResult.artikelgruppen) {
                this.artikelgruppenListe.next(stammdatenResult.artikelgruppen);
            }

            if (stammdatenResult.artikel) {
                this.artikelListe.next(stammdatenResult.artikel);
            }

            if (stammdatenResult.workflows) {
                this.workflows.next(stammdatenResult.workflows);
            }

            if (stammdatenResult.formulare) {
                this.formulare.next(stammdatenResult.formulare);
            }

            if (stammdatenResult.checklisten) {
                this.checklisten.next(stammdatenResult.checklisten);
            }

            if (stammdatenResult.regeln) {
                this.regeln.next(stammdatenResult.regeln);
            }

            AppStorage.current.set('stammdaten-timestamp', stammdatenResult.timestamp);
            AppStorage.current.set('changelog-timestamp', stammdatenResult.timestamp);

            this.aktualisiereListen();

            this.stammdatenGeladen = true;
            this.stammdatenNichtAktuell = false;
        } catch (err) {
            this.log.error('aktualisiereStammdaten: ' + Utils.getErrorMessage(err));
        }
    }

    async aktualisiereListen(): Promise<void> {
        const adressen = await this.getAdressen();

        this.homeNavigationAdressen.next(adressen.filter(p => p.IsNavigationsZiel));
        this.verwerterAdressen.next(adressen.filter(p => p.IsVerwerter));
    }

    /**
     * Lädt die Liste mit Fahrern neu vom Server
     */
    public async aktualisiereFahrerListe(): Promise<boolean> {
        this.log.info("aktualisiereFahrerListe");

        try {
            const fahrerListe = await this.remoteService.getAlleFahrer();

            if (fahrerListe) {
                this.updateDbSet('fahrer', fahrerListe);

                const angemeldeterFahrer = App.current.fahrer.getValue();
                if (angemeldeterFahrer) {
                    const neuerFahrer = fahrerListe.find(p => p.personalnummer == angemeldeterFahrer.personalnummer);

                    if (neuerFahrer) {
                        App.current.fahrer.next(neuerFahrer);
                    }
                }

                return true;
            }
        } catch (err) {
            this.log.error('aktualisiereFahrerListe', err);
        }

        return false;
    }

    /**
     * Lädt die Liste mit Fahrzeugen neu vom Server
     */
    public async aktualisiereFahrzeugListe(): Promise<boolean> {
        this.log.info("aktualisiereFahrzeugListe");

        try {
            const fahrzeuge = await this.remoteService.getAlleFahrzeuge();

            if (fahrzeuge) {
                this.updateDbSet('fahrzeuge', fahrzeuge);
                return true;
            }
        } catch (err) {
            this.log.error('aktualisiereFahrzeugListe', err);
        }

        return false;
    }

    /**
     * Lädt die Liste mit Fahrzeug´gruppen neu vom Server
     */
    public async aktualisiereFahrzeuggruppenListe(): Promise<boolean> {
        this.log.info("aktualisiereFahrzeuggruppenListe");

        try {
            const fahrzeuggruppen = await this.remoteService.getAlleFahrzeuggruppen();

            if (fahrzeuggruppen) {
                this.updateDbSet('fahrzeuggruppen', fahrzeuggruppen);
                return true;
            }
        } catch (err) {
            this.log.error('aktualisiereFahrzeuggruppenListe: ' + Utils.getErrorMessage(err), err);
        }

        return false;
    }

    public async syncDokumenteMitServer(info: string): Promise<boolean> {
        this.log.info("syncDokumenteMitServer: " + info);

        await this.datenLadenPromise;

        if (!App.current.dokumenteVerfuegbar.getValue()) {
            return;
        }

        try {
            const request: DokumenteSyncRequest = {
                fahrerKey: '',
                fahrzeugKey: ''
            };

            const fahrer = App.current.fahrer.getValue();
            const fahrzeug = App.current.fahrzeug.getValue();

            if (fahrer) {
                request.fahrerKey = fahrer.Key;
            }

            if (fahrzeug) {
                request.fahrzeugKey = fahrzeug.key;
            }

            const result = await this.remoteService.syncDokumente(request);

            this.log.debug('syncDokumenteMitServer result', result);

            if (result?.dokumente) {
                // Prüfe welche Dateien schon bekannt und vorhanden sind. Diese müssen nicht erneut heruntergeladen werden
                const lokaleDokumente = this.dokumente.getValue();

                for (const dokument of result.dokumente) {
                    if (!dokument) {
                        this.log.warn('dokument darf nicht NULL sein');
                        continue;
                    }

                    const index: number = lokaleDokumente.findIndex(p => p?.id == dokument.id);

                    if (index >= 0) {
                        this.log.debug('Lokales Dokument gefunden für: ' + dokument.dateiname);

                        const lokalesDokument = lokaleDokumente[index];

                        // Prüfe ob das Dokument geändert wurde. Wenn ja, muss es neu herunterladen werden
                        if (!Utils.areEqual(lokalesDokument, dokument, 'filePath', 'lesebestaetigungen')) {
                            this.log.debug('Lokales Dokument ist nicht identisch mit Server-Dokument: ' + dokument.dateiname);

                            // Dokument hat sich geändert
                            // Lösche die Datei (falls vorhanden). Wird im Anschluss dann wieder heruntergeladen
                            if (lokalesDokument.filePath) {
                                try {
                                    FileHelper.deleteFile(lokalesDokument.filePath);
                                } catch (err) {
                                    this.log.error('error deleteing file ' + lokalesDokument.filePath + ': ' + err.message);
                                }
                            }

                            // Neues Dokument übernehmen
                            lokaleDokumente[index] = dokument;
                        } else {
                            this.log.debug('Lokales Dokument ist identisch mit Server-Dokument: ' + dokument.dateiname);

                            // Prüfe ob Datei schon vorhanden ist. Wenn nein, dann nochmal download anstoßen
                            const fileExists: boolean = await FileHelper.checkFile(lokalesDokument.filePath);

                            if (!fileExists) {
                                this.log.debug('Lokale Datei nicht vorhanden. Erneut download anstoßen: ' + dokument.dateiname);

                                lokaleDokumente[index] = dokument;
                                lokaleDokumente[index].lesebestaetigungen = lokalesDokument.lesebestaetigungen;
                            }
                        }
                    } else {
                        // Dokument noch nicht vorhanden.
                        lokaleDokumente.push(dokument);
                    }
                }

                // Eventuell zuviel vorhandene Dokumente löschen
                this.entferneGeloeschteDokumente(lokaleDokumente, result.dokumente, request);

                // Erstmal speichern...
                await this.speichereDokumente(lokaleDokumente);

                this.aktualisiereAnzahlNeueDokumente();

                return true;
            }
        } catch (err) {
            this.log.error('syncDokumenteMitServer: ' + Utils.getErrorMessage(err), err);
        }

        this.aktualisiereAnzahlNeueDokumente();

        return false;
    }

    entferneGeloeschteDokumente(lokaleDokumente: LokalesDokument[], remoteDokumente: Dokument[], request: DokumenteSyncRequest) {
        this.log.debug('entferneGeloeschteDokumente');

        if (request.fahrerKey) {
            const lokaleFahrerDokumente = lokaleDokumente.filter(p => p.typ === 'fahrer' && p.typKey === request.fahrerKey);

            for (const dok of lokaleFahrerDokumente) {
                if (!remoteDokumente.find(p => p.id === dok.id)) {
                    this.loescheDokumentById(lokaleDokumente, dok.id);
                }
            }
        }

        if (request.fahrzeugKey) {
            const lokaleFahrzeugDokumente = lokaleDokumente.filter(p => p.typ === 'fahrzeug' && p.typKey === request.fahrzeugKey);

            for (const dok of lokaleFahrzeugDokumente) {
                if (!remoteDokumente.find(p => p.id === dok.id)) {
                    this.loescheDokumentById(lokaleDokumente, dok.id);
                }
            }
        }

        const lokaleRestDokumente = lokaleDokumente.filter(p => p.typ === 'mandant' || p.typ === 'geraet');

        for (const dok of lokaleRestDokumente) {
            if (!remoteDokumente.find(p => p.id === dok.id)) {
                this.loescheDokumentById(lokaleDokumente, dok.id);
            }
        }
    }

    loescheDokumentById(dokumente: LokalesDokument[], id: string) {
        this.log.info('loescheDokumentById: ' + id);

        const index = dokumente.findIndex(p => p.id === id);

        if (index >= 0) {
            const dokument = dokumente[index];

            dokumente.splice(index, 1);

            if (dokument.filePath) {
                try {
                    FileHelper.deleteFile(dokument.filePath);
                } catch (err) {
                    this.log.error('error deleting file ' + dokument.filePath + ': ' + err.message);
                }
            }
        }
    }

    public async speichereAktuelleDokument() {
        this.log.debug('speichereAktuelleDokument');

        await AppStorage.current.set('dokumente', this.dokumente.getValue());

        this.aktualisiereAnzahlNeueDokumente();
    }

    private async speichereDokumente(dokumente: LokalesDokument[]) {
        // Lösche fehlerhafte Einträge
        dokumente = dokumente.filter(p => p != null);

        for (const dokument of dokumente) {
            if (!dokument.filePath) {
                let typKey = dokument.typKey;

                if (!typKey) {
                    typKey = '_';
                }

                dokument.filePath = this.file.cacheDirectory + 'dokumente/'
                    + dokument.typ + '/'
                    + FileHelper.removeIllegalFilenameChars(typKey) + '/'
                    + FileHelper.removeIllegalFilenameChars(dokument.dateiname);
            }
        }

        this.log.debug('speichereDokumente', dokumente);

        this.dokumente.next(dokumente);

        await AppStorage.current.set('dokumente', dokumente);

        // Nicht vorhandene Dateien herunterladen
        const downloadFileList: DownloadFile[] = [];

        for (const dokument of dokumente) {
            const fileExists: boolean = await FileHelper.checkFile(dokument.filePath);

            if (!fileExists) {
                downloadFileList.push({
                    filename: dokument.dateiname,
                    status: 0,
                    guid: dokument.dataGuid,
                    size: dokument.size,
                    errorCount: 0,
                    filePath: dokument.filePath
                });
            }
        }

        if (downloadFileList.length) {
            this.systemService.enqueueDownloadFiles(downloadFileList);
        }
    }

    public async GetFahrerByPersonalnummer(personalnummer: string, remoteLadenErlaubt: boolean): Promise<Personal> {
        this.log.debug('GetFahrerByPersonalnummer: ' + personalnummer + ' (remoteLadenErlaubt=' + remoteLadenErlaubt + ')');

        if (!personalnummer) {
            return null;
        }

        if (remoteLadenErlaubt) {
            if (App.isInternetAvailable()) {
                this.log.debug('GetFahrerByPersonalnummer isInternetAvailable = true');

                let loadingStarted = false;

                if (!App.isLoading()) {
                    App.loading(true);
                    loadingStarted = true;
                }

                // Bei Anmeldung soll der Fahrer bevorzugt neu vom Server geladen werden
                try {
                    const fahrer = await this.remoteService.getFahrerByPersonalnummer(personalnummer);

                    if (fahrer) {
                        // Stammdaten aktualisieren
                        this.speicherePersonal(fahrer);
                        return fahrer;
                    }
                } catch (err) {
                    this.log.warn('GetFahrerByPersonalnummer: ' + Utils.getErrorMessage(err), err);
                } finally {
                    if (loadingStarted) {
                        App.loading(false);
                    }
                }
            }
        }

        const alleFahrer = await this.getFahrerListe(remoteLadenErlaubt);
        const suchStr = personalnummer.trim().toLowerCase();

        let resultList = alleFahrer.filter(p => p.personalnummer && p.personalnummer.trim().toLowerCase() === suchStr);

        if (AppConfig.current.UnterMandant) {
            resultList = resultList.filter(p => !p.UnterMandant || p.UnterMandant === AppConfig.current.UnterMandant);
        }

        if (resultList.length === 0) {
            // Prüfe Login mit Key. Damit wird es ermöglicht sich explizit mit einem Benutzer für einen
            // anderen UnterMandanten anzumelden, z.B. durch Eingabe von TVA123456
            resultList = alleFahrer.filter(p => p.Key && p.Key.trim().toLowerCase() === suchStr);
        }

        if (resultList.length > 0) {
            return resultList[0];
        } else {
            return null;
        }
    }

    public async GetFahrerByRfid(rfid: string): Promise<Personal> {
        if (!rfid) {
            return null;
        }

        const alleFahrer = await this.getFahrerListe();
        const suchStr = rfid.trim().toUpperCase();

        let fahrer = alleFahrer.find(p => p.rfid && p.rfid.trim().toUpperCase() === suchStr);

        if (!fahrer && this.systemService.isConnectedToServer()) {
            fahrer = await this.remoteService.getFahrerByRfid(rfid);

            if (fahrer) {
                this.speicherePersonal(fahrer);
            }
        }

        return fahrer;
    }

    public async getFahrerListe(remoteLadenErlaubt = true): Promise<PersonalEx[]> {
        let dbSet = await this.loadDbSet<PersonalEx>('fahrer');

        if (remoteLadenErlaubt && dbSet.date < Date.now() - ZWANZIG_STUNDEN) {
            await this.aktualisiereFahrerListe();
            dbSet = await this.loadDbSet<PersonalEx>('fahrer');
        }

        for (const fahrer of dbSet.items) {
            fahrer.anzeigeName = `${fahrer.vorname} ${fahrer.nachname}`.trim();
            fahrer.text = fahrer.anzeigeName;
        }

        return dbSet.items;
    }

    async getFahrerListeSortiert(remoteLadenErlaubt = true): Promise<PersonalEx[]> {
        let liste = await this.getFahrerListe(remoteLadenErlaubt);

        // Der angemeldete Benutzer ist immer Favorit
        const aktuellerPersonalKey = App.current.getPersonalKey();

        if (aktuellerPersonalKey) {
            const personal = liste.find(p => p.Key === aktuellerPersonalKey);

            if (personal) {
                personal.istFavorite = true;
            }
        }

        // Liste kopieren, damit die ursprungsliste nicht geändert wird
        liste = [...liste];

        liste.sort((a, b) => {
            if (a.istFavorite && !b.istFavorite) {
                return -1;
            } else if (b.istFavorite && !a.istFavorite) {
                return 1;
            }

            if (a.favoriteIdx >= 0 && b.favoriteIdx >= 0) {
                return a.favoriteIdx - b.favoriteIdx;
            }

            if (a.anzeigeName < b.anzeigeName) {
                return -1;
            } else if (a.anzeigeName > b.anzeigeName) {
                return 1;
            }

            return 0;
        });

        return liste;
    }

    public async getFahrzeug(kennzeichen: string): Promise<Fahrzeug> {
        if (!kennzeichen) {
            return null;
        }

        kennzeichen = kennzeichen.trim().toLowerCase();

        const dbSet = await this.loadDbSet<Fahrzeug>('fahrzeuge');

        return dbSet.items.find(p => p.kennzeichen.trim().toLowerCase() === kennzeichen);
    }

    public async getFahrzeugListe(remoteErlaubt = true): Promise<Fahrzeug[]> {
        let dbSet = await this.loadDbSet<Fahrzeug>('fahrzeuge');

        if (remoteErlaubt && dbSet.date < Date.now() - ZWANZIG_STUNDEN) {
            await this.aktualisiereFahrzeugListe();
            dbSet = await this.loadDbSet<Fahrzeug>('fahrzeuge');
        }

        return dbSet.items;
    }

    public async getFahrzeuggruppenListe(remoteErlaubt = true): Promise<Fahrzeuggruppe[]> {
        let dbSet = await this.loadDbSet<Fahrzeuggruppe>('fahrzeuggruppen');

        if (remoteErlaubt && dbSet.date < Date.now() - ZWANZIG_STUNDEN) {
            await this.aktualisiereFahrzeuggruppenListe();
            dbSet = await this.loadDbSet<Fahrzeuggruppe>('fahrzeuggruppen');
        }

        return dbSet.items;
    }

    public async getCheckliste(name: string): Promise<Checkliste> {
        let checkliste = this.checklisten.getValue().find(p => p.name === name);

        if (!checkliste) {
            const dbChecklisten = await AppStorage.current.get('checklisten');

            if (dbChecklisten && dbChecklisten.length) {
                this.checklisten.next(dbChecklisten);
                checkliste = dbChecklisten.find(p => p.name == name);
            }
        }

        return checkliste;
    }

    public async getAdressen(): Promise<AdresseEntity[]> {
        let adressen = await AppStorage.current.get('adressen');

        if (!adressen) {
            adressen = [];
        }

        return adressen;
    }

    public async getDruckformulare(): Promise<Druckformular[]> {
        let druckformulare = await AppStorage.current.get('druckformulare');

        if (!druckformulare) {
            druckformulare = [];
        }

        return druckformulare;
    }

    // public async getRegeln(): Promise<Regel[]> {
    //     let regeln = await AppStorage.current.get('regeln');

    //     if (!regeln) {
    //         regeln = [];
    //     }

    //     return regeln;
    // }

    private async loadDbSet<T>(key: string): Promise<DbSet<T>> {
        let dbSet: DbSet<T> = await AppStorage.current.get(key);

        if (!dbSet) {
            dbSet = {
                items: [],
                date: 0
            };

            await this.saveDbSet(key, dbSet);
        }

        if (!dbSet.items) {
            dbSet.items = [];
        }

        if (!dbSet.date) {
            dbSet.date = 0;
        }

        // Null-Einträge entfernen.
        // Sollte eigentlich gar nicht vorkommen. Aber zu Reparaturzwecken hier orhanden
        dbSet.items = dbSet.items.filter(p => p !== null);

        return dbSet;
    }

    private async saveDbSet<T>(key: string, dbSet: DbSet<T>): Promise<void> {
        await AppStorage.current.set(key, dbSet);
    }

    private async updateDbSet<T>(key: string, items: T[]): Promise<void> {
        const dbSet = await this.loadDbSet<T>(key);

        dbSet.items = items;
        dbSet.date = Date.now();

        await this.saveDbSet(key, dbSet);
    }

    /**
     * Setzt das Datum des DbSets auf 0. Damit wird es als veraltet markiert und wird beim nächsten Zugriff aktualisiert
     */
    private async invalidateDbSet(key: string): Promise<void> {
        this.log.debug('invalidateDbSet: ' + key);

        const dbSet = await this.loadDbSet<any>(key);

        dbSet.date = 0;

        await this.saveDbSet(key, dbSet);
    }

    private aktualisiereAnzahlNeueDokumente() {
        this.log.debug('aktualisiereAnzahlNeueDokumente');

        let anzahl = 0;

        const now = moment();
        const personalnummer = App.current.getPersonalnummer();

        for (const d of this.dokumente.getValue()) {
            if (!d) {
                continue;
            }

            if (d.Verfallsdatum) {
                const verfallsdatum = moment(d.Verfallsdatum);

                if (verfallsdatum.isBefore(now, "day")) {
                    // Datei ist nicht mehr relevant
                    continue;
                }
            }

            if (!d.lesebestaetigungen) {
                d.lesebestaetigungen = [];
            }

            if (d.Leseverpflichtung) {
                const lesebestaetigung = d.lesebestaetigungen.find(p => p.personalnummer == personalnummer);

                if (!lesebestaetigung || !lesebestaetigung.gelesen) {
                    anzahl++;
                }
            }
        }

        if (this.anzahlNeueDokumente.getValue() != anzahl) {
            this.anzahlNeueDokumente.next(anzahl);
        }
    }

    setLieferanten(adressen: Adresse[]) {
        this.lieferanten = adressen;
    }
}

interface DbSet<T> {
    items: T[];
    date: number;
}
