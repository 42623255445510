import { Component, OnInit, Input, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { Utils } from 'src/app/api/helper/utils';
import { Kontakt } from 'src/app/api/model/swagger-model';
import { SystemService } from 'src/app/api/system.service';

const log = new Logger("KontaktlisteComponent");

@Component({
    selector: 'app-kontaktliste',
    templateUrl: './kontaktliste.component.html',
    styleUrls: ['./kontaktliste.component.scss'],
})
export class KontaktlisteComponent implements OnInit, OnChanges {
    @Input() kontakte: Kontakt[];


    gruppen: GruppeVO[] = [];

    constructor(
        private cdr: ChangeDetectorRef,
        private systemService: SystemService,
        private modalController: ModalController,) {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.auftrag) {
            this.refresh();
        }
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        log.debug('refresh');

        this.gruppen = [];

        if (this.kontakte) {
            const typen = Utils.distinct(this.kontakte.map(p => p.Typ));

            for (const typ of typen) {
                const gruppenKontakte = this.kontakte.filter(p => p.Typ == typ);

                this.gruppen.push({
                    titel: typ,
                    kontakte: gruppenKontakte
                });
            }
        }
    }

    onClose() {
        log.debug('onClose');
        this.modalController.dismiss(null);
    }

    onAnrufen(kontakt: Kontakt) {
        log.debug('onAnrufen', kontakt);
        this.modalController.dismiss(kontakt);
    }
}

interface GruppeVO {
    titel: string;
    kontakte: Kontakt[];
}