import * as moment from 'moment';
import { Auftragsstatus } from '../model/aufteagsstatus';
import { AuftragEx, AuftragListItem, AuftragspositionEx, ListHeaderOptions } from '../model/model';
import { Adresse, ZeitEintrag, Kontakt, ReCoMobilEigenschaft, Regel } from '../model/swagger-model';
import { Logger } from './app-error-logger';
import { AppConfig } from './app.config';
import { Utils } from './utils';
import { Zeiterfassung } from './zeiterfassung-helper';
import { StammdatenService } from '../stammdaten.service';

const log = new Logger("AuftragHelper");

export class AuftragHelper {

    static getZutreffendeRegeln(auftrag: AuftragEx): Regel[] {
        if (!auftrag) {
            return [];
        }

        const regeln = StammdatenService.instance.regeln.getValue();

        if (!regeln?.length) {
            return [];
        }

        regeln.sort((a, b) => {
            if (a.Sortierung < b.Sortierung) {
                return -1;
            }
            if (a.Sortierung > b.Sortierung) {
                return 1;
            }

            if (a.Name < b.Name) {
                return -1;
            }
            if (a.Name > b.Name) {
                return 1;
            }

            return 0;
        })

        let resultList: Regel[] = [];

        for (const regel of regeln) {
            const ok = AuftragHelper.istRegelBedingungErfuellt(auftrag, regel);

            if (ok) {
                resultList.push(regel);

                if (regel.Aktion.KeineWeitereRegel) {
                    break;
                }
            }
        }

        return resultList;
    }

    static sortiereEigenschaften(eigenschaften: ReCoMobilEigenschaft[]) {
        if (!eigenschaften || !eigenschaften.length) {
            return;
        }

        try {
            if (eigenschaften.find(p => p.Sortierung > 0)) {
                // Es ist mindestens eine Sortierung gesetzt. Also sortieren
                for (const p of eigenschaften) {
                    if (p.Sortierung == null) {
                        p.Sortierung = 9999;
                    }
                }

                eigenschaften.sort((a, b) => {
                    if (a.Sortierung < b.Sortierung) {
                        return -1;
                    } else if (a.Sortierung > b.Sortierung) {
                        return 1;
                    }

                    if (a.Key < b.Key) {
                        return -1;
                    } else if (a.Key > b.Key) {
                        return 1;
                    }

                    return 0;
                });
            }
        } catch (err) {
            log.error(Utils.getErrorMessage(err), err);
        }
    }

    static getAuftragListHeaderKey(auftrag: AuftragListItem, options: ListHeaderOptions) {
        let key = auftrag.AAbholdatum;

        if (options.auftragFahrzeugFilterAktiv) {
            key += '/' + auftrag.FahrzeugKennung;
        }

        if (options.gruppenAnzeigen && auftrag.GruppeKey) {
            key += '/' + auftrag.GruppeKey;
        }

        return key;
    }

    static isAuftragImArchiv(auftrag: AuftragEx) {
        if (auftrag.Auftragsstatus >= Auftragsstatus.Abgeschlossen && auftrag.Auftragsstatus < Auftragsstatus.Geloescht) {
            return true;
        }

        return false;
    }

    static BerechneAktiveDauerInSekunden(auftrag: AuftragEx) {
        const zeiten = Zeiterfassung.getAuftragszeiten(auftrag);

        if (zeiten?.length) {
            return 0;
        }

        return AuftragHelper.BerechneAktiveDauerInSekundenFuerAuftragszeiten(zeiten);
    }

    static BerechneAktiveDauerInSekundenFuerAuftragszeiten(zeiten: ZeitEintrag[]) {
        let summeDauer = 0;

        if (zeiten) {
            for (const zeit of zeiten) {
                if (zeit.StartDatum && zeit.EndDatum) {
                    // Zeitaufzeichnung ist abgeschlossen
                    if (zeit.Dauer == null) {
                        const startMoment = moment(zeit.StartDatum);
                        const endMoment = moment(zeit.EndDatum);
                        zeit.Dauer = endMoment.diff(startMoment, 'seconds');
                    }

                    if (zeit.Dauer > 0) {
                        summeDauer += zeit.Dauer;
                    }
                } else if (zeit.StartDatum && !zeit.EndDatum) {
                    // Zeiterfassung läuft noch

                    const startMoment = moment(zeit.StartDatum);
                    const now = moment();
                    zeit.Dauer = now.diff(startMoment, 'seconds');

                    if (zeit.Dauer > 0) {
                        summeDauer += zeit.Dauer;
                    }
                }
            }
        }

        return summeDauer;
    }

    static sortiereAuftraege(auftraege: AuftragListItem[]) {
        const gruppenAnzeigen = AppConfig.current.einstellungen.AuftragGruppenAnzeigen === 'ja';

        for (let i = 0; i < auftraege.length; i++) {
            const auftrag = auftraege[i];

            if (!auftrag.Reihenfolge) {
                auftrag.Reihenfolge = 0;
            }
        }

        // zuerst nach Datum sortieren, dann nach Reihenfolge, dann nach Uhrzeit
        auftraege.sort((a, b) => {
            if (a.manuelleReihenfolge > 0 && b.manuelleReihenfolge > 0) {
                // Für beide Aufträge wurde die Reihenfolge manuell geändert (Aufträge sortieren).
                // Dann soll diese Reihenfolge auch beibehalten werden
                return a.manuelleReihenfolge - b.manuelleReihenfolge;
            }

            // Wenn ein Auftrag Reihenfolge 0 hat und noch keine manuelle Reihenfolge, dann soll er immer vor anderen bereits sortierten Aufträgen angezeigt werden.
            // Das will VNT so. Hier soll der Fahrer neue Aufträge immer ganz oben sehen. Die Dipso plant neue Aufträge immer mit Reihenfolge 0.
            if (a.manuelleReihenfolge == 0 && a.Reihenfolge == 0 && b.manuelleReihenfolge > 0) {
                return -1;
            } else if (b.manuelleReihenfolge == 0 && b.Reihenfolge == 0 && a.manuelleReihenfolge > 0) {
                return 1;
            }

            if (a.abholdatumTimestamp < b.abholdatumTimestamp) {
                return -1;
            } else if (a.abholdatumTimestamp > b.abholdatumTimestamp) {
                return 1;
            }

            let result = a.Reihenfolge - b.Reihenfolge;

            if (result === 0) {
                if (a.abholuhrzeitTimestamp < b.abholuhrzeitTimestamp) {
                    result = -1;
                } else if (a.abholuhrzeitTimestamp > b.abholuhrzeitTimestamp) {
                    result = 1;
                }
            }

            return result;
        });

        if (gruppenAnzeigen) {
            // Es sollen die Gruppen angezeigt werden.
            // Die Position der Gruppe ergebit sich aus der kleinsten Reihenfolge der Aufträge in der Gruppe.
            const gruppenReihenfolgenMap: { [name: string]: number } = {}

            let gruppeReihenfolge = 0;
            let gruppeGefunden = false;
            let gruppenSindTouren = false;

            for (const auftrag of auftraege) {
                let gruppeKey = auftrag.GruppeKey;

                if (gruppeKey) {
                    gruppeGefunden = true;

                    if (gruppeKey.indexOf('U') > 0) {
                        // Die Gruppe wurde für eine Tour erstellt.
                        // In der Tour sind die Aufträge wieder in der Reihenfolge 1,2,3 ... sortiert.
                        // Deshalb soll in diesem Fall immer zuerst nach Gruppen-Key-Reihenfolge sortiert werden und erst danach nach der Auftrags-Reihenfolge
                        gruppenSindTouren = true;
                    }
                } else {
                    gruppeKey = auftrag.Key + '/X';
                }

                // Die Gruppe gilt immer je Tag.
                // Es soll möglich sein z.B. als Key "Vormittag" und "Nachmittag" zu verwenden
                gruppeKey = auftrag.AAbholdatum + '-' + gruppeKey;

                auftrag.gruppeListKey = gruppeKey;

                let rf = gruppenReihenfolgenMap[gruppeKey];

                if (rf) {
                    // Für diese Gruppe ist bereits eine Reihenfolge bekannt.
                    auftrag.gruppeReihenfolge = rf;
                } else {
                    // Nächste Gruppen-Reihenfolge
                    rf = ++gruppeReihenfolge;
                    auftrag.gruppeReihenfolge = rf;
                    gruppenReihenfolgenMap[gruppeKey] = rf;
                }
            }

            if (gruppeGefunden) {
                // Nochmal neu sortieren, jetzt auch unter Berücksichtigung der Gruppen
                auftraege.sort((a, b) => {
                    if (a.abholdatumTimestamp < b.abholdatumTimestamp) {
                        return -1;
                    } else if (a.abholdatumTimestamp > b.abholdatumTimestamp) {
                        return 1;
                    }

                    let result = 0;

                    if (gruppenSindTouren) {
                        if (a.GruppeKey < b.GruppeKey) {
                            return -1;
                        } else if (a.GruppeKey > b.GruppeKey) {
                            return 1;
                        }
                    } else {
                        result = a.gruppeReihenfolge - b.gruppeReihenfolge;

                        if (result != 0) {
                            return result;
                        }
                    }

                    if (a.manuelleReihenfolge > 0 && b.manuelleReihenfolge > 0) {
                        // Für beide Aufträge wurde die Reihenfolge manuell geändert (Aufträge sortieren).
                        // Dann soll diese Reihenfolge auch beibehalten werden
                        return a.manuelleReihenfolge - b.manuelleReihenfolge;
                    }

                    // Wenn ein Auftrag Reihenfolge 0 hat und noch keine manuelle Reihenfolge, dann soll er immer vor anderen bereits sortierten Aufträgen angezeigt werden.
                    // Das will VNT so. Hier soll der Fahrer neue Aufträge immer ganz oben sehen. Die Dipso plant neue Aufträge immer mit Reihenfolge 0.
                    if (a.manuelleReihenfolge == 0 && a.Reihenfolge == 0 && b.manuelleReihenfolge > 0) {
                        return -1;
                    } else if (b.manuelleReihenfolge == 0 && b.Reihenfolge == 0 && a.manuelleReihenfolge > 0) {
                        return 1;
                    }

                    result = a.Reihenfolge - b.Reihenfolge;

                    if (result === 0) {
                        if (a.abholuhrzeitTimestamp < b.abholuhrzeitTimestamp) {
                            result = -1;
                        } else if (a.abholuhrzeitTimestamp > b.abholuhrzeitTimestamp) {
                            result = 1;
                        }
                    }

                    return result;
                });
            }
        }
    }

    static setzeEigenschaft(position: AuftragspositionEx, bezeichnung: string, wert: string, typ: string = 'text') {
        if (!position.Eigenschaften) {
            position.Eigenschaften = [];
        }

        var eigenschaft = position.Eigenschaften.find(p => p.Bezeichnung === bezeichnung);

        if (eigenschaft) {
            eigenschaft.Wert = wert;
        } else {
            position.Eigenschaften.push({
                Bezeichnung: bezeichnung,
                Key: bezeichnung,
                Typ: typ,
                Wert: wert
            });

            AuftragHelper.sortiereEigenschaften(position.Eigenschaften);
        }
    }

    static getAdressKontakteZumAnrufen(auftrag: AuftragEx, adresse: Adresse): Kontakt[] {
        log.debug('getAdressKontakteZumAnrufen');

        let resultList: Kontakt[] = [];

        if (!auftrag.Kontakte) {
            auftrag.Kontakte = [];
        }

        if (adresse.Telefon) {
            // Nur Ansprechpartner hinzufügen, falls kein Kontakt zur AdressNummer vorhanden ist.
            const kontakt = auftrag.Kontakte.find(p => p.AdressNummer == adresse.AdressNummer);

            if (!kontakt) {
                // Alte Variante
                resultList.push({
                    Name: adresse.Ansprechpartner,
                    Telefon: adresse.Telefon,
                    Typ: '',
                    AdressNummer: adresse.AdressNummer
                });
            }
        }

        for (const kontakt of auftrag.Kontakte) {
            if (!kontakt.AdressNummer || kontakt.AdressNummer == adresse.AdressNummer) {
                resultList.push(kontakt);
            }
        }

        // Doppelte Einträge entfernen
        resultList = resultList.filter((item, index, self) => {
            return self.findIndex(p => p.Name === item.Name && p.Telefon === item.Telefon) === index;
        });

        return resultList;
    }

    static getMengeText(auftrag: AuftragEx): string {
        const mengeMap = AuftragHelper.getMengeMap(auftrag);
        let text = '';

        for (const einheit in mengeMap) {
            if (Object.prototype.hasOwnProperty.call(mengeMap, einheit)) {
                const anzahl = mengeMap[einheit];

                if (text) {
                    text += ', ';
                }

                text += anzahl + ' ' + einheit;
            }
        }

        return text;
    }

    static getMengeMap(auftrag: AuftragEx): { [key: string]: number; } {
        const summenMap: { [key: string]: number; } = {};

        for (const p of auftrag.Positionen) {
            if (!p.Menge) {
                // Position ignorieren
                continue;
            }

            let einheit = p.Einheit;

            if (!einheit) {
                einheit = 'St';
            }

            let anzahl = summenMap[einheit];

            if (!anzahl) {
                anzahl = 0;
            }

            summenMap[einheit] = anzahl + p.Menge;
        }

        return summenMap;
    }

    static getSummeGewichtInKg(auftrag: AuftragEx): number {
        let result = 0;

        if (auftrag.Positionen) {
            for (const p of auftrag.Positionen) {
                if (typeof (p.GewichtInKg) === 'number') {
                    result += p.GewichtInKg;
                }
            }
        }

        return result;
    }

    static erstelleSuchtext(auftrag: AuftragEx): string {
        const suchtexte = [];

        suchtexte.push(auftrag.Auftragsnummer);
        suchtexte.push(auftrag.FahrzeugKennung);
        suchtexte.push(auftrag.Info1);
        suchtexte.push(auftrag.Info2);
        suchtexte.push(auftrag.Info3);

        if (auftrag.Abholadresse) {
            suchtexte.push(auftrag.Abholadresse.Name1);
            suchtexte.push(auftrag.Abholadresse.Name2);
            suchtexte.push(auftrag.Abholadresse.Name3);
            suchtexte.push(auftrag.Abholadresse.Strasse);
            suchtexte.push(auftrag.Abholadresse.PLZ);
            suchtexte.push(auftrag.Abholadresse.Ort);
            suchtexte.push(auftrag.Abholadresse.Ansprechpartner);
        }

        if (auftrag.Positionen) {
            for (const p of auftrag.Positionen) {
                suchtexte.push(p.Menge + ' ' + p.Einheit + ' ' + p.Bezeichnung);
            }
        }

        return suchtexte.join('; ').toLowerCase();
    }

    static getAbholdatumText(auftrag: AuftragEx | AuftragListItem): string {
        let abholdatum = auftrag.AAbholdatum;

        if (auftrag.AbholzeitText) {
            // Änderung, dass auch die Bezeichnung des DISPO Fenster angezeigt werden (HERZOG KG Anfrage)
            abholdatum += ` ${auftrag.AbholzeitText} ${auftrag.AAbholzeitVon} - ${auftrag.AAbholzeitBis} Uhr`;
        } else if (auftrag.AAbholzeitVon && auftrag.AAbholzeitBis) {
            abholdatum += ` ${auftrag.AAbholzeitVon} - ${auftrag.AAbholzeitBis} Uhr`;
        } else if (auftrag.AAbholzeitVon) {
            abholdatum += ` ${auftrag.AAbholzeitVon} Uhr`;
        }

        return abholdatum;
    }

    static getAbholdatumClassName(auftrag: AuftragEx | AuftragListItem): string {
        // const abholMoment = AuftragHelper.getAbholMoment(auftrag);
        // const now = moment();

        // if (abholMoment.isBefore(now)) {
        //     return 'termin-verpasst';
        // }

        return '';
    }

    /**
     * Liefert das Abholdatum + Zeit als Moment zurück.
     */
    static getAbholDatumMoment(auftrag: AuftragEx | AuftragListItem): moment.Moment {
        if (!auftrag.AAbholdatum) {
            return null;
        }

        return moment(auftrag.AAbholdatum + ' 23:59', 'DD.MM.YYYY HH:mm');

        // if (auftrag.AAbholzeitBis) {
        //     return moment(auftrag.AAbholdatum + ' ' + auftrag.AAbholzeitBis, 'DD.MM.YYYY HH:mm');
        // } else if (auftrag.AAbholzeitVon) {
        //     return moment(auftrag.AAbholdatum + ' ' + auftrag.AAbholzeitVon, 'DD.MM.YYYY HH:mm');
        // } else {
        //     return moment(auftrag.AAbholdatum + ' 23:59', 'DD.MM.YYYY HH:mm');
        // }
    }

    static getAbholUhrzeitMoment(auftrag: AuftragEx | AuftragListItem): moment.Moment {
        if (!auftrag.AAbholdatum) {
            return null;
        }

        if (auftrag.AAbholzeitBis) {
            return moment(auftrag.AAbholdatum + ' ' + auftrag.AAbholzeitBis, 'DD.MM.YYYY HH:mm');
        } else if (auftrag.AAbholzeitVon) {
            return moment(auftrag.AAbholdatum + ' ' + auftrag.AAbholzeitVon, 'DD.MM.YYYY HH:mm');
        } else {
            return moment(auftrag.AAbholdatum + ' 23:59', 'DD.MM.YYYY HH:mm');
        }
    }


    static istRegelBedingungErfuellt(auftrag: AuftragEx, regel: Regel) {
        if (!auftrag || !regel?.Bedingung) {
            return false;
        }

        // Gerätegruppe
        if (regel.Bedingung.Geraetegruppe) {
            let lines = Utils.split([';', '\n'], regel.Bedingung.Geraetegruppe);

            for (const l of lines) {
                if (!Utils.isWildcardMatch(AppConfig.current.GeraeteGruppe, l)) {
                    return false;
                }
            }
        }

        if (auftrag.Positionen?.length) {
            let positionen = auftrag.Positionen;

            if (regel.Bedingung.MengeVorhanden) {
                positionen = positionen.filter(p => p.Menge > 0);
            }

            // Artikel-Bezeichnung
            if (regel.Bedingung.Artikelbezeichnungen) {
                let lines = Utils.split([';', '\n'], regel.Bedingung.Artikelbezeichnungen);

                let isMatch = false;

                for (const l of lines) {
                    for (const pos of positionen) {
                        if (Utils.isWildcardMatch(pos.Bezeichnung, l)) {
                            isMatch = true;
                            break;
                        }
                    }

                    if (isMatch) {
                        break;
                    }
                }

                if (!isMatch) {
                    return false;
                }
            }

            // Artikelnummern
            if (regel.Bedingung.Artikelnummern) {
                let lines = Utils.split([';', '\n'], regel.Bedingung.Artikelnummern);

                let isMatch = false;

                for (const l of lines) {
                    for (const pos of positionen) {
                        if (Utils.isWildcardMatch(pos.ArtikelKey, l)) {
                            isMatch = true;
                            break;
                        }
                    }

                    if (isMatch) {
                        break;
                    }
                }

                if (!isMatch) {
                    return false;
                }
            }
        }

        return true;
    }
}
