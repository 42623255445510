<ion-header>
    <ion-toolbar class="auftrag-toolbar" #toolbar>
      <ion-buttons slot="start">
        <ion-button appClick (clicked)="onAbbrechen()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title><span id="page-title-span">{{ 'Auftrag weiterleiten' | translate }}</span></ion-title>
    </ion-toolbar>
  </ion-header>

<ion-content *ngIf="auftrag">
    <ion-list class="geraete-list" lines="full">
        <ion-item *ngFor="let dto of geraeteListe" appClick (clicked)="onGeraetSelected(dto)" [detail]="true">
          <ion-label>
            <h2>{{ dto.Bezeichnung }}</h2>
            <p>{{ dto.GeraeteNummer }} {{ dto.GeraeteGruppe }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
</ion-content>