<ion-content #content>
  <ion-card *ngIf="auftrag" style="--background: white;">
    <ion-card-header>
      <ion-card-title>Barcode</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <app-auftrag-barcode [auftrag]="auftrag"></app-auftrag-barcode>      
      <div>
        <ion-button expand="full" color="primary" appClick (clicked)="onClose()">Schließen</ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>