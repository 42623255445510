import { Logger } from './app-error-logger';
import { AppConfig } from './app.config';
import { Utils } from './utils';

const log = new Logger('FormatHelper');

export class FormatHelper {
    /**
     * see https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
     */
    static formatReadableNumber(n: number) {
        return n.toLocaleString('de', {
            useGrouping: true
        });

        // // let str = n.toString();
        // // const pattern = /(-?\d+)(\d{3})/;

        // // while (pattern.test(str)) {
        // //     str = str.replace(pattern, "$1.$2");
        // // }

        // // return str;
    }

    static trimEachString(array: string[]): string[] {
        const result: string[] = [];

        if (array) {
            for (let str of array) {
                if (str == null || typeof (str) === 'undefined') {
                    str = '';
                }

                result.push(str.trim());
            }
        }

        return result;
    }

    static formatEP(einzelpreis: number) {
        if(einzelpreis == null) {
            return '';
        }
        
        let nachkommastellen = Utils.parseInt(AppConfig.current.einstellungen.PreisNachkommastellen);

        let result = '';

        if (typeof (einzelpreis) === 'number') {
            result = einzelpreis.toFixed(nachkommastellen);
        }

        result = Utils.replaceAll(result, '.', ',');

        log.debug('formatEP: ' + einzelpreis + ' = ' + result);

        return result;
    }

    static formatPreis(preis: number) {
        if(preis == null) {
            return '';
        }

        let nachkommastellen = 2;

        let result = '';

        if (typeof (preis) === 'number') {
            result = preis.toFixed(nachkommastellen);
        }
        
        result = Utils.replaceAll(result, '.', ',');

        log.debug('formatPreis: ' + preis + ' = ' + result);

        return result;
    }
}
