import { ElementRef, NgZone } from "@angular/core";
import { Logger } from './app-error-logger';
import { AlertController, ActionSheetController, LoadingController, ModalController, AlertButton } from '@ionic/angular';
import { App } from './app';
import { Utils } from './utils';
import { I18N } from "./i18n";
import { ShowAlertOptions } from "../model/model";

export class UiHelper {
    static log = new Logger("UiHelper");

    static alertCtrl: AlertController;
    static modalController: ModalController;
    static actionSheetController: ActionSheetController;
    static loadingController: LoadingController;

    static loading: HTMLIonLoadingElement;
    static errorAlert: HTMLIonAlertElement;

    static istAlertSichtbar = false;

    // static translateService: TranslateService = null;
    // static ngZone: NgZone = null;
    // static log = new Logger('UiHelper');

    // static isDirty(): boolean {
    //     const dirtyForms = $('form.ng-dirty');
    //     console.log('dirtyForms', dirtyForms);
    //     return dirtyForms.length > 0;
    // }

    // static resetDirty() {
    //     $('form.ng-dirty').removeClass('ng-dirty');
    // }

    // static setDirty() {
    //     console.log('setDirty');
    //     $('.page-content form').addClass('ng-dirty');
    // }

    // static isValid(el: ElementRef): boolean {
    //     if(!el) {
    //         return true;
    //     }

    //     //console.log('el', $(el.nativeElement));

    //     const ngInvalid = $(el.nativeElement).find('input.ng-invalid');

    //     //console.log('ngInvalid', ngInvalid);

    //     return ngInvalid.length == 0;
    // }

    // static valid(el: ElementRef): boolean {
    //     let isValid = UiHelper.isValid(el);
    //     if (!isValid) {
    //         UiHelper.showValidationErrors(el);
    //         UiHelper.showFormularfehler();
    //     }
    //     console.log('valid', isValid);
    //     return isValid;
    // }

    // static async sweetAlert(params: (string | Partial<SwalOptions>)): Promise<any> {
    //     return this.ngZone.runOutsideAngular(async () => {
    //         const htScope = hotkeys.getScope();

    //         try {
    //             hotkeys.setScope('none');
    //             const result = await swal(params);
    //             return result;
    //         } finally {
    //             hotkeys.setScope(htScope);
    //         }
    //     });
    // }

    // static async showFormularfehler() {
    //     const text18 = await UiHelper.i18n('Bitte prüfen Sie Ihre Eingaben. Das Formular wurde nicht vollständig ausgefüllt.');
    //     const ok = await UiHelper.i18n('OK');

    //     return UiHelper.sweetAlert({
    //         text: text18,
    //         icon: 'error',
    //         buttons: {
    //             confirm: {
    //                 text: ok
    //             }
    //         }
    //     });
    // }

    // static showValidationErrors(form: ElementRef) {
    //     $(form.nativeElement).valid();
    // }

    // static async i18n(text: string): Promise<string> {
    //     try {
    //         if (!text || text == null || !UiHelper.isDefined(text) || text.length <= 0) {
    //             return Promise.resolve(text);
    //         }

    //         if (UiHelper.translateService != null) {
    //             return UiHelper.translateService.get(text).toPromise();
    //         }

    //         return Promise.resolve(text);
    //     } catch (err) {
    //         console.log('Unexpected error translating text: ' + text, err);
    //         return Promise.resolve(text);
    //     }
    // }

    // static isDefined(value): boolean {
    //     return typeof value !== 'undefined' && value !== null;
    // }

    static showFehlerKommunikation(err: any = null) {
        let text = 'Fehler bei der Kommunikation mit dem Server. Bitte prüfen Sie Ihre Internetverbindung und hierholen Sie den Vorgang.';

        if (err) {
            text += '\n\n' + Utils.getErrorMessage(err);
        }

        UiHelper.showErrorOhneSentry(text);
    }

    static async confirm(text: string, titel: string, button1: string, button2: string): Promise<string> {
        if (App.isCordovaAvailable() && App.current.NativeDialogeBevorzugen) {
            const buttonIndex = await App.current.dialogs.confirm(text, titel, [button1, button2]);

            if (buttonIndex == 1) {
                return button1;
            } else if (buttonIndex == 2) {
                return button2;
            } else {
                return null;
            }
        }

        const alert = await this.alertCtrl.create({
            mode: 'ios',
            message: text,
            backdropDismiss: true,
            buttons: [
                {
                    text: button1,
                    handler: (blah) => {
                        alert.dismiss(button1);
                        return false;
                    }
                },
                {
                    text: button2,
                    handler: (blah) => {
                        alert.dismiss(button2);
                        return false;
                    }
                }
            ]
        });

        await alert.present();
        const data = await alert.onDidDismiss();
        return data.data;
    }

    static async confirmButtons(text: string, titel: string, buttonLabels: string[]): Promise<string> {
        if (App.isCordovaAvailable() && App.current.NativeDialogeBevorzugen) {
            const buttonIndex = await App.current.dialogs.confirm(text, titel, buttonLabels);

            if (buttonIndex > 0) {
                return buttonLabels[buttonIndex - 1];
            } else {
                return null;
            }
        }

        let buttons: AlertButton[] = [];

        for (const label of buttonLabels) {
            buttons.push({
                text: label,
                handler: (blah) => {
                    alert.dismiss(label);
                    return false;
                }
            });
        }

        const alert = await this.alertCtrl.create({
            mode: 'ios',
            message: text,
            backdropDismiss: true,
            buttons: buttons
        });

        await alert.present();
        const data = await alert.onDidDismiss();
        return data.data;
    }

    static async confirmJaNein(text: string, header: string = null, translate = true): Promise<boolean> {
        App.loading(false);

        UiHelper.blurActiveElement();

        if (translate) {
            text = I18N.instant(text);
        }

        if (App.isCordovaAvailable() && App.current.NativeDialogeBevorzugen) {
            text = Utils.entferneHtmlTags(text);

            if (!header) {
                header = 'Frage';
            }

            if (translate) {
                header = I18N.instant(header);
            }

            const buttonIndex = await App.current.dialogs.confirm(text, header, [I18N.instant('Ja'), I18N.instant('Nein')]);

            if (buttonIndex == 1) {
                return true;
            } else {
                return false;
            }
        } else {
            if (translate && header) {
                header = I18N.instant(header);
            }

            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header,
                message: text,
                backdropDismiss: true,
                buttons: [
                    {
                        text: I18N.instant('Ja'),
                        handler: (blah) => {
                            alert.dismiss(true);
                            return false;
                        }
                    },
                    {
                        text: I18N.instant('Nein'),
                        handler: (blah) => {
                            alert.dismiss(false);
                            return false;
                        }
                    }
                ]
            });

            await alert.present();
            const data = await alert.onDidDismiss();
            return data.data;
        }
    }

    static delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // static async confirm(text: string, icon: 'info' | 'warning' | 'success' | '' = 'warning'): Promise<any> {
    //     const text18 = await UiHelper.i18n(text);
    //     const ok = await UiHelper.i18n('OK');
    //     const abbrechen = await UiHelper.i18n('Abbrechen');

    //     return UiHelper.sweetAlert({
    //         text: text18,
    //         icon: icon,
    //         buttons: {
    //             confirm: {
    //                 text: ok,
    //                 value: true,
    //                 visible: true,
    //                 className: "",
    //                 closeModal: true
    //             },
    //             cancel: {
    //                 text: abbrechen,
    //                 value: false,
    //                 visible: true,
    //                 className: "",
    //                 closeModal: true,
    //             }
    //         }
    //     });
    // }

    // static async showLoadingDialog(text: string) {
    //     const text18 = await UiHelper.i18n(text);

    //     const e = $.parseHTML(`<div>
    //         <p>` + text18 + `</p>
    //         <div class="spinner">
    //             <div class="bounce1"></div>
    //             <div class="bounce2"></div>
    //             <div class="bounce3"></div>
    //         </div>
    //     </div>`)[0];

    //     UiHelper.sweetAlert({
    //         content: {
    //             element: e,
    //         },
    //         buttons: {}
    //     });
    // }

    // static closeDialog() {
    //     try {
    //         (<any>swal).close();
    //     } catch (err) {
    //         // nicht offen
    //     }
    // }

    // static showContentSpinner() {
    //     $('#contentSpinner').show();
    // }

    // static hideContentSpinner() {
    //     $('#contentSpinner').hide();
    // }

    // static showContentBlocker() {
    //     $('#contentBlocker').show();
    // }

    // static hideContentBlocker() {
    //     $('#contentBlocker').hide();
    // }

    // static async showSuccess(text: string): Promise<any> {
    //     const text18 = await UiHelper.i18n(text);
    //     const ok = await UiHelper.i18n('OK');

    //     return UiHelper.sweetAlert({
    //         text: text18,
    //         icon: 'success',
    //         buttons: {
    //             confirm: {
    //                 text: ok,
    //                 value: true,
    //                 visible: true,
    //                 className: "",
    //                 closeModal: true
    //             }
    //         }
    //     });
    // }

    // static async showSaveSuccessfulWithButtons(buttons: ButtonList): Promise<any> {
    //     const text18 = await UiHelper.i18n('Erfolgreich gespeichert');

    //     return UiHelper.sweetAlert({
    //         text: text18,
    //         icon: 'success',
    //         buttons: buttons
    //     });
    // }

    // static async showErrorWithButtons(message: string, buttons: ButtonList): Promise<any> {
    //     return UiHelper.sweetAlert({
    //         text: await UiHelper.i18n(message),
    //         icon: 'error',
    //         buttons: buttons
    //     });
    // }

    // static showSaveSuccessful(): Promise<any> {
    //     // return Promise.resolve(true);
    //     return UiHelper.showSuccess('Erfolgreich gespeichert');
    // }

    static async presentLoading(message, backdropDismiss = false): Promise<HTMLIonLoadingElement> {
        await UiHelper.hideLoading();

        UiHelper.loading = await UiHelper.loadingController.create({
            message: I18N.instant(message),
            backdropDismiss,
            keyboardClose: backdropDismiss
        });

        await UiHelper.loading.present();

        if (UiHelper.loading) {
            UiHelper.loading.onDidDismiss().then(() => this.loading = null);
        }

        return UiHelper.loading;
    }

    static async showLoading(message, backdropDismiss = false, onClose: () => void = null): Promise<any> {
        this.log.debug('showLoading: ' + message);

        await UiHelper.hideLoading();

        UiHelper.loading = await UiHelper.loadingController.create({
            message: I18N.instant(message),
            backdropDismiss: backdropDismiss,
            keyboardClose: backdropDismiss
        });

        await UiHelper.loading.present();

        if (UiHelper.loading) {
            UiHelper.loading.onDidDismiss().then(() => {
                this.loading = null;

                if (typeof (onClose) === 'function') {
                    onClose();
                }
            });
        }
    }

    static async hideLoading(): Promise<any> {
        if (UiHelper.loading) {
            this.log.debug('hideLoading');

            await UiHelper.loading.dismiss();
            await App.delay(10);
            UiHelper.loading = null;
        }
    }

    static async showAlert(text: string, header = null, awaitDismiss = false): Promise<any> {
        UiHelper.showAlertEx({
            text: text,
            header,
            awaitDismiss,
            transalte: true
        })
    }

    static async showAlertEx(options: ShowAlertOptions): Promise<any> {
        const defaultOptionen: ShowAlertOptions = {
            text: '',
            header: null,
            awaitDismiss: false,
            transalte: true
        }

        options = Object.assign(defaultOptionen, options);

        let text = options.text;
        let header = options.header;

        UiHelper.log.info('showAlert: ' + text);

        if (!text) {
            return;
        }

        if (options.transalte) {
            text = I18N.instant(text);
        }

        if (this.errorAlert) {
            this.errorAlert.dismiss();
            this.errorAlert = null;
        }

        UiHelper.istAlertSichtbar = true;

        UiHelper.blurActiveElement();

        // Loading darf nicht angezeigt werden, ansonsten kann man nicht den Text lesen und auf OK klicken
        App.loading(false);

        await UiHelper.hideLoading();

        if (App.isCordovaAvailable() && App.current.NativeDialogeBevorzugen) {
            // TODO: noch testen
            text = Utils.entferneHtmlTags(text);

            if (!header) {
                header = 'Achtung';
            }

            if (options.transalte) {
                header = I18N.instant(header);
            }

            if (options.awaitDismiss) {
                await App.current.dialogs.alert(text, header);
            } else {
                App.current.dialogs.alert(text, header);
            }

            return;
        }

        if (options.transalte) {
            header = I18N.instant(header);
        }

        this.errorAlert = await this.alertCtrl.create({
            mode: 'ios',
            header,
            subHeader: text,
            backdropDismiss: true,
            buttons: [
                {
                    text: 'OK',
                }
            ]
        });

        await this.errorAlert.present();

        if (this.errorAlert) {
            this.errorAlert.onDidDismiss().then(() => this.errorAlert = null);
        }

        if (options.awaitDismiss) {
            if (this.errorAlert) {
                await this.errorAlert.onDidDismiss();
            }
        }

        UiHelper.istAlertSichtbar = false;

        return this.errorAlert;
    }

    static blurActiveElement() {
        if (document.activeElement instanceof HTMLElement) {
            try {
                // Verhindern dass wieder nach oben gescrollt wird zu einem Eingabefeld
                document.activeElement.blur();
            } catch (err) {
                this.log.warn('activeElement.blur(): ' + Utils.getErrorMessage(err));
            }
        }
    }

    static async showErrorOhneSentry(error: any): Promise<any> {
        UiHelper.showError(error, 'Fehler', false, false);
    }

    static async showErrorLight(error: any, header = 'Fehler', awaitDismiss = false): Promise<any> {
        return await UiHelper.showError(error, header, awaitDismiss, false);
    }

    static async showError(error: any, header = 'Fehler', awaitDismiss = false, logAsError = true): Promise<any> {
        if (logAsError) {
            UiHelper.log.error('showError: ' + Utils.getErrorMessage(error));
        } else {
            UiHelper.log.info('showError: ' + Utils.getErrorMessage(error));
        }

        App.loading(false);

        try {
            let message: string;

            if (typeof (error) === 'string') {
                message = error;
            } else if (error && typeof (error.text) === 'string') {
                message = error.text;
            } else if (error && typeof (error.message) === 'string') {
                message = error.message;
            } else if (!error) {
                message = 'Unbekannter Fehler';
            } else {
                message = error;
            }

            if (typeof (message) !== "string") {
                message = "Unbekannter Fehler";
            }

            if (this.errorAlert) {
                this.errorAlert.dismiss();
                this.errorAlert = null;
            }

            await UiHelper.hideLoading();

            if (!header) {
                header = '';
            }

            if (App.isCordovaAvailable() && App.current.NativeDialogeBevorzugen) {
                // TODO: noch testen
                if (awaitDismiss) {
                    await App.current.dialogs.alert(message, header);
                } else {
                    App.current.dialogs.alert(message, header);
                }

                return;
            }

            this.errorAlert = await this.alertCtrl.create({
                header,
                subHeader: message,
                backdropDismiss: true,
                buttons: [
                    {
                        text: 'OK',
                    }
                ]
            });

            await this.errorAlert.present();

            if (awaitDismiss) {
                if (this.errorAlert) {
                    await this.errorAlert.onDidDismiss();
                }

                this.errorAlert = null;
            } else {
                if (this.errorAlert) {
                    this.errorAlert.onDidDismiss().then(() => this.errorAlert = null);
                }
            }
        } catch (err) {
            UiHelper.log.error('showError', err);
        }
    }

    static async inputPIN(message: string = 'Bitte PIN eingeben'): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let resolved = false;

                const alert = await this.alertCtrl.create({
                    cssClass: 'input-alert',
                    message: message,
                    inputs: [
                        {
                            name: 'name',
                            type: 'number',
                            placeholder: '',
                            value: ''
                        }
                    ],
                    buttons: [
                        {
                            text: 'Abbruch',
                            role: 'cancel',
                            handler: data => {
                                resolved = true;
                                resolve(null);
                            }
                        },
                        {
                            text: 'OK',
                            role: 'accept',
                            handler: data => {
                                resolved = true;
                                resolve(data.name);
                            }
                        }
                    ]
                });

                await alert.present();

                await Utils.delay(200);

                const firstInput: any = document.querySelector('ion-alert input');

                if (firstInput) {
                    firstInput.focus();
                }

                await alert.onDidDismiss();

                if (!resolved) {
                    resolve(null);
                }
            } catch (err) {
                this.log.warn('getZebraPrinterName: ' + Utils.getErrorMessage(err));
                resolve(null);
            }
        });

    }
}
