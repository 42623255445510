<ion-item [lines]="lines" class="{{ cssClass }}" [style]="style">
  <ion-label class="ion-text-wrap">{{ titel | translate }}</ion-label>

  <div slot="end" class="input-outer">
    <ion-button *ngIf="isMinusButtonVisible" size="default" color="light" appClick (clicked)="change(-step)" class="inline-plusminus-button minus-button">-</ion-button>

    <ion-input #input
               [inputmode]="inputmode"
               type="text"
               [min]="min"
               [max]="max"
               [step]="step"
               [pattern]="pattern"
               [class.invalid-value]="isInvalid"
               [class.input-readonly]="readOnly"
               [(ngModel)]="myValue"
               [enterkeyhint]="enterkeyhint"
               [disabled]="disabled"
               [required]="required"
               [readonly]="readOnly"
               [clearInput]="false"
               (keyup.enter)="onEnter($event)"
               (ionChange)="onIonChange($event)"
               (ionBlur)="onBlur($event)"></ion-input>

    <span *ngIf="einheit" class="einheit">{{ einheit }}</span>

    <ion-button *ngIf="isPlusButtonVisible" size="default" color="light" appClick (clicked)="change(step)" class="inline-plusminus-button plus-button">+</ion-button>

    <!-- <ion-icon *ngIf="isClearButtonVisible" slot="icon-only" name="close" appClick (clicked)="onClearValue()" class="clear-icon" mode="ios"></ion-icon> -->
    <ion-button *ngIf="isClearButtonVisible"
                class="clear-button inline-plusminus-button"
                [disabled]="disabled"
                appClick (clicked)="onClearValue()"
                size="default"
                color="light"
                slot="end">
      <ion-icon name="create-outline" mode="ios" class="clear-icon"></ion-icon>
    </ion-button>

    <!-- <ion-icon *ngIf="isClearButtonVisible" slot="icon-only" name="create" appClick (clicked)="onClearValue()" class="clear-icon"></ion-icon> -->

    <!-- <ion-button *ngIf="isClearButtonVisible" size="default" color="light" appClick (clicked)="onClearValue()" class="inline-plusminus-button clear-button"></ion-button> -->
    <span *ngIf="isEmptySpaceVisible" class="empty-space {{ emptySpaceClass }}"></span>
  </div>
</ion-item>