import { Directive, ElementRef, HostListener, EventEmitter, Output, OnDestroy, NgZone, Input } from '@angular/core';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { Utils } from 'src/app/api/helper/utils';

const DEBUG = false;

const log = new Logger("ClickHandler");

class ClickHandler {
    static instance: ClickHandler;

    touchEventFound = false;
    touchDownElement: ClickDirective;
    preventClicks = false;

    list: ClickDirective[] = [];

    isValid = false;
    sensitivityX = 200;
    sensitivityY = 20;

    touchDownTime = 0;
    touchMoveTime = 0;
    touchUpTime = 0;
    touchStartX = 0;
    touchStartY = 0;

    onClickFn: (e: Event) => void;
    onTouchStartFn: (e: TouchEvent) => void;
    onTouchCancelFn: (e: TouchEvent) => void;
    onTouchMoveFn: (e: TouchEvent) => void;
    onTouchEndFn: (e: TouchEvent) => void;
    onMouseDownFn: (e: TouchEvent) => void;
    onMouseUpFn: (e: TouchEvent) => void;

    ignoreElements = ['ion-item-options', 'ion-item-sliding'];

    // onClickFn: (e: TouchEvent) => void;

    constructor() {
        ClickHandler.instance = this;
    }

    init() {
        this.onClickFn = (event: Event) => {
            if (this.preventClicks) {
                // PREVENT GHOST CLICK
                if (DEBUG) {
                    console.log('onClickFn');
                    console.log('preventDefault 1');
                }

                event.preventDefault();
            }
        }

        this.onTouchStartFn = (event: TouchEvent) => {
            this.touchEventFound = true;
            this.onPointerDown(event, true);
        };

        this.onMouseDownFn = (event) => {
            if (DEBUG) console.log('onMouseDownFn: ' + this.touchEventFound);
            if (!this.touchEventFound) {
                this.onPointerDown(event, false);
            } else if (this.preventClicks) {
                if (DEBUG) {
                    console.log('preventDefault 2');
                }

                event.preventDefault();
            }
        };

        this.onTouchMoveFn = (event) => {
            if (DEBUG) console.log('onTouchMoveFn');
            this.touchEventFound = true;
            this.onTouchMove(event);
        };

        this.onTouchEndFn = (event) => {
            if (DEBUG) console.log('onTouchEndFn');
            this.touchEventFound = true;
            this.onPointerUp(event);
        };

        this.onMouseUpFn = (event) => {
            if (DEBUG) console.log('onMouseUpFn: ' + this.touchEventFound);
            if (!this.touchEventFound) {
                this.onPointerUp(event);
            } else if (this.preventClicks) {
                if (DEBUG) {
                    console.log('preventDefault 3');
                }

                event.preventDefault();
            }
        };

        // this.onClickFn = (event) => {
        //     if(DEBUG) console.log('onClickFn: ' + event.target);

        //     if (this.touchEventFound) {
        //         return;
        //     }

        //     setTimeout(() => {
        //         this.ngZone.run(() => {
        //             (event as any).touchDuration = this.touchUpTime - this.touchDownTime;

        //             this.clicked.emit(event);
        //         });
        //     }, 5);
        // };

        document.addEventListener('click', this.onClickFn, true);
        document.addEventListener('touchstart', this.onTouchStartFn, true);
        document.addEventListener('touchmove', this.onTouchMoveFn, true);
        document.addEventListener('touchend', this.onTouchEndFn, true);
        document.addEventListener('mousedown', this.onMouseDownFn, true);
        document.addEventListener('mouseup', this.onMouseUpFn, true);
    }

    onPointerDown(event: Event, isTouch: boolean) {
        try {
            const target = event.target as HTMLElement;

            if (!target) {
                return;
            }

            if (target.localName == 'ion-toggle') {
                return;
            }

            if (target.localName == 'ion-checkbox') {
                return;
            }

            if (this.ignoreElements.includes(target.localName)) {
                return;
            }

            const clickDirective = this.getClickDirective(target);

            if (!clickDirective) {
                return;
            }

            if (DEBUG) console.log('onPointerDown: ' + target.localName);

            this.sensitivityX = clickDirective.sensitivityX;
            this.sensitivityY = clickDirective.sensitivityY;

            // if (target && target.localName == 'ion-button' && target != this.el.nativeElement) {
            //     return;
            // }

            // console.log('onTouchStartFn', event);

            this.touchDownElement = clickDirective;
            this.touchDownTime = new Date().getTime();

            if (isTouch) {
                const touchEvent = event as TouchEvent;
                this.touchStartX = touchEvent.touches[0].screenX;
                this.touchStartY = touchEvent.touches[0].screenY;
            } else {
                const mouseEvent = event as MouseEvent;
                this.touchStartX = mouseEvent.screenX;
                this.touchStartY = mouseEvent.screenY;
            }

            this.isValid = true;

            if (!this.sensitivityX) {
                this.sensitivityX = 200;
            }

            if (!this.sensitivityY) {
                this.sensitivityY = 20;
            }
        } catch (err) {
            log.error(err);
        }
    }

    onPointerUp(event: TouchEvent) {
        try {
            const target = event.target as HTMLElement;

            if (!target) {
                return;
            }

            if (this.ignoreElements.includes(target.localName)) {
                return;
            }

            this.touchUpTime = new Date().getTime();

            const touchDuration = this.touchUpTime - this.touchDownTime;

            if (DEBUG) console.log('onPointerUp: ' + target.localName + ', duration: ' + touchDuration);

            performance.mark('tourch end');

            if (this.isValid) {
                this.isValid = false;

                const touchUpElement = this.getClickDirective(target);

                if (!touchUpElement) {
                    return;
                }

                if (touchUpElement != this.touchDownElement) {
                    return;
                }

                (event as any).touchDuration = touchDuration;

                this.preventClicks = true;

                setTimeout(() => {
                    try {
                        touchUpElement.ngZone.run(() => {
                            (event as any).touchDuration = touchDuration;

                            touchUpElement.clicked.emit(event);
                        });
                    } catch (err) {
                        log.error(err);
                    }
                }, 5);

                setTimeout(() => {
                    this.preventClicks = false;
                }, 750);

                // console.log('onTouchEndFn: ' + (this.touchUpTime - this.touchDownTime), event);

                // const documentClickFn = (e) => {
                //     // console.log('PREVENT GHOST CLICK', e);
                //     e.preventDefault();
                // };

                // Kein preventDefault. Ansonsten gibt es Probleme mit dem Hardware-Back-Button
                // event.preventDefault();

                // setTimeout(() => {
                //     if (DEBUG) console.log('onTouchEndFn setTimeout: ' + (this.touchUpTime - this.touchDownTime));

                //     // if (!event.defaultPrevented) {
                //     //     this.ngZone.run(() => {
                //     //         (event as any).touchDuration = this.touchUpTime - this.touchDownTime;
                //     //         this.clicked.emit(event);
                //     //     });
                //     // }
                // }, 5);
            }
        } catch (err) {
            log.error(err);
        }
    }

    onTouchMove(event: TouchEvent) {
        try {
            this.touchMoveTime = new Date().getTime();

            if (this.isValid) {
                // console.log('onTouchMoveFn: ' + this.touchMoveTime, event);

                const posX = event.touches[0].screenX;
                const posY = event.touches[0].screenY;

                if (Math.abs(posY - this.touchStartY) > this.sensitivityY) {
                    this.isValid = false;
                }

                if (Math.abs(posX - this.touchStartX) > this.sensitivityX) {
                    this.isValid = false;
                }
            }
        } catch (err) {
            log.error(err);
        }
    }

    getClickDirective(target: HTMLElement, ttl = 20): ClickDirective {
        try {
            if (!target || ttl < 0) {
                return null;
            }

            const clickDirective = this.list.find(p => p.element == target);

            if (clickDirective) {
                return clickDirective;
            }

            return this.getClickDirective(target.parentElement, ttl - 1);
        } catch (err) {
            log.error(err);
            return null;
        }
    }

    register(clickDirective: ClickDirective) {
        this.list.push(clickDirective);
    }

    unregister(clickDirective: ClickDirective) {
        const idx = this.list.indexOf(clickDirective);

        if (idx >= 0) {
            this.list.splice(idx, 1);
        }
    }

}

const clickHandler = new ClickHandler();
clickHandler.init();

@Directive({
    selector: '[appClick]'
})
export class ClickDirective implements OnDestroy {
    @Output() clicked: EventEmitter<any> = new EventEmitter();

    @Input() sensitivityX = 200;
    @Input() sensitivityY = 20;

    static onDocumentClickFn: (e) => void;

    public element: HTMLElement;

    destroyed = false;
    initialized = false;

    constructor(private el: ElementRef, public ngZone: NgZone) {
        this.element = el.nativeElement;

        clickHandler.register(this);
    }

    init() {
        if (this.destroyed) {
            return;
        }

        this.initialized = true;
    }

    ngOnDestroy(): void {
        clickHandler.unregister(this);

        this.destroyed = true;
    }
}
