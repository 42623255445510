import { DefaultEntity, Dokument, Auftrag, ReCoMobilDokument, Unterschrift, ReCoMobilBild, ChecklisteStatus, BezahlartTyp, Auftragstyp, UvvPruefGegenstand, Tour, TourTyp, StatusmeldungZusatzinfo, Laderaum, AbfahrtskontrolleErgebnis, Fahrzeug, TourStatusLaderaum, TourStatus, LaderaumWiegung, Auftragsposition, AuftragspositionWiegung, Artikel, Vertrag, Vertragsposition, Artikelgruppe, Adresse, TourWiegung, GlasAnalyse, Personal, Zeiterfassung, Bericht, AbfahrtskontrolleEintrag, FahrzeugTyp, ReCoMobilCharge, ProtokollEintrag, AbfahrtskontrolleFormular, AbfahrtskontrolleGruppe, Fahrzeuggruppe, ZeitEintrag } from './swagger-model';
import { LaunchNavigatorOptions } from '@ionic-native/launch-navigator/ngx';

// export interface Textdatei {
//     typ: TextdateiTyp;
//     key: string;
//     geraeteNummer: string;
//     datum: string;
//     mandant: string;
//     auftrag?: Auftrag;
//     // nachricht?: Nachricht;
//     stammdaten?: Stammdaten;

//     // Fortlaufende Nummer / ID
//     // nummer?: number;

//     // TODO: Noch nicht verfügbar.
//     // Daten: string;
// }

// export enum TextdateiTyp {
//     Unbekannt = 0,
//     Auftrag = 1,
//     Nachricht = 2,
//     Stammdaten = 3
// }

export type AuftragsdateilsDarstellung = 'standard' | 'wizard' | 'wareneingang' | 'kommissionierung' | 'beladung';

export interface AuftragDto {
    key: string;
    auftrag: string;
    version: number;
    NichtFuerGeraet?: boolean;
}

export interface AuftragListItem {
    Key: string;
    OberAuftragKey: string;
    TourKey: string;
    Statuszeit: string;
    Auftragsstatus: number;
    Auftragsnummer: number;
    Reihenfolge: number;
    Geraet: string;
    GeraeteGruppe: string;
    PersonalKey: string;
    Personalnummer: string;
    Icon: string;
    Auftragstyp: Auftragstyp;
    AAbholdatum: string;
    AAbholzeitVon: string;
    AAbholzeitBis: string;
    AbholzeitText: string;
    AErfasser: string;
    Abholadresse: Adresse;
    FahrzeugKennung: string;
    FahrzeugKennung2: string;
    FahrzeugKennung3: string;
    Info1: string;
    Info2: string;
    Info3: string;
    Gelesen: boolean;
    UnterMandant: string;
    IstNeuanlage: boolean;
    GruppeKey: string;
    GruppeBezeichnung: string;
    ERPStatusFarbe: string;

    suchtext?: string;

    abholdatumTimestamp?: number;
    abholuhrzeitTimestamp?: number;

    gruppeReihenfolge?: number;
    gruppeListKey?: string;

    // Lokale Metadaten
    metadaten: AuftragMetadaten;

    hashKey?: string;

    oldIstZugewiesen?: boolean;
    istZugewiesen?: boolean;
    istAnderemGraetZugewiesen?: boolean;

    istAusgeblendet?: boolean;
    istListItem: boolean;
    itemHeight: string;
    info1MaxHeight: string;
    info2MaxHeight: string;
    info3MaxHeight: string;

    positionsInfo: PositionsInfo[];

    istVollePalette: boolean;
    stellplatzLkw: string;
    PaletteId?: string;
    Zusatzdaten?: AuftragZusatzdaten;

    status?: string;
    statusText?: string;

    label?: string;
    auftragsFarbe?: string;

    loeschMeldungWurdeAngezeigt?: boolean;

    zeiterfassungLaeuft?: boolean;
    zeiterfassungUnterbrochen?: boolean;
    
    manuelleReihenfolge: number;
}

export interface PositionsInfo {
    artikelKey: string;
    bezeichnung: string;
    menge: number;
    einheit: string;
}

export interface AuftragEx extends Auftrag {
    Unterschriften?: UnterschriftEx[];

    // Lokale Metadaten
    metadaten?: AuftragMetadaten;

    // Einstellungen zum Workflow
    Optionen?: AuftragOptionen;

    statusmeldungen?: Statusmeldung[];

    // suchtext: string;

    Bearbeitungsdauer?: number;

    /** Letzter Bearbeitungs-Start (Date()) */
    BearbeitungsStart?: string;

    Gelesen?: boolean;

    Anlagen?: Anlage[];

    Positionen?: AuftragspositionEx[];

    AusdruckNummer?: string;

    /** Handelt es sich um einen Clone */
    istClone?: boolean;

    /**
     * Soll-Positionen bei einem Wareneingang
     */
    SollPositionen?: AuftragspositionEx[];

    texteAusgeblendet?: boolean;

    istVollePalette?: boolean;

    label?: string;

    Zusatzdaten?: AuftragZusatzdaten; //  | { [key: string]: string; } | undefined;

    status?: string;
    statusText?: string;

    auftragsFarbe?: string;

    /**
     * Wurde der Auftrag aktualisiert
     */
    istAktualisiert?: boolean;

    /**
     * Eine eindeutige Kennung für die Instanz des Auftrag.
     * Wird beim Empfangen des Auftrags generiert.
     * Damit soll sichergestellt werden, dass keine alten Daten gespeichert werden
     */
    // InstanceVersion?: number;

    loeschMeldungWurdeAngezeigt?: boolean;

    zeiterfassungLaeuft?: boolean;
    zeiterfassungUnterbrochen?: boolean;
    
    manuelleReihenfolge?: number;
}

export enum AuftragZeiterfassungStatus {
    None = 0,
    Start = 1,
    Unterbrochen = 2,
    Ende = 3
}

export interface AuftragZusatzdaten {
    [key: string]: string;

    TourId?: string;
    LaderaumInfo?: string;
    TourNummer?: string;
    TourBezeichnung?: string;
    StellplatzLkw?: string;
    PaletteId?: string;
    IstVollePalette?: string;
    Hinweistext?: string;
    Status?: string;
    PosL?: string;
    PosB?: string;
    PosH?: string;
    SizeL?: string;
    SizeB?: string;
    SizeH?: string;
    Brutto?: string;
    StopNummer?: string;
    Farbe?: string;
}

export interface UnterschriftEx extends Unterschrift {
    // /** Rohdaten der Signatur */
    Data?: any;

    // imgSrc?: string;
}

export interface UvvPruefGegenstandEx extends UvvPruefGegenstand {
    naechstePruefungTimestamp?: number;
}

export interface Statusmeldung {
    datum: string;
    name: string;
    statusId: number;
    extraText: string;
    extraText2?: string;
    personalKey: string;
    personalName: string;
    personalnummer: string;
    abholstelleFertig?: boolean;
}

export interface AuftragOptionen {
    UnterschriftErforderlich: 'ja' | 'nein' | 'bei-aenderung';
    BildErforderlich: 'ja' | 'nein';
}

export interface AuftragMetadaten {
    modificationDate: string;
    empfangsDatum: string;
    version: number;
}

// export interface Adresse {
//     AdressNummer?: string;
//     Name1?: string;
//     Name2?: string;
//     Name3?: string;
//     Strasse?: string;
//     PLZ?: string;
//     Ort?: string;
//     LKZ?: string;
//     Telefon?: string;
//     Ansprechpartner?: string;
//     GeoX?: number;
//     GeoY?: number;
// }

// export interface Auftragsposition {
//     PosNr: number;
//     ArtikelKey?: number;
//     Bezeichnung?: string;
//     Menge?: number;
//     EinzelVK?: number;
//     Einheit?: string;
//     GewichtInKg?: number;
//     Eigenschaften?: Eigenschaft[];
//     Behaelter?: Behaelterbewegung[];

//     // Brutto der Position
//     Brutto?: number;

//     // TODO: Klären wie das gespeichert werden soll
//     Barcode?: string;

//     /** Aktion für Behälter: stellen, entleeren, tauschen, abziehen, umsetzen, warten */
//     BhAktion?: string;

//     /** Positione wurde nachträglich hinzugefügt */
//     IstNeu: boolean;
// }

export interface Behaelterbewegung {
    BehaelterNr?: string;
    Aktion?: string;
    IsScan?: boolean;
    Abstellort?: string;
    Zustand?: string;
}

export interface Eigenschaft {
    Bezeichnung?: string;
    Wert?: string;
    Typ?: string;
}

// export interface Hinweistext {
//     Art?: string;
//     Inhalt?: string;
// }

export interface Anlage extends ReCoMobilDokument {
    Pfad?: string;
}

export interface ReCoMobilBildEx extends ReCoMobilBild {
    imgSrc?: string;
}

export interface DeleteResult {
    success: boolean;
    message?: string;
}

export interface SaveResult {
    success: boolean;
    message?: string;
    id?: string;
}

export interface SendResult {
    success: boolean;
    message?: string;
}

export enum Permission {
    None = 0,
    ShowUsers = 1,
    EditUsers = 2,
    ShowProjects = 3,
    EditProjects = 4,
    ShowApplications = 5,
    EditApplications = 6,
    ShowDemandRequest = 7,
    EditDemandRequest = 8,
    CreateDemandRequest = 9,
    ShowUserRoles = 10,
    EditUserRoles = 11,
    Administration = 12,
    AdminDemandRequest = 13,
    ShowBusinessUnits = 14,
    EditBusinessUnits = 15,
    ShowLenders = 16,
    EditLenders = 17,
    ShowProfessions = 18,
    EditProfessions = 19,
    ShowQualifications = 20,
    EditQualifications = 21,
    ReadAuftrag = 22,
    ListAuftraege = 23,
}

export interface LoginResult {
    permissions?: Permission[];
    success: boolean;
    message?: string;
    token?: string;
    user?: User;
}

// export interface Fahrer extends DefaultEntity {
//     vorname?: string | undefined;
//     nachname?: string | undefined;
//     personalnummer?: string | undefined;
//     rfid?: string | undefined;
//     standardFahrzeug?: string | undefined;
//     passwortHash?: string | undefined;
//     neuesPasswort?: string | undefined;
// }

// export interface Fahrzeug extends DefaultEntity {
//     key?: string;
//     kennzeichen?: string;
//     standardFahrer?: string;
// }

export interface User extends DefaultEntity {
    username?: string;
    firstName?: string;
    surname?: string;
    password?: string;
    token?: string;
    deleted: boolean;
    deletionDate?: Date;
    deletedBy?: string;
    blocked: boolean;
    email?: string;
    isSysadmin: boolean;
    salt?: string;
    lastLogin?: Date;
    roles?: string[];
    welcomeMail?: boolean;
    passwortVergessenMailCount: number;
    passwortVergessenMailDate?: Date;
    fullName?: string;
    newEmail?: string;
    displayName?: string;
}

export interface LoginRequest {
    user?: string;
    password?: string;
    rememberMe: boolean;
}

export interface FileResponse {
    data: Blob;
    status: number;
    fileName?: string;
    headers?: { [name: string]: any };
}

// export interface Artikel {
//     artikelKey: number;
//     bezeichnung: string;
//     einheit?: string;
//     artikelGruppe?: string;
//     einzelVK?: number;
//     mwstSatz?: number;
//     eigenschaften?: ArtikelEigenschaft[];
// }

// export interface ArtikelEigenschaft {
//     sortierung: number;
//     bezeichnung?: string;
//     wert?: string;
//     typ?: string;
// }

export interface ReCoMobilEigenschaft {
    Bezeichnung?: string;
    Wert?: string;
    Typ?: string;
}

export enum ArtikelTyp {
    Standard = 0,
}

type DruckerTyp = 'zebra' | 'Druckdienst';
type JaNeinTyp = 'ja' | 'nein';
type DatenherkunftTyp = 'standard' | 'auftrag' | 'artikel';

// export enum Auftragstyp {
//     Unbekannt = 0,
//     Transportauftrag = 1,
//     Hofchecker = 2,
//     Alle = 999,
// }

// export interface WorkflowEinstellungen {
//     AdresseAnzeigen?: string;
//     LieferungAnzeigen?: string;
//     Fotos?: string;
//     Anmerkungen?: string;
//     Unterschrift?: string;
//     Navigation?: string;
// }

export interface AppEinstellungen {
    Farbe1?: string;
    Farbe2?: string;
    Logo?: string;
    CustomStlye?: string;
    KachelHoehe?: string;
    VerschluesslungAktiv?: string;
    MinAppVersion?: string;
    Nachrichten?: string;
    Transportauftraege?: string;
    Hofauftraege?: string;
    PositionenAufzeichnen?: string;
    PositionenSendenIntervall?: string;
    PositionenAufzeichnenIntervall?: string;
    PositionenStationaryRadius?: string;
    PositionenDistanceFilter?: string;
    PositionenActivitiesInterval?: string;
    PositionenDesiredAccuracy?: string;
    PositionenBeiStillstandSenden?: string;
    PositionenAufzeichnenAktivHalten?: string;
    PositionenDebugAktiv?: string;
    PositionenLocationProvider?: string;
    AlteAuftraegeLoeschenNachTagen?: string;
    MaxGeraete?: string;
    MaxBenutzer?: string;
    AnmeldungErforderlich?: JaNeinTyp;
    AktionBeiNeuenHofauftraegen?: 'klingeln' | 'beep' | 'keine';
    AktionBeiAktualisiertenHofauftraegen?: 'klingeln' | 'beep' | 'keine';
    AktionBeiNeuenTransportauftraegen?: 'klingeln' | 'beep' | 'keine';
    AktionBeiAktualisiertenTransportauftraegen?: 'klingeln' | 'beep' | 'keine';
    SicherheitshinweisAnzeigen?: string;
    SicherheitshinweisIntervall?: string;
    SicherheitshinweisText?: string;
    TourStartenErforderlich?: JaNeinTyp;
    AuftragBarcodeTyp?: string;
    AuftragBarcodeInhalt?: string;
    AuftragBarcodeButtonVerfuegbar?: string;
    AuftragFahrzeugFilterAktiv?: string;
    GekoppelteFahrzeuge?: string;
    SentryAktiv?: string;
    SentryDsn?: string;
    NurKlingelnWennAngemeldet?: string;
    BehaelterMitNfc?: JaNeinTyp;
    BehaelterMitBarcode?: JaNeinTyp;
    BehaelterAbstellorteVerwenden?: JaNeinTyp;
    UvvVerfuegbar?: JaNeinTyp;
    UvvSchnellpruefungVerfuegbar?: JaNeinTyp;
    UvvSchnellpruefungZugriffe?: string;
    UvvNaechstePruefungTageAnzeigen?: string;
    BehaelterNummerPruefung?: string;
    DokumenteVerfuegbar?: JaNeinTyp;
    AnmeldungStandardVerfahren?: string;
    DokumenteNurSichtbarWennAngemeldet?: string;
    NachtsAutomatischAbmelden?: JaNeinTyp;
    NachtsAutomatischTourEnde?: JaNeinTyp;
    AuftragScannenVerfuegbar?: string;
    AuftragScannenAuftragsnummerRegex?: string;
    AuftragScannenNeuanlageErlaubt?: string;
    AuftragScannenNeuanlageWorkflowName?: string;
    AufloesungFotos?: string;
    QualitaetFotos?: string;
    DruckerVerfuegbar?: string;
    DruckerTyp?: DruckerTyp;
    DruckerMacAdressen?: string;
    DruckerTestCode?: string;
    DruckerSuchenTimeout?: string;
    DruckenTimeout?: string;
    DruckerStandardFormular?: string;
    WaageVerfuegbar?: JaNeinTyp;
    WaageTyp?: string;
    WaageMacAdresse?: string;
    LogLevel?: string;
    NachrichtenMaxAlter?: string;
    NachrichtenSichtbarOhneAnmeldung?: string;
    NachrichtenSoundAbspielen?: string;
    NachrichtenSoundLautstaerke?: string;
    BehaelterNummerAbgelaufeneUvvPruefung?: string;
    BehaelterNummerBeschaedigungPruefung?: string;
    NfcFormat?: 'standard' | 'reverse';
    NfcPayloadVerwenden?: string;
    SignaturMinWidth?: string;
    SignaturMaxWidth?: string;
    GpsPositionBeiStatusmeldungTransportauftrag?: string;
    GpsPositionBeiStatusmeldungHofauftrag?: string;
    GpsPositionBeiBezahlung?: string;
    GpsPositionBeiBehaelterScan?: string;
    GpsPositionBeiUnterschrift?: string;
    GpsPositionBeiNachricht?: string;
    GpsPositionBeiAnmeldung?: string;
    GpsPositionBeiTour?: string;
    GpsPositionBeiUvv?: string;
    GpsPositionTimeout?: string;
    AppUpdateAktiv?: JaNeinTyp;
    AppUpdateVersion?: string;
    LogoAufUnterschritseiteAnzeigen?: JaNeinTyp;
    LogoAufSystemButtonAnzeigen?: JaNeinTyp;
    Schriftgroesse?: string;
    Animationen?: JaNeinTyp;
    Theme?: string;
    Layout?: string;
    Design?: string;
    TourStartModus?: string;
    TourAuswahlOptional?: string;
    WaageSimulationVerfuegbar?: string;
    WaageSoundBeiWiegungAktiv?: string;
    TourStartFahrzeugAuswahlRfid?: 'nein' | 'optional' | 'pflicht';
    TourStartAnhaengerAuswahlRfid?: 'nein' | 'optional' | 'pflicht';
    WaageAuftragAbschliessenMitOffnenWiegungen?: 'fehler' | 'warnung' | '';
    KommissionierungVerfuegbar?: string;
    BeladungVerfuegbar?: string;
    TourEndeWiegenBarcodesButtonsAnzeigen?: JaNeinTyp;
    ArtikelAuswahlUeberArtikelgruppen?: JaNeinTyp;
    ArtikelAuswahlVertraegeAnzeigen?: JaNeinTyp;
    PreisNachkommastellen?: string;
    TransportauftraegePositionHinzufuegenErlaubt?: JaNeinTyp;
    HofauftraegePositionHinzufuegenErlaubt?: JaNeinTyp;
    KommissionierungPositionHinzufuegenErlaubt?: JaNeinTyp;
    NavigationsApp?: string;
    MinAnzahlBilderInAuftragspositionenAddieren?: JaNeinTyp;
    TransportauftragLaderaumAuswahlAktiv?: JaNeinTyp;
    UnterschriftseiteDrehen?: JaNeinTyp;
    AuftragspositionenSuchButtonAnzeigen?: JaNeinTyp;
    NachrichtenSendenVerfuegbar?: JaNeinTyp;
    NavigationGeoKoordinatenBevorzugen?: JaNeinTyp;
    TransportauftragReihenfolgeAnzeigen?: JaNeinTyp;
    TourEndeWiegen?: 'ja' | 'nein' | 'gesamt';
    TourEndeLaderaeumeInhaltAuswahl?: JaNeinTyp;
    FileLoggerAktiv?: JaNeinTyp;
    FileLoggerMetadaten?: JaNeinTyp;
    HofauftraegePositionWiegenVerfuegbar?: JaNeinTyp;
    TransportauftragPositionWiegenVerfuegbar?: JaNeinTyp;
    TransportauftraegeAnGeraetegruppeManuellZuweisen?: JaNeinTyp;
    HofauftraegeAnGeraetegruppeManuellZuweisen?: JaNeinTyp;
    KommissionierungAuftraegeAnGeraetegruppeManuellZuweisen?: JaNeinTyp;
    WebSocketClientPingIntervall?: string;
    WebSocketClientTimeout?: string;
    UebersichtAnzeigen?: JaNeinTyp;
    TransportauftragNeuanlageErlaubt?: JaNeinTyp;
    HofauftraegeNeuanlageErlaubt?: JaNeinTyp;
    TransportauftragNeuanlageWorkflowName?: string;
    HofauftraegeNeuanlageWorkflowName?: string;
    HomeNavigationVerfuegbar?: JaNeinTyp;
    PositionsEigenschaftenImmerAnzeigen?: JaNeinTyp;
    AnhaengerVerfuegbar?: JaNeinTyp;
    AbfahrtskontrolleDauerProtokollieren?: JaNeinTyp;
    AbfahrtskontrolleMinDauer?: string;
    AbfahrtskontrolleDauerIgnorieren?: string;
    AnkunftskontrolleMinDauer?: string;
    TourAktivAnzeige?: 'datum' | 'timer' | 'aus';
    TourStartFahrzeugMerken?: JaNeinTyp;
    TourStartAnhaengerMerken?: JaNeinTyp;
    TransportauftragFahrzeugKennzeichenPruefen?: 'nein' | 'statusmeldung' | 'oeffnen';
    AuftragFooterAnzeigen?: JaNeinTyp;
    NachrichtenSymbolInTitelleiste?: 'ja' | 'nein' | 'vorhanden';
    GalaxyWatchVerfuegbar?: JaNeinTyp;
    TitelleisteAnzeigen?: JaNeinTyp;
    BenutzereinstellungenVerfuegbar?: JaNeinTyp;
    TourStartKilometerstand?: JaNeinTyp;
    TourEndeKilometerstand?: JaNeinTyp;
    TourStartMehrereTourenMoeglich?: JaNeinTyp;
    TourLadenVerfuegbar?: JaNeinTyp;
    TourEndeAuswahl?: 'nein' | 'A' | 'T';
    SmartwatchAnbindung?: 'websocket' | 'samsungaccessoryservice';
    FahrerHinweistexteAnzeigen?: JaNeinTyp;
    PersonalnummerNurZiffern?: JaNeinTyp;
    PasswortNurZiffern?: JaNeinTyp;
    BehaelterNummerNurZiffern?: JaNeinTyp;
    PositionenExaktMeter?: string;
    UvvBehaelterstandort?: JaNeinTyp;
    UvvZustand?: JaNeinTyp;
    UvvFreigabeText?: string;
    UvvAuswahlAktion?: 'nein' | 'reparieren-verschrotten';
    UvvRfidTauschErlaubt?: JaNeinTyp;
    AuftragKontakteAlleAnzeigen?: JaNeinTyp;
    NavigationGeocodingErlauben?: JaNeinTyp;
    GoogleApiKey?: string;
    AlleEigenschaftenUppercase?: JaNeinTyp;
    TransportauftragMengeImmerEingeben?: JaNeinTyp;
    TransportauftragMengeSollAnzeigen?: JaNeinTyp;
    TransportauftragWarnungAbweichungSoll?: string;
    TransportauftragDarstellung?: 'standard' | 'wizard';
    AbfahrtskontrolleAlleMarkierenTouchDauer?: string;
    Datumeingabe?: 'datetime' | 'text' | 'native';
    AuftragStartenBeiAenderung?: JaNeinTyp;
    StoerzeitenVerfuegbar?: JaNeinTyp;
    TourZeiterfassungAktiv?: JaNeinTyp;
    NativeDialogeBevorzugen?: JaNeinTyp;
    MenueAuftragslisteAnzeigen?: JaNeinTyp;
    MenueAuftragslisteTourStart?: JaNeinTyp;
    MenueAuftragslisteTourEnde?: JaNeinTyp;
    MenueAuftragslisteTourArchivWechseln?: JaNeinTyp;
    MenueAuftragdetailsAnzeigen?: JaNeinTyp;
    AdvancedHttpVerwenden?: JaNeinTyp;
    LogLevelNavigation?: string;
    ProgloveVerfuegbar?: JaNeinTyp;
    StationaereWaagenKeys?: string;
    StationaereWaagenGpsPruefen?: JaNeinTyp;
    KommisionierungBarcodeRegEx?: string;
    KommisionierungBarcodeArtikelNrGroupNumber?: string;
    KommisionierungBarcodeChargeGroupNumber?: string;
    KommisionierungBarcodeGTINGroupNumber?: string;
    KommisionierungBarcodeNVEGroupNumber?: string;
    KommisionierungBarcodeMHDGroupNumber?: string;
    KommisionierungPaletteDruckWennFertig?: string;
    LiveDatenVerfuegbar?: JaNeinTyp;
    HomeKundenAnzeigen?: JaNeinTyp;
    AuftraegeSynchronisierenIntervall?: string;
    AuftraegeSynchronisierenUhrzeitVon?: string;
    AuftraegeSynchronisierenUhrzeitBis?: string;
    TransportauftragArtikelAendern?: JaNeinTyp;
    HofauftragArtikelAendern?: JaNeinTyp;
    KommissionierungArtikelAendern?: JaNeinTyp;
    WareneingangVerfuegbar?: JaNeinTyp;
    WareneingangNeuanlageErlaubt?: JaNeinTyp;
    WareneingangeuanlageWorkflowName?: string;
    TourEndeTourKeyAnzeigen?: JaNeinTyp;
    TourEndeSonderablauf?: '' | 'TBN1';
    TourEndeSonderablaufParam1?: string;
    TourEndeSonderablaufParam2?: string;
    TourEndeSonderablaufParam3?: string;
    TourEndeSonderablaufParam4?: string;
    TourEndeSonderablaufParam5?: string;
    FuehrerscheinkontrolleVerfuegbar?: JaNeinTyp;
    FuehrerscheinkontrolleIntervall?: 'jahr' | 'quartal' | 'monat' | 'woche' | 'tag';
    FuehrerscheinkontrolleMaxVerschiebenTage?: string;
    FuehrerscheinkontrolleAblaufdatumErfassen?: JaNeinTyp;
    FuehrerscheinkontrolleUnterschriftErfassen?: JaNeinTyp;
    FuehrerscheinkontrolleFotos?: JaNeinTyp;
    FuehrerscheinkontrolleNFCScannen?: JaNeinTyp;
    FuehrerscheinkontrolleAblaufdatumKey?: string;
    FuehrerscheinkontrolleLetztePruefungKey?: string;
    KlingenAuftragAnzeigenErlaubt?: JaNeinTyp;
    HofAktivVerwenden?: JaNeinTyp;
    TransportauftragMaxSichtbareAuftraege?: string;
    UvvNeueRfidPruefen?: 'fehler' | 'warnung' | 'aus';
    ArtikelListeFormat?: string;
    FocusErstePosition?: JaNeinTyp;
    BehaelterAnlegenVerfuegbar?: JaNeinTyp;
    BehaelterAnlegenHerstellernummerPflicht?: JaNeinTyp;
    GlasAnalyseVerfuegbar?: JaNeinTyp;
    GlasAnalyseTageSpeichern?: string;
    GlasAnalyseMinFotos?: string;
    TourEndeWiegenErstwiegungIstLeergewicht?: JaNeinTyp;
    TourEndeWiegenWiegescheinNr?: JaNeinTyp;
    TourEndeWiegenWiegescheinBild?: JaNeinTyp;
    TourEndeWiegenWiegescheinBildPflicht?: JaNeinTyp;
    TourEndeWiegenArtikelauswahl?: JaNeinTyp;
    TourEndeWiegenHandeingabePIN?: string;
    DatenherkunftMengeOption?: DatenherkunftTyp;
    DatenherkunftStandardMenge?: DatenherkunftTyp;
    DatenherkunftAuftragWiegungAktiv?: DatenherkunftTyp;
    DatenherkunftLaderaumAuswahlAktiv?: DatenherkunftTyp;
    DatenherkunftSeriennummerErforderlich?: DatenherkunftTyp;
    TourEndeOhneWiegungMitMeldung?: JaNeinTyp;
    TourEndeWiegenErforderlich?: 'nein' | 'erzwingen' | 'nur-mit-pin';
    TourEndeOhneWiegePIN?: string;
    BeifahrerAuswaehlbar?: JaNeinTyp;
    Beifahrer2Auswaehlbar?: JaNeinTyp;
    BeifahrerRFIDScannen?: JaNeinTyp;
    TourStartZeitaenderbar?: JaNeinTyp;
    KommissionierungPaletteLabel?: 'stellplatz' | 'nummer';
    TransportauftragPositionWiegenAbschliessenOhneWiegungen?: JaNeinTyp;
    TransportauftragPositionWiegenHandeingabe?: JaNeinTyp;
    TransportauftragPositionWiegenLeergewicht?: JaNeinTyp;
    HofauftraegePositionWiegenHandeingabe?: JaNeinTyp;
    HofauftraegePositionWiegenLeergewicht?: JaNeinTyp;
    AuftragZeilenhoehe?: 'Standard' | 'Dynamisch' | 'Fix';
    AuftragZeilenhoeheMin?: string;
    AuftragInfoTextMaxLaenge?: string;
    WiegenMitExakterGpsPosition?: JaNeinTyp;
    KlingelnDelay?: string;
    TransportauftragNeuanlageArt?: 'Standard' | 'Vertrag' | 'Adresse';
    TransportauftragMinAnzahlBilderBeiMengenaenderung?: string;
    TransportauftragMinAnzahlBilderEinheiten?: string;
    TourEndeWiegenLeergewichtAendernErlaubt?: JaNeinTyp;
    TransportauftraBehaelterNummerHandeingabeErlaubt?: JaNeinTyp;
    WaagenTyp?: 'welvaarts' | 'sywatec' | '';
    Zeiterfassung?: JaNeinTyp;
    TransportauftragLeerePositionenAusblenden?: JaNeinTyp;
    AuftragGeloeschtMeldungImmerAnzeigen?: JaNeinTyp;
    AuftragGeloeschtMeldungAlsNotification?: JaNeinTyp;
    UebersichtEinheitAnzeigen?: JaNeinTyp;
    AuftragZeiterfassungAktiv?: JaNeinTyp;
    AuftragZeiterfassungManuellBearbeiten?: JaNeinTyp;
    AuftragZeiterfassungTyp?: 'Einfach' | 'MitArt';
    TourEndeTankenLiterEingeben?: JaNeinTyp;
    AuftragGruppenAnzeigen?: JaNeinTyp;
    GlasAnalyseBleiProbeAbUhrzeit?: string;
    GlasAnalyseBleiProbeErzwingenUhrzeit?: string;
    GlasAnalyseSM3SiebprobeUhrzeiten?: string;
    GlasAnalyseKSPMeldung1?: string;
    GlasAnalyseKSPMeldung2?: string;
    GlasAnalysePIN?: string;
    TourStartLeergewichtWiegen?: JaNeinTyp;
    Berichte_Unfallbericht?: JaNeinTyp;
    Berichte_Urlaubantrag?: JaNeinTyp;
    Berichte_Taetigkeitsbericht?: JaNeinTyp;
    Berichte_Tankbericht?: JaNeinTyp;
    Berichte_Maengelbericht?: JaNeinTyp;
    Berichte_Maengelbericht_ohneReifen?: JaNeinTyp;
    Berichte_Fahrzeugkontrolle?: JaNeinTyp;
    Berichte_Hofdokumentation?: JaNeinTyp;
    Berichte_DesinfektionKontrollbuch?: JaNeinTyp;
    WaageNettoBerechnung?: 'aus' | 'neeto-berechnen';
    KommissionierungMengeBestaetigen?: JaNeinTyp;
    KommissionierungChargenverfolgungNVE?: JaNeinTyp;
    AuftragERPStatusFarbeVerwenden?: JaNeinTyp;
    HofauftragZeile1Anzeigen?: JaNeinTyp;
    HofauftragZeile2Anzeigen?: JaNeinTyp;
    HofauftragZeile3Anzeigen?: JaNeinTyp;
    HofauftragAbholdatumAnzeigen?: JaNeinTyp;
    TransportauftragZeile1Anzeigen?: JaNeinTyp;
    TransportauftragZeile2Anzeigen?: JaNeinTyp;
    TransportauftragZeile3Anzeigen?: JaNeinTyp;
    TransportauftragAbholdatumAnzeigen?: JaNeinTyp;
    EPImmerAnzeigen?: JaNeinTyp;
    TourStartWiegen?: 'nein' | 'optional' | 'pflicht';
    KlingenJedenAuftragAnzeigen?: 'nur-letzten' | 'jeden';
    UhrzeitMinutenIntervall?: string;
    AuftragZeiterfassungNurEinAktiverAuftrag?: JaNeinTyp;
    DesinfektionKontrollbuchKeys?: string;
    TourEndeUnterschriftErfassen?: JaNeinTyp;
    TourEndeUnterschriftNameErfassen?: JaNeinTyp;
    TourEndeZeitaenderbar?: JaNeinTyp;
    TourEndeDatumaenderbar?: JaNeinTyp;
    FotosTextFont?: string;
    FotosTextFarbe?: string;
    FotosTextAuftrag?: string;
    FotosTextTourStart?: string;
    FotosTextTourEnde?: string;
    FotosTextUVV?: string;
    FotosTextFahrzeugkontrolle?: string;
    FotosTextSonstige?: string;
    TransportauftragPositionWiegenSofortSenden?: JaNeinTyp;
    TransportauftragPositionWiegenSofortSendenStatus?: string;
    TransportauftragBearbeitbarRegel?: string;
    GeraetegruppeAendernErlaubt?: JaNeinTyp;
    TransportauftraBehaelterNummerHandeingabeFotoErforderlich?: JaNeinTyp;
    SpracheWechseln?: JaNeinTyp;
    SpracheStandard?: string;
    SpracheDE?: JaNeinTyp;
    SpracheEN?: JaNeinTyp;
    SprachePL?: JaNeinTyp;
    TransportauftragSortieren?: JaNeinTyp;
    HofauftragSortieren?: JaNeinTyp;

    Berichte_Urlaubantrag_LoeschenErlaubt?: JaNeinTyp;
    Berichte_Unfallbericht_LoeschenErlaubt?: JaNeinTyp;
    Berichte_Taetigkeitsbericht_LoeschenErlaubt?: JaNeinTyp;
    Berichte_Tankbericht_LoeschenErlaubt?: JaNeinTyp;
    Berichte_Maengelbericht_LoeschenNachTagen?: JaNeinTyp;
    Berichte_Fahrzeugkontrolle_LoeschenErlaubt?: JaNeinTyp;
    Berichte_Hofdokumentation_LoeschenErlaubt?: JaNeinTyp;
    Berichte_DesinfektionKontrollbuch_LoeschenErlaubt?: JaNeinTyp;
    Berichte_Maengelbericht_LoeschenErlaubt?: JaNeinTyp;
}

export interface AppLoginRequest {
    mandant: string;
    geraeteNummer: string;
    passwort: string;
    appVersion: number;
    deviceId: string;
}

export interface LokalesDokument extends Dokument {
    filePath?: string;
    lesebestaetigungen?: Lesebestaetigung[];

    // ACHTUNG: Wenn hier etwas geändert wird, muss Utils.areEqual in stammdaten.service.ts angepasst werden !
}

export interface Lesebestaetigung {
    personalnummer: string;
    geoeffnet: boolean;
    geoeffnetDatum: string;
    gelesen: boolean;
    gelesenDatum: string;
}

// export interface Workflow {
//     name: string;
//     statusuebergaenge: StatusuebergaengeMap;
//     status: WorkflowStatus[];
//     einstellungen: WorkflowEinstellungen;
// }

export interface StatusuebergaengeMap {
    [key: string]: string[];
}

// export interface WorkflowStatus {
//     name: string;
//     icon?: string;
//     key?: string;
//     sortierung?: number;

//     // Aktionen die der Benutzer vor Statusmeldung erledigen muss

//     /** Es muss mindestens ein Bild/Foto vorhanden sein */
//     bildErforderlich?: boolean;

//     /** Unterschrift muss vorhanden sein */
//     unterschriftErforderlich?: boolean;

//     /** Anmerkung muss vorhanden sein */
//     anmerkungErforderlich?: boolean;

//     // Daten die an den Server übertragen werden sollen

//     /** GPS-Position senden */
//     geopositionSenden?: boolean;

//     /** Änderbare Daten senden (Anmerkungen, Positionen) */
//     auftragsdatenSenden?: boolean;

//     /** Bilder senden */
//     bilderSenden?: boolean;

//     /** Unterschrift senden */
//     unterschriftSenden?: boolean;

//     /** Auftrag abschließen */
//     auftragAbschliessen?: boolean;

//     auftragsstatus?: number;
// }

export interface PushRegistrationRequest {
    uniqueDeviceId: string;
    registrationId: string;
    registrationType: string;
    platform: string;
}

// export interface AppStammdatenResult {
//     artikel: Artikel[];
//     workflows: Workflow[];
//     formulare: MandantFormulare;
// }

export interface PushMessageHeader {
    /** Message type */
    t: PushMessageType;

    ackId?: number;
}

export interface StatusUpdatePushMessage extends PushMessageHeader {
    /** Neue Aufträge vorhanden */
    a?: number;

    /** Neue Stammdaten vorhanden */
    s?: number;

    /** Neue Einstellungen vorhanden */
    e?: number;

    /** Fahrer geändert */
    d?: number;

    /** Fahrzeuge geändert */
    f?: number;

    /** UVV-Daten geändert */
    uvv?: number;

    /** Neue Nachricht vorhanden */
    n?: number;

    /** Neue Touren vorhanden */
    tour?: number;

    /** Neue Changelog-Eintrag vorhanden */
    changelog?: number;

    /** Texte neu laden */
    texte?: number;
}

export interface GeoPositionenPushMessage extends PushMessageHeader {
    i: GpsPositionCompressed[];
}

export interface GlasAnalysePushMessage extends PushMessageHeader {
    GlasAnalyse: GlasAnalyse;
}

export interface GlasAnalyseErinnerungPushMessage extends PushMessageHeader {
    GlasAnalyse: GlasAnalyse;
}

export interface AuftragUpdatePushMessage extends PushMessageHeader {
    key: string;
    auftragsstatus?: number;
    datum?: string;
    geraet?: string;
    personalKey?: string;
    geaendert?: boolean;
}

export interface SmartwatchKopplungPushMessage extends PushMessageHeader {
    GeraeteNummer?: string;
    Status?: 'getrennt' | 'verbunden';
    SmartwatchId?: string;
    MandantName?: string;
    SmartwatchName?: string;
}

export enum PushMessageType {
    Unknown = 0,
    Ping = 1,
    Pong = 2,
    StatusUpdate = 3,
    Positionen = 4,
    Login = 5,
    AuftragUpdate = 6,
    DiagnosedatenAnfordern = 7,
    LoginResult = 8,
    WiegenRequest = 9,
    WiegenResult = 10,
    FahrzeugUpdate = 11,
    Nachricht = 12,
    SmartwatchPalette = 13,
    SmartwatchPaletteResult = 14,
    SmartwatchPing = 15,
    SmartwatchPong = 16,
    SmartwatchLoginResult = 17,
    SmartwatchKopplung = 18,
    NachrichtenLoeschen = 19,
    NachrichtGelesen = 20,
    Hello = 21,
    TestContent = 22,
    Statusmeldung = 23,
    GeraetStatus = 24,
    PushConfig = 25,
    LiveRequest = 26,
    LiveResult = 27,
    Ack = 28,
    ExportMessage = 29,
    GlasAnalyse = 30,
    GlasAnalyseErinnerung = 31
}

export interface GpsPositionCompressed {
    /** Latitude */
    a: number;
    /** Longitude */
    b: number;
    /** Höhe */
    h?: number;
    /** Timestamp */
    s: number;
    /** Accuracy */
    u?: number;
    /** Speed */
    v?: number;
    /** Bearing */
    w?: number;
    /** Provider */
    p?: string;
}

export interface LoginPushMessage extends PushMessageHeader {
    /** Mandant */
    m: string;

    /** GeraeteName */
    g: string;

    /** Token */
    x: string;

    /** DeviceId */
    d: string;

    /** AppVersion */
    v: string;
}

export interface FahrzeugUpdatePushMessage extends PushMessageHeader {
    Fahrzeuge: FahrzeugDto[];
}

export interface Ringtone {
    Name: string;
    Url: string;
}

// export interface TourStatus {
//     Key?: string;
//     Statuszeit?: string;
//     TourKey?: string;
//     TourTyp?: TourTyp;
//     Status?: TourStatusTyp;
//     Geraet?: string;
//     Personalnummer?: string;
//     Fahrzeug?: string;
//     FahrzeugKey?: string;
//     Kilometerstand?: number;
//     Uhrzeit?: string;
//     Datum?: string;
//     Abfahrtskontrolle?: AbfahrtskontrolleStatus[];
// }

// export enum TourStatusTyp {
//     None = 0,
//     Start = 1,
//     Unterbrochen = 2,
//     Ende = 3
// }

// export enum TourTyp {
//     Standard = 0,
//     AdHoc = 1
// }

export interface ChecklisteVorgabe {
    ChecklisteName?: string;
}

export interface ChecklisteErgebnis {
    ChecklisteName?: string;
    Eintraege: ChecklisteStatus[];
}

export interface DownloadFile {
    auftragKey?: string;
    guid: string;
    filename: string;
    status: number;
    errorCount: number;
    size: number;
    filePath: string;
}

export interface WaageDevice {
    name: string;
    address: string;
    id: string;
}

export interface GpsLocation {
    /** ID of location as stored in DB (or null) */
    id: number;

    /**
     * Native provider reponsible for location.
     *
     * Possible values:
     * "gps", "network", "passive" or "fused"
     */
    provider: string;

    /** Configured location provider. */
    locationProvider: number;

    /** UTC time of this fix, in milliseconds since January 1, 1970. */
    time: number;

    /** Latitude, in degrees. */
    latitude: number;

    /** Longitude, in degrees. */
    longitude: number;

    /** Estimated accuracy of this location, in meters. */
    accuracy: number;

    /**
     * Speed if it is available, in meters/second over ground.
     *
     * Note: Not all providers are capable of providing speed.
     * Typically network providers are not able to do so.
     */
    speed: number;

    /** Altitude if available, in meters above the WGS 84 reference ellipsoid. */
    altitude: number;

    /** Bearing, in degrees. */
    bearing: number;

    /**
     * True if location was recorded by mock provider. (ANDROID ONLY)
     *
     * Note: this property is not enabled by default!
     * You can enable it "postTemplate" configure option.
     */
    isFromMockProvider?: boolean;

    /**
     * True if device has mock locations enabled. (ANDROID ONLY)
     *
     * Note: this property is not enabled by default!
     * You can enable it "postTemplate" configure option.
     */
    mockLocationsEnabled?: boolean;
}

export enum GeraetStatusTyp {
    Unbekannt = 0,
    Verbunden = 1,
    NichtVerbunden = 2,
    Suche = 3,
    WirdGedruckt = 4,
    Fehler = 5
}

export interface StatusmeldungZusatzdaten {
    // Druck
    druckFormular?: string;
    druckDaten?: any;

    // Bezahlung
    istBezahlung?: boolean;
    bezahlungBetrag?: number;
    bezahlungArt?: BezahlartTyp;

    zusatzinfo?: StatusmeldungZusatzinfo;
}

export interface BluetoothDevice {
    name: string;
    address: string;
    id: string;
    class?: string;
}

export interface WaageProtokoll {
    waageAdresse: string;
    eintraege: WaageProtokollEintrag[];
}

export interface WaageProtokollEintrag {
    typ: string;
    daten: string;
    rohdaten: string;
    timestamp: number;
}

export interface OverlayButton {
    text: string;
    color: string;
    click: () => void;
}

export interface CustomAntwort {
    value: string;
    cssTyp?: string;
    schlecht?: boolean;
}

export interface ChecklisteEintragConfig {
    moeglicheAntworten: CustomAntwort[];
    default: string;
}

export interface TourEx extends Tour {
    typ?: TourTyp;
}

export interface FahrzeugDto {
    Id?: string;
    Key?: string | undefined;
    Kennzeichen?: string | undefined;
    StandardFahrer?: string | undefined;
    FahrzeugGruppe?: string | undefined;
    Rfid?: string | undefined;
    Laderaeume?: Laderaum[] | undefined;
    Abfahrtskontrolle?: AbfahrtskontrolleErgebnis | undefined;
    Leergewicht?: number;
}

export interface PushRegistration {
    registrationId: any;
    registrationType: any;
}

export interface TourStatusEx extends TourStatus {
    Laederaeume?: TourStatusLaderaumEx[];
    Wiegung?: TourWiegungEx;
    TourStartWiegung?: LaderaumWiegungEx;

    FahrerName?: string;
    TourKey2?: string;
    TourKey3?: string;
    TourKey4?: string;
    TourKey5?: string;
}

export interface LaderaumWiegungEx extends LaderaumWiegung {
    Erfolgreich?: boolean;
    Text?: string;
    Bezeichnung?: string;
    readOnly?: boolean;
}

export interface TourWiegungEx extends TourWiegung {
    Wiegungen?: LaderaumWiegungEx[];
}

export interface TourStatusLaderaumEx extends TourStatusLaderaum {
    Wiegungen?: LaderaumWiegungEx[];
    Inhalt?: string;

    laderaumWiegenBarcodesButtonsAnzeigen?: boolean;
    laderaumWiegen1ButtonColor?: string;
    laderaumWiegen2ButtonColor?: string;
}

export interface HostInfo {
    url: string;
    datumOnline: number;
    failCounter: number;
}

export interface AuftragspositionEx extends Auftragsposition {
    Wiegungen2?: AuftragspositionWiegungEx[] | undefined;
    NettoGewicht?: number;
    Fertig?: boolean;
    ArtikelGruppe?: string;
    Chargen?: ReCoMobilChargeEx[];
}

export interface ReCoMobilChargeEx extends ReCoMobilCharge {
    /** Rest-Menge auf der Palette */
    restMenge?: number;
    restMengeZuGering?: boolean;
}

export interface AuftragspositionWiegungEx extends AuftragspositionWiegung {
    Erfolgreich?: boolean;
    Text?: string;
    Bezeichnung?: string;
}

export interface SyncAuftragStatus {
    index?: number;
    anzahlGesamt?: number;
    text: string;
}

export interface ArtikelListItem {
    artikel?: Artikel;
    artikelgruppe?: Artikelgruppe;
    vertrag?: Vertrag;
    vertragsposition?: Vertragsposition;
    style?: string;
    text?: string;
    subtext?: string;
    suchtext?: string;
    sortText?: string;
}

export interface LieferantListItem {
    // TODO
    adresse: Adresse;
    style?: string;
    text?: string;
    subtext?: string;
    suchtext?: string;
    sortText?: string;
    istFavorit?: boolean;
}

export interface VertragListItem {
    vertrag: Vertrag;
    style?: string;
    text?: string;
    subtext?: string;
    suchtext?: string;
    sortText?: string;
    istFavorit?: boolean;
}

export interface AdresseListItem {
    adresse: Adresse;
    style?: string;
    text?: string;
    subtext?: string;
    suchtext?: string;
    sortText?: string;
    istFavorit?: boolean;
}

export interface LanguageListItem {
    language: string;
    text?: string;
    icon?: string;
}

export interface LaunchNavigatorPlugin {
    APP: {
        APPLE_MAPS: "apple_maps";
        BAIDU: "baidu";
        BING_MAPS: "bing_maps";
        CABIFY: "cabify";
        CITYMAPPER: "citymapper";
        EARTH: "com.google.earth";
        GAODE: "gaode";
        GEO: "geo";
        GOOGLE_MAPS: "google_maps";
        HERE_MAPS: "here_maps";
        LYFT: "lyft";
        MAPS_ME: "maps_me";
        MOOVIT: "moovit";
        NAVIGON: "com.navigon.navigator_checkout_eu40";
        PTV_NAVIGATOR: "com.ptvag.navigation.app";
        SYGIC: "sygic";
        TAXIS_99: "taxis_99";
        TOMTOM: "tomtom";
        TRANSIT_APP: "transit_app";
        UBER: "uber";
        USER_SELECT: "user_select";
        WAZE: "waze";
        YANDEX: "yandex";
    };

    APPS_BY_PLATFORM: {
        android: string[];
        ios: string[];
        windows: string[];
    };

    APP_NAMES: any;

    isAppAvailable: (appName: string, success: () => void, error: (err) => void) => void;

    navigate: (destination: string, options: LaunchNavigatorOptions) => void;

    setApiKey: (apiKey: string, success: () => void, error: (err) => void) => void;
}

export interface UnterschriftInfo {
    rolle: string;
    titel: string;
}

export interface ProgloveDeviceInfo {
    serialNumber?: string;
    firmwareRevision?: string;
    batteryLevel?: number;
    bceRevision?: string;
    modelNumber?: string;
    appVersion?: string;
}

export type ProGloveStatusType = 'GETRENNT' | 'VERBUNDEN' | 'SUCHE' | 'WIRD VERBUNDEN';

export interface Barcode {
    text: string;
    format: string;
    decodeData?: any;
    source?: 'PROGLOVE' | 'ZEBRA' | 'SIMULATION';
}

export interface WareneingangScan {
    artikelNr: number;
    charge: string;
    gtin: string;
    nve: string;
    mhd: string;
    menge: number;
}

export interface BarcodeEvent {
    barcode: Barcode;
    handled: boolean;
}

export interface DruckOptions {
    anzahl: number;
}

export class BarcodeFormat {
    static GS1_128 = 'GS1-128';
    static GS1_DATAMATRIX = 'GS1 DATAMATRIX';
}

export interface NfcEvent {
    id?: number[];
    seriennummer?: string;
    seriennummer2?: string;
    payload?: string;
    payloadList?: string[];
    handled?: boolean;
}

export interface ArtikelEx extends Artikel {
    text?: string;
}

export interface FahrzeugEx extends Fahrzeug {
    istFavorite?: boolean;
    favoriteIdx?: number;
}

export interface PersonalEx extends Personal, ISelectItem {
    anzeigeName?: string;
    istFavorite?: boolean;
    favoriteIdx?: number;
}

export interface GlasAnalyseEx extends GlasAnalyse {
    style?: string;
}

export interface BildVO {
    src: string;
    bild?: string;
    guid: string;
    datum: string;
}

export interface ZeiterfassungEx extends Zeiterfassung {
    // TODO
}

export interface ISelectItem {
    text?: string;
    istFavorite?: boolean;
}

export interface ListHeaderOptions {
    auftragFahrzeugFilterAktiv?: boolean;
    gruppenAnzeigen?: boolean;
}

export interface BerichtEx extends Bericht {
    subtext?: string;
}

export interface AbfahrtskontrolleVO {
    typ: FahrzeugTyp;
    fahrzeug: Fahrzeug;
    fahrzeuggruppe: Fahrzeuggruppe;
    name: string;
    eintraege: AbfahrtskontrolleEintragVO[];
    gruppen: AbfahrtskontrolleGruppeVO[];
    formular?: AbfahrtskontrolleFormular;
    anzahlAusgeblendeteEintraege: number;
}

// export interface AbfahrtskontrollListe {
//     typ: FahrzeugTyp;
//     eintraege: AbfahrtskontrolleEintragVO[];
//     formular?: AbfahrtskontrolleFormular;
// }

export interface AbfahrtskontrolleEintragVO {
    eintrag: AbfahrtskontrolleEintrag;
    ok?: boolean;
    nok?: boolean;
    okButtonColor?: string;
    nokButtonColor?: string;
    anmerkungen?: string;
    anmerkungenMoeglich: boolean;
    anmerkungenPflicht: boolean;
    bilder: BildVO[];
    bilderMoeglich: boolean;
    bilderPflicht: boolean;
    isInGruppe?: boolean;

    istAusgeblendet: boolean;
    naechstePruefung: string;
    naechstePruefungIso?: string;
}

export interface AbfahrtskontrolleGruppeVO {
    gruppe: AbfahrtskontrolleGruppe;
    ok?: boolean;
    nok?: boolean;
    okButtonColor?: string;
    nokButtonColor?: string;

    eintraege: AbfahrtskontrolleEintragVO[];
}

export interface TaetigkeitItem {
    Nummer: number;
    DatumVon?: string;
    DatumBis?: string;
    UhrzeitVon?: string;
    UhrzeitBis?: string;
    Eingestempelt?: boolean;
    Ausgestempelt?: boolean;
    Beschreibung?: string;
    Uebernachtung?: string;
}

export interface ProtokollEintragEx extends ProtokollEintrag {
    extraText?: string;
}

export interface KlingenDaten {
    aktion: string;
    neueAuftraege: Auftrag[];
}


export interface ZeitEintragEx extends ZeitEintrag {
    auftragName1?: string;
    auftragName2?: string;
    auftragInfo1?: string;
    auftragInfo2?: string;
    auftragInfo3?: string;
    gesendet?: boolean;
}

export interface ShowAlertOptions {
    text: string;
    header?: string;
    awaitDismiss?: boolean;
    transalte?: boolean;
}

export interface ScanNfcOptions {
    prio?: number;
    simulateSeriennummer?: string;
    simulateTimeout?: number;
}

export enum NeutralWorkflowAktuelleAnsichtTyp {
    Unbekannt = 0,
    Erzeuger = 1,
    Entsorger = 2
}

export interface AdresseEx extends Adresse {
    navigationDeaktiviert?: boolean;
}

export interface SpeichereAuftragOptions {
    listItemAktualisieren?: boolean;
}