import { Injectable, NgZone } from '@angular/core';
import { AppConfig, DruckerKonfiguration } from './helper/app.config';
import { BehaviorSubject, Subject } from 'rxjs';
import { Logger, LogLevel } from './helper/app-error-logger';
import { Platform } from '@ionic/angular';
import { App } from './helper/app';
import { Printer, PrintOptions } from '@ionic-native/printer/ngx';

import { delay } from 'rxjs/operators';

import { Utils } from './helper/utils';
import { ZbtPrinter } from './model/zebra-printer';
import { SystemService } from './system.service';
import { UiHelper } from './helper/ui-helper';
import { GeraetStatusTyp } from './model/model';

declare var cordova: any;

@Injectable({
    providedIn: 'root'
})
export class DruckerService {
    private log = new Logger("DruckerService", LogLevel.INFO);

    druckerStatus = new BehaviorSubject<GeraetStatusTyp>(GeraetStatusTyp.Unbekannt);

    /**
     * Liste aktuell verbundener Drucker
     */
    verbundeneDrucker = new BehaviorSubject<DruckerKonfiguration[]>([]);

    sucheDruckerPromise: Promise<string[]> = null;
    zebraStatusPromise: Promise<boolean> = null;

    druckenPromise: Promise<boolean> = null;

    letzteSuche = 0;

    updateIntervalId: any = null;

    sucheDruckerLaeuft = false;
    zebraStatusLaeuft = false;
    wirdGedruckt = false;

    constructor(
        private systemService: SystemService,
        private platform: Platform,
        private printer: Printer,
        private ngZone: NgZone,) {

    }

    async init() {
        this.log.debug('init');

        try {
            await delay(10 * 1000);

            this.systemService.istImVordergrund.subscribe(() => {
                this.updateTimer();
            });

            App.current.configRefreshed.subscribe(() => this.updateTimer());
        } catch (err) {
            this.log.error('initialize', err);
        }
    }

    updateTimer() {
        if (this.updateIntervalId) {
            clearInterval(this.updateIntervalId);
            this.updateIntervalId = null;
        }

        if (AppConfig.current.einstellungen.DruckerVerfuegbar !== 'ja') {
            return;
        }

        const imVordergrund = this.systemService.istImVordergrund.getValue();

        const interval = imVordergrund ? 60 : 300;

        this.updateIntervalId = setInterval(() => {
            this.update();
        }, interval * 1000);

        if (imVordergrund) {
            this.update();
        }
    }

    update() {
        if (AppConfig.current.einstellungen.DruckerVerfuegbar !== 'ja') {
            return;
        }

        this.log.debug('update');

        try {
            let timeout = 30 * 1000;

            if (this.druckerStatus.getValue() === GeraetStatusTyp.Verbunden) {
                // Wenn der Drucker verbunden ist, dann nur alle 5 Minuten prüfen.
                // Ansonsten stört das beim Drucken
                timeout = 5 * 60 * 1000;
            }

            if (this.letzteSuche < Date.now() - timeout) {
                this.updateDruckerVerbindung();
            }
        } catch (err) {
            this.log.error('update updateDruckerVerbindung', err);
        }
    }

    isFunktionAktiv() {
        return this.sucheDruckerLaeuft || this.zebraStatusLaeuft || this.wirdGedruckt;
    }

    async updateDruckerVerbindung() {
        this.log.debug('updateDruckerVerbindung');

        if (!this.systemService.istImVordergrund.getValue()) {
            this.log.debug('updateDruckerVerbindung: Anwendung nicht im Vordergrund. Abbruch');
            return;
        }

        await App.ready();

        switch (AppConfig.current.einstellungen.DruckerTyp) {
            case 'Druckdienst':
                await this.updateDruckerVerbindungDruckdienst();
                break;

            case 'zebra':
                await this.updateDruckerVerbindungZebra();
                break;
        }
    }

    async updateDruckerVerbindungDruckdienst(): Promise<void> {
        if (!App.isCordovaAvailable()) {
            this.log.debug('Cordova nicht verfügbar');
            return;
        }

        try {
            const available = await this.printer.isAvailable();

            if (available) {
                this.druckerStatus.next(GeraetStatusTyp.Unbekannt);
            } else {
                this.druckerStatus.next(GeraetStatusTyp.NichtVerbunden);
            }
        } catch (err) {
            this.log.error('updateDruckerVerbindungHP: ' + Utils.getErrorMessage(err), err);
            this.druckerStatus.next(GeraetStatusTyp.Fehler);
        }
    }

    async updateDruckerVerbindungZebra(): Promise<void> {
        const appConfig = AppConfig.current;

        if (!appConfig.gekoppelteDrucker) {
            appConfig.gekoppelteDrucker = [];
        }

        if (!appConfig.gekoppelteDrucker.length) {
            this.log.debug('updateDruckerVerbindung keine Drucker gekoppelt');
            this.druckerStatus.next(GeraetStatusTyp.NichtVerbunden);
            return;
        }

        if (!App.isCordovaAvailable()) {
            this.log.debug('Cordova nicht verfügbar');
            return;
        }

        const zbtprinter = (cordova.plugins as any).zbtprinter as ZbtPrinter;

        if (!zbtprinter) {
            this.log.warn('ZbtPrinter Plugin nicht installiert');
            this.druckerStatus.next(GeraetStatusTyp.Fehler);
            return;
        }

        if (this.sucheDruckerLaeuft) {
            this.log.debug('updateDruckerVerbindungZebra: sucheDruckerLaeuft, abbruch');
            return;
        }

        if (this.zebraStatusLaeuft) {
            this.log.debug('updateDruckerVerbindungZebra: zebraStatusLaeuft, abbruch');
            return;
        }

        if (this.isFunktionAktiv()) {
            this.log.debug('updateDruckerVerbindungZebra: isFunktionAktiv() = TRUE, abbruch');
            return;
        }

        this.letzteSuche = Date.now();

        for (const drucker of appConfig.gekoppelteDrucker) {
            if (drucker.macAdresse && drucker.aktiv && drucker.typ === 'ZEBRA') {
                this.log.debug('Prüfe Verbindung zu Drucker: ' + drucker.macAdresse, + ', ' + drucker.name);

                // Prüfe nur explizit die Verbindung zu diesem Drucker
                this.zebraStatusLaeuft = true;

                this.zebraStatusPromise = new Promise(async (resolve, reject) => {
                    let istResolved = false;

                    zbtprinter.getStatus(drucker.macAdresse,
                        success => {
                            this.log.debug('updateDruckerVerbindungZebra: ' + success);
                            this.letzteSuche = Date.now();
                            this.zebraStatusLaeuft = false;
                            this.druckerStatus.next(GeraetStatusTyp.Verbunden);

                            if (!istResolved) {
                                istResolved = true;

                                resolve(false);

                                this.zebraStatusLaeuft = false;
                                this.zebraStatusPromise = null;
                            }
                        },
                        fail => {
                            this.log.warn('updateDruckerVerbindungZebra failed: ' + Utils.getErrorMessage(fail), fail);
                            this.letzteSuche = Date.now();
                            this.zebraStatusLaeuft = false;
                            this.druckerStatus.next(GeraetStatusTyp.Fehler);

                            if (!istResolved) {
                                istResolved = true;

                                resolve(false);

                                this.zebraStatusLaeuft = false;
                                this.zebraStatusPromise = null;
                            }
                        });

                    setTimeout(() => {
                        this.log.debug('resolve timeout');

                        if (!istResolved) {
                            this.log.debug('updateDruckerVerbindungZebra: resolve timeout: noch nicht resolved');
                            this.letzteSuche = Date.now();
                            this.druckerStatus.next(GeraetStatusTyp.Unbekannt);

                            istResolved = true;

                            resolve(false);

                            this.zebraStatusLaeuft = false;
                            this.zebraStatusPromise = null;
                        }
                    }, App.current.druckerSuchenTimeout);
                });

                return;
            }
        }

        // this.sucheDrucker(false);
    }

    /**
     * Sucht erreichbare Drucker und gibt eine Liste an MAC-Adressen zurück
     */
    async sucheDrucker(mitFeedback: boolean): Promise<string[]> {
        this.log.debug('sucheDrucker');

        this.letzteSuche = Date.now();

        if (mitFeedback && !App.isCordovaAvailable()) {
            await UiHelper.showAlert('Drucker suchen in Simulation nicht verfügbar', 'Simulation', true);
        }

        if (this.sucheDruckerPromise) {
            this.log.debug('sucheDruckerPromise schon vorhanden.');

            if (mitFeedback) {
                // Nur warten, aber nicht das Ergebnis zurückliefern
                await this.sucheDruckerPromise;
            } else {
                return this.sucheDruckerPromise;
            }
        }

        if (this.druckenPromise) {
            // Warte bis Druck abgeschlossen ist
            await this.druckenPromise;
        }

        switch (AppConfig.current.einstellungen.DruckerTyp) {
            case 'Druckdienst':
                return await this.sucheStandardDrucker(mitFeedback);

            case 'zebra':
                return await this.sucheZebraDrucker();
        }

        return [];
    }

    async verbindeByMacAdresse(macAdresse: string): Promise<boolean> {
        this.log.info('verbindeByMacAdresse: ' + macAdresse);

        this.letzteSuche = Date.now();

        if (!macAdresse || macAdresse.length !== 12) {
            await UiHelper.showAlert('Ungültige MAC-Adresse: ' + macAdresse, 'Fehler', true);
            return;
        }

        if (!App.isCordovaAvailable()) {
            await UiHelper.showAlert('Drucker verbinden in Simulation nicht verfügbar', 'Simulation', true);
            return;
        }

        try {
            await UiHelper.showLoading('Durcker wird verbunden');

            // Status 
            const status = await this.getZebraStatus(macAdresse);

            this.log.info("STATUS: " + status);

            if (status.startsWith('Could not connect to device')) {
                await UiHelper.showAlert(status, 'Fehler');
                return false;
            }

            await Utils.delay(500);

            // Name ermitteln
            let name = await this.getZebraPrinterName(macAdresse);

            if (!name) {
                name = macAdresse;
            }

            await Utils.delay(500);

            const appConfig = AppConfig.current;

            if (!appConfig.gekoppelteDrucker) {
                appConfig.gekoppelteDrucker = [];
            }

            // Zuvor gekoppelten Drucker inaktiv setzen
            for (const drucker of appConfig.gekoppelteDrucker) {
                drucker.aktiv = false;
            }

            let konfiguration = this.getDruckerKonfigurationByMacAdresse(macAdresse);

            if (!konfiguration) {
                konfiguration = {
                    typ: 'ZEBRA',
                    macAdresse: macAdresse,
                    aktiv: false,
                    istVorkonfiguriert: false,
                    name: name,
                    zuletztVerwendet: '',
                };

                appConfig.gekoppelteDrucker.push(konfiguration);
            }

            konfiguration.aktiv = true;
            konfiguration.zuletztVerwendet = new Date().toISOString();

            this.systemService.speichereAppConfig(appConfig);

            this.druckerStatus.next(GeraetStatusTyp.Verbunden);

            await UiHelper.hideLoading();

            return true;
        } catch (err) {
            this.log.error('verbindeByMacAdresse ' + macAdresse + ': ' + Utils.getErrorMessage(err), err);

            await UiHelper.hideLoading();

            UiHelper.showErrorOhneSentry(err);
            return false;
        }
    }

    getZebraStatus(macAdresse: string): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                const zbtprinter = (cordova.plugins as any).zbtprinter as ZbtPrinter;
                zbtprinter.getStatus(macAdresse,
                    status => {
                        resolve(status);
                    }, e => {
                        this.log.warn('getZebraPrinterName getPrinterName: ' + Utils.getErrorMessage(e));
                        resolve(Utils.getErrorMessage(e));
                    });
            } catch (err) {
                this.log.warn('getZebraPrinterName: ' + Utils.getErrorMessage(macAdresse));
            }
        });
    }

    getZebraPrinterName(macAdresse: string): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                const zbtprinter = (cordova.plugins as any).zbtprinter as ZbtPrinter;
                zbtprinter.getPrinterName(macAdresse,
                    printerName => {
                        resolve(printerName);
                    }, e => {
                        this.log.warn('getZebraPrinterName getPrinterName: ' + Utils.getErrorMessage(e));
                        resolve('');
                    });
            } catch (err) {
                this.log.warn('getZebraPrinterName: ' + Utils.getErrorMessage(macAdresse));
            }
        });
    }

    async sucheStandardDrucker(mitFeedback: boolean): Promise<string[]> {
        try {
            if (!App.isCordovaAvailable()) {
                return ['Druckdienst'];
            }

            const available = await this.printer.isAvailable();

            if (available) {
                if (mitFeedback) {
                    const pickResult = await this.printer.pick();
                    this.log.debug('pickResult: ' + pickResult, pickResult);
                }

                return ['Druckdienst'];
            } else {
                return [];
            }
        } catch (err) {
            this.log.warn('sucheStandardDrucker: ' + Utils.getErrorMessage(err), err);
            return [];
        }
    }

    async sucheZebraDrucker(): Promise<string[]> {
        this.sucheDruckerLaeuft = true;

        const appConfig = AppConfig.current;

        if (!appConfig.gekoppelteDrucker) {
            appConfig.gekoppelteDrucker = [];
        }

        if (!App.isCordovaAvailable()) {
            return ['ZPL-Simulator'];
        }

        // Sortiere absteigend nach letzter Verwendung
        appConfig.gekoppelteDrucker = appConfig.gekoppelteDrucker.sort((a, b) => a.zuletztVerwendet < b.zuletztVerwendet ? 1 : -1);

        const gefundeneDrucker: DruckerKonfiguration[] = [];

        try {
            const zbtprinter = (cordova.plugins as any).zbtprinter as ZbtPrinter;

            if (!zbtprinter) {
                this.log.warn('ZbtPrinter Plugin nicht installiert');
                this.druckerStatus.next(GeraetStatusTyp.Fehler);
                return [];
            }

            if (this.druckerStatus.getValue() != GeraetStatusTyp.Verbunden) {
                this.druckerStatus.next(GeraetStatusTyp.Suche);
            }

            this.sucheDruckerPromise = new Promise(async (resolve, reject) => {
                const macAdressen: string[] = [];
                let istResolved = false;

                zbtprinter.discoverPrinters(
                    async MACAddress => {
                        this.log.debug('Drucker gefunden: ' + MACAddress);
                        this.letzteSuche = Date.now();

                        macAdressen.push(MACAddress.trim());

                        this.ngZone.run(async () => {
                            const drucker = this.getDruckerKonfigurationByMacAdresse(MACAddress);

                            if (drucker && drucker.aktiv) {
                                gefundeneDrucker.push(drucker);
                                this.druckerStatus.next(GeraetStatusTyp.Verbunden);
                            }
                        });
                    }, fail => {
                        this.ngZone.run(() => {
                            this.log.warn('ZbtPrinter discoverPrinters: ' + Utils.getErrorMessage(fail), fail);
                            this.druckerStatus.next(GeraetStatusTyp.Fehler);
                            this.letzteSuche = Date.now();

                            if (!istResolved) {
                                this.log.debug('resolve: ' + macAdressen.join(', '));

                                istResolved = true;
                                this.verbundeneDrucker.next(gefundeneDrucker);

                                resolve(macAdressen);

                                this.sucheDruckerLaeuft = false;
                                this.sucheDruckerPromise = null;
                            }
                        });
                    }
                );

                setTimeout(() => {
                    this.log.debug('resolve timeout');

                    if (!istResolved) {
                        this.log.debug('resolve timeout: noch nicht resolved: ' + macAdressen.join(', '));
                        this.letzteSuche = Date.now();

                        istResolved = true;
                        this.verbundeneDrucker.next(gefundeneDrucker);

                        resolve(macAdressen);

                        this.sucheDruckerLaeuft = false;
                        this.sucheDruckerPromise = null;
                    }
                }, App.current.druckerSuchenTimeout);
            });

            return this.sucheDruckerPromise;
        } catch (err) {
            this.log.error('sucheDrucker', err);
            return [];
        }
    }

    getDruckerKonfigurationByMacAdresse(macAdresse: string): DruckerKonfiguration {
        const appConfig = AppConfig.current;

        return appConfig.gekoppelteDrucker.find(p => p.macAdresse && p.macAdresse.toLowerCase() == macAdresse.toLowerCase());
    }

    getAktiveDrucker(): DruckerKonfiguration[] {
        return AppConfig.current.gekoppelteDrucker.filter(p => p.aktiv);
    }

    async druckeHtml(html: string, drucker: DruckerKonfiguration): Promise<boolean> {
        this.log.debug('druckeHtml');

        if (!App.isCordovaAvailable()) {
            this.log.info(html);
            return true;
        }

        const options: PrintOptions = {
            name: 'MyDocument',
            // printerId: 'printer007',
            duplex: false,
        };

        try {
            this.druckerStatus.next(GeraetStatusTyp.WirdGedruckt);

            const printResult = await this.printer.print(html, options);

            // FIXME: Das Ergebnis stimmt hier leider nicht. Mal wird true, mal false zurückgegeben. Unabhängig davon ob der Druck wirklich ausgeführt wurde
            this.log.info('printResult: ' + printResult);

            if (printResult || true) {
                this.druckerStatus.next(GeraetStatusTyp.Verbunden);
                return true;
            } else {
                return false;
            }
        } catch (err) {
            this.log.warn('druckeHtml: ' + Utils.getErrorMessage(err), err);
            this.druckerStatus.next(GeraetStatusTyp.Fehler);
            return false;
        }
    }

    async druckeZpl(zpl: string, drucker: DruckerKonfiguration): Promise<boolean> {
        const appConfig = AppConfig.current;

        if (!App.isCordovaAvailable()) {
            this.log.info(zpl);
            return true;
        }

        await UiHelper.showLoading("Warte auf Drucker...");

        if (this.sucheDruckerPromise) {
            // Warte bis Suche abgeschlsosen ist
            await this.sucheDruckerPromise;
        }

        if (this.druckenPromise) {
            this.log.info('Warte bis Suche abgeschlossen ist...');
            await this.druckenPromise;
        }

        if (this.zebraStatusLaeuft) {
            this.log.info('Warte bis Statusprüfung abgeschlossen ist...');
            await this.druckenPromise;
        }

        const zbtprinter = (cordova.plugins as any).zbtprinter as ZbtPrinter;

        if (!zbtprinter) {
            UiHelper.showAlert("ZbtPrinter Plugin nicht installiert");
            return false;
        }

        await UiHelper.showLoading("Wird gedruckt...");

        this.druckenPromise = new Promise((resolve, reject) => {
            let callbackErhalten = false;
            let zeitueberschreitung = false;

            zbtprinter.print(drucker.macAdresse, zpl,
                success => {
                    this.druckenPromise = null;
                    callbackErhalten = true;
                    this.letzteSuche = Date.now();

                    if (!zeitueberschreitung) {
                        this.log.info("Druck erfolgreich");

                        this.ngZone.run(() => {
                            UiHelper.hideLoading();
                            this.druckerStatus.next(GeraetStatusTyp.Verbunden);
                            resolve(true);
                        });
                    } else {
                        this.log.warn("Druck erfolgreich (nach Timeout!)");
                    }
                }, fail => {
                    this.druckenPromise = null;
                    callbackErhalten = true;

                    const msg = Utils.getErrorMessage(fail);

                    if (msg.startsWith('Could not connect to device: read failed, socket might closed or timeout, read ret: -1')) {
                        // Bluetooth-Problem.
                        // Lässt sich dur einen Neustart der App beheben. Das muss aber eigentlich auch anders gehen
                        // TODO zbtprinter.
                    }

                    this.log.warn("Druck fehlgeschlagen: " + Utils.getErrorMessage(fail), fail);

                    if (!zeitueberschreitung) {
                        this.ngZone.run(() => {
                            UiHelper.hideLoading();
                            UiHelper.showError(fail);
                            this.druckerStatus.next(GeraetStatusTyp.Fehler);
                            resolve(false);
                        });
                    }
                }
            );

            setTimeout(() => {
                if (!callbackErhalten) {
                    this.log.warn('Druck Timeout');
                    zeitueberschreitung = true;
                    this.druckenPromise = null;

                    UiHelper.hideLoading();
                    UiHelper.showAlert('Zeitüberschreitung beim Drucken');
                    this.druckerStatus.next(GeraetStatusTyp.Fehler);

                    resolve(false);
                }
            }, App.current.druckenTimeout);
        });

        return this.druckenPromise;
    }

    koppeln(macAdresse: string) {
        const appConfig = AppConfig.current;

        appConfig.gekoppelteDrucker.push({
            typ: 'ZEBRA',
            aktiv: true,
            macAdresse: macAdresse,
            name: macAdresse,
            zuletztVerwendet: new Date().toISOString(),
            istVorkonfiguriert: false
        });

        this.systemService.speichereAppConfig(appConfig);

        this.druckerStatus.next(GeraetStatusTyp.Verbunden);
    }

    async testDruck(druckerKonfiguration: DruckerKonfiguration) {
        this.log.debug('testDruck: ' + druckerKonfiguration.name);

        try {
            switch (AppConfig.current.einstellungen.DruckerTyp) {
                case 'Druckdienst':
                    await this.testDruckStandard(druckerKonfiguration);
                    break;

                case 'zebra':
                    await this.testDruckZebra(druckerKonfiguration);
                    break;

                default:
                    throw new Error('unbekannter DruckerTyp: ' + AppConfig.current.einstellungen.DruckerTyp);
            }
        } catch (err) {
            this.log.error('onDruckerTest', err);
            UiHelper.hideLoading();
            UiHelper.showError(err);
        }
    }

    private async testDruckZebra(druckerKonfiguration: DruckerKonfiguration): Promise<void> {
        this.log.debug('testDruckZebra: ' + druckerKonfiguration.name);

        let zpl = AppConfig.current.einstellungen.DruckerTestCode;

        if (!zpl) {
            zpl = "^XA"
                + "^FO20,20^A0N,25,25^FDThis is a ZPL test.^FS"
                + "^XZ";
        }

        await this.druckeZpl(zpl, druckerKonfiguration);
    }

    private async testDruckStandard(druckerKonfiguration: DruckerKonfiguration) {
        this.log.debug('testDruckStandard: ' + druckerKonfiguration.name);

        const options: PrintOptions = {
            name: 'MyDocument',
            // printerId: 'printer007',
            duplex: true,
        };

        const content = "Es flattert um die Quelle\nDie wechselnde Libelle,...";

        const printResult = await this.printer.print(content, options);

        this.log.debug('printResult: ' + printResult, printResult);
    }
}
