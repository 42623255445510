<ion-item [lines]="lines" [style]="style">
  <ion-label class="ion-text-wrap">{{ titel | translate }}</ion-label>

  <div slot="end" class="input-outer">

    <ion-input
               type="text"
               [class.invalid-value]="isInvalid"
               [class.input-readonly]="readOnly"
               [disabled]="disabled"
               [readonly]="readOnly"
               [(ngModel)]="myValue"
               [pattern]="pattern"
               (ionBlur)="onBlur($event)"
               (keyup.enter)="app.closeKeyboard($event)"
               [enterkeyhint]="enterkeyhint"></ion-input>

    <!-- <ion-input type="text" [(ngModel)]="p.Wert"  (ionBlur)="onEigenschaftLostFocus(vo, p)" (keyup.enter)="app.closeKeyboard($event)" enterkeyhint="done" clearInput></ion-input> -->

    <ion-button class="datum-button inline-plusminus-button"
                [disabled]="disabled"
                appClick (clicked)="selectDatum($event)"
                size="default"
                color="light"
                slot="end">
      <ion-icon name="calendar-outline"></ion-icon>
    </ion-button>

    <ion-popover cssClass="width-80" #popover>
      <ng-template>
        <ion-datetime
                      presentation="date"
                      [(ngModel)]="dateTimeValue"
                      [showDefaultButtons]="false"
                      doneText="OK"
                      [cancelText]="'Abbrechen' | translate"
                      (ionChange)="onDateSelected($event)"
                      size="cover"></ion-datetime>
      </ng-template>
    </ion-popover>

  </div>
</ion-item>