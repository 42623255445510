import { File } from '@ionic-native/file/ngx';
import { Logger } from './app-error-logger';

const log = new Logger('FileHelper');

export class FileHelper {
    static file: File;

    static getFilename(path: string) {
        if (path && typeof (path) === 'string') {
            // Extract just the filename. Result example: cdv_photo_003.jpg
            // Eventuelles ? und alles dahinter entfernen
            // Beispiel: file:///storage/emulated/0/Android/data/de.recomobil.app/cache/1000006939.jpg?1700814405156

            let idx = path.indexOf('?');

            if (idx > 0) {
                path = path.substring(0, idx);
            }

            return path.substr(path.lastIndexOf('/') + 1);
        }

        return null;
    }

    static getDirectory(path: string) {
        if (path && path.startsWith('file:')) {
            return path.substr(0, path.lastIndexOf('/') + 1);
        }

        return null;
    }

    static async deleteFile(file: string) {
        if (file && file.startsWith('file:')) {
            const filename = FileHelper.getFilename(file);
            const directory = FileHelper.getDirectory(file);

            try {
                await FileHelper.file.removeFile(directory, filename);
            } catch (err) {
                log.error('error removing file', err);
            }
        }
    }

    static removeIllegalFilenameChars(filename: string): string {
        return filename.replace(/[/\\?%*:|"<>]/g, '-');
    }

    static getFileIcon(mimeType: string) {
        if (mimeType) {
            if (mimeType.startsWith('image/jp')) {
                return 'jpg';
            } else if (mimeType.startsWith('image/png')) {
                return 'png';
            } else if (mimeType === 'application/pdf') {
                return 'pdf';
            }
        }

        return 'document';
    }

    static async checkFile(filePath: string): Promise<boolean> {
        log.debug('checkFile: ' + filePath);

        if (!filePath) {
            return false;
        }

        const directory = FileHelper.getDirectory(filePath);
        const filename = FileHelper.getFilename(filePath);

        let fileExists = false;

        try {
            fileExists = await FileHelper.file.checkFile(directory, filename);
        } catch (err) {
            log.warn('checkFile error ' + filePath + ': ' + err?.message, err);
        }

        return fileExists;
    }
}
