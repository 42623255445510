<ion-header>
  <ion-toolbar class="auftrag-toolbar" #toolbar>
    <ion-buttons slot="start">
      <ion-button appClick (clicked)="onAbbrechen()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title><span id="page-title-span">{{ 'Drucken' | translate }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="druckformulare">

    <ion-item>
      <ion-label>{{ 'Drucker' | translate }}</ion-label>
      <ion-select #druckerSelect [(ngModel)]="druckerMacAdresse">
        <ion-select-option *ngFor="let p of druckerAuswahl" value="{{ p.macAdresse }}">{{ p.name }}</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-radio-group [value]="druckformularName" (ionChange)="onRadioGroupChange($event)">
      <ion-list-header>
        <ion-label>Druckformular</ion-label>
      </ion-list-header>

      <ion-item *ngFor="let p of druckformulare" lines="none">
        <ion-label>{{ p.Name }}</ion-label>
        <ion-radio slot="start" value="{{ p.Name }}"></ion-radio>
      </ion-item>

    </ion-radio-group>

    <app-input-number titel="Exemplare" [(ngModel)]="anzahlExemplare" [min]="1" [max]="10" cssClass="pr-15"></app-input-number>
  </ion-list>

  <div style="margin: 15px">
    <ion-button appClick (clicked)="onDrucken()" expand="full" size="large" color="primary">Drucken</ion-button>
    <ion-button appClick (clicked)="onAbbrechen()" expand="full" size="default" color="light">Abbrechen</ion-button>
  </div>
</ion-content>