<ion-header>
  <ion-toolbar class="auftrag-toolbar" #toolbar>
    <ion-buttons slot="start">
      <ion-button appClick (clicked)="onAbbrechen()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title><span id="page-title-span">{{ 'Anmerkungen' | translate }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="auftrag">
    <ion-textarea [(ngModel)]="auftrag.Anmerkungen" placeholder="{{ 'AnmerkungenHierEingeben' | translate }}" class="full-height-textarea" #textarea></ion-textarea>
</ion-content>

<ion-footer *ngIf="auftrag">
  <ion-toolbar>
    <ion-buttons slot="secondary">
      <ion-button appClick (clicked)="onSpeichern()">OK</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>