import { ChecklisteEintrag } from '../model/swagger-model';
import { FormatHelper } from './format-helper';
import { ChecklisteEintragConfig } from '../model/model';

export class ChecklistHelper {
    static parseConfig(eintrag: ChecklisteEintrag): ChecklisteEintragConfig {
        const result: ChecklisteEintragConfig = {
            moeglicheAntworten: [],
            default: null
        };

        if (eintrag.config) {
            const parts = eintrag.config.split(';');

            for (const str of parts) {
                const x = str.split('=');

                if (x.length === 2) {
                    const key = x[0].trim().toLowerCase();
                    const value = x[1].trim();

                    if (key === 'antworten') {
                        const moeglicheAntworten = FormatHelper.trimEachString(value.split(','));

                        for (const p of moeglicheAntworten) {
                            result.moeglicheAntworten.push({ value: p });
                        }
                    } else if (key == 'schlecht') {
                        for (const p of result.moeglicheAntworten) {
                            if (p.value == value) {
                                p.schlecht = true;
                                p.cssTyp = 'schlecht';
                            }
                        }
                    } else if (key == 'gut') {
                        for (const p of result.moeglicheAntworten) {
                            if (p.value == value) {
                                p.schlecht = true;
                                p.cssTyp = 'gut';
                            }
                        }
                    } else if (key == 'default') {
                        result.default = value;
                    }
                }
            }
        }

        return result;
    }
}