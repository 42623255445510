<ion-header>
  <ion-toolbar class="auftrag-toolbar" #toolbar>
    <ion-buttons slot="start">
      <ion-button appClick (clicked)="onSpeichern()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title><span id="page-title-span">{{ title | async | translate }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="auftrag" class="section-content" #content>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-buttons">
    <ion-fab-button appClick (clicked)="auftragLieferungDetails.onSuchen()" *ngIf="suchButtonAnzeigen | async">
      <ion-icon name="search"></ion-icon>
    </ion-fab-button>
    <ion-fab-button appClick (clicked)="auftragLieferungDetails.onPositionHinzufuegen()" *ngIf="positionHinzufuegenErlaubt">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <app-auftrag-lieferung-details
                                 [auftrag]="auftrag"
                                 [auftragdetails]="auftragdetails"
                                 #auftragLieferungDetails></app-auftrag-lieferung-details>

  <div class="section buttons-section">
    <ion-button appClick (clicked)="onSpeichern()" class="button-md button-border" expand="full" color="light">OK</ion-button>
  </div>

  <div class="footer-space"></div>
</ion-content>