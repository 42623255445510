import { Adresse, Druckformular, Auftrag, Fahrzeug, Personal, ReportElement, ReportElementType, BarcodeType, Auftragsposition, TextAlignType } from 'src/app/api/model/swagger-model';

import * as moment from 'moment';
import { ZplImage } from './zpl-image';
import { ImageHelper } from 'src/app/api/helper/image-helper';
import { AuftragEx, DruckOptions } from 'src/app/api/model/model';
import { Utils } from 'src/app/api/helper/utils';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { DruckerHelper } from './drucker-helper';
import QRCode from 'qrcode'
import { DATAMatrix } from './datamatrix';

const log = new Logger('ZebraDrucker');

function ErsetzePlatzhalter(str: string, platzhalter: string, wert: any): string {
    return Ersetze(str, '{' + platzhalter + '}', wert);
}

function Ersetze(str: string, suchwert: string, wert: any): string {
    let result = str;
    let ttl = 10;

    do {
        if (wert === null || typeof (wert) === 'undefined') {
            wert = '';
        }

        result = str.replace(suchwert, wert);

        if (result === str) {
            // Nichts mehr ersetzt
            break;
        }
    } while (ttl-- > 0);

    return result;
}

export class ZebraDrucker {

    private deltaY = 0;

    constructor(
        private druckformular: Druckformular,
        private auftrag: AuftragEx,
        private fahrzeug: Fahrzeug,
        private fahrer: Personal) {

        if (!druckformular) {
            throw new Error('Parameter druckformular darf nicht null sein')
        }
    }

    async erstelleBelegZplCode(options: DruckOptions): Promise<string> {
        if (!this.auftrag) {
            throw new Error('Parameter auftrag darf nicht null sein')
        }

        this.deltaY = 0;

        const lines: string[] = [];

        lines.push('^XA');

        if (this.druckformular.HomeX || this.druckformular.HomeY) {
            lines.push(`^LH${this.druckformular.HomeX},${this.druckformular.HomeY}`);
        } else {
            lines.push('^LH0,0');
        }

        // Codepage 1250
        // lines.push('^CI31');

        // Codepage UTF-8
        lines.push('^CI28');

        const druckformular = this.druckformular;
        const auftrag = this.auftrag;
        const now = moment();

        if (!auftrag.Abholadresse) {
            auftrag.Abholadresse = {};
        }

        if (!auftrag.Erzeugeradresse) {
            auftrag.Erzeugeradresse = {};
        }

        if (!auftrag.Verwerteradresse) {
            auftrag.Verwerteradresse = {};
        }

        if (!this.fahrzeug) {
            this.fahrzeug = {};
        }

        if (!this.fahrer) {
            this.fahrer = {};
        }

        const platzhalter = DruckerHelper.erstellePlatzhalter(auftrag, this.fahrer, this.fahrzeug, null);

        if (druckformular.Header && druckformular.Header.Model && druckformular.Header.Model.Elements) {
            this.sortiereElemente(druckformular.Header.Model.Elements);

            for (const reportElement of druckformular.Header.Model.Elements) {
                lines.push(...await this.erstelleLinesFuerReportElement(reportElement, platzhalter));
            }

            this.deltaY += druckformular.Header.Height;
        }

        if (auftrag.Positionen && druckformular.Details && druckformular.Details.Model) {
            for (const position of auftrag.Positionen) {
                if (position.Menge) {
                    if (this.druckformular.ZplStartPosition) {
                        lines.push(this.druckformular.ZplStartPosition);
                    }

                    const posPlatzhalter = DruckerHelper.erstellePlatzhalter(auftrag, this.fahrer, this.fahrzeug, position);

                    this.sortiereElemente(druckformular.Details.Model.Elements);

                    for (const reportElement of druckformular.Details.Model.Elements) {
                        lines.push(... await this.erstelleLinesFuerReportElement(reportElement, posPlatzhalter, position));
                    }

                    this.deltaY += druckformular.Details.Height;
                }
            }
        }

        if (druckformular.Footer && druckformular.Footer.Model && druckformular.Footer.Model.Elements) {
            this.sortiereElemente(druckformular.Footer.Model.Elements);

            for (const reportElement of druckformular.Footer.Model.Elements) {
                lines.push(... await this.erstelleLinesFuerReportElement(reportElement, platzhalter));
            }

            this.deltaY += druckformular.Footer.Height;
        }

        if (options) {
            if (options.anzahl > 1 && options.anzahl <= 10) {
                lines.push('^PQ' + options.anzahl);
            }
        }

        lines.push('^XZ');

        // Label-Länge und Breite am Anfang setzen
        const labelLength = this.deltaY + this.druckformular.HomeY;
        const labelWidth = this.druckformular.LabelWidth * this.druckformular.Dpmm;
        lines.unshift(`^XA^LL${labelLength}^PW${labelWidth}^XZ`);

        if (this.druckformular.ZplStart) {
            lines.unshift(this.druckformular.ZplStart);
        }

        if (this.druckformular.ZplEnde) {
            lines.push(this.druckformular.ZplEnde);
        }

        const zpl = lines.join('\r\n');

        log.debug(zpl);

        // TODO: Hinweistexte
        // TODO: Unterschriften

        return zpl;
    }

    sortiereElemente(elemente: ReportElement[]) {
        elemente.sort((a, b) => {
            let result = 0;

            if (typeof (a.Y) === 'number' && typeof (b.Y) === 'number') {
                result = a.Y - b.Y;

                if (result == 0) {
                    if (typeof (a.X) === 'number' && typeof (b.X) === 'number') {
                        result = a.X - b.X;
                    }
                }
            }

            return result;
        })
    }

    private async erstelleLinesFuerReportElement(e: ReportElement, platzhalter: any, position: Auftragsposition = null): Promise<string[]> {
        const lines: string[] = [];

        if (!DruckerHelper.isDruckbedingungOk(e, this.auftrag, this.fahrer, this.fahrzeug, platzhalter, position)) {
            if (e.RemoveLineWhenBlank) {
                if (e.Height) {
                    this.deltaY -= e.Height;
                }
            }

            return [];
        }

        const text = this.formatTextbockText(e.Content, platzhalter);

        if (!text && e.RemoveLineWhenBlank) {
            if (e.RemoveLineWhenBlank) {
                if (e.Height) {
                    this.deltaY -= e.Height;
                }
            }

            return [];
        }

        // Schriftart
        if (e.Font) {
            if (e.FontSize) {
                lines.push(`^CF${e.Font},${e.FontSize}`);
            } else {
                lines.push(`^CF${e.Font}`);
            }
        }

        // Position
        if (e.TextAlign === TextAlignType.Right) {
            lines.push(`^FO${e.X + e.Width},${e.Y + this.deltaY},1`);
        } else {
            lines.push(`^FO${e.X},${e.Y + this.deltaY}`);
        }

        if (e.RemoveLineWhenBlank) {
            if (!text) {

            }
        }

        switch (e.Typ) {
            case ReportElementType.Text:
                {
                    let rotated = false;

                    if (e.Orientation === 'R') {
                        lines.push(`^FW` + e.Orientation);
                        rotated = true;
                    }

                    lines.push(`^FH^FD${text}^FS`);

                    if (rotated) {
                        lines.push(`^FWN`);
                    }
                    break;
                }

            case ReportElementType.Textblock:
                {
                    let rotated = false;

                    if (e.Orientation === 'R') {
                        lines.push(`^FW` + e.Orientation);

                        if (e.TextAlign === TextAlignType.Right) {
                            lines.push(`^FB${e.Width},1,0,R`);
                        } else if (e.TextAlign === TextAlignType.Center) {
                            lines.push(`^FB${e.Width},1,0,C`);
                        } else {
                            lines.push(`^FB${e.Width},1`);
                        }

                        rotated = true;
                    } else {

                        // Textblock
                        if (e.TextAlign === TextAlignType.Right) {
                            lines.push(`^FB${e.Width},999,0,R`);
                        } else if (e.TextAlign === TextAlignType.Center) {
                            lines.push(`^FB${e.Width},999,0,C`);
                        } else {
                            lines.push(`^FB${e.Width},999`);
                        }
                    }

                    // Text
                    lines.push(`^FH^FD${text}^FS`);

                    if (rotated) {
                        lines.push(`^FWN`);
                    }
                }
                break;

            // ^FO0,60^FDName:^FS
            case ReportElementType.Barcode:
                {
                    // w = module width (default: 2)
                    let w = 2;

                    if (e.ModuleWidth && e.ModuleWidth > 2) {
                        w = e.ModuleWidth;
                    }

                    // r: 2
                    const r = 2;

                    // h: height
                    const h = e.Height ? e.Height : 120;

                    lines.push(`^BY${w},${r},${h}`);

                    const orientation = e.Orientation ? e.Orientation : 'N';
                    const checkDigit = e.CheckDigit ? e.CheckDigit : 'N';
                    const f = e.PrintInterpolationLine ? e.PrintInterpolationLine : 'Y';
                    const g = e.PrintInterpolationLineAboveCode ? e.PrintInterpolationLineAboveCode : 'N';

                    let mode = 'N';


                    switch (e.BarcodeType) {
                        case BarcodeType.Code11:
                            lines.push(`^B1${orientation},${checkDigit},${h},${f},${g}^FD${text}^FS`);
                            break;

                        case BarcodeType.Interleaved2of5:
                            lines.push(`^B2${orientation},${h},${f},${g},${checkDigit}^FD${text}^FS`);
                            break;

                        case BarcodeType.Code128:
                            lines.push(`^BC${orientation},${h},${f},${g},${checkDigit},${mode}^FD${text}^FS`);
                            break;

                        case BarcodeType.GS1_128:
                            // ^BCN,280,Y,N,Y,D^FD(02)04018715004431(15)220606(37)40^FS
                            lines.push(`^BC${orientation},${h},${f},${g},${checkDigit},D^FD${text}^FS`);
                            break;

                        case BarcodeType.QR:
                            // ^BY width, widthRatio, height
                            // Configures the global bar code defaults.
                            const width = e.QrCodeWidth ? e.QrCodeWidth : 3;
                            const widthRatio = e.QrCodeWidthRatio ? e.QrCodeWidthRatio : 2.0;
                            const height = e.QrCodeHeight ? e.QrCodeHeight : 65;

                            lines.push(`^BY${width},${widthRatio},${height}`);

                            // ^BQ orientation, model, magnification, errorCorrection, mask
                            // Configures the current field as a QR Code bar code. Note that the data string in the corresponding ^FD command is expected to start with a bar code configuration prefix.
                            // For example, "^FDQA,12345" sets the error correction level to Q (high reliability), selects input mode A (automated encode mode switching) and encodes the data "12345" in the bar code.
                            lines.push(`^BQN,2,10`);
                            lines.push(`^FDQA,${text}^FS`);
                            break;

                        case BarcodeType.QR_Image:
                            {
                                const qrCodeText = this.ersetzeSteuerzeichen(text);

                                const dataUrl = await QRCode.toDataURL(qrCodeText, { width: e.Width });

                                const imageData = await ImageHelper.dataUrlToImageData(dataUrl, e.Width, e.Height);

                                const zplImage = new ZplImage();
                                const res = zplImage.rgbaToZ64(imageData.data, imageData.width, {});

                                if (res) {
                                    lines.push(`^GFA,${res.length},${res.length},${res.rowlen},${res.z64}`);
                                }
                                break;
                            }

                        case BarcodeType.DataMatrix:
                            {
                                const svgNode = DATAMatrix({
                                    msg: text,
                                    dim: e.Width,
                                    rct: 0,
                                    pad: 2,
                                    pal: ["#000000", "#fff"],
                                    vrb: 0
                                });

                                const str = new XMLSerializer().serializeToString(svgNode)
                                const dataUrl = await ImageHelper.svgString2Image(str, e.Width, e.Height, 'png');
                                const imageData = await ImageHelper.dataUrlToImageData(dataUrl, e.Width, e.Height);

                                const zplImage = new ZplImage();
                                const res = zplImage.rgbaToZ64(imageData.data, imageData.width, {});

                                if (res) {
                                    lines.push(`^GFA,${res.length},${res.length},${res.rowlen},${res.z64}`);
                                }
                                break;
                            }

                        case BarcodeType.Code39:
                        default:
                            lines.push(`^B3${orientation},${checkDigit},${h},${f},${g}^FD${text}^FS`);
                            // lines.push(`^BC${text}^FS`);
                            break;

                        case BarcodeType.Custom:
                            const code = e.CustomCode;
                            lines.push(code.replace('#TEXT#', text));
                            break;
                    }

                    // lines.push(`^FD${text}^FS`);
                }

                break;

            case ReportElementType.Rect:
                {
                    const t = e.BorderThickness ? e.BorderThickness : 1;
                    const c = e.LineColor ? e.LineColor : 'B';
                    const r = e.CornerRounding ? e.CornerRounding : 0;

                    lines.push(`^GB${e.Width},${e.Height},${t},${c},${r}^FS`);
                }

                break;

            case ReportElementType.Image:
                {
                    let imageDataUrl;

                    switch (e.Content) {
                        case 'unterschrift-erz':
                            imageDataUrl = this.GetUnterschriftBild('ERZ');
                            break;
                        case 'unterschrift-bef':
                            imageDataUrl = this.GetUnterschriftBild('BEF');
                            break;
                        case 'unterschrift-ent':
                            imageDataUrl = this.GetUnterschriftBild('ENT');
                            break;
                        case 'custom':
                            imageDataUrl = e.ImageData;
                            break;
                    };

                    if (imageDataUrl) {
                        const zplImage = new ZplImage();

                        // const image = await ImageHelper.dataUrlToImage(imageDataUrl);
                        // const res2 = zplImage.imageToZ64(image, {});

                        const imageData = await ImageHelper.dataUrlToImageData(imageDataUrl, e.Width, e.Height);

                        if (imageData) {
                            const res = zplImage.rgbaToZ64(imageData.data, imageData.width, {});

                            if (res) {
                                lines.push(`^GFA,${res.length},${res.length},${res.rowlen},${res.z64}`);
                            }
                        }
                    }

                    // TODO: Nur mit plugin derzeit möglich
                    // const t = e.BorderThickness ? e.BorderThickness : 1;
                    // const c = e.LineColor ? e.LineColor : 'B';
                    // const r = e.CornerRounding ? e.CornerRounding : 0;

                    // lines.push(`^GB${e.Width},${e.Height},${t},${c},${r}^FS`);
                }

                break;

            default:
                // TODO
                break;
        }

        return lines;
    }

    ersetzeSteuerzeichen(text: string): string {
        text = Utils.replaceAll(text, '<NUL>', String.fromCharCode(0));
        text = Utils.replaceAll(text, '<SOH>', String.fromCharCode(1));
        text = Utils.replaceAll(text, '<STX>', String.fromCharCode(2));
        text = Utils.replaceAll(text, '<ETX>', String.fromCharCode(3));
        text = Utils.replaceAll(text, '<EOT>', String.fromCharCode(4));
        text = Utils.replaceAll(text, '<ENQ>', String.fromCharCode(5));
        text = Utils.replaceAll(text, '<ACK>', String.fromCharCode(6));
        text = Utils.replaceAll(text, '<BEL>', String.fromCharCode(7));
        text = Utils.replaceAll(text, '<BS>', String.fromCharCode(8));
        text = Utils.replaceAll(text, '<TAB>', String.fromCharCode(9));
        text = Utils.replaceAll(text, '<LF>', String.fromCharCode(10));
        text = Utils.replaceAll(text, '<VT>', String.fromCharCode(11));
        text = Utils.replaceAll(text, '<FF>', String.fromCharCode(12));
        text = Utils.replaceAll(text, '<CR>', String.fromCharCode(13));
        text = Utils.replaceAll(text, '<SO>', String.fromCharCode(14));
        text = Utils.replaceAll(text, '<SI>', String.fromCharCode(15));
        text = Utils.replaceAll(text, '<DLE>', String.fromCharCode(16));
        text = Utils.replaceAll(text, '<DC1>', String.fromCharCode(17));
        text = Utils.replaceAll(text, '<DC2>', String.fromCharCode(18));
        text = Utils.replaceAll(text, '<DC3>', String.fromCharCode(19));
        text = Utils.replaceAll(text, '<DC4>', String.fromCharCode(20));
        text = Utils.replaceAll(text, '<NAK>', String.fromCharCode(21));
        text = Utils.replaceAll(text, '<SYN>', String.fromCharCode(22));
        text = Utils.replaceAll(text, '<ETB>', String.fromCharCode(23));
        text = Utils.replaceAll(text, '<CAN>', String.fromCharCode(24));
        text = Utils.replaceAll(text, '<EM>', String.fromCharCode(25));
        text = Utils.replaceAll(text, '<SUB>', String.fromCharCode(26));
        text = Utils.replaceAll(text, '<ESC>', String.fromCharCode(27));
        text = Utils.replaceAll(text, '<FS>', String.fromCharCode(28));
        text = Utils.replaceAll(text, '<GS>', String.fromCharCode(29));
        text = Utils.replaceAll(text, '<RS>', String.fromCharCode(30));
        text = Utils.replaceAll(text, '<US>', String.fromCharCode(31));
        text = Utils.replaceAll(text, '<SP>', String.fromCharCode(32));
        text = Utils.replaceAll(text, '<DEL>', String.fromCharCode(127));

        return text;
    }

    private GetUnterschriftBild(rolle: string) {
        if (!this.auftrag.Unterschriften) {
            return null;
        }

        const unterschrift = this.auftrag.Unterschriften.find(p => p.Typ === rolle);

        if (unterschrift) {
            const bild = unterschrift.Bild;

            if (bild) {
                if (bild.startsWith('data:')) {
                    return bild;
                }
            } else if (unterschrift.Guid) {
                // TODO
            }
        }

        return null;
    }

    private formatTextbockText(text: string, platzhalter: any): string {
        if (!text) {
            text = '';
        }

        if (platzhalter && text && text.indexOf('{') >= 0) {
            for (const key in platzhalter) {
                if (platzhalter.hasOwnProperty(key)) {
                    let value = platzhalter[key];

                    if (typeof (value) === 'undefined' || value == null || value === 'undefined') {
                        value = '';
                    }

                    text = ErsetzePlatzhalter(text, key, value)
                }
            }
        }

        // Unicode-Zeichen ersetzen
        let result = '';

        for (let i = 0; i < text.length; i++) {
            const c = text.charCodeAt(i);

            if (c === 13) {
                // ignore
            } else if (c === 10) {
                result += '\\&';
            } else if (c <= 255) {
                result += text[i];
            } else {
                // Escape (TODO)
                result += text[i];
            }
        }

        return result;
    }
}