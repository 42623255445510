import { Component, OnInit, OnDestroy, ViewChild, Input, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { AuftragEx } from 'src/app/api/model/model';

import * as JsBarcode from 'jsbarcode';
import { SystemService } from 'src/app/api/system.service';
import { StammdatenService } from 'src/app/api/stammdaten.service';
import { AppConfig } from 'src/app/api/helper/app.config';

@Component({
    selector: 'app-auftrag-barcode',
    templateUrl: './auftrag-barcode.component.html',
    styleUrls: ['./auftrag-barcode.component.scss'],
})
export class AuftragBarcodePage implements OnInit, OnDestroy, AfterViewInit {
    log = new Logger('AuftragBarcodePage');

    subscriptions: Subscription[] = [];

    @Input() auftrag: AuftragEx = null;

    isVisible = false;

    constructor(private systemService: SystemService, private stammdatenService: StammdatenService) { }

    ngOnInit() {
    }

    async ngAfterViewInit() {
        this.log.info('ngAfterViewInit');

        let format = AppConfig.current.einstellungen.AuftragBarcodeTyp;

        if (!format) {
            format = 'CODE39';
        }

        let inhalt = AppConfig.current.einstellungen.AuftragBarcodeInhalt;

        if (!inhalt) {
            inhalt = "#Auftragsnummer#";
        }

        if (!this.auftrag.Auftragsnummer) {
            this.auftrag.Auftragsnummer = 0;
        }

        inhalt = inhalt.replace("#Auftragsnummer#", this.auftrag.Auftragsnummer.toString());

        JsBarcode("#barcode", inhalt, {
            format,
            height: 150,
            width: 7,
            displayValue: false
        });
    }

    ngOnDestroy() {
    }

    async ionViewWillEnter() {
    }

    ionViewDidEnter() {
    }

    ionViewWillLeave() {
    }
}
