// export const StringHelper {
//     static isBlank(str: string) {
//         return typeof (str) === 'undefined' || str == '' || str === null || str.match(/^ *$/) !== null;
//     }
// }

export class StringHelper {
    static isBlank(str: string) {
        return typeof (str) === 'undefined' || str == '' || str === null || str.match(/^ *$/) !== null;
    }

    static ersetzePlatzhalter(str: string, platzhalter: string, wert: any): string {
        return StringHelper.ersetze(str, '{' + platzhalter + '}', wert);
    }

    static ersetze(str: string, suchwert: string, wert: any): string {
        let result = str;
        let ttl = 10;

        do {
            if (wert === null || typeof (wert) === 'undefined') {
                wert = '';
            }

            result = str.replace(suchwert, wert);

            if (result === str) {
                // Nichts mehr ersetzt
                break;
            }
        } while (ttl-- > 0);

        return result;
    }
}
