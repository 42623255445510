import { Component, OnInit, OnDestroy, ViewChild, Input, AfterViewInit, forwardRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logger } from 'src/app/api/helper/app-error-logger';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { App } from 'src/app/api/helper/app';
import { AlertController, IonInput } from '@ionic/angular';
import { BarcodeAuthGuard } from '../../barcode.guard';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { UiHelper } from 'src/app/api/helper/ui-helper';
import { Utils } from 'src/app/api/helper/utils';
import { SystemService } from 'src/app/api/system.service';
import { NfcEvent } from 'src/app/api/model/model';
import { I18N } from 'src/app/api/helper/i18n';

const log = new Logger('InputText');

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
    providers: [{   // <================================================ ADD THIS
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputText),
        multi: true
    }]
})
export class InputText implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit, OnChanges {
    @ViewChild('input', { read: IonInput, static: true }) input: IonInput;

    @Input() titel: string;
    @Input() lines = 'none';

    @Input() disabled = false;
    @Input() readOnly = false;
    @Input() enterkeyhint = 'done';
    @Input() pattern = '.*';
    @Input() required = false;
    @Input() clearInput = false;
    @Input() isInvalid = false;
    @Input() uppercase = false;
    @Input() scanNfcButton = false;
    @Input() scanBarcodeButton = false;
    @Input() inputmode = 'text';

    @Output() valueChanged = new EventEmitter<any>();
    @Output() lostFocus = new EventEmitter<any>();

    subscriptions: Subscription[] = [];

    isVisible = false;
    isExtraButtonZeile = false;

    autocapitalize: string = null;

    isClearButtonVisible = false;
    isEmptySpaceVisible = false;

    app = App.current;

    private _value: string;

    nfcAlert: any;
    nfcRegistrationId: number;

    // Whatever name for this (myValue) you choose here, use it in the .html file.
    public get myValue(): string { return this._value }

    public set myValue(v: string) {
        // log.debug('set myValue: ' + v);

        if (this.uppercase && v && typeof (v) === 'string') {
            v = v.toUpperCase();
        }

        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    constructor(
        private alertController: AlertController,
        private systemService: SystemService,
        private barcodeScanner: BarcodeScanner,
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        // log.debug('changed ' + this.titel, changes);

        this.updateType();
    }

    onChange = (_) => {
        if (this.isVisible) {
            this.validate();
        }
    };

    onTouched = () => { };

    writeValue(value: any): void {
        // log.debug('writeValue ' + this.titel, value);

        this.myValue = value;

        // setTimeout(() => {
        //     this.validate();
        // });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    ngOnInit() {
        // log.debug('ngOnInit ' + this.titel);

        this.isVisible = true;
        this.updateType();
        this.validate();
    }

    async ngAfterViewInit() {
        // log.debug('ngAfterViewInit ' + this.titel);

        setTimeout(() => {
            this.validate();
        });
    }

    ngOnDestroy() {
        this.systemService.unregisterNfcReceiver(this.nfcRegistrationId);
    }

    async ionViewWillEnter() {
    }

    ionViewDidEnter() {
    }

    ionViewWillLeave() {
    }

    onClearValue() {
        if (!this.readOnly) {
            this.myValue = null;
            this.valueChanged.next(this._value);

            this.input.setFocus();
        }
    }

    setFocus() {
        try {
            this.input.setFocus();
        } catch (err) {
            log.warn(Utils.getErrorMessage(err), err);
        }
    }

    updateType() {
        this.isClearButtonVisible = false;
        this.isExtraButtonZeile = false;

        if (this.uppercase) {
            this.autocapitalize = 'characters';
        } else {
            this.autocapitalize = null;
        }

        // if (this.plusMinusButtons && !this.disabled && !this.readOnly) {
        //     this.isPlusButtonVisible = true;
        //     this.isMinusButtonVisible = true;
        // }

        if (this.clearInput && !this.scanNfcButton && !this.scanBarcodeButton) {
            this.isClearButtonVisible = true;
        }

        if (!this.scanBarcodeButton && !this.scanNfcButton && !this.isClearButtonVisible) {
            this.isEmptySpaceVisible = true;
        }

        if (this.scanBarcodeButton && this.scanNfcButton) {
            this.isExtraButtonZeile = true;
        }
    }

    onBlur(e: any) {
        // log.debug('onBlur ' + this.titel);
        this.lostFocus.next(e);
        this.valueChanged.next(this._value);
        this.validate();
    }

    onValueChange(e: any) {
        // log.debug('onValueChange ' + this.titel);
        this.valueChanged.next(this._value);
        this.validate();
    }

    validate(): boolean {
        // log.debug('validate ' + this.titel + ': ' + this.pattern + ', value=' + this._value);

        if (this.pattern && this.pattern != '.*') {
            this.isInvalid = false;

            try {
                const wert = Utils.trimToEmpty(this._value);

                if (!wert.match(this.pattern)) {
                    this.isInvalid = true;
                }

            } catch (err) {
                log.warn('Fehler in Regex ' + this.pattern + ': ' + Utils.getErrorMessage(err));
            }
        }

        return !this.isInvalid;
    }

    async scanBarcode() {
        log.debug('scanEigenschaftBarcode: ' + this.titel);

        BarcodeAuthGuard.barcodeScanAktiv = true;

        try {
            let barcodeData;

            if (App.isCordovaAvailable()) {
                barcodeData = await this.barcodeScanner.scan({
                    showTorchButton: true,
                });
            } else {
                await App.delay(500);

                barcodeData = {
                    cancelled: false,
                    text: 'DEMO',
                    format: 'CODE_39'
                };
            }

            log.debug(`scanEigenschaftBarcode ${this.titel}: ${barcodeData.text}`);

            if (barcodeData.text && !barcodeData.cancelled) {
                let barcodeText = barcodeData.text;

                // Soll ein Rinderpass oder eine Ohrmarke gescannt werden?
                if (this.isOhrmarkeFeld()) {
                    barcodeText = this.getOhrmarkeBarcodeText(barcodeData);
                }

                this.myValue = barcodeText;
            }
        } catch (err) {
            UiHelper.showError(err);
        }

        setTimeout(() => BarcodeAuthGuard.barcodeScanAktiv = false, 500);
    }

    getOhrmarkeBarcodeText(barcodeData: any): string {
        let text = barcodeData.text as string;

        if (!text) {
            return text;
        }

        if (text.length === 12) {
            // Strichcode auf der Ohrmarke
            return 'DE' + text.substring(0, 11);
        } else if (text.length === 16) {
            // Strichcode auf dem Rinderpass. 276000955362661(2)
            return text.substring(0, 15);
        } else {
            return text;
        }
    }

    isOhrmarkeFeld() {
        if (this.titel) {
            if (this.titel.includes('Rinderpass') || this.titel.includes('Ohrmarke')) {
                return true;
            }
        }

        return false;
    }

    async scanNfc() {
        log.debug('scanNfc');

        if (this.readOnly || this.disabled) {
            return;
        }

        this.nfcRegistrationId = this.systemService.registerNfcReceiver(100, (nfcEvent) => this.verarbeiteNfcEvent(nfcEvent));

        BarcodeAuthGuard.barcodeScanAktiv = true;

        if (this.nfcAlert) {
            this.nfcAlert.dismiss();
            this.nfcAlert = null;
        }

        this.nfcAlert = await this.alertController.create({
            mode: 'ios',
            message: I18N.instant('NFCErkennungLaeuft'),
            buttons: [{
                text: I18N.instant('Abbrechen'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                }
            },
            ]
        });

        if (!App.isCordovaAvailable()) {
            setTimeout(() => {
                this.nfcAlert.dismiss();
                this.myValue = 'DEMO12345';
            }, 2000);
        }

        await this.nfcAlert.present();

        await this.nfcAlert.onDidDismiss();

        this.systemService.unregisterNfcReceiver(this.nfcRegistrationId);

        // if (this.auftragdetails) {
        //     this.auftragdetails.starteAuftragWennErforderlich('scanEigenschaftNfc');
        // }

        setTimeout(() => BarcodeAuthGuard.barcodeScanAktiv = false, 500);
    }

    verarbeiteNfcEvent(event: NfcEvent) {
        let barcode: string;

        if (App.current.NfcPayloadVerwenden && event.payload) {
            barcode = event.payload;
        } else {
            barcode = event.seriennummer;
        }

        this.myValue = barcode;

        if (this.nfcAlert) {
            this.nfcAlert.dismiss();
            this.nfcAlert = null;
        }
    }

    async onEnter(e) {
        if (this.enterkeyhint === 'next') {
            Utils.focusNextInputElement();

            // const domElement = e.srcElement?.parentElement as HTMLIonInputElement;

            // if (domElement) {
            //     const nextField = domElement.nextElementSibling as HTMLIonInputElement;
            //     if (nextField) {
            //         nextField.setFocus();
            //     }
            // }
        } else {
            this.app.closeKeyboard(e);
        }
    }
}
