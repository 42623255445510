import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { Logger } from './helper/app-error-logger';

import { AppConfig } from './helper/app.config';
import { App } from './helper/app';
import { GeraetStatusTyp, PushMessageType, SmartwatchKopplungPushMessage } from './model/model';
import { Utils } from './helper/utils';
import { SystemService } from './system.service';
import { Smartwatch } from './model/swagger-model';
import { AppStorage } from './helper/app-storage';

declare var cordova: any;

@Injectable({
    providedIn: 'root'
})
export class SmartwatchService {
    private log = new Logger('SmartwatchService');

    smartwatchStatus = new BehaviorSubject<GeraetStatusTyp>(GeraetStatusTyp.Unbekannt);

    gekoppelteSmartwatchName = new BehaviorSubject<string>('');

    public smartwatches = new BehaviorSubject<Smartwatch[]>([]);
    public gekoppelteSmartwatch = new BehaviorSubject<Smartwatch>(null);

    private updateIntervalId: any;
    private samsungAccessoryPluginInitialized = false;
    private samsungAccessoryPlugin: any;

    private verbunden = false;
    private letzteEmpfaengeneNachricht = Date.now();

    private datenLadenPromise: Promise<any>;

    nachrichtEmpfaengen = new Subject<any>();

    constructor(private systemService: SystemService
    ) {
    }

    async init(): Promise<void> {
        this.log.debug('init');

        await App.ready();

        this.systemService.webSocketNachrichtEmpfangen.subscribe(message => {
            switch (message.t) {
                case PushMessageType.SmartwatchKopplung:
                    this.verarbeiteSmartwatchKopplung(message);
                    break;
                case PushMessageType.SmartwatchPaletteResult:
                    this.nachrichtEmpfaengen.next(message);
                    break;
            }
        });

        this.ladeDatenVonStorage();

        App.current.configRefreshed.subscribe(() => this.initSmartwatch());

        setTimeout(() => {
            this.initSmartwatch();
        }, 10 * 1000);
    }

    async ladeDatenVonStorage(): Promise<void> {
        if (!this.datenLadenPromise) {
            this.log.info("Lade Stammdaten vom Storage...");

            this.datenLadenPromise = new Promise(async resolve => {
                let smartwatches = await AppStorage.current.get('smartwatches');
                const gekoppelteSmartwatch: Smartwatch = await AppStorage.current.get('gekoppelte-smartwatch');

                if (!smartwatches) {
                    smartwatches = [];
                }

                this.smartwatches.next(smartwatches);
                this.gekoppelteSmartwatch.next(gekoppelteSmartwatch);

                if (gekoppelteSmartwatch) {
                    this.gekoppelteSmartwatchName.next(gekoppelteSmartwatch.Name);
                } else {
                    this.gekoppelteSmartwatchName.next('');
                }

                resolve(null);
            });
        }

        await this.datenLadenPromise;

        this.log.debug("Lade Stammdatenn vom Storage fertig");
    }


    public async loescheDaten(): Promise<void> {
        this.log.info('loescheDaten');

        try {
            await AppStorage.current.set('smartwatches', []);

            this.smartwatches.next([]);
        } catch (err) {
            this.log.error('loescheDaten', err);
        }
    }

    /**
     * Wird aufgerufen wenn Einstellungen geändert wurden
     */
    async restart(): Promise<void> {
        this.log.info('restart');
        await this.initSmartwatch();
    }

    private async initSmartwatch(): Promise<void> {
        this.log.debug('initSmartwatch');

        if (this.updateIntervalId) {
            clearInterval(this.updateIntervalId);
            this.updateIntervalId = null;
        }

        if (App.current.GalaxyWatchVerfuegbar.getValue()) {
            switch (AppConfig.current.einstellungen.SmartwatchAnbindung) {
                case 'samsungaccessoryservice':
                    await this.initSamsungAccessoryService();
                    break;

                case 'websocket':
                    await this.initWebSocketConnection();
                    break;
            }
        } else {
            this.smartwatchStatus.next(GeraetStatusTyp.NichtVerbunden);
        }
    }

    async initWebSocketConnection(): Promise<void> {
        // TODO derzeit keine Aktion notwendig
    }

    async initSamsungAccessoryService(): Promise<void> {
        this.log.debug("initSamsungAccessoryService");

        if (!App.isCordovaAvailable()) {
            this.log.warn('Cordova nicht verfügbar. Samsung Galaxy Watch.');
            return;
        }

        if (this.samsungAccessoryPluginInitialized) {
            this.log.debug("SamsungAccessoryService bereits initialisiert. Abbruch");
            return;
        }

        this.samsungAccessoryPluginInitialized = true;

        this.samsungAccessoryPlugin = (cordova.plugins as any).SamsungAccessoryPlugin;

        if (!this.samsungAccessoryPlugin) {
            this.samsungAccessoryPlugin = (window as any).SamsungAccessoryPlugin;
        }

        if (!this.samsungAccessoryPlugin) {
            this.log.error('SamsungAccessoryService: SamsungAccessoryPlugin nicht gefunden');
            return;
        }

        this.log.info("SamsungAccessoryService: init");

        let ok = await this.initSamsungAccessoryPlugin();

        if (!ok) {
            return;
        }

        // Etwas warten
        await Utils.delay(6000);

        const receiveMessageSuccess = (message: string) => {
            this.log.debug("receiveMessageSuccess: Received message from Watch : " + message);
            this.letzteEmpfaengeneNachricht = Date.now();

            try {
                const data = JSON.parse(message);

                if (data.t === PushMessageType.Pong) {
                    // ignorieren
                    return;
                }

                this.nachrichtEmpfaengen.next(data);
            } catch (err) {
                this.log.warn('receiveMessageSuccess: ' + Utils.getErrorMessage(err));
            }
        };

        const receiveMessageFailure = () => {
            this.log.error("receiveMessageFailure: Could not receive message from Watch");
        };

        // Register to receive messages
        this.log.debug('registerMessageListener');
        this.samsungAccessoryPlugin.registerMessageListener(receiveMessageSuccess, receiveMessageFailure);

        // Initial nach der Uhr suchen
        await this.findPeer();

        this.log.debug('registerMessageListener 2');
        this.samsungAccessoryPlugin.registerMessageListener(receiveMessageSuccess, receiveMessageFailure);

        // Alle 60 Sekunden ping senden oder nach der Uhr suchen
        setInterval(async () => {
            if (this.letzteEmpfaengeneNachricht < 25 * 1000) {
                this.verbunden = false;
            }

            if (this.verbunden) {
                this.sendMessage({ t: PushMessageType.Ping });
            } else {
                ok = await this.findPeer();

                if (ok) {
                    this.verbunden = true;

                    this.sendMessage({ t: PushMessageType.Ping });
                }
            }
        }, 10 * 1000);
    }

    private initSamsungAccessoryPlugin(): Promise<boolean> {
        this.log.debug('initSamsungAccessoryPlugin');

        return new Promise(async (resolve) => {
            try {
                this.samsungAccessoryPlugin.init(
                    () => {
                        this.log.debug('samsungAccessoryPlugin init erfolgreich');
                        resolve(true);
                    },
                    (err) => {
                        this.log.error('samsungAccessoryPlugin init fehlgeschlagen', err);
                        resolve(false);
                    });
            } catch (err) {
                this.log.error('initSamsungAccessoryPlugin: ' + Utils.getErrorMessage(err, true));
            }
        });
    }

    private findPeer(): Promise<boolean> {
        this.log.debug('findPeer');

        return new Promise(async (resolve) => {
            try {
                this.samsungAccessoryPlugin.findPeer(
                    () => {
                        this.log.debug('findPeer erfolgreich');
                        this.verbunden = true;
                        resolve(true);
                    },
                    (err) => {
                        this.log.error('findPeer fehlgeschlagen', err);
                        this.verbunden = false;
                        resolve(false);
                    });
            } catch (err) {
                this.log.error('findPeer: ' + Utils.getErrorMessage(err, true));
                this.verbunden = false
            }
        });
    }

    async sendMessage(message: any): Promise<boolean> {
        this.log.debug('sendMessage', message);

        let result = false;

        switch (AppConfig.current.einstellungen.SmartwatchAnbindung) {
            case 'samsungaccessoryservice':
                result = await this.sendSamsungMessage(message);
                break;

            case 'websocket':
                result = await this.sendWebSocketMessage(message);
                break;
        }

        return result;
    }

    async sendSamsungMessage(message: any): Promise<boolean> {
        this.log.debug('sendSamsungMessage', message);

        if (!this.verbunden) {
            await this.findPeer();
        }

        if (!this.verbunden) {
            return false;
        }

        return await new Promise(async (resolve) => {
            if (!this.samsungAccessoryPlugin) {
                this.log.error('samsungAccessoryPlugin nicht intialisiert', message);
                resolve(false);
                return;
            }

            this.samsungAccessoryPlugin.sendMessage(
                () => {
                    // Success
                    this.log.debug('sendMessage erfolgreich', message);
                    resolve(true);
                },
                (err) => {
                    // Failed
                    this.log.warn('sendMessage fehlgeschlagen', err);
                    resolve(false);
                }, message);
        });
    }

    async sendWebSocketMessage(message: any): Promise<boolean> {
        this.log.debug('sendWebSocketMessage', message);

        return await this.systemService.sendWebSocketMessage(message, 'smartwatch');
    }

    public async speichereSmartwatch(smartwatch: Smartwatch): Promise<void> {
        this.log.info("speichereSmartwatch", smartwatch);

        if (smartwatch) {
            const smartwatches = this.smartwatches.getValue();

            const index = smartwatches.findIndex(p => p.GeraeteId == smartwatch.GeraeteId);

            if (index >= 0) {
                smartwatches[index] = smartwatch;
            } else {
                smartwatches.push(smartwatch);
            }

            await AppStorage.current.set('smartwatches', smartwatches);

            this.smartwatches.next(smartwatches);
        }
    }

    public async entferneSmartwatch(smartwatch: Smartwatch): Promise<void> {
        this.log.info("entferneSmartwatch", smartwatch);

        if (smartwatch) {
            const smartwatches = this.smartwatches.getValue();

            const index = smartwatches.findIndex(p => p.GeraeteId == smartwatch.GeraeteId);

            if (index >= 0) {
                smartwatches.splice(index, 1);
            }

            await AppStorage.current.set('smartwatches', smartwatches);

            this.smartwatches.next(smartwatches);
        }
    }

    public async speichereGekoppelteSmartwatch(smartwatch: Smartwatch): Promise<void> {
        this.log.info("speichereSmartwatch", smartwatch);

        await AppStorage.current.set('gekoppelte-smartwatch', smartwatch);

        this.gekoppelteSmartwatch.next(smartwatch);
        this.gekoppelteSmartwatchName.next(smartwatch?.Name);
    }

    public getGekoppelteSmartwatchId(): string {
        return this.gekoppelteSmartwatch.getValue()?.GeraeteId;
    }

    private verarbeiteSmartwatchKopplung(message: SmartwatchKopplungPushMessage) {
        this.log.debug("verarbeiteSmartwatchKopplung", message);

        if (message.Status === 'getrennt') {
            if (message.SmartwatchId === this.getGekoppelteSmartwatchId()) {
                this.gekoppelteSmartwatch.next(null);
                this.gekoppelteSmartwatchName.next('');
            }
        } else if (message.Status === 'verbunden') {
            const smartwatch = this.smartwatches.getValue().find(p => p.GeraeteId === message.SmartwatchId);

            if (smartwatch) {
                smartwatch.Name = message.SmartwatchName;

                this.gekoppelteSmartwatch.next(smartwatch);
                this.gekoppelteSmartwatchName.next(smartwatch.Name);

                this.speichereSmartwatch(smartwatch);
            }

            // if (message.SmartwatchId !== this.getGekoppelteSmartwatchId()) {
            // }
        }
    }
}
