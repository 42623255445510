<ion-content #content class="zusatzinfo-content">
  <ion-card *ngIf="workflowStatus" style="--background: white;">
    <ion-card-header>
      <ion-card-title>{{ workflowStatus.name }}</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <ion-list>
        <ng-container *ngIf="felder.length > 0">
          <ng-template ngFor let-p [ngForOf]="felder">
            <!-- Checkbox -->
            <app-input-checkbox *ngIf="p.Typ === 'checkbox'" [titel]="p.Bezeichnung" [(ngModel)]="p.Wert"></app-input-checkbox>

            <!-- Text -->
            <!-- <app-input-text *ngIf="p.Typ === 'text'" [titel]="p.Bezeichnung" [(ngModel)]="p.Wert" [clearInput]="false"></app-input-text> -->

            <!-- Ganzzahl -->
            <app-input-number
                              *ngIf="p.Typ === 'ganzzahl'"
                              type="decimal"
                              [titel]="p.Bezeichnung"
                              [(ngModel)]="p.Wert"
                              [required]="p.Erforderlich"
                              [clearInput]="true"
                              [plusMinusButtons]="false"
                              [noEmptySpace]="true"
                              [paddingStart]="0"></app-input-number>

            <!-- Dezimalzahl -->
            <app-input-number
                              *ngIf="p.Typ === 'dezimalzahl'"
                              type="ganzzahl"
                              [titel]="p.Bezeichnung"
                              [(ngModel)]="p.Wert"
                              [required]="p.Erforderlich"
                              [clearInput]="true"
                              [plusMinusButtons]="false"
                              [noEmptySpace]="true"
                              [paddingStart]="0"></app-input-number>

            <!-- Datum -->
            <app-input-datum *ngIf="p.Typ === 'datum'"
                             [titel]="p.Bezeichnung"
                             [(ngModel)]="p.Wert"
                             [required]="p.Erforderlich"
                             [paddingStart]="0"></app-input-datum>

            <!-- Uhrzeit -->
            <app-input-uhrzeit *ngIf="p.Typ === 'uhrzeit'"
                             [titel]="p.Bezeichnung"
                             [(ngModel)]="p.Wert"
                             [required]="p.Erforderlich"
                             [paddingStart]="0"></app-input-uhrzeit>

            <ion-item *ngIf="p.Typ === 'text'" lines="none" class="ion-no-padding item-label-stacked">
              <ion-label position="stacked" class="stacked-input-label">{{ p.Bezeichnung }}</ion-label>
              <ion-input type="text" [(ngModel)]="p.Wert" clearInput></ion-input>
            </ion-item>

            <!-- 
            <ion-item lines="none" class="ion-no-padding item-label-stacked">
              <ion-label *ngIf="p.Typ !== 'checkbox'" position="stacked" class="stacked-input-label">{{ p.Bezeichnung }}</ion-label>
              <ion-label *ngIf="p.Typ === 'checkbox'" class="">{{ p.Bezeichnung }}</ion-label>

              <ion-input *ngIf="p.Typ == 'text' || p.Typ == '' || p.Typ == null" type="text" [(ngModel)]="p.Wert" clearInput></ion-input>
              <ion-input *ngIf="p.Typ == 'ganzzahl'" inputmode="numeric" type="text" min="0" step="1" pattern="\d+" [(ngModel)]="p.Wert" clearInput></ion-input>
              <ion-input *ngIf="p.Typ == 'dezimalzahl'" inputmode="decimal" type="text" [(ngModel)]="p.Wert" pattern="^[0-9]+([\\,\\.][0-9]+)?$" clearInput></ion-input>

              <ng-container *ngIf="p.Typ == 'datum'">
                <ion-input type="text" [(ngModel)]="p.Wert" pattern="^\d{1,2}\.\d{1,2}\.\d{4}$" (keyup.enter)="app.closeKeyboard($event)" enterkeyhint="done" clearInput></ion-input>
                <ion-button class="datum-button" appClick (clicked)="selectDatum(p)" size="default" color="light" slot="end">...</ion-button>
              </ng-container>

              <ion-checkbox *ngIf="p.Typ == 'checkbox'" [(ngModel)]="p.Wert" slot="end"></ion-checkbox>
            </ion-item> -->
          </ng-template>
        </ng-container>
      </ion-list>

      <div>
        <ion-button expand="full" color="primary" class="button-md" appClick (clicked)="onOK()">OK</ion-button>
        <ion-button expand="full" color="dark" fill="clear" appClick (clicked)="onAbbrechen()">Abbrechen</ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>