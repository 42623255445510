import { Component, OnInit, OnDestroy, ViewChild, Input, AfterViewInit, forwardRef, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logger } from 'src/app/api/helper/app-error-logger';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { App } from 'src/app/api/helper/app';
import { AlertController, IonInput, IonPopover } from '@ionic/angular';

import * as moment from 'moment';
import { Utils } from 'src/app/api/helper/utils';
import { DateHelper } from 'src/app/api/helper/date-helper';

const log = new Logger('InputDatum');

@Component({
    selector: 'app-input-datum',
    templateUrl: './input-datum.component.html',
    styleUrls: ['./input-datum.component.scss'],
    providers: [{   // <================================================ ADD THIS
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputDatum),
        multi: true
    }]
})
export class InputDatum implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit, OnChanges {

    @ViewChild('input', { read: IonInput, static: true }) input: IonInput;
    @ViewChild('popover', { read: IonPopover }) popover: IonPopover;

    @Input() titel: string;
    @Input() lines = 'none';

    @Input() disabled = false;
    @Input() readOnly = false;
    @Input() enterkeyhint = 'done';
    @Input() required = false;
    @Input() clearInput = false;
    @Input() isInvalid = false;
    @Input() paddingStart = undefined;

    @Output() valueChanged = new EventEmitter<any>();
    @Output() lostFocus = new EventEmitter<any>();

    subscriptions: Subscription[] = [];

    isVisible = false;

    isClearButtonVisible = false;
    isEmptySpaceVisible = false;

    pattern = '^\\d{1,2}\\.\\d{1,2}\\.\\d{4}$';

    style = '';

    dateTimeValue = '';

    app = App.current;

    private _value: string;

    nfcAlert: any;

    /** 
     * Datum wann das Popover geöffnet wurde zur Datumsauswahl
     */
    selectDatumDate = 0;

    // Whatever name for this (myValue) you choose here, use it in the .html file.
    public get myValue(): string { return this._value }

    public set myValue(v: string) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);

            if (this.isVisible) {
                this.validate();
            }
        }
    }

    constructor(
        private alertController: AlertController,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        // log.debug('changed ' + this.titel, changes);

        this.updateType();
    }

    onChange = (_) => {
        if (this.isVisible) {
            this.validate();
        }
    };

    onTouched = () => { };

    writeValue(value: any): void {
        this.myValue = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    ngOnInit() {
        this.isVisible = true;
        this.updateType();
        this.validate();
    }

    async ngAfterViewInit() {
        // log.debug('ngAfterViewInit ' + this.titel);

        setTimeout(() => {
            this.validate();
        });
    }

    ngOnDestroy() {
    }

    async ionViewWillEnter() {
    }

    ionViewDidEnter() {
    }

    ionViewWillLeave() {
    }

    onClearValue() {
        this.myValue = null;
        this.valueChanged.next(this._value);

        this.input.setFocus();
    }

    updateType() {
        this.isClearButtonVisible = false;


        // if (this.plusMinusButtons && !this.disabled && !this.readOnly) {
        //     this.isPlusButtonVisible = true;
        //     this.isMinusButtonVisible = true;
        // }

        if (this.clearInput) {
            this.isClearButtonVisible = true;
        }

        if (!this.isClearButtonVisible) {
            this.isEmptySpaceVisible = true;
        }

        this.style = '';

        if (this.paddingStart != null) {
            this.style += '--padding-start: ' + this.paddingStart;
        }
    }

    onBlur(e: any) {
        log.debug('onBlur', e);
        this.korrigiereEingabe();

        this.lostFocus.next(e);
        this.valueChanged.next(this._value);
        this.validate();
    }

    onValueChange(e: any) {
        log.debug('onValueChange', e);
        this.korrigiereEingabe();

        this.valueChanged.next(this._value);
        this.validate();
    }

    async selectDatum(e: any) {
        log.debug('selectDatum: ' + this.titel);

        if (this.readOnly) {
            return;
        }

        try {
            let m: moment.Moment = null;

            if (this._value && this._value.length >= 8 && this._value.length <= 10) {
                m = Utils.parseDatum(this._value)?.local();
            }

            if (!m) {
                m = moment();
            }

            this.selectDatumDate = Date.now();

            this.dateTimeValue = m.toISOString(true);

            this.cdr.detectChanges();

            await Utils.delay(1);

            await this.popover.present(e);

            // await this.popover.onDidDismiss();

            // alert('ok');

            // this.myValue = m.format('DD.MM.YYYY');
            // this.valueChanged.next(this._value);
            // this.validate();

            setTimeout(() => {
                App.closeAnyKayboard();
            }, 100);
        } catch (err) {
            // UiHelper.showError(err);
        }
    }

    async onDateSelected(e: any) {
        log.debug('onDateSelected: ' + this.titel, e);

        if (this.selectDatumDate > Date.now() - 200) {
            // ignore
            return;
        }

        if (this.popover) {
            this.popover.dismiss();
        }

        const value = e.detail?.value;

        if (value) {
            const m = Utils.parseDatum(value);

            if (m) {
                this.myValue = m.format('DD.MM.YYYY');
                this.valueChanged.next(this._value);
                this.validate();
            }
        }
    }

    /**
     * Korrigiert falsche Eingaben im Form z.B.: 1.3.23
     */
    korrigiereEingabe() {
        log.debug('korrigiereEingabe');

        if (this._value && typeof (this._value) === 'string') {
            const str = this._value;
            const parts = str.split(/[\.,\/]/);

            let tag = -1;
            let monat = -1;
            let jahr = -1;
            let aktuellesJahr = new Date().getFullYear();

            if (parts.length == 2) {
                tag = Utils.parseInt(parts[0]);
                monat = Utils.parseInt(parts[1]);
                jahr = aktuellesJahr;
            } else if (parts.length == 3) {
                tag = Utils.parseInt(parts[0]);
                monat = Utils.parseInt(parts[1]);
                jahr = Utils.parseInt(parts[2]);

                if (jahr > 1 && jahr < 100) {
                    // Jahr wurde zweistellig eingegebn
                    jahr = 2000 + jahr;
                }
            }

            if (tag >= 1 && tag <= 31) {
                if (monat >= 1 && monat <= 12) {
                    if (jahr >= 2000 && jahr <= 2999) {
                        const z = n => ('0' + n).slice(-2);
                        const zz = n => ('000' + n).slice(-4);

                        let neuerWert = `${z(tag)}.${z(monat)}.${zz(jahr)}`;

                        if (neuerWert != str) {
                            log.info(`Datum korrigiert. Alt=${str}, Neu=${neuerWert}`);
                            this.myValue = neuerWert;
                        }
                    }
                }
            }
        }
    }

    validate(): boolean {
        // log.debug('validate ' + this.titel + ': ' + this.pattern + ', value=' + this._value);

        this.isInvalid = false;

        if (this.pattern && this.pattern != '.*' && this._value) {
            this.isInvalid = false;

            try {
                const wert = Utils.trimToEmpty(this._value);

                if (!wert.match(this.pattern)) {
                    this.isInvalid = true;
                }

            } catch (err) {
                log.warn('Fehler in Regex ' + this.pattern + ': ' + Utils.getErrorMessage(err));
            }
        }

        if (this.required && !this._value) {
            this.isInvalid = true;
        }

        return !this.isInvalid;
    }
}
