import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdresseComponent } from './components/adresse/adresse.component';
import { IonicModule } from '@ionic/angular';
import { UnterschriftComponent } from './components/unterschrift/unterschrift.component';
import { AuftragitemComponent } from './components/auftragitem/auftragitem.component';
import { AppRoutingModule } from '../app-routing.module';
import { RouterModule } from '@angular/router';
import { MainmenuComponent } from './components/mainmenu/mainmenu.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { LieferungComponent } from './components/lieferung/lieferung.component';
import { AuftragBarcodeModalPage } from './modal/auftrag-barcode-modal/auftrag-barcode-modal.page';
import { AuftragBarcodePage } from './components/auftrag-barcode/auftrag-barcode.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppAuftragChecklisteComponent } from './components/auftrag-checkliste/auftrag-checkliste.component';
import { AppAuftragDateienComponent } from './components/auftrag-dateien/auftrag-dateien.component';
import { UvvPruefungModalPage } from './modal/uvv-pruefung-modal/uvv-pruefung-modal.page';
import { ClickDirective } from './directives/click.directive';
import { AuftragKasseZusammenfassungComponent } from './components/auftrag-kasse-zusammenfassung/auftrag-kasse-zusammenfassung.component';
import { AuftragKasseComponent } from './components/auftrag-kasse/auftrag-kasse.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { HideHeaderDirective } from './directives/hide-header.directive';
import { BarcodePage } from './components/barcode/barcode.component';
import { BarcodeModalPage } from './modal/barcode-modal/barcode-modal.page';
import { StatuszusatzinfoModalPage } from './modal/statuszusatzinfo-modal/statuszusatzinfo-modal.page';
import { SignaturePad } from './components/signature-pad/signature-pad.component';
import { KontaktlisteComponent } from './components/kontaktliste/kontaktliste.component';
import { ArtikelSuchenComponent } from './components/artikel-suchen/artikel-suchen.component';
import { SidemenuComponent } from './components/sidemenu.component';
import { LeergutComponent } from './components/leergut/leergut.component';
import { StoerzeitenPage } from './components/stoerzeiten/stoerzeiten.page';
import { StoerzeitEditPage } from './components/stoerzeit-edit/stoerzeit-edit.page';
import { UvvDetailsPage } from './components/uvv-details/uvv-details.page';
import { LieferantSuchenComponent } from './components/lieferant-suchen/lieferant-suchen.component';
import { WareneinganScanPage } from './components/wareneingang-scan/wareneingang-scan.page';
import { FuehrerscheinkontrolleModalPage } from './modal/fuehrerscheinkontrolle-modal/fuehrerscheinkontrolle-modal.page';
import { UnterschriftModalPage } from './modal/unterschrift-modal/unterschrift-modal.page';
import { SeriennummernListeComponent } from './components/seriennummern-liste/seriennummern-liste.component';
import { InputNumber } from './inputs/input-number/input-number.component';
import { InputCheckbox } from './inputs/input-checkbox/input-checkbox.component';
import { InputText } from './inputs/input-text/input-text.component';
import { InputDatum } from './inputs/input-datum/input-datum.component';
import { UvvRfidTauschPage } from './components/uvv-rfid-tausch/uvv-rfid-tausch.page';
import { InputSelect } from './inputs/input-select/input-select.component';
import { InputUhrzeit } from './inputs/input-uhrzeit/input-uhrzeit.component';
import { FotosPage } from './components/fotos/fotos.component';
import { VertragSuchenComponent } from './components/vertrag-suchen/vertrag-suchen.component';
import { VertragDetailsComponent } from './components/vertrag-details/vertrag-details.component';
import { InputTextarea } from './inputs/input-textarea/input-textarea.component';
import { InputTextareaPopupPage } from './inputs/input-textarea-popup/input-textarea-popup.page';
import { InputZeitPopupPage } from './inputs/input-zeit-popup/input-zeit-popup.page';
import { LeegewichtWiegenPage } from './components/leergewicht-wiegen/leergewicht-wiegen.page';
import { EPPipe } from './pipes/ep.pipe';
import { PreisPipe } from './pipes/preis.pipe';
import { AdresseSuchenComponent } from './components/adresse-suchen/adresse-suchen.component';
import { AdresseDetailsComponent } from './components/adresse-details/adresse-details.component';
import { AbfahrtskontrolleGruppeDetailsComponent } from './components/abfahrtskontrolle-gruppe-details/abfahrtskontrolle-gruppe-details.component';
import { CheckmarkComponent } from './components/checkmark/checkmark.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild([]),
        IonicSelectableModule,
        TranslateModule,
        PinchZoomModule,
        ReactiveFormsModule,
        ScrollingModule
    ],
    declarations: [
        StatuszusatzinfoModalPage,
        UvvPruefungModalPage,
        AuftragBarcodePage,
        AuftragBarcodeModalPage,
        AppAuftragChecklisteComponent,
        AppAuftragDateienComponent,
        AuftragKasseComponent,
        AuftragKasseZusammenfassungComponent,
        AdresseComponent,
        BarcodePage,
        BarcodeModalPage,
        LieferungComponent,
        LeergutComponent,
        SignaturePad,
        UnterschriftComponent,
        AuftragitemComponent,
        MainmenuComponent,
        ClickDirective,
        HideHeaderDirective,
        KontaktlisteComponent,
        ArtikelSuchenComponent,
        LieferantSuchenComponent,
        VertragSuchenComponent,
        VertragDetailsComponent,
        SidemenuComponent,
        StoerzeitenPage,
        StoerzeitEditPage,
        UvvDetailsPage,
        UvvRfidTauschPage,
        WareneinganScanPage,
        SeriennummernListeComponent,
        FuehrerscheinkontrolleModalPage,
        UnterschriftModalPage,
        InputNumber,
        InputCheckbox,
        InputText,
        InputDatum,
        InputSelect,
        InputUhrzeit,
        InputTextarea,
        InputTextareaPopupPage,
        InputZeitPopupPage,
        FotosPage,
        LeegewichtWiegenPage,
        EPPipe,
        PreisPipe,
        AdresseSuchenComponent,
        AdresseDetailsComponent,
        AbfahrtskontrolleGruppeDetailsComponent,
        CheckmarkComponent
    ],
    exports: [
        StatuszusatzinfoModalPage,
        UvvPruefungModalPage,
        AuftragBarcodeModalPage,
        AuftragBarcodePage,
        AppAuftragChecklisteComponent,
        AppAuftragDateienComponent,
        AuftragKasseComponent,
        AuftragKasseZusammenfassungComponent,
        AdresseComponent,
        BarcodePage,
        BarcodeModalPage,
        LieferungComponent,
        LeergutComponent,
        SignaturePad,
        UnterschriftComponent,
        AuftragitemComponent,
        MainmenuComponent,
        IonicSelectableModule,
        TranslateModule,
        ClickDirective,
        HideHeaderDirective,
        PinchZoomModule,
        KontaktlisteComponent,
        ArtikelSuchenComponent,
        LieferantSuchenComponent,
        VertragSuchenComponent,
        VertragDetailsComponent,
        SidemenuComponent,
        StoerzeitenPage,
        StoerzeitEditPage,
        UvvDetailsPage,
        UvvRfidTauschPage,
        WareneinganScanPage,
        FuehrerscheinkontrolleModalPage,
        UnterschriftModalPage,
        SeriennummernListeComponent,
        InputNumber,
        InputCheckbox,
        InputText,
        InputDatum,
        InputSelect,
        InputUhrzeit,
        InputTextarea,
        InputTextareaPopupPage,
        InputZeitPopupPage,
        FotosPage,
        LeegewichtWiegenPage,
        EPPipe,
        PreisPipe,
        AdresseSuchenComponent,
        AdresseDetailsComponent,
        AbfahrtskontrolleGruppeDetailsComponent,
        CheckmarkComponent,
        ScrollingModule
    ],
    providers: []
})
export class SharedModule { }
