import { Component, OnInit, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuftragEx } from 'src/app/api/model/model';
import { ModalController } from '@ionic/angular';
import { AuftragService } from 'src/app/api/auftrag.service';
import { Logger } from 'src/app/api/helper/app-error-logger';
import { App } from 'src/app/api/helper/app';
import { Adresse } from 'src/app/api/model/swagger-model';
import { IAuftragdetails } from '../auftragdetails/iauftragdetails';

@Component({
    selector: 'app-auftrag-adresse',
    templateUrl: './auftrag-adresse.page.html',
    styleUrls: ['./auftrag-adresse.page.scss'],
})
export class AuftragAdressePage implements OnInit, AfterViewInit, OnDestroy {
    log = new Logger("AuftragAdressePage");

    @Input() auftrag: AuftragEx;
    @Input() adresse: Adresse;
    @Input() auftragdetails: IAuftragdetails;

    subscriptions: Subscription[] = [];

    app = App.current;

    constructor(
        private modalController: ModalController,
        private auftragService: AuftragService) { }

    async ionViewWillEnter() {
        this.log.debug('ionViewWillEnter');

        if (this.auftrag) {
            this.auftragService.setzeAktivenAuftrag(this.auftrag, 'adresse');
        }
    }

    ionViewDidEnter() {
        App.current.backAction = () => this.onAbbrechen();
    }

    async ionViewWillLeave() {
        this.log.debug('ionViewWillLeave');
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    async ngOnDestroy() {
        this.log.debug('ngOnDestroy');

        App.current.backAction = null;

        this.subscriptions.forEach(p => p.unsubscribe());
        this.subscriptions = [];

        if (this.auftrag) {
            if (this.auftragService.istAktiverAuftrag(this.auftrag)) {
                await this.auftragService.speichereAuftrag(this.auftrag);

                // this.auftragService.setzeAktivenAuftrag(null, 'adresse exit');
            }
        }
    }

    onAbbrechen() {
        this.dismiss();
    }

    async onSpeichern() {
        this.dismiss();
    }

    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            'dismissed': true
        });
    }
}
